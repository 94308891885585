import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'antd';

import { SectionDashboard } from 'configs/Interface';
import { NameIcons, PathRoute } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import IconCustom from 'components/atoms/icons';
import SectionDashboardSubRoutes from './SectionDashboardSubRoutes';

type SectionDashboardItemProps = {
  section: SectionDashboard;
  hideText?: boolean;
  onClick?: ({ onAction }: { onAction: () => void }) => void;
};

const SectionDashboardItem = ({ section, hideText, onClick }: SectionDashboardItemProps) => {
  const { numFailedOrders } = useContext(AuthContext);

  const [isExpand, setIsExpand] = useState(false);
  const [badge, setBadge] = useState(0);
  const [over, setOver] = useState(false);

  const navigate = useNavigate();

  const handleClickSubRoute = () => {
    setIsExpand(!isExpand);
  };

  useEffect(() => {
    if (section.path === PathRoute.listOrders || section.subRoutes?.some(rout => rout.path === PathRoute.listFailedOrders)) {
      setBadge(numFailedOrders);
    }
  }, [numFailedOrders]);

  return (
    <li>
      {!section.subRoutes && (
        <div className='bg-primary'>
          <button
            className={`flex w-full flex-row bg-white items-center h-12 border-white hover:border-araucoblue-200 border-l-4 pr-1`}
            onMouseOver={() => {
              setOver(true);
            }}
            onMouseOut={() => {
              setOver(false);
            }}
            onClick={() => {
              if (onClick) {
                onClick({
                  onAction: () => {
                    if (section.path) {
                      navigate(section.path);
                    }
                  }
                });
              }
            }}
          >
            <div className='flex items-center'>
              <span className='inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400'>
                <IconCustom className={`${over ? 'text-araucoblue-200' : 'text-grayaraucofive'}`} name={section.icon} />
              </span>
              {!hideText && <span className='text-sm font-medium mr-1'>{section.name}</span>}
            </div>

            {/**
            <Badge count={hideText ? badge : 0} overflowCount={99} />
            **/}
            <Badge offset={[hideText ? -30 : 0, 0]} count={badge} overflowCount={99} />
          </button>
        </div>
      )}
      {section.subRoutes && (
        <div className='bg-primary'>
          <div
            className={`flex bg-white flex-row items-center justify-between h-12 border-l-4 border-white pr-1 cursor-pointer ${
              isExpand ? 'border-araucoblue-200 bg-grayarauco-100 bg-gray-100' : ''
            }`}
            onClick={() => handleClickSubRoute()}
            onMouseOver={() => {
              setOver(true);
            }}
            onMouseOut={() => {
              setOver(false);
            }}
          >
            <div className='flex items-center'>
              <span className='inline-flex items-center justify-center h-12 w-12 text-lg text-black'>
                <IconCustom name={section.icon} className={`${isExpand ? 'text-araucoblue-200' : 'text-grayaraucofive'}`} />
              </span>
              {!hideText && <span className='text-sm font-medium'>{section.name}</span>}
            </div>

            <Badge offset={[hideText ? -30 : 0, 0]} count={isExpand ? 0 : badge} overflowCount={99} />

            <IconCustom className='mr-2' name={isExpand ? NameIcons.arrowUp : NameIcons.arrowDown} />
          </div>
        </div>
      )}
      {isExpand &&
        section.subRoutes &&
        section.subRoutes.map(subRoute => (
          <SectionDashboardSubRoutes
            key={subRoute.path}
            subRoute={subRoute}
            badge={subRoute.path === PathRoute.listFailedOrders ? numFailedOrders : 0}
            onClick={() => {
              if (onClick) {
                onClick({
                  onAction: () => {
                    if (subRoute.path) {
                      navigate(subRoute.path);
                    }
                  }
                });
              }
            }}
            {...{ isExpand, hideText }}
          />
        ))}
    </li>
  );
};

export default SectionDashboardItem;
