import { CSSProperties, useContext, useState } from 'react';
import { Dropdown } from 'antd';

import { NameIcons, SizeScreen } from 'configs/Enums';

import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';

import { shippingTypeStringToDisplay } from 'helpers/Converts';

const TypeDeliveryDropdown = ({
  nameIcon,
  title,
  value,
  breakdowns,
  className,
  classNameContainer,
  styleContainer
}: {
  nameIcon?: string;
  title?: string;
  value?: string | number;
  breakdowns?: { title?: string; value?: string | number; icon?: string }[];
  className?: string;
  classNameContainer?: string;
  styleContainer?: CSSProperties;
}) => {
  const { screenResolution } = useContext(RootContext);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`flex flex-1 flex-col ${classNameContainer}`}
      style={{ ...styleContainer, ...{ marginBottom: screenResolution >= SizeScreen.md ? 0 : 2 } }}
    >
      <Dropdown
        trigger={['click']}
        getPopupContainer={triggerNode => {
          console.log('getPopupContainer() => triggerNode', triggerNode);
          return triggerNode;
        }}
        disabled={false}
        overlay={
          screenResolution >= SizeScreen.md && breakdowns && breakdowns.length ? (
            <div className='bg-white flex flex-col'>
              {breakdowns.map(breakdown => (
                <div className='flex flex-1 items-center px-4 py-4' key={breakdown.title}>
                  <div className='flex justify-center items-center mr-2'>
                    <IconCustom name={breakdown.icon} className='text-4xl text-grayarauco-400' />
                  </div>

                  <div className='flex flex-1 flex-col'>
                    <p className='text-grayarauco-400 font-bold text-lg'>{shippingTypeStringToDisplay(breakdown.title)}</p>
                    <p className='text-black font-normal text-lg'>{breakdown.value}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )
        }
      >
        <div className={`flex flex-1 items-center px-4 py-6 bg-white ${className}`} onClick={() => setIsOpen(!isOpen)}>
          <div className='flex justify-center items-center mr-2'>
            <IconCustom name={nameIcon} className='text-4xl text-grayarauco-400' />
          </div>

          <div className='flex flex-1 flex-col'>
            <p className='text-grayarauco-400 font-bold text-lg'>{title}</p>
            <p className='text-black font-normal text-lg'>{value}</p>
          </div>

          {breakdowns && breakdowns.length > 0 && (
            <div className='flex justify-end items-end h-full'>
              <IconCustom name={!isOpen ? NameIcons.arrowDown : NameIcons.arrowUp} className='text-grayarauco-400 text-2xl' />
            </div>
          )}
        </div>
      </Dropdown>

      {isOpen && breakdowns && breakdowns.length > 0 && screenResolution < SizeScreen.md && (
        <div className='bg-white flex flex-col'>
          {breakdowns.map((breakdown: any, index: number) => (
            <div
              className='flex flex-1 items-center px-4 py-4 border-grayarauco-100'
              style={{ borderBottomWidth: screenResolution >= SizeScreen.md && index !== breakdowns.length - 1 ? 0 : 1 }}
              key={breakdown.title}
            >
              <div className='flex justify-center items-center mr-2'>
                <IconCustom name={breakdown.icon} className='text-4xl text-grayarauco-400' />
              </div>

              <div className='flex flex-1 flex-col'>
                <p className='text-grayarauco-400 font-bold text-lg'>{shippingTypeStringToDisplay(breakdown.title)}</p>
                <p className='text-black font-normal text-lg'>{breakdown.value}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TypeDeliveryDropdown;
