import { makeAutoObservable } from 'mobx';
import moment from 'configs/MomentJS';

import { KEY_COUNTRY_SESSION, KEY_TOKEN, KEY_TOKEN_EXPIRED_ID } from 'configs/Constants';
import { ResponseApp } from 'configs/Interface';
import { FormatDate } from 'configs/Enums';

class Session {
  token: undefined | string;
  /* formato de expiracion MM/DD/YYYY, HH:mm:ss A*/
  expiredIn: string | undefined;
  /** pais del usuario si es super admin global **/
  country: string | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  updateToken(token: string) {
    this.token = token;
  }

  updateExpiredIn(expiredIn?: string) {
    const dateUTC = moment(`${expiredIn?.replace(',', '')} Z`)
      .utc(true)
      .format(FormatDate.yyyymmddHHmmAlt);
    //console.log('updateExpiredIn() => dateUTC', { dateUTC, expiredIn: expiredIn?.replace(',', '') });
    this.expiredIn = dateUTC;
  }

  updateCountry(country?: string) {
    this.country = country;
  }

  destroyToken() {
    this.token = undefined;
    this.expiredIn = undefined;
    this.country = undefined;
  }
}

export const mySession = new Session();

export const setTokenSession = (token: string, expiredIn?: string): ResponseApp => {
  try {
    localStorage.setItem(KEY_TOKEN, token);
    localStorage.setItem(KEY_TOKEN_EXPIRED_ID, expiredIn || '');
    mySession.updateToken(token);
    mySession.updateExpiredIn(expiredIn);
    return { status: true };
  } catch (e) {
    return { status: false, message: 'No fue posible guardar su sesión.' };
  }
};

export const getTokenSession = (): ResponseApp => {
  try {
    const token = localStorage.getItem(KEY_TOKEN);
    const tokenExpired = localStorage.getItem(KEY_TOKEN_EXPIRED_ID);
    if (token && token !== null) {
      mySession.updateToken(token);
      if (tokenExpired && tokenExpired !== null && tokenExpired !== '') {
        mySession.updateExpiredIn(tokenExpired);
      }
      return { status: true, data: token };
    }
    return { status: false, message: 'No hay una sesión activa.' };
  } catch (e) {
    return { status: false, message: 'No fue posible obtener la sesión.' };
  }
};

export const removeTokenSession = (): ResponseApp => {
  try {
    localStorage.removeItem(KEY_TOKEN);
    localStorage.removeItem(KEY_TOKEN_EXPIRED_ID);
    localStorage.removeItem(KEY_COUNTRY_SESSION);
    mySession.destroyToken();
    return { status: true };
  } catch (e) {
    return { status: false, message: 'No fue posible cerrar sesión' };
  }
};

export const setCountrySession = (country?: string) => {
  try {
    localStorage.setItem(KEY_COUNTRY_SESSION, country || '');
    mySession.updateCountry(country);
    return { status: true };
  } catch (e) {
    return { status: false, message: 'No fue posible guardar su país.' };
  }
};

export const getCountrySession = () => {
  try {
    const countrySession = localStorage.getItem(KEY_COUNTRY_SESSION);
    if (countrySession && countrySession !== null) {
      mySession.updateCountry(countrySession);
      return { status: true, data: countrySession };
    }
    return { status: false, message: 'No hay un país.' };
  } catch (e) {
    return { status: false, message: 'No fue posible obtener la sesión.' };
  }
};
