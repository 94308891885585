import moment from 'configs/MomentJS';
import { Moment } from 'moment';

import { EnumCountries, FormatDate, TypeDelivery } from 'configs/Enums';

export const validateEmail = (email: string) => {
  try {
    /* eslint-disable no-useless-escape */
    return (
      email.search(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      ) !== -1
    );
    /* eslint-enabled no-useless-escape */
  } catch (err) {
    return false;
  }
};

/**
 * Carga de orden manual
 */
export const isRequeridUnitManualLoad = ({
  country,
  typeDelivery,
  field
}: {
  country?: string;
  typeDelivery?: string;
  field: string;
  typeDocument?: string;
}) => {
  if (typeDelivery) {
    if (typeDelivery === TypeDelivery.pickup || typeDelivery === TypeDelivery.pickupStore) {
      if (field === 'department' || field === 'numStreet' || field === 'direction' || field === 'district' || field === 'city') {
        return false;
      }
    } else if (typeDelivery !== TypeDelivery.pickup && typeDelivery !== TypeDelivery.pickupStore) {
      if (country !== EnumCountries.col && field === 'district') {
        return true;
      } else if (field === 'numStreet' || field === 'direction' || field === 'city') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  if (country) {
    if (field === 'typeDocument') {
      if (country === EnumCountries.cl) {
        return false;
      }
      return true;
    }
    if (field === 'numDocument') {
      if (country === EnumCountries.cl) {
        return false;
      } else {
        return true;
      }
    }
    if (field === 'dimension') {
      if (country !== EnumCountries.cl) {
        console.log('isRequeridUnitManualLoad() true ==>', { country, field });
        return true;
      } else {
        console.log('isRequeridUnitManualLoad() false ==>', { country, field });
        return false;
      }
    }
  }
  return true;
};

export const isDisabledUnitManualLoad = ({
  field,
  typeDelivery,
  cities,
  country
}: {
  country?: string;
  typeDelivery?: string;
  field: string;
  cities?: string[];
}) => {
  if (typeDelivery) {
    if (typeDelivery === TypeDelivery.pickup || typeDelivery === TypeDelivery.pickupStore) {
      if (field === 'department' || field === 'numStreet' || field === 'direction' || field === 'district' || field === 'city') {
        return true;
      }
    }

    if (
      typeDelivery === TypeDelivery.deliveryExpress ||
      typeDelivery === TypeDelivery.deliveryNextDay ||
      typeDelivery === TypeDelivery.deliverySameDay
    ) {
      if (field === 'district' && country === EnumCountries.col) {
        return true;
      }
    }

    if (field === 'city' && cities && cities.length === 1) {
      return true;
    }
  }
  return false;
};

/**
 * diferencias de fecha
 */
export const isSameOrBefore = ({ dateBase, dateReference }: { dateBase?: string; dateReference?: string }) => {
  try {
    if (dateBase && dateReference) {
      //console.log('isSameOrBefore() ==>', { dateBase, dateReference });
      return moment(dateBase).isSameOrBefore(dateReference);
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const isBeforeDate = ({
  dateBase,
  dateReference,
  formatRef,
  formatBase
}: {
  dateBase?: string;
  dateReference?: string;
  formatBase?: string;
  formatRef?: string;
}) => {
  try {
    if (dateBase && dateReference) {
      //console.log('isSameOrAfter() ==>', { dateBase, dateReference });
      return moment(dateBase, formatBase).isBefore(moment(dateReference, formatRef));
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const isSameOrAfter = ({ dateBase, dateReference }: { dateBase?: string | Moment; dateReference?: string | Moment }) => {
  try {
    if (dateBase && dateReference) {
      //console.log('isSameOrAfter() ==>', { dateBase, dateReference });
      return moment(dateBase).isSameOrAfter(dateReference);
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const isAfterDate = ({
  dateBase,
  dateReference,
  formatRef,
  formatBase
}: {
  dateBase?: string;
  dateReference?: string;
  formatBase?: string;
  formatRef?: string;
}) => {
  try {
    if (dateBase && dateReference) {
      //console.log('isSameOrAfter() ==>', { dateBase, dateReference });
      return moment(dateBase, formatBase).isAfter(moment(dateReference, formatRef));
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const isSameDate = ({
  dateBase,
  dateRef,
  formatBase,
  formatRef
}: {
  dateBase?: string;
  dateRef?: string;
  formatBase?: FormatDate;
  formatRef?: FormatDate;
}) => {
  try {
    if (dateBase && dateRef) {
      return moment(moment(dateBase, formatBase)).isSame(moment(dateRef, formatRef));
    }
    return false;
  } catch (err) {
    return false;
  }
};

/**
 * si el valor de la tabla tiene errores
 **/
export const isParameterInvalid = (param?: string | number): boolean => {
  try {
    if (param) {
      return `${param}`.toLowerCase().indexOf('error:') !== -1;
    }
    return false;
  } catch (err) {
    console.log('isParameterInvalid() ==> err', { err, param });
    return false;
  }
};

/*
 * re mueve la etiqueta de error de valor
 ***/
export const removeFlag = (param?: string | number): string => {
  try {
    if (param) {
      return `${param}`.replace('Error:', '');
    }
    return '';
  } catch (err) {
    console.log('removeFlag() => err', { param, err });
    return '';
  }
};
