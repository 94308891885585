import Delivery from 'models/Delivery';

export interface StoreJanisModel {
  referenceId: string;
  name: string;
  warehouseId: string;
  id: string;
  /** si esta habilitado para crear **/
  available?: boolean;
  deliveries?: Delivery[];
}

export interface StoreJanisServerModel {
  referenceId: string;
  name: string;
  warehouseId: string;
  id: string;
}

class StoreJanis {
  data: StoreJanisModel;

  constructor(data: StoreJanisModel) {
    this.data = data;
  }

  static formalizeData(data: StoreJanisServerModel): StoreJanisModel {
    const { referenceId, name, warehouseId, id } = data;

    return {
      referenceId,
      name,
      warehouseId,
      id,
      available: false
    };
  }

  updateAvailable(value?: boolean) {
    this.data.available = value;
  }

  updateDelivery(value?: Delivery[]) {
    this.data.deliveries = value;
  }
}

StoreJanis.prototype.toString = function storeJanisToString() {
  return `${this.data.id}-${this.data.warehouseId}`;
};

export default StoreJanis;
