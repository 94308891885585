import { forwardRef, ReactNode, useState, useEffect, CSSProperties } from 'react';
import { Select, Divider, Input, Typography, Space, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { NameIcons } from 'configs/Enums';

import LabelCustom from 'components/atoms/labels';

import IconCustom from '../icons';

const { Option } = Select;

export type SelectItem = { value: string | number; label?: string | ReactNode; disabled?: boolean; item?: any };

type SelectCustomProps = {
  data: SelectItem[];
  defaultValue?: string;
  className?: string;
  style?: React.CSSProperties;
  onChange: (value?: any) => void;
  value?: string | number | string[] | number[] | any;
  placeholder?: string;
  label?: string;
  classNameLabel?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  //onClick?: () => void;
  isRequest?: boolean;
  isRequired?: boolean;
  messageErrorTooltip?: string;
  flag?: number;
  showSearch?: boolean;
  onValidate?: (isValid?: boolean) => void;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  tooltipMessage?: string;
  notFoundContent?: React.ReactNode;
  classNameContainer?: string;
  styleContainer?: React.CSSProperties;
  maxTagCount?: number | 'responsive';
  hide?: boolean;
  type?: 'default' | 'add';
  open?: boolean;
  bordered?: boolean;
  allowClear?: boolean;
  isIconRequired?: boolean;
  info?: ReactNode;
  styleLabel?: CSSProperties | undefined;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  getPopupContainer?: any;
  optionAll?: boolean;
};

const SelectCustom = forwardRef(
  (
    {
      data,
      placeholder,
      defaultValue,
      className,
      style,
      onChange,
      value,
      label,
      classNameLabel,
      onFocus,
      onBlur,
      //onClick,
      messageErrorTooltip,
      isRequired,
      flag,
      showSearch,
      onValidate,
      mode,
      disabled,
      tooltipMessage,
      notFoundContent,
      classNameContainer,
      maxTagCount,
      hide,
      type = 'default',
      open,
      bordered,
      allowClear = true,
      isIconRequired = true,
      info,
      styleLabel,
      styleContainer,
      dropdownRender,
      getPopupContainer,
      optionAll
    }: SelectCustomProps,
    ref: any
  ) => {
    //const [openPicker, setOpenPicker] = useState<boolean>();
    const [messageError, setMessageError] = useState<string>();
    const [valueCurrent, setValueCurrent] = useState<any>();
    const [valCurr, setValCurr] = useState<string | number>();

    const handleValidate = (valueCurre: any) => {
      if (!valueCurre && isRequired) {
        setMessageError('El campo es requerido.');
        if (onValidate) {
          onValidate(false);
        }
      } else {
        setMessageError(undefined);
        if (onValidate) {
          onValidate(true);
        }
      }
    };

    const handleChange = (valueNew?: string | number, options?: any) => {
      //console.log('handleChange', { valueNew, options });
      if (!mode) {
        //setOpenPicker(false);
      }
      if (valueNew) {
        let newValues =
          typeof valueNew === 'number' || typeof valueNew === 'string' ? options.item : options.map((option: any) => option.item);
        console.log('type', typeof options.item);
        if (typeof options.item === 'object') {
          console.log('handleChange() ==>', options.item.toString());
        }
        if (typeof newValues !== 'number' || typeof newValues !== 'string') {
          if (newValues.length === 0) {
            newValues = undefined;
          }
        }
        console.log('SelectCustom', { value, valueNew, newValues, options, flag });
        onChange(newValues);
        setValueCurrent(newValues);
        handleValidate(newValues);
      } else {
        onChange(undefined);
        setValueCurrent(undefined);
        handleValidate(undefined);
      }
    };

    const handleFocus = () => {
      //setOpenPicker(true);
      if (onFocus) {
        onFocus();
      }
    };

    const handleBlur = () => {
      //setOpenPicker(false);
      if (onBlur) {
        onBlur();
      }
      //console.log('handleBlur() ==>', { value, isRequired });
      handleValidate(valueCurrent);
    };

    const onClick = () => {
      //setOpenPicker(true);
      //if (onClick) {
      //onClick();
      //}
      //setOpenPicker(true);
      //console.log('handleClick()');
    };

    const valueRaw = () => {
      let valCurr = value;
      if (typeof value === 'object') {
        if (value.length > 0) {
          valCurr = value.map((val: any) => val.toString());
        } else {
          if (data && data.length > 0) {
            if (data.filter(dataUnit => dataUnit.value === value.toString()).length > 0) {
              valCurr = value.toString();
            } else {
              valCurr = undefined;
            }
          } else {
            valCurr = undefined;
          }
        }
      } else {
        if (data && data.length > 0) {
          if (data.filter(dataUnit => dataUnit.value === value).length > 0) {
            valCurr = value;
          } else if (value === 'Todas') {
            valCurr = value;
          } else {
            valCurr = undefined;
          }
        }
      }
      console.log('valCurr ==> ', { valCurr, value, data });
      setValCurr(valCurr);
    };

    useEffect(() => {
      //console.log('useEffect() ==>', { messageErrorTooltip, value, ref, isRequired, label });
      if (!value && !isRequired && disabled) {
        setMessageError(undefined);
      }
      if (value && isRequired) {
        handleValidate(value);
      }
      setMessageError(messageErrorTooltip);
      setValueCurrent(value);
      valueRaw();
    }, [messageErrorTooltip, value, ref]);

    //console.log('SelectCustom() ==>', data);
    return (
      <div hidden={hide} className={`relative ${classNameContainer}`} style={styleContainer}>
        {label && (
          <LabelCustom className={`mb-2 ${classNameLabel} flex items-center justify-between font-normal`}>
            <div style={styleLabel}>
              {label}
              {isRequired && isIconRequired && <span className='text-red-400'> *</span>}
            </div>
            {info && (
              <Tooltip
                trigger={['hover', 'focus']}
                placement='top'
                overlay={info}
                getPopupContainer={triggerNode => {
                  console.log('getPopupContainer() => triggerNode', triggerNode);
                  return triggerNode;
                }}
                overlayClassName='ant-tooltip-content-info'
                overlayInnerStyle={{ backgroundColor: 'white', borderRadius: 5, color: 'black', padding: 10 }}
                color={'white'}
              >
                <button className='flex self-center h-4'>
                  <IconCustom name={NameIcons.info} className='text-xl leading-5' />
                </button>
              </Tooltip>
            )}
          </LabelCustom>
        )}
        <Tooltip
          visible={!!messageError}
          trigger={['hover', 'focus']}
          //title={messageError}
          overlay={messageError}
          placement='top'
          overlayClassName='ant-tooltip-content-error'
          overlayInnerStyle={{ backgroundColor: 'red' }}
        >
          <Tooltip overlay={tooltipMessage} overlayClassName='ant-tooltip-content-error' overlayInnerStyle={{ backgroundColor: 'red' }}>
            <div>
              {type === 'default' && (
                <Select
                  size='large'
                  placeholder={placeholder || 'Seleccione un valor'}
                  className={`text-gray-700 font-bold ${className} ${messageError ? 'ant-select-error' : ''}`}
                  style={{ width: 120, ...style }}
                  //open={openPicker}
                  notFoundContent={
                    notFoundContent || (
                      <div className='text-center'>
                        <div>
                          <IconCustom name={NameIcons.fileOpen} />
                        </div>
                        <span>No hay datos</span>
                      </div>
                    )
                  }
                  onChange={handleChange}
                  onFocus={() => handleFocus()}
                  onBlur={() => handleBlur()}
                  showAction={['focus', 'click']}
                  //onClick={() => handleCldwiick()}
                  //value={typeof value === 'object' ? (value.length > 0 ? value.map((val: any) => val.toString()) : value.toString()) : value}
                  value={valCurr}
                  {...{
                    defaultValue,
                    mode,
                    ref,
                    onClick,
                    disabled,
                    showSearch,
                    maxTagCount,
                    open,
                    bordered,
                    allowClear,
                    dropdownRender,
                    getPopupContainer
                  }}
                >
                  {optionAll && (
                    <Option className='text-gray-700 font-bold' key={`Todas`} value={'Todas'} item={'Todas'}>
                      Todas
                    </Option>
                  )}
                  {data &&
                    data.length > 0 &&
                    data.map(({ item, label, value: val }) => (
                      <Option className='text-gray-700 font-bold' key={`${val}-${label}`} value={val} item={item}>
                        {label && label}
                        {!label && val}
                      </Option>
                    ))}
                </Select>
              )}
              {type === 'add' && (
                <Select
                  size='large'
                  style={{ width: 300 }}
                  placeholder='custom dropdown render'
                  className='rounded-md'
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space align='center' style={{ padding: '0 8px 4px' }}>
                        <Input placeholder='Please enter item' onChange={() => console.log('onChange()')} />
                        <Typography.Link onClick={() => console.log('onClick()')} style={{ whiteSpace: 'nowrap' }}>
                          <PlusOutlined /> Add item
                        </Typography.Link>
                      </Space>
                    </>
                  )}
                >
                  {data.map((item, key) => (
                    <Option key={key}>
                      {item} <IconCustom onClick={() => console.log('deleted')} name={NameIcons.deleted} />
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </Tooltip>
        </Tooltip>
      </div>
    );
  }
);

export default SelectCustom;
