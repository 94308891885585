import { ReactNode, useEffect, useState, useContext, useRef } from 'react';
import { PaginationServerOptions } from 'react-data-table-component';
import { PaginationChangePage, PaginationChangeRowsPerPage } from 'react-data-table-component/dist/src/DataTable/types';

import { SizeScreen } from 'configs/Enums';
import { PageMetadata, ResponseApp } from 'configs/Interface';
import { RootContext } from 'context/RootContext';

import InfiniteScrollOrders from 'components/molecules/infiniteScroll/Orders';
import BoardOrders from 'components/molecules/board/BoardOrders';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import Order from 'models/order';
import Store from 'models/store';
import DetailOrderOrganism from './order/Detail';
import WarehousePA from 'models/warehouse';

type ListOrdersOrganismProps = {
  orders: Order[];
  onFilterFather?: (value: { dateStart?: string; dateEnd?: string; deliveryStates?: string[]; warehouse?: WarehousePA[] }) => void;
  title?: ReactNode;
  isFilter?: boolean;
  noHeader?: boolean;
  /** todos los usuarios sin filtro **/
  ordersAll: Order[];
  onFilter?(params: { val?: string[] | string; field?: string; data?: any[] }): Promise<string[]>;
  onSort?: (params: { isSort?: boolean; field?: string; data?: Order[] }) => void;
  flag?: number;
  onOpenEdit?: (params: { field: string; order: Order }) => void;
  noContextMenu?: boolean;
  onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: any[] }) => void;
  isListError?: boolean;
  paginationPerPage?: number;
  paginationResetDefaultPage?: boolean;
  paginationRowsPerPageOptions?: number[];
  paginationServer?: boolean;
  paginationServerOptions?: PaginationServerOptions;
  paginationTotalRows?: number;
  onChangePage?: PaginationChangePage;
  onChangeRowsPerPage?: PaginationChangeRowsPerPage;
  onSelected?: (order: Order) => void;
  noDataComponent?: React.ReactNode;
  onDetail?: (order: Order) => void;
  paramsFilter?: {
    dateStart?: string;
    dateEnd?: string;
    store?: Store;
    deliveryStates?: string[];
    numOrder?: string;
    warehouse?: WarehousePA[];
  };
  meta?: PageMetadata;
  onLoadMore?(params: { page?: number }): Promise<ResponseApp>;
  orderCurrent?: Order;
  onUpdateOrderCurrent?: (val?: Order) => void;
  onUpdate?: (order: Order) => void;
};

const ListOrdersOrganism = ({
  orders,
  noHeader = true,
  onFilter,
  onFilterFather,
  onSort,
  title,
  ordersAll,
  isFilter = true,
  flag,
  onOpenEdit,
  onSelectedRowsChange,
  noContextMenu,
  isListError,
  paginationPerPage,
  paginationResetDefaultPage,
  paginationRowsPerPageOptions,
  paginationServerOptions,
  paginationServer,
  paginationTotalRows,
  onChangeRowsPerPage,
  onChangePage,
  onSelected,
  noDataComponent,
  onDetail,
  paramsFilter,
  meta,
  onLoadMore,
  orderCurrent,
  onUpdateOrderCurrent,
  onUpdate
}: ListOrdersOrganismProps) => {
  const { screenResolution } = useContext(RootContext);
  const [scrollHeightBoard, setScrollHeightBoard] = useState('40px');

  const refContent = useRef<any>(null);

  const resizeTable = () => {
    const divListOrder = document.getElementById('containerListOrder');
    const listOrder = document.getElementById('listOrder');

    console.log('divListOrder ==>', { divListOrder, listOrder });
    if (divListOrder && listOrder) {
      if (divListOrder.clientHeight > 0 && listOrder.clientHeight > 0) {
        const heightDiv = divListOrder.clientHeight - listOrder.clientHeight * 2 - 6;
        console.log('alturas ===>', {
          clientOrderHeigh: divListOrder.clientHeight,
          clientOrderHe: listOrder.clientHeight,
          heightDiv,
          tototal: heightDiv + listOrder.clientHeight
        });
        setScrollHeightBoard(`${heightDiv + listOrder.clientHeight}px`);
      }
    }
  };

  useEffect(() => {
    resizeTable();
  }, []);

  return (
    <MenuSessionContainer
      ref={refContent}
      notStyleContainer
      classNameContainer='flex flex-1 relative h-full px-2'
      className='bg-gray-200'
      {...onDetail}
    >
      {screenResolution <= SizeScreen.lg && (
        <InfiniteScrollOrders scrollRef={refContent} onFilter={onFilterFather} {...{ orders, onDetail, meta, onLoadMore }} />
      )}
      {screenResolution > SizeScreen.lg && (
        <div className='flex flex-1 justify-between flex-col relative'>
          <div className='flex flex-col flex-1 overflow-y-auto'>
            <div id='containerListOrder' className='h-full'>
              <div id='listOrder'>
                <BoardOrders
                  responsive={true}
                  fixedHeader
                  fixedHeaderScrollHeight={scrollHeightBoard}
                  {...{
                    onFilter,
                    noHeader,
                    isFilter,
                    orders,
                    title,
                    onFilterFather,
                    onSort,
                    ordersAll,
                    flag,
                    onOpenEdit,
                    onSelectedRowsChange,
                    noContextMenu,
                    isListError,
                    paginationServer,
                    paginationTotalRows,
                    onChangeRowsPerPage,
                    onChangePage,
                    paginationPerPage,
                    paginationResetDefaultPage,
                    paginationRowsPerPageOptions,
                    paginationServerOptions,
                    onSelected,
                    noDataComponent,
                    onDetail,
                    paramsFilter
                  }}
                />
              </div>
            </div>
          </div>

          {/* leyenda de urgencia */}
          <div className='flex h-7 mt-4 absolute bottom-4 left-4'>
            <div className='flex justify-center items-center'>
              <div className='bg-redarauco h-6 w-6 rounded-md mr-2' /> <div>Urgencia alta</div>
            </div>

            <div className='flex ml-8 justify-center items-center'>
              <div className='bg-secondary h-6 w-6 rounded-md mr-2' /> <div>Urgencia media</div>
            </div>

            <div className='flex ml-8 justify-center items-center'>
              <div className='bg-tertiary h-6 w-6 rounded-md mr-2' /> <div>Urgencia baja</div>
            </div>

            <div className='flex ml-8 justify-center items-center'>
              <div className='bg-white h-6 w-6 rounded-md mr-2' /> <div>Sin urgencia</div>
            </div>
          </div>
        </div>
      )}

      {orderCurrent && (
        <div className='absolute z-10 top-0 bottom-0 left-0 right-0 bg-gray-200'>
          <DetailOrderOrganism
            order={orderCurrent}
            isPage={false}
            goBack={() => (onUpdateOrderCurrent ? onUpdateOrderCurrent(undefined) : undefined)}
            {...{ onUpdate }}
          />
        </div>
      )}
    </MenuSessionContainer>
  );
};

export default ListOrdersOrganism;
