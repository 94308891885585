import { useContext, useEffect, useState } from 'react';
import { PaginationServerOptions } from 'react-data-table-component';
import { PaginationChangePage, PaginationChangeRowsPerPage } from 'react-data-table-component/dist/src/DataTable/types';
import moment from 'moment';

import { AuthContext } from 'context/AuthContext';
//import { RootContext } from 'context/RootContext';

import { PageMetadata } from 'configs/Interface';
import { EnumFileUploadStates, FormatDate } from 'configs/Enums';

import BoardCustom from 'components/molecules/board';
import OrderTableHeader, { OrderTableHeaderOption } from 'components/molecules/headers/OrderTable';

//import { downloadXLSX } from 'helpers/Converts';
import { getDateNow } from 'helpers/Generator';

import Order from 'models/order';
import Store from 'models/store';
import FileOrders from 'models/files/FileOrders';

//import { getOrders, getOrdersByNumOrder, getOrdersByRangeDate, getOrdersByStore } from 'services/app/Order';
import { generatorExcelOrders } from 'services/app/File';
import WarehousePA from 'models/warehouse';
import { convertAllAtCountries } from 'helpers/Converts';
import { DataContext } from 'context/Data';

type BoardOrdersProps = {
  orders: Order[];
  ordersAll?: Order[];
  title?: string | React.ReactNode;
  isFilter?: boolean;
  noHeader?: boolean;
  onFilterFather?: (value: { dateStart?: string; dateEnd?: string; deliveryStates?: string[]; warehouse?: WarehousePA[] }) => void;
  onFilter?(params: { val?: string[] | string; field?: string; data?: any[] }): Promise<string[]>;
  onSort?: (params: { isSort?: boolean; field?: string; data?: any[] }) => void;
  flag?: number;
  onOpenEdit?: (params: { field: string; order: Order }) => void;
  noContextMenu?: boolean;
  onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: any[] }) => void;
  isListError?: boolean;
  paginationPerPage?: number;
  paginationResetDefaultPage?: boolean;
  paginationRowsPerPageOptions?: number[];
  paginationServer?: boolean;
  paginationServerOptions?: PaginationServerOptions;
  paginationTotalRows?: number;
  onChangePage?: PaginationChangePage;
  onChangeRowsPerPage?: PaginationChangeRowsPerPage;
  onSelected?: (order: Order) => void;
  noDataComponent?: React.ReactNode;
  responsive?: boolean;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  onDetail?: (order: Order) => void;
  meta?: PageMetadata;
  paramsFilter?: {
    dateStart?: string;
    dateEnd?: string;
    stores?: Store[];
    deliveryStates?: string[];
    numOrder?: string;
    warehouse?: WarehousePA[];
  };
};

const BoardOrders = ({
  noHeader,
  orders,
  title,
  isFilter,
  onFilterFather,
  flag,
  noContextMenu,
  onSelectedRowsChange,
  paginationPerPage,
  paginationResetDefaultPage,
  paginationRowsPerPageOptions,
  paginationServer,
  paginationServerOptions,
  paginationTotalRows,
  onChangePage,
  onChangeRowsPerPage,
  noDataComponent,
  responsive,
  fixedHeader,
  fixedHeaderScrollHeight,
  onDetail,
  paramsFilter
}: BoardOrdersProps) => {
  const { authStatus, countryUser, countries, stores: storesAll } = useContext(AuthContext);
  const { addUploadFile } = useContext(DataContext);
  //const { showToast } = useContext(RootContext);

  const [data, setData] = useState<Order[]>([]);

  const onExportCSV = async () => {
    console.log('onFilterOrder() ===>', { paramsFilter });
    if (paramsFilter) {
      const { dateStart, dateEnd, stores, deliveryStates } = paramsFilter;
      //const { page, items } = meta;

      const { status, data } = await generatorExcelOrders({
        dateEnd: `${dateEnd} 23:59` || `${getDateNow({})} 29:59`,
        dateStart: `${dateStart} 00:00` || `${getDateNow({})} 00:00`,
        deliveryState: deliveryStates || [],
        store: stores?.length === storesAll.length ? [] : stores || [],
        country: authStatus.user?.isSuperAdminGlobal() ? convertAllAtCountries({ country: countryUser, countries }) : undefined
      });

      console.log('onExportCSV() => { status, data }', { status, data });
      if (status) {
        const fileName = `Pedidos ${moment(dateStart).format(FormatDate.ddmmaaaa)} al ${moment(dateEnd).format(FormatDate.ddmmaaaa)}.xlsx`;

        addUploadFile(
          new FileOrders({
            nameFile: fileName,
            status: EnumFileUploadStates.inProcess,
            id: data.description.processID,
            type: 'download'
          })
        );
      }
    }
  };

  useEffect(() => {
    setData(orders);
  }, [orders, flag]);

  return (
    <BoardCustom
      data={data}
      responsive={responsive}
      columns={Order.headerTable({
        onDetail,
        user: authStatus.user
      })}
      subHeader={isFilter}
      subHeaderComponent={
        <OrderTableHeader
          onFilter={onFilterFather}
          {...{ onExportCSV, orders, paramsFilter }}
          options={[OrderTableHeaderOption.filter, OrderTableHeaderOption.options]}
        />
      }
      persistTableHead
      paginationRowsPerPageOptions={paginationRowsPerPageOptions || [10, 20, 50, 100, 200]}
      paginationComponentOptions={{ selectAllRowsItem: false }}
      {...{
        title,
        noHeader,
        noContextMenu,
        onSelectedRowsChange,
        paginationPerPage,
        paginationResetDefaultPage,
        paginationServer,
        paginationServerOptions,
        paginationTotalRows,
        onChangePage,
        onChangeRowsPerPage,
        noDataComponent,
        fixedHeader,
        fixedHeaderScrollHeight
      }}
    />
  );
};

export default BoardOrders;
