import { NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import { shippingTypeStringToDisplay, stringAlertOrderToStyle, stringDeliveryToIcon, stringStatusDeliveryToColor } from 'helpers/Converts';

import Order from 'models/order';

type OrderCardProps = {
  order?: Order;
  onDetail?: (order: Order) => void;
};

const OrderCard = ({ order, onDetail }: OrderCardProps) => {
  return (
    <div
      className={`w-full flex p-4 flex-col bg-white mb-1 ${order?.data.alertOrder ? 'border-l-4' : ''} ${stringAlertOrderToStyle({
        alertOrder: order?.data.alertOrder,
        type: 'border'
      })}`}
      //style={{ borderBottom: 1, borderBottomColor: 'var(--color-gray-4)', borderBottomStyle: 'solid' }}
      style={{ boxSizing: 'border-box' }}
    >
      <div className={'flex justify-between items-center'}>
        <div className='flex flex-1 justify-between'>
          <p className='text-lg font-normal flex'>
            {`Orden `} <p className='ml-2 text-lg font-bold'>{order?.getDataTable().order?.order}</p>
          </p>

          {/**
          <div className='flex items-center mr-2'>
            <IconCustom name={NameIcons.warehouse} className='text-xs mr-1' />
            <p className={'font-bold text-base'}>{order?.getDataTable().order?.warehouse}</p>
          </div>
**/}
        </div>

        <IconCustom
          name={NameIcons.visibility}
          onClick={() => (onDetail && order ? onDetail(order) : undefined)}
          className='text-xl text-grayarauco'
        />
      </div>

      <div className='flex mt-2'>
        <div className='flex items-center mr-4'>
          <p className='text-xs text-primary'>Fecha ingreso</p>
        </div>

        <div className='flex mr-8 items-center justify-center'>
          <div>
            <IconCustom className='text-xs text-grayarauco' name={NameIcons.calendarMonth} />
          </div>
          <div>
            <p className='text-xs text-grayarauco ml-1'>{order?.getDataTable().order?.creationDate}</p>
          </div>
        </div>

        <div className='flex items-center justify-center'>
          <div>
            <IconCustom className='text-xs text-grayarauco' name={NameIcons.schedule} />
          </div>
          <div>
            <p className='text-xs text-grayarauco ml-1'>{order?.getDataTable().order?.creationTime}</p>
          </div>
        </div>
      </div>

      {/** 
      <div className='flex mt-2'>
        <div className='flex flex-1 items-center'>
          <IconCustom name={NameIcons.warehouse} className='text-xs mr-1' />
          <p className={'font-bold text-base'}>{order?.getDataTable().order?.warehouse}</p>
        </div>
      </div>

      **/}

      <div className='flex flex-row mt-2'>
        <div className='flex flex-2 flex-col'>
          <p className={'font-bold text-base'}>{order?.getDataTable().client?.fullName}</p>
          <p className={'text-xs font-normal text-grayarauco mt-2'}>{order?.getDataTable().client?.phone}</p>
          <p className={'text-xs font-normal text-grayarauco mt-1'}>{order?.getDataTable().client?.email}</p>
        </div>
        <div className='flex flex-1 flex-col'>
          <div className='flex items-center'>
            <IconCustom name={NameIcons.warehouse} className='text-xs mr-1' />
            <p className={'font-bold text-base'}>{order?.getDataTable().order?.warehouse}</p>
          </div>
        </div>
        {/**
         **/}
      </div>

      <div className='flex justify-between mt-2'>
        <div
          className={`px-2 rounded-full text-white flex justify-center items-center ${stringStatusDeliveryToColor(
            order?.getDataTable().status
          )}`}
        >
          <p className='text-center text-xs'>{order?.getDataTable().status}</p>
        </div>
        <div className='flex items-center text-primary'>
          <IconCustom name={stringDeliveryToIcon(order?.getDataTable().typeDelivery)} />
          <div className='flex items-center ml-1'>
            <p className='text-xs'>{shippingTypeStringToDisplay(order?.getDataTable().typeDelivery)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
