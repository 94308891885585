import { useContext, useEffect, useState } from 'react';

import { NameIcons, SizeScreen, TypeDelivery } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import TypeDeliveryDropdown from 'components/molecules/dropDown/TypeDelivery';

import { capitalize, numberAtPrice, reducerDelivery, shippingTypeStringToDisplay } from 'helpers/Converts';

import Invoice, { InvoiceItemModel } from 'models/invoice';
import { RootContext } from 'context/RootContext';

type DeliveryBillCardProps = {
  data?: Invoice;
  classNameContainer?: string;
  type?: TypeDelivery;
};

const DeliveryBillCard = ({ data, classNameContainer, type }: DeliveryBillCardProps) => {
  const [orders, setOrders] = useState(0);
  const [total, setTotal] = useState('$ 0');
  const [avg, setAvg] = useState(0);
  const [deliveries, setDeliveries] = useState<InvoiceItemModel[]>([]);
  const [stateReducer, setStateReducer] = useState<TypeDelivery>();
  const { screenResolution } = useContext(RootContext);

  useEffect(() => {
    const stateReducerNew = type || TypeDelivery.delivery;
    if (data) {
      const ordersNew = stateReducerNew === TypeDelivery.delivery ? data?.data.amountDelivery : data?.data.amountPickup;
      const totalNew = stateReducerNew === TypeDelivery.delivery ? data?.data.totalDelivery : data?.data.totalPickup;
      const avgNew = stateReducerNew === TypeDelivery.delivery ? data?.data.avgTotalDelivery : data?.data.avgTotalPickup;
      setOrders(ordersNew || 0);
      setTotal(numberAtPrice(totalNew));
      setAvg(avgNew || 0);
      //console.log('deliveries ==>', { deliveries, data });
      setDeliveries(data?.data.deliveries?.filter((del: InvoiceItemModel) => reducerDelivery(del.name) === stateReducerNew) || []);
    }
    setStateReducer(stateReducerNew);
  }, [data, type]);

  return (
    <div className={`bg-white p-4 flex flex-col flex-1 justify-between ${classNameContainer}`} style={{ borderRadius: 10 }}>
      <div
        className='flex flex-col p-4'
        style={{
          borderBottomWidth: 0.5,
          borderBottomColor: 'var(--color-gray-2)',
          borderTopWidth: 0.5,
          borderTopColor: 'var(--color-gray-2)',
          borderRightWidth: 0.5,
          borderRightColor: 'var(--color-gray-2)',
          borderLeftWidth: 5,
          borderLeftColor: stateReducer === TypeDelivery.delivery ? 'var(--color-alternative-four)' : 'var(--color-alternative-two)'
        }}
      >
        <div className='flex flex-row justify-between items-center mb-2 border-grayarauco-200' style={{ borderBottomWidth: 3 }}>
          <h3 className={`text-2xl ${stateReducer === TypeDelivery.delivery ? 'text-alternativefour' : 'text-alternativetwo'} font-bold`}>
            {capitalize(stateReducer)}
          </h3>
          <p className={`${stateReducer === TypeDelivery.delivery ? 'text-alternativefour' : 'text-alternativetwo'} text-3xl font-normal`}>
            {orders}
          </p>
        </div>
        <div>
          <p className='text-4xl font-bold'>{total}</p>
        </div>
      </div>

      {stateReducer === TypeDelivery.delivery && deliveries.length > 0 && (
        <div className='flex flex-col sm:flex-row mx-4 my-4'>
          {deliveries.map((delivery: InvoiceItemModel, index: number) => {
            //console.log('delivery ==> ', delivery);
            return (
              <div
                key={delivery.name}
                className={`flex flex-row justify-between items-center flex-1 ${index === 1 ? 'mx-0 px-0 sm:mx-3 sm:px-3' : ''}`}
                style={
                  index === 1
                    ? {
                        borderLeftWidth: screenResolution > SizeScreen.md ? 0.5 : 0,
                        borderColor: 'var(--color-gray-2)',
                        borderRightWidth: screenResolution > SizeScreen.md ? 0.5 : 0,
                        borderBottomWidth: screenResolution <= SizeScreen.md ? 0.5 : 0,
                        borderTopWidth: screenResolution <= SizeScreen.md ? 0.5 : 0
                      }
                    : {}
                }
              >
                <div className='flex flex-col'>
                  <div className='flex flex-row'>
                    <IconCustom className='text-sm text-primary' name={delivery.icon} />
                    <p className='text-primary text-sm ml-1'>{shippingTypeStringToDisplay(delivery.name)}</p>
                  </div>

                  <div className='mt-2'>
                    <p className='text-base font-normal'>{numberAtPrice(delivery?.total)}</p>
                  </div>
                </div>

                <div className='ml-4'>
                  <p className='text-base font-normal'>{delivery?.amount || 0}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <TypeDeliveryDropdown
        classNameContainer='flex-none'
        styleContainer={{ borderWidth: 0.5, borderColor: 'var(--color-gray-2)' }}
        nameIcon={NameIcons.ticket}
        title={`Ticket promedio ${capitalize(stateReducer)}`}
        value={numberAtPrice(avg)}
        breakdowns={deliveries.map(deli => ({
          title: deli.name,
          value: numberAtPrice((deli.avg as number) || 0),
          icon: deli.icon
        }))}
      />
    </div>
  );
};

export default DeliveryBillCard;
