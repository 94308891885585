import { useContext, useEffect, useReducer, useState } from 'react';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import { EnumInvoiceSection, FormatDate, SizeScreen } from 'configs/Enums';

import SelectCustom from 'components/atoms/selects';

import { getDateNow } from 'helpers/Generator';
import { capitalize } from 'helpers/Converts';

import Store from 'models/store';
import TypeOperatorSelect from '../selects/TypeOperator';
import TypeOperator from 'models/store/TypeOperator';
import YearStartAtCurrentSelect from '../selects/YearStartAtCurrent';
import Invoice from 'models/invoice';

type BillingFilterMoleculeProps = {
  dateStart?: string;
  dateEnd?: string;
  paramsFilter?: FilterBillingParams;
  onFilter: (params: FilterBillingParams) => void;
  classNameContainer?: string;
  section: EnumInvoiceSection;
};

enum TypesFilterValue {
  store = 'store',
  typeStore = 'typeStore',
  year = 'year',
  all = 'all'
}

export interface FilterBillingParams {
  store?: Store;
  typeStore?: TypeOperator[];
  year?: number;
  country?: string;
  invoice?: Invoice;
}

const BillingFilterMolecule = ({ dateStart, dateEnd, paramsFilter, onFilter, classNameContainer, section }: BillingFilterMoleculeProps) => {
  const { authStatus, stores } = useContext(AuthContext);
  const { screenResolution } = useContext(RootContext);
  const [firstLoad, setFirstLoad] = useState(false);

  const [dateNow, setDateNow] = useState<string | undefined>();
  const [storesCurrent, setStoresCurrent] = useState<Store[]>();

  const [filter, dispatch] = useReducer(
    (prevState: FilterBillingParams, action: { type: TypesFilterValue; value?: any }) => {
      switch (action.type) {
        case TypesFilterValue.store:
          return {
            ...prevState,
            store: action.value
          };
        case TypesFilterValue.typeStore:
          return {
            ...prevState,
            typeStore: action.value
          };
        case TypesFilterValue.year:
          return {
            ...prevState,
            year: action.value
          };
        case TypesFilterValue.all:
          return {
            ...action.value
          };
        default:
          return {
            ...prevState
          };
      }
    },
    {
      store: paramsFilter?.store || 'Todas',
      typeStore: paramsFilter?.typeStore,
      year: paramsFilter?.year || parseInt(`${getDateNow({ format: FormatDate.yyyy })}`)
    }
  );

  useEffect(() => {
    setDateNow(`${getDateNow({ format: FormatDate.ddmmaaaaAlt })}`);
    if (filter.typeStore) {
      const storeNew = Store.onSort(
        stores?.filter(stor => filter.typeStore?.find((valC: TypeOperator) => valC.data.value === stor.data.typeOperator))
      );
      setStoresCurrent(storeNew);
      dispatch({ type: TypesFilterValue.store, value: 'Todas' });
      //onFilter({ ...filter, store: storeNew[0] });
      onFilter({ ...filter, store: 'Todas' });
    }
  }, [stores]);

  return (
    <div className={`flex flex-col md:flex-row mb-4 bg-white ${classNameContainer}`}>
      {section === EnumInvoiceSection.billingProgress && (
        <div className='flex flex-row mb-4 sm:mb-0'>
          <div className='flex flex-col mr-5 sm:mr-8 justify-center items-start'>
            <h1 className='text-2xl font-bold text-primary'>Vista general</h1>
            <p className='text-xl font-normal text-primary'>{dateNow}</p>
          </div>

          <div className='flex flex-col justify-center border-primary pl-4' style={{ borderLeftWidth: 0.6 }}>
            <div className='mb-2'>
              <span className='text-base'>
                <strong className='mr-1'>Inicio:</strong>
                {dateStart}
              </span>
            </div>

            <div>
              <span className='text-base'>
                <strong className='mr-1'>Cierre:</strong>
                {dateEnd}
              </span>
            </div>
          </div>
        </div>
      )}
      {section === EnumInvoiceSection.historicalBilling && (
        <div className='flex items-center mb-4 sm:mb-0'>
          <p className='text-2xl font-bold text-primary'>
            Listado Histórico {capitalize(filter.store?.data?.brand) || ''} {paramsFilter?.year}
          </p>
        </div>
      )}
      <div className='flex flex-1 flex-col justify-end items-end ml-0 sm:ml-4 sm:flex-row'>
        {!authStatus.user?.isLocatario() && stores && stores.length > 0 && (
          <SelectCustom
            classNameContainer='flex flex-col w-full mr-0 sm:mr-2 sm:w-auto'
            label='Marca'
            placeholder='Selecciona marca'
            showSearch
            style={{ width: screenResolution <= SizeScreen.sm ? '100%' : 204 }}
            data={
              storesCurrent?.map(sto => ({
                label: capitalize(sto.data.brand) || '',
                labelSearch: sto.data.brand || '',
                value: sto.toString() || '',
                item: sto
              })) || []
            }
            getPopupContainer={(triggerNode: any) => {
              console.log('getPopupContainer() => triggerNode', triggerNode);
              return triggerNode;
            }}
            value={filter.store}
            onChange={value => {
              dispatch({ type: TypesFilterValue.store, value: value });
              onFilter({ ...filter, store: value });
              console.log('onChange ==> Marca', { ...filter, store: value });
            }}
            optionAll={true}
          />
        )}

        <TypeOperatorSelect
          classNameContainer='mt-4 sm:mt-0'
          onChange={val => {
            const storCurrent = Store.onSort(stores?.filter(stor => val?.find(valC => valC.data.value === stor.data.typeOperator)));
            setStoresCurrent(storCurrent);
            let newStore: any | Store | undefined;
            if (!firstLoad) {
              newStore = 'Todas';
              setFirstLoad(true);
            } else {
              const storesCur = storCurrent.find(stor => stor.data.brand === filter.store?.data.brand);
              newStore = storesCur || 'Todas';
            }
            const newValue: FilterBillingParams = { ...filter, typeStore: val, store: newStore };
            dispatch({ type: TypesFilterValue.all, value: newValue });
            console.log('onChange ==> operators () ==> { newValue }', { newValue, newStore });
            onFilter(newValue);
          }}
          value={filter.typeStore}
        />

        {section === EnumInvoiceSection.historicalBilling && (
          <YearStartAtCurrentSelect
            value={filter.year}
            className={'ml-2 w-full sm:w-auto sm:ml-2'}
            onChange={val => {
              dispatch({ type: TypesFilterValue.year, value: val });
              console.log('onChange ==> year', { ...filter, year: val });
              onFilter({ ...filter, year: val });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BillingFilterMolecule;
