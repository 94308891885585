import { MouseEventHandler } from 'react';

import { NameIcons } from 'configs/Enums';

type IconCustomProps = {
  name?: NameIcons | string;
  className?: string;
  onClick?: MouseEventHandler<any> | undefined;
  outlined?: boolean;
  lightOutlined?: boolean;
};

const IconCustom = ({ name, className, onClick, outlined = true, lightOutlined }: IconCustomProps) => {
  if (outlined) {
    if (lightOutlined) {
      return (
        <span
          className={`material-symbols-outlined ${className}`}
          style={{ fontVariationSettings: '"FILL" 0, "wght" 100, "GRAD" 0, "opsz" 48' }}
          {...{ onClick }}
        >
          {name}
        </span>
      );
    }
    return (
      <span className={`material-symbols-outlined ${className}`} {...{ onClick }}>
        {name}
      </span>
    );
  }
  return (
    <span className={`material-icons ${className}`} {...{ onClick }}>
      {name}
    </span>
  );
};

export default IconCustom;
