import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'context/AuthContext';

import SelectionButton from 'components/atoms/selects/SelectionButton';

import Delivery from 'models/Delivery';

type TypeDeliverySelectProps = {
  onChange?: (val?: Delivery[]) => void;
  values?: Delivery[];
};

const TypeDeliverySelect = ({ onChange, values }: TypeDeliverySelectProps) => {
  const { typesDelivery } = useContext(AuthContext);

  const [valueCurrent, setValueCurrent] = useState<Delivery[] | undefined>(typesDelivery);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  useEffect(() => {
    if (!firstLoad && typesDelivery && typesDelivery.length > 0) {
      setValueCurrent(typesDelivery);
      setFirstLoad(true);
      if (onChange) {
        onChange(typesDelivery);
      }
    }
    if (values) {
      setValueCurrent(values);
    }
  }, [values, typesDelivery]);

  return (
    <SelectionButton
      items={typesDelivery?.map(typDeli => ({
        value: typDeli.data.name,
        labelSearch: typDeli.data.name,
        label: typDeli.data.name,
        item: typDeli
      }))}
      className={'w-full sm:w-auto'}
      classNameContainer={'w-full sm:w-auto'}
      text={'Tipo de envío'}
      getPopupContainer={(triggerNode: any) => {
        console.log('getPopupContainer() => triggerNode', triggerNode);
        return triggerNode;
      }}
      values={valueCurrent}
      onClose={val => {
        setValueCurrent(val);
        if (onChange) {
          onChange(val);
        }
      }}
    />
  );
};

export default TypeDeliverySelect;
