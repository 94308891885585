import Delivery, { DeliveryServerModel } from 'models/Delivery';

interface WareHouseModel {
  id: string;
  name: string;
  referenceId: string;
  salesChannels: string[];
  delivery?: Delivery[];
}

interface WareHouseServerModel {
  name: string;
  referenceId: string;
  salesChannels: string[];
  id: string;
  delivery?: DeliveryServerModel[];
}

class WareHouse {
  data: WareHouseModel;

  constructor(data: WareHouseModel) {
    this.data = data;
  }

  static formalizeData(data: WareHouseServerModel): WareHouseModel {
    const { name, referenceId, salesChannels, id, delivery } = data;

    return {
      name,
      referenceId,
      salesChannels,
      id,
      delivery: delivery ? delivery.map(deli => new Delivery(Delivery.formalizeData(deli))) : []
    };
  }
}

export default WareHouse;
