import { EnumDeliverySummaryStatus, FormatDate, FormatTime, NameIcons, TypeDelivery } from 'configs/Enums';

import { nameShippingStatusAtIconName, shippingStatusAtNameDisplay, stringAtDate, stringAtTime } from 'helpers/Converts';

export interface ShippingStatusModel {
  dateCreated?: string;
  timeCreated?: string;
  type?: 'statusChange' | 'milestone' | string;
  /** nombre para trabajo interno **/
  name?: string;
  /** nombre en que se muestra al usuario **/
  nameDisplay?: string;
  icon?: string;
  customData?: any;
  /** esta cancelado **/
  isCanceled?: boolean;
  /** si el estado esta en progreso **/
  inProgress?: boolean;
}

export interface ShippingStatusServerModel {
  /** example 2022-06-23T13:22:24.792Z **/
  dateCreated: string;
  userCreated: string;
  type: 'statusChange' | 'milestone' | string;
  name: string;
  icon: string;
  customData: any;
}

class ShippingStatus {
  data: ShippingStatusModel;

  constructor(data: ShippingStatusModel) {
    this.data = data;
  }

  static formalizeData(data: ShippingStatusServerModel): ShippingStatusModel {
    const { dateCreated, type, name, customData } = data;
    let dateC = dateCreated;
    let timeC = dateCreated;

    if (dateCreated) {
      dateC = stringAtDate(dateCreated, FormatDate.ddmmaaaaAlt) || '';
      timeC = stringAtTime(dateCreated, FormatTime.HHmm) || '';
    }

    return {
      dateCreated: dateC,
      timeCreated: timeC,
      type,
      name,
      nameDisplay: shippingStatusAtNameDisplay(name),
      icon: nameShippingStatusAtIconName(shippingStatusAtNameDisplay(name)),
      customData
    };
  }

  static reducerStatus(shippingsStatus?: ShippingStatus[]): ShippingStatus[] {
    try {
      if (shippingsStatus) {
        const resultReduce = (shippingsStatus as any).reduce((prev: any, curre: any) => {
          if (prev.length) {
            if (prev.find((shipS: any) => shipS.data.nameDisplay === curre.data.nameDisplay)) {
              return prev;
            } else {
              return [...prev, curre];
            }
          } else if (prev.data.nameDisplay === curre.data.nameDisplay) {
            return prev;
          } else {
            return [prev, curre];
          }
        });

        console.log('reducerStatus() => resultReduce', resultReduce);
        return resultReduce;
      }
      return [];
    } catch (err) {
      console.log('this.reducerStatus() => err', err);
      return [];
    }
  }

  static fullStatus({ shippingsStatus, typeDelivery }: { shippingsStatus?: ShippingStatus[]; typeDelivery?: string }): ShippingStatus[] {
    try {
      let shippingsStatusFull: ShippingStatus[] = [];
      let isCanceled = false;

      if (shippingsStatus) {
        const indexFind = shippingsStatus?.findIndex(sStatus => sStatus.data.nameDisplay === EnumDeliverySummaryStatus.cancelled);

        if (indexFind !== -1) {
          shippingsStatus.splice(indexFind, 1);
          isCanceled = true;
        }
      }

      //console.log('fullStatus ==> shippingsStatus', shippingsStatus);

      shippingsStatus?.forEach(shippingStatus => {
        shippingStatus.data.isCanceled = isCanceled;
        return shippingStatus;
      });

      if (typeDelivery === TypeDelivery.pickup || typeDelivery === TypeDelivery.pickupStore) {
        shippingsStatusFull = [
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.confirmedOrder, icon: NameIcons.done, isCanceled }),
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.orderPreparation, icon: NameIcons.warehouse, isCanceled }),
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.readyToBeRemoved, icon: NameIcons.whereToVote, isCanceled }),
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.successfulDelivery, icon: NameIcons.person, isCanceled })
        ];
      } else if (
        typeDelivery === TypeDelivery.expressDelivery ||
        typeDelivery === TypeDelivery.nextDay ||
        typeDelivery === TypeDelivery.sameDay ||
        typeDelivery === TypeDelivery.delivery
      ) {
        shippingsStatusFull = [
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.confirmedOrder, icon: NameIcons.done, isCanceled }),
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.orderPreparation, icon: NameIcons.warehouse, isCanceled }),
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.readyToBeRemoved, icon: NameIcons.whereToVote, isCanceled }),
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.onRoute, icon: NameIcons.route, isCanceled }),
          new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.successfulDelivery, icon: NameIcons.person, isCanceled })
        ];
      }

      /** Busqueda de estado de orden no enviada **/
      if (shippingsStatus) {
        const indexFindNotDelivered = shippingsStatus?.findIndex(
          sStatus => sStatus.data.nameDisplay === EnumDeliverySummaryStatus.undelivered
        );

        if (indexFindNotDelivered !== -1) {
          shippingsStatus.splice(indexFindNotDelivered, 1);
          shippingsStatusFull.pop();
          shippingsStatusFull.push(
            new ShippingStatus({ nameDisplay: EnumDeliverySummaryStatus.undelivered, icon: NameIcons.error, isCanceled })
          );
        }
      }
      /**  **/

      if (shippingsStatus) {
        const reducer = ShippingStatus.reducerStatus([...shippingsStatus, ...shippingsStatusFull]);

        //console.log('reducer ==> ', reducer);
        for (let i = reducer.length - 1; i >= 0; i--) {
          if (reducer[i].data.dateCreated) {
            reducer[i].data.inProgress = true;
            break;
          }
        }

        if (reducer) {
          return reducer;
        }
      }
      return shippingsStatusFull;
    } catch (err) {
      console.log('this.reducerStatus() => err', err);
      return [];
    }
  }
}

export default ShippingStatus;
