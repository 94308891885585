import { useContext, useEffect, useState } from 'react';

import { TypeToast } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import OrderAlertsSettingOrganism from 'components/organisms/dashboard/settings/OrderAlerts';

import OrderAlert from 'models/alerts/OrderAlert';

import {
  getOrderAlertsSetting,
  deleteOrderAlertSetting,
  createOrderAlertSetting,
  updateOrderAlertSetting
} from 'services/app/settings/OrderAlert';

type OrderAlertSettingPageProps = {
  title?: string;
};

const OrderAlertSettingPage = ({ title }: OrderAlertSettingPageProps) => {
  const { showToast, hideLoading, showLoading } = useContext(RootContext);
  const { countryUser } = useContext(AuthContext);

  const [orderAlerts, setOrderAlerts] = useState<OrderAlert[]>([]);

  const loadAll = async () => {
    showLoading();
    const { status, data, message } = await getOrderAlertsSetting(countryUser);
    console.log('loadAll() => { status, data, message }', { status, data, message });

    if (status && data) {
      setOrderAlerts(data);
    } else {
      showToast({ text: message || 'No fue posible obtener la información.' });
    }
    hideLoading();
  };

  const onUpdateOrderAlertSetting = async (orderAlert: OrderAlert) => {
    showLoading();
    const { status, message } = await updateOrderAlertSetting(orderAlert);

    if (status) {
      showToast({ text: message || 'Creado con éxito.', type: TypeToast.success });
      await loadAll();
    } else {
      showToast({ text: message || 'No fue posible crear.', type: TypeToast.error });
    }

    hideLoading();
  };

  const onCreateOrderAlertSetting = async (orderAlert: OrderAlert) => {
    showLoading();
    const { status, message } = await createOrderAlertSetting(orderAlert);

    if (status) {
      showToast({ text: message || 'Creado con éxito.', type: TypeToast.success });
      await loadAll();
    } else {
      showToast({ text: message || 'No fue posible crear.', type: TypeToast.error });
    }

    hideLoading();
  };

  const onDeletedOrderAlertSetting = async (orderAlert: OrderAlert) => {
    showLoading();
    const { status, message } = await deleteOrderAlertSetting(orderAlert);

    if (status) {
      showToast({ text: message || 'Creado con éxito.', type: TypeToast.success });
      await loadAll();
    } else {
      showToast({ text: message || 'No fue posible crear.', type: TypeToast.error });
    }

    hideLoading();
  };

  useEffect(() => {
    if (countryUser) {
      loadAll();
    }
  }, [countryUser]);

  return (
    <OrderAlertsSettingOrganism
      {...{ title, orderAlerts, onUpdateOrderAlertSetting, onCreateOrderAlertSetting, onDeletedOrderAlertSetting }}
    />
  );
};

export default OrderAlertSettingPage;
