import React, { useState, useEffect, ReactNode, CSSProperties } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { NameIcons, SizeScreen, TypeToast } from 'configs/Enums';

import ModalQuestion, { ActionOptionModal } from 'components/molecules/modals/ModalQuestion';
import ModalLoading from 'components/portals/ModalLoading';
import IconCustom from 'components/atoms/icons';
import NotificationPopUp, { NotificationPopUpContent, NotificationPopUpProps } from 'components/molecules/modals/Notification';
import useAreInApp from 'helpers/hooks/AreInApp';

type RootContextProviderProps = {
  children?: React.ReactNode;
};

type ToastOptions = {
  text: string | ReactNode;
  type?: TypeToast;
  icon?: NameIcons;
  /** componente footer del toast **/
  action?: {
    text?: string;
    onAction?: () => void;
    className?: string;
  };
  style?: CSSProperties;
};

type ParamsModalQuestion = {
  title: string | ReactNode;
  message?: string | ReactNode;
  contentStyle?: CSSProperties;
  options?: ActionOptionModal[];
  classNameOptions?: string;
};

export interface RootContextValues {
  showToast: (toastOptions: ToastOptions) => void;
  /**
   * Recuerde que solo puede ser usado un modal a la vez.
   */
  showModalQuestion: (params: ParamsModalQuestion) => void;
  showLoading: () => void;
  hideLoading: () => void;
  screenResolution: SizeScreen;
  showNotificationPopUp: (props: NotificationPopUpProps) => void;
  isUsePlatform: boolean;
}

export const RootContext = React.createContext({} as RootContextValues);

const RootContextProvider = ({ children }: RootContextProviderProps) => {
  const [paramsModalQuestion, setParamsModalQuestion] = useState<ParamsModalQuestion>();
  const [isVisibleMQ, setIsVisibleMQ] = useState<boolean>(false);
  const [isVisibleML, setIsVisibleML] = useState<boolean>(false);
  const [screenResolution, setScreenResolution] = useState(SizeScreen.sm);
  const [nodeNotification, setNodeNotification] = useState<React.ReactNode[]>();
  const isUsePlatform = useAreInApp();

  const showToast = (options: ToastOptions) => {
    const { text, type, style } = options;
    if (type === TypeToast.success) {
      toast.success(
        t => {
          return (
            <div className={`rounded-xl flex justify-center items-center`}>
              <div className='absolute top-2 right-2'>
                <IconCustom name={NameIcons.close} className='text-grayarauco-400' onClick={() => toast.dismiss(t.id)} />
              </div>

              <div className='flex justify-center items-center mr-4'>{text}</div>
              {options.action && (
                <button
                  className={`ml-2 py-1 px-2 rounded-md ${options.action.className}`}
                  style={t.style}
                  onClick={() => {
                    toast.dismiss(t.id);
                    if (options && options.action && options.action.onAction) {
                      options.action?.onAction();
                    }
                  }}
                >
                  {options.action.text || 'Cerrar'}
                </button>
              )}
            </div>
          );
        },
        {
          icon: (
            <div className='flex justify-start items-start h-full'>
              <IconCustom className='text-araucogreen text-4xl' name={options.icon || NameIcons.checkCircle} />
            </div>
          )
        }
      );
    } else if (type === TypeToast.info) {
      toast(
        t => {
          return (
            <div className={`rounded-xl flex justify-center items-center`}>
              <div className='absolute top-2 right-2'>
                <IconCustom name={NameIcons.close} className='text-grayarauco-400' onClick={() => toast.dismiss(t.id)} />
              </div>

              <div className='flex justify-center items-center mr-4'>{text}</div>
              {options.action && (
                <button
                  className={`ml-2 py-1 px-2 rounded-md ${options.action.className}`}
                  style={t.style}
                  onClick={() => {
                    toast.dismiss(t.id);
                    if (options && options.action && options.action.onAction) {
                      options.action?.onAction();
                    }
                  }}
                >
                  {options.action.text || 'Cerrar'}
                </button>
              )}
            </div>
          );
        },
        {
          icon: (
            <div className='flex justify-start items-start h-full'>
              <IconCustom className='text- text-4xl' name={options.icon || NameIcons.info} />
            </div>
          ),
          style: {
            backgroundColor: 'var(--color-notification-yellow)',
            color: 'rgba(135, 113, 3, 1)'
          }
        }
      );
    } else if (type === TypeToast.error) {
      toast.error(
        t => {
          return (
            <div className={`rounded-xl flex justify-center items-center`}>
              <div className='absolute top-2 right-2'>
                <IconCustom name={NameIcons.close} className='text-grayarauco-400' onClick={() => toast.dismiss(t.id)} />
              </div>

              <div className='flex justify-center items-center mr-4'>{text}</div>
              {options.action && (
                <button
                  className={`ml-2 py-1 px-2 rounded-md ${options.action.className}`}
                  style={t.style}
                  onClick={() => {
                    toast.dismiss(t.id);
                    if (options && options.action && options.action.onAction) {
                      options.action?.onAction();
                    }
                  }}
                >
                  {options.action.text || 'Cerrar'}
                </button>
              )}
            </div>
          );
        },
        {
          icon: (
            <div className='flex justify-start items-start h-full'>
              <IconCustom className='text-redarauco text-4xl' name={options.icon || NameIcons.error} />
            </div>
          )
        }
      );
    } else {
      toast(
        t => {
          return (
            <div className={`rounded-xl flex justify-center items-center`}>
              <div className='absolute top-2 right-2'>
                <IconCustom name={NameIcons.close} className='text-grayarauco-400' onClick={() => toast.dismiss(t.id)} />
              </div>

              <div className='flex justify-center items-center mr-4'>{text}</div>
              {options.action && (
                <button
                  className={`ml-2 py-1 px-2 rounded-md ${options.action.className}`}
                  style={t.style}
                  onClick={() => {
                    toast.dismiss(t.id);
                    if (options && options.action && options.action.onAction) {
                      options.action?.onAction();
                    }
                  }}
                >
                  {options.action.text || 'Cerrar'}
                </button>
              )}
            </div>
          );
        },
        {
          icon: (
            <div className='flex justify-start items-start h-full'>
              <IconCustom className='text- text-4xl' name={options.icon || NameIcons.info} />
            </div>
          ),
          style
        }
      );
    }
  };

  const showModalQuestion = (paramsModal: ParamsModalQuestion) => {
    setIsVisibleMQ(true);
    console.log('showModalQuestion() => paramsModal', paramsModal);
    setParamsModalQuestion(paramsModal);
  };

  const closeModalQuestion = () => {
    setIsVisibleMQ(false);
    setParamsModalQuestion(undefined);
  };

  const showLoading = () => {
    if (!isVisibleML) {
      setIsVisibleML(true);
    }
  };

  const hideLoading = () => {
    setIsVisibleML(false);
  };

  const defineScreen = ({ innerWidth }: { innerWidth: number; innerHeight: number }) => {
    if (innerWidth <= SizeScreen.sm) {
      setScreenResolution(SizeScreen.sm);
    } else if (innerWidth <= SizeScreen.md) {
      setScreenResolution(SizeScreen.md);
    } else if (innerWidth <= SizeScreen.lg) {
      setScreenResolution(SizeScreen.lg);
    } else if (innerWidth <= SizeScreen.xl) {
      setScreenResolution(SizeScreen.xl);
    } else if (innerWidth <= SizeScreen['2xl']) {
      setScreenResolution(SizeScreen['2xl']);
    } else {
      setScreenResolution(SizeScreen['2xl']);
    }
  };

  const onCloseNotification = (index: number) => {
    const nodes = nodeNotification?.splice(index, 1);
    setNodeNotification(nodes);
  };

  const onShowNotificationPopUp = (props: NotificationPopUpProps) => {
    if (nodeNotification && nodeNotification.length > 0) {
      setNodeNotification([
        ...nodeNotification,
        <NotificationPopUpContent
          key={`${nodeNotification.length + 1}-notification-node`}
          onClose={() => onCloseNotification(nodeNotification.length + 1)}
          {...props}
        />
      ]);
    } else {
      setNodeNotification([<NotificationPopUpContent key={`${0}-notification-node`} onClose={() => onCloseNotification(0)} {...props} />]);
    }
  };

  useEffect(() => {
    defineScreen(window.window);

    addEventListener('resize', (event: any) => {
      //console.log('resize', { width: event.target?.innerWidth, isTop });
      defineScreen(event.target);
    });

    return () => {
      removeEventListener('resize', (event: any) => {
        defineScreen(event.target);
      });
    };
  }, []);

  return (
    <RootContext.Provider
      value={{
        showToast,
        showModalQuestion,
        showLoading,
        hideLoading,
        screenResolution,
        showNotificationPopUp: onShowNotificationPopUp,
        isUsePlatform
      }}
    >
      {children}
      {isVisibleMQ && (
        <ModalQuestion
          closeModal={closeModalQuestion}
          isOpen={isVisibleMQ}
          title={paramsModalQuestion?.title || 'title'}
          options={paramsModalQuestion?.options}
          message={paramsModalQuestion?.message}
          contentStyle={paramsModalQuestion?.contentStyle}
          classNameOptions={paramsModalQuestion?.classNameOptions}
        />
      )}
      <Toaster
        position='bottom-right'
        containerStyle={{ top: 30 }}
        toastOptions={{
          duration: 6000,
          icon: <IconCustom name={NameIcons.info} />,
          style: {
            borderRadius: '16px'
          },
          success: {
            style: {
              backgroundColor: 'var(--color-green-two)',
              color: 'var(--color-green)'
            }
          },
          error: {
            style: {
              backgroundColor: 'var(--color-redarauco-two)',
              color: 'var(--color-redarauco)'
            }
          }
        }}
      />
      {<NotificationPopUp notifications={nodeNotification} />}
      {isVisibleML && <ModalLoading />}
    </RootContext.Provider>
  );
};

export default RootContextProvider;
