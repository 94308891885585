import moment from 'configs/MomentJS';
import { FormatDate, TypeDelivery } from 'configs/Enums';
import { GRAPHICS_COLOR } from 'configs/Constants';

import { myArrayWithNoDuplicates, getMonthByDate, numberAtPrice } from 'helpers/Converts';
import { isAfterDate, isBeforeDate } from 'helpers/Validate';

interface DeliveryQuantity {
  [index: string]: string | number;
}

interface MonthStatistic {
  date: string;
  total: number;
}

interface WeekStatistic {
  date: string;
  total: number;
}

export interface TypeDeliveryStatisticModel {
  type?: TypeDelivery;
  months?: MonthStatistic[];
  // para elminar todo el objeto de aqui para abajo
  weeks?: WeekStatistic[];
  qMonth?: {
    date: string;
    quantity: number;
    shippingQuantity: DeliveryQuantity;
    totalShipping: number;
  }[];
  week?: {
    date: string;
    quantity: number;
    shippingQuantity: DeliveryQuantity;
    avgDispatch: string;
    avgPromInWarehouseDelivered: string;
    levelOfService?: number;
  }[];
  monthToMonthLast?: {
    date: string;
    quantity: number;
    deliveryQuantity: DeliveryQuantity;
    shippingQuantity: DeliveryQuantity;
    levelOfService?: number;
    totalShipping?: number;
  }[];
  monthToMonthLastYear?: {
    date: string;
    quantity: number;
    deliveryQuantity: DeliveryQuantity;
    shippingQuantity: DeliveryQuantity;
    totalShipping: number;
    totalShippingByDelivery: DeliveryQuantity[];
  }[];
  year?: {
    date: string;
    store: DeliveryQuantity;
    district?: { district?: string; value?: number }[];
    timeByDelivery?: DeliveryQuantity[];
    price?: string | number;
  }[];
  yearToActuality?: {
    date: string;
    avgPromInWarehouseDelivered: string;
    effectivity: number;
    store: DeliveryQuantity;
    district?: { district?: string; value?: number }[];
    timeByDelivery: DeliveryQuantity[] | number;
    levelOfService?: number;
    price?: number;
  }[];
}

export interface MountLastYears {
  name: string;
  value: number;
  type: string;
  totalPrice: number;
  label: string;
}

class TypeDeliveryStatistic {
  data: TypeDeliveryStatisticModel;

  constructor(data: TypeDeliveryStatisticModel) {
    this.data = data;
  }

  static formalizeData(data?: any): TypeDeliveryStatisticModel {
    //console.log('PickupStatistic() => data', data);
    let sendData: TypeDeliveryStatisticModel = {};
    if (data) {
      if (data.monthGraphic && data.monthGraphic.length > 0) {
        const months: MonthStatistic[] = [];
        data.monthGraphic.forEach((monthGraphic: any) => {
          for (const month in monthGraphic) {
            months.push({ date: month, total: monthGraphic[month] || 0 });
          }
        });
        sendData = { ...sendData, months };
      }
      if (data.weekGraphic && data.weekGraphic.length > 0) {
        const weeks: WeekStatistic[] = [];

        data.weekGraphic.forEach((weekGraphic: any) => {
          for (const week in weekGraphic) {
            weeks.push({ date: week, total: weekGraphic[week] || 0 });
          }
        });

        sendData = { ...sendData, weeks };
      }
    } else {
      sendData = {
        type: undefined,
        months: [],
        // para elminar
        qMonth: [],
        week: [],
        monthToMonthLast: [],
        monthToMonthLastYear: [],
        year: [],
        yearToActuality: []
      };
    }

    return sendData;
  }

  monthlyEvolution() {
    const { qMonth } = this.data;

    if (qMonth) {
      //console.log(
      //'monthlyEvolution() =>',
      //qMonth.map(qM => ({ quantity: qM.quantity, date: qM.date }))
      //);
      return qMonth.map(qM => ({ quantity: qM.quantity, date: qM.date }));
    }
    return [];
  }

  monthlySales() {
    const { qMonth } = this.data;

    if (qMonth) {
      //console.log(
      //'monthlySales() => week',
      //qMonth.map(qM => ({ date: qM.date, totalShipping: qM.totalShipping }))
      //);
      return qMonth.map(qM => ({ value: qM.totalShipping, name: qM.date }));
    }
    return [];
  }

  monthlyQuantity() {
    const { months } = this.data;

    if (months && months.length > 0) {
      return months.map(month => ({ value: month.total, name: month.date }));
    }
    return [];
  }

  lastMonthlyQuantity() {
    const { months } = this.data;

    if (months && months.length > 0) {
      months.sort((qmA, qmB) => {
        if (isBeforeDate({ dateBase: qmA.date, dateReference: qmB.date, formatRef: FormatDate.mmyyyy, formatBase: FormatDate.mmyyyy })) {
          return -1;
        } else if (
          isAfterDate({ dateBase: qmA.date, dateReference: qmB.date, formatRef: FormatDate.mmyyyy, formatBase: FormatDate.mmyyyy })
        ) {
          return 1;
        }
        return 0;
      });

      const lengthItems = months.length - 1;
      return { name: months[lengthItems].date, value: months[lengthItems].total };
    }
    return { value: 0 };
  }

  numOrdersLast8Weeks() {
    const { weeks, type } = this.data;

    if (weeks) {
      return weeks.map(wk => ({ date: wk.date, value: wk.total, type })).reverse();
    }
    return [];
  }

  averageTimeLast8Week() {
    const { week, type } = this.data;

    if (week) {
      return week
        .map(wk => {
          const duration = moment.duration(wk.avgPromInWarehouseDelivered.replace('d', ''));
          return { date: wk.date, value: Math.round(duration.asHours()), type };
        })
        .sort((mkA, mkB) => {
          if (isBeforeDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyymm, formatBase: FormatDate.yyyymm })) {
            return -1;
          } else if (
            isAfterDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyymm, formatBase: FormatDate.yyyymm })
          ) {
            return 1;
          }
          return 0;
        });
    }
    return [];
  }

  serviceLevelLast8Week() {
    const { week, type } = this.data;

    if (week) {
      return week
        .map(wk => {
          //console.log('serviceLevelLast8Week() => wk', wk);
          return { date: wk.date, value: wk.levelOfService, type };
        })
        .sort((mkA, mkB) => {
          if (isBeforeDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyyww, formatBase: FormatDate.yyyyww })) {
            return -1;
          } else if (
            isAfterDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyyww, formatBase: FormatDate.yyyyww })
          ) {
            return 1;
          }
          return 0;
        });
    }
    return [];
  }

  serviceLevelLastYears() {
    const { yearToActuality, type } = this.data;

    if (yearToActuality) {
      return yearToActuality
        .sort((mkA, mkB) => {
          if (isBeforeDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyymm, formatBase: FormatDate.yyyymm })) {
            return -1;
          } else if (
            isAfterDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyymm, formatBase: FormatDate.yyyymm })
          ) {
            return 1;
          }
          return 0;
        })
        .map(wk => {
          //console.log('serviceLevelLast8Week() => wk', wk);
          return { name: getMonthByDate({ date: wk.date }), value: wk.levelOfService, type };
        });
    }
    return [];
  }

  mountLastYears() {
    const { yearToActuality, type } = this.data;

    if (yearToActuality && yearToActuality.length > 0) {
      return yearToActuality
        .sort((mkA, mkB) => {
          if (isBeforeDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyymm, formatBase: FormatDate.yyyymm })) {
            return -1;
          } else if (
            isAfterDate({ dateBase: mkA.date, dateReference: mkB.date, formatRef: FormatDate.yyyymm, formatBase: FormatDate.yyyymm })
          ) {
            return 1;
          }
          return 0;
        })
        .map(wk => {
          //console.log('serviceLevelLast8Week() => wk', wk);
          return { name: getMonthByDate({ date: wk.date }), value: wk.price, type, totalPrice: wk.price, label: numberAtPrice(wk.price) };
        });
    }
    return [{ name: undefined, value: 0, type: undefined, totalPrice: 0, label: undefined }];
  }

  /* se compara el mes anterio, el actual y el mismo mes pero del year anterior */
  prevVslastYearVsCurrYearComparison() {
    const { monthToMonthLastYear, monthToMonthLast } = this.data;
    let items: any[] = [];

    if (monthToMonthLast) {
      items = [...monthToMonthLast.map(mTML => ({ date: mTML.date, quantity: mTML.quantity }))];
    }
    if (monthToMonthLastYear) {
      items = [...items, ...monthToMonthLastYear.map(mTML => ({ date: mTML.date, quantity: mTML.quantity }))];
    }

    const reducerArray = items.reduce(function (previousValue, currentValue) {
      //console.log('previousValue', { previousValue, currentValue });
      if (!previousValue.find((preV: any) => preV.date === currentValue.date)) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);
    return reducerArray;
  }

  monthlyEvolutionaryWithdrawalTime() {
    const { yearToActuality } = this.data;

    if (yearToActuality) {
      return yearToActuality.map(mTMLY => {
        //console.log('averageTimeLast8Week() => mTMLY', {
        //avgDispatch: mTMLY.avgPromInWarehouseDelivered,
        //time: moment.duration(mTMLY.avgPromInWarehouseDelivered.replace('d', ''))
        //});
        const duration = moment.duration(mTMLY.avgPromInWarehouseDelivered.replace('d', ''));
        return {
          name: getMonthByDate({ date: mTMLY.date }),
          value: Math.round(duration.asHours()),
          label: mTMLY.avgPromInWarehouseDelivered
        };
      });
    }
    return [];
  }

  operatorRankingYearInP() {
    const { year } = this.data;

    const items: any = [];
    if (year && year.length > 0) {
      for (const [store, quantity] of Object.entries(year[0].store)) {
        items.push({ name: store, value: quantity });
      }
    }
    items.sort((a: any, b: any) => {
      if (a.value > b.value) {
        return -1;
      } else if (a.value < b.value) {
        return 1;
      }
      return 0;
    });

    const firstFive = items.splice(0, 5);
    let otherStore: any;

    if (items && items.length > 0) {
      const totalOther = items.map((item: any) => item.value).reduce((prev: any, curr: any) => (prev || 0) + (curr || 0));
      otherStore = { name: 'Otros', value: totalOther };
      const itemsReducer = [...firstFive, otherStore];
      itemsReducer.forEach((itemR, index) => {
        itemR.color = GRAPHICS_COLOR.barPrimary[index];
      });
      return itemsReducer;
    }

    firstFive.forEach((fF: any, index: number) => {
      fF.color = GRAPHICS_COLOR.barPrimary[index];
    });
    return firstFive;
    //console.log('operatorRankingYearInP() => items', items);
  }

  operatorRankingPerMonth({ isHeader }: { isHeader?: boolean }) {
    const { yearToActuality } = this.data;

    if (yearToActuality && yearToActuality.length > 0) {
      if (isHeader) {
        let header: string[] = [];

        yearToActuality.forEach(yTA => {
          for (const key in yTA.store) {
            header = [...header, key];
          }
        });
        const reduceHeader = myArrayWithNoDuplicates(header);
        //console.log('operatorRankingPerMonth() => header', { header, reduceHeader });
        return reduceHeader;
      }
      const items: any = [];

      yearToActuality.forEach(yTA => {
        if (yTA && yTA.store && Object.entries(yTA.store).length > 0) {
          for (const store in yTA.store) {
            items.push({ date: getMonthByDate({ date: yTA.date, format: FormatDate.yyyymm }), value: yTA.store[store], type: store });
          }
        }
      });

      return items;
    }
    return [];
  }

  getMonthLast() {
    const { monthToMonthLast, qMonth } = this.data;

    if (monthToMonthLast && monthToMonthLast.length > 0) {
      if (qMonth && qMonth.length > 0) {
        const qMFind = qMonth.find(qM => qM.date === monthToMonthLast[0].date);
        monthToMonthLast[0].totalShipping = qMFind?.totalShipping || 0;
      }
      return monthToMonthLast[0];
    }
  }

  /****
   * only delivery
   **/
  districtRankingYearInP() {
    const { year } = this.data;

    const items: any = [];
    if (year && year.length > 0 && year[0].district) {
      //for (const district in year[0].district) {
      //items.push({ name: district || '', value: district.value });
      //}
      year[0].district.forEach(dis => {
        items.push({ name: dis.district || '', value: dis.value });
      });
    }

    items.sort((a: any, b: any) => {
      if (a.value > b.value) {
        return -1;
      } else if (a.value < b.value) {
        return 1;
      }
      return 0;
    });

    items.forEach((fF: any, index: number) => {
      fF.color = GRAPHICS_COLOR.barPrimary[index];
    });
    return items;
    //console.log('operatorRankingYearInP() => items', items);
  }

  districtRankingPerMonth({ isHeader }: { isHeader?: boolean }) {
    const { yearToActuality } = this.data;

    if (yearToActuality && yearToActuality.length > 0) {
      if (isHeader) {
        let header: string[] = [];

        yearToActuality.forEach(yTA => {
          for (const key in yTA.district) {
            const district = key || 'OTROS';
            header = [...header, district];
          }
        });
        const reduceHeader = myArrayWithNoDuplicates(header);
        console.log('operatorRankingPerMonth() => header', { header, reduceHeader });
        return reduceHeader;
      }

      const items: any = [];
      yearToActuality.forEach(yTA => {
        const districts = yTA.district;

        if (districts && districts.length > 0) {
          districts.forEach(dis => {
            items.push({ date: getMonthByDate({ date: yTA.date }), value: dis.value, type: dis.district });
          });
        }
      });
      console.log('districtRankingPerMonth() ==> items', items);
      return items;
    }
    return [];
  }

  /* Nivel de servicio evolutivo mensual general y aperturado por tipos de delivery */
  lvlGeneralEvoSerByTypeDelivery({ isHeader }: { isHeader?: boolean }) {
    const { yearToActuality } = this.data;

    if (yearToActuality && yearToActuality.length > 0) {
      if (isHeader) {
        let header: string[] = [];

        yearToActuality.forEach(yTA => {
          if (typeof yTA.timeByDelivery !== 'number') {
            yTA.timeByDelivery.forEach(item => {
              const delivery = `${item.delivery}`;
              header = [...header, delivery];
            });
          }
        });
        const reduceHeader = myArrayWithNoDuplicates(header);
        console.log('operatorRankingPerMonth() => header', { header, reduceHeader });
        return reduceHeader;
      }

      return yearToActuality.map(yTA => {
        let deliveries: any = [];
        if (typeof yTA.timeByDelivery !== 'number') {
          yTA.timeByDelivery.forEach(item => {
            const delivery = `${item.delivery}`;
            const duration = moment.duration(`${item.avgPromInWarehouseDelivered}`.replace('d', ''));
            deliveries = {
              ...deliveries,
              [delivery]: Math.round(duration.asHours()),
              avgPromInWarehouseDelivered: item.avgPromInWarehouseDelivered
            };
          });
        }

        console.log('yearToActuality() => yTA', { yTA, deliveries });
        return {
          date: yTA.date,
          ...deliveries
        };
      });
    }
    return [];
  }

  getAverageTicket() {
    try {
      const { year } = this.data;
      let price: string | number = 0;

      if (year && year.length > 0) {
        price = year[0].price || 0;
      }

      return price;
    } catch (err) {
      console.log('getAverageTicket() => err', err);
      return 0;
    }
  }
}

export default TypeDeliveryStatistic;
