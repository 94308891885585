import Logo from 'assets/Logo.png';
import LogoWithoutText from 'assets/LogoWithoutText.png';
import DataJSON from 'assets/jsons/data.json';
import packageJson from '../../package.json';
import FlagChileSvg from 'assets/flags/FlagChile.svg';
import FlagColombiaSvg from 'assets/flags/FlagColombia.svg';
import FlagPeruSvg from 'assets/flags/FlagPeru.svg';
import GlobeWorldPng from 'assets/flags/GlobeWorld.png';
import AnimationCheckDisabled from 'assets/lottieFile/AnimationCheckDisabled.json';
import AnimationCheck from 'assets/lottieFile/AnimationCheck.json';

import { hashCommit, hashDate } from 'hashElement.json';

/** url de la api **/
export const URL_API = process.env.REACT_APP_API_URL;
export const URL_JANIS = process.env.REACT_APP_JANIS_BASE_URL;
export const URL_JANIS_ORDER_DETAIL = (process.env.REACT_APP_JANIS_BASE_URL || '') + process.env.REACT_APP_JANIS_ORDER_DETAIL_URL;
export const URL_API_TWO = process.env.REACT_APP_API_TWO_URL;
export const URL_WEB_SOCKET = process.env.REACT_APP_WEB_SOCKET_URL;
export const SURVEY_URL = process.env.REACT_APP_SURVEY_URL;
export const URL_API_TEST = 'http://localhost:3200/';
//export const URL_API = 'https://agzhm4gwv5.execute-api.us-east-1.amazonaws.com/dev/';

export const APP_NAME = 'Arauco Hub';

export const KEY_TOKEN = process.env.REACT_APP_KEY_TOKEN || '';
export const KEY_TOKEN_EXPIRED_ID = 't0k3nExp1d';
export const KEY_COUNTRY_SESSION = 'c0untryS3ssion';

export const IS_MOBILE = navigator.userAgent.toUpperCase().indexOf('MOBILE') !== -1 ? true : false;
export const IS_IOS = navigator.userAgent.indexOf('iPhone') !== -1 ? true : false;

export const APP_VERSION = `${packageJson.version}(${hashCommit})`;
export const APP_DEPLOYMENT_DATE = `${hashDate}`;

export const ASSETS = {
  logo: Logo,
  logoWithoutText: LogoWithoutText,
  data: DataJSON,
  flagColombiaSvg: FlagColombiaSvg,
  flagChileSvg: FlagChileSvg,
  flagPeruSvg: FlagPeruSvg,
  globeWorldPng: GlobeWorldPng,
  animationCheckDisabled: AnimationCheckDisabled,
  animationCheck: AnimationCheck
};

export const THEME_DEFAULT = {
  graphic: ['#0099FF', '#1422CC', '#3D4499', '#FF9540', '#CC4B14', '#14CC70'],
  default: {
    primary: '#00379B',
    araucogreen: '#6E9B3C',
    araucored: '#BE231E',
    araucoblue: '#00379B',
    araucoblueTwo: '#154BAE'
  }
};

export const GRAPHICS_COLOR = {
  barPrimary: ['#6E9B3C', '#154BAE', '#7096DC', '#246C74', '#C4C4C4', '#743766']
};

export const VALIDATE_CONSTRAINTS = {
  alphanumeric: {
    format: {
      pattern: '^[a-zA-Z0-9]+$',
      message: 'Solo se admiten letras y números.'
    }
  },
  textOnly: {
    format: {
      pattern: '[a-zA-Z]+',
      flags: 'i',
      message: 'Solo se admite texto.'
    }
  },
  containsUppercaseLowercase: {
    format: {
      pattern: '^.*(?=.*[a-z])(?=.*[A-Z]).*$',
      message: 'Solo se admite texto.'
    }
  },
  containsNumber: {
    format: {
      pattern: '^.*(?=.*[0-9]).*$',
      message: 'Solo se admite texto.'
    }
  },
  containsSpecialCharacters: {
    format: {
      pattern: '^.*(?=.*[!¡@#$%^&*-._]).*$',
      message: 'Hay caracteres especiales.'
    }
  },
  name: {
    format: {
      pattern: '^[a-zA-ZÀ-ÿ\u00f1\u00d1\u0020]+([a-zA-ZÀ-ÿ\u00f1\u00d1\u0020]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\u0020]+$',
      flags: 's',
      message: 'No aplica'
    }
  },
  password: {
    format: {
      pattern: '^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$',
      message: 'La contraseña debe tener mínimo 8 caracteres, tener mínimo una letra mayúscula, una minúscula y un número.'
    }
  },
  onlyNumber: {
    numericality: {
      onlyInteger: true,
      notInteger: 'Solo son permitidos los valores enteros.',
      greaterThan: 1,
      notGreaterThan: 'No menor a 1.',
      noStrings: false
    }
  },
  /** numero positivos **/
  onlyNumberPositive: {
    numericality: {
      onlyInteger: true,
      notInteger: 'Solo son permitidos los valores enteros.',
      greaterThan: 0,
      notGreaterThan: 'Solo valores positivo.',
      noStrings: false
    }
  },
  price: {
    numericality: {
      onlyInteger: false,
      //notInteger: 'Solo son permitidos los valores enteros.',
      greaterThan: -1,
      notGreaterThan: 'No menor a 0.',
      noStrings: false
    }
  },
  email: {
    email: {
      message: 'Debe tener @ y .'
    }
  },
  phone: {
    numericality: {
      onlyInteger: true,
      notInteger: 'Solo son permitidos los valores enteros.',
      notValid: 'Tiene que ser un valor numérico.',
      greaterThan: 999999,
      notGreaterThan: 'Longitud minima es de 7 dígitos.',
      noStrings: false
    }
  },
  documentNum: (typeDocument?: string, country?: string) => {
    //console.log('documentNum() ==>', { typeDocument, country });
    const typeD = typeDocument?.toUpperCase().trim();
    if (typeDocument === 'pasaporte' || typeDocument === 'Pasaporte') {
      return {
        format: {
          pattern: '^[A-Za-z0-9]+$',
          message: 'Solo se admiten caracteres alfanuméricos.'
        }
      };
    } else if (typeD === 'CE' || typeD === 'CC' || typeD === 'CI' || typeD === 'RUT') {
      //console.log('documentNum() ==>', typeDocument);
      if (country === 'CL') {
        return {
          format: {
            pattern: '^([0-9]+[-|‐]{1}[0-9kK]{1})|([0-9]+[kK]{1})|([0-9]{7,10})$',
            message: 'El numero documento no es valido.'
          }
        };
      }
      return {
        numericality: {
          onlyInteger: true,
          notInteger: 'Solo son permitidos los valores enteros.',
          greaterThan: 999999,
          notGreaterThan: 'No menor a 6 dígitos.',
          lessThan: 999999999999,
          notLessThan: 'No mayor a 12 dígitos',
          notValid: 'Solo valores numérico.'
        }
      };
    }
    return {
      numericality: {
        onlyInteger: true,
        notInteger: 'Solo son permitidos los valores enteros.',
        greaterThan: 999999,
        notGreaterThan: 'No menor a 6 dígitos.',
        notValid: 'Solo valores numérico.'
      }
    };
  },
  time: {
    hourAndMinutes: (value?: string) => {
      console.log('hourAndMinutes() => value', value);
      if (value) {
        const countTrue: boolean[] = [];
        const splitDuration = value.split(':');
        if (splitDuration && splitDuration.length > 1) {
          const seconds = parseInt(splitDuration[1]);
          const minutes = parseInt(splitDuration[0]);
          //console.log('hourAndMinutes() ==>', { seconds, minutes });

          if (seconds !== undefined) {
            if (seconds >= 0 && seconds <= 59) {
              countTrue.push(true);
            } else {
              countTrue.push(false);
            }
          } else {
            countTrue.push(false);
          }
          if (minutes !== undefined) {
            if (minutes >= 0) {
              countTrue.push(true);
            } else {
              countTrue.push(false);
            }
          } else {
            countTrue.push(false);
          }
        }
        //console.log('hourAndMinutes() ==>', { countTrue, result: countTrue.length > 0 && countTrue.every(conTrue => conTrue) });
        return countTrue.length > 0 && countTrue.every(conTrue => conTrue);
      }
      return false;
    }
  }
};

/** tiempo en diferentes timeout del app **/
export const TimeOut = {
  /** time out de los inputs de busqueda por servidor para no saturar **/
  search: 800
};

export const MonthsOfYears = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];
