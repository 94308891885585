import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthContext } from 'context/AuthContext';

import RoutersIn from 'navigations/in';
import RoutersOut from 'navigations/out';

import LoadingPage from 'pages/Loading';

const NavigationContainer = () => {
  const { authStatus } = useContext(AuthContext);

  if (authStatus.isLoading) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoadingPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      {authStatus.isAuthenticated && <RoutersIn />}
      {!authStatus.isAuthenticated && <RoutersOut />}
    </BrowserRouter>
  );
};

export default NavigationContainer;
