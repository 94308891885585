import { useContext } from 'react';

import { NameIcons, SizeScreen } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import Invoice from 'models/invoice';
import BoardCustom from '.';
import IconCustom from 'components/atoms/icons';

import { numberAtNumberH, numberAtPrice } from 'helpers/Converts';

type InvoiceBoardProps = {
  data: Invoice[];
  onDetail: (invoice: Invoice) => void;
  onDownload: (invoice: Invoice) => void;
  notDownload?: boolean;
};

const InvoiceBoard = ({ data, onDetail, onDownload, notDownload }: InvoiceBoardProps) => {
  const { screenResolution } = useContext(RootContext);
  return (
    <div>
      {screenResolution > SizeScreen.md && (
        <BoardCustom {...{ data }} pagination={false} columns={Invoice.headerTable({ onDetail, onDownload, notDownload })} />
      )}
      {screenResolution <= SizeScreen.md && (
        <div className='mt-2 sm:mt-4 flex flex-col mb-4'>
          {data &&
            data.length > 0 &&
            data.map((dat, index) => {
              if (index !== data.length - 1) {
                return (
                  <div key={dat.data.dateStart} className='bg-white p-4' style={{ marginBottom: 1 }}>
                    <div className='flex flex-row justify-between'>
                      <p>{`${dat.data.dateStartDisplayBoard} - ${dat.data.dateEndDisplayBoard}`}</p>
                      <IconCustom name={NameIcons.visibility} onClick={() => onDetail(dat)} className='text-grayarauco-400 text-xl' />
                    </div>

                    <div className='flex justify-between items-center mt-4'>
                      <div className='flex flex-row justify-center items-center'>
                        <IconCustom className='text-primary text-base' name={NameIcons.pickup} />
                        <p className='text-sm text-primary ml-1'>Pickup</p>
                        <p className='text-base text-black ml-2'>{numberAtNumberH(dat.data.amountPickup)}</p>
                      </div>

                      <div className='flex flex-row justify-center items-center'>
                        <IconCustom className='text-primary text-base' name={NameIcons.deliveryExpress} />
                        <div className='flex justify-center items-center'>
                          <p className='text-sm text-primary ml-1'>Delivery express</p>
                        </div>
                        <div className='flex justify-center items-center'>
                          <p className='text-base text-black ml-2'>{numberAtNumberH(dat.data.amountDelivery)}</p>
                        </div>
                      </div>
                    </div>

                    <div className='mt-4 flex flex-row divide-grayarauco-200' style={{ borderBottomWidth: 0.5 }}>
                      <p className='mr-1 text-base'>Precio:</p>
                      <p className='text-base'>{numberAtPrice(dat.data.total)}</p>
                    </div>

                    {!notDownload && (
                      <div className='flex justify-end items-center'>
                        <IconCustom name={NameIcons.download} className='text-xl text-grayarauco-400' onClick={() => onDownload(dat)} />
                      </div>
                    )}
                  </div>
                );
              }
              return (
                <div key={dat.data.dateEnd} className='flex flex-col bg-araucogreen p-4'>
                  <div className='flex items-center'>
                    <p className='text-base text-white'>Delivery:</p>
                    <p className='ml-1 text-base text-white'>{numberAtNumberH(dat.data.amountDelivery)}</p>
                  </div>

                  <div className='flex items-center'>
                    <p className='text-base text-white'>Pickup:</p>
                    <p className='ml-1 text-base text-white'>{numberAtNumberH(dat.data.amountPickup)}</p>
                  </div>

                  <div className='mt-4 flex flex-row items-center'>
                    <p className='text-base font-bold text-white'>Precio:</p>
                    <p className='ml-1 text-base font-bold text-white'>{numberAtPrice(dat.data.total)}</p>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default InvoiceBoard;
