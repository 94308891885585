import { EnumCountries, EnumTypeDownloadFile, EnumTypeFile } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import { dataURLtoFile, downloadBase64ToFile, fileToBase64 } from 'helpers/Converts';

interface HandbookModel {
  country?: EnumCountries | string;
  type?: EnumTypeDownloadFile;
  file?: File;
  url?: string;
  base64?: string;
  name?: string;
  format?: EnumTypeFile;
  nameFull?: string;
  title?: string;
}

class Handbook {
  data: HandbookModel;

  constructor(data: HandbookModel) {
    this.data = data;
  }

  sendServerFetch() {
    const { file, type, country } = this.data;
    if (file && type && country) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('type', type);
      formData.append('country', country);

      return formData;
    }
  }

  static async formalizeData(data: HandbookModel): Promise<HandbookModel> {
    const { base64, name, format } = data;
    let file: File | undefined;
    const { data: dataFile, status } = await dataURLtoFile({ dataurl: base64 || '', filename: name || '', type: format || '' });
    if (status) {
      file = dataFile;
    }
    return {
      ...data,
      file,
      nameFull: `${name}.${format}`
    };
  }

  async sendServer() {
    const { file, type, country } = this.data;

    if (file && type && country) {
      const base64Convert = await fileToBase64(file);
      console.log('sendServer() ==>', { base64Convert: (base64Convert as string).replace('::', ':').replace(';;', ';'), file });

      return {
        country,
        type,
        base64to: (base64Convert as string).replace('::', ':').replace(';;', ';')
      };
    }
  }

  deltedFile() {
    this.data.file = undefined;
  }

  updateFile(val?: File) {
    this.data.file = val;
  }

  updateInfo(val: HandbookModel) {
    this.data = val;
  }

  async download(): Promise<ResponseApp> {
    try {
      const { base64, name, format } = this.data;

      let onlyBase64 = base64;
      if (format === EnumTypeFile.pdf) {
        onlyBase64 = onlyBase64?.replace('data:application/pdf;base64,', '');
      } else if (format === EnumTypeFile.xls) {
        onlyBase64 = onlyBase64?.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '');
      }
      console.log('download() =>', { base64, name, format, onlyBase64 });
      await downloadBase64ToFile({ data: onlyBase64, name: name || 'archivo', type: format || 'xls' });
      return { status: true, message: 'Archivo descargado con exito.' };
    } catch (err) {
      return { status: false, message: `No fue posible descargar el archivo: ${(err as Error).message}` };
    }
  }
}

export default Handbook;
