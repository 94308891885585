import React, { useContext, useEffect, useState } from 'react';

import { RootContext } from 'context/RootContext';

import { ResponseApp } from 'configs/Interface';
import { EnumCountries, EnumTypeDownloadFile, TypeToast } from 'configs/Enums';
import { configNumeralLocal } from 'configs/NumeralJS';

import useSessionTime from 'helpers/hooks/SessionTime';

import { setTokenSession, getTokenSession, removeTokenSession, setCountrySession, getCountrySession } from 'storages/Session';
import Delivery, { DeliveryServerModel } from 'models/Delivery';
import Dimension, { DimensionServerModel } from 'models/Dimension';
import DownloadFile from 'models/file/DownloadFile';
import Store from 'models/store';
import UserRole from 'models/user/UserRole';
import User from 'models/User';
import DocumentType, { DocumentTypeServerModel } from 'models/user/DocumentType';

import { getDownloadFiles } from 'services/app/File';
import { getShop, getTypesOperator } from 'services/app/Store';
import { getOrdersFailed, getStatusOrder } from 'services/app/Order';
import { getInfoGeneral, getInfoUserSession, getRolesUser } from 'services/app/User';
import { signInApp, recoverPassword, changePassword, validateToken, restorePassword } from 'services/app/Auth';
import TypeOperator from 'models/store/TypeOperator';
import Country from 'models/Country';
import { getInfoToCountry } from 'services/app/Country';

export interface AuthStatus {
  accessToken?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
}

export interface DownloadFiles {
  template?: DownloadFile;
  handbook?: DownloadFile;
  handbookUnit?: DownloadFile;
}

export interface AuthContextValues {
  authStatus: AuthStatus;
  setAuthStatus: (authStatus: AuthStatus) => void;
  typesDocument: DocumentType[];
  typesDelivery: Delivery[];
  dimensions: Dimension[];
  signIn(form: { email: string; password: string }): Promise<ResponseApp>;
  signOut(): boolean;
  recoverPasswordUser(email: string): Promise<boolean>;
  changePasswordUser(formChangePassword: { password: string; token: string }): Promise<ResponseApp>;
  commerceId?: string;
  downloadFiles?: DownloadFiles;
  roles?: UserRole[];
  stores: Store[];
  countryUser?: string;
  countries?: Country[];
  onUpdateCountryUser: (value?: string) => void;
  deliveryStates: string[];
  loadAllDownloadFiles: () => void;
  numFailedOrders: number;
  onUpdateNumFailedOrders: (val?: number) => void;
  /** los tipos de operadores de tienda (etailer, tienda, dummy) **/
  typesOperator: TypeOperator[];
  onUpdateTypeOperator: () => void;
  onUpdateStoreSession: (store?: Store) => void;
  storeSession?: Store /** funcion que agrega una card cuando se carga un archivo y se espera que se procese **/;
  restorePasswordUser(formChangePassword: { password: string; token: string }): Promise<ResponseApp>;
  updateTokenAccessAndLogin: (params: { token?: string }) => void;
  dateExpiredPassword: string;
  onUpdateNotification: () => void;
  flagUpdateNotification: number;
}

export const AuthContext = React.createContext({} as AuthContextValues);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { showToast, showLoading, hideLoading } = useContext(RootContext);
  const [typesDocument, setTypesDocument] = useState<DocumentType[]>([]);
  const [typesDelivery, setTypesDelivery] = useState<Delivery[]>([]);
  const [dimensions, setDimensions] = useState<Dimension[]>([]);
  const [commerceId, setCommerceId] = useState<string>();
  const [downloadFiles, setDownloadFiles] = useState<DownloadFiles>();
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [stores, setStores] = useState<Store[]>([]);
  const [countryUserSession, setCountryUserSession] = useState<string>();
  const [deliveryStates, setDeliveryStates] = useState<string[]>([]);
  const [authStatus, setAuthStatus] = useState<AuthStatus>({
    accessToken: undefined,
    isAuthenticated: false,
    isLoading: true,
    user: undefined
  });
  const [numFailedOrders, setNumFailedOrders] = useState<number>(0);
  const [typesOperator, setTypesOperator] = useState<TypeOperator[]>([]);
  const [storeSession, setStoreSession] = useState<Store>();
  const [dateExpiredPassword, setDateExpiredPassword] = useState<string>('');
  const [flagUpdateNotification, setFlagUpdateNotification] = useState<number>(0);
  const [countries, setCountries] = useState<Country[]>();

  const onUpdateNotification = () => {
    setFlagUpdateNotification(flagUpdateNotification + 1);
  };

  const onUpdateTypeOperator = async () => {
    showLoading();
    const { data, status } = await getTypesOperator();
    console.log('onUpdateTypeOperator() ==> { data, status }', { data, status });

    if (status) {
      setTypesOperator(data);
    } else {
      setTypesOperator([]);
    }

    hideLoading();
  };

  const singOutNotReturn = () => {
    showLoading();
    try {
      setAuthStatus({
        ...authStatus,
        accessToken: undefined,
        isAuthenticated: false,
        isLoading: false,
        user: undefined
      });
      removeTokenSession();
      showToast({ text: 'Su sesión a caducado.', type: TypeToast.info });
      hideLoading();
    } catch (err) {
      hideLoading();
    }
  };

  useSessionTime({ onActionSession: singOutNotReturn });

  const loadStores = async (country?: string) => {
    try {
      if (country) {
        const { data, status } = await getShop(country);
        if (status) {
          setStores(data);
        } else {
          setStores([]);
        }
      }
    } catch (err) {
      setStores([]);
    }
  };

  const onUpdateNumFailedOrders = (val?: number) => {
    if (val) {
      setNumFailedOrders(val);
    } else {
      setNumFailedOrders(0);
    }
  };

  const loadOrdersfailed = async (country?: string) => {
    const { status, data } = await getOrdersFailed({ country: country || countryUserSession });
    if (status) {
      setNumFailedOrders(data.orders.length);
    } else {
      setNumFailedOrders(0);
    }
  };

  const loadDownloadFiles = async ({ country, typeDFile }: { country: EnumCountries | string; typeDFile: EnumTypeDownloadFile }) => {
    try {
      const { status, data } = await getDownloadFiles({ country, typeDFile });

      if (status) {
        return data && data.length > 0 ? data[0] : undefined;
      } else {
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  };

  const loadAllDownloadFiles = async (country?: string) => {
    showLoading();
    const template = await loadDownloadFiles({
      country: country || countryUserSession || EnumCountries.cl,
      typeDFile: EnumTypeDownloadFile.template
    });
    const handbook = await loadDownloadFiles({
      country: country || countryUserSession || EnumCountries.cl,
      typeDFile: EnumTypeDownloadFile.handbook
    });
    const handbookUnit = await loadDownloadFiles({
      country: country || countryUserSession || EnumCountries.cl,
      typeDFile: EnumTypeDownloadFile.handbookManualUnitLoading
    });
    setDownloadFiles({ template, handbook, handbookUnit });
    hideLoading();
  };

  const loadInfoToCountry = async (country?: string) => {
    try {
      const { data, status } = await getInfoToCountry({ country });
      console.log('loadInfoToCountry() ==> { data, status }', { data, status });

      if (status) {
        setTypesDelivery(data.delivery?.map((deliv: DeliveryServerModel) => new Delivery(Delivery.formalizeData(deliv))));
        setTypesDocument(data.documents?.map((doc: DocumentTypeServerModel) => new DocumentType(DocumentType.formalizeData(doc))));
        setDimensions(data.dimensions.map((dimension: DimensionServerModel) => new Dimension(Dimension.formalizeData(dimension))));
      }
    } catch (err) {
      console.log('loadInfoToCountry() => err', { err });
      setTypesDelivery([]);
      setTypesDocument([]);
      setDimensions([]);
    }
  };

  const onUpdateCountrySession = (value?: string) => {
    console.log('onUpdateCountrySession() => value', value);
    loadStores(value);
    loadInfoToCountry(value);
    loadOrdersfailed(value);
    setCountrySession(value);
    setCountryUserSession(value);
  };

  /** actuliza la tienda que el usuario bodega va usar para carga pedidos **/
  const onUpdateStoreSession = async (storeNew?: Store) => {
    console.log('onUpdateStoreSession() ==> storeNew', storeNew);
    setStoreSession(storeNew);
    if (storeNew && storeNew?.data.country) {
      const cty = (storeNew.data.country as Country).data.value;
      onUpdateCountrySession(cty);
      authStatus.user?.updateCountry(cty);
      authStatus.user?.updateCommerceId(storeNew.data.commerceId);
      setAuthStatus(authStatus);
      await loadAllDownloadFiles(cty);
      setTypesDelivery(storeNew.data.typesDelivery || []);
    }
  };

  const loadGeneralInf = async (user: User) => {
    try {
      const { status, data } = await getInfoGeneral(user);
      console.log('loadGeneralInf() ==>', { status, data });
      if (status) {
        setTypesDocument(data.documents);
        setTypesDelivery(data.deliveries);
        setDimensions(data.dimensions);
        setCommerceId(data.commerceId);
        onUpdateCountrySession(user.data.country);
        configNumeralLocal(user.data.country);
        await loadAllDownloadFiles(user.data.country);
      }
    } catch (err) {
      setTypesDocument([]);
    }
  };

  const loadInfoUser = async (token: string) => {
    showLoading();
    try {
      const { status, data, message } = await getInfoUserSession();

      if (status) {
        //console.log('loadInfoUser() => data', { commerce: data.data.commerce });
        if (data && data.data) {
          if (data.data.commerce && data.data.commerce.data.id) {
            onUpdateStoreSession(data.data.commerce);
          }
          if (data.data.commerce) {
            setCountries(data.data.commerce.data.country);
          }
        }
        setAuthStatus({
          ...authStatus,
          accessToken: token,
          isAuthenticated: true,
          isLoading: false,
          user: data
        });
        await loadGeneralInf(data);
        hideLoading();
        return true;
      } else {
        removeTokenSession();
        setAuthStatus({ ...authStatus, accessToken: undefined, isAuthenticated: false, user: undefined, isLoading: false });
        hideLoading();
        showToast({ text: message || 'No fue posible traer información.', type: TypeToast.error });
        return false;
      }
    } catch (err) {
      console.log('loadInfoUser ===>', err);
      removeTokenSession();
      setAuthStatus({ ...authStatus, accessToken: undefined, isAuthenticated: false, user: undefined, isLoading: false });
      hideLoading();
      return false;
    }
  };

  const signOut = () => {
    showLoading();
    try {
      setAuthStatus({
        ...authStatus,
        accessToken: undefined,
        isAuthenticated: false,
        isLoading: false,
        user: undefined
      });
      setStoreSession(undefined);
      setCountrySession(undefined);
      removeTokenSession();
      onUpdateCountrySession(undefined);
      hideLoading();
      return true;
    } catch (err) {
      hideLoading();
      return false;
    }
  };

  const recoverPasswordUser = async (email: string): Promise<boolean> => {
    try {
      showLoading();
      const { status, message } = await recoverPassword(email);
      if (status) {
        showToast({ text: message || 'Revisa tu correo electrónico.', type: TypeToast.success });
        hideLoading();
        return true;
      }
      showToast({ text: message || 'Error..', type: TypeToast.error });
      hideLoading();
      return false;
    } catch (err) {
      showToast({ text: (err as Error).message, type: TypeToast.error });
      hideLoading();
      return false;
    }
  };

  const changePasswordUser = async (form: { password: string; token: string }): Promise<ResponseApp> => {
    try {
      showLoading();
      const { status, message } = await changePassword(form);

      if (status) {
        showToast({ text: message || 'Su contraseña fue actualizada.', type: TypeToast.success });
        hideLoading();
        return { status: true };
      }
      showToast({ text: message || 'Error...', type: TypeToast.error });
      hideLoading();
      return { status: false, message };
    } catch (err) {
      showToast({ text: (err as Error).message, type: TypeToast.error });
      hideLoading();
      return { status: false };
    }
  };

  const restorePasswordUser = async (form: { password: string; token: string }): Promise<ResponseApp> => {
    showLoading();

    const { status, data } = await restorePassword(form);
    console.log('restorePassword() => { status, data }', { status, data });

    hideLoading();
    return { status, data };
  };

  const loadRoles = async () => {
    try {
      const { data, status, message } = await getRolesUser();
      if (status) {
        setRoles(data);
      } else {
        setRoles([]);
        console.log('loadRoles() ==>', message);
      }
    } catch (err) {
      setRoles([]);
    }
  };

  const loadAllDeliveryStates = async () => {
    try {
      const { data, status } = await getStatusOrder();

      console.log('loadAllDeliveryStates() => { status, data }', { status, data });
      if (status) {
        setDeliveryStates(data);
      } else {
        setDeliveryStates([]);
      }
    } catch (err) {
      console.log('loadAllDeliveryStates() ==> err', err);
      setDeliveryStates([]);
    }
  };

  const loadInitialInformation = async () => {
    try {
      const { status, data } = getTokenSession();
      const { status: statusVT } = await validateToken();
      console.log('loadInitialInformation() ==> { statusVT }', { statusVT });

      if (statusVT) {
        const { status: statusCountry, data: dataCountry } = getCountrySession();
        console.log('getCountrySession() ==> { statusCountry, dataCountry }', { statusCountry, dataCountry });
        if (statusCountry) {
          onUpdateCountrySession(dataCountry);
        }
        loadRoles();
        if (status) {
          await loadInfoUser(data);
          await loadOrdersfailed(dataCountry);
          await loadStores(dataCountry);
          loadAllDeliveryStates();
        } else {
          setAuthStatus({ ...authStatus, isLoading: false });
        }
      } else {
        setAuthStatus({ ...authStatus, isLoading: false });
      }
    } catch (err) {
      console.log('loadInitialInformation() => err', err);
    }
  };

  const signIn = async (formValues: { email: string; password: string }) => {
    try {
      showLoading();
      const { status, data, message } = await signInApp(formValues);
      console.log('singIn() => { status, data, message }', { status, data, message });

      if (status) {
        setTokenSession(data.token);
        setDateExpiredPassword(data.date || '');
        await loadInitialInformation();
        hideLoading();
        return { status };
      } else {
        hideLoading();
        if (data && data.token) {
          return { status, data };
        }
        showToast({ text: message || 'No fue posible conectar con el sever' });
        setDateExpiredPassword('');
        return { status: false };
      }
    } catch (err) {
      console.log('signIn ===>', err);
      setDateExpiredPassword('');
      hideLoading();
      return { status: false };
    }
  };

  const bootstrap = async () => {
    await loadInitialInformation();
  };

  const updateTokenAccessAndLogin = async ({ token }: { token?: string }) => {
    try {
      showLoading();
      console.log('updateTokenAccessAndLogin() ==> token', token);
      setTokenSession(token || '');
      await bootstrap();
      hideLoading();
    } catch (err) {
      console.log('updateTokenAccessAndLogin() ==>', err);
      hideLoading();
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        setAuthStatus,
        typesDocument,
        typesDelivery,
        dimensions,
        signIn,
        signOut,
        recoverPasswordUser,
        changePasswordUser,
        commerceId,
        downloadFiles,
        roles,
        stores,
        countryUser: countryUserSession,
        deliveryStates,
        loadAllDownloadFiles,
        numFailedOrders,
        onUpdateNumFailedOrders,
        onUpdateCountryUser: onUpdateCountrySession,
        typesOperator,
        onUpdateTypeOperator,
        onUpdateStoreSession,
        storeSession,
        restorePasswordUser,
        updateTokenAccessAndLogin,
        dateExpiredPassword,
        flagUpdateNotification,
        onUpdateNotification,
        countries
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
