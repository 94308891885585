import { useState, useEffect, useContext, CSSProperties } from 'react';
import { Skeleton } from 'antd';

import { SizeScreen } from 'configs/Enums';

import { RootContext } from 'context/RootContext';

import DonutGraphics, { DonutGraphicsData } from '../../graphics/Donut';
import BarTag from '../../graphics/bar/BarTag';

import { CountOrderByTypeDeliveryItemModel } from 'models/statistic/order/CountOrderByTypeDelivery';
import StatusOrder from 'models/statistic/order/StatusOrder';
import OrderByStore from 'models/statistic/order/OrderByStore';
import TabCustom from 'components/atoms/tabs';
import TabItemCustom from 'components/atoms/tabs/TabItem';

enum EnumTabKey {
  shipping = 'shipping',
  contributionsPerStore = 'contributionsPerStore',
  orderStates = 'orderStates'
}

const TabBarItem = ({
  title,
  value,
  keyV,
  keyActive,
  isFirst,
  className,
  style,
  onClick
}: {
  title: string;
  value?: string | number;
  /** no se pudo colocar key al pasa como undefined **/
  keyV: EnumTabKey;
  keyActive: EnumTabKey;
  isFirst?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: (val: EnumTabKey) => void;
}) => {
  const { screenResolution } = useContext(RootContext);

  return (
    <div
      className={`flex justify-center items-center py-9 px-4 flex-col w-full flex-1 ${isFirst ? '' : ''} ${
        keyV === keyActive ? 'bg-white' : 'bg-grayarauco-50'
      } ${className}`}
      style={{ width: screenResolution > SizeScreen.md ? '100%' : 'auto', ...style }}
      onClick={onClick ? () => onClick(keyV) : undefined}
    >
      <p className={`text-xl font-bold ${keyV === keyActive ? 'text-primary' : 'text-black'}`}>{title}</p>
      {value === undefined && <Skeleton.Input size='small' />}
      {value !== undefined && <p className='text-2xl font-bold text-black'>{value}</p>}
    </div>
  );
};

const StatisticGeneralTabs = ({
  dataCountOrder,
  statusOrder,
  orderByStore
}: {
  dataCountOrder?: CountOrderByTypeDeliveryItemModel[];
  statusOrder?: StatusOrder;
  orderByStore?: OrderByStore;
}) => {
  const { screenResolution } = useContext(RootContext);

  const [tabKeyActive, setTabKeyActive] = useState(EnumTabKey.shipping);
  const [dataShipping, setDataShipping] = useState<CountOrderByTypeDeliveryItemModel[]>();
  const [dataStore, setDataStore] = useState<any>();
  const [dataOrder, setDataOrder] = useState<StatusOrder>();

  const onChange = (val: any) => {
    setTabKeyActive(val);
  };

  useEffect(() => {
    setDataShipping(dataCountOrder);
    setDataStore(orderByStore?.getGraphicBar());
    setDataOrder(statusOrder);
  }, [dataCountOrder, statusOrder, orderByStore]);

  if (screenResolution >= SizeScreen.xl) {
    return (
      <TabCustom className={'px-10 pt-8'} keyActive={EnumTabKey.shipping} {...{ onChange }}>
        <TabItemCustom
          tab={
            <TabBarItem
              title='Envíos'
              keyV={EnumTabKey.shipping}
              isFirst
              keyActive={tabKeyActive}
              value={
                dataCountOrder && dataCountOrder.length > 0
                  ? dataCountOrder?.map(dCountOrder => dCountOrder.value).reduce((pre, curr) => (pre || 0) + (curr || 0))
                  : undefined
              }
            />
          }
          keyA={EnumTabKey.shipping}
        >
          <DonutGraphics textCenter='Envíos' title='Tipos de envío' data={dataShipping as DonutGraphicsData[]} />
        </TabItemCustom>
        <TabItemCustom
          tab={
            <TabBarItem
              title='Aporte de tiendas'
              keyV={EnumTabKey.contributionsPerStore}
              keyActive={tabKeyActive}
              value={orderByStore?.data.total}
            />
          }
          keyA={EnumTabKey.contributionsPerStore}
        >
          <BarTag title='Aporte de tiendas' data={dataStore} />
        </TabItemCustom>

        <TabItemCustom
          tab={
            <TabBarItem title='Estado de órdenes' keyV={EnumTabKey.orderStates} keyActive={tabKeyActive} value={dataOrder?.data.total} />
          }
          keyA={EnumTabKey.orderStates}
        >
          <DonutGraphics textCenter='Órdenes creadas' title='Estado de órdenes' data={dataOrder?.data.allStatus as DonutGraphicsData[]} />
        </TabItemCustom>
      </TabCustom>
    );
  }

  return (
    <div className='flex flex-col mt-4 mx-4'>
      <TabBarItem
        title='Envíos'
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.shipping ? 'border-primary' : 'border-grayarauco-50'}`}
        keyV={EnumTabKey.shipping}
        isFirst
        keyActive={tabKeyActive}
        value={dataCountOrder?.map(dCountOrder => dCountOrder.value).reduce((pre, curr) => (pre || 0) + (curr || 0))}
        onClick={val => setTabKeyActive(val)}
      />
      {tabKeyActive === EnumTabKey.shipping && (
        <div className='bg-white pb-4'>
          <DonutGraphics
            classNameContainer={'mx-5'}
            textCenter='Envíos'
            title='Tipos de envío'
            data={dataShipping as DonutGraphicsData[]}
          />
        </div>
      )}

      <TabBarItem
        title='Aporte de tiendas'
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.contributionsPerStore ? 'border-primary' : 'border-grayarauco-50'}`}
        keyV={EnumTabKey.contributionsPerStore}
        keyActive={tabKeyActive}
        value={orderByStore?.data.total}
        onClick={val => setTabKeyActive(val)}
      />
      {tabKeyActive === EnumTabKey.contributionsPerStore && (
        <div className='bg-white pb-4'>
          <BarTag title='Aporte de tiendas' data={dataStore} />
        </div>
      )}

      <TabBarItem
        title='Estado de órdenes'
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.orderStates ? 'border-primary' : 'border-grayarauco-50'}`}
        onClick={val => setTabKeyActive(val)}
        keyV={EnumTabKey.orderStates}
        keyActive={tabKeyActive}
        value={dataOrder?.data.total}
      />
      {tabKeyActive === EnumTabKey.orderStates && (
        <div className='bg-white pb-4'>
          <DonutGraphics
            classNameContainer={'mx-5'}
            textCenter='Órdenes creadas'
            title='Tipos de envío'
            data={dataOrder?.data.items as DonutGraphicsData[]}
          />
        </div>
      )}
    </div>
  );
};

export default StatisticGeneralTabs;
