import { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';

import { numberAtPercentage } from 'helpers/Converts';

import BarTagSkeleton from './BarTag.skeleton';

type BarTagItem = {
  name?: string;
  value?: number;
};

type BarTagProps = {
  data?: BarTagItem[];
  title?: string;
  colorBar?: string | string[] | ((datum: any, defaultColor?: string) => string);
};

const config = {
  xField: 'value',
  yField: 'name'
};

const BarTag = ({ data, title, colorBar = '#154BAE' }: BarTagProps) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const totalNew = data?.reduce((curr: any, prev: BarTagItem) => {
      if (typeof curr === 'number') {
        return curr + (prev?.value || 0);
      }
      return curr.value + (prev.value || 0);
    });

    setTotal((totalNew as number) || 0);
  }, [data]);

  if (!data || data.length === 0) {
    return <BarTagSkeleton isActive={true} />;
  }

  return (
    <div className='bg-white flex flex-col w-full border-grayarauco-300 p-8' style={{ borderWidth: 0.6, borderRadius: 10 }}>
      <h1 className='text-lg font-bold text-black'>{title}</h1>

      <Bar
        color={colorBar}
        {...{ data }}
        conversionTag={{
          text: {
            formatter: (_: number, pre: number) => {
              return `${numberAtPercentage({ value: pre, total })} %`;
            }
          }
        }}
        {...config}
      />
    </div>
  );
};

export default BarTag;
