import { Bar } from '@ant-design/plots';

import BarTagSkeleton from './BarTag.skeleton';

type BarStackCustomItem = {
  date: string;
  value: number;
  type?: string;
};

type BarStackCustomProps = {
  data?: BarStackCustomItem[];
  title?: string;
  titleX?: string;
  titleY?: string;
  color?: string | string[] | ((datum: any, defaultColor?: string) => string);
  classNameContainer?: string;
};

const config = {
  isStack: true,
  xField: 'value',
  yField: 'date',
  seriesField: 'type',
  label: {
    layout: [
      {
        type: 'interval-adjust-position'
      },
      {
        type: 'interval-hide-overlap'
      },
      {
        type: 'adjust-color'
      }
    ]
  }
};

const BarStackCustom = ({ data, title, titleX, titleY, color, classNameContainer }: BarStackCustomProps) => {
  if (!data) {
    return <BarTagSkeleton isActive={true} />;
  }

  return (
    <div
      className={`bg-white flex flex-col border-grayarauco-300 p-8 ${classNameContainer}`}
      style={{ borderWidth: 0.6, borderRadius: 10 }}
    >
      <h1 className='text-lg font-bold text-black'>{title}</h1>

      <Bar yAxis={{ title: { text: titleY } }} xAxis={{ title: { text: titleX } }} {...{ data, color }} {...config} />
    </div>
  );
};

export default BarStackCustom;
