import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Checkbox, Tabs } from 'antd';

import { NameIcons, PathRoute, TypeAlertMessage } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import { AuthContext } from 'context/AuthContext';
import { RouterInContext } from 'context/RouterIn';

import AlertMessage from 'components/molecules/messages/Alert';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';
import FailedOrderMolecules from 'components/molecules/items/cards/order/FailedOrder';

import Order from 'models/order';
import ButtonCustom from 'components/atoms/buttons';
import DetailFailedOrderOrganism from './FailedDetail';
import IconCustom from 'components/atoms/icons';
import DetailOrderOrganism from './Detail';
//import CheckboxCustom from 'components/atoms/checkbox';

type ListFailedOrdersOrganismProps = {
  orders?: Order[];
  onCorrectOrders: (orders: Order[]) => void;
  onDeleteFailedOrders: (onders: Order[], notMessage?: boolean) => void;
  onDetailC(order: string): Promise<ResponseApp>;
};

const { TabPane } = Tabs;

const ListFailedOrdersOrganism = ({ orders, onCorrectOrders, onDeleteFailedOrders, onDetailC }: ListFailedOrdersOrganismProps) => {
  const { authStatus, numFailedOrders } = useContext(AuthContext);
  const { hContainer, onIsCorrectOrders } = useContext(RouterInContext);

  const [orderCurrent, setOrderCurrent] = useState<Order | undefined>();
  const [orderCorrect, setOrderCorrect] = useState<Order | undefined>();
  const [correctedOrders, setCorrectedOrders] = useState<Order[]>([]);
  const [disabledCorrect, setDisabledCorrect] = useState(false);
  const [flag, setFlag] = useState(0);

  const navigate = useNavigate();

  const onDetail = (order: Order) => {
    setOrderCurrent(order);
  };

  const onDetailCorrect = async (order: Order) => {
    const { status, data } = await onDetailC(order.data.id || '');
    console.log('onDeleteCorrect() => { status, data }', { status, data });

    if (status) {
      setOrderCorrect(data);
    }
  };

  const onDeleted = (order?: Order) => {
    if (order) {
      onDeleteFailedOrders([order]);
    } else {
      const ordersDeleted = orders?.filter(orderD => orderD.data.isSelectDelete);
      console.log('onDelete() =>', ordersDeleted);
      if (ordersDeleted) {
        onDeleteFailedOrders(ordersDeleted);
      } else {
        onDeleteFailedOrders([]);
      }
    }
  };

  const onUpdateCorrectedOrders = () => {
    const numOrderCorretedOrders = orders?.filter(order => order.data.errors?.every(error => error.isValid)) || [];
    setCorrectedOrders(numOrderCorretedOrders);
    if (numOrderCorretedOrders.length > 0) {
      window.onbeforeunload = () => {
        return window.confirm('Seguro deseas salir?');
      };
      onIsCorrectOrders(true);
    } else {
      window.onbeforeunload = () => {
        console.log('onbeforeunload');
      };
      onIsCorrectOrders(false);
    }
  };

  useEffect(() => {
    console.log('hContainer ==> ', hContainer);
    if (orders) {
      onUpdateCorrectedOrders();
    }
    setDisabledCorrect(!authStatus.user?.isLocatario() || false);
  }, [hContainer, orders]);

  return (
    <MenuSessionContainer notStyleContainer classNameContainer='flex flex-1 flex-col bg-gray-200 relative'>
      <div className={`overflow-auto flex flex-col relative`} style={{ height: hContainer }}>
        <div className='flex w-full flex-col bg-white border-t border-gray-200 pt-4 md:pt-8 sm:flex-row'>
          <div className='flex flex-col md:items-center sm:flex-1 md:flex-row'>
            <div className='flex flex-1 flex-col mt-0 ml-5 md:ml-10 mr-6 mb-2 md:mb-0'>
              <h1 className=''>Órdenes fallidas</h1>
              {authStatus.user?.isLocatario() && (
                <p className='mt-2 font-normal text-lg'>{authStatus.user?.data.commerce?.data.brand || ''}</p>
              )}
            </div>
            <div className='flex flex-1 justify-center items-center md:justify-start md:items-start'>
              <AlertMessage
                className='md:mb-2 mr-5 ml-5 md:ml-0 md:mr-10'
                outlined={false}
                text={
                  <p className='text-base font-normal text-black'>
                    Se muestran las órdenes fallidas detectadas las últimas 24 horas, una vez pasado este plazo{' '}
                    <strong className='font-bold'>se eliminan automáticamente.</strong>
                  </p>
                }
                type={TypeAlertMessage.info}
              />
            </div>
          </div>
        </div>

        <div className='flex justify-center items-center bg-white'>
          <Tabs
            className={'w-full mx-10'}
            centered={true}
            onClick={e => console.log('onClick ==> e', e)}
            tabBarStyle={{ justifyContent: 'center', alignItems: 'center' }}
            activeKey={'2'}
            onTabClick={a => {
              if (a === '1') {
                navigate(PathRoute.listOrders);
              } else if (a === '2') {
                navigate(PathRoute.listFailedOrders);
              }
            }}
          >
            <TabPane tab={<h3>Órdenes en curso</h3>} key='1' />
            <TabPane
              tab={
                <h3>
                  Órdenes fallidas <Badge count={numFailedOrders} />
                </h3>
              }
              key='2'
            />
          </Tabs>
        </div>

        <div className='mt-4 mx-5 md:mt-8 md:mx-11'>
          <div className='bg-white p-4 flex border border-primary rounded-lg justify-between flex-col md:flex-row'>
            <div className='flex flex-col justify-center'>
              <h2 className='text-xl font-bold text-primary'>Corrección de órdenes</h2>
              <p className='text-base font-normal'>
                Una vez corregidas las órdenes, cárgalas y automáticamente pasarán al listado de órdenes.
              </p>
            </div>
            <div className='flex justify-center items-center'>
              <ButtonCustom
                className='w-60 md:w-auto'
                disabled={correctedOrders.length ? false : true}
                onClick={() => onCorrectOrders(correctedOrders)}
              >
                Cargar órdenes {correctedOrders.length ? `(${correctedOrders.length})` : ''}
              </ButtonCustom>
            </div>
          </div>
        </div>

        {orders && orders.length > 0 && (
          <div className='flex justify-center items-center mx-5 md:mx-11 my-4'>
            <div className='flex flex-row mx-3 md:mx-4 border-l-4 justify-center items-center'>
              <Checkbox
                className='checkbox-custom mr-3'
                checked={orders.every(order => order.data.isSelectDelete)}
                indeterminate={orders.some(order => order.data.isSelectDelete) && !orders.every(order => order.data.isSelectDelete)}
                onChange={e => {
                  orders.forEach(order => {
                    order.onCheckedDeleted(e.target.checked);
                    console.log('onChange() ==> order', { order, value: e.target.checked });
                  });
                  console.log('onChange() ==> orders', { orders, value: e.target.checked });
                  setFlag(flag + 1);
                }}
              />
              <div className='flex justify-center items-center'>
                {orders.some(order => order.data.isSelectDelete) && (
                  <IconCustom className='leading-6 cursor-pointer' onClick={() => onDeleted()} name={NameIcons.deleted} />
                )}
              </div>
            </div>

            <div className='flex justify-center items-center flex-1'>
              <h2
                className={`text-base md:text-xl font-bold ${
                  correctedOrders.length === orders?.length ? 'text-tertiary' : 'text-redarauco'
                }`}
              >
                {correctedOrders.length}/{orders?.length} órdenes corregidas
              </h2>
            </div>

            <div className='flex flex-row'>{/** <h3 className='text-sm md:text-lg font-normal'>errores</h3> **/}</div>
          </div>
        )}

        <div className='mx-5 md:mx-11'>
          {orders && orders.length > 0 && (
            <ul>
              {orders.map((order, index) => {
                return (
                  <FailedOrderMolecules
                    key={order.data.detail.data.order.data.order}
                    onUpdateFlag={() => setFlag(flag + 1)}
                    messageTooltip={disabledCorrect ? 'No esta habilitado para corregir' : undefined}
                    {...{ order, index, onDetail, onUpdateCorrectedOrders, onDeleted, flag, onDetailCorrect, disabledCorrect }}
                  />
                );
              })}
            </ul>
          )}
        </div>

        {(!orders || orders.length === 0) && (
          <div className='flex flex-1 justify-center items-center w-full'>
            <h1 className='text-3xl font-normal text-black'>No hay órdenes fallidas</h1>
          </div>
        )}
      </div>

      {orderCurrent && (
        <div className='absolute top-0 bottom-0 left-0 right-0 bg-gray-200'>
          <DetailFailedOrderOrganism
            order={orderCurrent}
            goBack={() => setOrderCurrent(undefined)}
            {...{ onUpdateCorrectedOrders, onDetailCorrect }}
          />
        </div>
      )}

      {orderCorrect && (
        <div className='absolute top-0 bottom-0 left-0 right-0 bg-gray-200'>
          <DetailOrderOrganism order={orderCorrect} isPage={false} goBack={() => setOrderCorrect(undefined)} />
        </div>
      )}
    </MenuSessionContainer>
  );
};

export default ListFailedOrdersOrganism;
