import './App.css';

import NavigationContainer from './navigations';

import AuthContextProvider from 'context/AuthContext';
import RootContextProvider from 'context/RootContext';
import DataContextProvider from 'context/Data';

const App = () => {
  return (
    <RootContextProvider>
      <AuthContextProvider>
        <DataContextProvider>
          <NavigationContainer />
        </DataContextProvider>
      </AuthContextProvider>
    </RootContextProvider>
  );
};

export default App;
