import { FormatDate } from 'configs/Enums';
import moment from 'configs/MomentJS';

import { convertStringDateUTCAtStringDateLocal, myArrayWithNoDuplicates } from 'helpers/Converts';
import { isAfterDate, isBeforeDate } from 'helpers/Validate';

class Base {
  static isParameterInvalid(param?: string): boolean {
    try {
      if (param) {
        return `${`${param}`.toLowerCase()}`.indexOf('error:') !== -1;
      }
      return false;
    } catch (err) {
      console.log('isParameterInvalid() ==> err', { err, param });
      return false;
    }
  }

  static removeFlag(param?: string): string {
    try {
      if (param) {
        return `${`${param}`.toLowerCase()}`
          .replace('error:', '')
          .replace(`{{${this.getMessageValue(param)?.toLowerCase()}`, '')
          .replace(`}}`, '');
      }
      return '';
    } catch (err) {
      console.log('removeFlag() => err', { param, err });
      return '';
    }
  }

  static getMessageError(param?: string): string | undefined {
    return this.isParameterInvalid(param) ? this.removeFlag(param) : undefined;
  }

  /** solo valor mal ingresado por el usuario **/
  static getMessageValue(stg?: string) {
    if (stg) {
      const indexFirst = stg.indexOf('{{');
      const indexLast = stg.indexOf('}}');

      if (indexFirst !== -1) {
        if (indexLast !== -1) {
          return stg.slice(indexFirst + 2, indexLast);
        }
        return stg.slice(indexFirst + 2);
      }
    }
    return stg;
  }

  static isMessageValue(stg?: string) {
    //console.log('isMessageValue() => stg', stg);
    if (stg) {
      const indexFirst = stg.indexOf('{{');
      const indexLast = stg.indexOf('}}');

      //console.log('isMessageValue() => { indexFirst, indexLast }', { indexFirst, indexLast });
      if (indexFirst !== -1) {
        if (indexLast !== -1) {
          return true;
        }
        return true;
      }
    }
    return false;
  }

  static async onFilterList({
    val,
    classAll,
    referenceValue,
    onFilter,
    field
  }: {
    val?: string | string[];
    classAll?: any[];
    referenceValue?: string;
    field?: string;
    onFilter?: (params: { field: string; data: any; val?: string | string[] }) => void;
  }) {
    const typesOrder: string[] = referenceValue?.split('.') || [];
    const classFilter = classAll?.filter(classCurrent => {
      let valueFormat: any = classCurrent;
      typesOrder.forEach(typeOrder => {
        valueFormat = valueFormat[typeOrder] || '';
      });
      //console.log('typesOrder ==>', { val, valueFormat });
      if (typeof val !== 'string') {
        const found = val?.find(va => va.toLowerCase() === `${valueFormat}`?.toLowerCase());
        if (found !== undefined) {
          return true;
        }
        return false;
      } else {
        return `${valueFormat}`?.toLowerCase().indexOf(val.toLowerCase()) !== -1;
      }
      return [];
    });
    if (onFilter) {
      onFilter({ val, field: field || '', data: classFilter });
    }
    return myArrayWithNoDuplicates(
      classFilter?.map(order => {
        let valueFormat: any = order;
        typesOrder.forEach(typeOrder => {
          valueFormat = valueFormat[typeOrder];
        });
        return valueFormat || '';
      }) || []
    );
  }

  static onSortList({
    isSort,
    onSort,
    classAll,
    referenceValue,
    field,
    type = 'default'
  }: {
    isSort?: boolean;
    classAll: any[];
    onSort?: (params: { isSort?: boolean; field?: string; data?: any[] }) => void;
    referenceValue?: string;
    field?: string;
    type?: 'default' | 'date';
  }) {
    let orderSort: any[] = classAll;
    if (classAll && referenceValue) {
      orderSort = classAll.sort((classA, classB) => {
        let orderAFormat: any = classA;
        let orderBFormat: any = classB;
        const refValuesSplit = referenceValue.split('.');
        refValuesSplit.forEach(refValueSplit => {
          orderAFormat = orderAFormat[refValueSplit];
          orderBFormat = orderBFormat[refValueSplit];
        });
        //console.log('onSortList() =>', { orderBFormat, orderAFormat, referenceValue, orders });
        if (type === 'default') {
          if ((orderBFormat || '').toUpperCase() > (orderAFormat || '').toUpperCase()) {
            return isSort ? -1 : 1;
          } else if ((orderBFormat || '').toUpperCase() < (orderAFormat || '').toUpperCase()) {
            return isSort ? 1 : -1;
          }
          return 0;
        } else if (type === 'date') {
          if (
            isBeforeDate({
              dateBase: orderBFormat,
              dateReference: orderAFormat,
              formatBase: FormatDate.yyyymmddAlthmma,
              formatRef: FormatDate.yyyymmddAlthmma
            })
          ) {
            return isSort ? -1 : 1;
          } else if (
            isAfterDate({
              dateBase: orderBFormat,
              dateReference: orderAFormat,
              formatBase: FormatDate.yyyymmddAlthmma,
              formatRef: FormatDate.yyyymmddAlthmma
            })
          ) {
            return isSort ? 1 : -1;
          }
          return 0;
        }
        return 0;
      });
    }
    if (onSort) {
      //console.log('onSort() ==>', { orderSort });
      onSort({ isSort, field, data: orderSort });
    }
  }

  /**
   * formatea la fecha que llega del servidor a como se usa en el app
   */
  formatStringData(date?: string | number) {
    try {
      let createdAtFormat = date;
      if (createdAtFormat) {
        if (typeof createdAtFormat === 'number') {
          createdAtFormat = moment(new Date(createdAtFormat)).format(FormatDate.yyyymmddAltHHmm);
        } else {
          createdAtFormat =
            convertStringDateUTCAtStringDateLocal({
              value: createdAtFormat,
              formatIn: FormatDate.yyyymmddAlthmma,
              formatOut: FormatDate.yyyymmddAltHHmm
            }) || createdAtFormat;
        }
      }
      return createdAtFormat;
    } catch (err) {
      return undefined;
    }
  }

  static formatStringData(date?: string | number) {
    try {
      let createdAtFormat = date ? `${date}` : undefined;
      if (createdAtFormat !== undefined && createdAtFormat !== '') {
        if (typeof date === 'number') {
          createdAtFormat = moment(new Date(date)).format(FormatDate.yyyymmddAltHHmm);
        } else {
          try {
            createdAtFormat = moment(new Date(Number(`${date}`))).format(FormatDate.yyyymmddAltHHmm);
          } catch (err) {
            createdAtFormat =
              convertStringDateUTCAtStringDateLocal({
                value: createdAtFormat,
                formatIn: FormatDate.yyyymmddAlthmma,
                formatOut: FormatDate.yyyymmddAltHHmm
              }) || createdAtFormat;
          }
        }
      }
      return createdAtFormat;
    } catch (err) {
      return undefined;
    }
  }
}

export default Base;
