import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EnumCountries, FormatDate, PathRoute, TypeDelivery, TypeTimeInterval, TypeToast, UnitDate } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import WelcomeOrganism from 'components/organisms/welcome';

import { getDateNow, getSubtractTime } from 'helpers/Generator';

import Store from 'models/store';

import { getOrdersFilter } from 'services/app/Order';
import { getKpiGeneral, getStatusOrder } from 'services/app/Statistic';
import KpiGeneral from 'models/statistic/order/KpiGeneral';
import StatusOrder from 'models/statistic/order/StatusOrder';

const WelcomePage = () => {
  const { showToast, showLoading, hideLoading } = useContext(RootContext);
  const { countryUser, authStatus, typesDelivery, stores, deliveryStates } = useContext(AuthContext);

  const [kpiGeneralStatistic, setKpiGeneralStatistic] = useState<KpiGeneral>();
  const [statusOrder, setStatusOrder] = useState<StatusOrder>();

  const navigate = useNavigate();

  const onSearchOrderByNum = async (numOrder?: string) => {
    showLoading();
    try {
      const params = {
        numOrder: numOrder,
        store: [],
        status: [],
        dateStart: getSubtractTime({ unit: UnitDate.months, amount: 1, format: FormatDate.yyyymmdd }) as string,
        dateEnd: getDateNow({ format: FormatDate.yyyymmdd }) as string,
        country: countryUser,
        page: 1,
        items: 10,
        warehouse: []
      };

      const { data, status, message } = await getOrdersFilter(params);
      console.log('onSearchOrderByNum() => { data, status, message }', { data, status, message });

      if (status) {
        console.log('onSearchOrderByNum() => { data, status, message }', { data, status, message });
        //navigate(`${PathRoute.listOrders}/${data.orders.map((order: Order) => order.data.detail.data.order.data.order).join(',')}`);
        if (data.orders.length > 1) {
          navigate(`${PathRoute.listOrders}/${numOrder}`);
          //navigate(PathRoute.listOrders);
        } else if (data.orders.length === 1) {
          navigate(`${PathRoute.orderDetail}/${data.orders[0].data.id}`);
        }
      } else {
        showToast({ text: message || 'No fue posible obtener la orden.', type: TypeToast.error });
      }
    } catch (err) {
      console.log('onSearchOrderByNum() => err', err);
    }
    hideLoading();
  };

  const loadStatusOrder = async (paramsFilter?: {
    dateEnd: string;
    dateStart: string;
    type: string[];
    statusOrder: string[];
    store: Store[];
  }) => {
    let countries = [countryUser || ''];
    if (countryUser === EnumCountries.all) {
      countries = [EnumCountries.cl, EnumCountries.col, EnumCountries.per];
    }

    const { status, data, message } = await getStatusOrder({
      orderStates: paramsFilter?.statusOrder,
      stores: paramsFilter?.store,
      country: countries,
      dateRange: { endDate: paramsFilter?.dateEnd || '', startDate: paramsFilter?.dateStart || '' }
    });
    console.log('loadStatusOrder() ==> { status, data }', { status, data });
    if (status) {
      setStatusOrder(data);
    }

    return { status, message };
  };

  const loadGeneralKpi = async (value: {
    dateEnd?: string;
    dateStart?: string;
    timeInterval?: string;
    type?: string[];
    store?: Store[];
    statusOrder: string[];
  }) => {
    showLoading();
    //const typeDelivery = value.type;
    let countries = [countryUser || ''];
    if (countryUser === EnumCountries.all) {
      countries = [EnumCountries.cl, EnumCountries.col, EnumCountries.per];
    }

    const { status, data } = await getKpiGeneral({
      dateRange: {
        endDate: value.dateEnd || '',
        startDate: value.dateStart || ''
      },
      timeInterval: (value.timeInterval as TypeTimeInterval) || TypeTimeInterval.month,
      typesDelivery: typesDelivery,
      country: countries,
      stores: value.store
    });

    console.log('loadGeneralKpi() ==> value', { data, status });
    if (status) {
      setKpiGeneralStatistic(data);
    } else {
      //showToast({ text: message || '' });
      setKpiGeneralStatistic(undefined);
    }
    hideLoading();
  };

  useEffect(() => {
    const dateEnd = (getDateNow({ format: FormatDate.yyyymmdd }) as string) || '';
    const dateStart = (getSubtractTime({ unit: UnitDate.months, amount: 1, format: FormatDate.yyyymmdd }) as string) || '';
    const typeDelivery = [TypeDelivery.expressDelivery, TypeDelivery.pickupStore];
    let store: Store[] = [];
    if (authStatus.user?.isLocatario() && authStatus.user.data.commerce) {
      store = [authStatus.user.data.commerce];
    }

    loadGeneralKpi({
      dateEnd,
      dateStart,
      type: typeDelivery,
      statusOrder: deliveryStates,
      store
    });
    loadStatusOrder({ dateEnd, dateStart, type: typeDelivery, statusOrder: deliveryStates, store });
    console.log('useEffect() ==> authStatus', { authStatus, stores });
  }, [countryUser, stores]);

  return <WelcomeOrganism {...{ onSearchOrderByNum, kpiGeneralStatistic, statusOrder }} />;
};

export default WelcomePage;
