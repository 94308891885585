import { useContext, useEffect, useState } from 'react';

import { TypeToast } from 'configs/Enums';
import { ASSETS } from 'configs/Constants';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import CreateUserOrganism from 'components/organisms/dashboard/create/CreateUser';
import { UserBoardParams } from 'components/molecules/filters/UserBoardFilter';

import Country, { CountryServerModel } from 'models/Country';
import User from 'models/User';
import Store from 'models/store';

import { getShop } from 'services/app/Store';
import { createUser, deleteUserById, getUsers, updateUserById } from 'services/app/User';

const CreateUserPage = () => {
  const { showToast, showLoading, hideLoading } = useContext(RootContext);
  const { commerceId, roles, countryUser } = useContext(AuthContext);

  const [countries, setCountries] = useState<Country[]>([]);
  const [shops, setShops] = useState<Store[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [usersFilter, setUsersFilter] = useState<User[]>([]);
  const [flag, setFlag] = useState(0);

  const loadShop = async () => {
    showLoading();
    try {
      const { status, data, message } = await getShop(countryUser);
      if (status) {
        //console.log('shops ==>', data);
        setShops(data);
      } else {
        showToast({ text: message || 'No fue posible cargar las ordenes.', type: TypeToast.error });
      }
    } catch (err) {
      console.log('loadShop() ==> err =>', err);
    }
  };

  const loadUsers = async () => {
    try {
      const { status, data, message } = await getUsers();
      if (status) {
        const dataU = data as User[];
        dataU.forEach(dat => {
          const rolN = dat.data.roles?.map(rol => roles?.find(rols => rols.data.value === rol.data.value) || rol);
          dat.data.roles = rolN;
        });
        setUsers(dataU);
        setUsersFilter(dataU);
      } else {
        setUsers([]);
        setUsersFilter([]);
        showToast({ text: message || 'No fue posible cargar los usuarios.', type: TypeToast.error });
      }
    } catch (err) {
      showToast({ text: 'No fue posible cargar los usuarios.', type: TypeToast.error });
    }
    hideLoading();
  };

  const onCreateUser = async (user: User) => {
    showLoading();
    try {
      //console.log('createUser() ==>', { user, authStatus: authStatus.user });
      user.updateCommerceId(commerceId);
      user.updateCountry(countryUser);
      const { status, message } = await createUser(user);
      if (status) {
        showToast({ text: message || 'Usuario creado con éxito.', type: TypeToast.success });
        loadUsers();
      } else {
        showToast({ text: message || 'No fue posible crear el usuario.', type: TypeToast.error });
      }
    } catch (err) {
      console.log('createUser() ==>', err);
    }
    hideLoading();
  };

  const onUpdateUser = async (user: User) => {
    showLoading();
    //console.log('onUpdateCutOffTimeSetting() ==>', deliverySchedule);
    const { status, message } = await updateUserById(user);
    //console.log('onUpdateUser() =>', { status, message });

    if (status) {
      showToast({ text: message || 'Actualizado con éxito.', type: TypeToast.success });
      loadUsers();
    } else {
      showToast({ text: message || 'No fue posible actualizar.', type: TypeToast.error });
      hideLoading();
    }
  };

  const onDeletedUser = async (user: User) => {
    showLoading();
    //console.log('onDeletedCutOffTimeSetting() ==>', deliverySchedule);
    const { status, message } = await deleteUserById(user);
    //console.log('onDeletedUser() =>', { status, message });

    if (status) {
      showToast({ text: message || 'Eliminado con éxito.', type: TypeToast.success });
      loadUsers();
    } else {
      showToast({ text: message || 'No fue posible eliminar.', type: TypeToast.error });
      hideLoading();
    }
  };

  const onFilterList = async (params?: UserBoardParams) => {
    try {
      console.log('onFilter() => params', { params, users });
      let filterUser = users;
      if (params) {
        const { roles, name, brands } = params;

        if (brands && brands.length > 0) {
          filterUser = filterUser.filter(filterU => brands.find(bra => bra.data.commerceId === filterU.data.commerce?.data.commerceId));
        }
        if (roles && roles.length > 0) {
          filterUser = filterUser.filter(filterU =>
            filterU.data.roles?.find(rolF => roles.find(rol => rol.data.value === rolF.data.value))
          );
        }
        if (name) {
          filterUser = filterUser.filter(filterU =>
            filterU.data.fullName?.toLowerCase().indexOf(name.toLowerCase()) !== -1 ? true : false
          );
        }
      }
      setUsersFilter(filterUser);
      setFlag(flag + 1);
    } catch (err) {
      console.log('onFilter() => err', err);
      setUsersFilter([]);
      setFlag(flag + 1);
    }
  };

  useEffect(() => {
    if (ASSETS.data && ASSETS.data.countries && ASSETS.data.countries.length > 0) {
      setCountries(ASSETS.data.countries.map((country: CountryServerModel) => new Country(Country.formalizeData(country))));
    }
    loadShop();
    loadUsers();
  }, [countryUser, roles]);

  return (
    <CreateUserOrganism
      roles={roles || []}
      onFilter={onFilterList}
      users={usersFilter}
      {...{ countries, onCreateUser, shops, onUpdateUser, onDeletedUser, flag }}
    />
  );
};

export default CreateUserPage;
