import { IS_MOBILE } from 'configs/Constants';
import { ResponseApp } from 'configs/Interface';

export const permissionNotification = async (): Promise<ResponseApp> => {
  if (Notification.permission === 'granted') {
    return { status: true };
  } else {
    const resultPermission = await Notification.requestPermission();
    if ('serviceWorker' in navigator) {
      console.log('permissionNotification() ====> si esta soportado', true);
    }

    if (resultPermission === 'granted') {
      return { status: true };
    } else if (resultPermission === 'denied') {
      return { status: false, message: 'No a concedido permisos nesesarios para residir notificaciones.' };
    } else if (resultPermission === 'default') {
      return { status: true, message: 'Permisos de notificacion por defecto.' };
    }
  }
  return { status: false, message: 'No fue posible solitiar los permisos.' };
};

export const showNotification = async ({
  title,
  description,
  onClick,
  disabled
}: {
  title?: string;
  description?: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  try {
    const { status } = await permissionNotification();

    if (status && !disabled) {
      if (IS_MOBILE) {
        navigator.serviceWorker.getRegistration().then((reg?: ServiceWorkerRegistration) => {
          reg?.showNotification(title || 'Notificación', {
            icon: '/logo192.png',
            silent: false,
            vibrate: [200, 100, 200],
            //requireInteraction: true,
            body: description
          });
        });
      } else {
        const notification = new Notification(title || 'Notificación', {
          icon: '/logo192.png',
          requireInteraction: true,
          vibrate: [200, 100, 200],
          silent: false,
          body: description
        });

        notification.onclick = (event: any) => {
          event?.preventDefault();
          if (onClick) {
            onClick();
          }
        };
      }
    }
  } catch (err) {
    console.log('showNotification() ==> err', err);
  }
};
