import ReactDOM from 'react-dom';

const ModalLoading = () => {
  return ReactDOM.createPortal(
    <>
      <div className='z-50 fixed inset-0 flex h-screen bg-backgroundmodal justify-center items-center'>
        <div className='spinner' />
      </div>
    </>,
    document.getElementById('modal-loading') as Element
  );
};

export default ModalLoading;
