export interface UserRoleModel {
  id?: string;
  name: string;
  value?: string;
}

export interface UserRoleServerModel {
  name: string;
  value?: string;
  description?: string;
  id?: string;
}

class UserRole {
  data: UserRoleModel;

  constructor(data: UserRoleModel) {
    this.data = data;
  }

  static formalizeData(data: UserRoleServerModel): UserRoleModel {
    const { name, id, description } = data;

    return {
      id,
      name: description || name,
      value: name
    };
  }
}

UserRole.prototype.toString = function userRoleToString() {
  return `${this.data.value}`;
};

export default UserRole;
