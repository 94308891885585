import { handleErrorServiceApi } from 'helpers/Errors';
import Notification from 'models/notification';

import api from '.';

class NotificationProvider {
  controllerGetNotifications: AbortController | undefined;

  async getNotifications() {
    try {
      this.controllerGetNotifications = new AbortController();
      return await api().get('websocket/notification', { signal: this.controllerGetNotifications.signal });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateReadNotification(connectionId: string) {
    try {
      return await api().put(`websocket/notification`, { data: [connectionId] });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateReadNotifications(notifications: Notification[]) {
    try {
      return await api().put(`websocket/notification`, { data: notifications.map(not => not.data.connectionId) });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new NotificationProvider();
