import axios, { AxiosInstance } from 'axios';

import { URL_API } from 'configs/Constants';

import { mySession, setTokenSession, removeTokenSession } from 'storages/Session';

export interface ApiParams {
  url?: string;
  /* valida si el servicio requeire que el token no este activo. */
  notTokenValidation?: boolean;
}

const api = (params?: ApiParams): AxiosInstance => {
  let baseURL = URL_API;

  if (params && params.url) {
    baseURL = params.url;
  }

  const apiAxios = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const token = mySession.token;
  //console.log('token api ===>', token);

  if (token && !params?.notTokenValidation) {
    apiAxios.defaults.headers.common['authorization'] = `Bearer ${token}`;
  }

  if (!params || !params.notTokenValidation) {
    apiAxios.interceptors.request.use(async config => {
      //console.log('interceptors() => ', config);
      try {
        if (mySession.token) {
          const { status, data } = await axios.request({
            method: 'GET',
            baseURL: URL_API,
            url: `users/refresh/token?token=${mySession.token}`
          });
          //console.log('interceptors() ==> mySession.token', { token: mySession.token, status, data });
          if (status) {
            setTokenSession(data.token, data.expiredIn);
            return config;
          } else {
            removeTokenSession();
            if (mySession.token) {
              config.url = `refresh/token?token=${mySession.token}`;
              config.method = 'GET';
            }
            return config;
          }
        } else {
          return config;
        }
      } catch (err) {
        console.log('interceptors() ==> err', { err });
        removeTokenSession();
        return config;
      }
    });
  }

  return apiAxios;
};

export default api;
