import { Route, Routes } from 'react-router-dom';

import { PathRoute } from 'configs/Enums';

import LoginPage from 'pages/auth/Login';
import RecoverPasswordPage from 'pages/auth/RecoverPassword';
import NewPasswordPage from 'pages/auth/NewPassword';
import LoadingPage from 'pages/Loading';
import LoadingRedirect from 'pages/LoadingRedirect';

const RoutersOut = () => {
  return (
    <Routes>
      <Route path='/' element={<LoadingPage />} />

      <Route path={PathRoute.uploadFile} element={<LoadingRedirect />} />
      <Route path={PathRoute.listOrders} element={<LoadingRedirect />} />
      <Route path={PathRoute.dashboard} element={<LoadingRedirect />} />

      <Route path={PathRoute.signin} element={<LoginPage />} />
      <Route path={PathRoute.reconverPassword} element={<RecoverPasswordPage />} />
      <Route path={PathRoute.newPassword} element={<NewPasswordPage />} />
      <Route path='*' element={<LoadingRedirect />} />
    </Routes>
  );
};

export default RoutersOut;
