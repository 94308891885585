import { CSSProperties, ReactNode } from 'react';

type LabelCustomProps = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const LabelCustom = ({ children, className, style }: LabelCustomProps) => {
  return (
    <label style={style} className={`${className} block text-gray-700 text-base font-normal`}>
      {children}
    </label>
  );
};

export default LabelCustom;
