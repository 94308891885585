import { FormatDate } from 'configs/Enums';
import Delivery from 'models/Delivery';
import moment from 'moment';

export interface WarehousePAModel {
  id?: string;
  description?: string;
  createdAt?: number;
  createdAtDisplay?: string;
  country?: string;
  integration?: string;
  delivery?: Delivery[];
}

const WAREHOUSE_DEFUALT: WarehousePAModel = {
  id: undefined,
  description: undefined,
  delivery: undefined
};

class WarehousePA {
  data: WarehousePAModel;

  constructor(data?: WarehousePAModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = WAREHOUSE_DEFUALT;
    }
  }

  static formalizeData(data: any): WarehousePAModel {
    const { id, description, createdAt, country, integration, delivery } = data;

    let deliveries: Delivery[] = [];

    if (delivery) {
      deliveries = delivery.map((deli: any) => new Delivery(Delivery.formalizeData({ ...deli, warehouse: id })));
    }

    return {
      id,
      description,
      createdAt,
      createdAtDisplay: moment(createdAt).format(FormatDate.yyyymmddAltHHmm),
      country,
      integration,
      delivery: deliveries
    };
  }
}

WarehousePA.prototype.toString = function toString() {
  return `${this.data.id}-${this.data.description}`;
};

export default WarehousePA;
