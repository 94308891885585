import { TableColumn } from 'react-data-table-component';

import { VALIDATE_CONSTRAINTS } from 'configs/Constants';
import { TypeDelivery, NameIcons } from 'configs/Enums';
import { FormatDate } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';
import FilterHeaderColumn from 'components/molecules/headers/FilterHeaderColumn';
import TableCellMolecule from 'components/molecules/items/TableCell';

import { myArrayWithNoDuplicates } from 'helpers/Converts';
import { isAfterDate, isBeforeDate } from 'helpers/Validate';

import Country, { CountryServerModel } from 'models/Country';
import Delivery, { DeliveryServerModel } from 'models/Delivery';

import { FormItemModel } from 'models/form';
import DeliverySchedule from 'models/delivery/DeliverySchedule';

import SaleChannel from './SaleChannel';
import StoreJanis from './StoreJanis';
import ExternalShopForm from 'components/molecules/items/ExternalStoreForm';
import TypeOperator from './TypeOperator';
import WarehousePA from 'models/warehouse';

export interface StoreModel {
  id?: string;
  brand?: string;
  brandSearch?: string;
  saleChannel?: SaleChannel;
  country?: Country | Country[];
  currency?: string;
  city?: string;
  typesDelivery?: Delivery[];
  storeJanis?: StoreJanis;
  ean?: string;
  commerceId?: string;
  storeJanisId?: string;
  rutOrId?: string;
  sucOrContractNum?: string;
  isExternal?: boolean;
  externalShops?: ExternalShopModel[];
  /* ya no se usa en las nuevas versiones pero las antiguas si */
  externalShopSecret?: string;
  externalShopName?: string;
  /** si la tienda tiene ordenes para filtrar **/
  isHasOrders?: boolean;
  /** si tiene politicas particulares **/
  particularShippingPolicies?: DeliverySchedule[];
  /** esta activa o no **/
  isActive?: boolean;
  typeOperator?: string;
  warehouses?: WarehousePA[];
  sendEmail?: boolean;
  warehouseId?: string;
}

export interface StoreServerModel {
  brand: string;
  saleChannel: string;
  country: string[] | CountryServerModel[];
  currency: string;
  city: string;
  typesDelivery: TypeDelivery[];
}

export interface ExternalShopModel {
  externalShopSecret?: string;
  externalShopName?: string;
}

export interface ShopServerModel {
  id?: string;
  currency?: string;
  created?: string;
  salesChannel?: string;
  delivery?: DeliveryServerModel[];
  country?: string | CountryServerModel[];
  merchantName?: string;
  commerceId?: string;
  city?: string;
  ean?: string;
  storeJanisId?: string;
  sucOrContractNum?: string;
  rucOrId?: string;
  isExternal?: boolean;
  externalShops?: ExternalShopModel[];
  /* ya no se usa en las nuevas versiones pero las antiguas si */
  externalShopSecret?: string;
  externalShopName?: string;
  status?: boolean;
  operator?: string;
  service?: any;
  //solo se usa para actualizar tienda y se pasa el warehouse
  warehouses?: WarehousePA[];
  sendEmail?: boolean;
}

class Store {
  data: StoreModel;

  constructor(data: StoreModel) {
    this.data = data;
  }

  static isParameterInvalid(param?: string): boolean {
    if (param) {
      return param.indexOf('Error:') !== -1;
    }
    return false;
  }

  static removeFlag(param?: string): string {
    if (param) {
      return param.replace('Error:', '');
    }
    return '';
  }

  static async onFilterList({
    val,
    classAll,
    referenceValue,
    onFilter,
    field
  }: {
    val?: string | string[];
    classAll?: any[];
    referenceValue?: string;
    field?: string;
    onFilter?: (params: { field: string; data: any; val?: string | string[] }) => void;
  }) {
    const typesOrder: string[] = referenceValue?.split('.') || [];
    const classFilter = classAll?.filter(orderCurrent => {
      let valueFormat: any = orderCurrent;
      typesOrder.forEach(typeOrder => {
        valueFormat = valueFormat[typeOrder];
      });
      console.log('typesOrder ==>', { val, valueFormat });
      if (typeof val !== 'string') {
        const found = val?.find(va => va.toLowerCase() === `${valueFormat}`?.toLowerCase());
        if (found !== undefined) {
          return true;
        }
        return false;
      } else {
        return `${valueFormat}`?.toLowerCase().indexOf(val.toLowerCase()) !== -1;
      }
      return [];
    });
    if (onFilter) {
      onFilter({ val, field: field || '', data: classFilter });
    }
    return myArrayWithNoDuplicates(
      classFilter?.map(order => {
        let valueFormat: any = order;
        typesOrder.forEach(typeOrder => {
          valueFormat = valueFormat[typeOrder];
        });
        return valueFormat || '';
      }) || []
    );
  }

  static onSortList({
    isSort,
    onSort,
    classAll,
    referenceValue,
    field,
    type = 'default'
  }: {
    isSort?: boolean;
    classAll: any[];
    onSort?: (params: { isSort?: boolean; field?: string; data?: any[] }) => void;
    referenceValue?: string;
    field?: string;
    type?: 'default' | 'date';
  }) {
    let orderSort: any[] = classAll;
    if (classAll && referenceValue) {
      orderSort = classAll.sort((classA, classB) => {
        let orderAFormat: any = classA;
        let orderBFormat: any = classB;
        const refValuesSplit = referenceValue.split('.');
        refValuesSplit.forEach(refValueSplit => {
          orderAFormat = orderAFormat[refValueSplit];
          orderBFormat = orderBFormat[refValueSplit];
        });
        //console.log('onSortList() =>', { orderBFormat, orderAFormat, referenceValue, orders });
        if (type === 'default') {
          if ((orderBFormat || '').toUpperCase() > (orderAFormat || '').toUpperCase()) {
            return isSort ? -1 : 1;
          } else if ((orderBFormat || '').toUpperCase() < (orderAFormat || '').toUpperCase()) {
            return isSort ? 1 : -1;
          }
          return 0;
        } else if (type === 'date') {
          if (
            isBeforeDate({
              dateBase: orderBFormat,
              dateReference: orderAFormat,
              formatBase: FormatDate.yyyymmddAlthmma,
              formatRef: FormatDate.yyyymmddAlthmma
            })
          ) {
            return isSort ? -1 : 1;
          } else if (
            isAfterDate({
              dateBase: orderBFormat,
              dateReference: orderAFormat,
              formatBase: FormatDate.yyyymmddAlthmma,
              formatRef: FormatDate.yyyymmddAlthmma
            })
          ) {
            return isSort ? 1 : -1;
          }
          return 0;
        }
        return 0;
      });
    }
    if (onSort) {
      //console.log('onSort() ==>', { orderSort });
      onSort({ isSort, field, data: orderSort });
    }
  }

  static headerTable({
    //onDeleted,
    showModalUpdate
  }: {
    onDeleted: (item: Store) => void;
    showModalUpdate: (item: Store) => void;
  }): TableColumn<Store>[] {
    return [
      {
        name: <FilterHeaderColumn title='' />,
        selector: row => Store.removeFlag(`${row.data.isActive}`),
        reorder: true,
        minWidth: '5px',
        maxWidth: '5px',
        conditionalCellStyles: [
          {
            when: row => row.data.isActive || false,
            classNames: ['cell-succes']
          },
          {
            when: row => row.data.isActive === false,
            classNames: ['cell-disabled']
          }
        ],
        cell: () => <div className='w-1 h-full' />
      },
      {
        name: <FilterHeaderColumn title='Nombre' />,
        selector: row => Store.removeFlag(row.data.brand),
        //sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(row.data.brand),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={Store.removeFlag(row.data.brand)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Divisa' />,
        selector: row => Store.removeFlag(row.data.currency),
        //sortable: true,
        reorder: true,
        maxWidth: '50px',
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(row.data.currency),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={Store.removeFlag(row.data.currency)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Sale channel' />,
        selector: row => Store.removeFlag(`${row.data.saleChannel?.data.referenceId}`),
        //sortable: true,
        reorder: true,
        maxWidth: '115px',
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(`${row.data.saleChannel?.data.referenceId}`),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            value={Store.removeFlag(`${row.data.saleChannel?.data.referenceId}`)}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn title='Ciudad' />,
        selector: row => Store.removeFlag(row.data.city),
        //sortable: true,
        reorder: true,
        maxWidth: '150px',
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(row.data.city),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={Store.removeFlag(`${row.data.city}`)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Tipos de envío' />,
        selector: row => Store.removeFlag(row.data.typesDelivery?.map(typeDelivery => typeDelivery.data.name).join(', ')),
        //sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(row.data.typesDelivery?.map(typeDelivery => typeDelivery.data.name).join(', ')),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            value={Store.removeFlag(row.data.typesDelivery?.map(typeDelivery => typeDelivery.data.name).join(', '))}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn title='RUT/ID' />,
        selector: row => Store.removeFlag(row.data.rutOrId),
        //sortable: true,
        reorder: true,
        maxWidth: '200px',
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(row.data.rutOrId),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={Store.removeFlag(row.data.rutOrId)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='SUC/Número de contrato' />,
        selector: row => Store.removeFlag(row.data.sucOrContractNum),
        reorder: true,
        minWidth: '200px',
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(row.data.sucOrContractNum),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={Store.removeFlag(row.data.sucOrContractNum)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Tipo de operador' />,
        selector: row => Store.removeFlag(`${row.data.typeOperator}`),
        reorder: true,
        minWidth: '150px',
        conditionalCellStyles: [
          {
            when: row => Store.isParameterInvalid(`${row.data.typeOperator}`),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={Store.removeFlag(`${row.data.typeOperator || ''}`)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: 'Opciones',
        cell: row => (
          <div className='flex flex-row'>
            <button onClick={() => showModalUpdate(row)}>
              <IconCustom name={NameIcons.rename} className='text-grayarauco-400' />
            </button>

            {/**
            <button onClick={() => onDeleted(row)}>
              <IconCustom name={NameIcons.deleted} />
            </button>
            **/}
          </div>
        )
      }
    ];
  }

  updateForm(values: StoreModel) {
    this.data.ean = values.ean;
    this.data.city = values.city;
    this.data.brand = values.brand;
    this.data.country = values.country;
    this.data.currency = values.currency;
    this.data.typesDelivery = values.typesDelivery;
    this.data.saleChannel = values.saleChannel;
    this.data.storeJanis = values.storeJanis;
    this.data.rutOrId = values.rutOrId;
    this.data.sucOrContractNum = values.sucOrContractNum;
    this.data.isExternal = values.isExternal;
    this.data.externalShopSecret = values.externalShopSecret;
    this.data.externalShopName = values.externalShopName;
    this.data.externalShops = values.externalShops;
    this.data.particularShippingPolicies = values.particularShippingPolicies;
    this.data.isActive = values.isActive;
    this.data.typeOperator = values.typeOperator;
    this.data.sendEmail = values.sendEmail;
  }

  updateIsHasOrders(value?: boolean) {
    this.data.isHasOrders = value;
  }

  onUpdateWarehouse({ warehouses }: { warehouses?: WarehousePA[] }) {
    const { typesDelivery } = this.data;

    if (typesDelivery && typesDelivery.length > 0) {
      this.data.warehouses = warehouses?.filter(warehouse => typesDelivery.find(typeD => typeD.data.warehouse === warehouse.data.id));
    }
  }

  sendServer() {
    const {
      brand,
      saleChannel,
      country,
      currency,
      city,
      ean,
      typesDelivery,
      storeJanis,
      rutOrId,
      sucOrContractNum,
      isExternal,
      externalShops,
      isActive,
      typeOperator,
      sendEmail
    } = this.data;
    let countries = '';
    let services = [];
    const countryFormat: any = country;

    if (countryFormat && countryFormat.length) {
      countries = countryFormat[0].data.value;
    } else if (countryFormat) {
      countries = countryFormat?.data.value || '';
    }
    const commonData = {
      name: 'janis',
      salesChannel: saleChannel?.data.referenceId || '',
      storeJanisId: storeJanis?.data.id,
      ean: ean || '1',
      warehouseId: storeJanis?.data.warehouseId
    };

    if (typesDelivery && typesDelivery.length > 1) {
      services = (typesDelivery as any).reduce((pre: any, curr: Delivery) => {
        console.log('pres , curr', { pre, curr });
        try {
          const indexFind = pre.findIndex((tD: any) => tD.warehouse === curr.data.warehouse);
          if (indexFind !== -1) {
            pre[indexFind].delivery.push(curr.sendServerStore());
          } else {
            pre.push({
              ...commonData,
              warehouse: curr.data.warehouse,
              delivery: [curr.sendServerStore()]
            });
          }
          return pre;
        } catch (err) {
          console.log('err', err);
          if (pre.data.warehouse === curr.data.warehouse) {
            return [
              {
                ...commonData,
                warehouse: curr.data.warehouse,
                delivery: [curr.sendServerStore(), pre.sendServerStore()]
              }
            ];
          } else {
            return [
              {
                ...commonData,
                warehouse: pre.data.warehouse,
                delivery: [pre.sendServerStore()]
              },
              {
                ...commonData,
                warehouse: curr.data.warehouse,
                delivery: [curr.sendServerStore()]
              }
            ];
          }
        }
      });
    } else {
      const data = typesDelivery as any;

      const delivery = data?.map((curr: Delivery) => {
        return curr.sendServerStore();
      });

      services = [
        {
          ...commonData,
          warehouse: data[0].data.warehouse,
          delivery
        }
      ];
    }

    console.log('sendServer() => { saleChannel, storeJanis, typesDelivery }', { saleChannel, storeJanis, typesDelivery });

    const sendValue: any = {
      merchantName: brand || '',
      status: isActive || false,
      country: countries,
      currency: currency || '',
      city: city || '',
      rucOrId: rutOrId,
      operator: typeOperator,
      isExternal: isExternal || false,
      externalShops: externalShops ? externalShops : [],
      service: services,
      sendEmail: sendEmail || false
    };

    if (sucOrContractNum) {
      sendValue.sucOrContractNum = sucOrContractNum;
    }
    return sendValue;
  }

  static onSort(stores?: Store[]) {
    if (stores && stores.length > 0) {
      return stores.sort((storA: Store, storB: Store) => {
        if ((storA.data.brandSearch || '') < (storB.data.brandSearch || '')) {
          return -1;
        } else if ((storA.data.brandSearch || '') > (storB.data.brandSearch || '')) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }

  static formalizeData(data: ShopServerModel): StoreModel {
    const {
      merchantName,
      id,
      currency,
      delivery,
      salesChannel,
      country,
      commerceId,
      city,
      ean,
      storeJanisId,
      rucOrId,
      sucOrContractNum,
      isExternal,
      externalShops,
      status,
      operator,
      service,
      warehouses,
      sendEmail
    } = data;

    let countries: Country[] | Country | undefined;
    let saleChannel = salesChannel ? new SaleChannel({ referenceId: salesChannel }) : undefined;
    let storeJID = storeJanisId;
    let eanCurrent = ean;
    let typesDelivery = delivery && delivery.length > 0 ? delivery.map(delive => new Delivery(Delivery.formalizeData(delive))) : [];
    let warehouse: WarehousePA[] | undefined;

    if (country && typeof country !== 'string' && country.length > 0) {
      countries = country.map(ctry => new Country(Country.formalizeData({ name: ctry.country, value: ctry.id, id: ctry.id })));
    } else if (country && typeof country === 'string') {
      countries = new Country(Country.formalizeData({ name: country, value: country }));
    }

    console.log('service', service);
    if (service && service.length > 0) {
      saleChannel = new SaleChannel({ referenceId: service[0].salesChannel || '' });
      storeJID = service[0].storeJanisId;
      eanCurrent = service[0].ean;
      typesDelivery = [];
      service.forEach((serv: any) => {
        typesDelivery.push(
          ...serv.delivery?.map((deli: any) => new Delivery(Delivery.formalizeData({ ...deli, warehouse: serv.warehouse })))
        );
      });
      warehouse = warehouses?.filter(warehouse => service.find((serv: any) => serv.warehouse === warehouse.data.id));
    }

    return {
      id,
      brand: merchantName,
      brandSearch: merchantName?.toLowerCase(),
      currency,
      saleChannel,
      country: countries || undefined,
      typesDelivery,
      commerceId,
      city,
      ean: eanCurrent,
      storeJanisId: storeJID,
      sucOrContractNum,
      rutOrId: rucOrId,
      isExternal,
      externalShops: externalShops,
      isActive: status,
      typeOperator: operator,
      warehouses: warehouse,
      sendEmail
    };
  }

  static formFields({
    countries,
    storesJanis,
    salesChannels,
    //typesDelivery,
    values,
    typesOperator,
    warehouses
  }: {
    countries: Country[];
    storesJanis: StoreJanis[];
    salesChannels: SaleChannel[];
    //typesDelivery: Delivery[];
    values?: StoreModel;
    typesOperator?: TypeOperator[];
    warehouses?: WarehousePA[];
  }): FormItemModel[] {
    //console.log('values()', { values, salesChannels });
    let country = values?.country;
    let saleChannel = values?.saleChannel;
    let storesJanisAvailable: StoreJanis[] = [];
    let typesDelivery: Delivery[] = [];

    if (values && values.storeJanis && salesChannels && salesChannels.length === 1) {
      saleChannel = salesChannels[0];
    }

    if (values && values.storeJanis && saleChannel && countries && countries.length > 0) {
      const countryFind = countries.filter(country => country.data.currency?.find(currency => currency === saleChannel?.data.currency));
      if (countryFind.length === 1) {
        country = countryFind[0];
      }
    }
    if (storesJanis && storesJanis.length > 0) {
      storesJanisAvailable = storesJanis.filter(storJ => storJ.data.available);
    }
    if (values && values.warehouses && values.warehouses.length > 0) {
      values.warehouses.map(warehouse => {
        if (typesDelivery.length > 0) {
          if (warehouse.data.delivery) {
            typesDelivery = [...typesDelivery, ...warehouse.data.delivery];
          }
        } else {
          if (warehouse.data.delivery) {
            typesDelivery = [...warehouse.data.delivery];
          }
        }
      });
    }
    console.log('formFields() =>', { storesJanisAvailable, values });
    //console.log('formFields() =>', { values, saleChannel, country });

    return [
      {
        label: 'Nombre de marca',
        type: 'text',
        identifier: 'brand',
        isRequired: true,
        value: values?.brand
      },
      {
        label: 'Tienda Janis',
        type: 'select',
        identifier: 'storeJanis',
        isRequired: true,
        data: storesJanisAvailable.map(storeJanis => ({ value: storeJanis.toString(), label: storeJanis.data.name, item: storeJanis })),
        value: storesJanisAvailable && storesJanisAvailable.length > 1 ? values?.storeJanis : storesJanisAvailable[0]
      },
      {
        label: 'Sales channel',
        type: 'select',
        identifier: 'saleChannel',
        data: salesChannels.map(saleChannel => ({
          value: saleChannel.toString(),
          label: saleChannel.data.referenceId,
          item: saleChannel
        })),
        //isRequired: true,
        value: saleChannel,
        disabled: true,
        tooltipMessage: 'Datos extraidos de Janis'
        //validateConstraints: VALIDATE_CONSTRAINTS.onlyNumberPositive
      },
      {
        label: 'País',
        type: 'select',
        //data: countries.map(country => ({ value: country.data.value, label: country.data.name, item: country })),
        data: [],
        identifier: 'country',
        //isRequired: true,
        value: country,
        dependentValue: {
          identifiers: 'saleChannel',
          conditionals: (saleChannel: SaleChannel) =>
            countries
              .filter(country => country.data.currency?.find(currency => currency === saleChannel?.data.currency))
              .map(country => ({ value: country.toString(), label: country.data.name, item: country }))
        },
        disabled: true,
        tooltipMessage: 'Datos extraidos de Janis'
      },
      {
        label: 'Moneda',
        type: 'select',
        data: [],
        identifier: 'currency',
        isRequired: true,
        value: values?.currency,
        dependentValue: {
          identifiers: 'country',
          conditionals: (country: Country) => {
            if (country && country.data.currency) {
              return country.data.currency?.map(cur => ({ value: cur, label: cur, item: cur }));
            }
            return [];
          }
        }
      },
      {
        label: 'RUT/ID',
        type: 'text',
        identifier: 'rutOrId',
        isRequired: true,
        value: values?.rutOrId,
        validateConstraints: VALIDATE_CONSTRAINTS.alphanumeric,
        maxLength: 20
      },
      {
        label: 'SUC/Número de contrato',
        type: 'text',
        identifier: 'sucOrContractNum',
        value: values?.sucOrContractNum,
        validateConstraints: VALIDATE_CONSTRAINTS.alphanumeric,
        maxLength: 20
      },
      {
        label: 'Ciudad',
        type: 'text',
        identifier: 'city',
        isRequired: true,
        value: values?.city,
        validateConstraints: VALIDATE_CONSTRAINTS.name
      },
      {
        label: 'Tipo de operador',
        type: 'select',
        isRequired: true,
        data:
          typesOperator?.map(typeOperator => ({
            label: typeOperator.data.name || '',
            item: typeOperator.data.value || '',
            value: typeOperator.data.value || ''
          })) || [],
        identifier: 'typeOperator',
        value: values?.typeOperator
      },
      {
        label: 'Bodega',
        type: 'selectMultiple',
        data:
          warehouses?.map(warehouse => ({
            value: warehouse.toString(),
            label: `${warehouse.data.description} (${warehouse.data.id})`,
            item: warehouse
          })) || [],
        identifier: 'warehouses',
        value: values?.warehouses,
        isRequired: true
      },
      {
        label: 'Tipos de envío',
        type: 'selectMultiple',
        data: typesDelivery.map(typeDelivery => ({
          value: typeDelivery.toString(),
          label: `${typeDelivery.data.name} - ${typeDelivery.data.warehouse}`,
          item: typeDelivery
        })),
        identifier: 'typesDelivery',
        value: values?.typesDelivery,
        isRequired: true
      },
      {
        label: 'Activa',
        type: 'boolean',
        identifier: 'isActive',
        value: values?.isActive
      },
      {
        label: '¿Tienda con inyección automática?',
        type: 'boolean',
        identifier: 'isExternal',
        value: values?.isExternal
      },
      {
        label: '¿Tienda con envío de emails?',
        type: 'boolean',
        identifier: 'sendEmail',
        value: values?.sendEmail
      },
      {
        label: 'Tiendas Externas',
        type: 'custom',
        componentRender: ({ hide, value, onValidate, onChange }) => (
          <ExternalShopForm hiden={hide} shopsExternal={value || []} {...{ onValidate, onChange }} />
        ),
        hide: !values?.isExternal,
        identifier: 'externalShops',
        //value: values?.externalShopSecret,
        value: values?.externalShops,
        isRequired: values?.isExternal
      }
    ];
  }
}

Store.prototype.toString = function saleChannelToString() {
  return `${this.data.brand}-${this.data.id}`;
};

export default Store;
