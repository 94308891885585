import { useState, useContext } from 'react';

import { NameIcons, SizeScreen, TypeDelivery } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';
import ButtonCustom from 'components/atoms/buttons';
import FormMolecule from 'components/molecules/forms';
import BoardCustom from 'components/molecules/board';
import ModalCustom from 'components/molecules/modals';

import { reducerDelivery, shippingTypeStringToDisplay, stringDeliveryToIcon } from 'helpers/Converts';

import DeliverySchedule from 'models/delivery/DeliverySchedule';
import { FormItemModel } from 'models/form';

type BoardDeliveryScheduleProps = {
  schedules: DeliverySchedule[];
  onUpdateCutOffTimeSetting: (form: any) => void;
  onCreateCutOffTimeSetting: (form: any) => void;
  onDeletedCutOffTimeSetting: (form: any) => void;
  title?: string;
};

const BoardDeliverySchedule = ({
  schedules,
  onUpdateCutOffTimeSetting,
  onCreateCutOffTimeSetting,
  onDeletedCutOffTimeSetting,
  title
}: BoardDeliveryScheduleProps) => {
  const { showModalQuestion, screenResolution } = useContext(RootContext);
  const { typesDelivery } = useContext(AuthContext);

  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [deliveryScheduleCurrent, setDeliveryScheduleCurrent] = useState<DeliverySchedule>();
  const [itemsForm, setItemsForm] = useState<FormItemModel[]>([]);

  const showModalUpdate = (deliverySchedule: DeliverySchedule) => {
    const updateScheduleCurrent = new DeliverySchedule({ ...deliverySchedule.data });
    setDeliveryScheduleCurrent(updateScheduleCurrent);
    //console.log('delivery', deliveryScheduleCurrent);
    setItemsForm(DeliverySchedule.formFieldsUpdate({ values: updateScheduleCurrent }));
    setIsModalUpdate(true);
  };

  const showModalCreate = () => {
    const newScheduleCurrent = new DeliverySchedule({});
    setDeliveryScheduleCurrent(newScheduleCurrent);
    //console.log('delivery', {
    //schedules,
    //typesDelivery,
    //filter: typesDelivery.filter(typeDelivery => !schedules.find(schedule => schedule.data.delivery === typeDelivery.data.value))
    //});
    setItemsForm(
      DeliverySchedule.formFieldsAdd({
        typesDelivery: typesDelivery.filter(typeDelivery => {
          return !schedules.find(schedule => {
            const sched = typeof schedule.data.delivery === 'string' ? schedule.data.delivery : schedule.data.delivery?.data.value;
            return sched === typeDelivery.data.value;
          });
        }),
        values: newScheduleCurrent
      })
    );
    setIsModalAdd(true);
  };

  const showModalDelete = (deliverySchedule: DeliverySchedule) => {
    setDeliveryScheduleCurrent(deliverySchedule);
    showModalQuestion({
      title: `¿Desea eliminar la configuración ${shippingTypeStringToDisplay(deliverySchedule.data.delivery as string)}?`,
      options: [{ text: 'Si', onAction: () => onDeletedCutOffTimeSetting(deliverySchedule) }, { text: 'No' }]
    });
  };

  const validateAddSchedules = () => {
    const isValidate = typesDelivery.every(typeDelivery =>
      schedules.find(schedule => {
        const sched = typeof schedule.data.delivery === 'string' ? schedule.data.delivery : schedule.data.delivery?.data.value;
        return typeDelivery.data.value === sched;
      })
    );
    //console.log('validateAddSchedules() =>', isValidate);
    return isValidate;
  };

  return (
    <>
      {title && (
        <div className='bg-white flex flex-row px-10 pt-8 pb-0 sm:pb-8'>
          <h1 className='mb-4'>{title}</h1>
          <ButtonCustom className='ml-4' disabled={validateAddSchedules()} onClick={() => showModalCreate()}>
            <IconCustom name={NameIcons.add} />
          </ButtonCustom>
        </div>
      )}

      <div className='mx-5 sm:mx-10'>
        {screenResolution >= SizeScreen.md && (
          <BoardCustom
            data={schedules.filter(sch => sch.data.delivery !== TypeDelivery.pickupStore && sch.data.delivery !== TypeDelivery.pickup)}
            columns={DeliverySchedule.headerTable({ showModalUpdate, onDeleted: showModalDelete })}
            persistTableHead
            pagination={false}
            title={
              <div className='flex overflow-clip justify-between mt-2'>
                <h1 className='overflow-clip text-base font-normal'>Configuración de la fecha y hora estimada de entrega</h1>

                {!title && (
                  <ButtonCustom className='ml-2' disabled={validateAddSchedules()} onClick={() => showModalCreate()}>
                    <IconCustom name={NameIcons.add} />
                  </ButtonCustom>
                )}
              </div>
            }
          />
        )}

        {screenResolution >= SizeScreen.md && (
          <div className='mt-4'>
            <BoardCustom
              data={schedules.filter(sch => sch.data.delivery === TypeDelivery.pickupStore || sch.data.delivery === TypeDelivery.pickup)}
              columns={DeliverySchedule.headerTable({ showModalUpdate, onDeleted: showModalDelete, isPickup: true })}
              persistTableHead
              pagination={false}
            />
          </div>
        )}

        {screenResolution < SizeScreen.md && schedules.length > 0 && (
          <div className='bg-grayarauco-200'>
            {schedules.map(sche => {
              return (
                <div key={sche.data.commerce} className='px-3 bg-white' style={{ borderBottom: 2 }}>
                  <div className='flex items-center border-grayarauco-200 flex-row' style={{ borderBottomWidth: 1 }}>
                    <IconCustom name={stringDeliveryToIcon(sche.data.delivery as string)} className='text-lg font-bold text-primary' />
                    <p className='text-sm font-bold text-primary'>{shippingTypeStringToDisplay(sche.data.delivery as string)}</p>
                  </div>

                  <div>
                    <p className='text-sm'>
                      Hora apertura: <strong>{sche.data.open || '-'}</strong>
                    </p>
                    <p className='text-sm'>
                      Hora de última recepción: <strong>{sche.data.stopDelivery || '-'}</strong>
                    </p>
                    <p className='text-sm'>
                      Hora de cierre {reducerDelivery(sche.data.delivery as string) === TypeDelivery.delivery ? 'despacho' : 'de Pickup'}
                      {': '}
                      <strong>{sche.data.close || '-'}</strong>
                    </p>
                    <p className='text-sm'>
                      {reducerDelivery(sche.data.delivery as string) === TypeDelivery.delivery
                        ? 'Tiempo despacho'
                        : 'Max. días almacenamiento'}
                      {': '}
                      <strong>{sche.data.time || '-'}</strong>
                    </p>
                  </div>

                  <div className='w-full flex justify-end'>
                    <IconCustom onClick={() => showModalUpdate(sche)} name={NameIcons.rename} className='text-lg text-grayarauco-400' />
                    <IconCustom onClick={() => showModalDelete(sche)} name={NameIcons.deleted} className='text-lg text-grayarauco-400' />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/** modal para actulizar el horario de corte **/}
      {deliveryScheduleCurrent && (
        <ModalCustom isVisible={isModalUpdate} styleContent={{ padding: 0 }} onClose={() => setIsModalUpdate(false)}>
          <FormMolecule
            classNameContainer='xl:w-full'
            classNameContent='border-t-2 border-grayarauco-200 pt-4 px-4 md:grid-cols-3 sm:grid-cols-2'
            title={`Horario ${shippingTypeStringToDisplay(deliveryScheduleCurrent.data.delivery as string)}`}
            items={itemsForm}
            textBtnSubmit='Actualizar'
            textBtnCancel='Cancelar'
            onChangeForm={form => {
              console.log('onChangeForm() ==>', { form, deliveryScheduleCurrent });
              deliveryScheduleCurrent.updateInfoForm(form);
              setDeliveryScheduleCurrent(deliveryScheduleCurrent);
            }}
            onCancel={() => {
              setIsModalUpdate(false);
              setDeliveryScheduleCurrent(undefined);
              setItemsForm([]);
            }}
            onSubmit={() => {
              onUpdateCutOffTimeSetting(deliveryScheduleCurrent);
              setIsModalUpdate(false);
              setDeliveryScheduleCurrent(undefined);
              setItemsForm([]);
            }}
          />
        </ModalCustom>
      )}

      {deliveryScheduleCurrent && (
        <ModalCustom isVisible={isModalAdd} onClose={() => setIsModalAdd(false)}>
          <FormMolecule
            classNameContainer='xl:w-full'
            classNameContent='md:grid-cols-3 sm:grid-cols-2'
            title='Agregar horario para tipo de envío'
            items={itemsForm}
            textBtnSubmit='Crear'
            textBtnCancel='Cancelar'
            onChangeForm={form => {
              console.log('onChangeForm() ==>', { form, typesDelivery, schedules });
              if (form.delivery !== deliveryScheduleCurrent.data.delivery) {
                form.time = '';
              }
              deliveryScheduleCurrent.updateInfoForm(form);
              setDeliveryScheduleCurrent(deliveryScheduleCurrent);
              setItemsForm(
                DeliverySchedule.formFieldsAdd({
                  typesDelivery: typesDelivery.filter(typeDelivery => {
                    return !schedules.find(schedule => {
                      const sched =
                        typeof schedule.data.delivery === 'string' ? schedule.data.delivery : schedule.data.delivery?.data.value;
                      return sched === typeDelivery.data.value;
                    });
                  }),
                  values: deliveryScheduleCurrent
                })
              );
            }}
            onSubmit={() => {
              onCreateCutOffTimeSetting(deliveryScheduleCurrent);
              setIsModalAdd(false);
              setDeliveryScheduleCurrent(undefined);
              setItemsForm([]);
            }}
            onCancel={() => {
              setIsModalAdd(false);
              setDeliveryScheduleCurrent(undefined);
              setItemsForm([]);
            }}
          />
        </ModalCustom>
      )}
    </>
  );
};

export default BoardDeliverySchedule;
