import { ResponseApp } from 'configs/Interface';

import DeliverySchedule, { DeliveryScheduleServerModel } from 'models/delivery/DeliverySchedule';

import DeliveryProvider from 'services/api/Delivery';

export const getDeliveriesConfig = async (country?: string): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await DeliveryProvider.getDeliveriesConfig();
    console.log('getDeliveriesConfig() ==> { data, status, ...other }', { data, status, ...other });

    if (status === 200) {
      if (data.delivery && data.delivery.length > 0) {
        return {
          status: true,
          data: data.delivery
            .map((delive: DeliveryScheduleServerModel) => new DeliverySchedule(DeliverySchedule.formalizeData(delive)))
            .filter((delive: DeliverySchedule) => delive.data.country === country)
        };
      }
      return { status: false, message: 'No hay horarios de los envíos.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los horarios de los envíos.' };
  } catch (err) {
    return { status: false, message: `No fue posible obtener los horarios de los envíos: ${(err as Error).message}` };
  }
};

export const getDeliveriesConfigByCommerce = async ({ country, commerceId }: { country?: string; commerceId?: string }) => {
  try {
    const { data, status, ...other } = await DeliveryProvider.getDeliveriesConfigByCommerce(commerceId);

    if (status === 200) {
      if (data.delivery && data.delivery.length > 0) {
        return {
          status: true,
          data: data.delivery
            .map((delive: DeliveryScheduleServerModel) => new DeliverySchedule(DeliverySchedule.formalizeData(delive)))
            .filter((delive: DeliverySchedule) => delive.data.country === country)
        };
      }
      return { status: false, message: 'No hay horarios de los envíos.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los horarios de los envíos.' };
  } catch (err) {
    return { status: false, message: `No fue posible obtener los horarios de los envíos: ${(err as Error).message}` };
  }
};

export const createDeliveryConfig = async (deliverySchedule: DeliverySchedule): Promise<ResponseApp> => {
  try {
    console.log('createDeliveryConfig() => deliverySchedule', deliverySchedule.sendServer());
    const { data, status, ...other } = await DeliveryProvider.createDeliveryConfig(deliverySchedule);
    console.log('createDeliveryConfig() ==>', { data, status, other });

    if (status === 201 || status === 200) {
      return { status: true, message: data.messagge || 'Se creo con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible crear la configuración de envío en este momento.' };
  } catch (err) {
    return { status: false, message: `No fue posible crear la configuración de envío en este momento: ${(err as Error).message}` };
  }
};

export const createDeliveryConfigs = async (deliverySchedules: DeliverySchedule[]) => {
  try {
    for await (const deliverySchedule of deliverySchedules) {
      const { status } = await createDeliveryConfig(deliverySchedule);
      console.log('createDeliveryConfigs() => status', status);
    }
    return { status: true };
  } catch (err) {
    return { status: false, message: `No fue posible crear la configuración de envío en este momento: ${(err as Error).message}` };
  }
};

export const updateDeliveryConfigById = async (deliverySchedule: DeliverySchedule): Promise<ResponseApp> => {
  try {
    //console.log('updateDeliveryConfigById() => deliverySchedule', deliverySchedule.sendServer());
    const { data, status, ...other } = await DeliveryProvider.updateDeliveryConfigById(deliverySchedule);
    console.log('updateDeliveryConfigById() ==>', { data, status, other });

    if (status === 201 || status === 200) {
      return { status: true, message: data.delivery || 'Se actualizo con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible actualizar en este momento.' };
  } catch (err) {
    return { status: false, message: `No fue posible actualizar en este momento: ${(err as Error).message}` };
  }
};

export const deletedDeliveryConfigById = async (deliverySchedule: DeliverySchedule): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await DeliveryProvider.deleteDeliveryConfigById(deliverySchedule);
    console.log('deletedDeliveryConfigById() ==>', { data, status, other });

    if (status === 200) {
      return { status: true, message: data.message || 'Se elimino con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible eliminar en este momento.' };
  } catch (err) {
    return { status: false, message: `No fue posible eliminar en este momento: ${(err as Error).message}` };
  }
};

export const deletedDeliveryConfigs = async (deliverySchedules: DeliverySchedule[]) => {
  try {
    for await (const deliverySchedule of deliverySchedules) {
      const { status } = await deletedDeliveryConfigById(deliverySchedule);
      console.log('deletedDeliveryConfigs() => status', status);
    }
    return { status: true };
  } catch (err) {
    return { status: false, message: `No fue posible crear la configuración de envío en este momento: ${(err as Error).message}` };
  }
};
