export interface DimensionModel {
  name: string;
  value: string;
}

export interface DimensionServerModel {
  name: string;
}

class Dimension {
  data: DimensionModel;

  constructor(data: DimensionModel) {
    this.data = data;
  }

  static formalizeData(data: DimensionServerModel): DimensionModel {
    const { name } = data;

    return {
      name,
      value: name
    };
  }
}

Dimension.prototype.toString = function dimensionToString() {
  return `${this.data.value}`;
};

export default Dimension;
