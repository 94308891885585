import { useEffect, useState } from 'react';

// si el usuario esta usando la plataforma.
const useAreInApp = () => {
  const [isUse, setIsUse] = useState(true);

  useEffect(() => {
    window.onfocus = () => {
      //console.log('onfocus ==>', { isUse });
      if (!isUse) {
        setIsUse(true);
      }
    };
    window.onblur = () => {
      //console.log('onblur ==>', { isUse });
      if (isUse) {
        setIsUse(false);
      }
    };
    window.onmousemove = () => {
      //console.log('onmousemove ==>', { isUse });
      if (!isUse) {
        setIsUse(true);
      }
    };
  }, [isUse]);

  return isUse;
};

export default useAreInApp;
