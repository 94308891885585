export enum PathRoute {
  root = '/',
  signin = '/signin',
  reconverPassword = '/recover-password',
  newPassword = '/new-password',
  signup = '/signup',
  menu = '/menu',
  dashboard = '/menu/dashboard',
  uploadFile = '/menu/upload-file',
  listOrders = '/menu/list-orders',
  listFailedOrders = '/menu/list-failed-orders',
  unitManualLoad = '/menu/unit-manual-load',
  billing = '/menu/billing',
  createUser = '/menu/create-user',
  createStore = '/menu/create-store',
  cutOffScheduleSetting = '/menu/cut-off-schedule-setting',
  updateHandbook = '/menu/update-handbook',
  frequentQuestions = '/menu/frequent-questions',
  frequentQuestionsList = '/menu/frequent-questions-list',
  orderDetail = '/menu/order-detail',
  orderAlertSettings = '/menu/order-alert-settings',
  welcome = '/menu/welcome'
}

export enum TypeToast {
  error = 'error',
  success = 'success',
  info = 'info',
  custom = 'custom'
}

export enum StyleButton {
  bgPrimary = 'bgPrimary',
  outlinePrimary = 'outlinePrimary',
  link = 'link'
}

export enum TypeAlertMessage {
  info = 'info',
  danger = 'danger',
  success = 'success',
  warning = 'warning'
}

export enum RoleUser {
  locatario = 'ROLE_TIENDA',
  cellarPA = 'ROLE_BODEGA',
  superAdmin = 'ROLE_SUPERADMIN',
  superAdminGlobal = 'ROLE_GLOBAL'
}

export enum UnitDate {
  years = 'y',
  hours = 'h',
  quarters = 'Q',
  months = 'M',
  weeks = 'w',
  days = 'd',
  minutes = 'm'
}

export enum FormatDate {
  yyyy = 'YYYY',
  yyyymmdd = 'YYYY-MM-DD',
  yyyymmddAlt = 'DD/MM/YYYY',
  ddmmaa = 'DD-MM-YY',
  ddmmaaaa = 'DD-MM-YYYY',
  ddmmaaAlt = 'DD/MM/YY',
  ddmmaaaaAlt = 'DD/MM/YYYY',
  none = 'none',
  yyyymmddHHmmAlt = 'YYYY-MM-DD HH:mm',
  yyyymmddHHmmssAlt = 'YYYY-MM-DD HH:mm:ss',
  yyyymmddHHmm = 'YYYY/MM/DD HH:mm',
  yyyymmddAltHHmm = 'DD/MM/YYYY HH:mm',
  yyyymmddAlthmma = 'DD/MM/YYYY h:mm a',
  yyyymm = 'YYYY-MM',
  mmyyyy = 'MM-YYYY',
  yyyyww = 'YYYY-WW',
  dd_mm_yy = 'DD_MM_YY',
  mmyyyyAlt = 'MM/YYYY',
  ddmmAlt = 'DD/MM'
}

export enum FormatTime {
  HHmm = 'HH:mm',
  hmmss = 'h:mm:ss',
  hmm = 'h:mm',
  hmma = 'h:mm a',
  HHmma = 'HH:mm a',
  hhmma = 'hh:mm a',
  HHmmssa = 'HH:mm:ss a',
  hmmssa = 'h:mm:ss A',
  HHmmss = 'HH:mm:ss'
}

export enum TypeDelivery {
  deliveryExpress = 'DELIVERY EXPRESS',
  deliverySameDay = 'DELIVERY SAME DAY',
  deliveryNextDay = 'DELIVERY NEXT DAY',
  pickup = 'pickup',
  delivery = 'delivery',
  pickupStore = 'store_pickup',
  sameday = 'sameday',
  nextday = 'nextday',
  express = 'express',
  sameDay = 'same_day',
  expressDelivery = 'express_delivery',
  nextDay = 'next_day'
}

export enum TypeDeliveryDisplay {
  pickupStore = 'Pickup',
  sameDay = 'Same day',
  expressDelivery = 'Delivery express',
  nextDay = 'Next day'
}

/** usado principalmente para los gráficos **/
export enum TypeTimeInterval {
  day = 'dia',
  week = 'semana',
  month = 'mes',
  quarterly = 'trimestre',
  year = 'anio',
  dayO = 'day',
  monthO = 'month',
  weekO = 'week'
}

export enum NameIcons {
  visibility = 'visibility',
  visibilityOff = 'visibility_off',
  fileOpen = 'folder_open',
  menu = 'menu',
  update = 'update',
  deleted = 'delete',
  add = 'add',
  arrowDropDown = 'arrow_drop_down',
  south = 'south',
  download = 'download',
  close = 'close',
  arrowDown = 'keyboard_arrow_down',
  arrowUp = 'keyboard_arrow_up',
  quiz = 'quiz',
  notification = 'notifications',
  notificationActive = 'notifications_active',
  hourglassFull = 'hourglass_full',
  traffic = 'traffic',
  checkCircle = 'check_circle',
  error = 'error',
  networkOff = 'sensors_off',
  info = 'info',
  contentCopy = 'content_copy',
  inventory = 'inventory_2',
  inventoryTwo = 'inventory',
  store = 'storefront',
  viewList = 'view_list',
  //delivery
  pickup = 'pin_drop',
  nextDay = 'dark_mode',
  deliveryExpress = 'bolt',
  sameDay = 'light_mode',
  done = 'done',
  calendarMonth = 'calendar_month',
  schedule = 'schedule',
  navigateBefore = 'navigate_before',
  alarm = 'alarm',
  ticket = 'confirmation_number',
  uploadFile = 'upload_file',
  help = 'help_center',
  listAlt = 'list_alt',
  playlistAddCheck = 'playlist_add_check',
  playlistRemove = 'playlist_remove',
  person = 'person',
  warehouse = 'warehouse',
  whereToVote = 'where_to_vote',
  route = 'route',
  warning = 'warning',
  filter = 'filter_alt',
  search = 'search',
  cancel = 'cancel',
  thumbUp = 'thumb_up',
  today = 'today',
  tune = 'tune',
  cropFree = 'crop_free',
  rename = 'drive_file_rename_outline',
  image = 'image',
  arrowRigth = 'arrow_forward_ios'
}

export enum EnumCountries {
  cl = 'CL',
  per = 'PER',
  col = 'COL',
  all = 'ALL'
}

export enum SizeScreen {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  '2xl' = 1536
}

export enum EnumDocumentType {
  ci = 'CI',
  rut = 'RUT',
  cc = 'CC',
  ce = 'CE',
  dni = 'DNI',
  passport = 'pasaporte'
}

export enum EnumTypeDownloadFile {
  template = 'Template',
  handbook = 'Manual',
  /** manual unitario carga manual **/
  handbookManualUnitLoading = 'ManualUnit'
}

export enum EnumTypeFile {
  pdf = 'pdf',
  xls = 'xls'
}

export enum DashboardOrganismSections {
  general = 'general',
  performanceDelivery = 'delivery',
  performancePickup = 'pickup',
  business = 'business'
}

/** son los colores que debe tener una row de la tabla de ordenes **/
export enum EnumAlert {
  high = 'alto',
  half = 'medio',
  low = 'bajo'
}

export enum EnumTypeUpload {
  loadFromKomax = 'carga desde komax',
  loadFromShopify = 'carga desde shopify',
  loadFromLoadingPlay = 'carga desde loadingplay',
  manualLoading = 'carga manual',
  uploadByFile = 'carga por archivo'
}

export enum EnumIncomeType {
  new = 'new',
  read = 'read'
}

export enum EnumNotificationType {
  ordersNotificationFail = 'orders-notification-fail',
  ordersNotificationFileFinal = 'orders-notification-file-final',
  creationOrders = 'creation-orders',
  orderStatusChangeCancelled = 'order-status-change-cancelada',
  orderStatusChangeDelivered = 'order-status-change-entregada',
  orderStatusChangeDispatched = 'order-status-change-despachado',
  excelNotificationSuccess = 'excel-notification-success',
  errorNotification = 'error-notification',
  /** es como la nueva orden ingresada **/
  new = 'new'
}

export enum EnumTypeNotification {
  info = 'info',
  danger = 'danger',
  success = 'success',
  warning = 'warning'
}

export enum EnumStatusDelivery {
  created = 'orden creada',
  readyStorePickup = 'listo para retirar en tienda',
  pickUpInStore = 'orden retirada en tienda',
  receivedInWarehouse = 'orden recibida en bodega',
  onRoute = 'orden en ruta',
  delivered = 'orden entregada',
  notDelivered = 'orden no entregada',
  cancelled = 'orden cancelada',
  earring = 'orden pendiente',
  pendingDelivery = 'orden pendiente entrega',
  // nuevos
  orderPreparation = 'preparación pedido',
  readyToBeRetired = 'listo para ser retirado',
  inRoute = 'en ruta',
  successfulDelivery = 'entrega exitosa',
  orderCanceled = 'orden cancelada',
  orderNotDelivered = 'orden no entregada',
  confirmedOrder = 'pedido confirmado'
}

export enum EnumDeliverySummaryStatus {
  confirmedOrder = 'pedido confirmado',
  orderPreparation = 'preparacion pedido',
  readyToBeRemoved = 'listo para ser retirado',
  onRoute = 'en ruta',
  successfulDelivery = 'entrega exitosa',
  cancelled = 'cancelado',
  undelivered = 'no entregado'
}

export enum EnumStatesJanis {
  new = 'new',
  inAudit = 'inAudit',
  readyForPicking = 'readyForPicking',
  picking = 'picking',
  readyToProcessPicking = 'readyToProcessPicking',
  processingPicking = 'processingPicking',
  inPostPickingAudit = 'inPostPickingAudit',
  readyForInvoice = 'readyForInvoice',
  invoiced = 'invoiced',
  preparingInternalDistribution = 'preparingInternalDistribution',
  readyForInternalDistribution = 'readyForInternalDistribution',
  fulfillingInternalDistribution = 'fulfillingInternalDistribution',
  readyForDelivery = 'readyForDelivery',
  inDelivery = 'inDelivery',
  delivered = 'delivered',
  cancelationRequested = 'cancelationRequested',
  canceled = 'canceled',
  undelivered = 'undelivered',
  notdelivered = 'notdelivered',
  notDelivered = 'notDelivered'
}

export enum EnumFileUploadStates {
  inProcess = 'en proceso',
  complete = 'completo',
  cancelado = 'cancelado',
  error = 'error'
}

export enum EnumTypeOperator {
  dummy = 'prueba',
  etailer = 'minorista',
  store = 'store'
}

export enum EnumTypePerformance {
  delivery = 'delivery',
  pickup = 'pickup'
}

export enum EnumInvoiceSection {
  billingProgress = 'billingProgress',
  historicalBilling = 'historicalBilling'
}

export enum EnumTextPrice {
  pickup = 'Ingresa valor Neto (sin impuesto)',
  delivey = ' Ingresa valor Neto (sin impuesto) y sin costo de despacho'
}
