import { ResponseApp } from 'configs/Interface';

import User from 'models/User';

import AuthProvider from 'services/api/Auth';

import { removeTokenSession } from 'storages/Session';

//import { getTokenSession, mySession, removeTokenSession, setTokenSession } from 'storages/Session';

export const signInApp = async (formLogin: { email: string; password: string }): Promise<ResponseApp> => {
  try {
    //console.log('singInApp ===> formLogin', formLogin);
    const { status, data, ...other } = await AuthProvider.signin(formLogin);
    console.log('singInApp provider ===>', { status, data, other });

    if (status === 200) {
      if (data) {
        return { status: true, data: { token: data.token, user: new User(User.formalizeData(data.user)), date: data.nextDateToExpired } };
      }
    }
    if (data.token) {
      let months = '';
      let date = '';
      if (data.message && data.message.es) {
        const indexStart = (data.message.es as string).indexOf('El ') + 3;
        const indexLast = (data.message.es as string).lastIndexOf(' venció');
        const indexStartOther = (data.message.es as string).indexOf(' cada ') + 6;
        const indexLastOther = (data.message.es as string).indexOf(' meses');

        date = (data.message.es as string).slice(indexStart, indexLast);
        months = (data.message.es as string).slice(indexStartOther, indexLastOther);
      }
      return { status: false, data: { token: data.token, message: data.message.es, months, date } };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible ingresar.' };
  } catch (e) {
    return { status: false, message: 'No fue posible ingresar.' };
  }
};

export const recoverPassword = async (email: string): Promise<ResponseApp> => {
  try {
    const { status, ...other } = await AuthProvider.recoverPassword(email);
    console.log('recoverPassword() ===>', '{ status, other }', { status, other });

    if (status === 200 || status === 201) {
      return { status: true, message: 'Por favor revise su correo electrónico' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible recuperar su contraseña.' };
  } catch (e) {
    return { status: false, message: 'No fue posible recuperar su contraseña.' };
  }
};

export const changePassword = async (form: { password: string; token: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await AuthProvider.changePassword(form);
    console.log('changePassword() ===>', '{ status, other }', { status, other });

    if (status === 200 || status === 201) {
      //return { status: true, data: { user: new User(User.formalizeData(data.user)), token: data.token } };
      if ('message' in other) {
        return { status: true, message: other.message };
      }
      return { status: true, message: 'Su contraseña fue actualizada.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible recuperar su contraseña.' };
  } catch (e) {
    return { status: false, message: 'No fue posible cambiar su contraseña.' };
  }
};

export const restorePassword = async (form: { password: string; token: string }) => {
  try {
    const { status, data, ...other } = await AuthProvider.restorePassword(form);
    console.log('restorePassword() ===>', '{ status, other }', { status, other, data });

    if (status === 200 || status === 201) {
      //return { status: true, data: { user: new User(User.formalizeData(data.user)), token: data.token } };
      if (data.message) {
        return { status: true, data: { validity: data.message.validity, message: data.message.info.es, token: data.message.token } };
      }
      return { status: true, message: 'Su contraseña fue actualizada.' };
    }

    if (data) {
      return { status: false, data: data };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible cambiar su contraseña.' };
  } catch (e) {
    console.log('restorePassword() => e', e);
    return { status: false, message: 'No fue posible cambiar su contraseña.' };
  }
};

export const validateToken = async () => {
  try {
    const { status, data } = await AuthProvider.validateToken();
    console.log('validateToken() => {}', { status, data });

    if (status === 200) {
      return { status: true };
    }
    removeTokenSession();
    return { status: false };
  } catch (err) {
    removeTokenSession();
    console.log('validateToken() ==> err', err);
    return { status: false, messaga: 'No fue posible validar la password.' };
  }
};

//export const refreshToken = async (): Promise<ResponseApp> => {
//try {
//const { status, data } = await AuthProvider.refreshToken(mySession.token);

//if (status === 200) {
//setTokenSession(data.token, data.expiredIn);
//return { status: true };
//} else {
//removeTokenSession();
//return { status: false, message: data.message };
//}
//} catch (err) {
//console.log('refreshToken() => err', err);
//return { status: false, message: 'No fue posible validar el token.' };
//}
//};
