import { useState, useContext, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';

import { TypeToast, StyleButton, SizeScreen, EnumFileUploadStates } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import ListFailedOrdersOrganism from 'components/organisms/dashboard/order/ListFailedOrders';

import Order from 'models/order';
import FileOrders from 'models/files/FileOrders';

import { getOrdersFailed, deleteFailedOrders, getOrdersByNumOrder, postFixOrders } from 'services/app/Order';

const ListFailedOrdersPage = () => {
  const { showToast, showLoading, hideLoading, showNotificationPopUp, screenResolution } = useContext(RootContext);
  const { countryUser, onUpdateNumFailedOrders, storeSession } = useContext(AuthContext);
  const { onUpdateWarehouse, addUploadFile } = useContext(DataContext);

  //const navigate = useNavigate();

  const [orders, setOrders] = useState<Order[]>([]);

  const loadOrdersfailed = async () => {
    showLoading();
    let ordersCurrent: Order[] = [];

    const { status, message, data } = await getOrdersFailed({ country: countryUser });
    console.log('loadOrders ===> dashboard ===> ', { status, message, data, countryUser, storeSession });
    if (status) {
      let newStores = data.orders;
      if (storeSession) {
        newStores = (data.orders as Order[]).filter(order => order.data.commerceId === storeSession?.data.commerceId);
      }
      onUpdateNumFailedOrders(newStores.length);
      newStores.forEach((order: Order) => order.getErrors());
      setOrders(newStores);
      ordersCurrent = newStores;
    } else {
      showToast({ text: message || 'No fue posible cargar las órdenes.', type: TypeToast.error });
      onUpdateNumFailedOrders(0);
      setOrders([]);
      ordersCurrent = [];
    }

    hideLoading();
    return ordersCurrent;
  };

  const onDeleteFailedOrders = async (correctedOrders: Order[], notMessage?: boolean) => {
    if (correctedOrders.length === orders.length && !notMessage) {
      console.log('onDeleteFailedOrders => screenResolution', screenResolution);
      showNotificationPopUp({
        title: 'Eliminación órdenes',
        messageNotMarkdown: (
          <div className='flex justify-center items-center flex-col'>
            <h3 className='text-xl font-normal mb-4'>¿No vas a corregir tus órdenes?</h3>
            <p className='text-center text-xl font-normal'>
              Se eliminaran todas las órdenes fallidas, <strong className='font-semibold'>tendras que subir una nueva carga</strong>, con la
              corrección de estas órdenes.
            </p>
          </div>
        ),
        isClose: true,
        styleContainer: { maxWidth: screenResolution === SizeScreen.sm ? '95%' : '40%', minWidth: 'auto' },
        options: [
          {
            text: 'Cancelar',
            style: StyleButton.outlinePrimary
          },
          {
            text: 'Eliminar todo',
            style: StyleButton.bgPrimary,
            onAction: async () => {
              showLoading();
              const { status, message } = await deleteFailedOrders(orders);
              console.log('onDeleteFailedOrders() => ', { status, message });

              if (!notMessage) {
                if (status) {
                  showToast({ text: message, type: TypeToast.success });
                } else {
                  showToast({ text: message, type: TypeToast.error });
                }

                await loadOrdersfailed();
              } else {
                hideLoading();
              }
            }
          }
        ]
      });
    } else {
      showLoading();
      const { status, message } = await deleteFailedOrders(correctedOrders);
      console.log('onDeleteFailedOrders() => ', { status, message });

      if (!notMessage) {
        if (status) {
          showToast({ text: 'Se elimino con éxito.', type: TypeToast.success });
        } else {
          showToast({ text: message, type: TypeToast.error });
        }

        await loadOrdersfailed();
      } else {
        hideLoading();
      }
    }
  };

  const onDetail = async (order: string) => {
    showLoading();
    const { status, data, message } = await getOrdersByNumOrder({ numOrder: order });
    console.log('onDetail() => { status, data }', { status, data, num: order, message, order });

    if (status) {
      hideLoading();
      return {
        status,
        data: (data.orders as Order[]).find(ordCu => {
          const result = ordCu.data.id === order;
          console.log('for => ', { ordeTrim: ordCu.data.detail.data.order.data.order.trim(), isND: order, result });
          return result;
        })
      };
    } else {
      showToast({ text: message, type: TypeToast.error });
    }

    hideLoading();
    return { status: false };
  };

  const onCorrectOrders = async (correctedOrders: Order[]) => {
    try {
      await onDeleteFailedOrders(correctedOrders, true);

      showLoading();
      let fileCsv = new File([''], 'Ordenes corregidas', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      fileCsv = new File([''], FileOrders.generateFileNameWithDate(fileCsv), {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      if (fileCsv) {
        const { status, message, data } = await postFixOrders({ orders: correctedOrders });
        console.log('onLoadOrders() =>', { status, message, data });
        if (status) {
          addUploadFile(
            new FileOrders({
              nameFile: fileCsv.name,
              id: data,
              status: EnumFileUploadStates.inProcess,
              type: 'upload'
            })
          );
        }
        onUpdateNumFailedOrders(0);
        setOrders([]);
        await loadOrdersfailed();
      }
    } catch (err) {
      console.log('onLoadOrders() =>', err);
    }
    hideLoading();
  };

  useEffect(() => {
    loadOrdersfailed();
    onUpdateWarehouse();
  }, [countryUser]);

  return <ListFailedOrdersOrganism orders={orders} onDetailC={onDetail} {...{ onCorrectOrders, onDeleteFailedOrders }} />;
};

export default ListFailedOrdersPage;
