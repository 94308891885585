import { stringStatusDeliveryToColor } from 'helpers/Converts';

import { DataTableOrder } from 'models/order';

type CellShippingStatesProps = {
  order?: DataTableOrder;
  ignoreError?: boolean;
};

const CellShippingStates = ({ order }: CellShippingStatesProps) => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center py-2 pl-4'>
      <div className='w-full h-full flex flex-col justify-center border-r border-grayaraucoborder pr-4'>
        {order && (
          <div className='flex flex-col w-full'>
            {order.typeDelivery && (
              <div
                className={`flex flex-row items-center rounded-full w-full px-2 py-1 text-center justify-center ${stringStatusDeliveryToColor(
                  order?.status
                )}`}
              >
                <p className='text-xs text-white ml-2'>{order?.status}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellShippingStates;
