import { useContext } from 'react';

import { EnumTypeDownloadFile, NameIcons } from 'configs/Enums';
import { AuthContext } from 'context/AuthContext';

import IconCustom from 'components/atoms/icons';

import DownloadFile from 'models/file/DownloadFile';

type ButtonDownloadFileProps = {
  className?: string;
  type?: EnumTypeDownloadFile;
};

const ButtonDownloadFile = ({ className, type }: ButtonDownloadFileProps) => {
  const { downloadFiles } = useContext(AuthContext);

  const onDownload = async (file?: DownloadFile) => {
    console.log('onDownload() =>', file);
    if (file) {
      await file.download();
    }
  };

  //console.log('ButtonDownloadFile() ==>', downloadFiles);
  if (downloadFiles) {
    return (
      <div className={`${className}`}>
        {downloadFiles.handbook && type === EnumTypeDownloadFile.handbook && (
          <button className='flex justify-center items-center text-center' onClick={() => onDownload(downloadFiles.handbook)}>
            <IconCustom name={NameIcons.contentCopy} />
            <span className='underline text-primary font-bold'>Manual de carga</span>
          </button>
        )}
        {downloadFiles.template && type === EnumTypeDownloadFile.template && (
          <button className='flex justify-center items-center text-center' onClick={() => onDownload(downloadFiles.template)}>
            <IconCustom name={NameIcons.viewList} />
            <span className='underline text-primary font-bold'>Plantilla de trabajo</span>
          </button>
        )}
        {downloadFiles.handbookUnit && type === EnumTypeDownloadFile.handbookManualUnitLoading && (
          <button className='flex justify-center items-center text-center' onClick={() => onDownload(downloadFiles.handbookUnit)}>
            <IconCustom name={NameIcons.contentCopy} />
            <span className='underline text-primary font-bold'>Manual de carga</span>
          </button>
        )}
      </div>
    );
  }
  return null;
};

export default ButtonDownloadFile;
