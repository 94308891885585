import { useState } from 'react';
import { Tabs } from 'antd';

//import { RouterInContext } from 'context/RouterIn';

import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import FrequentQuestion from 'models/questions/FrequentQuestion';
import CategoryQuestionOrganism from './Category';
import CategoryQuestion from 'models/questions/Category';
import InformationBoard from './Information';
import ResourceQuestions from './Resources';
import HelpCenterResource from 'models/questions/Resource';

type FrequentQuestionOrganismProps = {
  frequentQuestions: FrequentQuestion[];
  categories: any;
  onUpdateFrequentQuestion: (form: any) => void;
  onCreateFrequentQuestion: (form: any) => void;
  onDeletedFrequentQuestion: (form: any) => void;
  onCreateCategory: (category: CategoryQuestion) => void;
  onDeletedCategory: (category: CategoryQuestion) => void;
  onUpdateCategory: (category: CategoryQuestion) => void;
  onLoadQuestions: (params: { category?: CategoryQuestion }) => void;
  onLoadInformations: () => void;
  onCategoryCurrent: (category?: CategoryQuestion) => void;
  onUpdatedInformation: (fq: FrequentQuestion) => void;
  onCreatedInformation: (fq: FrequentQuestion) => void;
  onDeletedInformation: (fq: FrequentQuestion) => void;
  informations: FrequentQuestion[];
  onChangeSource: (source: { type: 'tutorials'; value: string }) => void;
  source?: HelpCenterResource;
};

enum TabQuestionSeccion {
  questionsFrequent = 'questionsFrequent',
  information = 'information',
  source = 'source'
}

const { TabPane } = Tabs;

const FrequentQuestionOrganism = ({
  categories,
  frequentQuestions,
  onCreateCategory,
  onDeletedCategory,
  onUpdateCategory,
  onDeletedFrequentQuestion,
  onUpdateFrequentQuestion,
  onCreateFrequentQuestion,
  onLoadQuestions,
  onCategoryCurrent,
  onLoadInformations,
  onUpdatedInformation,
  onCreatedInformation,
  onDeletedInformation,
  informations,
  onChangeSource,
  source
}: FrequentQuestionOrganismProps) => {
  //const { hContainer } = useContext(RouterInContext);
  const [sectionTabActive, setSectionTabActive] = useState<TabQuestionSeccion | string>(TabQuestionSeccion.questionsFrequent);

  return (
    <MenuSessionContainer notStyleContainer classNameContainer=''>
      <div className='relative'>
        <div className='px-5 sm:px-10 pt-8 pb-4 md:pb-8 bg-white'>
          <h1 className='text-2xl md:text-3xl font-bold'>Centro de ayuda</h1>
        </div>

        <div className='w-full mt-4'>
          <Tabs
            className={'tab-store'}
            activeKey={sectionTabActive}
            onChange={tab => {
              setSectionTabActive(tab);
              console.log('onChange() => tab', tab);
            }}
          >
            <TabPane key={TabQuestionSeccion.questionsFrequent} tab='Preguntas frecuentes' />
            <TabPane key={TabQuestionSeccion.information} tab='Información' />
            <TabPane key={TabQuestionSeccion.source} tab='Recursos' />
          </Tabs>
        </div>

        <div className='mx-5 sm:mx-10'>
          {sectionTabActive === TabQuestionSeccion.questionsFrequent && (
            <CategoryQuestionOrganism
              {...{
                onCategoryCurrent,
                frequentQuestions,
                onCreateCategory,
                categories,
                onDeletedCategory,
                onUpdateCategory,
                onDeletedFrequentQuestion,
                onCreateFrequentQuestion,
                onUpdateFrequentQuestion,
                onLoadQuestions
              }}
            />
          )}
          {sectionTabActive === TabQuestionSeccion.information && (
            <InformationBoard
              onDeleted={onDeletedInformation}
              onCreated={onCreatedInformation}
              onUpdated={onUpdatedInformation}
              {...{ informations, onLoadInformations }}
            />
          )}
          {sectionTabActive === TabQuestionSeccion.source && (
            <ResourceQuestions onChange={onChangeSource} urlTutorials={source?.tutorial || ''} />
          )}
        </div>
      </div>
    </MenuSessionContainer>
  );
};

export default FrequentQuestionOrganism;
