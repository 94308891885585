import { TableColumn } from 'react-data-table-component';

import { NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';
import FilterHeaderColumn from 'components/molecules/headers/FilterHeaderColumn';
import TableCellMolecule from 'components/molecules/items/TableCell';

import Base from 'models/Base';
import { FormItemModel } from 'models/form';

export interface FrequentQuestionModel {
  question?: string;
  answer?: string;
  id?: string;
  category?: string;
  categoryDisplay?: string;
}

export interface FrequentQuestionServerModel {
  question: string;
  response: string;
  id?: string;
  categoryId?: string;
}

class FrequentQuestion extends Base {
  data: FrequentQuestionModel;

  constructor(data: FrequentQuestionModel) {
    super();
    this.data = data;
  }

  static formalizeData(data: FrequentQuestionServerModel): FrequentQuestionModel {
    const { question, response, id, categoryId } = data;

    return {
      question,
      answer: response,
      id,
      category: categoryId
    };
  }

  static formFieldsAdd({ values }: { values?: FrequentQuestionModel }): FormItemModel[] {
    //console.log('formFieldsAdd() ==> ', { values });

    return [
      {
        label: 'Pregunta',
        type: 'text',
        identifier: 'question',
        isRequired: true,
        value: values?.question
      },
      {
        label: 'Respuesta',
        type: 'markdown',
        //type: 'textArea',
        identifier: 'answer',
        isRequired: true,
        value: values?.answer
      }
    ];
  }

  static headerTable({
    showModalUpdate,
    onDeleted
  }: {
    showModalUpdate: (item: FrequentQuestion) => void;
    onDeleted: (item: FrequentQuestion) => void;
  }): TableColumn<FrequentQuestion>[] {
    return [
      {
        name: <FilterHeaderColumn title='' />,
        selector: row => FrequentQuestion.removeFlag(row.data.question),
        sortable: false,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => FrequentQuestion.isParameterInvalid(row.data.question),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            disabledStyleContainerD
            classNameContainer='mx-4'
            classNameContent=''
            value={row.data.question}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn title='' />,
        //selector: row => row,
        maxWidth: '100px',
        minWidth: '100px',
        sortable: true,
        reorder: true,
        cell: row => (
          <div className='flex flex-row gap-4'>
            <button onClick={() => showModalUpdate(row)}>
              <IconCustom className='text-grayarauco-400' name={NameIcons.rename} />
            </button>

            <button onClick={() => onDeleted(row)}>
              <IconCustom className='text-grayarauco-400' name={NameIcons.deleted} />
            </button>
          </div>
        )
      }
    ];
  }

  /**
   * actuliza la informacion basica.
   */
  updateInfoForm(value: any) {
    this.data.answer = value.answer;
    this.data.question = value.question;
  }

  sendServer(): FrequentQuestionServerModel {
    const { answer, question, category, id } = this.data;

    return {
      question: question || '',
      response: answer || '',
      categoryId: category || '',
      id: id || ''
    };
  }
}

export default FrequentQuestion;
