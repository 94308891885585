import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//import { ASSETS } from 'configs/Constants';
import { TypeToast, PathRoute, EnumCountries } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import UnitManualLoadOrganism from 'components/organisms/dashboard/UnitManualLoad';

import FormManualOrder from 'models/order/FormManual';
import Order from 'models/order';

import { createOrderUnit } from 'services/app/Order';
import { getCities, getCitiesByTypeDelivery, getDistrictsByCity } from 'services/app/District';

const UnitManualLoadPage = () => {
  const navigate = useNavigate();
  const [orderNew, setOrderNew] = useState<Order>();
  //const [districts, setDistricts] = useState<string[]>([]);

  const { showLoading, hideLoading, showToast } = useContext(RootContext);
  const { typesDocument, typesDelivery, dimensions, authStatus, countryUser, storeSession } = useContext(AuthContext);
  const { onUpdateWarehouse } = useContext(DataContext);

  const onSubmitOrder = async (form: FormManualOrder) => {
    showLoading();
    try {
      console.log('onSubmitOrder() ==> form ==>', { form, formFormat: form.sendServer(authStatus.user?.data.country) });
      const { status, data, message } = await createOrderUnit(form, authStatus.user, storeSession);
      if (status) {
        setOrderNew(data);
        showToast({ text: 'La orden ha sido cargada con éxito.', type: TypeToast.success });
        navigate(PathRoute.listOrders);
        hideLoading();
        return { status: true };
      } else {
        //setOrderNew(data);
        showToast({ text: message || 'No fue posible cargar la orden.', type: TypeToast.error });
        hideLoading();
        return { status: false, data };
      }
    } catch (err) {
      hideLoading();
      console.log('onSubmitOrder() ==> err ==>', err);
      return { status: false };
    }
  };

  const loadCitiesByTypeDelivery = async () => {
    showLoading();
    try {
      console.log('loadDistrict() =>', typesDelivery);
      for await (const typeDel of typesDelivery) {
        const { status, data } = await getCitiesByTypeDelivery({ country: countryUser || '', typeDelivery: typeDel.data.value });
        if (status) {
          typeDel.updateCities(data);
        } else {
          typeDel.updateCities([]);
        }
      }
      //setDistricts(ASSETS.data.districts.sort((a, b) => (a > b ? 1 : -1)));
    } catch (err) {
      console.log('loadDistrict() ==> err', err);
    }
    hideLoading();
  };

  const onLoadCities = async (params: { country: string; typeDelivery: string }): Promise<string[]> => {
    showLoading();
    try {
      const { status, data } = await getCities(params);
      const typeDeliveryFind = typesDelivery.find(typeDelivery => typeDelivery.data.value === params.typeDelivery);
      if (typeDeliveryFind) {
        console.log('onLoadCities() ==>', { status, data });
        if (status) {
          typeDeliveryFind.updateCities(data);
          hideLoading();
          return data;
        } else {
          if (authStatus.user?.data.country === EnumCountries.col) {
            typeDeliveryFind.updateCities(['BOGOTÁ']);
            hideLoading();
            return ['BOGOTÁ'];
          } else {
            typeDeliveryFind.updateCities([]);
          }
        }
      }
      hideLoading();
      return [];
    } catch (err) {
      console.log('onLoadCities() => err', err);
      hideLoading();
      return [];
    }
  };

  const onLoadDistrictByCity = async (params: { country: string; typeDelivery: string; city: string }) => {
    showLoading();
    try {
      const { status, data } = await getDistrictsByCity(params);
      const typeDeliveryFind = typesDelivery.filter(typeDelivery => typeDelivery.data.value === params.typeDelivery);

      if (typeDeliveryFind) {
        if (status) {
          typeDeliveryFind.forEach(e => {
            e.updateDistricts(data);
          });
        } else {
          typeDeliveryFind.forEach(e => {
            e.updateDistricts([]);
          });
        }
      }
    } catch (err) {
      console.log('onLoadDistrictByCity() => err', err);
    }
    hideLoading();
  };

  useEffect(() => {
    if (countryUser && typesDelivery && typesDelivery.length > 0) {
      loadCitiesByTypeDelivery();
    }
    onUpdateWarehouse();
  }, [countryUser, typesDelivery]);

  return (
    <UnitManualLoadOrganism
      user={authStatus.user}
      onUpdateOrderNew={orderUpdate => setOrderNew(orderUpdate)}
      {...{ typesDocument, onSubmitOrder, typesDelivery, dimensions, orderNew, onLoadCities, onLoadDistrictByCity }}
    />
  );
};

export default UnitManualLoadPage;
