import IconCustom from 'components/atoms/icons';

import { shippingTypeStringToDisplay, stringDeliveryToIcon } from 'helpers/Converts';

import { DataTableOrder } from 'models/order';

type CellShippingTypeProps = {
  order?: DataTableOrder;
  ignoreError?: boolean;
};

const CellShippingType = ({ order }: CellShippingTypeProps) => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center py-2 pl-4'>
      <div className='w-full h-full flex flex-col justify-center border-r border-grayaraucoborder pr-4'>
        {order && (
          <div className='flex flex-col'>
            {order.typeDelivery && (
              <div className='flex flex-row items-center text-center'>
                <IconCustom name={stringDeliveryToIcon(order?.typeDelivery)} className='text-primary' />
                <p className='text-xs text-primary ml-2'>{shippingTypeStringToDisplay(order?.typeDelivery)}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellShippingType;
