import { useContext, useEffect, useState } from 'react';

import { EnumTypeOperator } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';

import { capitalize } from 'helpers/Converts';

import TypeOperator from 'models/store/TypeOperator';
import SelectionButton from 'components/atoms/selects/SelectionButton';

type TypeOperatorSelectProps = {
  onChange: (value?: TypeOperator[]) => void;
  value?: TypeOperator[];
  classNameContainer?: string;
  isDummy?: boolean;
};

const TypeOperatorSelect = ({ onChange, value, classNameContainer, isDummy }: TypeOperatorSelectProps) => {
  const { authStatus, typesOperator, onUpdateTypeOperator } = useContext(AuthContext);

  const [firstLoad, setFirstLoad] = useState(false);
  const [valueCurrent, setValueCurrent] = useState<TypeOperator[]>([]);

  const onChangeValue = (valueNew?: TypeOperator[]) => {
    onChange(valueNew || []);
    setValueCurrent(valueNew || []);
  };

  useEffect(() => {
    if (!firstLoad && typesOperator.length === 0) {
      onUpdateTypeOperator();
      setFirstLoad(true);
    } else if (!value && typesOperator.length !== 0) {
      onChangeValue(
        !isDummy ? typesOperator.filter(tyOperato => tyOperato.data.value?.toLowerCase() !== EnumTypeOperator.dummy) : typesOperator
      );
    } else {
      setValueCurrent(value || []);
    }
  }, [value, typesOperator]);

  if (!authStatus.user?.isLocatario()) {
    return (
      <SelectionButton
        classNameContainer={`flex flex-col w-full sm:w-auto ${classNameContainer}`}
        text='Tipo de marca'
        items={
          typesOperator
            ? typesOperator
                .filter(typeOperator => (!isDummy ? typeOperator.data.value?.toLowerCase() !== EnumTypeOperator.dummy : true))
                .map(typeOperator => ({
                  label: capitalize(typeOperator.data.name) || '',
                  labelSearch: typeOperator.data.name || '',
                  value: typeOperator.toString() || '',
                  item: typeOperator
                }))
            : []
        }
        getPopupContainer={(triggerNode: any) => {
          //console.log('getPopupContainer() => triggerNode', triggerNode);
          return triggerNode;
        }}
        values={valueCurrent && valueCurrent.length !== 0 ? valueCurrent : undefined}
        onChange={val => {
          onChangeValue(val);
        }}
        onClose={() => {
          onChangeValue(valueCurrent);
        }}
      />
    );
  }

  return null;
};

export default TypeOperatorSelect;
