import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
//import { useState, useEffect } from 'react';

import { RootContext } from 'context/RootContext';
import { NameIcons } from 'configs/Enums';
import { RouterInContext } from 'context/RouterIn';
import { AuthContext } from 'context/AuthContext';

import InputSearch from 'components/atoms/search';
import FrequentQuestionCard from 'components/molecules/items/cards/FrequentQuestion';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import FrequentQuestion from 'models/questions/FrequentQuestion';
import CategoryQuestion from 'models/questions/Category';
import IconCustom from 'components/atoms/icons';
import HelpCenterResource from 'models/questions/Resource';
import ModalCustom from 'components/molecules/modals';

import { getAllFrequentQuestionsByCategory } from 'services/app/Question';

type FrequentQuestionListProps = {
  frequentQuestions: FrequentQuestion[];
  informations: FrequentQuestion[];
  categories: CategoryQuestion[];
  source?: HelpCenterResource;
};

const FrequentQuestionListItem = ({ fqs, onDetail }: { fqs: FrequentQuestion[]; onDetail: (fq: FrequentQuestion) => void }) => {
  return (
    <div className='flex flex-col overflow-auto' style={{ maxHeight: 385 }}>
      {fqs &&
        fqs.length > 0 &&
        fqs.map(fq => {
          return (
            <button
              key={fq.data.id}
              className='px-4 py-3 bg-white border-grayarauco-200 flex'
              style={{ borderBottomWidth: 1 }}
              onClick={() => {
                onDetail(fq);
                console.log('fq ==> fq', fq);
              }}
            >
              <h1 className='text-sm font-normal'>{fq.data.question}</h1>
            </button>
          );
        })}
    </div>
  );
};

const FrequentQuestionList = ({ frequentQuestions, informations, categories, source }: FrequentQuestionListProps) => {
  const { hContainer } = useContext(RouterInContext);
  const { downloadFiles } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(RootContext);

  const [fQuestions, setFQuestions] = useState<FrequentQuestion[]>([]);
  const [showListFQ, setShowListFQ] = useState(false);
  const [fqCurrent, setFqCurrent] = useState<FrequentQuestion>();
  const [categoryCurrent, setCategoryCurrent] = useState<CategoryQuestion>();
  const [fQCurrents, setFQCurrents] = useState<FrequentQuestion[]>([]);

  const onSearch = (value?: string) => {
    try {
      if (value) {
        const result = frequentQuestions.filter(fQ => fQ.data.question?.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        console.log('result =>', { result, frequentQuestions, value });
        setFQuestions(result);
      } else {
        setFQuestions(frequentQuestions);
      }
    } catch (err) {
      console.log('onSearch() => err', err);
      setFQuestions(frequentQuestions);
    }
  };

  const onDetail = (fq: FrequentQuestion) => {
    console.log('onDetail() ==> fq', { fq });
    setShowListFQ(false);
    setFqCurrent(fq);
  };

  const onGetQuestionCategory = async (cat: CategoryQuestion) => {
    showLoading();

    const { data, status } = await getAllFrequentQuestionsByCategory({ category: cat });
    console.log('onGetQuestionCategory() => { data, status }', { data, status });

    if (status) {
      setFQCurrents(data);
    }
    hideLoading();
  };

  useEffect(() => {
    setFQuestions(frequentQuestions || []);
  }, [frequentQuestions]);

  return (
    <MenuSessionContainer notStyleContainer classNameContainer='relative'>
      <div className='flex flex-col relative overflow-auto' style={{ height: hContainer }}>
        <div className='mb-4 mx-10 mt-8'>
          <h1 className='text-2xl sm:text-3xl'>Centro de ayuda</h1>
          <div className='flex flex-col justify-center items-center'>
            <div className='flex flex-col self-center w-full sm:w-96'>
              <p className='text-sm mb-2 sm:text-base'>Busqueda de palabras claves</p>
              <Dropdown
                visible={showListFQ}
                overlay={<FrequentQuestionListItem fqs={fQuestions} onDetail={onDetail} />}
                trigger={['click']}
              >
                <InputSearch
                  placeholder='Ej: carga masiva'
                  onChange={onSearch}
                  onSearch={onSearch}
                  allowClear
                  onFocus={() => setShowListFQ(true)}
                />
              </Dropdown>
            </div>
          </div>
        </div>

        <div className='mx-5 sm:mx-10 mb-10 flex flex-col flex-1'>
          <p className='text-xl sm:text-2xl mb-4 font-bold'>Preguntas frecuentes</p>
          <div className='gap-4 grid grid-cols-1 sm:grid-cols-2'>
            {categories &&
              categories.length > 0 &&
              categories.map(value => {
                return (
                  <div
                    key={value.data.id}
                    className='flex flex-row items-center px-4 py-9 shadow-md bg-white cursor-pointer'
                    style={{ borderRadius: 10 }}
                    onClick={() => {
                      setCategoryCurrent(value);
                      onGetQuestionCategory(value);
                    }}
                  >
                    {value.data.icon && <img className='w-16 h-16' src={value.data.iconPath} />}
                    {!value.data.icon && <IconCustom name={NameIcons.image} className='text-grayarauco-300 text-5xl' />}
                    <div className='ml-4'>
                      <p className='text-primary text-base font-bold'>{value.data.name}</p>
                      <p className='mt-4 text-black text-sm font-normal'>{value.data.description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className='bg-notificationlb pt-8 pb-10 px-10'>
          <p className='text-primary text-2xl font-bold mb-4'>Información </p>
          {informations &&
            informations.length > 0 &&
            informations.map(inf => {
              return <FrequentQuestionCard className='mb-4' key={inf.data.id} frequentQuestion={inf} />;
            })}
        </div>

        <div className='bg-primary px-5 sm:px-10 pb-10 pt-8'>
          <p className='text-2xl font-bold mb-4 text-white'>Recursos</p>

          <div className='flex flex-col sm:flex-row w-full gap-4'>
            <a className='flex px-4 py-8 bg-white flex-1' href={source?.tutorial} style={{ borderRadius: 10 }}>
              <p className='text-xl text-primary font-bold'>Tutoriales</p>
            </a>

            <button
              className='flex flex-1 px-4 py-8 bg-white'
              style={{ borderRadius: 10 }}
              onClick={() => {
                downloadFiles?.handbook?.download();
              }}
            >
              <p className='text-xl text-primary font-bold'>Manual Arauco Hub</p>
            </button>
          </div>
        </div>
      </div>

      {categoryCurrent && (
        <ModalCustom notFullScreen onClose={() => setCategoryCurrent(undefined)} isVisible={fqCurrent ? true : false}>
          <div className='w-full bg-white px-10 py-4' style={{ height: hContainer }}>
            <div className='mb-4'>
              <button className='text-xs font-normal' onClick={() => setCategoryCurrent(undefined)}>
                {'< Volver'}
              </button>
            </div>

            <div className='mb-8'>
              <h1 className='text-3xl font-bold'>{categoryCurrent?.data.name}</h1>
            </div>

            <div className='border-grayarauco-200 overflow-hidden' style={{ borderWidth: 1, borderRadius: 10 }}>
              {fQCurrents &&
                fQCurrents.length > 0 &&
                fQCurrents.map((fq, index) => {
                  //return <FrequentQuestionCard className='mb-4' key={fq.data.id} frequentQuestion={fq} />;
                  return (
                    <div
                      key={fq.data.id}
                      className={`p-4 flex flex-row justify-between items-center cursor-pointer ${
                        index !== fQCurrents.length - 1 ? 'border-grayarauco-200' : ''
                      }`}
                      style={{ borderBottomWidth: index !== fQCurrents.length - 1 ? 1 : 0 }}
                      onClick={() => onDetail(fq)}
                    >
                      <p className='text-base font-normal'>{fq.data.question}</p>
                      <IconCustom name={NameIcons.arrowRigth} className='text-xl text-grayarauco-400' />
                    </div>
                  );
                })}
            </div>
          </div>
        </ModalCustom>
      )}
      {fqCurrent && (
        <ModalCustom notFullScreen onClose={() => setFqCurrent(undefined)} isVisible={fqCurrent ? true : false}>
          <div className='w-full bg-white px-10 py-4' style={{ height: hContainer }}>
            <div className='mb-4'>
              <button className='text-xs font-normal' onClick={() => setFqCurrent(undefined)}>
                {'< Volver'}
              </button>
            </div>

            <div className='mb-8'>
              <h1 className='text-3xl font-bold'>{fqCurrent?.data.categoryDisplay}</h1>
            </div>

            <div>
              <h1 className='text-xl font-bold mb-4'>{fqCurrent?.data.question}</h1>

              <ReactMarkdown className='template-markdown' remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {fqCurrent?.data.answer || ''}
              </ReactMarkdown>
            </div>
          </div>
        </ModalCustom>
      )}
    </MenuSessionContainer>
  );
};

export default FrequentQuestionList;
