import { ReactNode, useEffect, useRef, useState } from 'react';
import { Dropdown, Checkbox, Divider } from 'antd';

const CheckboxGroup = Checkbox.Group;

import ButtonCustom from 'components/atoms/buttons';
import InputCustom from 'components/atoms/inputs';
import DatePickerCustom from 'components/atoms/pickers/DatePicker';
import { FormatDate } from 'configs/Enums';

export type BoardFilterProps = {
  values?: string[];
  children?: ReactNode;
  /** si es verdadero en ascendente **/
  onSort?: (val?: boolean) => void;
  onFilter?(params: { val?: string[] | string }): Promise<string[]>;
  typeInput?: 'text' | 'time' | 'date';
  textAscendingOrder?: ReactNode;
  textDescendingOrder?: ReactNode;
};

export type MenuBoardFilterProps = {
  values?: string[];
  onSort?: (val?: boolean) => void;
  onFilter?(params: { val?: string[] | string }): Promise<string[]>;
  typeInput?: 'text' | 'time' | 'date';
  textAscendingOrder?: ReactNode;
  textDescendingOrder?: ReactNode;
};

export const MenuBoardFilter = ({
  values,
  onFilter,
  onSort,
  typeInput = 'text',
  textAscendingOrder,
  textDescendingOrder
}: MenuBoardFilterProps) => {
  const [value, setValue] = useState<string>();
  const [listValue, setListValue] = useState<string[]>([]);
  const refInputDate = useRef<HTMLInputElement | null>(null);

  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list: Array<string | number | boolean>) => {
    setCheckedList(list as string[]);
    setIndeterminate(!!list.length && list.length < (listValue?.length || 0));
    setCheckAll(list.length === listValue?.length);
    if (onFilter) {
      onFilter({ val: list as string[] });
    }
  };

  const onCheckAllChange = (e: any) => {
    //console.log('onCheckAllChange()', e.target.checked);
    setCheckedList(e.target.checked ? listValue || [] : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    if (onFilter) {
      onFilter({ val: e.target.checked ? listValue || [] : [] });
    }
  };

  const firstLoad = async () => {
    if (onFilter) {
      const filter = await onFilter({ val: '' });
      if (filter) {
        setListValue(filter);
        setCheckAll(true);
        setIndeterminate(false);
        setCheckedList(filter);
      }
    }
  };

  useEffect(() => {
    if (values) {
      setListValue(values);
      setCheckAll(true);
      setIndeterminate(false);
      setCheckedList(values);
    }
    firstLoad();
  }, [values]);

  return (
    <div className='bg-white rounded-md flex flex-col p-2 justify-start border-2 shadow-md'>
      {onSort && (
        <div className='flex flex-1 flex-col justify-start'>
          <ButtonCustom
            size='small'
            notStyleDefault
            onClick={() => (onSort ? onSort(true) : undefined)}
            className='text-sm text-left text-black hover:text-gray-500'
            type={'link'}
          >
            {textDescendingOrder || 'Ordenar ascendentemente'}
          </ButtonCustom>

          <ButtonCustom
            size='small'
            notStyleDefault
            onClick={() => (onSort ? onSort(false) : undefined)}
            className='text-sm text-left text-black mb-2 hover:text-gray-500'
            type={'link'}
          >
            {textAscendingOrder || 'Ordenar descendentemente'}
          </ButtonCustom>

          <Divider className='mb-2 mt-0' />
        </div>
      )}

      {typeInput === 'text' && (
        <InputCustom
          value={value}
          onChange={async event => {
            const val = event.target.value;
            setValue(val);
            if (onFilter) {
              const filter = await onFilter({ val: val });
              if (filter) {
                setListValue(filter);
                setCheckAll(true);
                setIndeterminate(false);
                setCheckedList(filter);
              }
            }
          }}
        />
      )}

      {typeInput === 'date' && (
        <DatePickerCustom
          ref={refInputDate}
          value={value}
          format={FormatDate.ddmmaaaaAlt}
          onChange={async val => {
            console.log('val', val);
            setValue(val);
            if (onFilter) {
              const filter = await onFilter({ val: val });
              if (refInputDate && refInputDate.current) {
                refInputDate.current.blur();
              }
              if (filter) {
                setListValue(filter);
                setCheckAll(true);
                setIndeterminate(false);
                setCheckedList(filter);
              }
            }
          }}
        />
      )}

      {listValue && listValue.length > 0 && (
        <div>
          <Checkbox className='mt-1' indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            Todo
          </Checkbox>
          <Divider className='my-1' />
          <CheckboxGroup
            className='grid grid-cols-1 overflow-y-auto  max-h-60'
            options={listValue.map(val => `${val}`)}
            value={checkedList}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

const BoardFilter = ({ children, ...props }: BoardFilterProps) => {
  return (
    <Dropdown trigger={['click']} overlay={<MenuBoardFilter {...props} />}>
      {children}
    </Dropdown>
  );
};

export default BoardFilter;
