import OrderTableFilter from 'components/molecules/filters/OrderTableFilter';

import Order from 'models/order';
import Store from 'models/store';
import WarehousePA from 'models/warehouse';

export enum OrderTableHeaderOption {
  filter = 'filter',
  /** exportar CSV ..etc **/
  options = 'options'
}

type OrderTableProps = {
  options: OrderTableHeaderOption[];
  onFilter?: (value: { dateStart?: string; dateEnd?: string; deliveryStates?: string[]; warehouse?: WarehousePA[] }) => void;
  onExportCSV?: () => void;
  orders: Order[];
  hiddenFilter?: boolean;
  paramsFilter?: {
    dateStart?: string;
    dateEnd?: string;
    store?: Store;
    deliveryStates?: string[];
    numOrder?: string;
    warehouse?: WarehousePA[];
  };
};

const OrderTable = ({ options, onFilter, onExportCSV, orders, hiddenFilter, paramsFilter }: OrderTableProps) => {
  return (
    <div className='flex flex-row w-full'>
      {onFilter && options.find(option => option === OrderTableHeaderOption.filter) && (
        <OrderTableFilter {...{ onFilter, onExportCSV, orders, hiddenFilter, paramsFilter }} />
      )}
    </div>
  );
};

export default OrderTable;
