export interface CustomerModel {
  lastName: string;
  firstName: string;
  fullName: string;
  numberDocument: string;
  phone: string;
  documentType: string;
  email: string;
}

export interface CustomerModelServer {
  lastName: string;
  firstName: string;
  numberDocument?: string;
  documentNumber?: string;
  phone: string;
  documentType: string;
  email: string;
}

class Customer {
  data: CustomerModel;

  constructor(data: CustomerModel) {
    this.data = data;
  }

  static formalizeData(data: CustomerModelServer): CustomerModel {
    const { lastName, firstName, numberDocument, documentNumber, phone, documentType, email } = data;

    return {
      lastName: lastName?.trim(),
      firstName: firstName?.trim(),
      fullName: `${firstName?.trim()} ${lastName?.trim()}`,
      numberDocument: numberDocument ? numberDocument.trim() || '' : documentNumber?.trim() || '',
      phone: phone?.trim(),
      documentType: documentType?.trim(),
      email: email?.trim()
    };
  }

  static createVoid(): Customer {
    return new Customer({
      lastName: '',
      firstName: '',
      fullName: ``,
      numberDocument: '',
      phone: '',
      documentType: '',
      email: ''
    });
  }
}

export default Customer;
