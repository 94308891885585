import { TypeTimeInterval } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';

//import KpiGeneralStatistic from 'models/statistic/KpiGeneral';
import CountOrderByTypeDelivery from 'models/statistic/order/CountOrderByTypeDelivery';
import EvolutionOrderByDelivery from 'models/statistic/order/EvolutionOrderByDelivery';
import KpiGeneral from 'models/statistic/order/KpiGeneral';
import OrderByStore from 'models/statistic/order/OrderByStore';
import StatusOrder from 'models/statistic/order/StatusOrder';
import StoreStatistic from 'models/statistic/Store';
import DemandPickupStatistics from 'models/statistic/DemandPickupStatistics';
import OrderCancel from 'models/statistic/order/OrderCancel';
import OrderFail from 'models/statistic/order/OrderFail';
import TypeDeliveryStatistic from 'models/statistic/TypeDelivery';
//import Store from 'models/store';

import Statistic from 'services/api/Statistic';
import StatisticProvider from 'services/api/Statistic';
import AmountStatistic from 'models/statistic/Amount';
import OperatorStatistic from 'models/statistic/Operator';
import DistributionStatistic from 'models/statistic/Distribution';
import PerformanceStatistic from 'models/statistic/Performance';
import TypeOperator from 'models/store/TypeOperator';

export const getStatisticTypeDeliveryPerformance = async (params: ParamsValueFilterStatistic): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await StatisticProvider.getStatisticTypeDeliveryPerformance(params);
    console.log('getStatisticTypeDeliveryPerformance() ==> { data, status, other }', { data, status, other });

    if (status === 200) {
      return { status: true, data: new TypeDeliveryStatistic(TypeDeliveryStatistic.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible conseguir las metricas.' };
  } catch (err) {
    console.log('getStatisticTypeDeliveryPerformance() => err', err);
    return {
      status: false,
      message: `No fue posible conseguir las metricas: ${(err as Error).message}`,
      data: new TypeDeliveryStatistic(TypeDeliveryStatistic.formalizeData())
    };
  }
};

export const getKpiGeneral = async (params: ParamsValueFilterStatistic) => {
  try {
    const { data, status, ...other } = await StatisticProvider.getKpiGeneral(params);
    console.log('getKpiGeneral() =>', { data, status, params });
    if (status === 200) {
      if (data && data.length > 0) {
        //return { status: true, data: new KpiGeneralStatistic(KpiGeneralStatistic.formalizeData(data)) };
        return { status: true, data: new KpiGeneral(KpiGeneral.formalizeData(data[0])) };
      } else {
        return { status: false, message: 'No hay informacion.' };
      }
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible conseguir los kpis genérales.' };
  } catch (err) {
    return { status: false, message: `No fue posible conseguir los kpis genérales: ${(err as Error).message}` };
  }
};

export const getActiveStores = async ({ country, typesOperator }: { country?: string[]; typesOperator?: TypeOperator[] }) => {
  try {
    const { data, status, ...other } = await Statistic.getActiveStores({ country, typesOperator });
    console.log('getActiveStores() =>', { data, status, country });

    if (status === 200) {
      if (data) {
        return { status: true, data: new StoreStatistic(StoreStatistic.formalizeActiveStore(data)) };
      } else {
        return { status: false, message: 'No hay informacion.' };
      }
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No obtuvieron tiendas activas.' };
  } catch (err) {
    return { status: false, message: `No obtuvieron tiendas activas ${(err as Error).message}` };
  }
};

export const getStatusOrder = async (params: ParamsValueFilterStatistic) => {
  try {
    const { status, data, ...other } = await Statistic.getStatusOrder({ ...params, timeInterval: TypeTimeInterval.year });
    console.log('getStatusOrder() ==> params', { params, status, data, other });

    if (status === 200) {
      if (data) {
        return { status: true, data: new StatusOrder(StatusOrder.formalizeData(data)) };
      } else {
        return { status: false, message: 'No hay informacion.' };
      }
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay estados de las ordenes.' };
  } catch (err) {
    console.log('getStatusOrder() ==> err', err);
    return { status: false, message: `No hay estados de las ordenes ${(err as Error).message}` };
  }
};

export const getCountOrderByTypeDelivery = async (params: ParamsValueFilterStatistic) => {
  try {
    const { data, status, ...other } = await StatisticProvider.getCountOrderByTypeDelivery(params);
    console.log('getCountOrderByTypeDelivery() ==> params', { params, status, data, other });

    if (status === 200 && data) {
      return { status: true, data: new CountOrderByTypeDelivery(CountOrderByTypeDelivery.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay ordenes.' };
  } catch (err) {
    console.log('getCountOrderByTypeDelivery() ==> err', err);
    return { status: false, message: `No hay ordenes ${(err as Error).message}` };
  }
};

export const getOrdersByStore = async (params: ParamsValueFilterStatistic) => {
  try {
    const { data, status, ...other } = await StatisticProvider.getOrdersByStore(params);
    console.log('getOrdersByStore() ==> params', { params, status, data, other });

    if (status === 200 && data) {
      return { status: true, data: new OrderByStore(OrderByStore.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay ordenes.' };
  } catch (err) {
    console.log('getOrdersByStore() ==> err', err);
    return { status: false, message: `No hay ordenes ${(err as Error).message}` };
  }
};

export const getEvolutionByTypesDelivery = async (params: ParamsValueFilterStatistic) => {
  try {
    const { data, status, ...other } = await StatisticProvider.getEvolutionByTypesDelivery(params);
    console.log('getOrdersByStore() ==> params', { params, status, data, other });

    if (status === 200 && data) {
      if (data && data.length === 0) {
        return { status: true, data: new EvolutionOrderByDelivery() };
      } else {
        return {
          status: true,
          data: new EvolutionOrderByDelivery(EvolutionOrderByDelivery.formalizeData(data, params.isGroupTypeDelivery))
        };
      }
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay ordenes.' };
  } catch (err) {
    console.log('getOrdersByStore() ==> err', err);
    return { status: false, message: `No hay ordenes ${(err as Error).message}` };
  }
};

export const getPickupDemand = async (params: ParamsValueFilterStatistic) => {
  try {
    const { data, status, ...other } = await StatisticProvider.getPickupDemand(params);
    console.log('getPickupDemand() ==> params', { params, status, data, other });

    if (status === 200 && data) {
      return { status: true, data: DemandPickupStatistics.formalizeData(data, params.group) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay datos.' };
  } catch (err) {
    console.log('getOrdersByStore() ==> err', err);
    return { status: false, message: `No hay datos ${(err as Error).message}` };
  }
};

export const getOrderCanceled = async (params: ParamsValueFilterStatistic) => {
  try {
    const { status, data, ...other } = await StatisticProvider.getOrderCanceled(params);
    console.log('getOrderCanceled() =>', { status, data, other });

    if (status === 200 && data) {
      return { status: true, data: new OrderCancel(OrderCancel.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay datos.' };
  } catch (err) {
    return { status: false, message: `no fue posible obtener la información de cancelación.` };
  }
};

export const getOrderFail = async (params: ParamsValueFilterStatistic) => {
  try {
    const { status, data, ...other } = await StatisticProvider.getOrderFail(params);
    console.log('getOrderFail() =>', { status, data, other });

    if (status === 200 && data) {
      return { status: true, data: new OrderFail(OrderFail.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay datos.' };
  } catch (err) {
    console.log('getOrderFail() ==> err', err);
    return { status: false, message: `no fue posible obtener la información de cancelación.` };
  }
};

export const getStatisticAmounts = async (params: ParamsValueFilterStatistic) => {
  try {
    const { status, data, ...other } = await StatisticProvider.getAmounts(params);
    console.log('getStatisticAmounts() =>', { status, data, other });

    if (status === 200 && data) {
      return { status: true, data: new AmountStatistic(AmountStatistic.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay datos.' };
  } catch (err: any) {
    return { status: false, message: `No fue posible cargar los montos: ${err.message}` };
  }
};

export const getStatisticOperators = async (params: ParamsValueFilterStatistic) => {
  try {
    const { status, data, ...other } = await StatisticProvider.getOperators(params);
    console.log('getStatisticOperators() =>', { status, data, other });

    if (status === 200 && data) {
      return { status: true, data: new OperatorStatistic(OperatorStatistic.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay datos.' };
  } catch (err: any) {
    return { status: false, message: `No fue posible cargar los montos: ${err.message}` };
  }
};

export const getStatisticDistribution = async (params: ParamsValueFilterStatistic) => {
  try {
    const { status, data, ...other } = await StatisticProvider.getDistribution(params);
    console.log('getStatisticDistribution() =>', { status, data, other });

    if (status === 200 && data) {
      return { status: true, data: new DistributionStatistic(DistributionStatistic.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay datos.' };
  } catch (err: any) {
    return { status: false, message: `No fue posible cargar los montos: ${err.message}` };
  }
};

export const getStatisticPerformance = async (params: ParamsValueFilterStatistic): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await StatisticProvider.getStatisticPerformance(params);
    console.log('getStatisticTypeDeliveryPerformance() ==> { data, status, other }', { data, status, other });

    if (status === 200) {
      return { status: true, data: new PerformanceStatistic(PerformanceStatistic.formalizeData(data)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible conseguir las metricas.' };
  } catch (err) {
    console.log('getStatisticTypeDeliveryPerformance() => err', err);
    return {
      status: false,
      message: `No fue posible conseguir las metricas: ${(err as Error).message}`,
      data: new TypeDeliveryStatistic(TypeDeliveryStatistic.formalizeData())
    };
  }
};
