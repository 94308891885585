import { Skeleton } from 'antd';

type BarTagSkeletonProps = {
  isActive?: boolean;
};

const BarTagSkeleton = ({ isActive }: BarTagSkeletonProps) => {
  return (
    <div className='flex flex-1 flex-col gap-2 ant-skeleton-bartag-statistic'>
      <Skeleton.Input className='mb-3 w-6' active size={'small'} />

      <Skeleton.Input active={isActive} size={'large'} block={true} />
      <Skeleton.Input className='w-4/5' active={isActive} size={'large'} />
      <Skeleton.Input className='w-3/4' active={isActive} size={'large'} />
      <Skeleton.Input className='w-2/5' active={isActive} size={'large'} />
      <Skeleton.Input className='w-1/5' active={isActive} size={'large'} />

      <div className='flex flex-1 flex-row mt-6 justify-between items-center ant-skeleton-bartag-statistic'>
        <Skeleton.Avatar className='w-3' active shape='square' size={'small'} />
        <Skeleton.Avatar className='w-3' active shape='square' size={'small'} />
        <Skeleton.Avatar className='w-3' active shape='square' size={'small'} />
        <Skeleton.Avatar className='w-3' active shape='square' size={'small'} />
        <Skeleton.Avatar className='w-3' active shape='square' size={'small'} />
      </div>
    </div>
  );
};

export default BarTagSkeleton;
