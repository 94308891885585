import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { PathRoute } from 'configs/Enums';

import InputCustom from 'components/atoms/inputs';

type ResourceQuestionsProps = {
  onChange: (params: { type: 'tutorials'; value: string }) => void;
  urlTutorials: string;
};

const ResourceQuestions = ({ onChange, urlTutorials }: ResourceQuestionsProps) => {
  const [urlT, setUrlT] = useState('');

  useEffect(() => {
    setUrlT(urlTutorials);
  }, [urlTutorials]);

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-4'>
        <h1 className='text-base sm:text-2xl text-primary font-normal'>Tutoriales</h1>
        <InputCustom
          onChange={val => {
            setUrlT(val.target.value);
            onChange({ type: 'tutorials', value: val.target.value });
          }}
          value={urlT}
        />
      </div>

      <div className='flex flex-col gap-4'>
        <h1 className='text-base sm:text-2xl text-primary font-normal'>Manual Arauco Hub</h1>
        <p className='text-sm sm:text-lg'>
          Los manuales de Arauco Hub se encuentran en el módulo de{' '}
          <Link className='text-primary' to={PathRoute.updateHandbook}>
            configuración de documentos
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResourceQuestions;
