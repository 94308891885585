import { useContext, useEffect, useState } from 'react';
import { Skeleton } from 'antd';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import { EnumAlert, EnumDeliverySummaryStatus, NameIcons, SizeScreen } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';
import ShippingStatusTimeLineMolecules from 'components/molecules/timeLines/ShippingStatus';

import {
  convertNumberToPrice,
  nameDistrictForCountry,
  shippingTypeStringToDisplay,
  stringAlertOrderToDisplay,
  stringAlertOrderToStyle,
  stringDeliveryToIcon,
  stringStatusDeliveryToColor
} from 'helpers/Converts';

import Order from 'models/order';
import ShippingStatus from 'models/delivery/ShippingStatus';

import { getHistoryStateByIdOrder } from 'services/app/Order';
import SelectCustom from 'components/atoms/selects';
import BoardCustom from 'components/molecules/board';

type DetailOrderOrganismProps = {
  order?: Order;
  isPage?: boolean;
  goBack?: () => void;
  onUpdate?: (order: Order) => void;
};

const DetailOrderOrganism = ({ order, isPage = true, goBack, onUpdate }: DetailOrderOrganismProps) => {
  const { countryUser, authStatus, dimensions } = useContext(AuthContext);
  const { screenResolution, showLoading, hideLoading } = useContext(RootContext);

  const [shippingsStatus, setShippingsStatus] = useState<ShippingStatus[] | undefined>();
  const [isExpandedStatus, setIsExpandedStatus] = useState(true);

  const loadHistoryStateByIdOrder = async (orderCurrent?: Order) => {
    showLoading();
    const orderNew = order || orderCurrent;
    if (orderNew) {
      const { data, status } = await getHistoryStateByIdOrder(orderNew);
      const summaryFullStatus = ShippingStatus.fullStatus({
        shippingsStatus: data,
        typeDelivery: orderNew?.data.detail.data.shipphing.data.type
      });

      //console.log('loadHistoryStateByIdOrder() => { data, status }', {
      //data,
      //status,
      //shippingsStatus: summaryFullStatus,
      //order: orderNew?.data.detail.data.shipphing.data.type
      //});

      if (status) {
        setShippingsStatus(summaryFullStatus);
      } else {
        setShippingsStatus([]);
      }
    } else {
      setShippingsStatus(undefined);
    }
    hideLoading();
  };

  useEffect(() => {
    loadHistoryStateByIdOrder(order);
  }, [order]);

  if (order) {
    return (
      <MenuSessionContainer notStyleContainer classNameContainer='pb-4' {...{ isPage }}>
        <div className='px-4 flex flex-col border-gray-200 md:px-10 py-4 bg-white mt-1'>
          <div>
            <button
              className='flex justify-center items-center mb-0 sm:mb-4'
              onClick={() => {
                if (isPage) {
                  window.history.back();
                }
                if (goBack) {
                  goBack();
                }
              }}
            >
              <IconCustom name={NameIcons.navigateBefore} /> Volver
            </button>
          </div>
          <div className={`flex flex-1 ${shippingsStatus && shippingsStatus.length >= 5 ? 'flex-col' : 'flex-col md:flex-row'}`}>
            <div>
              <h1 className='text-2xl'>Órden {order.data.detail.data.order.data.order}</h1>
              <h3 className='text-lg'>{order.data.detail.data.order.data.store}</h3>
              <div className='flex flex-row items-end'>
                <h3 className='text-base'>Tipo de carga:</h3>
                <p className='text-sm font-bold ml-1'>{order.getDataTable().typeUpload}</p>
              </div>
            </div>

            {!shippingsStatus && (
              <div className='ml-4'>
                <Skeleton active paragraph={{ rows: 1, width: '200px' }} />
              </div>
            )}

            <div
              className={`flex flex-1 ${
                shippingsStatus && shippingsStatus.length >= 5 ? 'ml-0' : 'ml-0 md:ml-10'
              } flex-col bg-white md:bg-transparent md:flex-row`}
            >
              {shippingsStatus && shippingsStatus.length > 0 && (
                <div className='flex justify-between w-full pr-4 md:w-auto'>
                  <div>
                    <h3 className='text-lg font-normal'>Seguimiento de pedido</h3>

                    <div className='mt-2'>
                      {shippingsStatus?.every(shippingStatus => shippingStatus.data.isCanceled) && (
                        <div>
                          <p className='ml-1 text-lg text-grayarauco-400 font-bold'>Cancelado</p>
                        </div>
                      )}
                      {!shippingsStatus?.every(shippingStatus => shippingStatus.data.isCanceled) &&
                        shippingsStatus?.find(
                          shippingStatus =>
                            shippingStatus.data.nameDisplay === EnumDeliverySummaryStatus.undelivered && shippingStatus.data.inProgress
                        ) && (
                          <div>
                            <p className='ml-1 text-lg text-redarauco font-bold'>No entregado</p>
                          </div>
                        )}
                      {!shippingsStatus?.every(shippingStatus => shippingStatus.data.isCanceled) &&
                        shippingsStatus?.find(
                          shippingStatus =>
                            shippingStatus.data.nameDisplay === EnumDeliverySummaryStatus.successfulDelivery &&
                            shippingStatus.data.inProgress
                        ) && (
                          <div>
                            <p className='ml-1 text-lg text-tertiary font-bold'>Entregado</p>
                          </div>
                        )}
                      {!shippingsStatus?.every(shippingStatus => shippingStatus.data.isCanceled) &&
                        !shippingsStatus?.find(
                          shippingStatus =>
                            shippingStatus.data.nameDisplay === EnumDeliverySummaryStatus.successfulDelivery &&
                            shippingStatus.data.inProgress
                        ) && (
                          <div>
                            <p className='text-sm font-normal text-araucoblue-200'>Estimación de entrega</p>

                            <div className='flex justify-between items-center mt-1 text-grayarauco-400'>
                              <div className='flex items-center'>
                                <IconCustom name={NameIcons.calendarMonth} className='text-xs text-grayarauco-400' />
                                <p className='ml-1'>{order.getDataTable().order?.estimatedDate}</p>
                              </div>

                              <div className='flex items-center'>
                                <IconCustom name={NameIcons.schedule} className='text-xs text-grayarauco-400' />
                                <p className='ml-1'>{order.getDataTable().order?.estimatedTime}</p>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  {screenResolution <= SizeScreen.md && (
                    <div className='flex justify-end items-end'>
                      <IconCustom
                        name={isExpandedStatus ? NameIcons.arrowUp : NameIcons.arrowDown}
                        onClick={() => setIsExpandedStatus(!isExpandedStatus)}
                      />
                    </div>
                  )}
                </div>
              )}

              {isExpandedStatus && (
                <div className='ml-0 md:ml-10 flex flex-1 mt-4 md:mt-0'>
                  <ShippingStatusTimeLineMolecules {...{ shippingsStatus }} />
                </div>
              )}
            </div>
          </div>
        </div>

        {order.data.alertOrder && (
          <div
            className={`mx-4 sm:mx-10 my-4 sm:my-8 bg-white flex flex-1 border-l-8 ${stringAlertOrderToStyle({
              alertOrder: order.data.alertOrder,
              type: 'border'
            })}`}
          >
            <div className='w-full my-4 flex flex-col sm:items-center sm:flex-row'>
              <p className='ml-4 text-base font-bold'>{stringAlertOrderToDisplay(order.data.alertOrder)}</p>
              {order.getTimeLimit()?.asHours() && (
                <div className='flex font-bold sm:justify-center items-center sm:border-l border-grayaraucoborder px-4 sm:ml-4'>
                  <IconCustom
                    className={`${stringAlertOrderToStyle({ alertOrder: order.data.alertOrder, type: 'text' })} text-2xl`}
                    name={order.data.alertOrder === EnumAlert.low ? NameIcons.schedule : NameIcons.alarm}
                  />
                  <div className={`flex ml-2 ${order.data.alertOrder === EnumAlert.half ? 'font-bold' : ''}`}>
                    {Math.round(order.getTimeLimit()?.asHours() || 0) <= 0 && (
                      <p className='font-normal flex'>
                        {`La entrega tiene un `}
                        <p className='font-bold ml-1'>
                          {`atraso de ${-1 * Math.round(order.getTimeLimit()?.asHours() || 0)} `}
                          {Math.round(order.getTimeLimit()?.asHours() || 0) === -1 ? 'hora' : 'horas'}
                        </p>
                      </p>
                    )}
                    {Math.round(order.getTimeLimit()?.asHours() || 0) > 0 && (
                      <p>
                        {Math.round(order.getTimeLimit()?.asHours() || 0) === 1 ? 'Queda ' : 'Quedan '}
                        {Math.round(order.getTimeLimit()?.asHours() || 0)}{' '}
                        {Math.round(order.getTimeLimit()?.asHours() || 0) === 1 ? 'hora' : 'horas'}
                        <p className={`ml-1 ${order.data.alertOrder === EnumAlert.half ? 'font-bold' : 'font-normal'}`}>
                          {`para cumplir la hora estimada`}
                        </p>
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {!order.data.alertOrder && (
          <div className={`mx-4 sm:mx-10 mb-4 sm:my-8 bg-white flex flex-1`}>
            <p className='py-4 ml-4 text-base font-bold'>Sin urgencia</p>
          </div>
        )}

        <div className='mx-4 sm:mx-10 my-4 grid gap-4 grid-cols-1 sm:grid-cols-3'>
          <div className='bg-white rounded-xl p-4'>
            <p className='text-primary font-bold text-sm'>Datos Cliente</p>

            <h1 className='text-lg font-bold'>{order.data.detail.data.customer.data.fullName}</h1>

            <div className='flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center'>
              <div>
                <div className='mt-4'>
                  <p className='text-primary font-normal text-sm'>Dirección</p>
                  <p className='text-black font-normal text-sm mt-1'>
                    {order.data.detail.data.addresses.data.adr || ''} {order.data.detail.data.addresses.data.number || ''}
                  </p>
                </div>

                <div className='mt-4'>
                  <p className='text-primary font-normal text-sm'>Ciudad</p>
                  <p className='text-black font-normal text-sm mt-1'>{order.data.detail.data.addresses.data.state || ''}</p>
                </div>

                <div className='mt-4'>
                  <p className='text-primary font-normal text-sm'>{nameDistrictForCountry(countryUser)}</p>
                  <p className='text-black font-normal text-sm mt-1'>{order.data.detail.data.addresses.data.district || ''}</p>
                </div>
              </div>

              <div>
                <div className='mt-4'>
                  <p className='text-primary font-normal text-sm'>Teléfono</p>
                  <p className='text-black font-normal text-sm mt-1'>{order.data.detail.data.customer.data.phone || ''}</p>
                </div>

                <div className='mt-4'>
                  <p className='text-primary font-normal text-sm'>Correo</p>
                  <p className='text-black font-normal text-sm mt-1'>{order.data.detail.data.customer.data.email || ''}</p>
                </div>

                <div className='mt-4'>
                  <p className='text-primary font-normal text-sm'>Documento</p>
                  <p className='text-black font-normal text-sm mt-1'>
                    {order.data.detail.data.customer.data.documentType || ''} - {order.data.detail.data.customer.data.numberDocument || ''}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl p-4'>
            <p className='text-primary font-bold text-sm'>Datos de envío</p>

            <div className='flex mt-4 justify-between'>
              <div className='flex items-center'>
                <IconCustom className='text-sm text-primary' name={stringDeliveryToIcon(order.data.detail.data.shipphing.data.type)} />
                <div className='flex justify-center items-center'>
                  <p className='text-primary text-xs font-normal ml-2'>
                    {shippingTypeStringToDisplay(order.data.detail.data.shipphing.data.type)}
                  </p>
                </div>
              </div>

              <div className='flex'>
                <div className='flex items-center'>
                  <IconCustom name={NameIcons.warehouse} className='text-xs mr-1' />
                  <p className={'font-bold text-base ml-2'}>{order?.getDataTable().order?.warehouse}</p>
                </div>
              </div>
            </div>

            <div>
              <div className='mt-4 flex'>
                <p className='text-black font-normal text-sm'>Estado de envío</p>
                <div className={`${stringStatusDeliveryToColor(order.data.status)} ml-4 px-2 rounded-full`}>
                  <p className='text-white font-normal text-sm'>{order.data.status || ''}</p>
                </div>
              </div>

              <div className='mt-8 flex'>
                <p className='text-black font-normal w-14 text-sm'>Items</p>
                <p className='text-black font-normal text-sm ml-8'>{order.data.detail.data.shipphing.data.items || ''}</p>
              </div>

              <div className='mt-4 flex items-center'>
                <p className='text-black font-normal w-14 text-sm'>Tamaño</p>
                {authStatus.user?.isLocatario() && (
                  <p className='text-black font-normal text-sm ml-8'>{order.data.detail.data.shipphing.data.size || 'No ingresado'}</p>
                )}
                {!authStatus.user?.isLocatario() && (
                  <SelectCustom
                    className='ml-8 flex flex-1'
                    classNameContainer='flex flex-1'
                    style={{ width: '100%' }}
                    data={dimensions?.map(dim => ({ value: dim.data.value, label: dim.data.name, item: dim }))}
                    value={order.data.detail.data.shipphing.data.size}
                    onChange={val => {
                      //console.log('onChange() => val', val);
                      if (onUpdate) {
                        order.data.detail.data.shipphing.data.size = val.data.value;
                        onUpdate(order);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className='bg-white rounded-xl p-4'>
            <p className='text-primary font-bold text-sm'>Precio y costos</p>
            <p className='mt-4 text-xs text-grayarauco'>
              País: {order.data.detail.data.addresses.data.country} | Divisa: {order.data.detail.data.shipphing.data.currency}
            </p>
            <div className='flex justify-between items-center mt-4'>
              <p>Precio</p>
              <p className='font-bold text-lg'>
                {convertNumberToPrice({ val: parseFloat(`${order.data.detail.data.shipphing.data.purchasePrice}`), country: countryUser })}
              </p>
            </div>

            <div className='flex justify-between items-center mt-4'>
              <p>Costo de servicio</p>
              <p className='font-bold text-lg'>
                {convertNumberToPrice({ val: parseFloat(`${order.data.deliveryCost}`), country: countryUser })}
              </p>
            </div>
          </div>
        </div>

        <div className='bg-white rounded-xl p-4 mx-4 sm:mx-10'>
          <p className='text-primary font-bold text-sm'>Fecha y hora</p>

          <div className='flex flex-col justify-start items-start sm:flex-row sm:justify-center sm:items-center'>
            <div className={`flex-1 flex flex-col justify-between pt-4 pb-4 sm:pb-0 pr-0 w-full sm:pr-8`}>
              <p className='text-sm text-primary'>Fecha ingreso</p>
              <div className='flex justify-between items-center mt-1'>
                <div className='flex justify-center items-center'>
                  <IconCustom name={NameIcons.calendarMonth} className='text-grayarauco mr-1 text-base sm:text-2xl' />
                  <div>
                    <p className='text-grayarauco text-lg'>{order.getDataTable().order?.creationDate}</p>
                  </div>
                </div>

                <div className='flex justify-center items-center'>
                  <IconCustom name={NameIcons.schedule} className='text-grayarauco mr-1 text-base sm:text-2xl' />
                  <div>
                    <p className='text-grayarauco text-lg'>{order.getDataTable().order?.creationTime}</p>
                  </div>
                </div>
              </div>
            </div>

            {order.data.warehouseTime && (
              <div
                className={`flex-1 flex flex-col justify-between pt-4 pb-4 sm:pb-0 px-0 sm:px-8 border-grayaraucoborder w-full ${
                  screenResolution > SizeScreen.sm ? `border-l border-r` : `border-t`
                }`}
              >
                <p className='text-sm text-primary'>Ingresó a bodega</p>
                <div className='flex justify-between items-center mt-1'>
                  <div className='flex justify-center items-center'>
                    <IconCustom name={NameIcons.calendarMonth} className='text-grayarauco mr-1 text-xs sm:text-2xl' />
                    <div>
                      <p className='text-grayarauco text-sm'>{order.data.warehouseTime.split(' ')[0]}</p>
                    </div>
                  </div>

                  <div className='flex justify-center items-center'>
                    <IconCustom name={NameIcons.schedule} className='text-grayarauco mr-1 text-xs sm:text-2xl' />
                    <div>
                      <p className='text-grayarauco text-sm'>{order.data.warehouseTime.split(' ')[1]}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`flex-1 flex flex-col justify-between pt-4 pl-0 sm:pl-8 w-full border-grayaraucoborder ${
                order.getDataTable().order?.estimatedDate ? '' : 'border-l'
              } ${screenResolution > SizeScreen.sm ? '' : 'border-t'}`}
            >
              <p className='text-sm text-primary'>Fecha {order.getDataTable().order?.deliveredDate !== '' ? 'entrega' : 'estimada'}</p>
              <div className='flex justify-between items-center mt-1'>
                <div className='flex justify-center items-center'>
                  <IconCustom name={NameIcons.calendarMonth} className='text-grayarauco mr-1 text-xs sm:text-2xl' />
                  <div>
                    <p className='text-grayarauco text-sm'>
                      {order.getDataTable().order?.deliveredDate || order.getDataTable().order?.estimatedDate}
                    </p>
                  </div>
                </div>

                <div className='flex justify-center items-center'>
                  <IconCustom name={NameIcons.schedule} className='text-grayarauco mr-1 text-xs sm:text-2xl' />
                  <div>
                    <p className='text-grayarauco text-sm'>
                      {order.getDataTable().order?.deliveredTime || order.getDataTable().order?.estimatedTime}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mx-4 sm:mx-10 my-4 grid gap-12 grid-cols-1 sm:grid-cols-1'>
          <div className='bg-white rounded-xl p-4'>
            <p className='text-primary font-bold text-sm'>Envío de correos electrónicos</p>

            <BoardCustom
              persistTableHead
              responsive={true}
              data={order.data.detail.data.emailEventLogs || []}
              columns={Order.headerEmailsTable()}
            />
          </div>
        </div>
      </MenuSessionContainer>
    );
  }

  return null;
};

export default DetailOrderOrganism;
