import { Skeleton } from 'antd';
import { SizeScreen } from 'configs/Enums';
import { RootContext } from 'context/RootContext';
import { CSSProperties, useContext } from 'react';

const TabBarItem = ({
  title,
  value,
  keyV,
  keyActive,
  isFirst,
  className,
  style,
  onClick,
  valueClassName
}: {
  title: string;
  value?: string | number;
  /** no se pudo colocar key al pasa como undefined **/
  keyV: any;
  keyActive: any;
  isFirst?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: (val: any) => void;
  valueClassName?: string;
}) => {
  const { screenResolution } = useContext(RootContext);

  return (
    <div
      className={`flex justify-center items-center py-9 px-4 flex-col w-full flex-1 ${isFirst ? '' : ''} ${
        keyV === keyActive ? 'bg-white' : 'bg-grayarauco-50'
      } ${className}`}
      style={{ width: screenResolution > SizeScreen.md ? '100%' : 'auto', ...style }}
      onClick={onClick ? () => onClick(keyV) : undefined}
    >
      <p className={`text-xl ${keyV === keyActive ? 'text-primary font-bold' : 'text-black font-normal'}`}>{title}</p>
      {value === undefined && <Skeleton.Input size='small' />}
      {value !== undefined && <p className={`font-bold text-black ${valueClassName}`}>{value}</p>}
    </div>
  );
};

export default TabBarItem;
