import { TypeDeliveryDisplay } from 'configs/Enums';
import { shippingTypeStringToDisplay } from 'helpers/Converts';

export interface CountOrderByTypeDeliveryItemModel {
  name?: string;
  value?: number;
  type?: TypeDeliveryDisplay;
}

export interface CountOrderByTypeDeliveryModel {
  items?: CountOrderByTypeDeliveryItemModel[];
}

export type CountOrderByTypeDeliveryServerModel = [string, number][];

class CountOrderByTypeDelivery {
  data: CountOrderByTypeDeliveryModel;

  constructor(data: CountOrderByTypeDeliveryModel) {
    this.data = data;
  }

  static formalizeData(data: any) {
    const items: CountOrderByTypeDeliveryItemModel[] = [];

    if (data) {
      for (const delivery in data) {
        items.push({
          name: shippingTypeStringToDisplay(delivery),
          value: data[delivery],
          type: shippingTypeStringToDisplay(delivery) as TypeDeliveryDisplay
        });
      }
      //data.forEach(element => {
      //if (element && element.length) {
      //items.push({ name: element[0], value: element[1], type: element[0] as TypeDeliveryDisplay });
      //}
      //});
    }

    return { items };
  }
}

export default CountOrderByTypeDelivery;
