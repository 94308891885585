import { useState } from 'react';
import { Link } from 'react-router-dom';

import { PathRoute, TypeAlertMessage } from 'configs/Enums';

import ButtonCustom from 'components/atoms/buttons';
import InputCustom from 'components/atoms/inputs';
import LabelCustom from 'components/atoms/labels';
import AlertMessage from 'components/molecules/messages/Alert';
import LayoutContainerAuth from 'components/molecules/layout/ContainerAuth';

import { validateEmail } from 'helpers/Validate';

type LoginFormMoleculeProps = {
  handleSignIn: (formLogin: { email: string; password: string }) => void;
  onNavigation?: (pathRoute: PathRoute) => void;
};

const LoginFormMolecule = ({ handleSignIn }: LoginFormMoleculeProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [messageErrorEmail, setMessageErrorEmail] = useState<string>();
  const [generalMessageError, setGeneralMessageError] = useState<string>();

  const handleChangeEmail = (event: any) => {
    setGeneralMessageError(undefined);
    setMessageErrorEmail(undefined);
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: any) => {
    setGeneralMessageError(undefined);
    setPassword(event.target.value);
  };

  const handleSubmit = (event: any) => {
    if (email !== '') {
      if (password !== '') {
        //console.log('handleSubmit() ===>', validateEmail(email));
        if (validateEmail(email)) {
          handleSignIn({ email, password });
        } else {
          setMessageErrorEmail('El email no cumple con el formato de example@mail.com.');
        }
      } else {
        setGeneralMessageError('El campo es obligatorio.');
      }
    } else {
      setMessageErrorEmail('El campo es obligatorio.');
    }
    event.preventDefault();
  };

  return (
    <LayoutContainerAuth onSubmit={handleSubmit} classNameForm={'flex flex-col'} classNameTop={'h-screen overflow-auto'}>
      <div className='mb-4'>
        <LabelCustom className='mb-2'>Correo Electrónico</LabelCustom>
        <InputCustom placeholder='usuario@micorreo.cl' onChange={handleChangeEmail} />
      </div>

      {messageErrorEmail && <AlertMessage text={messageErrorEmail} type={TypeAlertMessage.danger} />}

      <div className='mb-6'>
        <LabelCustom className='mb-2'>Contraseña</LabelCustom>
        <InputCustom placeholder='Contraseña' onChange={handleChangePassword} type='password' />
      </div>

      {generalMessageError && <AlertMessage text={generalMessageError} type={TypeAlertMessage.danger} />}

      {/**
      <div className='flex items-center justify-center'>
        <ButtonCustom className='font-bold w-80' type='submit'>
          Ingresar
        </ButtonCustom>
      </div>
        **/}
      <div className='flex items-center justify-between'>
        <Link
          className='inline-block align-baseline font-normal text-sm text-blue-500 hover:text-blue-800 mr-2'
          to={PathRoute.reconverPassword}
        >
          ¿Olvidó su contraseña?
        </Link>

        <ButtonCustom className='font-bold ml-2' htmlType='submit'>
          Ingresar
        </ButtonCustom>
      </div>
    </LayoutContainerAuth>
  );
};

export default LoginFormMolecule;
