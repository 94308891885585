import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import { EnumFileUploadStates, EnumInvoiceSection, TypeToast } from 'configs/Enums';

import InvoiceOrganism from 'components/organisms/dashboard/Invoice';
import { FilterBillingParams } from 'components/molecules/filters/BillingFilter';

import Invoice from 'models/invoice';

import { getInvoiceDetail, getInvoiceCurrent, getInvoiceHistorical } from 'services/app/Invoice';
import FileOrders from 'models/files/FileOrders';

const InvoicePage = () => {
  const { showLoading, hideLoading, showToast } = useContext(RootContext);
  const { countryUser } = useContext(AuthContext);
  const { addUploadFile, updateUploadFile } = useContext(DataContext);

  const [bills, setBills] = useState<Invoice[]>([]);
  const [billCurrent, setBillCurrent] = useState<Invoice>();
  const [section, setSection] = useState(EnumInvoiceSection.billingProgress);
  const [paramsFilter, setParamsFilter] = useState<FilterBillingParams>();
  const [billDetail, setBillDetail] = useState<Invoice>();

  const onLoadInvoiceCurrent = async (paramsFilter?: FilterBillingParams) => {
    try {
      const { status, data, message } = await getInvoiceCurrent({ ...paramsFilter, country: countryUser });
      console.log('onLoadInvoiceCurrent() =>', { status, data, message });

      if (status) {
        setBillCurrent(data);
      } else {
        showToast({ text: message || 'No fue posible cargar las facturas.', type: TypeToast.error });
        setBillCurrent(data);
      }
    } catch (err) {
      console.log('onLoadInvoiceCurrent() ==>', err);
      setBillCurrent(Invoice.voidIvoice());
    }
  };

  const onLoadInvoiceHistorical = async (paramsFilter?: FilterBillingParams) => {
    try {
      const { status, data, message } = await getInvoiceHistorical({ ...paramsFilter, country: countryUser });
      console.log('onLoadInvoiceHistorical() =>', { status, data, message });

      if (status) {
        const billsNew: Invoice[] = [...data];
        if (billsNew.length > 1) {
          const billsTotal = billsNew.reduce((prev: any, curr: any) => {
            const newPrev = new Invoice({ ...prev.data });
            newPrev.data.amountPickup = (prev.data.amountPickup || 0) + (curr.data.amountPickup || 0);
            newPrev.data.amountDelivery = (prev.data.amountDelivery || 0) + (curr.data.amountDelivery || 0);
            newPrev.data.total = (prev.data.total || 0) + (curr.data.total || 0);
            newPrev.data.dateEndDisplayBoard = '';
            newPrev.data.dateStartDisplayBoard = '';
            return newPrev;
          });
          data.push(billsTotal);
          console.log('onLoadInvoiceHistorical()1 ==> { data }', { data });
          setBills(data || []);
        } else {
          const billsTotal = new Invoice({ ...billsNew[0].data, dateEndDisplayBoard: '', dateStartDisplayBoard: '' });
          data.push(billsTotal);
          console.log('onLoadInvoiceHistorical()2 ==> { data }', { data });
          setBills(data || []);
        }
      } else {
        showToast({ text: message || 'No fue posible cargar las facturas.', type: TypeToast.error });
        setBills(data || []);
      }
    } catch (err) {
      console.log('onLoadInvoiceHistorical() ==>', err);
      setBills([]);
    }
  };

  const onLoadDetail = async (paramsFilter?: FilterBillingParams) => {
    try {
      showLoading();
      const { data, status, message } = await getInvoiceDetail({
        ...paramsFilter,
        store: paramsFilter?.invoice?.data.store,
        country: countryUser
      });
      console.log('onLoadDetail() =>', { status, data, message });

      if (status) {
        setBillDetail(data);
      } else {
        setBillDetail(undefined);
        showToast({ text: message, type: TypeToast.error });
      }
      hideLoading();
    } catch (err) {
      console.log('onLoadDetail() => err', err);
      setBillDetail(undefined);
      hideLoading();
    }
  };

  const onFilter = async (params?: FilterBillingParams) => {
    try {
      showLoading();
      setParamsFilter({ ...paramsFilter, ...params });
      console.log('params ==> ', params);
      await onLoadInvoiceCurrent(params);
      await onLoadInvoiceHistorical(params);
      hideLoading();
    } catch (err) {
      console.log('onFilterFather() ==> err', err);
      setBills([]);
      hideLoading();
    }
  };

  const onDownload = async (val: Invoice) => {
    showLoading();
    try {
      if (val.data.url) {
        addUploadFile(
          new FileOrders({
            nameFile: `Factura ${val.data.dateStartDisplayBoard}-${val.data.dateEndDisplayBoard}`,
            status: EnumFileUploadStates.inProcess,
            id: val.data.id,
            type: 'download'
          })
        );

        const { status } = await val.download();

        updateUploadFile(
          new FileOrders({
            status: EnumFileUploadStates.complete,
            id: val.data.id,
            type: status ? 'download' : 'error'
          })
        );
      } else {
        showToast({ text: 'No es posible descargar el archivo.', type: TypeToast.info });
      }
    } catch (err) {
      console.log('onDownload() ==> err', err);
    }
    hideLoading();
  };

  const onChangeSeccion = (sectionNew: EnumInvoiceSection) => {
    setSection(sectionNew);
  };

  const onLoadAll = async () => {
    showLoading();
    if (paramsFilter) {
      await onLoadInvoiceCurrent(paramsFilter);
    }
    hideLoading();
  };

  useEffect(() => {
    onLoadAll();
  }, []);

  return (
    <InvoiceOrganism
      updateDetail={val => {
        setBillDetail(val);
        console.log('updateDetail() => val', val);
      }}
      {...{ bills, billCurrent, onFilter, onDownload, onChangeSeccion, section, onLoadDetail, billDetail, paramsFilter }}
    />
  );
};

export default InvoicePage;
