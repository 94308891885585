import { useContext, useEffect, useState } from 'react';
import { ExpanderComponentProps } from 'react-data-table-component';

import { NameIcons, SizeScreen, StyleButton } from 'configs/Enums';
import { RootContext } from 'context/RootContext';
import { RouterInContext } from 'context/RouterIn';

import ButtonCustom from 'components/atoms/buttons';
import IconCustom from 'components/atoms/icons';
import BoardCustom from 'components/molecules/board';

import FrequentQuestion, { FrequentQuestionModel } from 'models/questions/FrequentQuestion';
import ModalCustom from 'components/molecules/modals';
import InputCustom from 'components/atoms/inputs';
import MarkdownEditor from 'components/atoms/markdown';
import FrequentQuestionCard from 'components/molecules/items/cards/FrequentQuestion';
import FrequentQuestionBoard from 'components/molecules/items/cards/FrequentQuestionBoard';

type InformationBoardProps = {
  informations?: FrequentQuestion[];
  onDeleted: (fq: FrequentQuestion) => void;
  onCreated: (fq: FrequentQuestion) => void;
  onUpdated: (fq: FrequentQuestion) => void;
  onLoadInformations: () => void;
};

const InformationBoard = ({ informations, onDeleted, onCreated, onUpdated, onLoadInformations }: InformationBoardProps) => {
  const { showModalQuestion, screenResolution } = useContext(RootContext);
  const { hContainer } = useContext(RouterInContext);

  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const [informationCurrent, setInformationCurrent] = useState<FrequentQuestionModel>();

  const onDelete = (frequentQuestion: FrequentQuestion) => {
    showModalQuestion({
      contentStyle: { padding: 0 },
      classNameOptions: 'mx-4 mb-4',
      title: (
        <div className='border-grayarauco-200 px-4 py-4' style={{ borderBottomWidth: 1 }}>
          <h1 className='text-2xl font-bold'>Eliminación de información</h1>
        </div>
      ),
      message: (
        <div className='mx-4 flex flex-col justify-center items-center py-8'>
          <IconCustom name={NameIcons.error} className='text-primary text-7xl' />
          <p className='mt-4 text-center text-xl font-normal'>¿Estás seguro?</p>
          <p className='mt-4 text-center text-xl font-normal'>Se eliminará toda la información seleccionada</p>
        </div>
      ),
      options: [
        { text: 'Cancelar', style: StyleButton.outlinePrimary },
        { text: 'Eliminar', onAction: () => onDeleted(frequentQuestion) }
      ]
    });
  };

  const expandedComponent: React.FC<ExpanderComponentProps<FrequentQuestion>> = ({ data }) => {
    return (
      <div className='bg-gray-100 p-4'>
        <FrequentQuestionCard frequentQuestion={data} />
      </div>
    );
  };

  useEffect(() => {
    onLoadInformations();
  }, []);

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row gap-3 items-center mb-4'>
        <p className='text-base md:text-xl font-bold'>Información</p>

        <ButtonCustom onClick={() => setShowCreate(true)}>
          <IconCustom name={NameIcons.add} />
        </ButtonCustom>
      </div>

      {screenResolution > SizeScreen.md && (
        <BoardCustom
          data={informations || []}
          columns={FrequentQuestion.headerTable({
            showModalUpdate: val => {
              setInformationCurrent(val.data);
              setShowUpdate(true);
            },
            onDeleted: onDelete
          })}
          expandableRowsComponent={expandedComponent}
          expandableRows
        />
      )}

      {screenResolution <= SizeScreen.md && (
        <div>
          {informations &&
            informations.length > 0 &&
            informations.map(inf => (
              <FrequentQuestionBoard
                key={inf.data.id}
                fq={inf}
                onUpdated={val => {
                  setInformationCurrent(val.data);
                  setShowUpdate(true);
                }}
                onDeleted={onDelete}
              />
            ))}
        </div>
      )}

      {(showCreate || showUpdate) && (
        <ModalCustom isVisible={showCreate || showUpdate} notFullScreen onClose={() => setShowCreate(false)} styleContent={{ padding: 0 }}>
          <div className='flex flex-col bg-white pb-4 pt-4 px-5 sm:px-10' style={{ height: hContainer }}>
            <div className='flex flex-col gap-4'>
              <button
                className='text-xs font-normal self-start'
                onClick={() => {
                  setShowCreate(false);
                  setShowUpdate(false);
                  setInformationCurrent(undefined);
                }}
              >
                {'< Volver'}
              </button>
              <h1 className='text-2xl sm:text-3xl font-bold'>{showUpdate ? 'Editar' : 'Crear'} Información</h1>
            </div>

            <div className='flex flex-col flex-1 gap-4 mt-4 md:mt-8'>
              <InputCustom
                label='Información'
                value={informationCurrent?.question}
                onChange={val => setInformationCurrent({ ...informationCurrent, question: val.target.value })}
              />
              <MarkdownEditor
                value={informationCurrent?.answer}
                onChange={val => setInformationCurrent({ ...informationCurrent, answer: val })}
              />
            </div>

            <div className='flex flex-col sm:flex-row justify-between items-center gap-4 mt-4 pb-4'>
              <ButtonCustom
                onClick={() => {
                  setShowCreate(false);
                  setShowUpdate(false);
                  setInformationCurrent(undefined);
                }}
                style={{ width: screenResolution <= SizeScreen.sm ? '100%' : 250 }}
                styleButton={StyleButton.outlinePrimary}
              >
                Cancelar
              </ButtonCustom>
              <ButtonCustom
                onClick={() => {
                  if (showCreate && informationCurrent) {
                    onCreated(new FrequentQuestion(informationCurrent));
                    setShowCreate(false);
                  } else if (showUpdate && informationCurrent) {
                    onUpdated(new FrequentQuestion(informationCurrent));
                    setShowUpdate(false);
                  }
                  setInformationCurrent(undefined);
                }}
                style={{ width: screenResolution <= SizeScreen.sm ? '100%' : 250 }}
              >
                Actualizar
              </ButtonCustom>
            </div>
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default InformationBoard;
