import { ReactNode, useContext, useEffect, useState } from 'react';
import { Dropdown } from 'antd';

import { NameIcons, SizeScreen } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import ButtonCustom from 'components/atoms/buttons';
import IconCustom from 'components/atoms/icons';
import InputCustom from '../inputs';
import CheckboxCustom from '../checkbox';

interface SelectionButtonItem {
  label: string | ReactNode;
  /** se busca con este elemento en caso de que alla buscador **/
  labelSearch: string;
  value: string | number;
  item?: any;
  checked?: boolean;
}

type SelectionButtonProps = {
  items?: SelectionButtonItem[];
  text?: string | ReactNode;
  values?: any[];
  className?: string;
  limitBubbles?: number;
  isRequired?: boolean;
  onChange?: (values?: any) => void;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  classNameContainer?: string;
  onOpen?: (val?: any[]) => void;
  onClose?: (val?: any[]) => void;
};

const ContentSelectionButton = ({
  items,
  onChangePrev,
  values,
  limitBubbles = 15,
  isRequired
}: {
  items?: SelectionButtonItem[];
  onChangePrev: (vals: any[] | undefined) => void;
  values?: any[];
  limitBubbles?: number;
  isRequired?: boolean;
}) => {
  //const [valuesCurrent, setValuesCurrent] = useState<any[]>();
  const [flag, setFlag] = useState(0);
  const [itemsList, setItemsList] = useState<SelectionButtonItem[]>();
  const [valueInputSearch, setValueInputSearch] = useState<string>();

  const onChange = () => {
    //console.log('onChange() => { items }', { items });
    setFlag(flag + 1);
    onChangePrev(items?.filter(itm => itm.checked)?.map(itm => itm.item));
  };

  useEffect(() => {
    //setValuesCurrent(values);
    items?.forEach(item => {
      const resultFind = values?.find(val => {
        if (typeof val === 'string' || typeof val === 'number') {
          return val === item.value;
        }
        return val.toString() === item.value;
      });

      if (resultFind) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    if (valueInputSearch && valueInputSearch !== '') {
      const filterItemsList = items?.filter(itm => itm.labelSearch?.toLowerCase().search(valueInputSearch?.toLowerCase()) !== -1);
      setItemsList(filterItemsList);
    } else {
      setItemsList(items);
    }
    setFlag(flag + 1);
    //console.log('useEffect()2 => values', { values, items });
  }, [values, items]);

  return (
    <div className='p-2 bg-white shadow-md' style={{ borderRadius: 10 }}>
      {items && items.length <= limitBubbles && (
        <div style={{ borderRadius: 20, borderWidth: 1 }} className='border-grayarauco-300 p-2'>
          {items?.some(item => item.checked) && (
            <div className='flex flex-row'>
              <div className='gap-x-2 gap-y-1 flex flex-auto flex-wrap w-96'>
                {items
                  ?.filter(item => item.checked)
                  ?.map(item => (
                    <div
                      key={item.value}
                      style={{ borderRadius: 10, borderWidth: 0.6 }}
                      className='border-araucoblue-200 flex flex-row p-2'
                    >
                      <div className='flex flex-1'>
                        <p className='text-sm text-black font-normal'>{item.label}</p>{' '}
                      </div>

                      <div className='flex justify-center items-center'>
                        <IconCustom
                          onClick={() => {
                            item.checked = false;
                            onChange();
                          }}
                          className='ml-2 text-sm text-grayarauco-400 select-none cursor-pointer'
                          name={NameIcons.close}
                        />
                      </div>
                    </div>
                  ))}
              </div>

              <div className='flex justify-center items-center'>
                <IconCustom
                  name={NameIcons.deleted}
                  onClick={() => {
                    items?.forEach(itm => {
                      itm.checked = false;
                    });
                    onChange();
                  }}
                  className='cursor-pointer select-none text-3xl text-grayarauco-400'
                />
              </div>
            </div>
          )}

          {!items?.some(item => item.checked) && isRequired && (
            <div>
              <p className='text-sm font-normal'>Debes seleccionar al menos una opción</p>
            </div>
          )}
        </div>
      )}

      <div className=''>
        {items && items.length > limitBubbles && (
          <InputCustom
            type='search'
            suffix={<IconCustom className='text-grayarauco-400' name={NameIcons.search} />}
            classNameContainer='w-full mt-4'
            classNameContainerInput='w-full'
            onChange={e => {
              console.log('onChange() ==> e', { e, value: e.target.value });
              const filterItemsList = items?.filter(itm => itm.labelSearch?.toLowerCase().search(e.target.value?.toLowerCase()) !== -1);
              setValueInputSearch(e.target.value);
              setItemsList(filterItemsList);
            }}
          />
        )}

        {items && items.length > limitBubbles && !items?.some(item => item.checked) && isRequired && (
          <div>
            <p className='text-sm font-normal'>Debes seleccionar al menos una opción</p>
          </div>
        )}

        {itemsList && itemsList?.length > 0 && (
          <div className='overflow-auto' style={{ maxHeight: 200 }}>
            <div className='flex items-center mt-6' style={{ paddingLeft: 10, paddingRight: 10 }}>
              <CheckboxCustom
                checked={items?.every(itm => itm.checked)}
                onChange={e => {
                  const valChec = e.target.checked;
                  items?.forEach(itm => {
                    itm.checked = valChec;
                  });
                  onChange();
                }}
              />
              <p className='ml-2'>Todos</p>
            </div>

            {itemsList?.map(item => (
              <div key={item.value} className='flex items-center mt-6' style={{ paddingLeft: 10, paddingRight: 10 }}>
                <CheckboxCustom
                  checked={item.checked}
                  onChange={e => {
                    item.checked = e.target.checked;
                    onChange();
                  }}
                />
                {typeof item.label === 'string' && <p className='ml-2'>{item.label}</p>}
                {typeof item.label !== 'string' && item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const SelectionButton = ({
  items,
  text,
  values,
  className,
  isRequired = true,
  limitBubbles,
  onChange,
  getPopupContainer,
  classNameContainer,
  onOpen,
  onClose
}: SelectionButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { screenResolution } = useContext(RootContext);
  const [valuesCurrent, setValuesCurrent] = useState<any[]>();

  const onChangePrev = (valuesNew: any[] | undefined) => {
    setValuesCurrent(valuesNew);
    if (onChange) {
      onChange(valuesNew);
    }
  };

  useEffect(() => {
    setValuesCurrent(values);
    //console.log('useEffect()1 => values', { values });
  }, [values]);

  return (
    <div
      className={`${classNameContainer} border-grayarauco-300 pb-4 sm:pb-0 sm:border-transparent`}
      style={{ borderBottomWidth: screenResolution > SizeScreen.md ? 0 : 1 }}
    >
      <Dropdown
        trigger={['click']}
        onVisibleChange={vis => {
          //console.log('onVisibleChange() => vis', vis);
          if (vis) {
            onOpen ? onOpen(valuesCurrent) : undefined;
          } else {
            onClose ? onClose(valuesCurrent) : undefined;
          }
        }}
        overlay={
          screenResolution < SizeScreen.md ? (
            <></>
          ) : (
            <ContentSelectionButton values={valuesCurrent} {...{ items, onChangePrev, limitBubbles, isRequired }} />
          )
        }
        {...{ getPopupContainer }}
      >
        <ButtonCustom
          onClick={() => {
            const valNew = !isOpen;
            //console.log('onClick() => valNew', valNew);
            setIsOpen(valNew);
            if (valNew) {
              onOpen ? onOpen(valuesCurrent) : undefined;
            } else {
              onClose ? onClose(valuesCurrent) : undefined;
            }
          }}
          className={`flex justify-center items-center ${className}`}
          style={{ height: 40, paddingLeft: 8, paddingRight: 8 }}
        >
          <div className='flex flex-row'>
            <p className='text-sm font-bold'>{text}</p>
            <p className='text-sm font-normal ml-1'>{`(${valuesCurrent?.length || 0})`}</p>
          </div>
          <IconCustom name={NameIcons.filter} />
        </ButtonCustom>
      </Dropdown>
      {screenResolution < SizeScreen.md && isOpen && (
        <ContentSelectionButton values={valuesCurrent} {...{ items, onChangePrev, limitBubbles, isRequired }} />
      )}
    </div>
  );
};

export default SelectionButton;
