import { EnumNotificationType, FormatDate } from 'configs/Enums';
import { stringAtDate } from 'helpers/Converts';

export interface NotificationModel {
  date?: string;
  time?: string;
  dateFull?: string;
  data?: any;
  connectionId?: string;
  description?: string;
  descriptionAlternative?: string;
  store?: string;
  country?: string;
  state?: string;
  title?: string;
  type?: string;
  titleAlternative?: string;
  idOrders?: string[];
  fails?: number;
  correct?: number;
}

export interface NotificationServerModel {
  date?: string;
  data?: any;
  connectionId?: string;
  description?: any;
  country?: string;
  state?: string;
  title?: string;
  type?: string;
}

class Notification {
  data: NotificationModel;

  constructor(data: NotificationModel) {
    this.data = data;
  }

  static convertAlternativeTitle(noti: NotificationServerModel) {
    try {
      if (noti.type === EnumNotificationType.ordersNotificationFail) {
        //console.log('convertAlternativeTitle() => noti', { noti, leg: noti.description?.fails });
        if (noti.description?.fails === 0) {
          return `Nuevas órdenes (${noti.description?.correct})`;
        }
        return `Ordenes fallidas (${noti.description?.fails})`;
      } else if (noti.type === EnumNotificationType.creationOrders) {
        if (noti.data && typeof noti.data !== 'string' && 'data' in noti.data[0] && !noti.data[0].data.order) {
          //console.log('convertAlternativeTitle() ==> noti', noti);
          return `Nuevas órdenes (${noti.data[0].data.length})`;
        }
        return `Nueva orden`;
      } else if (noti.type === EnumNotificationType.new) {
        return `Nueva orden`;
      }
      return noti.title;
    } catch (err) {
      //console.log('convertAlternativeTitle() ==> err', { err, noti });
      return noti.title;
    }
  }

  static convertAlternativeDescription({ noti, numOrder }: { noti: NotificationServerModel; numOrder?: string; shop?: string }) {
    try {
      if (noti.type === EnumNotificationType.ordersNotificationFail) {
        //console.log('convertAlternativeTitle() => noti', { noti, leg: noti.description?.fails });
        if (noti.description?.fails === 0) {
          return `Se ingresaron varias ordenes nuevas.`;
        }
        return `Varias órdenes contienen errores. corrígelas en el módulo de órdenes fallidas.`;
      } else if (noti.type === EnumNotificationType.creationOrders) {
        if (noti.data && typeof noti.data !== 'string' && 'data' in noti.data[0] && !noti.data[0].data.order) {
          return `Se ingresaron varias ordenes nuevas.`;
        }
        return `Orden ${noti.data[0].order.order}`;
      } else if (noti.type === EnumNotificationType.new) {
        return `Orden ${numOrder}`;
      }
      return noti.title;
    } catch (err) {
      //console.log('convertAlternativeDescription() ==> err', { err, noti });
      return noti.title;
    }
  }

  static formalizeData(data: NotificationServerModel): NotificationModel {
    const { date, data: dataNotf, connectionId, description, country, state, title, type } = data;

    const dateFormat = stringAtDate(date, FormatDate.yyyymmddAltHHmm).split(' ');
    let idOrders: string[] = [];
    let shop: string | undefined;
    let orderNum = '';
    let correct = 0;
    let fails = 0;

    if (type === EnumNotificationType.creationOrders) {
      if (dataNotf.length > 0 && dataNotf[0].data) {
        idOrders = dataNotf[0].data;
      } else if (dataNotf.length > 0 && dataNotf[0].order) {
        idOrders = dataNotf[0].order.order;
      }
    }

    if (typeof dataNotf !== 'string' && dataNotf.shop) {
      shop = dataNotf.shop;
    }

    if (description) {
      const strSearch = 'Nueva Orden #';
      const startIndex = `${description}`.indexOf(strSearch);
      const firstIndex = `${description}`.indexOf(' - ');
      const secondIndex = `${description}`.indexOf(' - ', firstIndex + 3);
      orderNum = `${description}`.slice(startIndex + strSearch.length, secondIndex);
      if (shop === undefined) {
        shop = `${description}`.slice(0, firstIndex);
      }
    }

    if (type === EnumNotificationType.ordersNotificationFail) {
      const strSearch = 'tienda ';
      const startIndex = `${description.web}`.indexOf(strSearch);
      shop = `${description.web}`.slice(startIndex + strSearch.length);
      correct = description.correct;
      fails = description.fails;
    }

    if (type === EnumNotificationType.new) {
      idOrders = [orderNum];
    }

    return {
      date: dateFormat ? dateFormat[0] : '',
      time: dateFormat ? dateFormat[1] : '',
      dateFull: `${dateFormat[0]} ${dateFormat[1]}`,
      data: dataNotf,
      connectionId,
      description: typeof description === 'string' ? description : '',
      country,
      state,
      title,
      titleAlternative: Notification.convertAlternativeTitle(data),
      descriptionAlternative: Notification.convertAlternativeDescription({ noti: data, numOrder: orderNum, shop }),
      store: shop,
      type,
      idOrders,
      fails,
      correct
    };
  }
}

Notification.prototype.toString = function countryToString() {
  return `${this.data.connectionId}`;
};

export default Notification;
