import { TableColumn } from 'react-data-table-component';

import { VALIDATE_CONSTRAINTS } from 'configs/Constants';
import { EnumCountries, NameIcons, PathRoute, RoleUser } from 'configs/Enums';
import { SectionDashboard } from 'configs/Interface';

import IconCustom from 'components/atoms/icons';
import FilterHeaderColumn from 'components/molecules/headers/FilterHeaderColumn';
import TableCellMolecule from 'components/molecules/items/TableCell';

import { myArrayWithNoDuplicates } from 'helpers/Converts';

import UserRole, { UserRoleServerModel } from 'models/user/UserRole';
import Delivery, { DeliveryServerModel } from './Delivery';
import Dimension, { DimensionServerModel } from './Dimension';
import { FormItemModel } from './form';
import Store, { ShopServerModel } from './store';
import DocumentType, { DocumentTypeServerModel } from './user/DocumentType';

import { mySession } from 'storages/Session';

export interface GeneralInformationModel {
  deliveries: Delivery[];
  documents: DocumentType[];
  dimensions: Dimension[];
  commerceId?: string;
  stores?: Store[];
}

export interface GeneralInformationServerModel {
  delivery: DeliveryServerModel[];
  documents: DocumentTypeServerModel[];
  dimensions: DimensionServerModel[];
  country: string;
  commerceId?: string;
  stores?: ShopServerModel[];
}

export interface UserModel {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  picture?: string;
  id?: string;
  roles?: UserRole[];
  /** este campo se agrega después de iniciado la sección en el endpoint de /users/data **/
  generalInf?: GeneralInformationModel;
  shop?: Store;
  country?: string;
  shopId?: string;
  commerceId?: string;
  password?: string;
  passwordConfirm?: string;
  commerce?: Store;
}

export interface UserModelServer {
  firstName: string;
  lastName?: string;
  email: string;
  picture?: string;
  id?: string;
  role?: UserRoleServerModel[];
  shopId?: string;
  commerceId?: string;
  password?: string;
  commerce?: ShopServerModel;
}

export const sections: SectionDashboard[] = [
  {
    name: 'Cargar pedidos',
    icon: 'upload_file',
    path: PathRoute.uploadFile,
    type: 1,
    subRoutes: [
      {
        name: 'Carga masiva',
        icon: 'upload_file',
        path: PathRoute.uploadFile,
        type: 1
      },
      {
        name: 'Carga unitaria',
        icon: 'post_add',
        path: PathRoute.unitManualLoad,
        type: 1
      }
    ]
  },
  {
    name: 'Órdenes de envío',
    icon: NameIcons.inventory,
    path: PathRoute.listOrders,
    type: 2
  },
  {
    name: 'Estadísticas',
    description: 'Revisar dashboard de estadísticas',
    icon: 'insert_chart_outlined',
    path: PathRoute.dashboard,
    type: 3
  },
  {
    name: 'Facturación',
    icon: 'receipt',
    path: PathRoute.billing,
    type: 4
  },
  {
    name: 'Configuración',
    icon: 'settings',
    path: PathRoute.createUser,
    type: 7,
    subRoutes: [
      {
        name: 'Usuario',
        icon: NameIcons.person,
        path: PathRoute.createUser,
        type: 7
      },
      {
        name: 'Tienda',
        icon: NameIcons.store,
        path: PathRoute.createStore,
        type: 7
      },
      {
        name: 'Políticas de envío',
        icon: NameIcons.schedule,
        path: PathRoute.cutOffScheduleSetting,
        type: 7
      },
      {
        name: 'Límites de horarios',
        icon: NameIcons.traffic,
        path: PathRoute.orderAlertSettings,
        type: 7
      },
      {
        name: 'Preguntas frecuentes',
        icon: NameIcons.help,
        path: PathRoute.frequentQuestions,
        type: 7
      },
      {
        name: 'Actualización documentos',
        icon: NameIcons.uploadFile,
        path: PathRoute.updateHandbook,
        type: 7
      }
    ]
  },
  {
    name: 'Bienvenida',
    icon: 'welcome',
    path: PathRoute.welcome,
    /** no mostrar en el menu **/
    notMenu: true,
    notWelcome: true,
    type: 5
  },
  {
    name: 'Preguntas frecuentes',
    icon: NameIcons.help,
    path: PathRoute.frequentQuestionsList,
    /** no mostrar en el menu **/
    notMenu: false,
    notWelcome: true,
    type: 6
  },
  {
    name: 'Detalle orden',
    icon: 'question_answer',
    path: PathRoute.orderDetail,
    /** no mostrar en el menu **/
    notMenu: true,
    notWelcome: true,
    type: 8
  },
  {
    name: 'Órdenes fallidas',
    icon: NameIcons.playlistRemove,
    path: PathRoute.listFailedOrders,
    notMenu: true,
    notWelcome: true,
    type: 2
  }
];

class User {
  data: UserModel;

  constructor(data: UserModel) {
    this.data = data;
  }

  static formalizeData(data: UserModelServer): UserModel {
    const { firstName, lastName, email, picture, id, role, commerce } = data;

    return {
      firstName,
      lastName,
      fullName: (firstName || '') + (lastName ? ` ${lastName}` : ''),
      email,
      picture,
      id,
      roles: role && role.length > 0 ? role.map(rol => new UserRole(UserRole.formalizeData(rol))) : [],
      commerce: commerce ? new Store(Store.formalizeData(commerce)) : undefined
    };
  }

  getFirstLetterName() {
    const { firstName, lastName, fullName } = this.data;

    try {
      if (firstName && lastName) {
        return `${firstName[0]}${lastName[0]}`.toUpperCase();
      } else if (firstName) {
        return `${firstName[0]}${firstName[1]}`.toUpperCase();
      }

      return fullName;
    } catch (err) {
      return fullName;
    }
  }

  static formFields({
    shops,
    values,
    roles,
    isUpdate
  }: {
    shops: Store[];
    values?: UserModel;
    roles: UserRole[];
    /** es un formulario de actulizacion de informacion **/
    isUpdate?: boolean;
  }): FormItemModel[] {
    //console.log('formFields() ==> ', { shops, values });
    //

    return [
      {
        label: 'Nombre',
        type: 'text',
        identifier: 'firstName',
        isRequired: true,
        validateConstraints: VALIDATE_CONSTRAINTS.name,
        value: values?.firstName
      },
      {
        label: 'Apellido',
        type: 'text',
        identifier: 'lastName',
        isRequired: true,
        validateConstraints: VALIDATE_CONSTRAINTS.textOnly,
        value: values?.lastName
      },
      {
        label: 'Rol',
        type: 'select',
        identifier: 'roles',
        isRequired: true,
        data: roles.map(rol => ({ value: rol.toString(), label: rol.data.name || rol.data.value, item: rol })),
        value: values && values.roles && values.roles.length > 0 ? values.roles[0] : undefined
      },
      {
        label: 'Marca',
        type: 'select',
        isRequired: User.isLocatario(values),
        disabled: !User.isLocatario(values),
        identifier: 'commerce',
        data: shops && shops.length > 0 ? shops.map(shop => ({ value: shop.toString(), label: shop.data.brand || '', item: shop })) : [],
        value: values?.commerce
      },
      {
        label: 'Correo',
        type: 'text',
        identifier: 'email',
        isRequired: true,
        validateConstraints: VALIDATE_CONSTRAINTS.email,
        value: values?.email
      },
      {
        label: 'Contraseña',
        type: 'password',
        identifier: 'password',
        isRequired: !isUpdate,
        value: values?.password,
        passwordConfirmValue: values?.passwordConfirm,
        validateConstraints: VALIDATE_CONSTRAINTS.password
      },
      {
        label: 'Confirmación contraseña',
        type: 'password',
        identifier: 'passwordConfirm',
        isRequired: !isUpdate,
        value: values?.passwordConfirm,
        passwordValue: values?.password
      }
    ];
  }

  static isParameterInvalid(param?: string): boolean {
    if (param) {
      return param.indexOf('Error:') !== -1;
    }
    return false;
  }

  static removeFlag(param?: string): string {
    if (param) {
      return param.replace('Error:', '');
    }
    return '';
  }

  static headerTable({
    showModalUpdate,
    onDeleted,
    user
  }: {
    showModalUpdate: (item: User) => void;
    onDeleted: (item: User) => void;
    user?: User;
  }): TableColumn<User>[] {
    return [
      {
        name: <FilterHeaderColumn title='Nombre' />,
        selector: row => User.removeFlag(row.data.firstName),
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => User.isParameterInvalid(row.data.firstName),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={User.removeFlag(row.data.firstName)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Apellido' />,
        selector: row => User.removeFlag(row.data.lastName),
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => User.isParameterInvalid(row.data.lastName),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={User.removeFlag(row.data.lastName)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Email' />,
        selector: row => User.removeFlag(row.data.email),
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => User.isParameterInvalid(row.data.email),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={User.removeFlag(row.data.email)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Rol' />,
        selector: row => User.removeFlag(row.data.roles?.map(rol => rol.data.name).join(', ')),
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => User.isParameterInvalid(row.data.roles?.map(rol => rol.data.name).join(', ')),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            value={User.removeFlag(row.data.roles?.map(rol => rol.data.name).join(', '))}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn title='Marca' />,
        selector: row => User.removeFlag(row.data.commerce?.data.brand || ''),
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => User.isParameterInvalid(row.data.commerce?.data.brand || ''),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={User.removeFlag(row.data.commerce?.data.brand || '')} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='País' />,
        omit: !user?.isSuperAdminGlobal(),
        selector: row => User.removeFlag(`${row.data.commerce?.data.country}` || ''),
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => User.isParameterInvalid(`${row.data.commerce?.data.country}` || ''),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            value={User.removeFlag(`${row.data.commerce?.data.country}` || '')}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: 'Opciones',
        cell: row => (
          <div className='flex flex-row'>
            <button onClick={() => showModalUpdate(row)} className='mr-3'>
              <IconCustom name={NameIcons.rename} />
            </button>

            <button onClick={() => onDeleted(row)}>
              <IconCustom name={NameIcons.deleted} />
            </button>
          </div>
        )
      }
    ];
  }

  sendServer(commerceID?: string): UserModelServer {
    const { firstName, commerce, lastName, password, email, roles, country } = this.data;

    const sendUser: UserModelServer = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      //commerceId: commerceID || commerceId || '',
      //commerceId: commerce?.data.commerceId || '',
      //shopId: commerce?.data.id || '',
      role: roles?.map(role => ({ name: role.data.value || role.data.name }))
    };
    if (password) {
      sendUser.password = password;
    }
    if (this.isLocatario() && commerce) {
      sendUser.commerceId = commerce.data.commerceId;
      //sendUser.shopId = commerce.data.id;
    }
    if (this.isSuperAdmin() || this.isCellarPA()) {
      const countryBodega: any = commerce?.data.country;
      sendUser.commerce = { country: country || countryBodega?.data.value || EnumCountries.cl };
    }

    console.log('sendServer() ==> { commerceID, sendUser }', { commerceID, sendUser });
    return sendUser;
  }

  updateFormUser(data: UserModel): void {
    try {
      const { lastName, firstName, email, password, passwordConfirm, commerce, roles } = data;
      console.log('updateFormUser() ==>', roles);
      this.data.lastName = lastName;
      this.data.firstName = firstName;
      this.data.email = email;
      this.data.password = password;
      this.data.passwordConfirm = passwordConfirm;
      this.data.commerce = commerce;
      this.data.roles = roles;
    } catch (err) {
      console.log('updateFormUser() =>', err);
    }
  }

  updateInfoGeneral(data: GeneralInformationServerModel): void {
    try {
      const { delivery, documents, dimensions, country, commerceId, stores } = data;
      const deliverysFilter: any = [];

      delivery.forEach((e: any) => {
        e.map((i: any) => {
          if (!deliverysFilter.find((e: any) => e.name === i.name)) {
            deliverysFilter.push(i);
          }
        });
      });

      this.data.generalInf = {
        deliveries:
          deliverysFilter && deliverysFilter.length > 0
            ? deliverysFilter.map((deli: any) => new Delivery(Delivery.formalizeData(deli)))
            : [],
        documents: documents && documents.length > 0 ? documents.map(docu => new DocumentType(DocumentType.formalizeData(docu))) : [],
        dimensions: dimensions && dimensions.length > 0 ? dimensions.map(dim => new Dimension(Dimension.formalizeData(dim))) : [],
        commerceId,
        stores: stores && stores.length > 0 ? stores.map(store => new Store(Store.formalizeData(store))) : []
      };
      this.data.country = country;
    } catch (err) {
      console.log('updateInfoGeneral() ==>', err);
    }
  }

  updateCommerceId(commerceId?: string): void {
    this.data.commerceId = commerceId;
  }

  updateCountry(country?: string): void {
    this.data.country = country;
  }

  isLocatario(): boolean {
    const { roles } = this.data;

    if (roles?.find(rol => rol.data.value === RoleUser.locatario)) {
      return true;
    }
    return false;
  }

  isCellarPA(): boolean {
    const { roles } = this.data;

    if (roles?.find(rol => rol.data.value === RoleUser.cellarPA)) {
      return true;
    }
    return false;
  }

  isSuperAdmin(): boolean {
    const { roles } = this.data;

    if (roles?.find(rol => rol.data.value === RoleUser.superAdmin)) {
      return true;
    }
    return false;
  }

  isSuperAdminGlobal(): boolean {
    const { roles } = this.data;

    if (roles?.find(rol => rol.data.value === RoleUser.superAdminGlobal)) {
      return true;
    }
    return false;
  }

  static isOtherRoleThanSA(userData?: UserModel): boolean {
    if (userData) {
      const { roles } = userData;
      if (roles?.find(rol => rol.data.value !== RoleUser.superAdmin)) {
        return true;
      }
    }
    return false;
  }

  static isLocatario(userData?: UserModel): boolean {
    if (userData) {
      const { roles } = userData;

      if (roles?.find(rol => rol.data.value === RoleUser.locatario)) {
        return true;
      }
    }
    return false;
  }

  static isSuperAdmin(userData?: UserModel): boolean {
    if (userData) {
      const { roles } = userData;

      console.log('isSuperAdmin() =>', roles);
      if (roles?.find(rol => rol.data.value === RoleUser.superAdmin)) {
        return true;
      }
    }
    return false;
  }

  static isSuperAdminGlobal(userData?: UserModel): boolean {
    if (userData) {
      const { roles } = userData;

      if (roles?.find(rol => rol.data.value === RoleUser.superAdminGlobal)) {
        return true;
      }
    }
    return false;
  }

  /**
   * rutas habilitadas
   */
  enabledRoutes(): string[] {
    const isLocatario = this.isLocatario();
    const isSuperAdmin = this.isSuperAdmin();
    const isCellarPA = this.isCellarPA();
    const isSuperAdminGlobal = this.isSuperAdminGlobal();

    //console.log('showRoutesMenu() ===>', { isLocatario, isSuperAdmin });
    const routesEnabled: string[] = [];

    if (isLocatario) {
      routesEnabled.push(
        ...[
          PathRoute.welcome,
          PathRoute.uploadFile,
          PathRoute.unitManualLoad,
          PathRoute.listOrders,
          PathRoute.listFailedOrders,
          PathRoute.dashboard,
          //PathRoute.frequentQuestionsList,
          PathRoute.orderDetail
        ]
      );
    }

    if (isCellarPA) {
      routesEnabled.push(
        ...[
          PathRoute.welcome,
          PathRoute.uploadFile,
          PathRoute.unitManualLoad,
          PathRoute.listOrders,
          PathRoute.listFailedOrders,
          PathRoute.dashboard,
          PathRoute.orderDetail
        ]
      );
    }

    if (isSuperAdmin) {
      routesEnabled.push(
        ...[
          PathRoute.welcome,
          PathRoute.listOrders,
          PathRoute.listFailedOrders,
          PathRoute.dashboard,
          PathRoute.billing,
          PathRoute.createUser,
          PathRoute.createStore,
          PathRoute.cutOffScheduleSetting,
          //PathRoute.frequentQuestions,
          PathRoute.updateHandbook,
          PathRoute.orderAlertSettings,
          PathRoute.orderDetail
        ]
      );
    }

    if (isSuperAdminGlobal) {
      routesEnabled.push(
        ...[PathRoute.welcome, PathRoute.listOrders, PathRoute.listFailedOrders, PathRoute.dashboard, PathRoute.orderDetail]
      );
      if (mySession.country !== EnumCountries.all) {
        routesEnabled.push(
          ...[PathRoute.createUser, PathRoute.billing, PathRoute.createStore, PathRoute.cutOffScheduleSetting, PathRoute.orderAlertSettings]
        );
      }
    }

    return myArrayWithNoDuplicates(routesEnabled);
  }

  /**
   * que opciones tiene el menu
   */
  showOptionsMenu(store?: Store) {
    const routes = this.enabledRoutes();
    if (store) {
      if (!store.data.isActive) {
        const indexRoute = routes.findIndex(route => route === PathRoute.uploadFile);
        routes.splice(indexRoute, 1);
        const indexRouteTwo = routes.findIndex(route => route === PathRoute.unitManualLoad);
        routes.splice(indexRouteTwo, 1);
      }
    }
    //console.log('showOptionsMenu() ==> routes', routes);
    const options = sections.filter(section => {
      return routes.find(route => route === section.path && !section.notMenu);
    });

    options.forEach(option => {
      option.subRoutes = option.subRoutes?.filter(subRoute => routes.find(route => route === subRoute.path));
    });
    //console.log('showOptionsMenu() ===>', options);
    return options;
  }

  showOptionsWelcome() {
    const routes = this.enabledRoutes();

    const options = sections.filter(section => {
      return routes.find(route => route === section.path && !section.notWelcome);
    });

    return options;
  }

  enabledRoutesMenu(store?: Store) {
    const routes = this.enabledRoutes();
    //console.log('enabledRoutesNotMenu() ==> { routes, store }', { routes, store });
    if (store) {
      if (!store.data.isActive) {
        const indexRoute = routes.findIndex(route => route === PathRoute.uploadFile);
        routes.splice(indexRoute, 1);
        const indexRouteTwo = routes.findIndex(route => route === PathRoute.unitManualLoad);
        routes.splice(indexRouteTwo, 1);
      }
    }
    //console.log('enabledRoutesNotMenu2() ==> { routes, store }', { routes, store });
    const options = routes.filter(route => {
      return sections.find(section => {
        if (section.subRoutes) {
          return section.subRoutes.find(subRoute => subRoute.path === route);
        }

        return section.path === route;
      });
    });
    //console.log('enabledRoutesMenu() ===>', options);
    return options;
  }

  /** rutas que no estan en el menu **/
  enabledRoutesNotMenu() {
    const routes = this.enabledRoutes();
    //console.log('enabledRoutesNotMenu() ==> routes', routes);
    const options = sections.filter(section => {
      return routes.find(route => route === section.path && section.notMenu);
    });
    //console.log('enabledRoutesNotMenu() ===>', options);
    return options;
  }
}

User.prototype.toString = function saleChannelToString() {
  return `${this.data.id}-${this.data.firstName}-${this.data.lastName}`;
};

export default User;
