import { useContext, useEffect, useReducer, useState } from 'react';

import { NameIcons, SizeScreen } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import ButtonCustom from 'components/atoms/buttons';
import InputCustom from 'components/atoms/inputs';
import BrandSelect from '../selects/Brand';
import RoleUserSelect from '../selects/RoleUser';

import Store from 'models/store';
import UserRole from 'models/user/UserRole';
import IconCustom from 'components/atoms/icons';

export interface UserBoardParams {
  name?: string;
  brands?: Store[];
  roles?: UserRole[];
}

export enum UserBoardEnum {
  name = 'name',
  brands = 'brands',
  roles = 'roles',
  all = 'all'
}

type UserBoardFilterProps = {
  onFilter: (params?: UserBoardParams) => void;
  values?: UserBoardParams;
};

const UserBoardFilter = ({ onFilter, values }: UserBoardFilterProps) => {
  const { screenResolution } = useContext(RootContext);

  const [isOpen, setIsOpen] = useState(false);

  const [valuesCurrent, dispatch] = useReducer(
    (prevState: UserBoardParams, action: { type: UserBoardEnum; value: any }) => {
      switch (action.type) {
        case UserBoardEnum.brands:
          return {
            ...prevState,
            brands: action.value
          };
        case UserBoardEnum.name:
          return {
            ...prevState,
            name: action.value
          };
        case UserBoardEnum.roles:
          return {
            ...prevState,
            roles: action.value
          };
        case UserBoardEnum.all:
          return {
            ...values
          };
        default:
          return {
            ...prevState
          };
      }
    },
    { name: undefined, brands: undefined, roles: undefined }
  );

  useEffect(() => {
    dispatch({ type: UserBoardEnum.brands, value: values });
  }, [values]);

  return (
    <div>
      {(isOpen || screenResolution > SizeScreen.sm) && (
        <div className={'flex flex-col items-end gap-4 sm:flex-row'}>
          <div className='flex w-full flex-col items-end gap-4 sm:flex-row sm:w-auto'>
            <InputCustom
              classNameContainer='w-full sm:w-auto'
              label={'Nombre'}
              placeholder={'Ej: Alfredo Toledo'}
              onChange={val => dispatch({ type: UserBoardEnum.name, value: val.target.value })}
            />
            <BrandSelect onChange={val => dispatch({ type: UserBoardEnum.brands, value: val })} />
            <RoleUserSelect onChange={val => dispatch({ type: UserBoardEnum.roles, value: val })} />
          </div>

          <div className='px-4 w-full sm:w-auto'>
            <ButtonCustom className='w-full sm:w-auto' onClick={() => onFilter(valuesCurrent)}>
              Filtrar
            </ButtonCustom>
          </div>
        </div>
      )}

      {screenResolution <= SizeScreen.sm && (
        <div className='flex w-full justify-end items-end mt-2'>
          <button onClick={() => setIsOpen(!isOpen)}>
            <IconCustom name={isOpen ? NameIcons.arrowUp : NameIcons.arrowDown} className='text-grayarauco-400 text-2xl' />
          </button>
        </div>
      )}
    </div>
  );
};

export default UserBoardFilter;
