import { numberAtPrice } from 'helpers/Converts';

import { DataTableOrder } from 'models/order';

type CellOtherProps = {
  order?: DataTableOrder;
  filed?: 'price' | 'deliveryCost' | 'totalAmount' | 'items' | 'size' | 'typeUpload' | 'warehouse';
  ignoreError?: boolean;
};

const CellOther = ({ order, filed }: CellOtherProps) => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center py-2 pl-2'>
      <div className='w-full h-full flex flex-col justify-center border-r border-grayaraucoborder pr-2'>
        {order && (
          <div className='flex flex-col w-full'>
            {(filed === 'price' || filed === 'deliveryCost' || filed === 'totalAmount') && (
              <div className={`flex flex-row items-end rounded-full w-full text-right justify-end`}>
                <p className='text-xs text-black font-bold ml-2'>{numberAtPrice(parseFloat(`${order[filed]}` || '0'))}</p>
              </div>
            )}
            {(filed === 'items' || filed === 'size' || filed === 'typeUpload' || filed === 'warehouse') && (
              <div className={`flex flex-row items-center rounded-full w-full text-center justify-center`}>
                <p className='text-xs text-black font-bold ml-2'>{order[filed]}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellOther;
