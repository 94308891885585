import { forwardRef, useState, useEffect } from 'react';
import { Moment } from 'moment';
import { TimePicker, Tooltip } from 'antd';

import { FormatDate, FormatTime } from 'configs/Enums';
import { IS_MOBILE } from 'configs/Constants';

import LabelCustom from 'components/atoms/labels';
import InputCustom from '../inputs';

import { stringAtMoment, stringAtTime } from 'helpers/Converts';
import { getDateNow } from 'helpers/Generator';

type TimePickerCustomProps = {
  onChange: (value: string) => void;
  format?: FormatTime;
  placeholder?: string;
  className?: string;
  label?: string;
  classNameLabel?: string;
  value?: any;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  isRequired?: boolean;
  messageErrorTooltip?: string;
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
  onSelect?: (value: Moment) => void;
  hide?: boolean;
};

const TimePickerCustom = forwardRef(
  (
    {
      placeholder,
      format = FormatTime.hmmssa,
      onChange,
      className,
      label,
      classNameLabel,
      value,
      onFocus,
      onBlur,
      onClick,
      isRequired,
      messageErrorTooltip,
      disabledHours,
      disabledMinutes,
      disabledSeconds,
      onSelect,
      hide
    }: TimePickerCustomProps,
    ref: any
  ) => {
    const [openPicker, setOpenPicker] = useState<boolean>();
    const [messageError, setMessageError] = useState<string>();
    const [valueCurrent, setValueCurrent] = useState<string>();

    const handleChange = (value: Moment | null, dateString: string) => {
      console.log('handleChange() ===>', { value, dateString });
      setOpenPicker(false);
      if (isRequired && (dateString === '' || dateString === undefined)) {
        setMessageError('El campo es requerido.');
      } else if (dateString !== undefined) {
        onChange(dateString);
        setMessageError(undefined);
      }
    };

    const handleFocus = () => {
      setOpenPicker(true);
      if (onFocus) {
        onFocus();
      }
    };

    const handleBlur = (event: any) => {
      //console.log('handleBlur() ==>', { event, value: event.target.value });
      const valueFormat = stringAtMoment(event.target.value, format)?.format(format) || '';
      if (event && event.target && event.target.value !== undefined && event.target.value !== '') {
        const dateString = valueFormat;
        if (isRequired && (dateString === '' || dateString === undefined)) {
          onChange(dateString);
          setMessageError('El campo es requerido.');
        } else if (dateString !== undefined) {
          onChange(dateString);
          setMessageError(undefined);
        }
      }
      setOpenPicker(false);
      if (onBlur) {
        onBlur();
      }
    };

    const handleClick = () => {
      setOpenPicker(true);
      if (onClick) {
        onClick();
      }
    };

    useEffect(() => {
      setMessageError(messageErrorTooltip);
      setValueCurrent(value);
    }, [messageErrorTooltip, value]);

    return (
      <div hidden={hide}>
        {label && (
          <LabelCustom className={`mb-2 ${classNameLabel}`}>
            {label}
            {isRequired && <span className='text-red-400'> *</span>}
          </LabelCustom>
        )}
        <Tooltip
          visible={!!messageError}
          trigger={['hover', 'focus']}
          title={messageErrorTooltip || messageError}
          placement='top'
          overlayClassName='ant-tooltip-content-error'
          overlayInnerStyle={{ backgroundColor: 'red' }}
        >
          {IS_MOBILE && (
            <InputCustom
              type='time'
              value={
                valueCurrent ? stringAtTime(`${getDateNow({ format: FormatDate.yyyymmdd })} ${valueCurrent}`, FormatTime.HHmm) : undefined
              }
              //value='2:00 PM'
              //value={valueCurrent}
              onChange={event => {
                //console.log('event ==>', { format: `${getDateNow({})} ${event.target.value}`, raw: event.target.value });
                handleChange(null, stringAtTime(`${getDateNow({})} ${event.target.value}`, format) || '');
              }}
              {...{ ref }}
            />
          )}
          {!IS_MOBILE && (
            <TimePicker
              size='large'
              className={`text-gray-700 font-bold w-full ${className}`}
              placeholder={placeholder || 'Seleccione la hora'}
              onChange={handleChange}
              open={openPicker}
              onFocus={() => handleFocus()}
              onBlur={event => handleBlur(event)}
              onClick={() => handleClick()}
              onSelect={onSelect}
              //value={value}
              value={stringAtMoment(valueCurrent, format)}
              {...{ format, ref, disabledHours, disabledMinutes, disabledSeconds }}
            />
          )}
        </Tooltip>
      </div>
    );
  }
);

export default TimePickerCustom;
