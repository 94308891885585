import { useState, useContext, useEffect } from 'react';

import { NameIcons, SizeScreen } from 'configs/Enums';

import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';
import ButtonCustom from 'components/atoms/buttons';
import FormMolecule from 'components/molecules/forms';
import BoardCustom from 'components/molecules/board';
import ModalCustom from 'components/molecules/modals';

import { FormItemModel } from 'models/form';
import User from 'models/User';
import Store from 'models/store';
import UserRole from 'models/user/UserRole';
import UserBoardFilter, { UserBoardParams } from '../filters/UserBoardFilter';

type BoardUserProps = {
  users: User[];
  /** todos los usuarios sin filtro **/
  roles: UserRole[];
  shops: Store[];
  //title?: string | React.ReactNode;
  onUpdateUser: (form: any) => void;
  onCreateUser: (form: any) => void;
  onDeletedUser: (form: any) => void;
  onFilter: (params?: UserBoardParams) => void;
  flag?: number;
};

const BoardUser = ({ users, shops, roles, onUpdateUser, onCreateUser, onDeletedUser, onFilter, flag }: BoardUserProps) => {
  const { showModalQuestion, screenResolution } = useContext(RootContext);

  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [userCurrent, setUserCurrent] = useState<User>();
  const [itemsForm, setItemsForm] = useState<FormItemModel[]>([]);
  const [data, setData] = useState<User[]>([]);

  const showModalUpdate = (user: User) => {
    const userUpdate = new User({ ...user.data });
    setUserCurrent(userUpdate);
    //console.log('delivery', deliveryScheduleCurrent);
    setItemsForm(User.formFields({ values: userUpdate.data, shops, roles, isUpdate: true }));
    setIsModalUpdate(true);
  };

  const showModalCreate = () => {
    const newUser = new User({});
    setUserCurrent(newUser);
    //console.log('delivery', deliveryScheduleCurrent);
    setItemsForm(
      User.formFields({
        shops,
        roles,
        values: newUser.data,
        isUpdate: false
      })
    );
    setIsModalAdd(true);
  };

  const showModalDelete = (user: User) => {
    setUserCurrent(user);
    showModalQuestion({
      title: '¿Desea eliminar el usuario?',
      options: [{ text: 'Si', onAction: () => onDeletedUser(user) }, { text: 'No' }]
    });
  };

  useEffect(() => {
    console.log('reload');
    setData(users);
  }, [users, flag]);

  return (
    <>
      <div className='flex flex-col pt-6 pb-0 px-5 sm:px-10 bg-white sm:pb-6'>
        <div className='flex overflow-clip mb-8'>
          <h1 className='overflow-clip mr-3'>Usuarios</h1>
          <ButtonCustom onClick={() => showModalCreate()}>
            <IconCustom name={NameIcons.add} />
          </ButtonCustom>
        </div>

        <UserBoardFilter onFilter={val => onFilter(val)} />
      </div>

      {screenResolution > SizeScreen.md && (
        <BoardCustom
          data={data}
          columns={User.headerTable({
            showModalUpdate,
            onDeleted: showModalDelete
          })}
          persistTableHead
        />
      )}

      {screenResolution <= SizeScreen.md && (
        <div className='bg-grayarauco-200 mx-5 mt-5'>
          {data &&
            data.length > 0 &&
            data.map(dat => {
              return (
                <div key={dat.data.id} className='p-4 bg-white' style={{ marginBottom: 2 }}>
                  <div className='w-full border-grayarauco-300 pb-3 mb-3' style={{ borderBottomWidth: 0.5 }}>
                    <h1 className='text-sm font-bold'>{dat.data.fullName}</h1>
                    <p className='text-sm font-normal'>{dat.data.email}</p>
                  </div>
                  <p className='text-sm'>{dat.data.roles?.map(rol => rol.data.name).join(',')}</p>
                  <p className='text-sm font-bold text-primary'>{dat.data.commerce?.data.brand}</p>
                  <div className='flex flex-row w-full justify-end'>
                    <IconCustom onClick={() => showModalUpdate(dat)} name={NameIcons.rename} className='text-grayarauco-400 text-lg mr-4' />
                    <IconCustom onClick={() => showModalDelete(dat)} name={NameIcons.deleted} className='text-grayarauco-400 text-lg' />
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {/** modal para actulizar el usuario **/}
      {userCurrent && (
        <ModalCustom isVisible={isModalUpdate} onClose={() => setIsModalUpdate(false)}>
          <div>
            <FormMolecule
              classNameContainer='xl:w-full'
              classNameContent='md:grid-cols-3 sm:grid-cols-2'
              title='Actualizar usuario'
              items={itemsForm}
              textBtnSubmit='Actualizar'
              textBtnCancel='Cancelar'
              onChangeForm={form => {
                //console.log('onChangeForm() ==>', form);
                if (form.roles) {
                  form.roles = [form.roles];
                }
                userCurrent.updateFormUser(form);
                setUserCurrent(userCurrent);
                setItemsForm(
                  User.formFields({
                    shops,
                    roles,
                    values: userCurrent.data,
                    isUpdate: true
                  })
                );
              }}
              onCancel={() => {
                setIsModalUpdate(false);
                setUserCurrent(undefined);
                setItemsForm([]);
              }}
              onSubmit={() => {
                onUpdateUser(userCurrent);
                setIsModalUpdate(false);
                setUserCurrent(undefined);
                setItemsForm([]);
              }}
            />
          </div>
        </ModalCustom>
      )}

      {/** modal para crear usuario **/}
      {userCurrent && (
        <ModalCustom isVisible={isModalAdd} onClose={() => setIsModalUpdate(false)}>
          <FormMolecule
            classNameContainer='xl:w-full'
            classNameContent='md:grid-cols-3 sm:grid-cols-2'
            title='Agregar usuario'
            items={itemsForm}
            textBtnSubmit='Crear'
            textBtnCancel='Cancelar'
            onChangeForm={form => {
              //console.log('onChangeForm() ==>', form);
              if (form.roles) {
                form.roles = [form.roles];
              }
              userCurrent.updateFormUser(form);
              setUserCurrent(userCurrent);
              setItemsForm(
                User.formFields({
                  shops,
                  roles,
                  values: userCurrent.data,
                  isUpdate: false
                })
              );
            }}
            onSubmit={() => {
              onCreateUser(userCurrent);
              setIsModalAdd(false);
              setUserCurrent(undefined);
              setItemsForm([]);
            }}
            onCancel={() => {
              setIsModalAdd(false);
              setUserCurrent(undefined);
              setItemsForm([]);
            }}
          />
        </ModalCustom>
      )}
    </>
  );
};

export default BoardUser;
