import { ReactElement } from 'react';
import Modal from 'react-modal';

type ModalCustomProps = {
  isVisible: boolean;
  children?: ReactElement;
  onClose: () => void;
  styleContent?: React.CSSProperties | undefined;
  notFullScreen?: boolean;
  className?: string;
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.6)',
    zIndex: 20
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%'
  }
};

const ModalCustom = ({ isVisible, children, onClose, styleContent, notFullScreen, className }: ModalCustomProps) => {
  if (!notFullScreen) {
    return (
      <Modal
        isOpen={isVisible}
        onRequestClose={onClose}
        style={{ overlay: { ...customStyles.overlay }, content: { ...customStyles.content, ...styleContent } }}
      >
        {children}
      </Modal>
    );
  } else {
    return <div className={`absolute top-0 left-0 right-0 bottom-0 ${className}`}>{children}</div>;
  }
};

export default ModalCustom;
