import { useEffect, useState, useContext } from 'react';

import { FormatDate, SizeScreen } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import SelectCustom from 'components/atoms/selects';

import { getDateNow } from 'helpers/Generator';

type YearStartAtCurrentProps = {
  value?: number;
  onChange: (val?: number) => void;
  className?: string;
  valueDefault?: number;
};

const YearStartAtCurrentSelect = ({
  value,
  onChange,
  className,
  valueDefault = parseInt(`${getDateNow({ format: FormatDate.yyyy })}`)
}: YearStartAtCurrentProps) => {
  const { screenResolution } = useContext(RootContext);

  const [years, setYears] = useState<number[]>([]);
  const [valueCurrent, setValueCurrent] = useState<number | undefined>(valueDefault);
  const [firstLoad, setFirstLoad] = useState(false);

  const onChangeValue = (val?: number) => {
    setValueCurrent(val);
    onChange(val);
  };

  useEffect(() => {
    if (!firstLoad) {
      let startYear = 2021;
      const yearsNew = [startYear];

      do {
        startYear += 1;
        yearsNew.push(startYear);
      } while (startYear < parseInt(`${getDateNow({ format: FormatDate.yyyy })}`));

      setYears(yearsNew);
      setFirstLoad(true);
      console.log('value1 ====>', { value, yearsNew });
    }
    console.log('value2 ====>', { value, years });
    setValueCurrent(value || valueDefault);
  }, [value]);

  return (
    <SelectCustom
      classNameContainer={className}
      label={'Año'}
      allowClear={false}
      getPopupContainer={(triggerNode: any) => {
        console.log('getPopupContainer() => triggerNode', triggerNode);
        return triggerNode;
      }}
      style={{
        maxWidth: screenResolution > SizeScreen.md ? 204 : '100%',
        width: screenResolution > SizeScreen.md ? 204 : '100%'
      }}
      data={years.map(year => ({ value: year, label: year, item: year }))}
      value={valueCurrent}
      onChange={val => onChangeValue(val)}
    />
  );
};

export default YearStartAtCurrentSelect;
