import { Collapse } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import FrequentQuestion from 'models/questions/FrequentQuestion';

type FrequentQuestionCardProps = {
  frequentQuestion: FrequentQuestion;
  className?: string;
};

const FrequentQuestionCard = ({ frequentQuestion, className }: FrequentQuestionCardProps) => {
  return (
    <Collapse className={`container-fq site-collapse-custom-collapse ${className}`} bordered={false}>
      <Collapse.Panel header={<h2>{frequentQuestion.data.question}</h2>} key='1' className='site-collapse-custom-panel'>
        <ReactMarkdown className='template-markdown' remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {frequentQuestion.data.answer || ''}
        </ReactMarkdown>
      </Collapse.Panel>
    </Collapse>
  );
};

export default FrequentQuestionCard;
