import { Moment } from 'moment';

import { UnitDate, FormatDate, FormatTime } from 'configs/Enums';
import moment from 'configs/MomentJS';

/**
 * genera números enteros random entre 2 rangos
 */
export const getRandomNumberBT = ({ max = 5000, min = 0 }: { max?: number; min?: number }) => {
  try {
    return Math.floor(Math.random() * (max - min) + min);
  } catch (err) {
    console.log('getRandomNumberBT()', err);
    return 0;
  }
};

export const getSubtractTime = ({
  unit = UnitDate.days,
  amount = 1,
  format = FormatDate.yyyymmdd
}: {
  unit?: UnitDate;
  amount?: number;
  format?: FormatDate;
}): string | Moment | undefined => {
  try {
    if (format === FormatDate.none) {
      return moment().subtract(amount, unit);
    }
    return moment().subtract(amount, unit).format(format);
  } catch (err) {
    return moment().subtract(1, UnitDate.days).format(FormatDate.yyyymmdd);
  }
};

export const addTime = ({
  unit = UnitDate.days,
  amount = 1,
  format = FormatDate.yyyymmdd,
  baseDate
}: {
  unit?: UnitDate;
  amount?: number;
  format?: FormatDate;
  baseDate?: string | Moment;
}): string | Moment | undefined => {
  try {
    if (format === FormatDate.none) {
      if (typeof baseDate === 'string') {
        return moment(baseDate).add(amount, unit);
      }
      return baseDate?.add(amount, unit);
    }
    return moment(baseDate).add(amount, unit).format(format);
  } catch (err) {
    return moment(baseDate).add(1, UnitDate.days).format(FormatDate.yyyymmdd);
  }
};

export const subtractTime = ({
  unit = UnitDate.days,
  amount = 1,
  format = FormatDate.yyyymmdd,
  baseDate
}: {
  unit?: UnitDate;
  amount?: number;
  format?: FormatDate;
  baseDate?: string | Moment;
}): string | Moment | undefined => {
  try {
    if (format === FormatDate.none) {
      if (typeof baseDate === 'string') {
        return moment(baseDate).subtract(amount, unit);
      }
      return baseDate?.subtract(amount, unit);
    }
    return moment(baseDate).subtract(amount, unit).format(format);
  } catch (err) {
    return moment(baseDate).subtract(1, UnitDate.days).format(FormatDate.yyyymmdd);
  }
};

export const getDateNow = ({ format = FormatDate.yyyymmdd }: { format?: FormatDate | FormatTime }): string | Moment => {
  if (format === FormatDate.none) {
    return moment();
  }
  return moment().format(format);
};

/** generar un array con numeros de un rango **/
export const range = ({ start, end }: { start: number; end: number }) => {
  try {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  } catch (err) {
    console.log('range()', err);
    return [];
  }
};

export const getLastDayMonth = ({ date, format = FormatDate.yyyymmdd }: { date: string; format?: string }) => {
  return moment(`${moment(date).format(FormatDate.yyyymm) + moment().daysInMonth()}`, FormatDate.yyyymmdd).format(format);
};

export const rangerStatistics = () => {
  return {
    endDate: getDateNow({ format: FormatDate.yyyymmdd }) as string,
    startDate: subtractTime({
      unit: UnitDate.months,
      amount: 6,
      format: FormatDate.yyyymmdd
    }) as string
  };
};
