import { ResponseApp } from 'configs/Interface';

import Store from 'models/store';
import WareHouse from 'models/store/Warehouse';
import SaleChannel from 'models/store/SaleChannel';
import StoreJanis, { StoreJanisServerModel } from 'models/store/StoreJanis';

import StoreProvider from 'services/api/Store';
import TypeOperator from 'models/store/TypeOperator';
import { EnumCountries } from 'configs/Enums';
import Country from 'models/Country';
import WarehousePA from 'models/warehouse';

export const createStore = async (store: Store): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.createStore(store);
    //console.log('createStore() ==>', { status, data, other });
    if (status === 201) {
      return { status: true, message: data.messagge, data: new Store(Store.formalizeData(data.data)) };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible crear la tienda por el momento.' };
  } catch (err) {
    return { status: false, message: `No fue posible crear la tienda por el momento: ${(err as Error).message}.` };
  }
};

export const updateStore = async (store: Store): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.updateStore(store);
    //console.log('updateStore() =>', { status, data, other });

    if (status === 201 || status === 200) {
      return { status: true, message: data.message || 'La tienda se actulizo con éxito.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible actualizar la tienda.' };
  } catch (err) {
    return { status: false, message: `No fue posible actulizar la tienda por el momento: ${(err as Error).message}` };
  }
};

export const deleteStore = async (store: Store): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.deleteStore(store);
    //console.log('deleteStore() =>', { status, data, other });

    if (status === 200) {
      return { status: true, message: data.msg || 'Se elimino la tienda con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible eliminar la tienda por el momento.' };
  } catch (err) {
    return { status: false, message: `No fue posible eliminar la tienda por el momento: ${(err as Error).message}` };
  }
};

export const getStoreJanisById = async (storeJanis: StoreJanis): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.getStoreJanisById(storeJanis);

    if (status === 200) {
      if (data.data) {
        return { status: true, data: new StoreJanis(data.data) };
      }
      return { status: false, message: 'No hay información de la tienda en Janis.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible cargar la información.' };
    }
    return { status: false, message: 'No fue posible cargar la información.' };
  } catch (err) {
    return { status: false, message: `No fue posible cargar la información de la tienda de janis: ${(err as Error).message}` };
  }
};

export const getInfoStoresJanisAvailables = async (country?: string): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.getInfoStoresAvailables(country);
    console.log('getInfoStoresJanisAvailables() =>', { status, data, other });

    if (status) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((store: StoreJanisServerModel) => {
            const storeJanis = new StoreJanis(StoreJanis.formalizeData(store));
            storeJanis.updateAvailable(true);
            return storeJanis;
          })
        };
      }
      return { status: false };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible cargar los tiendas que están en Janis.' };
  } catch (err) {
    console.log('getInfoStoresJanisAvailables() =>', err);
    return { status: false, message: (err as Error).message };
  }
};

export const getInfoStoresJanis = async (country?: string): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.getInfoStores(country);

    if (status) {
      if (data.data && data.data.length > 0) {
        return { status: true, data: data.data.map((store: StoreJanisServerModel) => new StoreJanis(StoreJanis.formalizeData(store))) };
      }
      return { status: false, message: 'No hay tiendas en Janis disponibles.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible cargar los tiendas que están en Janis.' };
  } catch (err) {
    console.log('getInfoStores() ==>', err);
    return { status: false, message: (err as Error).message };
  }
};

export const getInfoWarehouse = async ({ idWarehouse, country }: { idWarehouse: string; country?: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.getInfoWarehouse({ idWarehouse, country });
    console.log(`getInfoWarehouse() => { status, data, other }`, { status, data, other, idWarehouse, country });
    if (status) {
      if (data.data) {
        return { status: true, data: new WareHouse(WareHouse.formalizeData(data.data)) };
      }
      return {
        status: false,
        message: 'No hay información del warehouse.'
      };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible cargar la información del warehouse.' };
  } catch (err) {
    console.log('getInfoWarehouse() ==>', (err as Error).message);
    return { status: false, message: 'No fue posible cargar la información del warehouse.' };
  }
};

export const getInfoSaleChannel = async (params: { idSaleChannel: string; country?: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await StoreProvider.getInfoSaleChannel(params);

    if (status) {
      if (data.data) {
        return { status: true, data: new SaleChannel(SaleChannel.formalizeData(data.data)) };
      }
      return {
        status: false,
        message: 'No hay información del sale-channel'
      };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible cargar la sale-channel por el momento.' };
  } catch (err) {
    return { status: false, message: `No fue posible cargar el sale-channel por el momento: ${(err as Error).message}.` };
  }
};

export const getShop = async (country?: string) => {
  try {
    const { status, data, ...other } = await StoreProvider.getShop(country);
    console.log('getShop() ==>', { status, data, other, country });
    if (status === 200) {
      console.log('getShop', data.length, country);
      if (data && data.length > 0) {
        if (country) {
          return {
            status: true,
            data: Store.onSort(
              data
                .map((shop: any) => new Store(Store.formalizeData(shop)))
                .filter((store: Store) => {
                  if (country === EnumCountries.all) {
                    return true;
                  }
                  if (store.data.country) {
                    console.log('country() ==>', { country, store });
                    return (store.data.country as any).data.value?.toLowerCase() === country?.toLowerCase();
                  }
                  return false;
                })
            )
          };
        }
        return {
          status: true,
          data: data
            .map((shop: any) => new Store(Store.formalizeData(shop)))
            ?.sort((a: Store, b: Store) => {
              if ((a?.data.brand?.toLowerCase() || '') > (b?.data.brand?.toLowerCase() || '')) {
                return 1;
              } else if ((a?.data.brand?.toLowerCase() || '') < (b?.data.brand?.toLowerCase() || '')) {
                return -1;
              }
              return 0;
            })
        };
      }
      return { status: false, message: 'No hay tiendas disponibles.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener las tiendas.' };
  } catch (err) {
    console.log('getShop() ==> err', err);
    return { status: false, message: 'No fue posible obtener las tiendas.' };
  }
};

export const getTypesOperator = async () => {
  try {
    const { status, data, ...other } = await StoreProvider.getTypesOperator();

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((typeOperator: any) => new TypeOperator(TypeOperator.formalizeData(typeOperator)))
        };
      }
      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los tipos de operador.' };
  } catch (err) {
    console.log('getTypesOperator() ==> err', err);
    return { status: false, message: 'No fue posible obtener los tipos de operador.' };
  }
};

export const getStoreById = async (id?: string) => {
  try {
    const { status, data, ...other } = await StoreProvider.getStoreById(id);

    if (status === 200) {
      return { status: true, data: new Store(Store.formalizeData(data.shop)) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener la tienda.' };
  } catch (err) {
    return { status: false, message: 'No fue posible obtener la tienda.' };
  }
};

export const getWarehouse = async ({ country, countries }: { country: string; countries: Country[] }) => {
  try {
    const { status, data, ...other } = await StoreProvider.getWarehouse({ country, countries });
    console.log('getWarehouse() ==> { status, data, other }', { status, data, other });

    if (status === 200 || status === 201) {
      return { status: true, data: data.map((warehouse: any) => new WarehousePA(WarehousePA.formalizeData(warehouse))) };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible cargar las bodegas.' };
  } catch (err) {
    console.log('getWarehouse() ==> err', err);
    return { status: false, message: `No fue posible cargar las bodegas${(err as Error).message}` };
  }
};
