import { Line } from '@ant-design/plots';
import { Types } from '@antv/g2';
import { Skeleton } from 'antd';

type LineCustomProps = {
  data?: { name?: string; value?: number; type?: string }[];
  titleX?: string;
  titleY?: string;
  title?: string;
  legend?: false | Types.LegendCfg;
  slider?: any;
};

const config = {
  xField: 'name',
  yField: 'value',
  seriesField: 'type'
};

const convertTypeAtColor = (type?: string) => {
  if (type === 'featured') {
    return '#6E9B3C';
  }
  return '#154BAE';
};

const LineCustom = ({ data, titleX, titleY, legend, title, slider }: LineCustomProps) => {
  return (
    <div>
      <h1 className='text-lg font-normal text-black mb-4'>{title}</h1>

      {!data && <Skeleton.Avatar className='ant-skeleton-statistic w-full h-96' active={true} size={'large'} shape='square' />}
      {data && (
        <Line
          {...{ data, legend, slider }}
          color={(datum: any) => {
            return convertTypeAtColor(datum.type);
          }}
          label={{
            autoRotate: false
          }}
          yAxis={{
            title: {
              text: titleY
            }
          }}
          xAxis={{
            nice: true,
            title: {
              text: titleX
            },
            label: {
              autoRotate: true,
              style: {
                fill: '#aaa',
                fontSize: 10
              },
              formatter: name => name
            }
          }}
          {...config}
        />
      )}
    </div>
  );
};

export default LineCustom;
