import { summaryTypeDelivery } from 'helpers/Converts';

export interface EvolutionOrderByDeliveryItem {
  type?: string;
  typeAxu?: string;
  date?: string;
  value?: number;
}

export interface EvolutionOrderByDeliveryModel {
  items?: EvolutionOrderByDeliveryItem[];
}

export type EvolutionOrderByDeliveryServerModel = { date: string; orders: { [index: string]: number } }[];

class EvolutionOrderByDelivery {
  data: EvolutionOrderByDeliveryModel;

  constructor(data?: EvolutionOrderByDeliveryModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = { items: [] };
    }
  }

  static formalizeData(data: EvolutionOrderByDeliveryServerModel, isGroup?: boolean) {
    let items: EvolutionOrderByDeliveryItem[] = [];
    if (data && data.length > 0) {
      data.forEach(da => {
        const delivery = da.orders;

        for (const key in delivery) {
          const nameDelivery = isGroup ? summaryTypeDelivery(key) : key;
          items.push({ date: da.date, type: nameDelivery, value: delivery[key] });
        }
      });
    }

    if (isGroup) {
      const reducerItems = items.reduce((prev: any, curr: any) => {
        if ((prev as any).length > 0) {
          const index = (prev as EvolutionOrderByDeliveryItem[]).findIndex(val => val.date === curr.date && val.type === curr.type);
          if (index !== -1) {
            prev[index].value += curr.value;
            return prev;
          }
          return [...prev, curr];
        }
        if (prev.date === curr.date && prev.type === curr.type) {
          const item = prev;
          item.value += curr.value;
          return [item];
        }
        return [prev, curr];
      }) as any;
      console.log('reduce() => reducerItems', reducerItems);
      items = reducerItems;
    }
    return { items };
  }
}

export default EvolutionOrderByDelivery;
