import { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { ButtonHTMLType } from 'antd/lib/button/button';

import { StyleButton } from 'configs/Enums';

type ButtonCustomProps = {
  children?: ReactNode;
  autoFocus?: boolean | undefined;
  disabled?: boolean | undefined;
  type?: ButtonType;
  onClick?: MouseEventHandler<any> | undefined;
  styleButton?: StyleButton;
  className?: string | undefined;
  htmlType?: ButtonHTMLType;
  /** desactiva los estilos por defecto **/
  notStyleDefault?: boolean;
  target?: string;
  rel?: string;
  size?: 'small' | 'middle' | 'large' | 'none';
  style?: React.CSSProperties;
};

const ButtonCustom = ({
  children,
  styleButton,
  onClick,
  type = 'primary',
  className,
  autoFocus,
  disabled,
  htmlType,
  notStyleDefault,
  target,
  rel,
  size = 'large',
  style
}: ButtonCustomProps) => {
  const [typeStyle, setTypeStyle] = useState(
    'font-bold text-xl px-4 rounded text-white inline-block shadow bg-primary hover:bg-primary-600 focus:bg-primary-200 leading-normal rounded-full'
  );

  useEffect(() => {
    if (styleButton === StyleButton.bgPrimary) {
      setTypeStyle(
        'font-bold text-xl px-4 rounded text-white inline-block shadow bg-primary hover:bg-primary-600 focus:bg-primary-200 leading-normal rounded-full'
      );
    } else if (styleButton === StyleButton.outlinePrimary) {
      setTypeStyle(
        'font-bold text-xl px-4 rounded text-primary inline-block shadow border-solid border-2 border-primary rounded-full hover:bg-transparent hover:border-primary-600 focus:border-primary-600 hover:text-primary-600 leading-normal bg-transparent'
      );
    }
  }, [styleButton]);

  return (
    <Button
      className={`${notStyleDefault ? '' : typeStyle} ${className}`}
      shape='round'
      size={size === 'none' ? undefined : size}
      {...{ onClick, target, rel, type, autoFocus, disabled, htmlType, style }}
    >
      {children}
    </Button>
  );
};

export default ButtonCustom;
