import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TypeToast } from 'configs/Enums';

import { RootContext } from 'context/RootContext';

import { getOrderById } from 'services/app/Order';

import Order from 'models/order';
import DetailOrderOrganism from 'components/organisms/dashboard/order/Detail';

const DetailOrderPage = () => {
  const { showToast, showLoading, hideLoading } = useContext(RootContext);

  const [orderId, setOrderId] = useState<string>();
  const [order, setOrder] = useState<Order>();

  const params = useParams();

  const loadOrder = async (id?: string, count?: number) => {
    showLoading();
    const orderIdCurrent = id || orderId;
    const { status, data, message } = await getOrderById(orderIdCurrent || '');
    console.log('loadOrder() ==> { status, data, message }', { status, data, message });

    if (status) {
      setOrder(data);
      //loadHistoryStateByIdOrder(data);
    } else {
      const newCount = (count || 0) + 1;
      console.log('count => ', { count, newCount });
      if (newCount < 2) {
        loadOrder(id, newCount);
      } else {
        showToast({ text: message || 'No fue posible cargar la orden.', type: TypeToast.error });
      }
    }

    hideLoading();
  };

  useEffect(() => {
    console.log('orderDecode1 ==>', { params });
    if (params && params.orderId) {
      const orderDecode = decodeURIComponent(params.orderId);
      console.log('orderDecode ==>', orderDecode);
      setOrderId(orderDecode);
      loadOrder(orderDecode);
    }
  }, []);

  return <DetailOrderOrganism {...{ order }} />;
};

export default DetailOrderPage;
