import { AxiosResponse } from 'axios';

import { ResponseApp } from 'configs/Interface';

import { handleErrorServiceApi } from 'helpers/Errors';

import api from './index';

class CountryProvider {
  async getInfoToCountry(country?: string): Promise<AxiosResponse | ResponseApp> {
    try {
      return api().get(`warehouses/?country=${country}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new CountryProvider();
