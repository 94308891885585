import { getBase64 } from 'helpers/Converts';

export interface CategoryQuestionModel {
  id?: string;
  name?: string;
  description?: string;
  icon?: string | File;
  iconPath?: string;
}

export interface CategoryQuestionServerModel {
  id?: string;
  name?: string;
  description?: string;
  icon?: string;
}

class CategoryQuestion {
  data: CategoryQuestionModel;

  constructor(data: CategoryQuestionModel) {
    this.data = data;
  }

  static formalizeData(data: CategoryQuestionServerModel): CategoryQuestionModel {
    const { id, name, description, icon } = data;

    return {
      id: `${id}`,
      name,
      description,
      icon,
      iconPath: icon
    };
  }

  update({ name, description, icon }: { name?: string; description?: string; icon?: string | File }) {
    this.data.name = name;
    this.data.description = description;
    this.data.icon = icon;
  }

  async sendServer() {
    const { id, name, description, icon } = this.data;

    return {
      id,
      name,
      description,
      icon: await getBase64(icon)
    };
  }
}

CategoryQuestion.prototype.toString = function saleChannelToString() {
  return `${this.data.id}`;
};

export default CategoryQuestion;
