import numeral from 'numeral';
import { EnumCountries } from './Enums';

numeral.register('locale', 'cl', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  currency: {
    symbol: '$'
  },
  abbreviations: {
    thousand: 'mil',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  ordinal: (num: number) => {
    return num === 1 ? 'º' : 'er';
  }
});

numeral.register('locale', 'col', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  currency: {
    symbol: '$'
  },
  abbreviations: {
    thousand: 'mil',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  ordinal: (num: number) => {
    return num === 1 ? 'º' : 'er';
  }
});

numeral.register('locale', 'per', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  },
  abbreviations: {
    thousand: 'mil',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  ordinal: (num: number) => {
    return num === 1 ? 'º' : 'er';
  }
});

numeral.locale('cl');

export const configNumeralLocal = (country?: string, numeralLocal?: any) => {
  if (country === EnumCountries.cl) {
    numeral.locale('cl');
    numeralLocal?.locale('cl');
  } else if (country === EnumCountries.per) {
    numeral.locale('per');
    numeralLocal?.locale('per');
  } else {
    numeral.locale('col');
    numeralLocal?.locale('col');
  }
};

export default numeral;
