import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PathRoute } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';

import NewPasswordFormMolecule from 'components/molecules/forms/NewPassword';

const NewPasswordPage = () => {
  const { changePasswordUser, restorePasswordUser } = useContext(AuthContext);
  //const { changePasswordUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [token, setToken] = useState<string>();
  const [isForce, setIsForce] = useState(false);
  const [months, setMonths] = useState<number | undefined>(0);
  const [expiredDate, setExpiredDate] = useState<string | undefined>('');

  const handleNewPassword = async (password: string) => {
    //console.log('password', password);
    if (!isForce) {
      if (token) {
        const { status, message } = await changePasswordUser({ password, token });

        if (status) {
          //navigate(PathRoute.signin);
          return { status, message };
        }
        return { status, message };
      }
    } else {
      if (token) {
        console.log({ password, token });
        return await restorePasswordUser({ password, token });
        //console.log('restorePasswordUser() => { password, token }', { password, token });
      }
    }
    return { status: false };
  };

  useEffect(() => {
    const tokenAuth = params.get('tokenAuth');
    const force = params.get('force') === 'true' ? true : false;
    const date = params.get('date');
    const months = params.get('months');
    //console.log('userEffect(2)', { tokenAuth, force });

    setIsForce(force);
    if (months && date) {
      setMonths(parseInt(months));
      setExpiredDate(date);
    } else {
      setMonths(0);
      setExpiredDate('');
    }
    if (tokenAuth) {
      setToken(tokenAuth);
    } else {
      navigate(PathRoute.signin);
    }
  }, []);

  return <NewPasswordFormMolecule isUpdatePassword={isForce} {...{ handleNewPassword, months, expiredDate }} />;
};

export default NewPasswordPage;
