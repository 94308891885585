import { ReactNode, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';

import { EnumTypeNotification, NameIcons, StyleButton } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';
import ButtonCustom from 'components/atoms/buttons';

export interface NotificationItemPopUpProps {
  text?: string;
  style?: StyleButton;
  classNameButton?: string;
  onAction?: () => void;
}

export interface NotificationPopUpProps {
  title?: string;
  message?: string;
  messageNotMarkdown?: string | ReactNode;
  description?: string;
  options?: NotificationItemPopUpProps[];
  isClose?: boolean;
  onClose?: () => void;
  index?: number;
  type?: EnumTypeNotification;
  children?: ReactNode;
  classNameMessageP?: string;
  classNameContainer?: string;
  styleContainer?: CSSProperties | undefined;
  classNameDescription?: string;
}

export const NotificationPopUpContent = ({
  title,
  message,
  messageNotMarkdown,
  description,
  options,
  isClose,
  onClose,
  children,
  classNameMessageP,
  classNameContainer,
  classNameDescription,
  styleContainer,
  type = EnumTypeNotification.success
}: NotificationPopUpProps) => {
  return (
    <div className={`flex flex-col bg-white rounded-xl mx-4 ${classNameContainer}`} style={{ ...{ minWidth: '50%' }, ...styleContainer }}>
      <div className={'flex justify-between items-center px-6 py-4 border-b border-gray-300 mb-6'}>
        <h1 className={'text-xl sm:text-xl'}>{title}</h1>
        {isClose && (
          <button
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            <IconCustom name={NameIcons.close} />
          </button>
        )}
      </div>

      {!children && (
        <div className='mb-6 flex justify-center items-center flex-col px-6'>
          <div className={`icon-notification-${type}`}>
            <IconCustom name={NameIcons.error} className={'text-6xl sm:text-8xl'} />
          </div>
          {!messageNotMarkdown && message && (
            <p className={`mt-3 text-xl ${classNameMessageP}`}>
              <ReactMarkdown className={`markdown-notification-${type} text-center text-base sm:text-xl sm:text-left`}>
                {message}
              </ReactMarkdown>
            </p>
          )}
          {messageNotMarkdown}
          {description && (
            <p className={`mt-8 text-xl ${classNameDescription}`}>
              <ReactMarkdown className={`markdown-notification-${type} text-center text-base sm:text-xl sm:text-left`}>
                {description}
              </ReactMarkdown>
            </p>
          )}
        </div>
      )}
      {children}

      {options && options.length > 0 && (
        <div
          className={`mt-3 flex items-center px-6 mb-6 flex-col sm:flex-row ${options.length === 1 ? 'justify-center' : 'justify-between'}`}
        >
          {options.map(option => {
            return (
              <ButtonCustom
                styleButton={option.style}
                key={option.text}
                className={option.classNameButton}
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                  if (option.onAction) {
                    option.onAction();
                  }
                }}
              >
                {option.text}
              </ButtonCustom>
            );
          })}
        </div>
      )}
    </div>
  );
};

const NotificationPopUp = ({ notifications }: { notifications?: ReactNode[] }) => {
  if (notifications && notifications.length > 0) {
    return ReactDOM.createPortal(
      <div className='z-40 fixed inset-0 flex h-screen justify-center items-center'>
        {notifications.map((notification: ReactNode, index: any) => (
          <div
            key={`${index}-container-notification`}
            className='h-full w-full bg-backgroundmodal flex justify-center items-center absolute'
          >
            {notification}
          </div>
        ))}
      </div>,
      document.getElementById('modal-notification') as Element
    );
  }
  return null;
};

export default NotificationPopUp;
