import { convertNameFailedAtName } from 'helpers/Converts';

export interface OrderFailModel {
  general: { name?: string; value: number }[];
  brand: { name?: string; value: number }[];
  error: { name?: string; value: number }[];
  storeMostFailed?: string;
  formattingMostErrors?: string;
  totalOrderFail?: number;
}

export interface OrderFailServerModel {
  byOrders?: {
    fails?: { total?: number };
    orders?: { total?: number };
  };
  byShop?: { name?: string; value?: number; percentage: number }[];
  byField?: { name?: string; value?: number; percentage: number }[];
}

class OrderFail {
  data: OrderFailModel;

  constructor(data?: OrderFailModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = { general: [], brand: [], error: [], totalOrderFail: 0 };
    }
  }

  static formalizeData(data: OrderFailServerModel): OrderFailModel | undefined {
    try {
      const { byOrders, byShop, byField } = data;

      //console.log('formalizeData() 2 ==> data', data);
      const shopOrder =
        byShop && byShop.length > 0
          ? byShop
              .map(byS => ({ name: byS.name, value: byS.value }))
              .sort((a, b) => {
                const valA = a.value || 0;
                const valB = b.value || 0;
                if (valA > valB) {
                  return -1;
                } else if (valA < valB) {
                  return 1;
                }
                return 0;
              })
              .slice(0, 3)
              .map(fo => fo.name)
              .join(', ')
          : '';
      const filedOrder =
        byField && byField.length > 0
          ? byField
              .map(byF => ({ name: convertNameFailedAtName(byF.name), value: byF.value }))
              .sort((a, b) => {
                const valA = a.value || 0;
                const valB = b.value || 0;
                if (valA > valB) {
                  return -1;
                } else if (valA < valB) {
                  return 1;
                }
                return 0;
              })
              .slice(0, 3)
              .map(fo => fo.name)
              .join(', ')
          : '';

      const send: OrderFailModel = {
        general: [
          { name: 'Órdenes correctas', value: byOrders?.orders?.total || 0 },
          { name: 'Órdenes fallidas', value: byOrders?.fails?.total || 0 }
        ],
        brand: byShop && byShop.length > 0 ? byShop.map(byS => ({ name: byS.name, value: byS.value || 0 })) : [],
        error:
          byField && byField.length > 0 ? byField.map(byF => ({ name: convertNameFailedAtName(byF.name), value: byF.value || 0 })) : [],
        totalOrderFail: byOrders?.fails?.total || 0,
        storeMostFailed: shopOrder,
        formattingMostErrors: filedOrder
      };

      //console.log('formalizeData() ==> send', send);
      return send;
    } catch (err) {
      console.log('formalizeData() ==> err', err);
      return undefined;
    }
  }
}

export default OrderFail;
