import { useContext, useReducer, useEffect, useState } from 'react';

import { EnumAlert, SizeScreen, StyleButton } from 'configs/Enums';
import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import InputCustom from 'components/atoms/inputs';
import ButtonCustom from 'components/atoms/buttons';

import { shippingTypeStringToDisplay } from 'helpers/Converts';

import OrderAlert from 'models/alerts/OrderAlert';
import SelectCustom from 'components/atoms/selects';
import Delivery from 'models/Delivery';

type LimitScheduleFormProps = {
  data?: OrderAlert;
  onUpdate?: (data: OrderAlert) => void;
  onClose: () => void;
  onCreate?: (data: OrderAlert) => void;
  orderAlerts?: OrderAlert[];
  flag?: number;
};

interface ValueAlertReducer {
  high: string;
  half: string;
  low: string;
  typeDelivery: Delivery | string;
}

enum ValueAlertEnum {
  high = 'high',
  half = 'half',
  low = 'low',
  typeDelivery = 'typeDelivery',
  all = 'all'
}

const LimitScheduleForm = ({ data, flag, onUpdate, onClose, onCreate, orderAlerts }: LimitScheduleFormProps) => {
  const { countryUser, typesDelivery } = useContext(AuthContext);
  const { screenResolution } = useContext(RootContext);

  const [typeDeli, setTypeDeli] = useState<Delivery[]>([]);
  const [valueAlert, dispatchAlert] = useReducer(
    (prevState: ValueAlertReducer, action: { type: ValueAlertEnum; value: any }) => {
      switch (action.type) {
        case ValueAlertEnum.half:
          return {
            ...prevState,
            half: action.value
          };
        case ValueAlertEnum.high:
          return {
            ...prevState,
            high: action.value
          };
        case ValueAlertEnum.low:
          return {
            ...prevState,
            low: action.value
          };
        case ValueAlertEnum.typeDelivery:
          return {
            ...prevState,
            typeDelivery: action.value
          };
        case ValueAlertEnum.all:
          return {
            ...prevState,
            ...action.value
          };
        default:
          return {
            ...prevState
          };
      }
    },
    {
      typeDelivery: data?.data.typeDelivery,
      high: data?.data.params?.find(parm => parm.color === EnumAlert.high)?.time,
      half: data?.data.params?.find(parm => parm.color === EnumAlert.half)?.time,
      low: data?.data.params?.find(parm => parm.color === EnumAlert.low)?.time
    }
  );

  useEffect(() => {
    const typesDeliveryNew = typesDelivery.filter(
      typeDelivery => !orderAlerts?.find(orderAlert => orderAlert.data.typeDelivery === typeDelivery.data.value)
    );
    setTypeDeli(typesDeliveryNew);
  }, [orderAlerts, flag]);

  return (
    <div>
      <div className='mb-4 p-5 border-grayarauco-200' style={{ borderBottomWidth: 1 }}>
        <h1 className='text-xl sm:text-2xl font-bold'>
          Límites de horario {shippingTypeStringToDisplay((data?.data.typeDelivery as string) || '')}
        </h1>
      </div>

      <div className='mx-5'>
        {onCreate && (
          <div className='mb-4 w-full flex justify-center items-center'>
            <SelectCustom
              value={valueAlert.typeDelivery}
              onChange={val => dispatchAlert({ type: ValueAlertEnum.typeDelivery, value: val })}
              data={typeDeli?.map(tD => ({ value: tD.toString(), label: tD.data.name, item: tD }))}
              label='Tipo de envío'
              style={{ width: screenResolution <= SizeScreen.md ? '100%' : 224 }}
            />
          </div>
        )}

        <div className={'flex flex-row'}>
          <div className='flex justify-center items-center mr-4'>
            <div className={'w-16 bg-redarauco-200 rounded-full text-white text-center text-xs py-1 px-4'}>Alto</div>
          </div>

          <div className={'flex justify-center items-center gap-4'}>
            <InputCustom label='Desde' value='00:00' disabled />
            <InputCustom
              type='duration'
              label='Hasta'
              value={valueAlert.high}
              onChange={val => {
                dispatchAlert({ type: ValueAlertEnum.high, value: val.target.value });
              }}
            />
          </div>
        </div>

        <div className={'flex flex-row mt-4'}>
          <div className='flex justify-center items-center mr-4'>
            <div className={'w-16 bg-araucoyellow-200 rounded-full text-white text-center text-xs py-1 px-4'}>Medio</div>
          </div>

          <div className={'flex justify-center items-center gap-4'}>
            <InputCustom label='Desde' value={OrderAlert.addOneMinute(valueAlert.high)} disabled />
            <InputCustom
              type='duration'
              label='Hasta'
              value={valueAlert.half}
              onChange={val => {
                dispatchAlert({ type: ValueAlertEnum.half, value: val.target.value });
              }}
            />
          </div>
        </div>

        <div className={'flex flex-row mt-4'}>
          <div className='flex justify-center items-center mr-4'>
            <div className={'w-16 bg-araucogreen rounded-full text-white text-center text-xs py-1 px-4'}>Bajo</div>
          </div>

          <div className={'flex justify-center items-center gap-4'}>
            <InputCustom label='Desde' value={OrderAlert.addOneMinute(valueAlert.half)} disabled />
            <InputCustom
              type='duration'
              label='Hasta'
              value={valueAlert.low}
              onChange={val => {
                dispatchAlert({ type: ValueAlertEnum.low, value: val.target.value });
              }}
            />
          </div>
        </div>
      </div>

      <div className={'flex justify-between items-center flex-col sm:flex-row mt-14 mx-5 mb-5'}>
        <ButtonCustom
          styleButton={StyleButton.outlinePrimary}
          style={{ width: screenResolution <= SizeScreen.sm ? '100%' : 244 }}
          onClick={() => {
            dispatchAlert({ type: ValueAlertEnum.all, value: { high: '', half: '', low: '', typesDelivery: undefined } });
            onClose();
          }}
        >
          Cancelar
        </ButtonCustom>
        <ButtonCustom
          className='mt-4 sm:mt-0'
          style={{ width: screenResolution <= SizeScreen.sm ? '100%' : 244 }}
          onClick={() => {
            data?.updateInfoForm({ ...valueAlert, country: countryUser });
            if (data && onUpdate) {
              onUpdate(data);
            }
            if (data && onCreate) {
              onCreate(data);
            }
            dispatchAlert({ type: ValueAlertEnum.all, value: { high: '', half: '', low: '', typesDelivery: undefined } });
          }}
        >
          {onUpdate ? 'Guardar' : 'Crear'}
        </ButtonCustom>
      </div>
    </div>
  );
};

export default LimitScheduleForm;
