import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { NameIcons } from 'configs/Enums';
import { PageMetadata, ResponseApp } from 'configs/Interface';
//import { AuthContext } from 'context/AuthContext';

import OrderCard from 'components/molecules/items/cards/order/Order';
//import InputCustom from 'components/atoms/inputs';
import OrderTableHeader, { OrderTableHeaderOption } from 'components/molecules/headers/OrderTable';

import Order from 'models/order';
import IconCustom from 'components/atoms/icons';

type InfiniteScrollOrdersProps = {
  orders?: Order[];
  loadMore?: () => void;
  scrollRef?: any;
  onDetail?: (order: Order) => void;
  onFilter?: (value: { dateStart?: string; dateEnd?: string; deliveryState?: string[]; numOrder?: string }) => void;
  onLoadMore?(params: { page?: number }): Promise<ResponseApp>;
  meta?: PageMetadata;
};

const InfiniteScrollOrders = ({ orders, onDetail, onFilter, meta, onLoadMore }: InfiniteScrollOrdersProps) => {
  //const { authStatus } = useContext(AuthContext);
  const [showFilter, setShowFilter] = useState(true);

  const [ordersCurrent, setOrdersCurrent] = useState<Order[] | undefined>(orders);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = async (page?: number) => {
    console.log('loadMore() ==> ', { page, meta, ordersCurrent });
    if (meta && ordersCurrent && !isLoading) {
      setIsLoading(true);
      if ((meta.page || 0) <= (meta.totalPages || 0)) {
        if (onLoadMore) {
          const { data, status } = await onLoadMore({ page });
          if (status) {
            setOrdersCurrent([...ordersCurrent, ...data]);
          }
          console.log('loadMore() => { page }', { data, status });
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (orders && meta?.page === 1) {
      setOrdersCurrent(orders);
    }
  }, [orders]);

  console.log('render => { meta }', meta);
  return (
    <div className='w-full h-full flex flex-col pb-4'>
      <div className='m-2 mt-4 relative'>
        <OrderTableHeader
          orders={orders || []}
          onFilter={onFilter}
          options={[OrderTableHeaderOption.filter, OrderTableHeaderOption.options]}
          hiddenFilter={!showFilter}
        />

        <div className='absolute bottom-0 right-0'>
          <IconCustom name={showFilter ? NameIcons.arrowUp : NameIcons.arrowDown} onClick={() => setShowFilter(!showFilter)} />
        </div>
      </div>

      <div
        className='flex flex-col overflow-auto w-full'
        //onScroll={e => console.log('onScroll ==> e', e)}
        //onScrollCapture={e => console.log('onScrollCapture() ==> e', e)}
      >
        {ordersCurrent && ordersCurrent.length > 0 && (
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMore}
            hasMore={(meta?.totalPages || 0) > (meta?.page || 0) && !isLoading}
            //hasMore={true}
            //hasMore={false}
            className='w-full'
            loader={
              <div className='loader' key={0}>
                Loading ...
              </div>
            }
            useWindow={false}
          >
            <ul>
              {ordersCurrent.map((order, index) => {
                return <OrderCard key={index} order={order} {...{ onDetail }} />;
              })}
            </ul>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default InfiniteScrollOrders;
