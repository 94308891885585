import { useContext, useEffect, useState } from 'react';

import { EnumTypeDownloadFile, TypeToast } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import ButtonCustom from 'components/atoms/buttons';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';
import UploadedFileItem from 'components/molecules/items/UploadedFile';

import Handbook from 'models/file/Handbook';

export type HandbookOrganismProps = {
  onUploadHandBook?: (handbook?: Handbook) => void;
  onUploadHandBooks?: (handbooks?: Handbook[]) => void;
  handbooks: Handbook[];
  countryUser?: string;
  flagHB?: number;
};

const HandbookOrganism = ({ handbooks, countryUser, onUploadHandBooks, flagHB }: HandbookOrganismProps) => {
  const { showToast, showLoading, hideLoading } = useContext(RootContext);

  const [template, setTemplate] = useState<Handbook>();
  const [handbook, setHandbook] = useState<Handbook>();
  const [handbookUnit, setHandbookUnit] = useState<Handbook>();
  //const [typeFile, setTypeFile] = useState<EnumTypeDownloadFile>();
  const [flag, setFlag] = useState<number>(0);

  const onDragStart = (event: any) => {
    event.dataTransfer.setData('file', event.target.id);
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
  };

  const changeHandlerFile = (event: any, type?: EnumTypeDownloadFile) => {
    console.log('changeHandlerFile() ==>', { event, type, file: event.target.files[0] });
    if (type === EnumTypeDownloadFile.template) {
      setTemplate(new Handbook({ country: countryUser, type, file: event.target.files[0] }));
    } else if (type === EnumTypeDownloadFile.handbook) {
      setHandbook(new Handbook({ country: countryUser, type, file: event.target.files[0] }));
    } else if (type === EnumTypeDownloadFile.handbookManualUnitLoading) {
      setHandbookUnit(new Handbook({ country: countryUser, type, file: event.target.files[0] }));
    }
    setFlag(flag + 1);
  };

  const onDrop = (event: any, type?: EnumTypeDownloadFile) => {
    event.preventDefault();
    if (event.dataTransfer.files.length === 1) {
      const file: File = event.dataTransfer.files[0];
      console.log('file', file);
      if (type === EnumTypeDownloadFile.template) {
        if (
          file.type === 'text/csv' ||
          file.type === 'application/vnd.ms-excel' ||
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          setTemplate(new Handbook({ country: countryUser, type, file }));
        } else {
          showToast({ text: 'Sólo está permitido subir archivos .csv o .xls', type: TypeToast.error });
          //restartValue();
        }
      } else if (type === EnumTypeDownloadFile.handbook) {
        if (file.type === 'application/pdf') {
          setHandbook(new Handbook({ country: countryUser, type, file }));
        } else {
          showToast({ text: 'Sólo está permitido subir archivos .pdf', type: TypeToast.error });
          //restartValue();
        }
      } else if (type === EnumTypeDownloadFile.handbookManualUnitLoading) {
        if (file.type === 'application/pdf') {
          setHandbookUnit(new Handbook({ country: countryUser, type, file }));
        } else {
          showToast({ text: 'Sólo está permitido subir archivos .pdf', type: TypeToast.error });
          //restartValue();
        }
      }
      setFlag(flag + 1);
    } else {
      showToast({ text: 'Sólo puede subir un archivo', type: TypeToast.error });
      //restartValue();
    }
  };

  const handleUploadHandBooks = () => {
    if (onUploadHandBooks) {
      const handbooksCurrent: Handbook[] = [];
      if (template) {
        handbooksCurrent.push(template);
      }
      if (handbook) {
        handbooksCurrent.push(handbook);
      }
      if (handbookUnit) {
        handbooksCurrent.push(handbookUnit);
      }
      console.log('handleUploadHandBooks() => { template, handbook, handbookUnit }', { template, handbook, handbookUnit, handbooks });
      onUploadHandBooks(handbooksCurrent);
    }
  };

  const openBrowserFile = (type: EnumTypeDownloadFile) => {
    //setTypeFile(type);
    if (type === EnumTypeDownloadFile.template) {
      const hidden = document.getElementById('hidden-input-xls');
      hidden?.click();
    } else if (type === EnumTypeDownloadFile.handbook) {
      const hidden = document.getElementById('hidden-input-pdf');
      hidden?.click();
    } else if (type === EnumTypeDownloadFile.handbookManualUnitLoading) {
      const hidden = document.getElementById('hidden-input-pdf-two');
      hidden?.click();
    }
  };

  useEffect(() => {
    const templ = handbooks.find(handB => handB.data.type === EnumTypeDownloadFile.template);
    const handB = handbooks.find(handB => handB.data.type === EnumTypeDownloadFile.handbook);
    const handBU = handbooks.find(handB => handB.data.type === EnumTypeDownloadFile.handbookManualUnitLoading);
    if (templ) {
      setTemplate(new Handbook(templ.data));
    }
    if (handB) {
      setHandbook(new Handbook(handB.data));
    }
    if (handBU) {
      setHandbookUnit(new Handbook(handBU.data));
    }
    setFlag(flag + 1);
    console.log('useEffect() => ', handbooks);
  }, [handbooks, flagHB]);

  return (
    <MenuSessionContainer notStyleContainer classNameContainer='mx-5 my-10 md:mx-10'>
      <div className='flex flex-row justify-between items-center mb-4'>
        <h1 className='text-3xl font-bold flex pb-2'>{`Documentos Arauco Hub`}</h1>
      </div>

      <div>
        <input
          id='hidden-input-pdf'
          type='file'
          onChange={event => changeHandlerFile(event, EnumTypeDownloadFile.handbook)}
          className='hidden'
          accept={'.pdf'}
          multiple={false}
        />

        <input
          id='hidden-input-pdf-two'
          type='file'
          onChange={event => changeHandlerFile(event, EnumTypeDownloadFile.handbookManualUnitLoading)}
          className='hidden'
          accept={'.pdf'}
          multiple={false}
        />

        <input
          id='hidden-input-xls'
          type='file'
          onChange={event => changeHandlerFile(event, EnumTypeDownloadFile.template)}
          className='hidden'
          accept={'.xls,.xlsx,.csv'}
          multiple={false}
        />

        <div className='w-full flex flex-col py-8 px-4 md:px-8 bg-white' style={{ borderRadius: 10 }}>
          <ul id='gallery' className='flex flex-1 justify-between flex-wrap gap-12'>
            {handbook && handbook.data.file && (
              <UploadedFileItem
                flag={flag}
                url={handbook.data.url}
                file={handbook.data.file}
                handbook={handbook}
                onDownload={async () => {
                  showLoading();
                  await handbook.download();
                  hideLoading();
                }}
                //onDeleted={() => onDeletedFile(EnumTypeDownloadFile.handbook)}
                onUpload={() => openBrowserFile(EnumTypeDownloadFile.handbook)}
              />
            )}
            {handbookUnit && handbookUnit.data.file && (
              <UploadedFileItem
                flag={flag}
                url={handbookUnit.data.url}
                file={handbookUnit.data.file}
                handbook={handbookUnit}
                onDownload={async () => {
                  showLoading();
                  await handbookUnit.download();
                  hideLoading();
                }}
                //onDeleted={() => onDeletedFile(EnumTypeDownloadFile.template)}
                onUpload={() => openBrowserFile(EnumTypeDownloadFile.handbookManualUnitLoading)}
              />
            )}
            {template && template.data.file && (
              <UploadedFileItem
                flag={flag}
                url={template.data.url}
                file={template.data.file}
                handbook={template}
                onDownload={async () => {
                  showLoading();
                  await template.download();
                  hideLoading();
                }}
                //onDeleted={() => onDeletedFile(EnumTypeDownloadFile.template)}
                onUpload={() => openBrowserFile(EnumTypeDownloadFile.template)}
              />
            )}
          </ul>
          {onUploadHandBooks && (
            <div className='flex w-full justify-end mt-8'>
              <ButtonCustom className='w-full sm:w-60' onClick={() => handleUploadHandBooks()}>
                Guardar
              </ButtonCustom>
            </div>
          )}
        </div>

        <div className='flex flex-col w-full sm:flex-row'>
          {!template && (
            <div
              className='border-dashed border-2 border-gray-400 py-12 flex flex-1 flex-col justify-center items-center mb-8 sm:mr-2'
              onDragOver={event => onDragOver(event)}
              onDrop={event => onDrop(event, EnumTypeDownloadFile.template)}
              onDragStart={event => onDragStart(event)}
            >
              <p className='mx-3 mb-3 font-semibold text-gray-900 flex flex-wrap justify-center'>
                <span className='text-center'>Arrastra y suelta archivo en cualquier lugar del cuadro (Plantilla)</span>
              </p>
              <ButtonCustom onClick={() => openBrowserFile(EnumTypeDownloadFile.template)}>Cargar archivo</ButtonCustom>
            </div>
          )}

          {!handbook && (
            <div
              className='border-dashed border-2 border-gray-400 py-12 flex flex-1 flex-col justify-center items-center mb-8 sm:ml-2'
              onDragOver={event => onDragOver(event)}
              onDrop={event => onDrop(event, EnumTypeDownloadFile.handbook)}
              onDragStart={event => onDragStart(event)}
            >
              <p className='mx-3 mb-3 font-semibold text-gray-900 flex flex-wrap justify-center'>
                <span className='text-center'>Arrastra y suelta archivo en cualquier lugar del cuadro (Manual)</span>
              </p>
              <ButtonCustom onClick={() => openBrowserFile(EnumTypeDownloadFile.handbook)}>Cargar archivo</ButtonCustom>
            </div>
          )}

          {!handbookUnit && (
            <div
              className='border-dashed border-2 border-gray-400 py-12 flex flex-1 flex-col justify-center items-center mb-8 sm:ml-2'
              onDragOver={event => onDragOver(event)}
              onDrop={event => onDrop(event, EnumTypeDownloadFile.handbookManualUnitLoading)}
              onDragStart={event => onDragStart(event)}
            >
              <p className='mx-3 mb-3 font-semibold text-gray-900 flex flex-wrap justify-center'>
                <span className='text-center'>Arrastra y suelta archivo en cualquier lugar del cuadro (Manual carga unitaria)</span>
              </p>
              <ButtonCustom onClick={() => openBrowserFile(EnumTypeDownloadFile.handbookManualUnitLoading)}>Cargar archivo</ButtonCustom>
            </div>
          )}
        </div>
      </div>
    </MenuSessionContainer>
  );
};

export default HandbookOrganism;
