import { AxiosResponse } from 'axios';
//import { URL_API_TWO } from 'configs/Constants';

import { ResponseApp } from 'configs/Interface';

import { handleErrorServiceApi } from 'helpers/Errors';

import { mySession } from 'storages/Session';

import api from './index';

class AuthProvider {
  async signin(formLogin: { email: string; password: string }): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api({ notTokenValidation: true }).post('users/login', formLogin);
    } catch (err) {
      console.log('signin api ===>', err);
      //return { status: false, message: 'error al registrarse' };
      return handleErrorServiceApi(err as Error);
    }
  }

  async signup(): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api({ notTokenValidation: true }).get('users/signup');
    } catch (err) {
      return { status: false, message: 'error al ingresar' };
    }
  }

  async recoverPassword(email: string): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api({ notTokenValidation: true }).post('users/forgot_password', { email });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async changePassword(form: { password: string; token: string }): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api({ notTokenValidation: true }).put('users/update_password', { newPassword: form.password, tokenAuth: form.token });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async restorePassword(form: { token: string; password: string }): Promise<AxiosResponse | ResponseApp> {
    try {
      console.log('restorePassword() => form', form);
      return await api({ notTokenValidation: true }).post(
        'users/restore-password',
        { newPassword: form.password },
        { headers: { Authorization: `Bearer ${form.token}` } }
      );
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async validateToken(): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api({ notTokenValidation: true }).get(`users/refresh/token?token=${mySession.token}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new AuthProvider();
