import { useState, useEffect } from 'react';
import { Skeleton } from 'antd';

import { EnumStatusDelivery, NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';
import { StatusOrderItemModel } from 'models/statistic/order/StatusOrder';

//type StatisticsCardsProps = {
//statistic?: any;
//};

const StatisticCardItem = ({
  title,
  value,
  icon,
  styleIcons
}: {
  title?: string;
  value?: number;
  icon?: NameIcons;
  styleIcons?: string;
}) => {
  return (
    <div className='flex flex-1 bg-white border-araucoblue py-4 lg:py-10' style={{ borderRadius: 10, borderWidth: 0.6 }}>
      <div className='flex justify-center items-center'>
        <IconCustom name={icon} className={`text-4xl ml-4 lg:ml-8 mr-2 ${styleIcons}`} />
      </div>

      <div className='mr-4 flex flex-1 flex-col justify-center items-center overflow-hidden w-20 md:w-auto'>
        <p className='text-araucoblue font-bold text-base text-center lg:text-xl'>{title}</p>
        {value === undefined && <Skeleton.Input size='small' />}
        {value !== undefined && <p className='text-black text-xl lg:text-2xl font-bold'>{value}</p>}
      </div>
    </div>
  );
};

const StatisticsCards = ({ data, total }: { data?: StatusOrderItemModel[]; total?: number }) => {
  const [delivered, setDelivered] = useState<StatusOrderItemModel>();
  const [earring, setEarring] = useState<StatusOrderItemModel>();
  const [cancelled, setCancelled] = useState<StatusOrderItemModel>();

  useEffect(() => {
    setDelivered(data?.find(d => d.type?.toLowerCase() === EnumStatusDelivery.delivered));
    setEarring(data?.find(d => d.type?.toLowerCase() === EnumStatusDelivery.pendingDelivery));
    setCancelled(data?.find(d => d.type?.toLowerCase() === EnumStatusDelivery.cancelled));
  }, [data]);

  return (
    <div className='grid grid-cols-2 md:grid-cols-4 gap-2 mx-5 mt-4 md:mx-10 md:mt-8 md:gap-4'>
      <StatisticCardItem title={'Total órdenes'} value={total} icon={NameIcons.inventoryTwo} styleIcons='text-araucoblue' />
      <StatisticCardItem title={'Órdenes entregadas'} value={delivered?.value} icon={NameIcons.checkCircle} styleIcons='text-tertiary' />
      <StatisticCardItem
        title={'Pendientes de gestión y entrega'}
        value={earring?.value}
        icon={NameIcons.schedule}
        styleIcons='text-secondary'
      />
      <StatisticCardItem title={'Órdenes canceladas'} value={cancelled?.value} icon={NameIcons.cancel} styleIcons='text-grayarauco-400' />
    </div>
  );
};

export default StatisticsCards;
