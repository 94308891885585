import { useContext, useState } from 'react';

import { NameIcons, SizeScreen, StyleButton } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';
import ButtonCustom from 'components/atoms/buttons';
import ModalCustom from 'components/molecules/modals';
import InputCustom from 'components/atoms/inputs';
import CategoryQuestion from 'models/questions/Category';
import ModalUpdateCategory from './category/ModalUpdate';
import FrequentQuestion from 'models/questions/FrequentQuestion';

type CategoryQuestionOrganismProps = {
  categories?: CategoryQuestion[];
  frequentQuestions: FrequentQuestion[];
  onCreateCategory: (category: CategoryQuestion) => void;
  onDeletedCategory: (category: CategoryQuestion) => void;
  onUpdateCategory: (category: CategoryQuestion) => void;
  onUpdateFrequentQuestion: (form: any) => void;
  onCreateFrequentQuestion: (form: any) => void;
  onDeletedFrequentQuestion: (form: any) => void;
  onLoadQuestions: (params: { category?: CategoryQuestion }) => void;
  onCategoryCurrent: (category?: CategoryQuestion) => void;
};

const CategoryQuestionOrganism = ({
  categories,
  onCreateCategory,
  onDeletedCategory,
  onUpdateCategory,
  onUpdateFrequentQuestion,
  onCreateFrequentQuestion,
  onDeletedFrequentQuestion,
  onLoadQuestions,
  frequentQuestions,
  onCategoryCurrent
}: CategoryQuestionOrganismProps) => {
  const { screenResolution, showModalQuestion } = useContext(RootContext);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [updateCategory, setUpdateCategory] = useState<CategoryQuestion>();

  const onDeleted = (category: CategoryQuestion) => {
    showModalQuestion({
      contentStyle: { padding: 0 },
      classNameOptions: 'mx-4 mb-4',
      title: (
        <div className='border-grayarauco-200 px-4 py-4' style={{ borderBottomWidth: 1 }}>
          <h1 className='text-2xl font-bold'>Eliminación de categoría de preguntas frecuentes</h1>
        </div>
      ),
      message: (
        <div className='flex flex-col justify-center items-center mb-12 mt-10 mx-4'>
          <IconCustom name={NameIcons.error} className='text-primary text-8xl sm:text-7xl' />
          <p className='text-xl text-center'>¿Estás seguro?</p>
          <p className='text-xl text-center'>Se eliminará la categoría con todas sus preguntas y respuestas</p>
        </div>
      ),
      options: [
        { text: 'Cancelar', style: StyleButton.outlinePrimary },
        { text: 'Eliminar', onAction: () => onDeletedCategory(category) }
      ]
    });
  };

  return (
    <div className='flex flex-1 flex-col'>
      <div className='flex flex-row items-center'>
        <p className='text-base md:text-xl font-bold'>Categorías</p>
        <ButtonCustom className='ml-3'>
          <IconCustom name={NameIcons.add} className='text-white' onClick={() => setShowModalCreate(true)} />
        </ButtonCustom>
      </div>

      <div className='mt-4 flex flex-1 flex-col'>
        <div className='overflow-auto flex flex-col' style={{ borderRadius: 10 }}>
          {categories &&
            categories.length > 0 &&
            categories.map((category: any) => {
              return (
                <div key={category.data.id} className='flex flex-row justify-between items-center p-4 bg-white' style={{ marginBottom: 1 }}>
                  <div className='flex justify-center items-center'>
                    <p className='text-sm sm:text-base font-normal'>{category.data.name}</p>
                  </div>

                  <div className='flex flex-row justify-center items-center gap-4'>
                    <IconCustom
                      name={NameIcons.rename}
                      className={'text-xl text-grayarauco-400 cursor-pointer'}
                      onClick={() => {
                        setUpdateCategory(category);
                        onCategoryCurrent(category);
                        setShowModalUpdate(true);
                      }}
                    />
                    <IconCustom
                      name={NameIcons.deleted}
                      className={'text-xl text-grayarauco-400 cursor-pointer'}
                      onClick={() => onDeleted(category)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <ModalCustom isVisible={showModalCreate} onClose={() => setShowModalCreate(!showModalCreate)} styleContent={{ padding: 0 }}>
        <div className='flex flex-col flex-1' style={{ width: screenResolution < SizeScreen.md ? 'auto' : 985 }}>
          <div className='border-gray-200 p-4' style={{ borderBottomWidth: 1 }}>
            <h1 className='text-2xl font-bold text-black'>Agregar categoría</h1>
          </div>

          <div className='flex flex-col p-4'>
            <InputCustom
              label='Categoría'
              onChange={val => setNewCategory(val.target.value)}
              classNameContainer={`${screenResolution < SizeScreen.md ? '' : 'w-64'}`}
            />

            <div className='flex flex-col justify-between mt-14 gap-4 sm:flex-row'>
              <ButtonCustom
                style={{ width: screenResolution < SizeScreen.md ? 'auto' : 256 }}
                styleButton={StyleButton.outlinePrimary}
                onClick={() => {
                  setShowModalCreate(false);
                  setNewCategory('');
                }}
              >
                Cancelar
              </ButtonCustom>
              <ButtonCustom
                disabled={newCategory === ''}
                style={{ width: screenResolution < SizeScreen.md ? 'auto' : 256 }}
                onClick={() => {
                  onCreateCategory(
                    new CategoryQuestion({
                      id: '',
                      name: newCategory,
                      description: newCategory,
                      icon: ''
                    })
                  );
                  setShowModalCreate(false);
                  setNewCategory('');
                }}
              >
                Guardar
              </ButtonCustom>
            </div>
          </div>
        </div>
      </ModalCustom>

      {showModalUpdate && (
        <ModalUpdateCategory
          isVisible={showModalUpdate}
          onUpdateCategory={onUpdateCategory}
          category={updateCategory}
          onClose={() => setShowModalUpdate(false)}
          {...{
            onUpdateFrequentQuestion,
            onDeletedFrequentQuestion,
            onCreateFrequentQuestion,
            onLoadQuestions,
            frequentQuestions,
            categories
          }}
        />
      )}
    </div>
  );
};

export default CategoryQuestionOrganism;
