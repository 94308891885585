import { NameIcons, TypeDelivery } from 'configs/Enums';

import AlertMessage from 'components/molecules/messages/Alert';
import DeliveryBillCard from 'components/molecules/cards/bill/Delivery';

import { numberAtPrice } from 'helpers/Converts';

import Invoice from 'models/invoice';
import IconCustom from 'components/atoms/icons';
import { FilterBillingParams } from 'components/molecules/filters/BillingFilter';
import Store from 'models/store';

type ViewGeneralProps = {
  bill?: Invoice;
  showAlert?: boolean;
  showInterval?: boolean;
  classNameContainer?: string;
  classNameContent?: string;
  onClose?: () => void;
  paramsFilter?: FilterBillingParams;
};

const ViewGeneralBill = ({
  bill,
  showAlert = true,
  showInterval = false,
  classNameContainer,
  classNameContent,
  onClose,
  paramsFilter
}: ViewGeneralProps) => {
  const nameStoreDisplay = (store?: Store | string) => {
    if (typeof store === 'string') {
      if (store === 'Todas') {
        return '';
      }
      return '';
    } else {
      return store?.data?.brand || '';
    }
  };

  return (
    <div className={`flex flex-col ${classNameContainer}`}>
      {showAlert && (
        <AlertMessage
          outlined={false}
          text={
            <p className='text-black'>
              Los datos presentados corresponden a una estimación, los valores están estimados hasta el día anterior.
            </p>
          }
        />
      )}

      {showInterval && (
        <div className='flex flex-col md:flex-row md:items-center p-4 border-grayarauco-200 relative' style={{ borderBottomWidth: 0.5 }}>
          <p className='text-2xl font-bold text-primary'>Vista general {nameStoreDisplay(paramsFilter?.store)}</p>
          <p className='mt-5 md:mt-0 md:ml-5 text-2xl font-bold text-black'>{`${bill?.data.dateStartDisplay} - ${bill?.data.dateEndDisplay}`}</p>

          <div className='flex justify-center items-center absolute top-4 right-4'>
            <IconCustom
              name={NameIcons.close}
              className='text-3xl font-grayarauco-400 cursor-pointer'
              onClick={() => (onClose ? onClose() : null)}
            />
          </div>
        </div>
      )}

      <div className={`${classNameContent}`}>
        <div className='flex w-full bg-primary p-4 mt-4 justify-between flex-col md:flex-row' style={{ borderRadius: 10 }}>
          <p className='text-3xl font-bold text-white'>{numberAtPrice(bill?.data.total)}</p>
          <p className='text-3xl font-normal text-white'>{bill?.data.amount || 0}</p>
        </div>

        <div className='flex justify-between items-center flex-1 flex-col md:flex-row'>
          <DeliveryBillCard classNameContainer='mr-0 mt-4 md:mt-0 md:mr-4 self-stretch' data={bill} type={TypeDelivery.delivery} />
          <DeliveryBillCard classNameContainer='mr-0 mt-4 md:mt-0 md:ml-4 self-stretch' data={bill} type={TypeDelivery.pickup} />
        </div>
      </div>
    </div>
  );
};

export default ViewGeneralBill;
