import { TypeDelivery } from 'configs/Enums';

import { shippingTypeStringToDisplay } from 'helpers/Converts';

export interface DeliveryModel {
  name: string;
  value: string;
  carrier?: string;
  slaNameType?: string;
  warehouseRefIdPickup?: string;
  streetNumberPickup?: string;
  statePickup?: string;
  geolocationPickup?: [number, number];
  streetNamePickup?: string;
  neighborhoodPickup?: string;
  namePickup?: string;
  cityPickup?: string;
  postalCodePickup?: string;
  /** array de distritos donde se presta el servicio **/
  districts?: string[];
  /** array de ciudades donde se presta el servicio **/
  cities?: string[];
  warehouse?: string;
}

export interface DeliveryServerModel {
  name: string;
  carrier?: string;
  slaNameType?: string;
  warehouseRefIdPickup?: string;
  streetNumberPickup?: string;
  statePickup?: string;
  geolocationPickup?: [number, number];
  streetNamePickup?: string;
  neighborhoodPickup?: string;
  namePickup?: string;
  cityPickup?: string;
  postalCodePickup?: string;
  warehouse?: string;
}

class Delivery {
  data: DeliveryModel;

  constructor(data: DeliveryModel) {
    this.data = data;
  }

  static formalizeData(data: DeliveryServerModel): DeliveryModel {
    const {
      name,
      carrier,
      slaNameType,
      warehouseRefIdPickup,
      streetNamePickup,
      streetNumberPickup,
      statePickup,
      geolocationPickup,
      neighborhoodPickup,
      namePickup,
      cityPickup,
      postalCodePickup,
      warehouse
    } = data;

    return {
      name: shippingTypeStringToDisplay(name) || '',
      value: name,
      carrier,
      slaNameType,
      warehouseRefIdPickup,
      streetNumberPickup,
      statePickup,
      geolocationPickup,
      streetNamePickup,
      neighborhoodPickup,
      namePickup,
      cityPickup,
      postalCodePickup,
      districts: [],
      cities: [],
      warehouse
    };
  }

  updateDistricts(values: string[]) {
    this.data.districts = values;
  }

  updateCities(values: string[]) {
    this.data.cities = values;
  }

  isApplyAddress() {
    const { value } = this.data;
    const typeDelivery = value.toLocaleLowerCase();

    if (typeDelivery == TypeDelivery.pickup || typeDelivery == TypeDelivery.pickupStore) {
      return false;
    }
    return true;
  }

  /** como se envia para crear una tienda **/
  sendServerStore() {
    const {
      carrier,
      //name,
      value,
      slaNameType,
      warehouseRefIdPickup,
      streetNamePickup,
      streetNumberPickup,
      statePickup,
      geolocationPickup,
      neighborhoodPickup,
      namePickup,
      cityPickup,
      postalCodePickup
    } = this.data;

    return {
      name: value,
      carrier,
      slaNameType,
      warehouseRefIdPickup,
      streetNumberPickup,
      statePickup,
      geolocationPickup,
      streetNamePickup,
      neighborhoodPickup,
      namePickup,
      cityPickup,
      postalCodePickup
    };
  }
}

Delivery.prototype.toString = function deliveryToString() {
  return `${this.data.name}-${this.data.warehouse}`;
};

export default Delivery;
