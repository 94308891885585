import { AxiosResponse } from 'axios';

import { URL_API } from 'configs/Constants';
import { EnumCountries, EnumTypeDownloadFile } from 'configs/Enums';

import { ResponseApp } from 'configs/Interface';

import { handleErrorServiceApi } from 'helpers/Errors';

import Handbook from 'models/file/Handbook';
import Store from 'models/store';

import { mySession } from 'storages/Session';

import api from '.';

class FileProvider {
  async getDownloadFiles({
    country,
    type
  }: {
    country?: EnumCountries | string;
    type?: EnumTypeDownloadFile;
  }): Promise<AxiosResponse | ResponseApp> {
    try {
      //console.log('getDownloadFiles ==>', { country, type });
      return await api().get(`template?country=${country}&type=${type || EnumTypeDownloadFile.template}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async postHandbook(params: { handbook?: Handbook }): Promise<ResponseApp> {
    try {
      const { handbook } = params;
      return api().post('template', await handbook?.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async postHandbookFetch(params: { handbook?: Handbook }): Promise<ResponseApp> {
    try {
      const { handbook } = params;
      const urlIndex = URL_API?.lastIndexOf('/');
      const length = (URL_API?.length || 0) - 1;

      //console.log('{ urlIndex, length, csvFile }', { urlIndex, length, csvFile });
      const response = await fetch(`${urlIndex === length ? `${URL_API}` : `${URL_API}/`}template`, {
        method: 'POST',
        body: handbook?.sendServerFetch(),
        redirect: 'follow',
        headers: {
          authorization: `Bearer ${mySession.token}`
        }
      });

      const responseFormat = await response.json();
      console.log('postHandbook() ==>', { responseFormat, response });

      if (response.status === 201 || response.status === 200) {
        if (responseFormat.msg) {
          return { status: true, message: responseFormat.msg };
        } else {
          return { status: true, message: 'Archivo subido con éxito.' };
        }
      }

      return { status: false, message: 'No fue posible cargar los archivos.' };
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async generatorExcelOrders({
    dateStart,
    dateEnd,
    store,
    deliveryState,
    country
  }: {
    dateStart: string;
    dateEnd: string;
    store?: Store[];
    deliveryState?: string[];
    country?: string[];
  }) {
    try {
      let paramsSend: any = {
        fechaInicio: dateStart,
        fechaFin: dateEnd,
        search_parameters: [],
        status_list: []
      };

      console.log('generatorExcelOrders() ==> store', store);
      if (store && store.length > 0) {
        paramsSend = {
          ...paramsSend,
          search_parameters: store
            ? store.map(str => {
                console.log('str', str);
                return str.data.commerceId || '';
              })
            : []
        };
      } else if (country) {
        paramsSend.search_parameters = [...country];
      }

      if (deliveryState) {
        paramsSend = { ...paramsSend, status_list: deliveryState || [] };
      }

      return await api().post(`/orders/generateExcel`, paramsSend);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new FileProvider();
