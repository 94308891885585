import { URL_API_TEST } from 'configs/Constants';
import { handleErrorServiceApi } from 'helpers/Errors';

import CategoryQuestion from 'models/questions/Category';
import FrequentQuestion from 'models/questions/FrequentQuestion';
import HelpCenterResource from 'models/questions/Resource';

import api from '.';

class QuestionProvider {
  async getAllFrequentQuestions() {
    try {
      //return api().get(`frequentQuestions`);
      return api({ url: URL_API_TEST }).get(`questions?categoryId_ne=`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getAllFrequentQuestionsByCategory(params: { category?: CategoryQuestion }) {
    try {
      return api({ url: URL_API_TEST }).get(`categories/${params.category?.data.id}/questions`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getAllInformations() {
    try {
      return api({ url: URL_API_TEST }).get(`questions?categoryId=`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async createFrequentQuestion(frequentQuestion: FrequentQuestion) {
    try {
      //return api().post(`frequentQuestions`, frequentQuestion.sendServer());
      return api({ url: URL_API_TEST }).post(`questions`, frequentQuestion.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateFrequentQuestion(frequentQuestion: FrequentQuestion) {
    try {
      //return api().put(`frequentQuestions/${frequentQuestion.data.id}`, frequentQuestion.sendServer());
      console.log('updateFrequentQuestion() ==> frequentQuestion', frequentQuestion);
      return api({ url: URL_API_TEST }).put(`questions/${frequentQuestion.data.id}`, frequentQuestion.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async deletedFrequentQuestion(frequentQuestion: FrequentQuestion) {
    try {
      return api({ url: URL_API_TEST }).delete(`questions/${frequentQuestion.data.id}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getCategories() {
    try {
      return api({ url: URL_API_TEST }).get(`categories`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async createCategoryQuestion(categoryQuestion: CategoryQuestion) {
    try {
      return api({ url: URL_API_TEST }).post(`categories`, await categoryQuestion.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async deletedCategoryQuestion(categoryQuestion: CategoryQuestion) {
    try {
      return api({ url: URL_API_TEST }).delete(`categories/${categoryQuestion.data.id}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updatedCategoryQuestion(categoryQuestion: CategoryQuestion) {
    try {
      return api({ url: URL_API_TEST }).put(`categories/${categoryQuestion.data.id}`, await categoryQuestion.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateSource(helpCenterResource: HelpCenterResource) {
    try {
      return api({ url: URL_API_TEST }).put(`sources`, helpCenterResource);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getSource() {
    try {
      return api({ url: URL_API_TEST }).get(`sources`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new QuestionProvider();
