import { FormatDate } from 'configs/Enums';
import { stringAtDate } from 'helpers/Converts';

export interface OrderInfoModel {
  nameCommerce?: string;
  saleDate?: string;
  saleTimeSeparated?: string;
  saleDateSeparated?: string;
  numberCommerce?: string;
  addressCommerce?: string;
  order: string;
  store?: string;
  warehouse?: string;
}

export interface OrderInfoModelServer {
  nameCommerce?: string;
  saleDate?: string;
  numberCommerce?: string;
  addressCommerce?: string;
  order: string;
  store?: string;
  warehouse?: string;
}

class OrderInfo {
  data: OrderInfoModel;

  constructor(data: OrderInfoModel) {
    this.data = data;
  }

  static formalizeData(data: OrderInfoModelServer): OrderInfoModel {
    //console.log('formalizeData() ==>', data);
    const { nameCommerce, saleDate, numberCommerce, addressCommerce, order, store, warehouse } = data;

    let saleDateSeparated: string | undefined;
    let saleTimeSeparated: string | undefined;
    if (saleDate) {
      try {
        const separeted = saleDate.split(' ');
        saleDateSeparated = separeted[0]
          .split('/')
          .map(seg => (seg.length < 2 ? `0${seg}` : seg))
          .join('/');
        saleTimeSeparated = separeted[1];
      } catch (err) {
        console.log('formalizeData() ==>', err);
      }
    }

    return {
      nameCommerce,
      saleDate:
        stringAtDate(`${saleDateSeparated} ${saleTimeSeparated}`, FormatDate.yyyymmddAltHHmm, FormatDate.yyyymmddAltHHmm) || saleDate,
      numberCommerce,
      addressCommerce,
      saleDateSeparated,
      saleTimeSeparated,
      order,
      store,
      warehouse
    };
  }

  static createVoid(): OrderInfo {
    return new OrderInfo({
      nameCommerce: '',
      saleDate: '',
      numberCommerce: '',
      addressCommerce: '',
      saleDateSeparated: '',
      saleTimeSeparated: '',
      order: '',
      store: '',
      warehouse: ''
    });
  }
}

export default OrderInfo;
