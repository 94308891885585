import axios, { AxiosResponse } from 'axios';
import { URL_API } from 'configs/Constants';

import { EnumCountries } from 'configs/Enums';
import { ResponseApp, PaginatedServicesQuery } from 'configs/Interface';
import moment from 'configs/MomentJS';

import { BulkLoadStatisticsFilterValue } from 'components/molecules/filters/BulkLoadStatisticsFilter';

import { handleErrorServiceApi } from 'helpers/Errors';
import FormManualOrder from 'models/order/FormManual';
import Store from 'models/store';
import User from 'models/User';
import Order from 'models/order';

import { mySession } from 'storages/Session';

import api from '.';

class OrderProvider {
  source = axios.CancelToken.source();
  cancelToken = this.source.token;
  controllerOrderByNumOrder: AbortController | undefined;

  parseQueryServices({ page, items, country, numOrder }: PaginatedServicesQuery) {
    let query = '';
    //console.log('parseQueryServices() => { page, items, country }', { page, items, country });
    if (page) {
      query += `page=${page || 1}`;
    } else {
      query += `page=1`;
    }
    if (items) {
      query += `&items=${items}`;
    } else {
      query += '&items=10';
    }
    if (country && country !== EnumCountries.all) {
      query += `&country=${country}`;
    }
    if (numOrder) {
      query += `&order=${numOrder}`;
    }
    return query;
  }
  /**
   * subir archivo csv para creación de la orden
   */
  async uploadFileCsv(csvFile: File, store?: Store): Promise<ResponseApp | AxiosResponse> {
    try {
      //console.log(__filename, '===>', __dirname, ' ===> ', csvFile);
      const formData = new FormData();
      formData.append('doc', csvFile, csvFile.name);
      if (store) {
        formData.append('commerceId', store.data.commerceId || '');
      }

      const urlIndex = URL_API?.lastIndexOf('/');
      const length = (URL_API?.length || 0) - 1;

      //console.log('{ urlIndex, length, csvFile }', { urlIndex, length, csvFile });
      const response = await fetch(`${urlIndex === length ? `${URL_API}` : `${URL_API}/`}orders/bulkUpload`, {
        method: 'POST',
        body: formData,
        redirect: 'follow',
        headers: {
          authorization: `Bearer ${mySession.token}`
        }
      });

      const responseFormat = await response.json();

      console.log('responseFormat ===>', { responseFormat, response });
      if (response.status === 201 || response.status === 200) {
        if (responseFormat.processId) {
          return { status: true, message: responseFormat.message, data: responseFormat.processId };
        } else {
          return { status: true, message: 'Archivo subido con éxito.' };
        }
      }

      if (responseFormat.description) {
        return { status: false, data: { message: responseFormat.msg, description: responseFormat.description } };
      }

      if (responseFormat.resp && responseFormat.resp.length > 0) {
        if (typeof responseFormat.resp[0] === 'string') {
          return { status: false, data: { message: responseFormat.msg, duplicates: responseFormat.resp } };
        }
        return { status: false, data: { message: responseFormat.msg, data: responseFormat.resp } };
      }
      if (responseFormat.error && responseFormat.error.length > 0) {
        return { status: false, data: responseFormat.error };
      }
      if (responseFormat.message) {
        return { status: false, message: responseFormat.message };
      }
      if (responseFormat.duplicadas) {
        return { status: false, data: { message: responseFormat.msg, duplicates: responseFormat.duplicadas } };
      }
      return { status: false, message: responseFormat.msg };
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrders(params?: PaginatedServicesQuery): Promise<ResponseApp | AxiosResponse> {
    try {
      const query = this.parseQueryServices(params || {});
      return await api().get(`orders?${query}&fechaInicio=${params?.dateStart} 00:00&fechaFin=${params?.dateEnd} 23:59`);
    } catch (err) {
      console.log('getOrders() ==> err', err);
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrdersFailed(params?: PaginatedServicesQuery): Promise<ResponseApp | AxiosResponse> {
    try {
      const query = this.parseQueryServices(params || {});
      console.log({ params, query });
      return await api().get(`orders/getFailedOrder?${query}`);
    } catch (err) {
      console.log('getOrdersFailed() ==> err', err);
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrdersByRangeDate({
    startDate,
    finishDate,
    deliveryState,
    ...params
  }: {
    startDate?: string;
    finishDate?: string;
    deliveryState?: string[];
  } & PaginatedServicesQuery): Promise<ResponseApp | AxiosResponse> {
    try {
      /** version anterior **/
      //return await api().get(`orders/date/${startDate}/${finishDate}/`);
      const query = this.parseQueryServices(params);
      let queryUrl = '';
      if (deliveryState) {
        queryUrl += `?status=${deliveryState.join(',')}`;
      }

      if (startDate) {
        if (queryUrl !== '') {
          queryUrl += `&fechaInicio=${startDate}`;
        } else {
          queryUrl += `?fechaInicio=${startDate}`;
        }
      }

      if (finishDate) {
        if (queryUrl !== '') {
          queryUrl += `&fechaFin=${finishDate}`;
        } else {
          queryUrl += `?fechaFin=${finishDate}`;
        }
      }

      return await api().get(`orders/date${queryUrl ? `${queryUrl}&` : '?'}${query}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrdersByStore({
    store,
    stores,
    dateStart,
    dateEnd,
    deliveryState,
    ...params
  }: {
    stores?: Store[];
    store?: Store;
    dateStart?: string;
    dateEnd?: string;
    deliveryState?: string[];
    numOrder?: string;
  } & PaginatedServicesQuery) {
    try {
      let queryUrl = '';
      const query = this.parseQueryServices(params);
      this.source = axios.CancelToken.source();
      this.cancelToken = this.source.token;

      if (store) {
        queryUrl +=
          queryUrl === '' ? `?store=${encodeURIComponent(store.data.brand || '')}` : `store=${encodeURIComponent(store.data.brand || '')}`;
      }
      if (stores) {
        queryUrl +=
          queryUrl === ''
            ? `?store=${encodeURIComponent(stores.map(sto => sto.data.brand).join(',') || '')}`
            : `store=${encodeURIComponent(stores.map(sto => sto.data.brand).join(',') || '')}`;
      }
      if (dateStart && dateEnd) {
        queryUrl += queryUrl === '' ? `?fechaInicio=${dateStart}&fechaFin=${dateEnd}` : `&fechaInicio=${dateStart}&fechaFin=${dateEnd}`;
      }
      if (deliveryState) {
        queryUrl += queryUrl === '' ? `?status=${deliveryState.join(',')}` : `&status=${deliveryState.join(',')}`;
      }
      return await api().get(`orders/super${queryUrl ? `${queryUrl}&` : '?'}${query}`, { cancelToken: this.cancelToken });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrdersByNumOrder(params: PaginatedServicesQuery) {
    this.controllerOrderByNumOrder = new AbortController();
    try {
      return await api().get(`orders/${encodeURIComponent(params.numOrder || '')}`, { signal: this.controllerOrderByNumOrder.signal });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrdersById(id: string) {
    try {
      return await api().get(`orders/${encodeURIComponent(id)}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getGraphicPie(value: BulkLoadStatisticsFilterValue) {
    try {
      const { dateStart, dateEnd, type, deliveryStates } = value;
      const types = type?.join(',') || '';

      const body: any = {
        startDate: moment(`${dateStart} 00:00`).valueOf(),
        endDate: moment(`${dateEnd} 23:59`).valueOf(),
        tipo: types,
        status: deliveryStates
      };

      return await api().post(`graficas`, body);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getGraphicOrdersByStatusJanis(value: BulkLoadStatisticsFilterValue & PaginatedServicesQuery) {
    try {
      const { dateStart, dateEnd, timeInterval, type, deliveryStates, user, stores, country } = value;
      const types = type?.join(',') || '';
      let storesFormat: any = [];

      if (user?.isLocatario()) {
        storesFormat.push({ 0: user?.data.commerce?.data.commerceId });
      } else {
        storesFormat = stores?.map(store => store.data.commerceId);
      }

      const body: any = {
        startDate: moment(`${dateStart} 00:00`).valueOf(),
        endDate: moment(`${dateEnd} 23:59`).valueOf(),
        country,
        grafico: timeInterval,
        tipo: types,
        status: deliveryStates,
        store: storesFormat
      };

      if (user?.isLocatario()) {
        return await api().post(`graficas/janis`, body);
      } else {
        return await api().post(`graficas/super/store`, body);
      }
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getGraphicTimeAverageOrder(value: BulkLoadStatisticsFilterValue & PaginatedServicesQuery) {
    try {
      const { dateStart, dateEnd, timeInterval, type, deliveryStates, stores, user, country } = value;
      const types = type?.join(',') || '';
      let storesFormat: any = [];

      if (user?.isLocatario()) {
        storesFormat.push({ 0: user?.data.commerce?.data.commerceId });
      }

      if (stores && stores.length > 0) {
        storesFormat = stores.map(store => store.data.commerceId);
      }

      const body: any = {
        startDate: moment(`${dateStart} 00:00`).valueOf(),
        endDate: moment(`${dateEnd} 23:59`).valueOf(),
        country,
        grafico: timeInterval,
        tipo: types,
        status: deliveryStates,
        store: storesFormat
      };

      if (user && user.isSuperAdmin()) {
        return await api().post(`graficas/super/time`, body);
      } else {
        return await api().post(`graficas/time`, body);
      }
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getGraphicStore(value: BulkLoadStatisticsFilterValue & PaginatedServicesQuery) {
    try {
      const { dateStart, dateEnd, stores, type, deliveryStates, user, country } = value;
      const types = type?.join(',') || '';
      let storesFormat: any = [];

      if (user?.isLocatario()) {
        storesFormat.push({ 0: user?.data.commerce?.data.commerceId });
      }

      if (stores && stores.length > 0) {
        storesFormat = stores.map(store => store.data.commerceId);
      }

      const body: any = {
        startDate: moment(`${dateStart} 00:00`).valueOf(),
        endDate: moment(`${dateEnd} 23:59`).valueOf(),
        country,
        tipo: types,
        store: storesFormat,
        status: deliveryStates
      };

      return await api().post('graficas/store', body);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async createOrderUnit(form: FormManualOrder, user?: User, store?: Store) {
    try {
      return await api().put(
        `orders/manual`,
        { ...form.sendServer(user?.data.country), commerceId: store?.data.commerceId },
        {
          headers: {
            //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }
      );
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getHistoryStateByIdOrder(order: Order) {
    try {
      console.log('getHistoryStateByIdOrder() ==> order', order);
      return await api().get(`update/history?id=${order.data.orderJanis}&country=${order.data.detail.data.addresses.data.country}`);
    } catch (err) {
      console.log('getHistoryStateByIdOrder() ==> err', { err });
      return handleErrorServiceApi(err as Error);
    }
  }

  async deleteFailedOrders(orders: Order[]) {
    try {
      return await api().post(
        `orders/deleteFailedOrder`,
        orders.map(order => order.data.id)
      );
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getStatusOrder() {
    try {
      return await api().get(`status`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async onCorrectOrders({ orders }: { orders: Order[] }) {
    try {
      return await api().post(`orders/updateFailedOrder`, {
        fileJson: orders.map(order => order.convertObjectSimple())
      });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrdersFilter(params: PaginatedServicesQuery & BulkLoadStatisticsFilterValue) {
    try {
      this.source = axios.CancelToken.source();
      this.cancelToken = this.source.token;
      let countries = [params.country];
      if (params.country === EnumCountries.all) {
        countries = [EnumCountries.cl, EnumCountries.col, EnumCountries.per];
      }

      return await api().post(
        `orders/getOrders`,
        {
          id: params.numOrder || '',
          store: params.stores?.map(store => store.data.commerceId) || [],
          status: params.deliveryStates || [],
          startDate: `${moment(`${params.dateStart} 00:00`).valueOf()}`,
          endDate: `${moment(`${params.dateEnd} 23:59`).valueOf()}`,
          country: countries,
          page: params.page,
          items: params.items,
          warehouse: params.warehouse?.map(wareh => wareh.data.id) || []
        },
        { cancelToken: this.cancelToken }
      );
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateOrder(order: Order) {
    try {
      return await api().put(`orders/${order.data.id}`, { ...order.sendServerUpdate() });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new OrderProvider();
