import { NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import Handbook from 'models/file/Handbook';

type UploadFileItemProps = {
  file: File;
  url?: string;
  onDeleted?: (file: File) => void;
  onUpload?: (file: File) => void;
  onDownload?: () => void;
  flag?: number;
  handbook?: Handbook;
};

const UploadFileItem = ({ file, url, onDeleted, onUpload, onDownload, handbook }: UploadFileItemProps) => {
  return (
    <li className='flex flex-col flex-1'>
      <div className='mb-6'>
        <h1 className='text-base font-bold'>{handbook?.data.title || ''}</h1>
      </div>

      <div
        className='bg-white flex flex-row justify-between break-words py-10 px-5 border-primary'
        style={{ borderRadius: 10, borderWidth: 1 }}
      >
        {!onDownload && (
          <a href={url} target='black' rel='noopener noreferrer' download={file.name}>
            <span className='text-base font-normal cursor-pointer'>{`${handbook?.data.nameFull}`}</span>
          </a>
        )}

        {onDownload && (
          <button onClick={() => onDownload()}>
            <span className='text-base font-normal cursor-pointer'>{`${handbook?.data.nameFull}`}</span>
          </button>
        )}

        <div className='flex items-center ml-4'>
          {onUpload && (
            <button onClick={() => onUpload(file)}>
              <IconCustom name={NameIcons.uploadFile} className='text-3xl text-grayarauco-400' />
            </button>
          )}
          {onDeleted && (
            <button onClick={() => onDeleted(file)}>
              <IconCustom name={NameIcons.deleted} className='text-3xl text-grayarauco-400' />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default UploadFileItem;
