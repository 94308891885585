import { ReactNode, useState, createContext, useEffect } from 'react';

type TabCustomProps = {
  keyActive?: string;
  children?: ReactNode;
  onChange?: (key: string) => void;
  className?: string;
};

interface TabContextCustomValue {
  onHandleKey: (key: string) => void;
  keyAct?: string;
  onChangeChildren: (chil: ReactNode) => void;
}

export const TabContextCustom = createContext({} as TabContextCustomValue);

const TabCustom = ({ keyActive, children, onChange, className }: TabCustomProps) => {
  const [active, setActive] = useState<string>();
  const [content, setContent] = useState<ReactNode>();

  const onChangeTab = (key: string) => {
    console.log('onChangeTab() ==> key', key);
    setActive(key);
    if (key !== active) {
      if (onChange) {
        onChange(key);
      }
    }
  };

  useEffect(() => {
    setActive(keyActive);
  }, [keyActive]);

  return (
    <TabContextCustom.Provider value={{ onHandleKey: key => onChangeTab(key), keyAct: active, onChangeChildren: chil => setContent(chil) }}>
      <div className={`flex flex-col w-full ${className}`}>
        <div className='flex flex-row justify-between w-full'>{children}</div>
        <div className='px-4 pb-4 bg-white'>{content}</div>
      </div>
    </TabContextCustom.Provider>
  );
};

export default TabCustom;
