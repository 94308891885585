import { ReactNode } from 'react';

import { NameIcons, TypeAlertMessage } from 'configs/Enums';
import IconCustom from 'components/atoms/icons';

type AlertMessageProps = {
  type?: TypeAlertMessage;
  featuredText?: string | ReactNode;
  text?: string | ReactNode;
  className?: string;
  classNameIcon?: string;
  outlined?: boolean;
  notIcon?: boolean;
};

const validateStyleAlert = (type?: TypeAlertMessage) => {
  switch (type) {
    case TypeAlertMessage.info:
      return 'text-blue-700 bg-blue-100';
    case TypeAlertMessage.danger:
      return 'text-red-700 bg-red-100';
    case TypeAlertMessage.success:
      return 'text-green-700 bg-green-100';
    case TypeAlertMessage.warning:
      return 'text-yellow-700 bg-yellow-100';
    default:
      return 'text-blue-700 bg-blue-100';
  }
};

const AlertMessage = ({ type, featuredText, text, className, classNameIcon, outlined, notIcon }: AlertMessageProps) => {
  return (
    <div className={`flex rounded-lg px-2 py-4 text-sm ${validateStyleAlert(type)} ${className}`} role='alert'>
      {/**
      <svg className={`w-5 h-5 inline mr-3 ${classNameIcon}`} fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
          clipRule='evenodd'
        ></path>
      </svg>
      **/}
      {!notIcon && (
        <div className='flex justify-center items-center'>
          <IconCustom className={`w-5 h-5 inline ${classNameIcon}`} name={NameIcons.info} {...{ outlined }} />
        </div>
      )}
      <div className='ml-2'>
        <span className='font-medium'>{featuredText}</span> {text}
      </div>
    </div>
  );
};

export default AlertMessage;
