import { TypeDelivery, TypeDeliveryDisplay } from 'configs/Enums';

import { secondsToHumanize } from 'helpers/Converts';

export interface KpiGeneralItem {
  name?: string;
  type?: TypeDelivery;
  value?: number | string;
}

export interface KpiGeneralModel {
  time: { delivery: KpiGeneralItem[]; avg: string | number };
  effectivity: { delivery: KpiGeneralItem[]; avg: string | number };
  ticket: { delivery: KpiGeneralItem[]; avg: string | number };
}

interface KpiGeneralServerModel {
  avgTimeKpi: {
    pickup?: string;
    delivery_express?: string;
    sameday?: string;
    nextday?: string;
    promKpi?: string;
  };
  avgEffectivity: {
    pickup: number;
    delivery_express: number;
    sameday: number;
    nextday: number;
    promKpi: number;
  };
  totalByDelivery: {
    pickup: number;
    delivery_express: number;
    sameday: number;
    nextday: number;
    promKpi: number;
  };
}

class KpiGeneral {
  data: KpiGeneralModel;

  constructor(data: KpiGeneralModel) {
    this.data = data;
  }

  static transformDelivery({ propertyD, defaultV }: { propertyD: any; defaultV: string | number }) {
    return [
      {
        name: TypeDeliveryDisplay.pickupStore,
        value: propertyD.pickup || defaultV,
        type: TypeDelivery.pickup
      },
      {
        name: TypeDeliveryDisplay.expressDelivery,
        value: propertyD.delivery_express || defaultV,
        type: TypeDelivery.expressDelivery
      },
      {
        name: TypeDeliveryDisplay.sameDay,
        value: propertyD.sameday || defaultV,
        type: TypeDelivery.sameDay
      },
      {
        name: TypeDeliveryDisplay.nextDay,
        value: propertyD.nextday || defaultV,
        type: TypeDelivery.nextDay
      }
    ];
  }

  static formalizeData(data: KpiGeneralServerModel): KpiGeneralModel {
    const { avgTimeKpi, avgEffectivity, totalByDelivery } = data;

    return {
      time: {
        delivery: KpiGeneral.transformDelivery({ propertyD: avgTimeKpi, defaultV: '00:00:00' }),
        avg: avgTimeKpi.promKpi || '00:00:00'
      },
      effectivity: {
        delivery: KpiGeneral.transformDelivery({ propertyD: avgEffectivity, defaultV: 0 }),
        avg: avgEffectivity.promKpi || 0
      },
      ticket: {
        delivery: KpiGeneral.transformDelivery({ propertyD: totalByDelivery, defaultV: 0 }),
        avg: totalByDelivery.promKpi || 0
      }
    };
  }

  getAvgTimePickup() {
    const { time } = this.data;

    const pickup = time?.delivery?.find(deli => deli.type === TypeDelivery.pickup);
    if (pickup) {
      return pickup.value;
    }
  }

  getAvgTimePickupDisplay() {
    const { time } = this.data;

    const pickup = time?.delivery?.find(deli => deli.type === TypeDelivery.pickup);
    if (pickup) {
      return secondsToHumanize(pickup.value);
    }
  }
}

export default KpiGeneral;
