import { FilterBillingParams } from 'components/molecules/filters/BillingFilter';

import { handleErrorServiceApi } from 'helpers/Errors';

import Invoice from 'models/invoice';
import Store from 'models/store';

import api from '.';

class InvoiceProvider {
  async getInvoiceHistorical(params?: FilterBillingParams) {
    const store = params?.store;
    const commmerce = typeof store === 'object' && store !== null ? `commerce=${params?.store?.data.commerceId || ''}&` : '';
    try {
      return await api().get(`billing/historical?${commmerce}country=${params?.country}&year=${params?.year}`);
    } catch (err) {
      return await handleErrorServiceApi(err as Error);
    }
  }

  async getFileDownload(invoice?: Invoice) {
    try {
      return await api().post(`invoice/download/${invoice?.data.id}`);
    } catch (err) {
      return await handleErrorServiceApi(err as Error);
    }
  }

  async getInvoiceCurrent(params?: { store?: Store; country?: string }) {
    const store = params?.store;
    const commmerce =
      typeof store === 'object' && store !== null ? `commerce=${encodeURIComponent(params?.store?.data.commerceId || '')}&` : '';
    try {
      return await api().get(`billing/byEstimate?${commmerce}country=${params?.country}`);
    } catch (err) {
      return await handleErrorServiceApi(err as Error);
    }
  }

  async getInvoiceDetail(params?: { store?: Store; country?: string; invoice?: Invoice }) {
    const store = params?.store;
    const commmerce = typeof store === 'object' && store !== null ? `commerce=${params?.store?.data.commerceId || ''}&` : '';
    try {
      return await api().get(
        `billing/historicalDetail?${commmerce}country=${params?.country}&monthYear=${params?.invoice?.data.monthYear}`
      );
    } catch (err) {
      return await handleErrorServiceApi(err as Error);
    }
  }
}

export default new InvoiceProvider();
