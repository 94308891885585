import { useEffect, useState } from 'react';

//import LabelCustom from 'components/atoms/labels';
//import ButtonCustom from 'components/atoms/buttons';
import ModalCustom from '../modals';

import { ExternalShopModel } from 'models/store';
import InputCustom from 'components/atoms/inputs';
import ButtonCustom from 'components/atoms/buttons';
import IconCustom from 'components/atoms/icons';
import { NameIcons, StyleButton } from 'configs/Enums';
import AlertMessage from '../messages/Alert';

type ExternalShopFormProps = {
  shopsExternal: ExternalShopModel[];
  onChange?: (item: ExternalShopModel[]) => void;
  hiden?: boolean;
  onValidate?: (val?: boolean) => void;
};

type ExternalShopItemProps = {
  onDeleted: (item: ExternalShopModel) => void;
  item: ExternalShopModel;
  onUpdate: (val: ExternalShopModel) => void;
  isDeleted?: boolean;
};

export const ExternalStoreItem = ({ item, onDeleted, onUpdate, isDeleted }: ExternalShopItemProps) => {
  const [name, setName] = useState<string>();
  const [secret, setSecret] = useState<string>();

  const onChange = ({ name, secret }: { name?: string; secret?: string }) => {
    onUpdate({ externalShopName: name, externalShopSecret: secret });
  };

  useEffect(() => {
    setName(item.externalShopName);
    setSecret(item.externalShopSecret);
  }, []);

  return (
    <div className='flex flex-row px-8 pt-8'>
      <InputCustom
        classNameContainer='mr-2'
        label='Nombre tienda'
        value={name}
        isRequired
        onChange={value => {
          setName(value.target.value);
          onChange({ secret, name: value.target.value });
        }}
      />
      <InputCustom
        classNameContainer='mr-2'
        label='Token tienda'
        value={secret}
        isRequired
        onChange={value => {
          setSecret(value.target.value);
          onChange({ secret: value.target.value, name });
        }}
      />

      {isDeleted && (
        <div className='flex flex-col justify-center items-center'>
          <div className='text-lg text-transparent'>x</div>
          <IconCustom
            onClick={() => onDeleted(item)}
            className='text-grayarauco-400 rounded-full border-grayarauco-400 cursor-pointer border-2'
            name={NameIcons.close}
          />
        </div>
      )}
    </div>
  );
};

export const ExternalShopModal = ({
  shopsExternal,
  onUpdate,
  isVisible,
  onClose
}: {
  shopsExternal: ExternalShopModel[];
  onUpdate: (item: ExternalShopModel[]) => void;
  onClose: () => void;
  isVisible: boolean;
}) => {
  const [shopsExternalCurrent, setShopsExternalCurrent] = useState<ExternalShopModel[]>([{ externalShopName: '', externalShopSecret: '' }]);
  const [flag, setFlag] = useState<number>(0);

  const onAddShopExternal = () => {
    setShopsExternalCurrent([...shopsExternalCurrent, { externalShopName: '', externalShopSecret: '' }]);
  };

  const onDeleted = (item: ExternalShopModel) => {
    const shopEC = shopsExternalCurrent.filter(
      sECurrent => `${sECurrent.externalShopSecret} ${sECurrent.externalShopName}` !== `${item.externalShopSecret} ${item.externalShopName}`
    );
    console.log('onDeleted() =>', { item, shopEC, shopsExternalCurrent });
    setShopsExternalCurrent(shopEC);
  };

  const onChange = (item: ExternalShopModel, index: number) => {
    shopsExternalCurrent[index] = item;
    setShopsExternalCurrent(shopsExternalCurrent);
    //console.log('onChange() ==> { item, index }', { item, index });
    setFlag(flag + 1);
  };

  useEffect(() => {
    if (isVisible) {
      setShopsExternalCurrent(
        shopsExternal && shopsExternal.length > 0 ? [...shopsExternal] : [{ externalShopName: '', externalShopSecret: '' }]
      );
      console.log('useEffect => external shops', { shopsExternal });
    }
    console.log('useEffect => external shops 1', { shopsExternal, isVisible });
    setFlag(flag + 1);
  }, [shopsExternal, isVisible]);

  return (
    <ModalCustom styleContent={{ padding: 0 }} {...{ isVisible, onClose }}>
      <div className='m-auto'>
        <div className='flex flex-row p-4 justify-between mb-3 border-grayarauco-200' style={{ borderBottomWidth: 1 }}>
          <h1 className='mr-5'>Marcas Externas</h1>
        </div>

        <AlertMessage
          outlined={false}
          className='mx-8 mt-8'
          text={
            <p className='text-base font-normal text-black'>
              Puedes agregar hasta <strong>2 token</strong> de marca
            </p>
          }
        />

        <div className='flex flex-row'>
          <div>
            {shopsExternalCurrent &&
              shopsExternalCurrent.length > 0 &&
              shopsExternalCurrent.map((shoEx, index) => {
                return (
                  <ExternalStoreItem
                    //key={`${shoEx.externalShopName} ${shoEx.externalShopSecret}`}
                    isDeleted={index !== 0}
                    key={index}
                    item={shoEx}
                    onUpdate={val => onChange(val, index)}
                    {...{ onDeleted }}
                  />
                );
              })}
          </div>

          <div className='mt-8 mr-10'>
            <div className='text-xl mb-1'>
              <p className='text-transparent'>x</p>
            </div>
            <div className='flex flex-row items-center'>
              <p className='text-base font-normal mr-4'>Agregar marca</p>
              <button
                className={`rounded-full h-10 w-10 ${
                  shopsExternalCurrent && shopsExternalCurrent.length >= 2 ? 'bg-grayarauco-300' : 'bg-primary'
                }`}
                disabled={shopsExternalCurrent && shopsExternalCurrent.length >= 2 ? true : false}
                onClick={() => onAddShopExternal()}
              >
                <IconCustom name={NameIcons.add} className='text-white text-4xl' />
              </button>
            </div>
          </div>
        </div>

        <div className='flex justify-between mx-4 my-4'>
          <ButtonCustom
            styleButton={StyleButton.outlinePrimary}
            onClick={() => {
              //console.log('onClick() ==>', false);
              onClose();
            }}
          >
            Cancelar
          </ButtonCustom>
          <ButtonCustom
            disabled={!shopsExternalCurrent.every(sEC => sEC.externalShopName !== '' && sEC.externalShopSecret !== '')}
            onClick={() => onUpdate(shopsExternalCurrent)}
          >
            Guardar
          </ButtonCustom>
        </div>
      </div>
    </ModalCustom>
  );
};

const ExternalShopForm = ({ shopsExternal, onChange, hiden, onValidate }: ExternalShopFormProps) => {
  //const refSelect = useRef<any>(null);

  const [shopsExternalT, setShopsExternalT] = useState<ExternalShopModel[]>([]);
  const [showModal, setShowModal] = useState(false);

  const onUpdate = (val: ExternalShopModel[]) => {
    setShopsExternalT(val);
    setShowModal(false);
    if (onChange) {
      onChange(val);
    }
    //console.log('val', { val, length: val.length });
    if (onValidate) {
      if (val.length === 0) {
        onValidate(false);
      } else if (val.length > 0) {
        onValidate(true);
      }
    }
  };

  useEffect(() => {
    setShopsExternalT(shopsExternal);
  }, [shopsExternal]);

  return (
    <div hidden={hiden}>
      <button
        className={`rounded-full ${shopsExternalT.length > 0 ? 'bg-primary' : 'bg-grayarauco-300'}`}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <p className='text-sm text-white font-bold my-2 mx-2'>Marcas externas ({shopsExternalT.length || 0})</p>
      </button>
      <ExternalShopModal isVisible={showModal} onClose={() => setShowModal(false)} shopsExternal={shopsExternalT} {...{ onUpdate }} />
    </div>
  );
};

export default ExternalShopForm;
