import { ReactNode, useContext, useEffect } from 'react';
import { TabContextCustom } from '.';

export type TabItemCustomProps = {
  tab: ReactNode;
  keyA: string;
  children?: ReactNode;
};

const TabItemCustom = ({ tab, keyA, children }: TabItemCustomProps) => {
  const { keyAct, onHandleKey, onChangeChildren } = useContext(TabContextCustom);

  useEffect(() => {
    if (keyAct === keyA) {
      onChangeChildren(children);
    }
  }, [children]);

  return (
    <button
      className={`${keyAct === keyA ? 'border-primary' : 'border-transparent'} flex flex-1`}
      style={{ borderTopWidth: 3 }}
      onClick={() => {
        console.log('onClick() ==> key', keyA);
        onHandleKey(keyA);
        onChangeChildren(children);
      }}
    >
      {tab}
    </button>
  );
};

export default TabItemCustom;
