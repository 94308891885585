import { THEME_DEFAULT } from 'configs/Constants';
import { convertDayAtNumber } from 'helpers/Converts';

interface ItemDay {
  name?: string;
  value?: number;
  numDay?: number;
  color?: string;
}

interface ItemSchedule {
  name?: string;
  value?: number;
  hour?: string;
}

export interface ItemDemandDayPickup {
  items: ItemDay[];
  more: string;
  less: string;
}

export interface ItemDemandSchedulePickup {
  items: ItemSchedule[];
  more: string;
  less: string;
}

export interface DemandPickupStatisticsModel {
  day: {
    average: ItemDemandDayPickup;
    month: ItemDemandDayPickup;
    week: ItemDemandDayPickup;
  };
  schedule: {
    average: ItemDemandSchedulePickup;
    month: ItemDemandSchedulePickup;
    week: ItemDemandSchedulePickup;
  };
}

class DemandPickupStatistics {
  data: DemandPickupStatisticsModel;

  constructor(data?: DemandPickupStatisticsModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = {
        day: {
          average: { items: [], more: '', less: '' },
          month: { items: [], more: '', less: '' },
          week: { items: [], more: '', less: '' }
        },
        schedule: {
          average: { items: [], more: '', less: '' },
          month: { items: [], more: '', less: '' },
          week: { items: [], more: '', less: '' }
        }
      };
    }
  }

  static dayWithMoreDemandColor(day: string, dayMore: string) {
    if (day === dayMore) {
      return THEME_DEFAULT.default.araucogreen;
    }
    return THEME_DEFAULT.default.araucoblueTwo;
  }

  static dayWhithMoreDemand(items: { name: string; value: number }[]) {
    let more = '';
    let less = '';

    const itemsAlt = items;
    if (itemsAlt && itemsAlt.length > 0) {
      itemsAlt.sort((a, b) => {
        if (a.value > b.value) {
          return -1;
        }
        if (a.value < b.value) {
          return 1;
        }
        return 0;
      });
      more = itemsAlt[0].name;
      less = itemsAlt[itemsAlt.length - 1].name;
    }
    return { more, less };
  }

  static itemFormalize(items: any) {
    if (items && items.length > 0) {
      let lessAndMore = {
        less: '',
        more: ''
      };

      lessAndMore = DemandPickupStatistics.dayWhithMoreDemand([...items]);

      return {
        items: items.map((avg: any) => ({
          name: avg.name,
          value: avg.value,
          number: convertDayAtNumber(avg.name),
          color: DemandPickupStatistics.dayWithMoreDemandColor(avg.name, lessAndMore.more),
          hour: avg.name
        })),
        ...lessAndMore
      };
    }
    return {
      items: [],
      less: '',
      more: ''
    };
  }

  static formalizeData(data?: any, group?: string) {
    if (data) {
      let dataAverage: any = {};
      let dataMonth: any = {};
      let dataWeek: any = {};

      if (data.average) {
        dataAverage = DemandPickupStatistics.itemFormalize(data.average);
      }
      if (data.current_month) {
        dataMonth = DemandPickupStatistics.itemFormalize(data.current_month);
      }
      if (data.current_week) {
        dataWeek = DemandPickupStatistics.itemFormalize(data.current_week);
      }

      return {
        average: dataAverage,
        month: dataMonth,
        week: dataWeek,
        group
      };
    }
    return {
      average: { item: [], more: '', less: '' },
      month: { item: [], more: '', less: '' },
      week: { item: [], more: '', less: '' },
      group
    };
  }
}

export default DemandPickupStatistics;
