import { createContext, useContext, useEffect, useRef, useState } from 'react';

import {
  DashboardOrganismSections,
  EnumTypeOperator,
  EnumTypePerformance,
  FormatDate,
  FormatTime,
  TypeTimeInterval,
  TypeToast,
  UnitDate
} from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import DashboardOrganism from 'components/organisms/dashboard/Dashboard';
import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';

import { getDateNow, rangerStatistics, subtractTime } from 'helpers/Generator';

import Store from 'models/store';
import StatusOrder from 'models/statistic/order/StatusOrder';
import CountOrderByTypeDelivery from 'models/statistic/order/CountOrderByTypeDelivery';
import OrderByStore from 'models/statistic/order/OrderByStore';
import EvolutionOrderByDelivery from 'models/statistic/order/EvolutionOrderByDelivery';
import KpiGeneral from 'models/statistic/order/KpiGeneral';
import TypeDeliveryStatistic, { MountLastYears } from 'models/statistic/TypeDelivery';

import {
  getCountOrderByTypeDelivery,
  getOrdersByStore,
  getStatusOrder,
  getEvolutionByTypesDelivery,
  getKpiGeneral,
  getStatisticTypeDeliveryPerformance,
  getPickupDemand,
  getOrderCanceled,
  getActiveStores,
  getOrderFail,
  getStatisticAmounts,
  getStatisticOperators,
  getStatisticDistribution,
  getStatisticPerformance
} from 'services/app/Statistic';
import DemandPickupStatistics, { DemandPickupStatisticsModel } from 'models/statistic/DemandPickupStatistics';
import OrderCancel from 'models/statistic/order/OrderCancel';
import StoreStatistic from 'models/statistic/Store';
import OrderFail from 'models/statistic/order/OrderFail';
import { convertAllAtCountries } from 'helpers/Converts';
import AmountStatistic from 'models/statistic/Amount';
import OperatorStatistic from 'models/statistic/Operator';
import DistributionStatistic from 'models/statistic/Distribution';
import moment from 'moment';
import PerformanceStatistic from 'models/statistic/Performance';

interface DashboardContextValues {
  onUpdateParams: (params: { tabActiveBusiness: string }) => void;
  params: ParamsValueFilterStatistic;
}

export const DashboardContext = createContext({} as DashboardContextValues);

const DashboardPage = () => {
  const { stores, authStatus, onUpdateTypeOperator, countryUser, countries, typesDelivery, typesOperator } = useContext(AuthContext);
  const { showToast } = useContext(RootContext);

  const refContainer = useRef<HTMLDivElement>(null);

  const [storesFilter, setStoresFilter] = useState<Store[]>();
  const [dataUpdateDate, setDataUpdateDate] = useState('');
  const [animationButton, setAnimationButton] = useState(false);

  const [statusOrder, setStatusOrder] = useState<StatusOrder>();
  const [countOrderByTypeDelivery, setCountOrderByTypeDelivery] = useState<CountOrderByTypeDelivery>();
  const [orderByStore, setOrderByStore] = useState<OrderByStore>();
  const [evolutionByTypesDelivery, setEvolutionByTypesDelivery] = useState<EvolutionOrderByDelivery>();
  const [kpiGeneral, setKpiGeneral] = useState<KpiGeneral>();
  const [performanceTypeDelivery, setPerformanceTypeDelivery] = useState<TypeDeliveryStatistic>();
  const [amountSalesStatistic, setAmountSalesStatistic] = useState<MountLastYears[]>();
  const [demandPickup, setDemandPickup] = useState<DemandPickupStatistics>();
  const [orderCancelStatistic, setOrderCancelStatistic] = useState<OrderCancel>();
  const [activeStoreStatistic, setActiveStoreStatistic] = useState<StoreStatistic>();
  const [orderFailStatistic, setOrderFailStatistic] = useState<OrderFail>();
  const [amountStatistic, setAmountStatistic] = useState<AmountStatistic>();
  const [operatorOrders, setOperatorOrders] = useState<OperatorStatistic>();
  const [distributionOrders, setDistributionOrders] = useState<DistributionStatistic>();
  const [performanceStatistic, setPerformanceStatistic] = useState<PerformanceStatistic>();

  const [paramsFilter, setParamsFilter] = useState<ParamsValueFilterStatistic>({
    timeInterval: TypeTimeInterval.day,
    isGroupTypeDelivery: true,
    country: convertAllAtCountries({ country: countryUser, countries }),
    intervalTimeAmountSale: 'yearActuality',
    dateRange: {
      endDate: `${getDateNow({ format: FormatDate.yyyymmdd })}`,
      startDate: `${getDateNow({ format: FormatDate.yyyymm })}-01`
    },
    dateRangeFailedOrder: {
      endDate: `${getDateNow({ format: FormatDate.yyyymmdd })}`,
      startDate: `${getDateNow({ format: FormatDate.yyyymm })}-01`
    },
    timeIntervalCancelOrder: TypeTimeInterval.month,
    dataRangeCancelOrder: {
      endDate: `${getDateNow({ format: FormatDate.yyyymmdd })}`,
      startDate: `${getDateNow({ format: FormatDate.yyyymm })}-01`
    },
    typesOperator: typesOperator?.filter(typeO => typeO.data.value?.toLowerCase() !== EnumTypeOperator.dummy) || undefined,
    typesDelivery: typesDelivery,
    group: 'day',
    section: DashboardOrganismSections.general
  });

  const onUpdateParams = (paramsF: ParamsValueFilterStatistic) => {
    setParamsFilter(paramsF);
  };

  const loadStatusOrder = async (paramsFilter?: ParamsValueFilterStatistic) => {
    if (paramsFilter) {
      const paramsUpdate = paramsFilter;
      let dateRange = {
        endDate: paramsUpdate.dateRange?.endDate || '',
        startDate: paramsUpdate.dateRange?.startDate || ''
      };
      if (
        paramsFilter.section === DashboardOrganismSections.performanceDelivery ||
        paramsFilter.section === DashboardOrganismSections.performancePickup
      ) {
        let startMes = moment();
        startMes = moment(`${startMes.year()}-${startMes.month() + 1}-01`);
        dateRange = {
          endDate: getDateNow({ format: FormatDate.yyyymmdd }) as string,
          startDate: startMes.format(FormatDate.yyyymmdd)
        };
      }
      const { status, data, message } = await getStatusOrder({ ...paramsUpdate, dateRange });
      console.log('loadStatusOrder() ==> { status, data }', { status, data });
      if (status) {
        setStatusOrder(data);
      }

      return { status, message };
    }
  };

  const loadOrderByTypeDelivery = async (paramsFilter?: ParamsValueFilterStatistic) => {
    if (paramsFilter) {
      const { status, data, message } = await getCountOrderByTypeDelivery(paramsFilter);
      console.log('loadOrderByTypeDelivery() ==> { status, data }', { status, data });

      if (status) {
        setCountOrderByTypeDelivery(data);
      }

      return { status, message };
    }
  };

  const loadOrdersByShop = async (paramsFilter?: ParamsValueFilterStatistic) => {
    if (paramsFilter) {
      const { status, data, message } = await getOrdersByStore(paramsFilter);

      if (status) {
        setOrderByStore(data);
      }

      return { status, message };
    }
  };

  const loadEvolutionOrder = async (paramsFilter?: ParamsValueFilterStatistic) => {
    if (paramsFilter) {
      const { status, data, message } = await getEvolutionByTypesDelivery(paramsFilter);
      console.log('loadEvolutionOrder() => { status, data }', { status, data });

      if (status) {
        const evolutionByTypesDeliveryCurrent = data as EvolutionOrderByDelivery;
        setEvolutionByTypesDelivery(evolutionByTypesDeliveryCurrent);
      }

      return { status, message };
    }
  };

  const loadGeneralKpi = async (paramsFilter?: ParamsValueFilterStatistic) => {
    if (paramsFilter) {
      const paramsUpdate = paramsFilter;
      let dateRange = {
        endDate: paramsUpdate.dateRange?.endDate || '',
        startDate: paramsUpdate.dateRange?.startDate || ''
      };
      if (
        paramsFilter.section === DashboardOrganismSections.performanceDelivery ||
        paramsFilter.section === DashboardOrganismSections.performancePickup
      ) {
        let startMes = moment();
        startMes = moment(`${startMes.year()}-${startMes.month() + 1}-01`);
        dateRange = {
          endDate: getDateNow({ format: FormatDate.yyyymmdd }) as string,
          startDate: startMes.format(FormatDate.yyyymmdd)
        };
      }
      console.log('loadGeneralKpi() ==> paramsFilter', paramsFilter);
      const { status, data, message } = await getKpiGeneral({ ...paramsUpdate, dateRange, timeInterval: TypeTimeInterval.year });
      console.log('loadEvolutionOrder() => { status, data }', { status, data });

      if (status) {
        setKpiGeneral(data);
      }

      return { status, message };
    }
  };

  const loadTotalOrders = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      const paramsUpdate: ParamsValueFilterStatistic = { ...params, dateRange: rangerStatistics() };
      const { status, data, message } = await getStatisticTypeDeliveryPerformance(paramsUpdate);

      if (status) {
        setPerformanceTypeDelivery(data);
      } else {
        setPerformanceTypeDelivery(data);
      }
      return { status, message };
    }
  };

  const loadPerformance = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      const paramsUpdate: ParamsValueFilterStatistic = { ...params, dateRange: rangerStatistics() };
      const { status, data, message } = await getStatisticPerformance(paramsUpdate);

      if (status) {
        setPerformanceStatistic(data);
      } else {
        setPerformanceStatistic(data);
      }
      return { status, message };
    }
  };

  const loadAmounts = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      const paramsUpdate: ParamsValueFilterStatistic = { ...params, dateRange: rangerStatistics() };
      const { status, data, message } = await getStatisticAmounts(paramsUpdate);
      console.log('loadAmounts() ==> ', { status, data, message });

      if (status) {
        setAmountStatistic(data);
      } else {
        setAmountStatistic(data);
      }
      return { status, message };
    }
  };

  const loadOperatorsOrders = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      const paramsUpdate: ParamsValueFilterStatistic = { ...params, dateRange: rangerStatistics() };
      const { status, data, message } = await getStatisticOperators(paramsUpdate);
      console.log('loadOperatorsOrders() ==> ', { status, data, message });

      if (status) {
        setOperatorOrders(data);
      } else {
        setOperatorOrders(data);
      }
      return { status, message };
    }
  };

  const loadDistributionOrders = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      const paramsUpdate: ParamsValueFilterStatistic = { ...params, dateRange: rangerStatistics() };
      const { status, data, message } = await getStatisticDistribution(paramsUpdate);
      console.log('loadDistributionOrders() ==> ', { status, data, message });

      if (status) {
        setDistributionOrders(data);
      } else {
        setDistributionOrders(data);
      }
      return { status, message };
    }
  };

  const loadMountSales = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      const range = { startDate: '', endDate: '' };
      if (params.intervalTimeAmountSale === 'year') {
        range.endDate = getDateNow({ format: FormatDate.yyyymmdd }) as string;
        range.startDate = subtractTime({
          unit: UnitDate.years,
          amount: 1,
          format: FormatDate.yyyymmdd
        }) as string;
      } else {
        range.endDate = getDateNow({ format: FormatDate.yyyymmdd }) as string;
        range.startDate = `${getDateNow({ format: FormatDate.yyyy })}-01-01`;
      }

      const { status, data, message } = await getStatisticTypeDeliveryPerformance({ ...params, dateRange: range });
      console.log('loadMountSales() ==> { status, data }', { status, data });

      if (status) {
        setAmountSalesStatistic(data?.mountLastYears() as any);
      } else {
        setAmountSalesStatistic([]);
      }

      return { status, message };
    }
  };

  const loadOrderCancel = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      setOrderCancelStatistic(undefined);
      const { status, data, message } = await getOrderCanceled(params);
      console.log('loadOrderCancel() => { status, data }', { status, data });

      if (status) {
        setOrderCancelStatistic(data);
      } else {
        setOrderCancelStatistic(new OrderCancel());
      }

      return { status, message };
    }
  };

  const loadStoreActive = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      setActiveStoreStatistic(undefined);
      const { status, data, message } = await getActiveStores(params);
      console.log('loadStoreActive() => { status, data }', { status, data, params });

      if (status) {
        setActiveStoreStatistic(data);
      } else {
        setActiveStoreStatistic(new StoreStatistic());
      }

      return { status, message };
    }
  };

  const loadOrderFailed = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      setOrderFailStatistic(undefined);
      const { status, data, message } = await getOrderFail(params);
      console.log('loadOrderFailed() => { status, data }', { status, data });

      if (status) {
        setOrderFailStatistic(data);
      } else {
        setOrderFailStatistic(new OrderFail());
      }

      return { status, message };
    }
  };

  const resetValue = () => {
    setStatusOrder(undefined);
    setCountOrderByTypeDelivery(undefined);
    setOrderByStore(undefined);
    setKpiGeneral(undefined);
    setEvolutionByTypesDelivery(undefined);
    setPerformanceTypeDelivery(undefined);
    setAmountSalesStatistic(undefined);
    setDemandPickup(undefined);
  };

  const onFilterEvolutionOrder = async (params?: ParamsValueFilterStatistic) => {
    setEvolutionByTypesDelivery(undefined);
    const paramsCurrent = { ...paramsFilter, ...params };
    console.log('onFilterEvolutionOrder() ==> { paramsCurrent }', { paramsCurrent, params, paramsFilter });
    setParamsFilter(paramsCurrent);
    if (paramsCurrent) {
      loadEvolutionOrder(paramsCurrent);
    }
  };

  const onFilterDemandPickup = async (params: ParamsValueFilterStatistic) => {
    setDemandPickup(undefined);
    const promiseAll = [
      await getPickupDemand({ ...params, dateRange: rangerStatistics(), group: 'hour', country: paramsFilter?.country }),
      await getPickupDemand({ ...params, dateRange: rangerStatistics(), group: 'day', country: paramsFilter?.country })
    ];
    const result = await Promise.all(promiseAll);

    if (result) {
      const dataContructor: DemandPickupStatisticsModel = {
        day: {
          average: { items: [], more: '', less: '' },
          month: { items: [], more: '', less: '' },
          week: { items: [], more: '', less: '' }
        },
        schedule: {
          average: { items: [], more: '', less: '' },
          month: { items: [], more: '', less: '' },
          week: { items: [], more: '', less: '' }
        }
      };
      result.forEach(({ data, status }) => {
        if (status) {
          if (data?.group === 'day') {
            dataContructor.day = data;
          }
          if (data?.group === 'hour') {
            dataContructor.schedule = data;
          }
        }
      });

      //console.log('onFilterDemandPickup() ==> result', { result, params, demand: new DemandPickupStatistics(dataContructor) });
      setDemandPickup(new DemandPickupStatistics(dataContructor));
      return { status: true };
    }
    return { status: false };
  };

  const onFilterOrderFail = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      setParamsFilter(params);
      loadOrderFailed(params);
    }
  };

  const onFilterOrderCancel = async (params?: ParamsValueFilterStatistic) => {
    if (params) {
      setParamsFilter(params);
      loadOrderCancel(params);
    }
  };

  const onFilter = (params?: ParamsValueFilterStatistic) => {
    const paramsCurrent = { ...paramsFilter, ...params };
    paramsCurrent.orderStates = [];
    if (authStatus.user?.isLocatario() && authStatus.user?.data.commerce) {
      paramsCurrent.stores = [authStatus.user?.data.commerce];
    }
    console.log('onFilter() => params', { paramsCurrent, params, paramsFilter });
    if (paramsCurrent?.section !== DashboardOrganismSections.business) {
      resetValue();
      setDataUpdateDate(`${getDateNow({ format: FormatTime.hhmma })}`);
      setParamsFilter(paramsCurrent);
      const promiseAll: Promise<any>[] = [];
      promiseAll.push(loadStatusOrder(paramsCurrent));
      promiseAll.push(loadGeneralKpi(paramsCurrent));

      if (!authStatus.user?.isLocatario()) {
        promiseAll.push(loadOrdersByShop(paramsCurrent));
      }
      if (
        paramsCurrent?.typePerformance !== EnumTypePerformance.delivery &&
        paramsCurrent?.typePerformance !== EnumTypePerformance.pickup
      ) {
        promiseAll.push(loadOrderByTypeDelivery(paramsCurrent));
      }
      if (
        paramsCurrent?.typePerformance === EnumTypePerformance.delivery ||
        paramsCurrent?.typePerformance === EnumTypePerformance.pickup
      ) {
        promiseAll.push(loadTotalOrders(paramsCurrent));
        promiseAll.push(loadPerformance(paramsCurrent));
        promiseAll.push(loadAmounts(paramsCurrent));
        promiseAll.push(loadOperatorsOrders(paramsCurrent));
        if (paramsCurrent?.typePerformance === EnumTypePerformance.delivery) {
          promiseAll.push(loadDistributionOrders(paramsCurrent));
        }
      }
      if (paramsCurrent?.typePerformance === EnumTypePerformance.pickup) {
        promiseAll.push(onFilterDemandPickup(paramsCurrent));
      }
      if (paramsCurrent?.section === DashboardOrganismSections.general) {
        promiseAll.push(loadEvolutionOrder(paramsCurrent));
      }
      Promise.all(promiseAll)
        .then((values: { status: boolean; message?: string }[]) => {
          console.log('onFilter() ==> values', values);
          if (values.some(val => !val.status)) {
            showToast({ text: 'No fue posible cargar toda la información, intente nuevamente.', type: TypeToast.error });
            refContainer.current?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' });
            setAnimationButton(true);
            setTimeout(() => {
              setAnimationButton(false);
            }, 3000);
          }
        })
        .catch(err => {
          console.log('onFilter() ==> err', err);
          showToast({ text: 'oprime refrescar para obtener la informacion.' });
        });
    } else {
      setParamsFilter(paramsCurrent);
      loadOrderCancel(paramsCurrent);
      loadStoreActive(paramsCurrent);
      loadOrderFailed(paramsCurrent);
    }
  };

  const onAmountFilter = (params: ParamsValueFilterStatistic) => {
    setAmountSalesStatistic(undefined);
    setParamsFilter(params);
    //console.log('onAmountFilter() ==> params', params);
    loadMountSales(params);
  };

  useEffect(() => {
    if (!authStatus.user?.isLocatario()) {
      setStoresFilter(stores);
      paramsFilter.country = convertAllAtCountries({ country: countryUser, countries });
      paramsFilter.stores = stores;
      onFilter(paramsFilter);
    }
    onUpdateTypeOperator();
  }, [stores]);

  return (
    <DashboardContext.Provider value={{ onUpdateParams, params: paramsFilter }}>
      <DashboardOrganism
        stores={storesFilter}
        {...{
          onFilter,
          paramsFilter,
          statusOrder,
          orderByStore,
          dataUpdateDate,
          evolutionByTypesDelivery,
          kpiGeneral,
          countOrderByTypeDelivery,
          onFilterEvolutionOrder,
          performanceTypeDelivery,
          onAmountFilter,
          amountSalesStatistic,
          demandPickup,
          onFilterDemandPickup,
          orderCancelStatistic,
          activeStoreStatistic,
          orderFailStatistic,
          onFilterOrderFail,
          onFilterOrderCancel,
          refContainer,
          animationButton,
          amountStatistic,
          operatorOrders,
          distributionOrders,
          performanceStatistic
        }}
      />
    </DashboardContext.Provider>
  );
};

export default DashboardPage;
