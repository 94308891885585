import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export interface RenderPopUpProps {
  children?: ReactNode;
  isVisible?: boolean;
  styleContent?: CSSProperties;
  classNameContent?: string;
  classNameContainer?: string;
}

const RenderPopUp = ({ children, styleContent, isVisible, classNameContent, classNameContainer }: RenderPopUpProps) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  if (visible) {
    return ReactDOM.createPortal(
      <div className='z-40 fixed inset-0 flex h-full justify-center items-center'>
        <div className={`h-full w-full bg-backgroundmodal flex justify-center items-center absolute ${classNameContainer}`}>
          <div style={styleContent} className={`rounded-xl bg-white p-4 sm:py-4 ${classNameContent}`}>
            {children}
          </div>
        </div>
      </div>,
      document.getElementById('modal-render-popup') as Element
    );
  }
  return null;
};

export default RenderPopUp;
