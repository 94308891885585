import { TableColumn } from 'react-data-table-component';

import { EnumAlert, FormatTime, NameIcons } from 'configs/Enums';
import moment from 'configs/MomentJS';

import IconCustom from 'components/atoms/icons';
import FilterHeaderColumn from 'components/molecules/headers/FilterHeaderColumn';
import TableCellMolecule from 'components/molecules/items/TableCell';

import { minutesToHoursAndMinutes, shippingTypeStringToDisplay, stringDeliveryToIcon } from 'helpers/Converts';

import Base from 'models/Base';
import { FormItemModel } from 'models/form';
import Delivery from 'models/Delivery';
//import Delivery from 'models/Delivery';

export interface OrderAlertModel {
  id?: string;
  typeDelivery?: string | Delivery;
  description?: string;
  country?: string;
  /** por el momento son solo 3 nivels los correspondientes al semaforo (rojo, amarillo y verde) **/
  params?: { color?: string; colorValue?: string; time?: string; level?: string; id: string }[];
}

export interface OrderAlertServerModel {
  id?: string;
  levelAlert?: string;
  level?: string;
  time?: string;
  delivery?: string;
  color?: string;
}

class OrderAlert extends Base {
  data: OrderAlertModel;

  constructor(data: OrderAlertModel) {
    super();
    this.data = data;
  }

  static groupByServer(orderAlertsS: OrderAlertServerModel[]) {
    try {
      const reducer = orderAlertsS.reduce((prev: any, curre: any) => {
        //console.log('groupByServer() => { prev, currre }', { prev, curre });
        const { id, levelAlert, time: timeCurrent, delivery, country, color: colorCurrent } = curre;
        const color = OrderAlert.parseAlert(colorCurrent);
        const time = typeof timeCurrent === 'string' ? timeCurrent : minutesToHoursAndMinutes(timeCurrent);
        if (!prev.length) {
          const orderA = [
            {
              id: prev.id,
              typeDelivery: prev.delivery,
              description: prev.delivery,
              country: prev.country,
              params: [
                {
                  id: prev.id || '',
                  color: OrderAlert.parseAlert(prev.color),
                  time: typeof prev.time === 'string' ? prev.time : minutesToHoursAndMinutes(prev.time),
                  level: prev.levelAlert || prev.level
                }
              ]
            }
          ];
          const orderAFind = orderA.find(oA => oA.typeDelivery === delivery);
          if (orderAFind && orderAFind.typeDelivery === delivery) {
            orderAFind.params = [...orderAFind.params, { id: id || '', color, time, level: levelAlert }];
            return [orderAFind];
          } else {
            return [
              ...orderA,
              { id, typeDelivery: delivery, description: delivery, country, params: [{ id: id || '', color, time, level: levelAlert }] }
            ];
          }
        } else {
          const orderAlertIndex = prev.findIndex((prevC: any) => prevC.typeDelivery === delivery);
          if (orderAlertIndex !== -1) {
            prev[orderAlertIndex].params.push({ id: id || '', color, time, level: levelAlert });
            return prev;
          }
          return [
            ...prev,
            { id, typeDelivery: delivery, description: delivery, country, params: [{ id: id || '', color, time, level: levelAlert }] }
          ];
        }
      }) as OrderAlertModel[];
      //console.log('groupByServer() => reducer', reducer);
      return reducer;
    } catch (err) {
      console.log('groupByServer() ==> err', err);
      return undefined;
    }
  }

  static formalizeData(data: any): OrderAlertModel {
    const { typeDelivery, params, id } = data;

    return {
      id,
      typeDelivery,
      params: params
    };
  }

  static parseAlert(col?: string, reverse?: boolean) {
    try {
      let colParse: string | undefined;
      console.log('parseAlert() => col', col);
      const color = col ? col.toLowerCase() : '';

      if (reverse) {
        if (color === EnumAlert.high) {
          colParse = 'Rojo';
        } else if (color === EnumAlert.half) {
          colParse = 'Amarillo';
        } else if (color === EnumAlert.low) {
          colParse = 'Verde';
        }
      } else {
        if (color === 'rojo') {
          colParse = EnumAlert.high;
        } else if (color === 'amarillo') {
          colParse = EnumAlert.half;
        } else if (color === 'verde') {
          colParse = EnumAlert.low;
        }
      }
      return colParse;
    } catch (err) {
      console.log('parseAlert() ==> err', err);
      return '';
    }
  }

  sendServer() {
    try {
      const { typeDelivery, params, country } = this.data;

      return params?.map(param => {
        return {
          id: param.id,
          delivery: typeof typeDelivery === 'string' ? typeDelivery : typeDelivery?.data.value,
          time: param.time,
          color: OrderAlert.parseAlert(param.color, true),
          levelAlert: param.level,
          country
        };
      });
    } catch (err) {
      return undefined;
    }
  }

  updateInfoForm({
    low,
    half,
    high,
    country,
    typeDelivery
  }: {
    typeDelivery?: Delivery | string;
    low: string;
    half: string;
    high: string;
    country?: string;
  }) {
    let paramHalf = this.data.params?.find(param => param.color === EnumAlert.half);
    let paramHigh = this.data.params?.find(param => param.color === EnumAlert.high);
    let paramLow = this.data.params?.find(param => param.color === EnumAlert.low);

    if (paramHalf) {
      paramHalf = { ...paramHalf, time: half };
    } else {
      paramHalf = { id: '', color: EnumAlert.half, colorValue: EnumAlert.half, level: '2', time: half };
    }
    if (paramLow) {
      paramLow = { ...paramLow, time: low };
    } else {
      paramLow = { id: '', color: EnumAlert.low, colorValue: EnumAlert.low, level: '3', time: low };
    }
    if (paramHigh) {
      paramHigh = { ...paramHigh, time: high };
    } else {
      paramHigh = { id: '', color: EnumAlert.high, colorValue: EnumAlert.high, level: '1', time: high };
    }

    this.data.params = [paramHigh, paramLow, paramHalf];

    if (typeDelivery) {
      this.data.typeDelivery = typeDelivery;
    }

    this.data.country = country;
  }

  static formFields({ values, typesDelivery }: { values?: OrderAlert; typesDelivery?: Delivery[] }): FormItemModel[] {
    console.log('formFieldsAdd() ==> ', { typesDelivery, values });

    return [
      {
        label: 'Tipo envío',
        type: 'select',
        identifier: 'typeDelivery',
        isRequired: true,
        value: values?.data.typeDelivery,
        data: typesDelivery?.map(typeDelivery => ({ value: typeDelivery.data.value, item: typeDelivery, label: typeDelivery.data.name }))
      },
      {
        label: 'Alto (HH:mm)',
        type: 'duration',
        identifier: 'high',
        isRequired: true,
        value: values?.data.params?.find(param => param.color === EnumAlert.high)?.time,
        formatTime: FormatTime.HHmm
      },
      {
        label: 'Medio (HH:mm)',
        type: 'duration',
        identifier: 'half',
        isRequired: true,
        value: values?.data.params?.find(param => param.color === EnumAlert.half)?.time,
        formatTime: FormatTime.HHmm
      },
      {
        label: 'Bajo (HH:mm)',
        type: 'duration',
        identifier: 'low',
        isRequired: true,
        value: values?.data.params?.find(param => param.color === EnumAlert.low)?.time,
        formatTime: FormatTime.HHmm
      }
    ];
  }

  static formFieldsUpdate({ values, typesDelivery }: { values?: OrderAlert; typesDelivery?: Delivery[] }): FormItemModel[] {
    console.log('formFieldsUpdate() ==> ', { typesDelivery, values });

    return [
      {
        label: 'Alto (HH:mm)',
        type: 'duration',
        identifier: 'high',
        isRequired: true,
        value: values?.data.params?.find(param => param.color === EnumAlert.high)?.time,
        formatTime: FormatTime.HHmm
      },
      {
        label: 'Medio (HH:mm)',
        type: 'duration',
        identifier: 'half',
        isRequired: true,
        value: values?.data.params?.find(param => param.color === EnumAlert.half)?.time,
        formatTime: FormatTime.HHmm
      },
      {
        label: 'Bajo (HH:mm)',
        type: 'duration',
        identifier: 'low',
        isRequired: true,
        value: values?.data.params?.find(param => param.color === EnumAlert.low)?.time,
        formatTime: FormatTime.HHmm
      }
    ];
  }

  static headerTable({
    showModalUpdate,
    onDeleted,
    typesDelivery
  }: {
    showModalUpdate: (item: OrderAlert) => void;
    onDeleted: (item: OrderAlert) => void;
    typesDelivery: Delivery[];
  }): TableColumn<OrderAlert>[] {
    return [
      {
        name: <FilterHeaderColumn title='Tipo envío' />,
        selector: row => OrderAlert.removeFlag(typesDelivery.find(typeD => typeD.data.value === row.data.typeDelivery)?.data.name || ''),
        sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row =>
              OrderAlert.isParameterInvalid(typesDelivery.find(typeD => typeD.data.value === row.data.typeDelivery)?.data.name || ''),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule isEditable={false} {...{ rowIndex, column }}>
            <IconCustom name={stringDeliveryToIcon(row.data.typeDelivery as string)} className='text-primary text-xl mr-1' />
            <p className='text-sm text-normal text-primary'>{shippingTypeStringToDisplay(row.data.typeDelivery as string) || ''}</p>
          </TableCellMolecule>
        )
      },
      {
        name: (
          <FilterHeaderColumn>
            <div className='flex flex-col justify-center items-center'>
              <div className='bg-redarauco-200 rounded-full text-white text-base w-16 text-center'>Alto</div>
              <p className='mt-2 text-xs font-normal'>Horas restantes</p>
            </div>
          </FilterHeaderColumn>
        ),
        selector: row => OrderAlert.removeFlag(row.data.params?.find(param => (param.color || '') === EnumAlert.high)?.time || ''),
        sortable: false,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => OrderAlert.isParameterInvalid(row.data.params?.find(param => (param.color || '') === EnumAlert.high)?.time || ''),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            value={`00:00 - ${row.data.params?.find(param => (param.color || '') === EnumAlert.high)?.time || ''}`}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: (
          <FilterHeaderColumn>
            <div className='flex flex-col justify-center items-center'>
              <div className='bg-araucoyellow-200 rounded-full text-white text-base w-16 text-center'>Medio</div>
              <p className='mt-2 text-xs font-normal'>Horas restantes</p>
            </div>
          </FilterHeaderColumn>
        ),
        selector: row => OrderAlert.removeFlag(row.data.params?.find(param => (param.color || '') === EnumAlert.half)?.time || ''),
        sortable: false,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => OrderAlert.isParameterInvalid(row.data.params?.find(param => (param.color || '') === EnumAlert.half)?.time || ''),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            value={`${OrderAlert.addOneMinute(row.data.params?.find(param => (param.color || '') === EnumAlert.high)?.time)} - ${
              row.data.params?.find(param => (param.color || '') === EnumAlert.half)?.time || ''
            }`}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: (
          <FilterHeaderColumn>
            <div className='flex flex-col justify-center items-center'>
              <div className='bg-araucogreen rounded-full text-white text-base w-16 text-center'>Bajo</div>
              <p className='mt-2 text-xs font-normal'>Horas restantes</p>
            </div>
          </FilterHeaderColumn>
        ),
        selector: row => OrderAlert.removeFlag(row.data.params?.find(param => (param.color || '') === EnumAlert.low)?.time || ''),
        sortable: false,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => OrderAlert.isParameterInvalid(row.data.params?.find(param => (param.color || '') === EnumAlert.low)?.time || ''),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            value={`${OrderAlert.addOneMinute(row.data.params?.find(param => (param.color || '') === EnumAlert.half)?.time)} - ${
              row.data.params?.find(param => (param.color || '') === EnumAlert.low)?.time || ''
            }`}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn title='Opciones' />,
        sortable: true,
        reorder: true,
        cell: row => (
          <div className='w-full flex flex-row justify-center pr-4'>
            <button onClick={() => showModalUpdate(row)}>
              <IconCustom name={NameIcons.rename} className='text-grayarauco-400 text-2xl' />
            </button>

            <button onClick={() => onDeleted(row)}>
              <IconCustom name={NameIcons.deleted} className='text-grayarauco-400 text-2xl' />
            </button>
          </div>
        )
      }
    ];
  }

  static addOneMinute(time?: string) {
    try {
      const duration = moment.duration(time);
      const minutes = duration.asMinutes() + 1;
      console.log('reverseTime ==>', { time, duration });
      return minutesToHoursAndMinutes(minutes);
    } catch (err) {
      console.log('addOneMinute() ==> err', err);
      return '00:00';
    }
  }

  static getFirstTime(orderAlert: OrderAlert, level?: string) {
    if (level === '1') {
      return '00:00';
    }
    if (level === '2') {
      return OrderAlert.addOneMinute(orderAlert.data.params?.find(par => par.level === '1')?.time);
    }
    if (level === '3') {
      return OrderAlert.addOneMinute(orderAlert.data.params?.find(par => par.level === '2')?.time);
    }
  }
}

export default OrderAlert;
