import { GRAPHICS_COLOR } from 'configs/Constants';
import { ItemGraph } from './Common';

interface OperatorStatisticModel {
  orderByOperators?: ItemGraph[];
  monthlyOrdersByOperator?: ItemGraph[];
  totalOrders?: number;
  totalOrdersLastMonth?: number;
  total?: number;
}

class OperatorStatistic {
  data: OperatorStatisticModel;

  constructor(data?: OperatorStatisticModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = { orderByOperators: [], monthlyOrdersByOperator: [], totalOrders: 0, totalOrdersLastMonth: 0 };
    }
  }

  static formalizeData(data: any): OperatorStatisticModel {
    const operators: ItemGraph[] = [];
    const monthlies: ItemGraph[] = [];
    const total: number = data.total || 0;
    let totalOrdersLastMonth = 0;

    if (data.orders_by_operators && data.orders_by_operators.length > 0) {
      data.orders_by_operators.forEach((item: any) => {
        operators.push(item);
      });
      operators.forEach((itemR, index) => {
        itemR.color = GRAPHICS_COLOR.barPrimary[index];
      });
    }

    if (data.monthly_orders_by_operator && data.monthly_orders_by_operator.length > 0) {
      //data.month_graphic.reverse();
      data.monthly_orders_by_operator.forEach((item: any, index: number) => {
        if (index === 0) {
          if (item.values.length === 1) {
            totalOrdersLastMonth = item.values[0].value;
          } else {
            totalOrdersLastMonth =
              item.values.reduce((prev: any, curr: any) => {
                if (prev.value !== undefined) {
                  return prev.value + curr.value;
                } else {
                  return prev + curr.value;
                }
              }) || 0;
          }
        }
        item.values.forEach((val: any) => {
          monthlies.push({ date: item.month, value: val.value, type: val.name });
        });
      });
    }

    return { orderByOperators: operators, monthlyOrdersByOperator: monthlies, totalOrders: 0, totalOrdersLastMonth, total };
  }
}

export default OperatorStatistic;
