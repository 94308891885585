import { CSSProperties, ReactNode, useContext } from 'react';
import Modal from 'react-modal';

import { SizeScreen, StyleButton } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import ButtonCustom from 'components/atoms/buttons';

export type ActionOptionModal = {
  text?: string;
  onAction?: () => void;
  style?: StyleButton;
};

export type ModalQuestionProps = {
  title: string | ReactNode;
  message?: string | ReactNode;
  options?: ActionOptionModal[];
  closeModal: () => void;
  isOpen: boolean;
  contentStyle?: CSSProperties;
  classNameOptions?: string;
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.6)',
    zIndex: 20
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root');

const ModalQuestion = ({ title, message, options, closeModal, isOpen, contentStyle, classNameOptions }: ModalQuestionProps) => {
  const { screenResolution } = useContext(RootContext);

  const onClose = () => {
    closeModal();
  };

  console.log('render() ==>', contentStyle);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{ overlay: customStyles.overlay, content: { ...customStyles.content, ...contentStyle } }}
      contentLabel='Example Modal'
    >
      {title && typeof title === 'string' && <h2 className='text-gray-800 text-2xl flex justify-center border-b-2 py-2 mb-4'>{title}</h2>}
      {title && typeof title !== 'string' && title}
      {message && typeof message === 'string' && <p className='text-lg mb-4'>{message}</p>}
      {message && typeof message !== 'string' && message}

      {options && options.length > 0 && (
        <div className={`flex items-center justify-between ${classNameOptions} flex-col sm:flex-row gap-4`}>
          {options.map(option => {
            const { text, onAction, style } = option;
            return (
              <ButtonCustom
                key={text}
                styleButton={style}
                style={{ width: screenResolution <= SizeScreen.sm ? '100%' : 'auto' }}
                onClick={() => {
                  if (onAction) {
                    onAction();
                  }
                  onClose();
                }}
              >
                {text || 'OK'}
              </ButtonCustom>
            );
          })}
        </div>
      )}
      {!options && <ButtonCustom onClick={() => onClose()}>OK</ButtonCustom>}
    </Modal>
  );
};

export default ModalQuestion;
