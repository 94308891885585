import { useContext, useEffect, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Tabs } from 'antd';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import { PathRoute, SizeScreen, StyleButton } from 'configs/Enums';
import moment from 'configs/MomentJS';

import ButtonCustom from 'components/atoms/buttons';
import DatePickerCustom from 'components/atoms/pickers/DatePicker';

import './OrderTableFilter.css';

import { capitalize } from 'helpers/Converts';

import Order from 'models/order';
import InputCustom from 'components/atoms/inputs';
import Store from 'models/store';
import SelectionButton from 'components/atoms/selects/SelectionButton';
import WarehousePA from 'models/warehouse';

export interface FilterOrderValue {
  val?: string[] | string;
  field?: string;
  data?: any[];
}

type OrderTableFilterProp = {
  onFilter?: (value: { dateEnd?: string; dateStart?: string; deliveryStates?: string[] }) => void;
  onClear?: () => void;
  filter?: { runDni?: string; nOrder?: string; dateEnd?: string; dateStart?: string };
  classNameContainer?: string;
  onExportCSV?: () => void;
  orders: Order[];
  hiddenFilter?: boolean;
  paramsFilter?: {
    dateStart?: string;
    dateEnd?: string;
    store?: Store;
    stores?: Store[];
    deliveryStates?: string[];
    numOrder?: string;
    warehouse?: WarehousePA[];
  };
};

export interface FilterOrderStatus {
  dateRange?: boolean;
  nOrder?: boolean;
  runDni?: boolean;
}

export enum TypesFilterOrder {
  dateRange = 'dateRange',
  runDni = 'runDni',
  numberOrder = 'numberOrder',
  numOrder = 'numOrder'
}

export enum TypesFilterOrderValue {
  dateEnd = 'dateEnd',
  dateStart = 'dateStart',
  deliveryStates = 'deliveryStates',
  numOrder = 'numOrder',
  stores = 'stores',
  warehouse = 'warehouse'
}

const { TabPane } = Tabs;

const OrderTableFilter = ({ onFilter, classNameContainer, onExportCSV, hiddenFilter, paramsFilter }: OrderTableFilterProp) => {
  const { authStatus, stores, deliveryStates, numFailedOrders } = useContext(AuthContext);
  const { screenResolution } = useContext(RootContext);
  const { warehouse } = useContext(DataContext);

  const navigate = useNavigate();

  const [valueFilter, dispatchValue] = useReducer(
    (
      prevState: {
        dateEnd?: string;
        dateStart?: string;
        deliveryStates?: string[];
        numOrder?: string;
        stores?: Store[];
        warehouse?: WarehousePA[];
      },
      action: { type: TypesFilterOrderValue; value?: any }
    ) => {
      switch (action.type) {
        case TypesFilterOrderValue.stores:
          return {
            ...prevState,
            stores: action.value
          };
        case TypesFilterOrderValue.dateStart:
          return {
            ...prevState,
            dateStart: action.value
          };
        case TypesFilterOrderValue.dateEnd:
          return {
            ...prevState,
            dateEnd: action.value
          };
        case TypesFilterOrderValue.deliveryStates:
          return {
            ...prevState,
            deliveryStates: action.value
          };
        case TypesFilterOrderValue.numOrder:
          return {
            ...prevState,
            numOrder: action.value
          };
        case TypesFilterOrderValue.warehouse:
          return {
            ...prevState,
            warehouse: action.value
          };
        default:
          return {
            ...prevState
          };
      }
    },
    {
      dateEnd: paramsFilter?.dateEnd,
      dateStart: paramsFilter?.dateStart,
      deliveryStates: paramsFilter?.deliveryStates,
      numOrder: paramsFilter?.numOrder,
      stores: paramsFilter?.stores,
      warehouse: paramsFilter?.warehouse
    }
  );

  const validateTextTitle = () => {
    const user = authStatus.user;
    if (user) {
      if (user.isSuperAdmin() || user.isSuperAdminGlobal() || user.isCellarPA()) {
        return 'Órdenes de envío';
      } else if (user.isLocatario()) {
        return 'Órdenes de pedidos';
      }
    }
  };

  useEffect(() => {
    console.log('useEffect() =>', { stores, deliveryStates });
    if (deliveryStates && deliveryStates.length > 0) {
      console.log('useEffect() => OrderTableFilter =>', { deliveryStates });
      if (!valueFilter.deliveryStates || valueFilter.deliveryStates.length === 0) {
        dispatchValue({ type: TypesFilterOrderValue.deliveryStates, value: deliveryStates });
      }
    }
    if (stores && stores.length > 0) {
      dispatchValue({ type: TypesFilterOrderValue.stores, value: stores });
    }
    if (warehouse && warehouse.length > 0) {
      dispatchValue({ type: TypesFilterOrderValue.warehouse, value: warehouse });
    }
  }, [deliveryStates, stores, warehouse]);

  return (
    <div className={'w-full mt-0 sm:mt-3 grid grid-cols-1 sm:grid-cols-2' + classNameContainer}>
      <div className='flex flex-col justify-between items-start sm:flex-row'>
        <div>
          <h1 className='self-start'>{validateTextTitle()}</h1>
          {authStatus.user?.isLocatario() && (
            <div className='flex flex-1'>
              <h2 className='text-base font-normal self-start sm:text-xl'>{authStatus.user?.data.commerce?.data.brand}</h2>
            </div>
          )}
        </div>

        {screenResolution > SizeScreen.sm && (
          <div className='flex flex-row justify-end flex-col sm:flex-row'>
            <ButtonCustom
              className='flex flex-row justify-center items-center ml-2'
              onClick={() => (onExportCSV ? onExportCSV() : undefined)}
              styleButton={StyleButton.outlinePrimary}
            >
              Descargar órdenes
            </ButtonCustom>
            {authStatus.user?.isLocatario() && (
              <div className='items-center ml-2 mb-2 sm:mb-0'>
                <Link className='text-base' to={PathRoute.uploadFile}>
                  <ButtonCustom>Cargar Órdenes</ButtonCustom>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      <div className='w-full justify-end' hidden={hiddenFilter}>
        <div className='flex flex-col'>
          <div className='flex flex-col sm:flex-row'>
            <div className='flex flex-1 justify-center items-center'>
              <Tabs
                className={'w-full'}
                centered={true}
                onClick={e => console.log('onClick ==> e', e)}
                tabBarStyle={{ justifyContent: 'center', alignItems: 'center' }}
                onTabClick={a => {
                  if (a === '1') {
                    navigate(PathRoute.listOrders);
                  } else if (a === '2') {
                    navigate(PathRoute.listFailedOrders);
                  }
                }}
              >
                <TabPane tab={<h3>Órdenes en curso</h3>} key='1' />
                <TabPane
                  tab={
                    <h3>
                      Órdenes fallidas <Badge count={numFailedOrders} />
                    </h3>
                  }
                  key='2'
                />
              </Tabs>
            </div>
          </div>

          <div className='flex flex-col mt-4 self-center justify-center items-center sm:ml-2 sm:mt-0 sm:flex sm:flex-row sm:justify-end sm:self-end'>
            <InputCustom
              classNameContainer='w-2/3 sm:w-72'
              label='Ingresa n° de orden:'
              classNameLabel='text-xs sm:text-sm'
              styleLabel={{ fontWeight: screenResolution > SizeScreen.sm ? 'bold' : 'normal' }}
              placeholder='N° de orden'
              onSearch={value => {
                const valueFilterCurrent = { ...valueFilter, numOrder: value };
                console.log('onChange() =>', { value: value, valueFilterCurrent });
                dispatchValue({ type: TypesFilterOrderValue.numOrder, value: value });
                if (onFilter) {
                  onFilter(valueFilterCurrent);
                }
              }}
            />

            {warehouse && warehouse.length > 0 && (
              <SelectionButton
                className='w-full justify-between'
                classNameContainer='ml-0 mb-4 w-full sm:ml-2 sm:mb-0 sm:w-auto flex self-end'
                text='Bodega'
                items={warehouse?.map(wareh => ({
                  label: wareh.data.description || '',
                  labelSearch: wareh.data.description || '',
                  value: wareh.toString() || '',
                  item: wareh
                }))}
                getPopupContainer={(triggerNode: any) => {
                  //console.log('getPopupContainer() => triggerNode', triggerNode);
                  return triggerNode;
                }}
                onClose={() => {
                  if (onFilter) {
                    onFilter(valueFilter);
                  }
                }}
                values={valueFilter.warehouse || []}
                onChange={values => dispatchValue({ type: TypesFilterOrderValue.warehouse, value: values })}
              />
            )}

            {!authStatus.user?.isLocatario() && stores && stores.length > 0 && (
              <SelectionButton
                className='w-full justify-between'
                classNameContainer='ml-0 mb-4 w-full sm:ml-2 sm:mb-0 sm:w-auto flex self-end'
                text='Marca'
                items={stores?.map(sto => ({
                  label: capitalize(sto.data.brand) || '',
                  labelSearch: sto.data.brand || '',
                  value: sto.toString() || '',
                  item: sto
                }))}
                getPopupContainer={(triggerNode: any) => {
                  console.log('getPopupContainer() => triggerNode', triggerNode);
                  return triggerNode;
                }}
                onClose={() => {
                  if (onFilter) {
                    onFilter(valueFilter);
                  }
                }}
                values={valueFilter.stores || []}
                onChange={values => dispatchValue({ type: TypesFilterOrderValue.stores, value: values })}
              />
            )}

            <SelectionButton
              className='w-full justify-between'
              classNameContainer='ml-0 mb-4 w-full sm:ml-2 sm:mb-0 sm:w-auto flex self-end'
              text='Estados de orden'
              items={deliveryStates?.map(delState => ({ value: delState, label: delState, labelSearch: delState, item: delState }))}
              getPopupContainer={(triggerNode: any) => {
                console.log('getPopupContainer() => triggerNode', triggerNode);
                return triggerNode;
              }}
              onClose={() => {
                if (onFilter) {
                  onFilter(valueFilter);
                }
              }}
              values={valueFilter.deliveryStates || []}
              //onChange={values => dispatchValue({ type: TypesFilterOrderValue.stores, value: values })}
              onChange={value => {
                const valueFilterCurrent = { ...valueFilter, deliveryStates: value };
                console.log('onChange() =>', { value, valueFilterCurrent });
                dispatchValue({ type: TypesFilterOrderValue.deliveryStates, value });
                if (onFilter) {
                  onFilter(valueFilterCurrent);
                }
              }}
            />

            <DatePickerCustom
              label='Rango de fechas:'
              classNameLabel='text-xs sm:text-sm'
              styleLabel={{ fontWeight: screenResolution > SizeScreen.sm ? 'bold' : 'normal' }}
              classNameContainer='mt-4 w-2/3 sm:mt-0 sm:w-72 sm:ml-2'
              isRange
              value={
                valueFilter.dateStart && valueFilter.dateEnd ? [moment(valueFilter.dateStart), moment(valueFilter.dateEnd)] : undefined
              }
              onChangeRange={(value: any) => {
                dispatchValue({ type: TypesFilterOrderValue.dateEnd, value: value.endDate });
                dispatchValue({ type: TypesFilterOrderValue.dateStart, value: value.startDate });
                const valueCurren = valueFilter;
                valueCurren.dateEnd = value.endDate;
                valueCurren.dateStart = value.startDate;
                console.log('onChangeRange() ==>', valueCurren);
                if (onFilter) {
                  onFilter(valueCurren);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTableFilter;
