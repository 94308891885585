import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IS_MOBILE, URL_WEB_SOCKET } from 'configs/Constants';
import {
  EnumFileUploadStates,
  EnumNotificationType,
  EnumTypeNotification,
  FormatDate,
  FormatTime,
  NameIcons,
  PathRoute,
  RoleUser,
  StyleButton,
  TypeToast
} from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';

import { showNotification } from 'helpers/Notification';
import { downloadFileByURL, stringAtDate, stringAtTime } from 'helpers/Converts';

import Order, { OrderModelWebSocket } from 'models/order';
import { OrderDetailModelServer } from 'models/order/OrderDetail';
import FileOrders from 'models/files/FileOrders';

import { updateReadNotification } from 'services/app/Notification';

type WebSocketProps = {
  url?: string;
  onUpdateOrders?: (orders?: OrderModelWebSocket[]) => void;
  onNewOrders?: (orders?: Order[]) => void;
};

const convertTypeAtString = (type?: string) => {
  try {
    if (type === EnumNotificationType.orderStatusChangeCancelled) {
      return 'cancelada';
    } else if (type === EnumNotificationType.orderStatusChangeDelivered) {
      return 'entregada';
    } else if (type === EnumNotificationType.orderStatusChangeDispatched) {
      return 'despachado';
    }
    return '';
  } catch (err) {
    return '';
  }
};

const convertTypeAtColoText = (type?: string, onlyColor?: boolean) => {
  try {
    if (type === EnumNotificationType.orderStatusChangeCancelled) {
      return onlyColor ? 'var(--color-yellow)' : 'text-araucoyellow';
    } else if (type === EnumNotificationType.orderStatusChangeDelivered) {
      return onlyColor ? 'var(--color-green)' : 'text-araucogreen';
    } else if (type === EnumNotificationType.orderStatusChangeDispatched) {
      return onlyColor ? 'var(--color-primary)' : 'text-primary';
    }
    return '';
  } catch (err) {
    return '';
  }
};

const convertTypeAtBackground = (type?: string) => {
  try {
    if (type === EnumNotificationType.orderStatusChangeCancelled) {
      return 'var(--color-notification-yellow)';
    } else if (type === EnumNotificationType.orderStatusChangeDelivered) {
      return 'var(--color-green-two)';
    } else if (type === EnumNotificationType.orderStatusChangeDispatched) {
      return 'var(--color-notification-lb)';
    }
    return '';
  } catch (err) {
    return '';
  }
};

const convertTypeAtIcon = (type?: string) => {
  try {
    if (type === EnumNotificationType.orderStatusChangeCancelled) {
      return NameIcons.info;
    } else if (type === EnumNotificationType.orderStatusChangeDelivered) {
      return NameIcons.person;
    } else if (type === EnumNotificationType.orderStatusChangeDispatched) {
      return NameIcons.route;
    }
    return NameIcons.info;
  } catch (err) {
    return NameIcons.info;
  }
};

const useWebSocketOrder = ({ url, onUpdateOrders, onNewOrders }: WebSocketProps) => {
  const { showToast, showNotificationPopUp, showLoading, hideLoading, isUsePlatform } = useContext(RootContext);
  const { authStatus, countryUser, onUpdateNotification, downloadFiles } = useContext(AuthContext);
  const { updateUploadFile, filesOrders } = useContext(DataContext);
  const navigate = useNavigate();

  const onClickNotification = (event?: any) => {
    if (IS_MOBILE) {
      event?.notification.close();
      window.open(window.location.href, '_blank');
    } else {
      if (window == null || window.closed) {
        window.open(window.location.href, '_blank');
      } else {
        window.focus();
      }
    }
  };

  const showNotificationChangeStatus = ({
    type,
    title,
    description,
    connectionId,
    data,
    date
  }: {
    type?: string;
    title?: string;
    description?: any;
    connectionId: string;
    data?: any;
    date?: string;
  }) => {
    let orderNum = '';
    let shop = '';
    if (data) {
      const order = new Order(Order.formalizeData(data));
      orderNum = order.data.detail.data.order.data.order;
      shop = order.data.detail.data.order.data.store || '';
    }

    if (
      type === EnumNotificationType.orderStatusChangeDispatched ||
      type === EnumNotificationType.orderStatusChangeCancelled ||
      type === EnumNotificationType.orderStatusChangeDelivered
    ) {
      showToast({
        text: (
          <div className='flex flex-col'>
            <h2 className={`text-xl font-bold mb-2 ${convertTypeAtColoText(type, false)}`}>
              Acaba de ser {convertTypeAtString(type)} a las {stringAtTime(date, FormatTime.HHmm)}
            </h2>

            <p className='text-base font-normal text-grayarauco-400'>
              Orden <strong>{orderNum}</strong>
            </p>
            <p className='text-base font-normal text-grayarauco-400'>Tienda: {shop}</p>
            <p className='text-base font-normal text-grayarauco-400'>Fecha: {stringAtDate(date, FormatDate.yyyymmddAlt)}</p>
          </div>
        ),
        type: TypeToast.custom,
        icon: convertTypeAtIcon(type),
        style: { color: convertTypeAtColoText(type, true), background: convertTypeAtBackground(type) }
      });
    } else {
      showToast({
        text: `${title} \n ${description}`,
        type: TypeToast.info,
        action: {
          text: 'Ver',
          onAction: () => {
            updateReadNotification(connectionId);
            //navigate(PathRoute.listOrders);
            try {
              navigate(`${PathRoute.orderDetail}/${data.idOrder}`);
            } catch (err) {
              navigate(`${PathRoute.orderDetail}/${data?.length > 0 ? data[0].idOrder : ''}`);
            }
          }
        }
      });
    }
  };

  const onMessage = (e: any) => {
    showLoading();
    console.log('onmessage() => e', e);
    if (e.data) {
      try {
        const dataParse = JSON.parse(e.data);
        console.log('dataParse() => dataParse', dataParse);
        onUpdateNotification();
        if (dataParse && dataParse.length === 0) {
          //console.log('dataParse ==>');
        } else if (dataParse.length > 0) {
          const updateOrders = dataParse as OrderModelWebSocket[];
          //console.log('updateOrders() =>', updateOrders);
          if (onUpdateOrders) {
            onUpdateOrders(updateOrders);
          }
        } else {
          const { title, description, data, connectionId, date, role, type } = dataParse;
          if (type === EnumNotificationType.errorNotification) {
            const fileOrderU = new FileOrders({
              correct: 0,
              failed: 0,
              status: EnumFileUploadStates.error,
              nameFileFull: description ? description.name || '' : '',
              id: description.id,
              type: title,
              description: ''
            });
            updateUploadFile(fileOrderU);
          }
          if (type === EnumNotificationType.excelNotificationSuccess) {
            const fileOrderU = new FileOrders({
              correct: 0,
              failed: 0,
              status: EnumFileUploadStates.complete,
              id: description.processID,
              type: 'download'
            });
            //const findFileOrders = filesOrders.find(fileOrders => fileOrders.data.id === fileOrderU.data.id);

            downloadFileByURL({ url: description.url });

            //if (updateUploadFile && findFileOrders) {
            updateUploadFile(fileOrderU);
            //}
          } else if (role === RoleUser.cellarPA) {
            if (type === EnumNotificationType.ordersNotificationFail) {
              let optionsButton: any = [];
              const info = {
                failed: description ? description.fails || 0 : 0,
                approved: description ? description.correct || 0 : 0,
                fileName: description ? description.name || '' : ''
              };

              if (info) {
                optionsButton = [
                  {
                    text: 'ir a órdenes correctas',
                    style: info.failed ? StyleButton.outlinePrimary : StyleButton.bgPrimary,
                    onAction: () => {
                      navigate(PathRoute.listOrders);
                    }
                  }
                ];
                if (info.failed !== 0) {
                  optionsButton.push({
                    text: 'ir a órdenes fallidas',
                    onAction: () => {
                      navigate(PathRoute.listFailedOrders);
                    }
                  });
                }
              }
              const fileOrderU = new FileOrders({
                correct: info.approved || 0,
                failed: info.failed || 0,
                status: EnumFileUploadStates.complete,
                nameFileFull: info.fileName,
                type: title === ' Archivo con formato erroneo' ? 'error' : 'upload',
                description:
                  title === ' Archivo con formato erroneo' ? (
                    <div className='mt-4'>
                      <p className='text-sm font-normal'>Para subir de forma correcta tus órdenes, descarga la plantilla excel.</p>
                      <p
                        className='mt-4 cursor-pointer text-primary text-sm font-normal'
                        onClick={() => downloadFiles?.template?.download()}
                      >
                        plantilla de trabajo
                      </p>
                    </div>
                  ) : undefined
              });
              //const findFileOrders = filesOrders.find(fileOrders => fileOrders.data.id === fileOrderU.data.id);

              //if (updateUploadFile && findFileOrders) {
              updateUploadFile(fileOrderU);
              //}
            }
          } else if (role === RoleUser.locatario) {
            showNotification({
              title: title,
              description: description?.web || '',
              onClick: onClickNotification,
              disabled: isUsePlatform
            });

            if (type === EnumNotificationType.ordersNotificationFail) {
              let optionsButton: any = [];
              const info = {
                failed: description ? description.fails || 0 : 0,
                approved: description ? description.correct || 0 : 0,
                fileName: description ? description.name || '' : ''
              };

              if (info) {
                optionsButton = [
                  {
                    text: 'ir a órdenes correctas',
                    style: info.failed ? StyleButton.outlinePrimary : StyleButton.bgPrimary,
                    onAction: () => {
                      navigate(PathRoute.listOrders);
                    }
                  }
                ];
                if (info.failed !== 0) {
                  optionsButton.push({
                    text: 'ir a órdenes fallidas',
                    onAction: () => {
                      navigate(PathRoute.listFailedOrders);
                    }
                  });
                }
              }
              const fileOrderU = new FileOrders({
                correct: info.approved || 0,
                failed: info.failed || 0,
                status: EnumFileUploadStates.complete,
                nameFileFull: info.fileName,
                type: title === ' Archivo con formato erroneo' ? 'error' : 'upload',
                description:
                  title === ' Archivo con formato erroneo' ? (
                    <div className='mt-4'>
                      <p className='text-sm font-normal'>Para subir de forma correcta tus órdenes, descarga la plantilla excel.</p>
                      <p
                        className='mt-4 cursor-pointer text-primary text-sm font-normal'
                        onClick={() => downloadFiles?.template?.download()}
                      >
                        plantilla de trabajo
                      </p>
                    </div>
                  ) : undefined
              });
              const findFileOrders = filesOrders.find(fileOrders => fileOrders.data.id === fileOrderU.data.id);

              //if (updateUploadFile && findFileOrders) {
              updateUploadFile(fileOrderU);
              //}

              if (!findFileOrders) {
                /** NOTE: recuerde que si actuliza esta funcion tendria que actulizar la de notificacion **/
                showNotificationPopUp({
                  title: 'Resultado carga de archivo',
                  children: (
                    <div>
                      {info.approved !== 0 && info.failed !== 0 && (
                        <div className='mb-2'>
                          <div className='flex justify-center items-center'>
                            <div className='text-tertiary text-4xl'>
                              <IconCustom name={NameIcons.done} className='text-4xl' />
                            </div>
                            <p className='font-bold'>
                              <span className='text-tertiary text-2xl'>{info.approved}</span>
                              {' de '}
                              <span className='text-2xl'>{info.approved + info.failed}</span>
                              <span className='text-tertiary'>{' ordenes ingresadas correctamente'}</span>
                            </p>
                          </div>

                          <div className='flex flex-col justify-center items-center mt-2'>
                            <div className='text-6xl'>
                              <IconCustom name={NameIcons.error} className='text-6xl text-primary' />
                            </div>
                            <p>Se han detectado</p>
                            <p className='text-primary font-bold'>{info.failed} órdenes mal ingresadas</p>
                          </div>

                          <div className='flex justify-center items-center mt-4'>
                            <p className='text-center mx-4'>Puedes corregirlas en el módulo de órdenes fallidas</p>
                          </div>
                        </div>
                      )}

                      {info.approved !== 0 && info.failed === 0 && (
                        <div className='flex flex-col justify-center items-center mb-2'>
                          <IconCustom name={NameIcons.done} className='text-8xl text-tertiary' />

                          <p className='font-bold mb-2'>
                            <span className='text-tertiary text-2xl'>{info.approved}</span>
                            {' de '}
                            <span className='text-2xl'>{info.approved + info.failed}</span>
                          </p>

                          <p className='text-tertiary font-bold mx-8'>{`Se han subido correctamente todas las órdenes`}</p>
                        </div>
                      )}

                      {info.failed !== 0 && info.approved === 0 && (
                        <div>
                          <div className='flex flex-col justify-center items-center'>
                            <IconCustom name={NameIcons.error} className='text-6xl text-primary' />
                            <p>Se han detectado</p>
                            <p className='text-primary font-bold'>todas las órdenes mal ingresadas</p>
                          </div>

                          <div className='flex justify-center items-center mt-4 mx-7'>
                            <p className='text-center'>Puedes corregirlas en el módulo de órdenes fallidas</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ),
                  options: optionsButton
                });
              }
            } else {
              if (type !== EnumNotificationType.errorNotification) {
                showNotificationChangeStatus({
                  type,
                  title,
                  description,
                  connectionId,
                  data,
                  date
                });
              }
            }
          } else {
            showNotification({ title: title, description: description, onClick: onClickNotification, disabled: isUsePlatform });

            if (type === EnumNotificationType.ordersNotificationFail) {
              showToast({
                text: `${title} \n ${description}`,
                type: TypeToast.error,
                action: {
                  text: 'click aquí',
                  onAction: () => {
                    updateReadNotification(connectionId);
                    navigate(PathRoute.listFailedOrders);
                  }
                }
              });
              showNotificationPopUp({
                title: 'Archivo cargado',
                message: `${title} \n ${description}`,
                description: 'Puedes corregirlas en el módulo de órdenes fallidas',
                type: EnumTypeNotification.success,
                options: [
                  {
                    text: 'ir a órdenes fallidas',
                    onAction: () => {
                      updateReadNotification(connectionId);
                      navigate(PathRoute.listFailedOrders);
                    }
                  }
                ]
              });
            } else {
              if (onNewOrders) {
                let newOrders: Order[] = [];
                if (data && data.length > 0) {
                  newOrders = data.map(
                    (order: OrderDetailModelServer) => new Order(Order.formalizeData({ text: order, id: connectionId, updatedAt: date }))
                  );
                }
                onNewOrders(newOrders);
              }
              showNotification({ title: title, description: description, onClick: onClickNotification, disabled: isUsePlatform });

              showNotificationChangeStatus({
                type,
                title,
                description,
                connectionId,
                data,
                date
              });
            }
          }
        }
      } catch (err) {
        //console.log('onmessage() => err', err);
      }
    }
    hideLoading();
  };

  useEffect(() => {
    if (IS_MOBILE) {
      navigator.serviceWorker.addEventListener('notificationclick', onClickNotification);
      addEventListener('notificationclick', onClickNotification);
    }
    const socket = new WebSocket(
      url || `${URL_WEB_SOCKET}?user=${authStatus.user?.data.id}${countryUser ? `&country=${countryUser}` : ''}`
    );

    socket.addEventListener('open', event => {
      console.log('socket ==> open', { event, socket });
    });

    socket.addEventListener('message', event => {
      console.log('socket ==> message', { event, socket });
      onMessage(event);
    });

    socket.addEventListener('error', err => {
      console.log('socket ==> error', { err, socket });
    });

    return () => {
      socket?.close();
      if (IS_MOBILE) {
        navigator.serviceWorker.removeEventListener('notificationclick', onClickNotification);
        removeEventListener('notificationclick', onClickNotification);
      }
    };
  }, [authStatus, countryUser, filesOrders]);

  return null;
};

export default useWebSocketOrder;
