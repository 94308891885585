import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { SizeScreen } from 'configs/Enums';
import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import SidebarNavigationVertical from 'components/molecules/sidebarNavigation/SidebarNavigation';

const ContainerMenuLayout = () => {
  const { authStatus, storeSession } = useContext(AuthContext);
  const { screenResolution } = useContext(RootContext);

  //console.log('screenResolution =>', screenResolution);
  return (
    <div className={`flex bg-gray-100 w-screen h-screen ${screenResolution > SizeScreen.md ? 'flex-row' : 'flex-col'}`}>
      {authStatus.user?.showOptionsMenu(storeSession) && (
        <SidebarNavigationVertical sections={authStatus.user?.showOptionsMenu(storeSession)} />
      )}
      <div className={`w-full h-full overflow-auto`}>
        <Outlet />
      </div>
    </div>
  );
};

export default ContainerMenuLayout;
