import { useContext } from 'react';

import { AuthContext } from 'context/AuthContext';

import { NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import { convertNumberToPrice, nameDistrictForCountry, shippingTypeStringToDisplay, stringDeliveryToIcon } from 'helpers/Converts';

import Order, { EnumFielOrder } from 'models/order';

type DetailFailedOrderOrganismProps = {
  order?: Order;
  goBack: () => void;
  onDetailCorrect: (order: Order) => void;
};

const DetailFailedOrderOrganism = ({ order, goBack, onDetailCorrect }: DetailFailedOrderOrganismProps) => {
  const { countryUser } = useContext(AuthContext);
  //const { screenResolution } = useContext(RootContext);

  if (order) {
    return (
      <div className='h-full w-full'>
        <div className='px-4 border-gray-200 sm:px-10 py-4 sm:bg-white border-t'>
          <button className='flex justify-center text-xs items-center mb-0 sm:mb-4' onClick={() => goBack()}>
            <IconCustom name={NameIcons.navigateBefore} className='text-xs' /> Volver
          </button>
          <div className='flex items-center'>
            <h1 className={`text-2xl ${order.data.errors?.every(err => err.isValid) ? 'text-tertiary' : 'text-redarauco'}`}>
              Orden{' '}
              {order.isNumOrderDuplicate() ||
                order.getValueErrorOrCorrect({ type: EnumFielOrder.order, value: order.data.detail.data.order.data.order })}
            </h1>
            {order.isNumOrderDuplicate() && (
              <div className='ml-9 flex justify-center items-center'>
                <IconCustom name={NameIcons.warning} className='text-alternativethree text-xl' />

                <p className='text-base text-alternativethree font-normal ml-1'>
                  Orden ya existente,{' '}
                  <strong className='underline font-normal cursor-pointer' onClick={() => onDetailCorrect(order)}>
                    ver orden ingresada
                  </strong>
                </p>
              </div>
            )}
          </div>

          <h3 className='text-lg'>{order.data.detail.data.order.data.store}</h3>
        </div>

        {!order.data.alertOrder && (
          <div className={`mx-4 sm:mx-10 mb-4 sm:mt-8 sm:mb-4 flex flex-1`}>
            <p className='text-base font-normal'>
              La orden no pasó al listado de órdenes correctas porque presenta error, una vez corregida la orden, puedes cargarla y
              automáticamente pasará al listado de órdenes correctas.
            </p>
          </div>
        )}

        <div className='mx-4 sm:mx-10 my-4 grid gap-4 grid-cols-1 sm:grid-cols-3'>
          <div className={`bg-white rounded-xl p-4 ${order.getValidateErrorSection('client') ? '' : 'border-redarauco border'}`}>
            <p className='text-primary font-bold text-sm'>Datos Cliente</p>

            <h1
              className={`text-lg font-bold mt-4 ${
                order.getValueErrorOrCorrect({
                  type: EnumFielOrder.firstName,
                  value: order.data.detail.data.customer.data.firstName,
                  returnOnlyBoolean: true
                }) ||
                order.getValueErrorOrCorrect({
                  type: EnumFielOrder.lastName,
                  value: order.data.detail.data.customer.data.lastName,
                  returnOnlyBoolean: true
                })
                  ? 'text-redarauco'
                  : 'text-black'
              }`}
            >
              {order.getValueErrorOrCorrect({ type: EnumFielOrder.firstName, value: order.data.detail.data.customer.data.firstName })}{' '}
              {order.getValueErrorOrCorrect({ type: EnumFielOrder.lastName, value: order.data.detail.data.customer.data.lastName })}
            </h1>

            <div className='flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center'>
              {(order.data.detail.data.addresses.data.adr ||
                order.data.detail.data.addresses.data.state ||
                order.data.detail.data.addresses.data.district) && (
                <div className='flex flex-1 flex-col'>
                  {order.data.detail.data.addresses.data.adr && (
                    <div className='mt-4'>
                      <p
                        className={`font-normal text-sm ${
                          order.getValueErrorOrCorrect({
                            type: EnumFielOrder.adr,
                            value: order.data.detail.data.addresses.data.adr,
                            returnOnlyBoolean: true
                          })
                            ? 'text-redarauco'
                            : 'text-primary'
                        }`}
                      >
                        Dirección
                      </p>
                      {order.getValueErrorOrCorrect({
                        type: EnumFielOrder.adr,
                        value: order.data.detail.data.addresses.data.adr,
                        className: 'text-black font-normal text-sm mt-1',
                        isReactNode: true
                      })}
                    </div>
                  )}

                  {order.data.detail.data.addresses.data.state && (
                    <div className='mt-4'>
                      <p
                        className={`font-normal text-sm ${
                          order.getValueErrorOrCorrect({
                            type: EnumFielOrder.city,
                            value: order.data.detail.data.addresses.data.state,
                            returnOnlyBoolean: true
                          })
                            ? 'text-redarauco'
                            : 'text-primary'
                        }`}
                      >
                        Ciudad
                      </p>
                      {order.getValueErrorOrCorrect({
                        type: EnumFielOrder.city,
                        value: order.data.detail.data.addresses.data.state,
                        className: 'text-black font-normal text-sm mt-1',
                        isReactNode: true
                      })}
                    </div>
                  )}

                  {order.data.detail.data.addresses.data.district && (
                    <div className='mt-4'>
                      <p
                        className={`font-normal text-sm ${
                          order.getValueErrorOrCorrect({
                            type: EnumFielOrder.district,
                            value: order.data.detail.data.addresses.data.district,
                            returnOnlyBoolean: true
                          })
                            ? 'text-redarauco'
                            : 'text-primary'
                        }`}
                      >
                        {nameDistrictForCountry(countryUser)}
                      </p>
                      {order.getValueErrorOrCorrect({
                        type: EnumFielOrder.district,
                        value: order.data.detail.data.addresses.data.district,
                        className: 'text-black font-normal text-sm mt-1',
                        isReactNode: true
                      })}
                    </div>
                  )}
                </div>
              )}

              <div className='flex flex-1 flex-col'>
                {order.data.detail.data.customer.data.phone && (
                  <div className='mt-4'>
                    <p
                      className={`font-normal text-sm ${
                        order.getValueErrorOrCorrect({
                          type: EnumFielOrder.phone,
                          value: order.data.detail.data.customer.data.phone,
                          returnOnlyBoolean: true
                        })
                          ? 'text-redarauco'
                          : 'text-primary'
                      }`}
                    >
                      Teléfono
                    </p>
                    {order.getValueErrorOrCorrect({
                      type: EnumFielOrder.phone,
                      value: order.data.detail.data.customer.data.phone,
                      className: 'text-black font-normal text-sm mt-1',
                      isReactNode: true
                    })}
                  </div>
                )}

                {order.data.detail.data.customer.data.email && (
                  <div className='mt-4'>
                    <p
                      className={`font-normal text-sm ${
                        order.getValueErrorOrCorrect({
                          value: order.data.detail.data.customer.data.email,
                          type: EnumFielOrder.email,
                          returnOnlyBoolean: true
                        })
                          ? 'text-redarauco'
                          : 'text-primary'
                      }`}
                    >
                      Correo
                    </p>
                    {order.getValueErrorOrCorrect({
                      type: EnumFielOrder.email,
                      value: order.data.detail.data.customer.data.email,
                      isReactNode: true,
                      className: 'text-black font-normal text-sm mt-1'
                    })}
                  </div>
                )}

                {order.data.detail.data.customer.data.numberDocument && (
                  <div className='mt-4'>
                    <p
                      className={`font-normal text-sm ${
                        order.getValueErrorOrCorrect({
                          type: EnumFielOrder.documentType,
                          value: order.data.detail.data.customer.data.documentType,
                          returnOnlyBoolean: true
                        })
                          ? 'text-redarauco'
                          : 'text-primary'
                      }`}
                    >
                      {order.getValueErrorOrCorrect({
                        type: EnumFielOrder.documentType,
                        value: order.data.detail.data.customer.data.documentType
                      })}
                    </p>
                    {order.getValueErrorOrCorrect({
                      type: EnumFielOrder.numberDocument,
                      value: order.data.detail.data.customer.data.numberDocument,
                      isReactNode: true,
                      className: 'text-black font-normal text-sm mt-1'
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={`bg-white rounded-xl p-4 ${order.getValidateErrorSection('shipping') ? '' : 'border-redarauco border'}`}>
            <p className='text-primary font-bold text-sm'>Datos de envío</p>

            <div className='flex mt-4'>
              <IconCustom
                className={`text-sm ${
                  order.getValueErrorOrCorrect({
                    type: EnumFielOrder.typeShipphing,
                    value: order.data.detail.data.shipphing.data.type,
                    returnOnlyBoolean: true
                  })
                    ? 'text-redarauco'
                    : 'text-primary'
                }`}
                name={stringDeliveryToIcon(Order.getMessageValue(order.data.detail.data.shipphing.data.type))}
              />
              <div className='flex justify-center items-center'>
                <p
                  className={`text-xs font-normal ml-2 ${
                    order.getValueErrorOrCorrect({
                      type: EnumFielOrder.typeShipphing,
                      value: order.data.detail.data.shipphing.data.type,
                      returnOnlyBoolean: true
                    })
                      ? 'text-redarauco'
                      : 'text-primary'
                  }`}
                >
                  {shippingTypeStringToDisplay(Order.getMessageValue(order.data.detail.data.shipphing.data.type))}
                </p>
              </div>
            </div>

            <div>
              {order.data.detail.data.shipphing.data.items && (
                <div className='mt-8 flex'>
                  <p
                    className={`font-normal w-14 text-sm ${
                      order.getValueErrorOrCorrect({
                        type: EnumFielOrder.items,
                        value: order.data.detail.data.shipphing.data.items,
                        returnOnlyBoolean: true
                      })
                        ? 'text-redarauco'
                        : 'text-black'
                    }`}
                  >
                    Items
                  </p>
                  {order.getValueErrorOrCorrect({
                    type: EnumFielOrder.items,
                    value: order.data.detail.data.shipphing.data.items,
                    isReactNode: true,
                    className: 'font-normal text-sm ml-8'
                  })}
                </div>
              )}

              {order.data.detail.data.shipphing.data.size && (
                <div
                  className={`mt-4 flex ${
                    order.getValueErrorOrCorrect({
                      type: EnumFielOrder.size,
                      value: order.data.detail.data.shipphing.data.size,
                      returnOnlyBoolean: true
                    })
                      ? 'text-redarauco'
                      : 'text-black'
                  }`}
                >
                  <p className={`font-normal w-14 text-sm`}>Tamaño</p>
                  <p className='font-normal text-sm ml-8'>
                    {order.getValueErrorOrCorrect({ type: EnumFielOrder.size, value: order.data.detail.data.shipphing.data.size })}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className={`bg-white rounded-xl p-4 ${order.getValidateErrorSection('costs') ? '' : 'border-redarauco border'}`}>
            <p className='text-primary font-bold text-sm'>Precio y costos</p>
            <p className='mt-4 text-xs text-grayarauco'>
              País: {Order.getMessageValue(order.data.detail.data.addresses.data.country)} | Divisa:{' '}
              {Order.getMessageValue(order.data.detail.data.shipphing.data.currency)}
            </p>
            <div
              className={`flex justify-between items-center mt-4 ${
                order.getValueErrorOrCorrect({
                  type: EnumFielOrder.purchasePrice,
                  value: order.data.detail.data.shipphing.data.purchasePrice,
                  returnOnlyBoolean: true
                })
                  ? 'text-redarauco'
                  : 'text-black'
              }`}
            >
              <p>Precio</p>
              <p className='font-bold text-lg'>
                {convertNumberToPrice({
                  val: parseFloat(
                    `${order.getValueErrorOrCorrect({
                      type: EnumFielOrder.purchasePrice,
                      value: order.data.detail.data.shipphing.data.purchasePrice
                    })}`
                  ),
                  country: countryUser
                })}
              </p>
            </div>

            <div className={`flex justify-between items-center mt-4`}>
              <p>Costo</p>
              <p className='font-bold text-lg'>
                {convertNumberToPrice({ val: parseFloat(`${Order.getMessageValue(order.data.deliveryCost)}`), country: countryUser })}
              </p>
            </div>

            <div className='flex justify-between items-center mt-4 border-t border-grayaraucoborder py-4'>
              <p>Costo total</p>
              <p className='font-bold text-lg'>
                {convertNumberToPrice({ val: parseFloat(`${Order.getMessageValue(`${order.data.totalAmount}`)}`), country: countryUser })}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default DetailFailedOrderOrganism;
