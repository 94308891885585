import { ReactNode } from 'react';
import { Skeleton } from 'antd';
import { Types } from '@antv/g2';

import { Column } from '@ant-design/plots';

type ColumnCustomItem = {
  value?: number;
  name?: string;
};

type ColumnCustomProps = {
  data?: ColumnCustomItem[];
  title?: string;
  titleX?: string;
  titleY?: string;
  color?: string | string[] | ((datum: any, defaultColor?: string) => string);
  classNameContainer?: string;
  seriesField?: string;
  legend?: false | Types.LegendCfg;
  header?: ReactNode;
};

const config = {
  xField: 'name',
  yField: 'value',
  label: {
    layout: [
      {
        type: 'interval-adjust-position'
      },
      {
        type: 'interval-hide-overlap'
      },
      {
        type: 'adjust-color'
      }
    ]
  }
};

const ColumnCustom = ({ data, title, titleX, titleY, color, classNameContainer, seriesField, legend, header }: ColumnCustomProps) => {
  return (
    <div
      className={`bg-white flex flex-col border-grayarauco-300 p-4 md:p-8 ${classNameContainer}`}
      style={{ borderWidth: 0.6, borderRadius: 10 }}
    >
      <h1 className='text-lg font-bold text-black'>{title}</h1>

      {header}

      {!data && <Skeleton.Avatar className='ant-skeleton-statistic w-full h-96' active={true} size={'large'} shape='square' />}
      {data && (
        <Column
          {...{ data, color, seriesField, legend }}
          yAxis={{ title: { text: titleY } }}
          xAxis={{ title: { text: titleX, autoRotate: true }, label: { autoRotate: true } }}
          {...config}
          label={{
            formatter: (props: any) => {
              //console.log('label ===> props', props);
              const valuesProps: any = props;
              return `${valuesProps.label || valuesProps.value}`;
            }
          }}
          tooltip={{
            customContent: (title, data) => {
              return (
                <div className='p-2'>
                  {data.length === 1 && (
                    <div className='flex flex-row items-center'>
                      <div className='h-2 w-2 rounded-full mr-2' style={{ backgroundColor: data[0].color }} />
                      {title}: {data[0].value}
                    </div>
                  )}
                  {data.length > 1 && (
                    <div className='flex flex-col items-center gap-3'>
                      <div className='flex w-full'>{title}</div>
                      {data.map(dat => {
                        return (
                          <div key={dat.title} className='flex flex-row items-center'>
                            <div className='h-2 w-2 rounded-full mr-2' style={{ backgroundColor: dat.color }} />
                            {dat.title}: {dat.value}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default ColumnCustom;
