import { useContext, useState } from 'react';

import { RootContext } from 'context/RootContext';

import { NameIcons, SizeScreen } from 'configs/Enums';

import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';
import DonutGraphics from 'components/molecules/graphics/Donut';
import TabBarItem from './ItemsTabs';
import TabCustom from 'components/atoms/tabs';
import TabItemCustom from 'components/atoms/tabs/TabItem';

import OrderCancel from 'models/statistic/order/OrderCancel';
import StoreStatistic from 'models/statistic/Store';
import BusinessFailedOrderTab from './business/FailedOrder';
import IconCustom from 'components/atoms/icons';
import OrderFail from 'models/statistic/order/OrderFail';
import { DashboardContext } from 'pages/menu/Dashboard';
//import SelectInterval from 'components/atoms/selects/SelectInterval';

type StatisticBusinessProps = {
  orderCancel?: OrderCancel;
  activeStores?: StoreStatistic;
  failedOrders?: OrderFail;
  onFilterFail?: (params: ParamsValueFilterStatistic) => void;
  onFilterCancel?: (params: ParamsValueFilterStatistic) => void;
  paramsFilter?: ParamsValueFilterStatistic;
};

enum EnumTabKey {
  orderFail = 'orderFail',
  orderCancel = 'orderCancel',
  storeActived = 'storeActived'
}

const StatisticBusinessTabs = ({
  orderCancel,
  activeStores,
  failedOrders,
  onFilterFail,
  //onFilterCancel,
  paramsFilter
}: StatisticBusinessProps) => {
  const { screenResolution } = useContext(RootContext);
  const { onUpdateParams } = useContext(DashboardContext);

  const [tabKeyActive, setTabKeyActive] = useState(paramsFilter?.tabActiveBusiness || EnumTabKey.orderFail);

  const onChange = (val: any) => {
    setTabKeyActive(val);
    console.log('onChange() ===> val', { val, paramsFilter });
    onUpdateParams({ ...paramsFilter, tabActiveBusiness: val });
  };

  if (screenResolution >= SizeScreen.xl) {
    return (
      <TabCustom className='px-10 pt-8' keyActive={tabKeyActive} {...{ onChange }}>
        <TabItemCustom
          tab={
            <TabBarItem
              title='Órdenes fallidas'
              valueClassName={'text-lg'}
              keyV={EnumTabKey.orderFail}
              isFirst
              keyActive={tabKeyActive}
              value={failedOrders?.data.totalOrderFail}
            />
          }
          keyA={EnumTabKey.orderFail}
        >
          <BusinessFailedOrderTab
            onFilter={params => (onFilterFail ? onFilterFail(params) : undefined)}
            params={paramsFilter}
            {...{ failedOrders }}
          />
        </TabItemCustom>

        <TabItemCustom
          tab={
            <TabBarItem
              title='Órdenes canceladas'
              valueClassName={'text-lg'}
              keyV={EnumTabKey.orderCancel}
              isFirst
              keyActive={tabKeyActive}
              value={orderCancel?.data.totalCancel}
            />
          }
          keyA={EnumTabKey.orderCancel}
        >
          <DonutGraphics
            componentLeft={
              <div className='flex flex-col justify-center items-end'>
                <div className='flex justify-center items-center w-full'>
                  <h1 className='text-4xl text-primary font-bold'>{orderCancel?.getOrderCanceled()}</h1>
                </div>
                <div className='flex flex-row items-center'>
                  <IconCustom lightOutlined name={NameIcons.cancel} className='text-4xl text-grayarauco-400' />
                  <p className='text-lg font-normal ml-2'>Órdenes canceladas</p>
                </div>
              </div>
            }
            title='Carga con órdenes fallidas'
            textCenter='Órdenes ingresadas'
            data={orderCancel?.data.items as any}
          />
        </TabItemCustom>

        <TabItemCustom
          tab={
            <TabBarItem
              title='Tiendas activas'
              valueClassName={'text-lg'}
              keyV={EnumTabKey.storeActived}
              isFirst
              keyActive={tabKeyActive}
              value={activeStores?.data.totalActives}
            />
          }
          keyA={EnumTabKey.storeActived}
        >
          <DonutGraphics title='Tiendas activas' textCenter='Marcas' data={activeStores?.data.items as any} />
        </TabItemCustom>
      </TabCustom>
    );
  }

  return (
    <div className='flex flex-col mt-4 mx-4'>
      <TabBarItem
        title='Órdenes fallidas'
        valueClassName={'text-lg'}
        keyV={EnumTabKey.orderFail}
        isFirst
        keyActive={tabKeyActive}
        value={failedOrders?.data.totalOrderFail}
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.orderFail ? 'border-primary' : 'border-grayarauco-50'}`}
        onClick={val => setTabKeyActive(val)}
      />
      {tabKeyActive === EnumTabKey.orderFail && (
        <div className='bg-white pb-4'>
          <BusinessFailedOrderTab
            onFilter={params => (onFilterFail ? onFilterFail(params) : undefined)}
            params={paramsFilter}
            {...{ failedOrders }}
          />
        </div>
      )}

      <TabBarItem
        title='Órdenes canceladas'
        valueClassName={'text-lg'}
        keyV={EnumTabKey.orderCancel}
        isFirst
        keyActive={tabKeyActive}
        value={orderCancel?.data.totalCancel}
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.orderCancel ? 'border-primary' : 'border-grayarauco-50'}`}
        onClick={val => setTabKeyActive(val)}
      />

      {tabKeyActive === EnumTabKey.orderCancel && (
        <div className='bg-white pb-4'>
          <DonutGraphics
            componentLeft={
              <div className='flex flex-col justify-center items-end'>
                <div className='flex justify-center items-center w-full'>
                  <h1 className='text-4xl text-primary font-bold'>{orderCancel?.getOrderCanceled()}</h1>
                </div>
                <div className='flex flex-row items-center'>
                  <IconCustom lightOutlined name={NameIcons.cancel} className='text-4xl text-grayarauco-400' />
                  <p className='text-lg font-normal ml-2'>Órdenes canceladas</p>
                </div>
              </div>
            }
            title='Carga con órdenes fallidas'
            textCenter='Órdenes ingresadas'
            data={orderCancel?.data.items as any}
          />
        </div>
      )}

      <TabBarItem
        title='Tiendas activas'
        valueClassName={'text-lg'}
        keyV={EnumTabKey.storeActived}
        isFirst
        keyActive={tabKeyActive}
        value={activeStores?.data.totalActives}
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.storeActived ? 'border-primary' : 'border-grayarauco-50'}`}
        onClick={val => setTabKeyActive(val)}
      />

      {tabKeyActive === EnumTabKey.storeActived && (
        <div className='bg-white pb-4'>
          <DonutGraphics title='Tiendas activas' textCenter='Marcas' data={activeStores?.data.items as any} />
        </div>
      )}
    </div>
  );
};

export default StatisticBusinessTabs;
