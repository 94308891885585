import { EnumStatusDelivery } from 'configs/Enums';

export interface StatusOrderItemModel {
  name?: string;
  value?: number;
  type?: EnumStatusDelivery;
}

export interface StatusOrderModel {
  items?: StatusOrderItemModel[];
  /** este item guarda el total de ordenes ya que el ordenes creadas no suma las canceladas. **/
  total?: number;
  allStatus?: StatusOrderItemModel[];
}

type StatusOrderItemServerModel = { [key: string]: number };
export type StatusOrderServerModel = StatusOrderItemServerModel | any;

class StatusOrder {
  data: StatusOrderModel;

  constructor(data: StatusOrderModel) {
    this.data = data;
  }

  static convertNameAndType(status: string) {
    if (status === 'createdOrder') {
      return EnumStatusDelivery.created;
    } else if (status === 'orderCanceled') {
      return EnumStatusDelivery.cancelled;
    } else if (status === 'orderDelivered') {
      return EnumStatusDelivery.delivered;
    } else if (status === 'orderNotDelivered') {
      return EnumStatusDelivery.notDelivered;
    } else if (status === 'pendingOrderDelivery') {
      return EnumStatusDelivery.pendingDelivery;
    }
  }

  static formalizeData(data: StatusOrderServerModel): StatusOrderModel {
    const items: StatusOrderItemModel[] = [];
    let total = 0;

    if (data) {
      for (const status in data) {
        const value = data[status];
        if (status === 'createdOrder') {
          total = value;
        } else {
          const name = StatusOrder.convertNameAndType(status);
          items.push({ name, value: value, type: name });
        }
      }
    }

    return { items, total, allStatus: data.allStatus };
  }
}

export default StatusOrder;
