import { useContext, useEffect, useState } from 'react';

import { TypeToast } from 'configs/Enums';

import { RootContext } from 'context/RootContext';

import FrequentQuestionOrganism from 'components/organisms/dashboard/questions/FrequentQuestion';

import FrequentQuestion from 'models/questions/FrequentQuestion';
import CategoryQuestion from 'models/questions/Category';
import HelpCenterResource from 'models/questions/Resource';

import {
  createFrequentQuestion,
  deletedFrequentQuestion,
  getAllFrequentQuestionsByCategory,
  getAllInformations,
  getCategoriesQuestions,
  getSource,
  onCreateCategoryQuestion,
  onDeletedCategoryQuestion,
  onUpdateCategoryQuestion,
  onUpdateSource,
  updateFrequentQuestion
} from 'services/app/Question';

const FrequentQuestionPage = () => {
  const { showLoading, hideLoading, showToast } = useContext(RootContext);

  const [frequentQuestions, setFrequentQuestions] = useState<FrequentQuestion[]>([]);
  const [categories, setCategories] = useState<CategoryQuestion[]>([]);
  const [categoryCurrent, setCategoryCurrent] = useState<CategoryQuestion>();
  const [informations, setInformations] = useState<FrequentQuestion[]>([]);
  const [source, setSource] = useState<HelpCenterResource>();

  const loadAllCategories = async () => {
    showLoading();
    const { status, data } = await getCategoriesQuestions();

    if (status) {
      setCategories(data);
    }
    hideLoading();
  };

  const loadAllFrequentQuestionsByCategory = async (params: { category?: CategoryQuestion }) => {
    showLoading();
    try {
      const { status, data } = await getAllFrequentQuestionsByCategory(params);
      console.log('loadAllFrequentQuestionsByCategory() ==> { status, data }', { status, data });
      if (status) {
        const fqNew = data as FrequentQuestion[];
        fqNew.forEach(fq => (fq.data.categoryDisplay = params.category?.data.name || fq.data.category));
        setFrequentQuestions(fqNew);
      }
    } catch (err) {
      console.log('loadAllFrequentQuestionsByCategory() => err', err);
    }
    hideLoading();
  };

  const loadAllInformations = async () => {
    showLoading();
    try {
      const { status, data } = await getAllInformations();
      console.log('loadAllInformations() ==> { status, data }', { status, data });
      if (status) {
        const fqNew = data as FrequentQuestion[];
        //fqNew.forEach(fq => (fq.data.categoryDisplay = params.category?.data.name || fq.data.category));
        setInformations(fqNew);
      }
    } catch (err) {
      console.log('loadAllInformations() => err', err);
    }
    hideLoading();
  };

  const onCreateFrequentQuestion = async (frequentQuestion?: FrequentQuestion) => {
    showLoading();
    try {
      if (frequentQuestion) {
        const { status, message } = await createFrequentQuestion(frequentQuestion);

        if (status) {
          console.log('onCreateFrequentQuestion() => status', { status, frequentQuestion });
          showToast({ text: message || 'Pregunta creada con éxito.', type: TypeToast.success });
          await loadAllFrequentQuestionsByCategory({ category: categoryCurrent });
        } else {
          showToast({ text: message || 'No fue posible crear.', type: TypeToast.error });
        }
      } else {
        showToast({ text: 'Por favor comparta una pregunta.', type: TypeToast.error });
      }
    } catch (err) {
      console.log('onCreateFrequentQuestion()', err);
    }
    hideLoading();
  };

  const onUpdateFrequentQuestion = async (frequentQuestion?: FrequentQuestion) => {
    showLoading();
    try {
      if (frequentQuestion) {
        const { status, message } = await updateFrequentQuestion(frequentQuestion);

        if (status) {
          console.log('onUpdateFrequentQuestion() => status', { status, frequentQuestion });
          showToast({ text: message || 'Pregunta actulizada con éxito.', type: TypeToast.success });
          await loadAllFrequentQuestionsByCategory({ category: categoryCurrent });
        } else {
          showToast({ text: message || 'No fue posible actulizar.', type: TypeToast.error });
        }
      } else {
        showToast({ text: 'Por favor comparta una pregunta.', type: TypeToast.error });
      }
    } catch (err) {
      console.log('onUpdateFrequentQuestion()', err);
    }
    hideLoading();
  };

  const onDeletedFrequentQuestion = async (frequentQuestion?: FrequentQuestion) => {
    showLoading();
    try {
      if (frequentQuestion) {
        const { status, message } = await deletedFrequentQuestion(frequentQuestion);

        if (status) {
          console.log('onDeletedFrequentQuestion() => status', { status, frequentQuestion });
          showToast({ text: message || 'Pregunta eliminada con éxito.', type: TypeToast.success });
          await loadAllFrequentQuestionsByCategory({ category: categoryCurrent });
        } else {
          showToast({ text: message || 'No fue posible eliminar.', type: TypeToast.error });
        }
      } else {
        showToast({ text: 'Por favor comparta una pregunta.', type: TypeToast.error });
      }
    } catch (err) {
      console.log('onDeletedFrequentQuestion()', err);
    }
    hideLoading();
  };

  const onCreateCategory = async (category: CategoryQuestion) => {
    console.log('onCreateCategory() =>', category);
    showLoading();
    const { status, message } = await onCreateCategoryQuestion(category);

    if (status) {
      showToast({ text: 'Categoría agregada con éxito.', type: TypeToast.success });
      loadAllCategories();
    } else {
      showToast({ text: message || 'No fue posible crear la categoría', type: TypeToast.error });
      hideLoading();
    }
  };

  const onDeletedCategory = async (category: CategoryQuestion) => {
    showLoading();

    const { status, message } = await onDeletedCategoryQuestion(category);

    if (status) {
      showToast({ text: 'Categoría eliminada con éxito.', type: TypeToast.success });
      loadAllCategories();
    } else {
      showToast({ text: message || 'No fue posible eliminar la categoría.', type: TypeToast.error });
      hideLoading();
    }
  };

  const onUpdateCategory = async (category: CategoryQuestion) => {
    showLoading();

    const { status, message } = await onUpdateCategoryQuestion(category);

    if (status) {
      showToast({ text: 'Categoría fue actualizada con éxito.', type: TypeToast.success });
      loadAllCategories();
    } else {
      showToast({ text: message || 'No fue posible actualizar la categoría.', type: TypeToast.error });
      hideLoading();
    }
  };

  const onUpdatedInformation = async (frequentQuestion: FrequentQuestion) => {
    showLoading();

    const { status, message } = await updateFrequentQuestion(frequentQuestion);

    if (status) {
      showToast({ text: 'Información fue actualizada con éxito.', type: TypeToast.success });
      loadAllInformations();
    } else {
      showToast({ text: message || 'No fue posible actualizar la información.', type: TypeToast.error });
      hideLoading();
    }
  };

  const onDeletedInformation = async (frequentQuestion: FrequentQuestion) => {
    showLoading();

    const { status, message } = await deletedFrequentQuestion(frequentQuestion);

    if (status) {
      showToast({ text: 'Información fue eliminada con éxito.', type: TypeToast.success });
      loadAllInformations();
    } else {
      showToast({ text: message || 'No fue posible eliminar la información.', type: TypeToast.error });
      hideLoading();
    }
  };

  const onCreatedInformation = async (frequentQuestion: FrequentQuestion) => {
    showLoading();

    const { status, message } = await createFrequentQuestion(frequentQuestion);

    if (status) {
      showToast({ text: 'Información fue creada con éxito.', type: TypeToast.success });
      loadAllInformations();
    } else {
      showToast({ text: message || 'No fue posible crear la información.', type: TypeToast.error });
      hideLoading();
    }
  };

  const onChangeSource = async (params: { type: 'tutorials'; value: string }) => {
    showLoading();
    console.log('onChangeSource() => params', params);
    const helpCenterResource = new HelpCenterResource({ tutorial: params.value });

    const { status, data } = await onUpdateSource(helpCenterResource);

    if (status) {
      setSource(data);
    }
    hideLoading();
  };

  const onLoadSource = async () => {
    showLoading();
    const { status, data } = await getSource();

    if (status) {
      setSource(data);
    }

    hideLoading();
  };

  useEffect(() => {
    //loadAllFrequentQuestions();
    loadAllCategories();
    onLoadSource();
  }, []);

  return (
    <FrequentQuestionOrganism
      onLoadQuestions={loadAllFrequentQuestionsByCategory}
      onLoadInformations={loadAllInformations}
      onCategoryCurrent={val => setCategoryCurrent(val)}
      {...{
        frequentQuestions,
        onUpdateFrequentQuestion,
        onCreateFrequentQuestion,
        onDeletedFrequentQuestion,
        categories,
        onCreateCategory,
        onDeletedCategory,
        onUpdateCategory,
        informations,
        onUpdatedInformation,
        onCreatedInformation,
        onDeletedInformation,
        source,
        onChangeSource
      }}
    />
  );
};

export default FrequentQuestionPage;
