import { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'antd';

import { RootContext } from 'context/RootContext';
import { EnumFileUploadStates, NameIcons, SizeScreen } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import FileOrders from 'models/files/FileOrders';
import CardUploadFile from './CardUploadFile';

type CardFileGroupProps = {
  files?: FileOrders[];
  type?: 'download' | 'error' | 'upload';
  onClose?: (files: FileOrders[]) => void;
  filesOrdersFlag?: number;
};

const validateTitle = (status?: EnumFileUploadStates, type?: 'upload' | 'download' | 'error') => {
  let typeText = 'Carga';
  if (type === 'download') {
    typeText = 'Descarga';
  } else if (type === 'error') {
    typeText = 'Archivo con formato erróneo';
  }

  if (type !== 'error') {
    if (status === EnumFileUploadStates.complete) {
      return `${typeText} de órdenes completa`;
    } else if (status === EnumFileUploadStates.inProcess) {
      return `${typeText} de órdenes en curso`;
    }
  }

  if (status === EnumFileUploadStates.error) {
    typeText = 'Error - Intente nuevamente';
  }

  return typeText;
};

const validateInfo = (status?: EnumFileUploadStates, type?: 'upload' | 'download' | 'error') => {
  let content = (
    <div>
      <h3 className='text-sm font-bold text-araucoblue-200'>Detectamos que tu archivo tiene más de 50 órdenes</h3>
      <p className='text-sm text-black mt-4'>Esto puede tardar aproximadamente 5 minutos, por favor espera un momento.</p>
      <p className='text-sm text-black mt-4'>La carga no se puede cancelar.</p>
    </div>
  );

  if (type === 'download' && status === EnumFileUploadStates.inProcess) {
    content = (
      <div>
        <h3 className='text-sm font-bold text-araucoblue-200'>Descarga en curso</h3>
        <p className='text-sm text-black mt-4'>
          Esto puede tardar unos minutos dependiendo de la cantidad de datos solicitada, por favor espera un momento.
        </p>
      </div>
    );
  } else if (type === 'error' && status === EnumFileUploadStates.inProcess) {
    content = (
      <div>
        <h3 className='text-sm font-bold text-araucoblue-200'>Error en la descarga</h3>
        <p className='text-sm text-black mt-4'>Ocurrio un error al momento de la descarga intente nuevamente.</p>
      </div>
    );
  }

  return content;
};

const validateColorBg = (type?: 'upload' | 'download' | 'error') => {
  if (type === 'upload' || type === 'download') {
    return 'bg-araucoblue-200';
  }
  return 'bg-redarauco-200';
};

const CardFileGroup = ({ files, type, onClose, filesOrdersFlag }: CardFileGroupProps) => {
  const { screenResolution } = useContext(RootContext);
  const [status, setStatus] = useState(EnumFileUploadStates.inProcess);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setStatus(
      files?.every(file => file.data.status === EnumFileUploadStates.complete)
        ? EnumFileUploadStates.complete
        : files?.every(file => file.data.status === EnumFileUploadStates.error)
        ? EnumFileUploadStates.error
        : EnumFileUploadStates.inProcess
    );
  }, [files, filesOrdersFlag]);

  return (
    <div>
      <div
        className={`${validateColorBg(type)} flex justify-between items-center py-2 px-4 rounded-t-xl`}
        style={{ maxWidth: '432px', width: screenResolution === SizeScreen.sm ? 'auto' : '432px' }}
      >
        <p className='text-sm font-bold text-white'>{validateTitle(status, type)}</p>

        <div>
          <IconCustom
            name={isOpen ? NameIcons.arrowUp : NameIcons.arrowDown}
            className='text-white cursor-pointer select-none'
            onClick={() => setIsOpen(!isOpen)}
          />

          <Tooltip
            visible={status !== EnumFileUploadStates.inProcess ? false : undefined}
            popupVisible={status !== EnumFileUploadStates.inProcess}
            trigger={['hover']}
            overlayClassName={'ant-tooltip-content-neutral'}
            getPopupContainer={triggerNode => {
              console.log('getPopupContainer() => triggerNode', triggerNode);
              return triggerNode;
            }}
            title={validateInfo(status, type)}
          >
            <button>
              <IconCustom
                name={status === EnumFileUploadStates.inProcess ? NameIcons.info : NameIcons.close}
                className='text-white cursor-pointer select-none'
                onClick={() => {
                  if (onClose && (status === EnumFileUploadStates.complete || status === EnumFileUploadStates.error)) {
                    onClose ? onClose(files || []) : undefined;
                  }
                }}
              />
            </button>
          </Tooltip>
        </div>
      </div>

      <div className='flex flex-col'>
        {files &&
          isOpen &&
          files.length > 0 &&
          files.map((file, index) => <CardUploadFile index={index} key={file.data.id} status={file.data.status} fileOrders={file} />)}
      </div>
    </div>
  );
};

export default CardFileGroup;
