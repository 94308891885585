import Order, { DataTableOrder } from 'models/order';

type CellCustomerProps = {
  order?: DataTableOrder;
  ignoreError?: boolean;
};

const CellCustomer = ({ order, ignoreError }: CellCustomerProps) => {
  return (
    <div className='flex flex-col my-2 ml-4 w-full'>
      <div className='flex flex-col pr-2 justify-start items-start'>
        <h3 className='text-xs font-bold text-black'>
          {ignoreError && Order.isParameterInvalid(order?.client?.fullName) ? '' : order?.client?.fullName}
        </h3>

        {order && (
          <div className='flex flex-col mt-2'>
            {order.client && order.client.numDocument && (
              <div className='flex flex-row items-center'>
                <p className='text-xs text-grayarauco'>{Order.isParameterInvalid(order?.client?.numDocument)}</p>
              </div>
            )}

            {order.client && order.client.phone && (
              <div className='flex flex-row items-center mt-2'>
                <p className='text-xs text-grayarauco'>{order?.client?.phone}</p>
              </div>
            )}

            {order.client && order.client.email && (
              <div className='flex flex-row items-center mt-2'>
                <p className='text-xs text-grayarauco'>{order?.client?.email}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellCustomer;
