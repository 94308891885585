import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { StyleButton } from 'configs/Enums';

import ButtonCustom from 'components/atoms/buttons';
import IconCustom from 'components/atoms/icons';

type DirectAccessCardProps = {
  iconName?: string;
  textButton?: string;
  styleButton?: StyleButton;
  pathRoute: string;
  body?: ReactNode;
};

const DirectAccessCard = ({ iconName, textButton, pathRoute, body }: DirectAccessCardProps) => {
  return (
    <div className='flex justify-center items-center flex-col flex-1 px-8 py-10 rounded-lg bg-white'>
      <div className='flex justify-center items-center flex-col flex-1'>
        {body}
        {!body && <IconCustom name={iconName} />}
      </div>

      <div className='flex justify-center items-center w-full'>
        <Link to={pathRoute} className='w-52'>
          <ButtonCustom className='w-full' styleButton={StyleButton.outlinePrimary}>
            {textButton}
          </ButtonCustom>
        </Link>
      </div>
    </div>
  );
};

export default DirectAccessCard;
