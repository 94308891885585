import { useState, useContext, useEffect } from 'react';

import { RootContext } from 'context/RootContext';
import { RouterInContext } from 'context/RouterIn';
import { NameIcons, SizeScreen, StyleButton } from 'configs/Enums';

import FormMolecule from 'components/molecules/forms';
import BoardCustom from 'components/molecules/board';
import ModalCustom from 'components/molecules/modals';
import FrequentQuestionCard from 'components/molecules/items/cards/FrequentQuestion';
import SelectCustom from 'components/atoms/selects';

import FrequentQuestion, { FrequentQuestionModel } from 'models/questions/FrequentQuestion';
import { FormItemModel } from 'models/form';
import { ExpanderComponentProps } from 'react-data-table-component';
import ButtonCustom from 'components/atoms/buttons';
import IconCustom from 'components/atoms/icons';
import InputCustom from 'components/atoms/inputs';
import CategoryQuestion from 'models/questions/Category';
import FrequentQuestionBoard from '../items/cards/FrequentQuestionBoard';

type BoardFrequentQuestionProps = {
  frequentQuestions: FrequentQuestion[];
  onUpdateFrequentQuestion: (form: any) => void;
  onDeletedFrequentQuestion: (form: any) => void;
  onCreateFrequentQuestion: (fq: FrequentQuestion) => void;
  categories?: CategoryQuestion[];
  category?: CategoryQuestion;
};

const BoardFrequentQuestion = ({
  frequentQuestions,
  onUpdateFrequentQuestion,
  onDeletedFrequentQuestion,
  onCreateFrequentQuestion,
  categories,
  category
}: BoardFrequentQuestionProps) => {
  const { showModalQuestion, screenResolution } = useContext(RootContext);
  const { hContainer } = useContext(RouterInContext);

  const [showCreateQuestion, setShowCreateQuestion] = useState<boolean>(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [frequentQuestionCurrent, setFrequentQuestionCurrent] = useState<FrequentQuestionModel>();
  const [itemsForm, setItemsForm] = useState<FormItemModel[]>([]);

  const showModalUpdate = (frequentQuestion: FrequentQuestion) => {
    const updateQuestionCurrent = { ...frequentQuestion.data };
    setFrequentQuestionCurrent(updateQuestionCurrent);
    //console.log('delivery', deliveryScheduleCurrent);
    setItemsForm(FrequentQuestion.formFieldsAdd({ values: updateQuestionCurrent }));
    setIsModalUpdate(true);
  };

  const showModalDelete = (frequentQuestion: FrequentQuestion) => {
    setFrequentQuestionCurrent(undefined);
    showModalQuestion({
      title: '¿Desea eliminar la pregunta?',
      options: [{ text: 'Si', onAction: () => onDeletedFrequentQuestion(frequentQuestion) }, { text: 'No' }]
    });
  };

  // data provides access to your row data
  const expandedComponent: React.FC<ExpanderComponentProps<FrequentQuestion>> = ({ data }) => {
    return (
      <div className='bg-gray-100 p-4'>
        <FrequentQuestionCard frequentQuestion={data} />
      </div>
    );
  };

  useEffect(() => {
    setFrequentQuestionCurrent({ question: '', answer: '', category: category?.data.id });
    console.log('useEffect() ==> { question, answer, category }', { question: '', answer: '', category, name: category?.data.id });
  }, [category]);

  return (
    <div>
      {screenResolution > SizeScreen.md && (
        <BoardCustom
          data={frequentQuestions}
          columns={FrequentQuestion.headerTable({ showModalUpdate, onDeleted: showModalDelete })}
          persistTableHead
          expandableRowsComponent={expandedComponent}
          expandableRows
          title={
            <div className='flex items-center'>
              <p className='text-xl font-bold'>Pregunta</p>
              <ButtonCustom className='ml-3' onClick={() => setShowCreateQuestion(true)}>
                <IconCustom name={NameIcons.add} />
              </ButtonCustom>
            </div>
          }
        />
      )}
      {screenResolution <= SizeScreen.md && (
        <div className='flex items-center'>
          <p className='text-xl font-bold'>Pregunta</p>
          <ButtonCustom className='ml-3' onClick={() => setShowCreateQuestion(true)}>
            <IconCustom name={NameIcons.add} />
          </ButtonCustom>
        </div>
      )}
      {screenResolution <= SizeScreen.md &&
        frequentQuestions &&
        frequentQuestions.length > 0 &&
        frequentQuestions.map(fq => {
          return <FrequentQuestionBoard key={fq.data.id} onUpdated={showModalUpdate} onDeleted={showModalDelete} {...{ fq }} />;
        })}

      {/** modal para actulizar el horario de corte **/}
      {isModalUpdate && (
        <ModalCustom isVisible={isModalUpdate} notFullScreen className='z-50' onClose={() => setIsModalUpdate(false)}>
          <div className='bg-white flex flex-col px-5 md:px-10 py-4 overflow-auto' style={{ height: hContainer }}>
            <div className='flex justify-start flex-col gap-4'>
              <button
                onClick={() => {
                  setIsModalUpdate(false);
                  setFrequentQuestionCurrent(undefined);
                }}
                className='text-sm font-normal self-start'
              >
                {'< Volver'}
              </button>
              <p className='text-2xl sm:text-3xl text-black font-bold'>Editar pregunta</p>
            </div>
            <div className='flex flex-1 z-50'>
              <FormMolecule
                classNameContainer='flex flex-1 flex-col xl:w-full'
                classNameContent='md:grid-cols-3 sm:grid-cols-2'
                //title='Editar pregunta frecuente'
                items={itemsForm}
                textBtnSubmit='Actualizar'
                textBtnCancel='Cancelar'
                onChangeForm={form => {
                  const frequentQuestionCurrentNew = { ...frequentQuestionCurrent, ...form };
                  setFrequentQuestionCurrent(frequentQuestionCurrentNew);
                }}
                onCancel={() => {
                  setIsModalUpdate(false);
                  setFrequentQuestionCurrent(undefined);
                  setItemsForm([]);
                }}
                onSubmit={() => {
                  if (frequentQuestionCurrent) {
                    onUpdateFrequentQuestion(new FrequentQuestion(frequentQuestionCurrent));
                  }
                  setIsModalUpdate(false);
                  setFrequentQuestionCurrent(undefined);
                  setItemsForm([]);
                }}
              />
            </div>
          </div>
        </ModalCustom>
      )}

      {showCreateQuestion && (
        <ModalCustom isVisible={showCreateQuestion} styleContent={{ padding: 0 }} onClose={() => setShowCreateQuestion(false)}>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row justify-between items-center p-4 border-grayarauco-200' style={{ borderBottomWidth: 1 }}>
              <h1 className='text-xl sm:text-2xl font-bold'>Agregar pregunta</h1>
              <IconCustom name={NameIcons.close} className='text-4xl text-grayarauco-400' onClick={() => setShowCreateQuestion(false)} />
            </div>

            <div className='flex flex-col md:flex-row gap-8 p-4'>
              <InputCustom
                label='Pregunta'
                value={frequentQuestionCurrent?.question}
                className='w-full sm:w-96'
                onChange={val => setFrequentQuestionCurrent({ ...frequentQuestionCurrent, question: val.target.value })}
              />
              <SelectCustom
                label='Categoría'
                value={frequentQuestionCurrent?.category}
                data={categories?.map(cat => ({ value: cat.toString(), label: cat.data.name, item: cat })) || []}
                onChange={val => {
                  console.log('onChange() ==> val', val);
                  setFrequentQuestionCurrent({ ...frequentQuestionCurrent, category: val.data.id });
                }}
                style={{ width: screenResolution <= SizeScreen.sm ? '100%' : 200 }}
              />
            </div>

            <div className='flex flex-col justify-center items-center gap-4 md:justify-between p-4 md:flex-row'>
              <ButtonCustom
                styleButton={StyleButton.outlinePrimary}
                className='w-52'
                onClick={() => {
                  setShowCreateQuestion(false);
                  setFrequentQuestionCurrent({ question: '', answer: '', category: category?.data.id });
                }}
              >
                Cancelar
              </ButtonCustom>
              <ButtonCustom
                className='w-52'
                onClick={() => {
                  if (frequentQuestionCurrent) {
                    onCreateFrequentQuestion(new FrequentQuestion(frequentQuestionCurrent));
                    setShowCreateQuestion(false);
                    setFrequentQuestionCurrent({ question: '', answer: '', category: category?.data.id });
                  }
                }}
              >
                Guardar
              </ButtonCustom>
            </div>
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default BoardFrequentQuestion;
