import { useContext } from 'react';

import { TypeToast } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';
import BoardCutOffSchedule from 'components/molecules/board/CutOffSchedule';

import DeliverySchedule from 'models/delivery/DeliverySchedule';

import { createDeliveryConfig, updateDeliveryConfigById, deletedDeliveryConfigById } from 'services/app/Delivery';

type CutOffScheduleSettingOrganismProps = {
  loadSchedulesConfig: (commerce?: string) => void;
  schedules: DeliverySchedule[];
  title?: string;
  onUpdateSchedules?: ({ type, value }: { type: 'update' | 'delete' | 'create'; value: DeliverySchedule }) => void;
  isNewShop?: boolean;
  /** si es full page **/
  isFullPage?: boolean;
  commerce?: string;
};

const CutOffScheduleSettingOrganism = ({
  schedules,
  onUpdateSchedules,
  loadSchedulesConfig,
  title,
  isNewShop,
  isFullPage = true,
  commerce
}: CutOffScheduleSettingOrganismProps) => {
  const { showLoading, hideLoading, showToast } = useContext(RootContext);
  const { countryUser } = useContext(AuthContext);

  const onCreateCutOffTimeSetting = async (deliverySchedule: DeliverySchedule) => {
    showLoading();
    console.log('onCreateCutOffTimeSetting() ==>', { isNewShop });
    if (isNewShop && onUpdateSchedules) {
      onUpdateSchedules({ type: 'create', value: deliverySchedule });
      hideLoading();
    } else {
      if (commerce) {
        deliverySchedule.data.commerce = commerce;
      }
      deliverySchedule.updateCountry(countryUser);
      const { status, message } = await createDeliveryConfig(deliverySchedule);

      if (status) {
        showToast({ text: message || 'Creado con éxito.', type: TypeToast.success });
        loadSchedulesConfig();
      } else {
        showToast({ text: message || 'No fue posible crear.', type: TypeToast.error });
        hideLoading();
      }
    }
  };

  const onUpdateCutOffTimeSetting = async (deliverySchedule: DeliverySchedule) => {
    showLoading();
    if (isNewShop && onUpdateSchedules) {
      onUpdateSchedules({ type: 'update', value: deliverySchedule });
      hideLoading();
    } else {
      //console.log('onUpdateCutOffTimeSetting() ==>', deliverySchedule);
      const { status, message } = await updateDeliveryConfigById(deliverySchedule);

      if (status) {
        showToast({ text: message || 'Actualizado con éxito.', type: TypeToast.success });
        loadSchedulesConfig();
      } else {
        showToast({ text: message || 'No fue posible actualizar.', type: TypeToast.error });
        hideLoading();
      }
    }
  };

  const onDeletedCutOffTimeSetting = async (deliverySchedule: DeliverySchedule) => {
    showLoading();

    if (isNewShop && onUpdateSchedules) {
      onUpdateSchedules({ type: 'delete', value: deliverySchedule });
      hideLoading();
    } else {
      //console.log('onDeletedCutOffTimeSetting() ==>', deliverySchedule);
      const { status, message } = await deletedDeliveryConfigById(deliverySchedule);

      if (status) {
        showToast({ text: message || 'Eliminado con éxito.', type: TypeToast.success });
        loadSchedulesConfig();
      } else {
        showToast({ text: message || 'No fue posible eliminar.', type: TypeToast.error });
        hideLoading();
      }
    }
  };

  if (isFullPage) {
    return (
      <MenuSessionContainer notStyleContainer classNameContainer='flex flex-col flex-1'>
        <BoardCutOffSchedule {...{ onUpdateCutOffTimeSetting, onCreateCutOffTimeSetting, onDeletedCutOffTimeSetting, schedules, title }} />
      </MenuSessionContainer>
    );
  }
  return (
    <BoardCutOffSchedule {...{ onUpdateCutOffTimeSetting, onCreateCutOffTimeSetting, onDeletedCutOffTimeSetting, schedules, commerce }} />
  );
};

export default CutOffScheduleSettingOrganism;
