import { useContext, useEffect, useState } from 'react';
import { Types } from '@antv/g2';

import { Skeleton } from 'antd';
import { Line } from '@ant-design/plots';

import { SizeScreen, TypeDelivery, TypeTimeInterval } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import SelectCustom from 'components/atoms/selects';
import Delivery from 'models/Delivery';

type MultiLineItem = {
  date?: string;
  value?: number;
  type?: string;
};

type MultiLineProps = {
  data?: MultiLineItem[];
  classNameContainer?: string;
  title?: string;
  titleX?: string;
  titleY?: string;
  values?: {
    intervalTime?: TypeTimeInterval.day;
    typesDelivery?: Delivery[];
  };
  onFilter?: (params: { isGroupTypeDelivery?: boolean; timeInterval?: TypeTimeInterval }) => void;
  flagFilter?: number;
  legend?: false | Types.LegendCfg;
};

const config = {
  xField: 'date',
  yField: 'value',
  seriesField: 'type'
};

const intervalTime = [
  {
    label: 'Día',
    value: TypeTimeInterval.day,
    item: TypeTimeInterval.day
  },
  {
    label: 'Semana',
    value: TypeTimeInterval.week,
    item: TypeTimeInterval.week
  },
  {
    label: 'Mes',
    value: TypeTimeInterval.month,
    item: TypeTimeInterval.month
  }
];

const typeDelivery = [
  {
    label: 'Delivery y Pickup',
    value: 'true',
    item: 'true'
  },
  {
    label: 'Todos',
    value: 'false',
    item: 'false'
  }
];

const convertTypeAtColor = (type?: string) => {
  if (type === TypeDelivery.pickup) {
    return '#00379B';
  }
  if (type === TypeDelivery.delivery || type === TypeDelivery.expressDelivery || type === TypeDelivery.express) {
    return '#6E9B3C';
  }
  if (type === TypeDelivery.sameDay || type === TypeDelivery.sameday) {
    return '#BE231E';
  }
  if (type === TypeDelivery.nextDay || type === TypeDelivery.nextday) {
    return '#FBBF24';
  }
  return '';
};

const MultiLineEvolutionOrders = ({ data, title, onFilter, classNameContainer, titleX, titleY, legend }: MultiLineProps) => {
  const { screenResolution } = useContext(RootContext);

  const [typeDeliveryCurrent, setTypeDeliveryCurrent] = useState<string>(typeDelivery[0].value);
  const [intervalTimeCurrent, setIntervalTimeCurrent] = useState(TypeTimeInterval.day);

  const onChange = ({ type, value }: { type?: 'interval' | 'typeDelivery'; value?: any }) => {
    if (type === 'typeDelivery') {
      setTypeDeliveryCurrent(value || typeDelivery[0].value);
    } else if (type === 'interval') {
      setIntervalTimeCurrent((value as any) || TypeTimeInterval.day);
    }

    if (onFilter) {
      onFilter({
        isGroupTypeDelivery: type === 'typeDelivery' ? (value === 'true' ? true : false) : typeDeliveryCurrent === 'true' ? true : false,
        timeInterval: type === 'interval' ? (value as any) : intervalTimeCurrent
      });
    }
  };

  useEffect(() => {
    if (onFilter) {
      onFilter({ isGroupTypeDelivery: typeDeliveryCurrent === 'true' ? true : false, timeInterval: intervalTimeCurrent });
    }
  }, []);

  return (
    <div className={`bg-white ${classNameContainer}`}>
      <div
        className='bg-white flex flex-col border-grayarauco-300 px-4 py-8 md:py-8 md:px-8'
        style={{ borderWidth: 0.6, borderRadius: 10 }}
      >
        <h1 className='text-lg font-bold text-black'>{title}</h1>
        {onFilter && (
          <div className='flex items-start md:items-center gap-4 md:gap-8 relative mt-4 mb-8 flex-col md:flex-row'>
            <SelectCustom
              data={intervalTime}
              style={{ width: 204 }}
              label={'Intervalo de tiempo'}
              onChange={val => onChange({ type: 'interval', value: val })}
              value={intervalTimeCurrent}
              getPopupContainer={(triggerNode: any) => {
                console.log('getPopupContainer() => triggerNode', triggerNode);
                return triggerNode;
              }}
            />
            <SelectCustom
              data={typeDelivery}
              style={{ width: 204 }}
              label={'Tipo de servició'}
              onChange={val => onChange({ type: 'typeDelivery', value: val })}
              value={typeDeliveryCurrent}
              getPopupContainer={(triggerNode: any) => {
                console.log('getPopupContainer() => triggerNode', triggerNode);
                return triggerNode;
              }}
            />
          </div>
        )}

        {!data && <Skeleton.Avatar className='ant-skeleton-statistic w-full h-96' active={true} size={'large'} shape='square' />}

        {data && (
          <Line
            {...{ data, legend }}
            color={(datum: any) => {
              return convertTypeAtColor(datum.type);
            }}
            label={{
              autoRotate: false
            }}
            yAxis={{
              title: {
                text: titleY
              }
            }}
            xAxis={{
              nice: true,
              title: {
                text: titleX
              },
              label: {
                autoRotate: true,
                style: {
                  fill: '#aaa',
                  fontSize: 10
                },
                formatter: name => name
              }
            }}
            legend={{
              position: screenResolution < SizeScreen.md ? 'top' : 'bottom'
            }}
            {...config}
          />
        )}
      </div>
    </div>
  );
};

export default MultiLineEvolutionOrders;
