import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthContext } from 'context/AuthContext';
import { PathRoute } from 'configs/Enums';

const LoadingPages = ({ onlyLoad }: { onlyLoad?: boolean }) => {
  const { authStatus } = useContext(AuthContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const navigateInit = () => {
    console.log('loadingPages ===>', authStatus);
    const redirectNavigation = () => {
      if (authStatus.isAuthenticated) {
        console.log('navigateInit() ==> showRoutesMenu => ', authStatus.user?.enabledRoutes()[0]);
        navigate(authStatus.user?.enabledRoutes()[0] || PathRoute.uploadFile);
      } else {
        navigate(PathRoute.signin);
      }
    };

    redirectNavigation();
  };

  useEffect(() => {
    if (!onlyLoad) {
      const isRestart = params.get('restart')?.includes('true');
      console.log('userEffect ===> ', isRestart);
      if (isRestart) {
        navigateInit();
      }
      return () => {
        if (!isRestart) {
          navigateInit();
        }
      };
    }
  }, [authStatus, params, onlyLoad]);

  return (
    <div className='flex h-screen justify-center items-center'>
      <div className='spinner'></div>
    </div>
  );
};

export default LoadingPages;
