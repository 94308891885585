import { useContext } from 'react';
import { Tabs } from 'antd';

import { RootContext } from 'context/RootContext';
import { EnumInvoiceSection, NameIcons, SizeScreen } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

const { TabPane } = Tabs;

interface BillingHeaderMoleculeProps {
  onChangeSeccion: (section: EnumInvoiceSection) => void;
  section: EnumInvoiceSection;
  classNameContainer?: string;
}

const BillingHeaderMolecule = ({ onChangeSeccion, section, classNameContainer }: BillingHeaderMoleculeProps) => {
  const { screenResolution } = useContext(RootContext);

  const onChangeTab = (activeKey: string) => {
    onChangeSeccion(activeKey as EnumInvoiceSection);
  };

  return (
    <div className={`flex w-full bg-white ${classNameContainer}`}>
      <div className='flex flex-col w-full'>
        {screenResolution <= SizeScreen.xl && (
          <div className='flex justify-center items-center self-start'>
            <h1 className='text-3xl font-bold'>Facturación</h1>
            <IconCustom name={NameIcons.tune} className='ml-1 text-2xl text-grayarauco-400 cursor-pointer' />
          </div>
        )}
        <Tabs
          centered={true}
          size='large'
          tabBarExtraContent={{
            left:
              screenResolution > SizeScreen.xl ? (
                <div className='flex justify-center items-center'>
                  <h1 className='text-3xl font-bold'>Facturación</h1>
                  <IconCustom name={NameIcons.tune} className='ml-1 text-2xl text-grayarauco-400' />
                </div>
              ) : undefined
          }}
          activeKey={section}
          defaultActiveKey={EnumInvoiceSection.billingProgress}
          className='flex flex-1 w-full text-lg tab-statistics'
          onChange={onChangeTab}
        >
          <TabPane tab='Facturación en curso' className='text-xs md:text-lg' key={EnumInvoiceSection.billingProgress} />
          <TabPane tab='Facturación histórica' className='text-xs md:text-lg' key={EnumInvoiceSection.historicalBilling} />
        </Tabs>
      </div>
    </div>
  );
};

export default BillingHeaderMolecule;
