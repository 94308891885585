import { ReactNode } from 'react';

type FormSessionLayoutProps = {
  children?: ReactNode;
  title?: string | ReactNode;
};

const FormSessionLayout = ({ children, title }: FormSessionLayoutProps) => {
  return (
    <div className=''>
      {title && typeof title === 'string' && <h3 className='text-xl font-bold mb-2'>{title}</h3>}
      {title && typeof title !== 'string' && title}
      <div className='transition ease-in-out grid gap-y-4 gap-x-10 grid-cols-1 mb-2 md:grid-cols-4 sm:grid-cols-2 md:mb-10'>{children}</div>
    </div>
  );
};

export default FormSessionLayout;
