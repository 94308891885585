import { DashboardOrganismSections, NameIcons } from 'configs/Enums';

import { numberAtPrice, reducerDelivery, stringDeliveryToIcon } from 'helpers/Converts';

import KpiGeneral from 'models/statistic/order/KpiGeneral';
import TypeDeliveryDropdown from './TypeDelivery';

const StatisticDropDown = ({ data, section }: { data?: KpiGeneral; section?: DashboardOrganismSections }) => {
  return (
    <div className='flex mx-5 md:mx-10 mt-8 relative flex-col md:flex-row'>
      <TypeDeliveryDropdown
        nameIcon={NameIcons.schedule}
        title={'Promedio de entrega'}
        value={data?.data.time.avg}
        breakdowns={data?.data.time.delivery
          ?.filter(deli => (section === DashboardOrganismSections.general ? true : reducerDelivery(deli.type) === `${section}`))
          ?.map(deli => ({
            title: deli.name,
            value: `${deli.value}`,
            icon: stringDeliveryToIcon(deli.type)
          }))}
        {...{ section }}
      />

      <TypeDeliveryDropdown
        nameIcon={NameIcons.thumbUp}
        title={'Tasa efectividad'}
        value={`${data?.data.effectivity.avg || 0} %`}
        className='mx-0 md:mx-1'
        breakdowns={data?.data.effectivity.delivery
          ?.filter(deli => (section === DashboardOrganismSections.general ? true : reducerDelivery(deli.type) === `${section}`))
          ?.map(deli => ({
            title: deli.name,
            value: `${(deli.value || 0).toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            })} %`,
            icon: stringDeliveryToIcon(deli.type)
          }))}
        {...{ section }}
      />

      <TypeDeliveryDropdown
        nameIcon={NameIcons.ticket}
        title={'Ticket promedio'}
        value={numberAtPrice((data?.data.ticket.avg as number) || 0)}
        breakdowns={data?.data.ticket.delivery
          ?.filter(deli => (section === DashboardOrganismSections.general ? true : reducerDelivery(deli.type) === `${section}`))
          .map(deli => ({
            title: deli.name,
            value: numberAtPrice((deli.value as number) || 0),
            icon: stringDeliveryToIcon(deli.type)
          }))}
        {...{ section }}
      />
    </div>
  );
};

export default StatisticDropDown;
