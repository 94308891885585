import { EnumCountries } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import DistrictProvider from 'services/api/District';
//getDistricts
export const getCitiesByTypeDelivery = async (params: { typeDelivery: string; country: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await DistrictProvider.getCitiesByTypeDelivery(params);
    console.log('loadCitiesByTypeDelivery() ==> { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data.city && data.city.length > 0) {
        return { status: true, data: (data.city as string[]).sort((a, b) => (a > b ? 1 : -1)) };
      }
      if (params.country === EnumCountries.col) {
        return { status: true, data: ['Bogotá'] };
      }
      return { status: false, message: 'No hay ciudades.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener las ciudades.' };
    }
    return { status: false, message: 'No fue posible obtener las ciudades.' };
  } catch (err) {
    return { status: false, message: `No fue posible obtener las ciudades: ${(err as Error).message}` };
  }
};

export const getDistrictsByCity = async (params: { typeDelivery: string; country: string; city: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await DistrictProvider.getDistrictsByCity(params);

    if (status === 200) {
      if (data.district && data.district.length > 0) {
        return { status: true, data: (data.district as string[]).sort((a, b) => (a > b ? 1 : -1)) };
      }
      return { status: false, message: 'No hay districtos.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener los distritos.' };
    }

    return { status: false, message: 'No fue posible obtener los distritos.' };
  } catch (err) {
    return { status: false, message: `No fue posible obtener los distritos: ${(err as Error).message}` };
  }
};

export const getCities = async (params: { typeDelivery: string; country: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await DistrictProvider.getCities(params);

    if (status === 200) {
      if (data.city && data.city.length > 0) {
        return { status: true, data: (data.city as string[]).sort((a, b) => (a > b ? 1 : -1)) };
      }
      return { status: false, message: 'No hay ciudades.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener las ciudades.' };
    }

    return { status: false, message: 'No fue posible obtener las ciudades.' };
  } catch (err) {
    return { status: false, message: `No fue posible obtener las ciudades: ${(err as Error).message}` };
  }
};
