//import { useEffect } from 'react';

import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import Country from 'models/Country';
import User from 'models/User';
import Store from 'models/store';
import UserRole from 'models/user/UserRole';
import BoardUser from 'components/molecules/board/User';
import { UserBoardParams } from 'components/molecules/filters/UserBoardFilter';

type CreateUserOrganismProps = {
  onCreateUser: (user: User) => void;
  onUpdateUser: (user: User) => void;
  onDeletedUser: (user: User) => void;
  countries: Country[];
  shops: Store[];
  //values?: UserModel;
  roles: UserRole[];
  users: User[];
  onFilter: (params?: UserBoardParams) => void;
  flag?: number;
};

const CreateUserOrganism = ({
  shops,
  users,
  onCreateUser,
  roles,
  onUpdateUser,
  onDeletedUser,
  onFilter,
  flag
}: CreateUserOrganismProps) => {
  return (
    <MenuSessionContainer notStyleContainer>
      <BoardUser {...{ users, shops, roles, onCreateUser, onUpdateUser, onDeletedUser, onFilter, flag }} />
    </MenuSessionContainer>
  );
};

export default CreateUserOrganism;
