import XLSX from 'xlsx';
import { Duration, Moment } from 'moment';

import {
  EnumAlert,
  EnumCountries,
  EnumDeliverySummaryStatus,
  EnumDocumentType,
  EnumStatesJanis,
  EnumStatusDelivery,
  EnumTypeDownloadFile,
  EnumTypeFile,
  FormatDate,
  FormatTime,
  NameIcons,
  TypeDelivery,
  TypeDeliveryDisplay,
  TypeTimeInterval,
  UnitDate
} from 'configs/Enums';
import moment from 'configs/MomentJS';
import numeral, { configNumeralLocal } from 'configs/NumeralJS';
import { MonthsOfYears } from 'configs/Constants';
import Country from 'models/Country';

//const XLSX = require('xlsx');
/**
 * humaniza el peso de un archivo
 */
export const humanizeSizeFile = (size: number): string => {
  return size > 1024 ? (size > 1048576 ? Math.round(size / 1048576) + 'mb' : Math.round(size / 1024) + 'kb') : size + 'b';
};

export const numberAtPrice = (num?: number): string => {
  try {
    return numeral(num).format('$ 0,0[.]00');
  } catch (err) {
    console.log('numberAtPrice()', err);
    return '$ 0';
  }
};

/**
 * humaniza el un valor numérico.
 */
export const numberAtNumberH = (num?: number): string => {
  try {
    return numeral(num).format('0,0');
  } catch (err) {
    console.log('numberAtNumberH()', err);
    return '0';
  }
};

/** convertir string en un formato de fecha especifico **/
export const stringAtDate = (value?: string, format?: FormatDate, formatIn?: FormatDate): any => {
  try {
    if (value) {
      if (format && format !== FormatDate.none) {
        const dateFormat = moment(value, formatIn).format(format || FormatDate.yyyymmdd);
        if (moment(dateFormat, format || FormatDate.yyyymmdd).isValid()) {
          return dateFormat;
        }
      } else {
        const dateFormat = moment(value, formatIn);
        if (dateFormat.isValid()) {
          return dateFormat;
        }
      }
      //console.log('stringAtDate() =>', { dateFormat, isValid: moment.isMoment(moment(dateFormat)), value });
      return undefined;
    }
    return undefined;
  } catch (err) {
    console.log('stringAtDate() ==>', err);
    return undefined;
  }
};

/** convertir string en un formato de tiempo especifico **/
export const stringAtTime = (value?: string, format?: FormatTime): string | undefined => {
  try {
    if (value) {
      //console.log('stringAtTime() ==>', { result: moment(value).format(format || FormatTime.hmmssa), format, value });
      return moment(value).format(format || FormatTime.hmmssa);
    }
    return undefined;
  } catch (err) {
    console.log('stringAtDate() ==>', err);
    return undefined;
  }
};

export const stringAtMoment = (value?: string, format?: FormatTime): Moment | undefined => {
  try {
    if (value) {
      //console.log('stringAtMoment() ==> { value, format }', { value, format, moment: moment(value, format) });
      return moment(value, format);
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const stringAtDuration = ({ value, unit }: { value?: string; unit?: UnitDate }): Duration | undefined => {
  try {
    if (value) {
      console.log('stringAtDuration() => ', { moment: moment.duration(value, unit), value, unit });
      return moment.duration(value, unit);
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

/**
 * convierte la hora que llega del serividor a la zona horaria del usuario que consulta
 **/
export const convertStringDateUTCAtStringDateLocal = ({
  value,
  formatOut,
  formatIn
}: {
  value?: string;
  formatIn?: FormatDate;
  formatOut?: FormatDate;
}) => {
  try {
    if (value) {
      return moment(value, formatIn).add(moment().utcOffset(), 'minutes').format(formatOut);
    }
    return value;
  } catch (err) {
    return value;
  }
};

/**
 * delivery type
 * para que pueda ser procesado por el back al enviarlo
 */
export const typeDeliveryAtTypeDeliveryServer = (typeDelivery: string) => {
  if (typeDelivery === TypeDelivery.pickupStore) {
    return TypeDelivery.pickup;
  } else if (typeDelivery === 'express_delivery') {
    return 'express';
  }
  return typeDelivery.replace('_', '');
};

/**
 * tipo de docuemento
 * para que pueda ser procesado en el back al crear el servicio
 */
export const typeDocAtTypeDocServer = (typeDoc: string, country?: string): string => {
  if (typeDoc === EnumDocumentType.ci && country === EnumCountries.cl) {
    return 'RUT';
  } else if (typeDoc === EnumDocumentType.ce && country === EnumCountries.cl) {
    return 'RUT';
  }
  return typeDoc;
};

/**
 * CSV
 */
export const convertXlsAtCsv = async (file: File) => {
  try {
    const workBook = XLSX.read(await file.arrayBuffer(), { raw: true });
    const fileConvert = await XLSX.write(workBook, { bookType: 'csv', type: 'string' });

    //console.log('convertXlsAtCsv() => fileConvert', { fileConvert, countLineBreak: (fileConvert.match(/\n/g) || []).length });
    if (fileConvert) {
      console.log('convertXlsAtCsv() => file', { onSplitCsv: onSplitCsv(fileConvert, file), fileConvert: fileConvert });
      return onSplitCsv(fileConvert, file);
      //return {
      //file: new File([fileConvert], `${file.name.replace('.xlsx', '.csv').replace('.xls', '.csv')}`, { type: 'text/csv' }),
      //numberLine: (fileConvert.match(/\n/g) || []).length
      //};
    }
    return undefined;
  } catch (err) {
    console.log('convertXlsAtCsv() ==> err ==>', (err as Error).message);
    return undefined;
  }
};

export const onSplitCsv = (csv: string, file: File) => {
  try {
    const numbOrders = csv.split(/\n/g).filter(order => !order.split(',').every(parm => parm === ''));
    const header = numbOrders.shift();
    const filesOrders: Array<string> = [''];
    let countOrder = 1;

    console.log('orders() ==> ', { orders: numbOrders, header });
    numbOrders.forEach(numOrder => {
      if (numOrder !== '') {
        if (countOrder < 15) {
          if (countOrder === 1) {
            filesOrders[filesOrders.length - 1] = `${header}${filesOrders[filesOrders.length - 1]}\n${numOrder}`;
          } else {
            filesOrders[filesOrders.length - 1] = `${filesOrders[filesOrders.length - 1]}\n${numOrder}`;
          }
          countOrder++;
        } else {
          filesOrders.push(`${header}${numOrder}`);
          countOrder = 1;
        }
      }
    });

    const splitFiles = filesOrders.map((fileOrder, index) => {
      return {
        file: new File([fileOrder], `${`${index}-${file.name}`.replace('.xlsx', '.csv').replace('.xls', '.csv')}`, { type: 'text/csv' }),
        numberLine: (fileOrder.match(/\n/g) || []).length
      };
    });

    console.log('onSplitCsv() ==> filesOrders', { filesOrders, csv, splitFiles });
    return splitFiles;
  } catch (err) {
    console.log('onSplitCsv() ==> err', err);
    return undefined;
  }
};

export const convertArrayOfObjectsToCSV = (array: any) => {
  try {
    console.log('convertArrayOfObjectsToCSV', array);
    let result = '';

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    console.log('convertArrayOfObjectsToCSV() ==> keys ===>', keys);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
      //console.log('convertArrayOfObjectsToCSV', item);
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += `${item[key]}`;

        ctr++;
      });
      result += lineDelimiter;
    });

    console.log('convertArrayOfObjectsToCSV() ==> result', result);
    return result;
  } catch (err) {
    console.log('convertArrayOfObjectsToCSV() ==> err', err);
    return '';
  }
};

export const convertArrayToCSV = (array: any) => {
  try {
    const workSheet = XLSX.utils.json_to_sheet(array);
    const csv = XLSX.utils.sheet_to_csv(workSheet);
    const workBook = XLSX.read(csv, { type: 'string', raw: true });
    //const filename = `Listado_órdenes_${moment().format(FormatDate.ddmmaaAlt)}.csv`;
    return XLSX.write(workBook, { bookType: 'csv', type: 'string' });
  } catch (err) {
    console.log('convertArrayToCSV() =>', err);
    return undefined;
  }
};

export const convertArrayToXls = (array: any) => {
  try {
    const workSheet = XLSX.utils.json_to_sheet(array);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, workSheet, 'ConvertArrayToXls');
    return XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  } catch (err) {
    console.log('convertArrayToXls() =>', err);
    return undefined;
  }
};

export const downloadCSV = async (array: any) => {
  console.log('downloadCSV() => array', array);
  const workSheet = XLSX.utils.json_to_sheet(array);
  const csv = XLSX.utils.sheet_to_csv(workSheet);
  const workBook = XLSX.read(csv, { type: 'string' });
  const filename = `Listado_órdenes_${moment().format(FormatDate.ddmmaaAlt)}.csv`;
  await XLSX.writeFile(workBook, filename, { bookType: 'csv', type: 'string' });
};

export const downloadXLSX = async (array: any) => {
  console.log('downloadCSV() => array', array);
  const workSheet = XLSX.utils.json_to_sheet(array);
  const csv = XLSX.utils.sheet_to_csv(workSheet);
  const workBook = XLSX.read(csv, { type: 'string', raw: true });
  const filename = `Listado_órdenes_${moment().format(FormatDate.ddmmaaAlt)}.xlsx`;
  await XLSX.writeFile(workBook, filename, { bookType: 'xlsx', type: 'string' });
};

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const downloadBase64ToFile = async ({
  data,
  type,
  name = 'archivo'
}: {
  data?: string;
  type: 'pdf' | 'xls' | 'xlsx';
  name?: string;
}) => {
  try {
    if (data) {
      let linkSource = data;
      if (linkSource.indexOf('data:') === -1) {
        if (type === 'xls' || type === 'xlsx') {
          linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${linkSource}`;
        } else {
          linkSource = `data:application/pdf;base64,${linkSource}`;
        }
      }
      console.log('downloadBase64ToFile() =>', { linkSource });

      const downloadLink = document.createElement('a');
      const fileName = `${name}.${type}`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  } catch (err) {
    console.log('downloadBase64ToFile() => err', err);
  }
};

export const downloadFileByURL = async ({ url, nameFile }: { url: string; nameFile?: string }) => {
  try {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    if (nameFile) {
      downloadLink.download = nameFile;
    }
    downloadLink.click();
  } catch (err) {
    console.log('downloadFileByURL() ==> err', err);
  }
};

export const dataURLtoFile = async ({ dataurl, filename, type }: { dataurl: string; filename: string; type: string }) => {
  try {
    let url = dataurl;
    let onlyBase64 = dataurl;
    const arr = url.split(',');
    let mime: string = type;
    console.log('dataURLtoFile() => { arr, mime }', { arr, mime, type });

    if (arr && arr.length > 1) {
      const mimeFind = arr[0].match(/:(.*?);/);
      console.log('dataURLtoFile() => mimeFind', mimeFind);
      if (mimeFind && mimeFind.length > 0) {
        mime = `${mimeFind[0]}`;
      }
      onlyBase64 = url.replace(url.slice(url.indexOf('data:'), url.indexOf(',') + 1), '');
    } else if (type === EnumTypeDownloadFile.handbook || type === EnumTypeFile.pdf) {
      url = `data:application/pdf;base64,${url}`;
    } else if (type === EnumTypeDownloadFile.template || type === EnumTypeFile.xls) {
      url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${url}`;
    }

    console.log('dataURLtoFile() => url', { url, onlyBase64, start: url.indexOf('data:'), end: url.indexOf(',') });
    const base64Response = await fetch(url);
    if (base64Response) {
      const blob = await base64Response.blob();
      return { status: true, data: new File([blob], filename, { type: mime }) };
    }
    return { status: false, message: 'No fue posible convertir el base64 a file.' };
  } catch (err) {
    return { status: true, message: `No fue posible comvertir el base64 a file: ${(err as Error).message}` };
  }
};

/**
 * convert text por country
 */
/** convierte el label de distrito en su respectivo por país **/
export const nameDistrictForCountry = (country?: string): string => {
  //console.log('nameDistrictForCountry() ==> country', country);
  if (country) {
    if (country === EnumCountries.cl) {
      return 'Comuna';
    }
    if (country === EnumCountries.col) {
      return 'Barrio';
    }
    if (country === EnumCountries.per) {
      return 'Distrito';
    }
  }
  return 'Distrito';
};

/**
 * reduccion de arrays
 */
/** reduccion simple de un array y no duplicacion de items **/
export const myArrayWithNoDuplicates = (simpleArray: Array<any>) => {
  //console.log('myArrayWithNoDuplicates() ==>', simpleArray);
  try {
    const reducerArray = simpleArray.reduce(function (previousValue, currentValue) {
      if (previousValue.indexOf(currentValue) === -1) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);
    //console.log('reducerArray() ==>', reducerArray);
    return reducerArray;
  } catch (e) {
    console.log('myArrayWithNoDuplicates() ==> e ==>', e);
  }
};

export const getDecimalPart = (numb?: number | string) => {
  try {
    const num = parseFloat(`${numb}`);
    if (Number.isInteger(numb)) {
      return undefined;
    }

    const decimalStr = num.toString().split('.')[1];
    return Number(decimalStr);
  } catch (err) {
    return undefined;
  }
};

export const convertNumberToPrice = ({ val, country }: { val?: number; country?: string }) => {
  try {
    //const regularPhrase = /\B(?=(\d{3})+(?!\d))/g;
    //let valFormat = `$ ${val}`.replace(regularPhrase, ',');
    //if (country === EnumCountries.cl) {
    //valFormat = `$ ${val}`.replace(regularPhrase, '.');
    //}
    //return valFormat;
    configNumeralLocal(country, numeral);
    const numberOfDecimals = getDecimalPart(val) ? `${getDecimalPart(val)}`.length : 0;
    //const numeralFormat = numeral(parseFloat(`${val}`)).format(`$ 0,0[.]00`);
    const numeralFormat = numeral(parseFloat(`${val}`)).format(
      `$ 0,0${numberOfDecimals ? `[.]${Array(numberOfDecimals).fill(0).join('')}` : ''}`
    );
    //console.log('convertNumberToPrice() ==> ', { val, country, numeralFormat });
    return numeralFormat !== null ? numeralFormat : '';
  } catch (err) {
    return '';
  }
};

export const replaceNumberToPrice = ({ val, country }: { val?: number | string; country?: string }) => {
  try {
    //let regularPhrase = /\$\s?|(,*)/g;
    if (country === EnumCountries.cl) {
      //regularPhrase = /\$\s?|(\u10101*)/g;
    }
    configNumeralLocal(country, numeral);
    //console.log('replaceNumberToPrice() => { val, country }', { val, country, regularPhrase, numeral: numeral(val).value() });
    //const valFormat = `${val}`.replace(regularPhrase, '');
    const numeralFormat = numeral(val).value();
    //console.log('replaceNumberToPrice() =>', { float: parseFloat(valFormat), val, valFormat, numeralFormat });
    return numeralFormat !== null ? numeralFormat : 0;
  } catch (err) {
    return 0;
  }
};

export const secondsToHumanize = (seconds?: number | string) => {
  try {
    if (seconds) {
      const duration = moment.duration(seconds, 'seconds');
      return `${duration.days() ? `${duration.days()} días` : ''} ${duration.hours() ? duration.hours() : '00'}:${
        duration.minutes() ? duration.minutes() : '00'
      }:${duration.seconds() ? duration.seconds() : ''}`;
    }
    return '';
  } catch (err) {
    return '';
  }
};

export const durationWithDiff = ({
  date,
  format,
  dateRef,
  formatRef
}: {
  date: string;
  format?: FormatDate;
  dateRef: string;
  formatRef?: FormatDate;
}) => {
  try {
    return moment.duration(moment(date, format).diff(moment(dateRef, formatRef)));
  } catch (err) {
    console.log('durationWithDiff() ==> err', err);
    return undefined;
  }
};

export const capitalize = (str?: string) => {
  try {
    if (typeof str === 'string') {
      return str
        .toLowerCase()
        .trim()
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    return str;
  } catch (err) {
    console.log('capitalize() => err =>', err);
    return str;
  }
};

export const minutesToHoursAndMinutes = (str?: string | number) => {
  try {
    if (str) {
      const duration = moment.duration(str, 'minute');
      const hours = duration.get('hours');
      const minutes = duration.get('minutes');
      return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
    }
    return str;
  } catch (err) {
    console.log('minutesToHoursAndMinutes() => err', err);
    return str;
  }
};

export const shippingTypeStringToDisplay = (type?: string) => {
  try {
    if (type) {
      if (type === TypeDelivery.sameDay) {
        return TypeDeliveryDisplay.sameDay;
      } else if (type === TypeDelivery.pickupStore) {
        return TypeDeliveryDisplay.pickupStore;
      } else if (type === TypeDelivery.expressDelivery) {
        return TypeDeliveryDisplay.expressDelivery;
      } else if (type === TypeDelivery.nextDay) {
        return TypeDeliveryDisplay.nextDay;
      }
    }
    return type;
  } catch (err) {
    console.log('shippingTypeStringToDisplay() => err', err);
    return type;
  }
};

export const stringDeliveryToIcon = (delivery?: string) => {
  try {
    if (delivery === TypeDelivery.pickupStore || delivery === TypeDelivery.pickup) {
      return NameIcons.pickup;
    } else if (
      delivery === TypeDelivery.delivery ||
      delivery === TypeDelivery.expressDelivery ||
      delivery === TypeDelivery.deliveryExpress
    ) {
      return NameIcons.deliveryExpress;
    } else if (delivery === TypeDelivery.nextDay) {
      return NameIcons.nextDay;
    } else if (delivery === TypeDelivery.sameDay) {
      return NameIcons.sameDay;
    }
    return NameIcons.deliveryExpress;
  } catch (err) {
    return NameIcons.deliveryExpress;
  }
};

export const stringStatusDeliveryToColor = (sta?: string) => {
  try {
    const status = sta?.trim().toLowerCase();

    if (status === EnumStatusDelivery.created) {
      return 'bg-grayarauco-300';
    } else if (status === EnumStatusDelivery.readyStorePickup) {
      return 'bg-alternativefour';
    } else if (status === EnumStatusDelivery.onRoute) {
      return 'bg-primary';
    } else if (status === EnumStatusDelivery.pickUpInStore) {
      return 'bg-alternativeone';
    } else if (status === EnumStatusDelivery.receivedInWarehouse) {
      return 'bg-alternativetwo';
    } else if (status === EnumStatusDelivery.cancelled) {
      return 'bg-grayaraucofive';
    } else if (status === EnumStatusDelivery.notDelivered) {
      return 'bg-alternativethree';
    } else if (status === EnumStatusDelivery.delivered) {
      return 'bg-tertiary';
    } else if (status === EnumStatusDelivery.orderPreparation) {
      return 'bg-alternativefour';
    } else if (status === EnumStatusDelivery.readyToBeRetired) {
      return 'bg-lightblue';
    } else if (status === EnumStatusDelivery.inRoute) {
      return 'bg-primary';
    } else if (status === EnumStatusDelivery.successfulDelivery) {
      return 'bg-araucogreen';
    } else if (status === EnumStatusDelivery.orderCanceled) {
      return 'bg-grayarauco-400';
    } else if (status === EnumStatusDelivery.orderNotDelivered) {
      return 'bg-redarauco';
    } else if (status === EnumStatusDelivery.confirmedOrder) {
      return 'bg-grayarauco-300';
    }
    return 'bg-redarauco';
  } catch (err) {
    return 'bg-redarauco';
  }
};

export const stringAlertOrderToDisplay = (alertOrder?: string) => {
  try {
    if (alertOrder === EnumAlert.half) {
      return 'Urgencia media de envío';
    } else if (alertOrder === EnumAlert.high) {
      return 'Urgencia alta de envío';
    } else if (alertOrder === EnumAlert.low) {
      return 'Urgencia baja de envío';
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const stringAlertOrderToStyle = ({ alertOrder, type }: { alertOrder?: string; type?: 'text' | 'bg' | 'border' }) => {
  try {
    if (type === 'text') {
      if (alertOrder === EnumAlert.half) {
        return 'text-secondary';
      } else if (alertOrder === EnumAlert.high) {
        return 'text-redarauco';
      } else if (alertOrder === EnumAlert.low) {
        return 'text-tertiary';
      }
    } else if (type === 'bg') {
      if (alertOrder === EnumAlert.half) {
        return 'bg-secondary';
      } else if (alertOrder === EnumAlert.high) {
        return 'bg-redarauco';
      } else if (alertOrder === EnumAlert.low) {
        return 'bg-tertiary';
      }
    } else if (type === 'border') {
      if (alertOrder === EnumAlert.half) {
        return 'border-secondary';
      } else if (alertOrder === EnumAlert.high) {
        return 'border-redarauco';
      } else if (alertOrder === EnumAlert.low) {
        return 'border-tertiary';
      }
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const nameShippingStatusAtIconName = (status?: string) => {
  try {
    if (status === EnumDeliverySummaryStatus.confirmedOrder) {
      return NameIcons.done;
    } else if (status === EnumDeliverySummaryStatus.orderPreparation) {
      return NameIcons.warehouse;
    } else if (status === EnumDeliverySummaryStatus.readyToBeRemoved) {
      return NameIcons.whereToVote;
    } else if (status === EnumDeliverySummaryStatus.successfulDelivery) {
      return NameIcons.person;
    } else if (status === EnumDeliverySummaryStatus.onRoute) {
      return NameIcons.route;
    } else if (status === EnumDeliverySummaryStatus.cancelled) {
      return NameIcons.close;
    } else if (status === EnumDeliverySummaryStatus.undelivered) {
      return NameIcons.info;
    }
    return NameIcons.info;
  } catch (err) {
    console.log('nameShippingStatusAtIconName() => err', { err });
    return NameIcons.error;
  }
};

export const shippingStatusAtNameDisplay = (status: string) => {
  try {
    if (
      status === EnumStatesJanis.new ||
      status === EnumStatesJanis.inAudit ||
      status === EnumStatesJanis.readyForPicking ||
      status === EnumStatesJanis.picking ||
      status === EnumStatesJanis.readyToProcessPicking ||
      status === EnumStatesJanis.processingPicking ||
      status === EnumStatesJanis.inPostPickingAudit ||
      status === EnumStatesJanis.readyForInvoice ||
      status === EnumStatesJanis.invoiced ||
      status === EnumStatesJanis.preparingInternalDistribution
    ) {
      //return EnumStatusDelivery.created;
      return EnumDeliverySummaryStatus.confirmedOrder;
    } else if (status === EnumStatesJanis.fulfillingInternalDistribution || status === EnumStatesJanis.readyForInternalDistribution) {
      //return EnumStatusDelivery.pickUpInStore;
      return EnumDeliverySummaryStatus.orderPreparation;
    } else if (status === EnumStatesJanis.readyForDelivery) {
      //return EnumStatusDelivery.receivedInWarehouse;
      return EnumDeliverySummaryStatus.readyToBeRemoved;
    } else if (status === EnumStatesJanis.inDelivery) {
      //return EnumStatusDelivery.onRoute;
      return EnumDeliverySummaryStatus.onRoute;
    } else if (status === EnumStatesJanis.delivered) {
      //return EnumStatusDelivery.delivered;
      return EnumDeliverySummaryStatus.successfulDelivery;
    } else if (status === EnumStatesJanis.cancelationRequested || status === EnumStatesJanis.canceled) {
      //return EnumStatusDelivery.cancelled;
      return EnumDeliverySummaryStatus.cancelled;
    } else if (
      status === EnumStatesJanis.undelivered ||
      status === EnumStatesJanis.notdelivered ||
      status === EnumStatesJanis.notDelivered
    ) {
      //return EnumStatusDelivery.notDelivered;
      return EnumDeliverySummaryStatus.undelivered;
    }
    return EnumDeliverySummaryStatus.confirmedOrder;
  } catch (err) {
    console.log('statusAtNameDisplay() => err', err);
    return '';
  }
};

export const numberAtPercentage = ({ value, total, maxDecimal = 2 }: { value: number; total: number; maxDecimal?: number }) => {
  try {
    if (total !== 0) {
      return ((value * 100) / total).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxDecimal
      });
    }
    return 0;
  } catch (err) {
    console.log('numberAtPercentage() ==> err', err);
    return 0;
  }
};

export const summaryTypeDelivery = (delivery?: string) => {
  try {
    if (
      delivery === TypeDelivery.expressDelivery ||
      delivery === TypeDelivery.nextDay ||
      delivery === TypeDelivery.sameDay ||
      delivery === TypeDelivery.delivery ||
      delivery === 'express' ||
      delivery === 'nextday' ||
      delivery === 'sameday'
    ) {
      return TypeDelivery.delivery;
    } else {
      return TypeDelivery.pickup;
    }
  } catch (err) {
    console.log('summaryTypeDelivery() ==> err => ', { err });
    return undefined;
  }
};

export const getMonthByDate = ({ date, format }: { date?: string; format?: FormatDate }) => {
  try {
    if (date) {
      const month = moment(date, format).get('month');
      //console.log('month ==> ', month);
      if (month > -1) {
        return MonthsOfYears[month];
      }
    }

    return date;
  } catch (err) {
    console.log('getMonthByDate() ==> err', err);
    return undefined;
  }
};

export const convertDayEnglishAtSpanish = (day?: string) => {
  if (day) {
    if (day === 'Sun') {
      return 'Domingo';
    } else if (day === 'Sat') {
      return 'Sábado';
    } else if (day === 'Mon') {
      return 'Lunes';
    } else if (day === 'Tue') {
      return 'Martes';
    } else if (day === 'Wed') {
      return 'Miércoles';
    } else if (day === 'Thu') {
      return 'Jueves';
    } else if (day === 'Fri') {
      return 'Viernes';
    }
    return day;
  }
  return day;
};

export const convertDayAtNumber = (day?: string) => {
  if (day) {
    if (day === 'Sun' || day === 'Domingo') {
      return 7;
    } else if (day === 'Sat' || day === 'Sabado') {
      return 6;
    } else if (day === 'Mon' || day === 'Lunes') {
      return 1;
    } else if (day === 'Tue' || day === 'Martes') {
      return 2;
    } else if (day === 'Wed' || day === 'Miercoles') {
      return 3;
    } else if (day === 'Thu' || day === 'Jueves') {
      return 4;
    } else if (day === 'Fri' || day === 'Viernes') {
      return 5;
    }
    return 1;
  }
};

export const convertHourAtString = (hour?: string) => {
  try {
    const hourString = [
      '12 AM',
      '1 AM',
      '2 AM',
      '3 AM',
      '4 AM',
      '5 AM',
      '6 AM',
      '7 AM',
      '8 AM',
      '9 AM',
      '10 AM',
      '11 AM',
      '12 PM',
      '1 PM',
      '2 PM',
      '3 PM',
      '4 PM',
      '5 PM',
      '6 PM',
      '7 PM',
      '8 PM',
      '9 PM',
      '10 PM',
      '11 PM'
    ];
    if (hour) {
      return hourString[parseInt(hour)];
    }
    return hour;
  } catch (err) {
    console.log('convertHourAtString() => err', err);
    return '';
  }
};

const findNameFragment = (nameFailed?: string, name?: string) => {
  return nameFailed?.indexOf(name || '') !== -1;
};

export const convertNameFailedAtName = (nameFailed?: string) => {
  try {
    if (findNameFragment(nameFailed, 'email')) {
      return 'email';
    }
    if (findNameFragment(nameFailed, 'firstName')) {
      return 'nombre';
    }
    if (findNameFragment(nameFailed, 'lastname')) {
      return 'apellido';
    }
    if (findNameFragment(nameFailed, 'shippings-type')) {
      return 'tipo de envío';
    }
    if (findNameFragment(nameFailed, 'numdocument')) {
      return 'numero documento';
    }
    if (findNameFragment(nameFailed, 'state')) {
      return 'ciudad';
    }
    if (findNameFragment(nameFailed, 'adr')) {
      return 'dirección';
    }
    if (findNameFragment(nameFailed, 'numOrder') || findNameFragment(nameFailed, 'orderNum')) {
      return 'numero ordern';
    }
    if (findNameFragment(nameFailed, 'addresses-district')) {
      return 'distrito';
    }
    if (findNameFragment(nameFailed, 'document')) {
      return 'tipo de documento';
    }
    if (findNameFragment(nameFailed, 'customer-phone')) {
      return 'teléfono';
    }
    if (findNameFragment(nameFailed, 'phone')) {
      return 'teléfono';
    }
    if (findNameFragment(nameFailed, 'price')) {
      return 'preció';
    }
    if (findNameFragment(nameFailed, 'item')) {
      return 'item';
    }
    if (findNameFragment(nameFailed, 'quantity')) {
      return 'cantidad';
    }
    if (findNameFragment(nameFailed, 'size')) {
      return 'tamaño';
    }

    return nameFailed;
  } catch (err) {
    console.log('convertNameFailedAtName() ==> err', err);
    return '';
  }
};

export const reducerDelivery = (type?: string) => {
  try {
    if (
      type === TypeDelivery.delivery ||
      type === TypeDelivery.deliveryExpress ||
      type === TypeDelivery.deliveryNextDay ||
      type === TypeDelivery.deliverySameDay ||
      type === TypeDelivery.expressDelivery ||
      type === TypeDelivery.nextDay ||
      type === TypeDelivery.sameDay
    ) {
      return TypeDelivery.delivery;
    }
    return TypeDelivery.pickup;
  } catch (err) {
    return '';
  }
};

export const getBase64 = async (file?: File | string) => {
  try {
    if (file) {
      if (typeof file !== 'string') {
        const result = await fileToBase64(file);
        if (typeof result === 'string') {
          return result;
        }
      } else {
        return file;
      }
    }
    return undefined;
  } catch (err) {
    console.log('getBase64() ==> err', err);
    return undefined;
  }
};

export const homologationInterval = (interval?: string) => {
  try {
    if (interval === TypeTimeInterval.day) {
      return TypeTimeInterval.dayO;
    } else if (interval === TypeTimeInterval.month) {
      return TypeTimeInterval.monthO;
    } else if (interval === TypeTimeInterval.week) {
      return TypeTimeInterval.weekO;
    }
  } catch (err) {
    return undefined;
  }
};

export const convertAllAtCountries = ({
  country,
  countries,
  isString
}: {
  country?: string;
  countries?: Country[];
  isString?: boolean;
}): any => {
  try {
    if (countries) {
      if (country === EnumCountries.all) {
        if (isString) {
          return countries.map(coun => coun.data.id || '').join(',');
        }
        return countries.map(coun => coun.data.id || '');
      }
    }
    if (country) {
      if (isString) {
        return country;
      }
      return [country];
    }
    if (isString) {
      return EnumCountries.all;
    }
    return [EnumCountries.all];
  } catch (err) {
    console.log('convertAllAtCountries() => err', err);
    if (country) {
      if (isString) {
        return country;
      }
      return [country];
    }
    if (isString) {
      return EnumCountries.all;
    }
    return [EnumCountries.all];
  }
};

// Función para formatear la fecha
export const formatDate = (timestamp: string) => {
  const fecha = new Date(timestamp);
  return `${fecha.getFullYear()}-${padZero(fecha.getMonth() + 1)}-${padZero(fecha.getDate())} ${padZero(fecha.getHours())}:${padZero(
    fecha.getMinutes()
  )}:${padZero(fecha.getSeconds())}`;
};

const padZero = (value: number) => (value < 10 ? `0${value}` : value);
