import { ReactNode } from 'react';

import { NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import BoardFilter, { BoardFilterProps } from 'components/molecules/dropDown/BoardFilter';

type FilterHeaderColumnProps = {
  values?: string[];
  children?: ReactNode;
  /** si es verdadero en ascendente **/
  onSort?: (val?: boolean) => void;
  onFilter?(params: { val?: string[] | string }): Promise<string[]>;
  classNameContainer?: string;
  title?: string | ReactNode;
  typeInput?: 'text' | 'time' | 'date';
  isFilter?: boolean;
} & BoardFilterProps;

const FilterHeaderColumn = ({ title, children, isFilter, classNameContainer, ...props }: FilterHeaderColumnProps) => {
  return (
    <div className={`flex justify-center items-center w-full ${classNameContainer} ${isFilter ? 'bg-gray-200' : ''}`}>
      {children}
      {title}
      {props.onFilter && (
        <BoardFilter {...props}>
          <IconCustom onClick={event => event.preventDefault()} name={NameIcons.arrowDropDown} className='text-xs' />
        </BoardFilter>
      )}
    </div>
  );
};

export default FilterHeaderColumn;
