import OrderAlert, { OrderAlertModel } from 'models/alerts/OrderAlert';

import OrderAlertSettingProvider from 'services/api/settings/OrderAlert';

export const getOrderAlertsSetting = async (country?: string) => {
  try {
    const { status, data, ...other } = await OrderAlertSettingProvider.getOrderAlertsSetting(country);
    console.log('getOrderAlertsSetting() ==> { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data.lightorder && data.lightorder.length > 0) {
        const orderAlerts = OrderAlert.groupByServer(data.lightorder);
        return {
          status: true,
          data: orderAlerts ? orderAlerts.map((orderAlert: OrderAlertModel) => new OrderAlert(orderAlert)) : []
        };
      }
      return { status: false, message: 'No hay configuraciones.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: `No fue posible obtener las configuraciones.` };
  } catch (err) {
    return { status: false, message: `No fue posible obtener las configuraciones: ${(err as Error).message}.` };
  }
};

export const createOrderAlertSetting = async (orderAlert: OrderAlert) => {
  try {
    const promisesOrderAlert = orderAlert
      .sendServer()
      ?.map(async orderSend => await OrderAlertSettingProvider.createOrderAlertSetting(orderSend));
    if (promisesOrderAlert) {
      const result = await Promise.all(promisesOrderAlert);
      //console.log('updateOrderAlertSetting() => result', result);

      if (result.every(res => res.status === 200 || res.status === 201)) {
        return { status: true, message: 'Limites configurados con éxito.' };
      }

      return { status: false, message: 'No fue posible crear el limite.' };
    }

    return { status: false, message: `No fue posible crear las configuraciones.` };
  } catch (err) {
    return { status: false, message: `No fue posible crear: ${(err as Error).message}.` };
  }
};

export const updateOrderAlertSetting = async (orderAlert: OrderAlert) => {
  try {
    console.log('updateOrderAlertSetting() ==> orderAlert', orderAlert);
    const promisesOrderAlert = orderAlert
      .sendServer()
      ?.map(async orderSend => await OrderAlertSettingProvider.updateOrderAlertSetting(orderSend));
    if (promisesOrderAlert) {
      const result = await Promise.all(promisesOrderAlert);
      //console.log('updateOrderAlertSetting() => result', result);

      if (result.every(res => res.status === 200 || res.status === 201)) {
        return { status: true, message: 'El limite de horas fue actualizado con éxito.' };
      }

      return { status: false, message: 'No fue posible actualizar el limite.' };
    }

    return { status: false, message: `No fue posible obtener las configuraciones.` };
  } catch (err) {
    return { status: false, message: `No fue posible actualizar: ${(err as Error).message}.` };
  }
};

export const deleteOrderAlertSetting = async (orderAlert: OrderAlert) => {
  try {
    const promisesOrderAlert = orderAlert
      .sendServer()
      ?.map(async orderSend => await OrderAlertSettingProvider.deleteOrderAlertSetting(orderSend));
    //console.log('updateOrderAlertSetting() ==> orderAlert', orderAlert);
    if (promisesOrderAlert) {
      const result = await Promise.all(promisesOrderAlert);
      //console.log('updateOrderAlertSetting() => result', result);
      if (result.every(res => res.status === 200)) {
        return { status: true, message: 'El limite de horas fue borrado con éxito.' };
      }

      return { status: false, message: 'No fue posible eliminar.' };
    }

    return { status: false, message: `No fue posible obtener las configuraciones.` };
  } catch (err) {
    return { status: false, message: `No fue posible actulizar: ${(err as Error).message}.` };
  }
};
