import { useContext, useState } from 'react';

import { RootContext } from 'context/RootContext';
import { AuthContext } from 'context/AuthContext';

import { GRAPHICS_COLOR } from 'configs/Constants';
import { DashboardOrganismSections, FormatDate, SizeScreen } from 'configs/Enums';

import BarStackCustom from 'components/molecules/graphics/bar/BarStack';
import ColumnCustom from 'components/molecules/graphics/column/Column';
import MultiLineEvolutionOrders from 'components/molecules/graphics/line/MultiLineEvolutionOrders';
import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';

import { getDateNow } from 'helpers/Generator';
import { numberAtPrice } from 'helpers/Converts';

import { CountOrderByTypeDeliveryItemModel } from 'models/statistic/order/CountOrderByTypeDelivery';
import StatusOrder from 'models/statistic/order/StatusOrder';
import OrderByStore from 'models/statistic/order/OrderByStore';
import TypeDeliveryStatistic, { MountLastYears } from 'models/statistic/TypeDelivery';
import DemandStatistics from './pickup/Demand';
import DemandPickupStatistics from 'models/statistic/DemandPickupStatistics';
import TabBarItem from './ItemsTabs';
import AmountStatistic from 'models/statistic/Amount';
import OperatorStatistic from 'models/statistic/Operator';
import DistributionStatistic from 'models/statistic/Distribution';
import PerformanceStatistic from 'models/statistic/Performance';
import TabCustom from 'components/atoms/tabs';
import TabItemCustom from 'components/atoms/tabs/TabItem';

enum EnumTabKey {
  total = 'total',
  performance = 'performance',
  mount = 'mount',
  operator = 'operator',
  distribution = 'distribution',
  pickupDemand = 'pickupDemand'
}

const StatisticDeliveryPickupTabs = ({
  performanceTypeDelivery,
  section,
  paramsFilter,
  onFilterDemandPickup,
  demandPickup,
  amountStatistic,
  operatorOrders,
  distributionOrders,
  performanceStatistic
}: {
  dataCountOrder?: CountOrderByTypeDeliveryItemModel[];
  statusOrder?: StatusOrder;
  orderByStore?: OrderByStore;
  performanceTypeDelivery?: TypeDeliveryStatistic;
  section?: DashboardOrganismSections;
  paramsFilter?: ParamsValueFilterStatistic;
  onAmountFilter?: (params: ParamsValueFilterStatistic) => void;
  amountSalesStatistic?: MountLastYears[];
  onFilterDemandPickup?: (params: ParamsValueFilterStatistic) => void;
  demandPickup?: DemandPickupStatistics;
  amountStatistic?: AmountStatistic;
  operatorOrders?: OperatorStatistic;
  distributionOrders?: DistributionStatistic;
  performanceStatistic?: PerformanceStatistic;
}) => {
  const { screenResolution } = useContext(RootContext);
  const { authStatus } = useContext(AuthContext);

  const [tabKeyActive, setTabKeyActive] = useState(EnumTabKey.total);

  const onChange = (val: any) => {
    setTabKeyActive(val);
    console.log('onChange() ===> val', val);
  };

  if (screenResolution >= SizeScreen.xl) {
    return (
      <TabCustom className='px-10 pt-8' keyActive={tabKeyActive} {...{ onChange }}>
        <TabItemCustom
          tab={
            <TabBarItem
              title='Total pedidos'
              valueClassName={'text-lg'}
              keyV={EnumTabKey.total}
              isFirst
              keyActive={tabKeyActive}
              value={`Último mes: ${performanceTypeDelivery?.lastMonthlyQuantity()?.value || 0}`}
            />
          }
          keyA={EnumTabKey.total}
        >
          <div className='flex justify-center items-center flex-row gap-4'>
            <ColumnCustom
              classNameContainer={'flex-1'}
              title='Total pedidos mensual'
              titleX='Mes'
              titleY='Cantidad'
              color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
              data={performanceTypeDelivery?.monthlyQuantity() as any}
            />

            <MultiLineEvolutionOrders
              classNameContainer='flex-1'
              title='Total pedidos últimas 8 semanas'
              titleX='Mes'
              titleY='Tiempo promedió (horas)'
              data={performanceTypeDelivery?.numOrdersLast8Weeks()}
            />
          </div>
        </TabItemCustom>

        <TabItemCustom
          tab={
            <TabBarItem
              title='Performance'
              valueClassName={'text-lg'}
              keyV={EnumTabKey.performance}
              keyActive={tabKeyActive}
              value={`Nivel de servicio: ${performanceStatistic?.data.levelOfService || 0}`}
            />
          }
          keyA={EnumTabKey.performance}
        >
          <div className='flex justify-center items-center flex-row gap-4'>
            <ColumnCustom
              classNameContainer={'flex-1'}
              title='Nivel de servicio mensual'
              titleX='Mes'
              titleY='Cantidad'
              color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
              data={(performanceStatistic?.data.levelOfServiceMonth as any) || []}
            />

            <MultiLineEvolutionOrders
              classNameContainer='flex-1'
              title='Nivel de servicio últimas 8 semanas'
              titleX=''
              titleY='Porcentaje'
              legend={false}
              data={(performanceStatistic?.data.levelOfServiceWeek as any) || []}
            />
          </div>

          <div className='flex justify-center items-center flex-row gap-4 mt-4'>
            <ColumnCustom
              classNameContainer={'flex-1'}
              title='Tiempo promedio de entrega mensual'
              titleX='Mes'
              titleY='Tiempo promedio (horas)'
              color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
              data={(performanceStatistic?.data.averageDeliveryMonth as any) || []}
            />

            <MultiLineEvolutionOrders
              classNameContainer='flex-1'
              title='Tiempo promedio de entrega últimas 8 semanas'
              titleX=''
              titleY='Tiempo promedio (horas)'
              legend={false}
              data={(performanceStatistic?.data.averageDeliveryWeek as any) || []}
            />
          </div>
        </TabItemCustom>

        <TabItemCustom
          tab={
            <TabBarItem
              title='Montos'
              valueClassName={'text-lg'}
              keyV={EnumTabKey.mount}
              keyActive={tabKeyActive}
              value={`Último mes: ${numberAtPrice(amountStatistic?.data.totalLastMonth || 0)}`}
            />
          }
          keyA={EnumTabKey.mount}
        >
          <ColumnCustom
            classNameContainer={'flex-1'}
            title='Montos de ventas mensual'
            titleX={`Mes ${getDateNow({ format: FormatDate.yyyy })}`}
            titleY='$ Ventas'
            legend={false}
            color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
            data={(amountStatistic?.data.items as any) || []}
          />
        </TabItemCustom>

        {!authStatus.user?.isLocatario() && (
          <TabItemCustom
            tab={
              <TabBarItem
                title='Operadores'
                valueClassName={'text-lg'}
                keyV={EnumTabKey.operator}
                keyActive={tabKeyActive}
                value={`Último mes: ${operatorOrders?.data.total || 0}`}
              />
            }
            keyA={EnumTabKey.operator}
          >
            <div className='flex justify-center items-center flex-row gap-4 mt-4'>
              <BarStackCustom
                classNameContainer={'flex-1'}
                title='Total de pedidos mensual por operador'
                titleX=''
                titleY={`Mes ${getDateNow({ format: FormatDate.yyyy })}`}
                data={(operatorOrders?.data.monthlyOrdersByOperator as any) || []}
                color={GRAPHICS_COLOR.barPrimary}
              />

              <ColumnCustom
                classNameContainer={'flex-1'}
                title='Total de pedidos por operador (Acumulado 6 meses)'
                titleX='Tienda'
                titleY='Pedidos'
                seriesField='color'
                legend={false}
                color={(data: any) => {
                  return data.color;
                }}
                data={(operatorOrders?.data.orderByOperators as any) || []}
              />
            </div>
          </TabItemCustom>
        )}

        {section === DashboardOrganismSections.performanceDelivery && (
          <TabItemCustom
            tab={
              <TabBarItem
                title='Distribución'
                valueClassName={'text-lg'}
                keyV={EnumTabKey.distribution}
                keyActive={tabKeyActive}
                value={`Último mes: ${distributionOrders?.data.total || 0}`}
              />
            }
            keyA={EnumTabKey.distribution}
          >
            <div className='flex justify-center items-center flex-row gap-4 mt-4'>
              <BarStackCustom
                classNameContainer={'flex-1'}
                title='Total de pedidos mensuales por Comuna'
                titleX=''
                titleY={`Mes ${getDateNow({ format: FormatDate.yyyy })}`}
                data={distributionOrders?.data.monthlyOrdersByOperator as any}
                color={GRAPHICS_COLOR.barPrimary}
              />

              <ColumnCustom
                classNameContainer={'flex-1'}
                title='Total de pedidos por Comuna (Acumulado 6 meses)'
                titleX='Comuna'
                titleY='Pedidos'
                seriesField='color'
                legend={false}
                color={(data: any) => {
                  //console.log('color ==>', { data, other });
                  return data.color;
                }}
                data={distributionOrders?.data.orderByOperators as any}
              />
            </div>
          </TabItemCustom>
        )}

        {section === DashboardOrganismSections.performancePickup && (
          <TabItemCustom
            tab={
              <TabBarItem
                title='Demanda Pickup'
                valueClassName={'text-lg'}
                keyV={EnumTabKey.pickupDemand}
                keyActive={tabKeyActive}
                value={``}
              />
            }
            keyA={EnumTabKey.pickupDemand}
          >
            <DemandStatistics
              onFilter={pFilter => (onFilterDemandPickup ? onFilterDemandPickup(pFilter) : undefined)}
              {...{ paramsFilter, demandPickup }}
            />
          </TabItemCustom>
        )}
      </TabCustom>
    );
  }

  return (
    <div className='flex flex-col mt-4 mx-4'>
      <TabBarItem
        title='Total pedidos'
        valueClassName={'text-lg'}
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.total ? 'border-primary' : 'border-grayarauco-50'}`}
        keyV={EnumTabKey.total}
        isFirst
        keyActive={tabKeyActive}
        value={`Último mes: ${performanceTypeDelivery?.lastMonthlyQuantity()?.value || 0}`}
        onClick={val => setTabKeyActive(val)}
      />
      {tabKeyActive === EnumTabKey.total && (
        <div className='bg-white pb-4'>
          <ColumnCustom
            classNameContainer={'mx-4 flex-1'}
            title='Total pedidos mensual'
            titleX='Mes'
            titleY='Cantidad'
            color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
            data={performanceTypeDelivery?.monthlyQuantity() as any}
          />

          <MultiLineEvolutionOrders
            classNameContainer='mx-4 mt-4 flex-1'
            title='Total pedidos últimas 8 semanas'
            titleX='Mes'
            titleY='Tiempo promedió (horas)'
            data={performanceTypeDelivery?.numOrdersLast8Weeks()}
          />
        </div>
      )}

      <TabBarItem
        title='Performance'
        valueClassName={'text-lg'}
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.performance ? 'border-primary' : 'border-grayarauco-50'}`}
        keyV={EnumTabKey.performance}
        keyActive={tabKeyActive}
        value={`Nivel de servicio: ${performanceTypeDelivery?.getMonthLast()?.levelOfService || 0}`}
        onClick={val => setTabKeyActive(val)}
      />

      {tabKeyActive === EnumTabKey.performance && (
        <div className='bg-white pb-4'>
          <div className='flex justify-center items-center flex-col gap-4 px-4'>
            <ColumnCustom
              classNameContainer={'flex-1 w-full'}
              title='Nivel de servicio mensual'
              titleX='Mes'
              titleY='Cantidad'
              color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
              data={performanceTypeDelivery?.serviceLevelLastYears() as any}
            />

            <MultiLineEvolutionOrders
              classNameContainer='flex-1 w-full'
              title='Nivel de servicio últimas 8 semanas'
              titleX=''
              titleY='Porcentaje'
              legend={false}
              data={performanceTypeDelivery?.serviceLevelLast8Week()}
            />
          </div>

          <div className='flex justify-center items-center flex-col gap-4 mt-4 px-4'>
            <ColumnCustom
              classNameContainer={'flex-1 w-full'}
              title='Tiempo promedio de entrega mensual'
              titleX='Mes'
              titleY='Tiempo promedio (horas)'
              color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
              data={performanceTypeDelivery?.monthlyEvolutionaryWithdrawalTime() as any}
            />

            <MultiLineEvolutionOrders
              classNameContainer='flex-1 w-full'
              title='Tiempo promedio de entrega últimas 8 semanas'
              titleX=''
              titleY='Tiempo promedio (horas)'
              legend={false}
              data={performanceTypeDelivery?.averageTimeLast8Week()}
            />
          </div>
        </div>
      )}

      <TabBarItem
        title='Montos'
        valueClassName={'text-lg'}
        keyV={EnumTabKey.mount}
        keyActive={tabKeyActive}
        value={`Último mes: ${numberAtPrice(amountStatistic?.data.totalLastMonth) || 0}`}
        className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.mount ? 'border-primary' : 'border-grayarauco-50'}`}
        onClick={val => setTabKeyActive(val)}
      />

      {tabKeyActive === EnumTabKey.mount && (
        <div className='bg-white pb-4'>
          <ColumnCustom
            classNameContainer={'flex-1'}
            title='Montos de ventas mensual'
            titleX={`Mes ${getDateNow({ format: FormatDate.yyyy })}`}
            titleY='$ Ventas'
            legend={false}
            color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
            data={(amountStatistic?.data.items as any) || []}
          />
        </div>
      )}

      {!authStatus.user?.isLocatario() && (
        <TabBarItem
          title='Operadores'
          valueClassName={'text-lg'}
          keyV={EnumTabKey.operator}
          keyActive={tabKeyActive}
          value={`Último mes: ${operatorOrders?.data.total || 0}`}
          className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.operator ? 'border-primary' : 'border-grayarauco-50'}`}
          onClick={val => setTabKeyActive(val)}
        />
      )}

      {tabKeyActive === EnumTabKey.operator && (
        <div className='flex justify-center items-center flex-col gap-4 mt-4 bg-white pb-4'>
          <BarStackCustom
            classNameContainer={'flex-1 w-full'}
            title='Total de pedidos mensual por operador'
            titleX=''
            titleY={`Mes ${getDateNow({ format: FormatDate.yyyy })}`}
            data={(operatorOrders?.data.monthlyOrdersByOperator as any) || []}
            color={GRAPHICS_COLOR.barPrimary}
          />
          <ColumnCustom
            classNameContainer={'flex-1 w-full'}
            title='Montos de ventas mensual'
            titleX={`Mes ${getDateNow({ format: FormatDate.yyyy })}`}
            titleY='$ Ventas'
            legend={false}
            color={section === DashboardOrganismSections.performanceDelivery ? '#6E9B3C' : '#154BAE'}
            data={(operatorOrders?.data.orderByOperators as any) || []}
          />
        </div>
      )}

      {section === DashboardOrganismSections.performanceDelivery && (
        <div>
          <TabBarItem
            title='Distribución'
            valueClassName={'text-lg'}
            keyV={EnumTabKey.distribution}
            keyActive={tabKeyActive}
            value={`Último mes: ${distributionOrders?.data.total || 0}`}
            className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.operator ? 'border-primary' : 'border-grayarauco-50'}`}
            onClick={val => setTabKeyActive(val)}
          />
          {tabKeyActive === EnumTabKey.distribution && (
            <div className='bg-white pb-4'>
              <div className='flex flex-col justify-center items-center flex-row gap-4 mt-4'>
                <BarStackCustom
                  classNameContainer={'flex-1 w-full'}
                  title='Total de pedidos mensuales por Comuna'
                  titleX=''
                  titleY={`Mes ${getDateNow({ format: FormatDate.yyyy })}`}
                  data={(distributionOrders?.data.monthlyOrdersByOperator as any) || []}
                  color={GRAPHICS_COLOR.barPrimary}
                />

                <ColumnCustom
                  classNameContainer={'flex-1 w-full'}
                  title='Total de pedidos por Comuna (Acumulado 6 meses)'
                  titleX='Comuna'
                  titleY='Pedidos'
                  seriesField='color'
                  legend={false}
                  color={(data: any) => {
                    //console.log('color ==>', { data, other });
                    return data.color;
                  }}
                  //data={performanceTypeDelivery?.districtRankingYearInP() as any}
                  data={(distributionOrders?.data.orderByOperators as any) || []}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {section === DashboardOrganismSections.performancePickup && (
        <div>
          <TabBarItem
            title='Demanda Pickup'
            valueClassName={'text-lg'}
            keyV={EnumTabKey.pickupDemand}
            keyActive={tabKeyActive}
            value={``}
            className={`mt-1 border-t-4 ${tabKeyActive === EnumTabKey.operator ? 'border-primary' : 'border-grayarauco-50'}`}
            onClick={val => setTabKeyActive(val)}
          />
          {tabKeyActive === EnumTabKey.pickupDemand && (
            <div className='bg-white pb-4'>
              <DemandStatistics
                onFilter={pFilter => (onFilterDemandPickup ? onFilterDemandPickup(pFilter) : undefined)}
                {...{ paramsFilter, demandPickup }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StatisticDeliveryPickupTabs;
