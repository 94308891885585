import { Skeleton, Space } from 'antd';

const DonutSkeleton = ({ isActive }: { isActive?: boolean }) => {
  if (isActive) {
    return (
      <div className='flex flex-1 justify-center items-center w-full'>
        <Space>
          <Skeleton.Avatar className='ant-skeleton-statistic h-32 lg:h-96 w-32 lg:w-96' active={true} size={'large'} shape='circle' />

          <div className='flex flex-col gap-2 ml-8'>
            <Skeleton.Input active={true} size='small' />
            <Skeleton.Input active={true} size='small' />
            <Skeleton.Input active={true} size='small' />
            <Skeleton.Input active={true} size='small' />
          </div>
        </Space>
      </div>
    );
  }

  return null;
};

export default DonutSkeleton;
