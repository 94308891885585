import React, { useContext } from 'react';
import MDEditor from '@uiw/react-md-editor';

import { SizeScreen } from 'configs/Enums';

import LabelCustom from '../labels';
import { RootContext } from 'context/RootContext';
//import InputCustom from '../inputs';

export interface MarkdownEditorProps {
  value?: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  classNameLabel?: string;
  classNameContainer?: string;
  onChange?: (value?: string) => void;
  hide?: boolean;
  onValidate?: (isValid?: boolean, val?: any) => void;
}

const MarkdownEditor = ({
  value,
  label,
  onChange,
  hide,
  classNameLabel,
  classNameContainer,
  isRequired,
  onValidate
}: MarkdownEditorProps) => {
  const { screenResolution } = useContext(RootContext);

  const onChangeEditable = (valueCurrent?: string) => {
    if (isRequired && (valueCurrent === null || valueCurrent === undefined || valueCurrent === '')) {
      if (onValidate) {
        onValidate(false, valueCurrent);
      }
    } else {
      if (onValidate) {
        onValidate(true, valueCurrent);
      }
    }
    if (onChange) {
      onChange(valueCurrent);
    }
  };

  return (
    <div className={`flex flex-col ${classNameContainer}`} hidden={hide}>
      {label && (
        <LabelCustom className={`mb-2 ${classNameLabel}`}>
          {label}
          {isRequired && <span className='text-red-400'> *</span>}
        </LabelCustom>
      )}
      {screenResolution > SizeScreen.md && (
        <div className='items-center h-full'>
          <MDEditor className='w-full' value={value} onChange={onChangeEditable} />
        </div>
      )}
      {screenResolution <= SizeScreen.md && (
        <div className='items-center flex flex-col md:flex-row h-full'>
          <MDEditor
            className='w-full'
            preview='edit'
            toolbarHeight={screenResolution <= SizeScreen.sm ? 38 : 29}
            value={value}
            onChange={onChangeEditable}
          />
          <MDEditor className='w-full mt-7' hideToolbar preview='preview' value={value} />
        </div>
      )}
    </div>
  );
};

export default MarkdownEditor;
