export interface DocumentTypeModel {
  name: string;
  value: string;
}

export interface DocumentTypeServerModel {
  name: string;
  id: string;
}

class DocumentType {
  data: DocumentTypeModel;

  constructor(data: DocumentTypeModel) {
    this.data = data;
  }

  static formalizeData(data: DocumentTypeServerModel): DocumentTypeModel {
    const { name, id } = data;

    return {
      name,
      value: id
    };
  }
}

DocumentType.prototype.toString = function documentTypeToString() {
  return `${this.data.value}`;
};

export default DocumentType;
