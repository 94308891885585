import validate from 'validate.js';
import { TableColumn } from 'react-data-table-component';
import numeral from 'numeral';
import { Duration } from 'moment';

import { VALIDATE_CONSTRAINTS } from 'configs/Constants';
import { EnumAlert, EnumCountries, TypeDelivery, EnumIncomeType, NameIcons, FormatDate, EnumDocumentType } from 'configs/Enums';

import FilterHeaderColumn from 'components/molecules/headers/FilterHeaderColumn';
import CellOrder from 'components/molecules/items/order/CellOrder';
import CellCustomer from 'components/molecules/items/order/CellCustomer';
import { Tooltip } from 'antd';

import {
  durationWithDiff,
  nameDistrictForCountry,
  typeDeliveryAtTypeDeliveryServer,
  stringStatusDeliveryToColor,
  formatDate
} from 'helpers/Converts';
import { getDateNow } from 'helpers/Generator';

import OrderDetail, { OrderDetailModelServer } from 'models/order/OrderDetail';
import { OrderInfoModelServer } from 'models/order/OrderInfo';
import Base from 'models/Base';
import Delivery from 'models/Delivery';
import DocumentType from 'models/user/DocumentType';
import OrderAlert from 'models/alerts/OrderAlert';
import CellLocation from 'components/molecules/items/order/CellLocation';
import CellShippingType from 'components/molecules/items/order/CellShippingType';
import CellShippingStates from 'components/molecules/items/order/CellShippingStates';
import CellOther from 'components/molecules/items/order/CellOther';
import IconCustom from 'components/atoms/icons';
import CellStore from 'components/molecules/items/order/CellStore';
import User from 'models/User';
import TableCellMolecule from 'components/molecules/items/TableCell';

export interface DataTableOrder {
  order?: {
    order?: string;
    creationDate?: string;
    creationTime?: string;
    estimatedDate?: string;
    estimatedTime?: string;
    deliveredDate?: string;
    deliveredTime?: string;
    warehouse?: string;
  };
  status?: string;
  deliveryCost?: string;
  totalAmount?: number;
  client?: {
    fullName?: string;
    numDocument?: string;
    phone?: string;
    email?: string;
  };
  location?: {
    city?: string;
    district?: string;
    address?: string;
  };
  warehouse?: string;
  typeDelivery?: string;
  typeUpload?: string;
  price?: string;
  items?: string;
  size?: string;
  store?: string;
}

export interface OrderError {
  name: string;
  value?: number | string;
  message?: string;
  correctedValue?: number | string;
  isValid?: boolean;
  field?: EnumFielOrder;
  input?: 'select' | 'text' | 'number';
  /** ubicacion de dato erroneo **/
  path?: string;
  isRequired?: boolean;
  constraints?: any;
  /** campo como se envía al servidor **/
  filedSend?: string;
  hide?: boolean;
}

export enum EnumFielOrder {
  order = 'order',
  typeShipphing = 'typeShipphing',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  documentType = 'documentType',
  numberDocument = 'numberDocument',
  city = 'city',
  district = 'district',
  adr = 'adr',
  country = 'country',
  streetNumber = 'streetNumber',
  department = 'department',
  items = 'items',
  purchasePrice = 'purchasePrice',
  quanty = 'quanty',
  purchasedQuantity = 'purchasedQuantity',
  size = 'size',
  warehouse = 'warehouse'
}

export interface OrderModel {
  id?: string;
  /** si esta en estado leido o nuevo **/
  incomeType?: EnumIncomeType;
  checked?: boolean;
  error?: boolean;
  createdAt?: number | string;
  createdAtDisplay?: string;
  detail: OrderDetail;
  updatedAt?: number;
  updatedAtDisplay?: string;
  status?: string;
  deliveryCost?: string;
  totalAmount?: number;
  totalAmountDisplay?: number;
  typeUpload?: string;
  /** nombre del usuario que cargo la orden **/
  userUpload?: string;
  /** usuario que cargo la orden **/
  idUser?: string;
  /** id como se identifica la orden en janis **/
  orderJanis?: string;
  /** alerta de entrega **/
  alertOrder?: string;
  dateSendEstimate?: number;
  formattedDateSendEstimate?: string;
  /** notificaciones id de connexion **/
  connectionId?: string;
  warehouseTime?: string;
  errors?: OrderError[];
  commerceId?: string;
  /** si la roden a sido seleccionan para ser borrada **/
  isSelectDelete?: boolean;
  dateDelivered?: string | number;
  formattedDateDelivered?: string;
  warehouse?: string;
  emailEventLogs?: emailEventLogsModelServer[];
}

export type OrderModelServer = {
  checked?: boolean;
  createdAt?: number | string;
  id: string;
  updatedAt: number;
  status?: string;
  deliveryCost?: string;
  totalAmount?: number;
  orderJanis?: string;
  typeUpload?: string;
  warehouseTime?: string;
  commerceId?: string;
  /** nombre del usuario que cargo la orden **/
  userUpload?: string;
  /** usuario que cargo la orden **/
  idUser?: string;
  /** alerta de entrega **/
  alertOrder?: string;
  dateSendEstimate?: number;
  /** si esta en estado nuevo, leido es de las notificaciones **/
  type?: string;
  /** notificaciones id de connexion **/
  connectionId?: string;
  incomeType?: string;
  /** ya no existe el order como objeto **/
  orderNum?: string;
  numOrder?: string;
  store?: string;
  dateDelivered?: string | number;
  /** ya no va esta era la estructura anterior **/
  text?: OrderDetailModelServer;
  warehouse?: any;
  emailEventLogs?: emailEventLogsModelServer[];
} & OrderDetailModelServer;

export interface emailEventLogsModelServer {
  email_sent: boolean;
  date_sent: string;
  destination: string;
  status_order: any;
  error?: any;
  forwarded: boolean;
}

/** la orden como llega del websocket para actulizar **/
export interface OrderModelWebSocket {
  alertOrder?: string;
  createdAt?: string;
  dateSendEstimate?: number;
  deliveryCost?: number;
  id: string;
  originalAmount?: number;
  status?: string;
  totalAmount?: number;
  warehouseTime?: string;
}

/** esta interfaz es respondida por el servidor cuando falla el subir un archivo **/
export type OrderModelErrorServer = OrderDetailModelServer;

class Order extends Base {
  data: OrderModel;

  constructor(data: OrderModel) {
    super();
    this.data = data;
  }

  static formalizeData(data: OrderModelServer, encrypted?: boolean): OrderModel {
    //console.log('formalizeData() => data', data);
    const {
      text,
      createdAt,
      id,
      updatedAt,
      status,
      deliveryCost,
      totalAmount,
      orderJanis,
      typeUpload,
      userUpload,
      idUser,
      alertOrder,
      dateSendEstimate,
      type,
      connectionId,
      warehouseTime,
      commerceId,
      /** nueva estructura **/
      addresses,
      order,
      orden,
      ean,
      errores,
      shippings,
      customer,
      customerEncrypted,
      addressEncrypted,
      numOrder,
      orderNum,
      store,
      dateDelivered,
      warehouse
    } = data;

    let detail: OrderDetail;
    if (text) {
      detail = new OrderDetail(OrderDetail.formalizeData(text));
    } else {
      let orderCurrent: OrderInfoModelServer = { order: '' };
      if (numOrder || orderNum || id) {
        let numOrderCurrent = numOrder || orderNum || id || '';
        let indexFirst = numOrderCurrent?.indexOf(`-${EnumCountries.cl}-`);
        if (indexFirst === -1) {
          indexFirst = numOrderCurrent?.indexOf(`-${EnumCountries.col}-`);
        }
        if (indexFirst === -1) {
          indexFirst = numOrderCurrent?.indexOf(`-${EnumCountries.per}-`);
        }
        if (indexFirst !== -1) {
          const numOrderSlice = numOrderCurrent.slice(indexFirst);
          numOrderCurrent = numOrderCurrent.replace(numOrderSlice, '');
        }
        //console.log('formalizeData() => { indexFirst, formatNumOrder }', { indexFirst, formatNumOrder, numOrderCurrent });
        orderCurrent = { order: numOrderCurrent, store, warehouse };
      } else {
        orderCurrent = order || { order: '' };
      }

      if (shippings) {
        shippings.warehouse = warehouse;
      }

      detail = new OrderDetail(
        OrderDetail.formalizeData(
          {
            addresses,
            order: orderCurrent,
            orden,
            ean,
            errores,
            addressEncrypted,
            shippings,
            customer,
            customerEncrypted,
            emailEventLogs: data?.emailEventLogs ? data.emailEventLogs : []
          },
          encrypted
        )
      );
    }

    return {
      id,
      error: errores,
      /** agregue este por un unico motivo arroja errores si no se ubica de esta forma en el
       * servicio de ordens**/
      checked: data.checked || false,
      createdAt: Order.formatStringData(createdAt),
      detail: detail,
      updatedAt,
      status,
      deliveryCost,
      totalAmount,
      orderJanis,
      typeUpload,
      idUser,
      userUpload,
      dateSendEstimate,
      formattedDateSendEstimate: Order.formatStringData(dateSendEstimate),
      alertOrder: OrderAlert.parseAlert(alertOrder),
      incomeType: type ? (type as EnumIncomeType) : undefined,
      connectionId,
      warehouseTime: Order.formatStringData(warehouseTime),
      commerceId,
      dateDelivered,
      formattedDateDelivered: Order.formatStringData(dateDelivered),
      warehouse
    };
  }

  /** ordenes que fallaron **/
  static formalizeDataError(data: OrderModelErrorServer): OrderModel {
    return {
      detail: new OrderDetail(OrderDetail.formalizeData(data)),
      error: data.errores
    };
  }

  static conditionalRowStyles(order: Order): any {
    if (order.data.alertOrder === EnumAlert.low) {
      return {
        backgroundColor: 'rgb(5, 150, 105, 1)'
      };
    } else if (order.data.alertOrder === EnumAlert.high) {
      return {
        backgroundColor: 'rgb(220, 38, 38, 1)'
      };
    } else if (order.data.alertOrder === EnumAlert.half) {
      return {
        backgroundColor: 'rgb(251, 191, 36, 1)'
      };
    }
  }

  static headerTable({ onDetail, user }: { onDetail?: (order: Order) => void; user?: User }): TableColumn<Order>[] {
    const returnFilterHeaderColumn: TableColumn<Order>[] = [
      {
        name: <div className='m-0 p-0' />,
        selector: row => Order.removeFlag(row.data.alertOrder || ''),
        sortable: false,
        reorder: false,
        minWidth: '5px',
        maxWidth: '5px',
        cell: row => <div className='w-2 h-full' style={Order.conditionalRowStyles(row)} />
      },
      {
        name: <FilterHeaderColumn title='Orden' />,
        selector: row => Order.removeFlag((row.data.detail.data.order.data.order as string) || ''),
        reorder: true,
        minWidth: '220px',
        cell: row => <CellOrder ignoreError order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Tienda' />,
        selector: row => Order.removeFlag((row.data.detail.data.order.data.store as string) || ''),
        omit: user?.isLocatario(),
        reorder: true,
        minWidth: '100px',
        cell: row => <CellStore ignoreError order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Cliente' />,
        selector: row => Order.removeFlag(row.data.detail.data.customer.data.fullName ? row.data.detail.data.customer.data.fullName : ''),
        reorder: true,
        maxWidth: '150px',
        cell: row => <CellCustomer ignoreError order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Ubicación' />,
        selector: row => Order.removeFlag((row.data.detail.data.addresses.data.adr as string) || ''),
        reorder: true,
        maxWidth: '150px',
        cell: row => <CellLocation ignoreError order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Tipo de envío' />,
        selector: row => Order.removeFlag(row.data.detail.data.shipphing.data.type || ''),
        reorder: true,
        minWidth: '100px',
        cell: row => <CellShippingType ignoreError order={row?.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Estado de envío' />,
        selector: row => Order.removeFlag(row.data.status),
        maxWidth: '220px',
        minWidth: '220px',
        reorder: true,
        cell: row => <CellShippingStates ignoreError order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Precio' />,
        selector: row => Order.removeFlag(row.data.detail.data.shipphing.data.purchasePrice),
        reorder: true,
        minWidth: '80px',
        cell: row => <CellOther ignoreError order={row.getDataTable()} filed='price' />
      },
      //{
      //name: <FilterHeaderColumn title='Costo de servicio' />,
      //selector: row => Order.removeFlag(`${row.data.deliveryCost}`),
      //reorder: true,
      //minWidth: '100px',
      //cell: row => <CellOther ignoreError filed='deliveryCost' order={row.getDataTable()} />
      //},
      {
        name: <FilterHeaderColumn title='Bodega' />,
        selector: row => Order.removeFlag(`${row.data.warehouse}`),
        reorder: true,
        minWidth: '100px',
        cell: row => <CellOther ignoreError filed='warehouse' order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Tipo de carga' />,
        minWidth: '80px',
        selector: row => Order.removeFlag(`${row.data.totalAmount}`),
        reorder: true,
        cell: row => <CellOther ignoreError filed='typeUpload' order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Items' />,
        minWidth: '50px',
        maxWidth: '50px',
        selector: row => Order.removeFlag(`${row.data.detail.data.shipphing.data.items}`),
        reorder: true,
        cell: row => <CellOther ignoreError filed='items' order={row.getDataTable()} />
      },
      {
        name: <FilterHeaderColumn title='Tamaño' />,
        minWidth: '50px',
        maxWidth: '50px',
        selector: row => Order.removeFlag(`${row.data.detail.data.shipphing.data.size}`),
        reorder: true,
        cell: row => <CellOther ignoreError filed='size' order={row.getDataTable()} />
      },
      {
        name: '',
        maxWidth: '50px',
        minWidth: '50px',
        selector: row => Order.removeFlag(`${row.data.detail.data.shipphing.data.size}`),
        reorder: false,
        cell: row => (
          <div className='h-full w-full flex justify-center items-center'>
            <IconCustom
              name={NameIcons.visibility}
              onClick={() => {
                if (onDetail) {
                  onDetail(row);
                }
              }}
              className='text-grayarauco cursor-pointer'
            />
          </div>
        )
      }
    ];

    return returnFilterHeaderColumn;
  }

  sendDataFileCSV(country?: string) {
    const { detail, status, deliveryCost, totalAmount, typeUpload, idUser, userUpload, createdAt } = this.data;
    let sendResponse: any = {
      'Fecha de Ingreso': createdAt,
      'Nº orden': detail.data.order.data.order,
      'Tipo envío': detail.data.shipphing.data.type,
      Estado: status,
      Nombre: `${detail.data.customer.data.firstName} ${detail.data.customer.data.lastName || ''}`,
      Email: detail.data.customer.data.email,
      Teléfono: detail.data.customer.data.phone,
      'Tipo documento': detail.data.customer.data.documentType,
      'Nº documento': detail.data.customer.data.numberDocument,
      País: detail.data.addresses.data.country,
      Ciudad: detail.data.addresses.data.state,
      Dirección: `${detail.data.addresses.data.state || ''} ${detail.data.addresses.data.district || ''} ${
        detail.data.addresses.data.adr || ''
      } ${detail.data.addresses.data.number || ''} ${detail.data.addresses.data.department || ''}`
    };
    sendResponse[nameDistrictForCountry(country)] = detail.data.addresses.data.district;
    sendResponse = {
      ...sendResponse,
      ...{
        Tienda: detail.data.order.data.store || '',
        Divisa: detail.data.shipphing.data.currency || '',
        Items: detail.data.shipphing.data.items || '',
        Precio: `$${detail.data.shipphing.data.purchasePrice || ''}`,
        'Cantidad comprada': detail.data.shipphing.data.purchasedQuantity || '',
        'Coste de envío': deliveryCost || '',
        'Cantidad total': totalAmount || '',
        Tamaño: detail.data.shipphing.data.size || '',
        'Origen carga': typeUpload,
        'Id usuario (carga)': idUser,
        'Nombre usuario (carga)': userUpload
      }
    };

    return sendResponse;
  }

  /*
   * tranforma la Order en un objeto simple para procesarse en un csv.
   **/
  convertObjectSimple() {
    const { detail, commerceId } = this.data;
    const { order, shipphing, customer, addresses } = detail.data;

    let typeDocument = customer.data.documentType;
    let numDocument = customer.data.numberDocument;
    if (addresses.data.country === EnumCountries.cl) {
      if (
        customer.data.documentType.toUpperCase() === EnumDocumentType.rut &&
        customer.data.numberDocument === Order.getMessageValue(order.data.order)
      ) {
        typeDocument = '';
        numDocument = '';
      }
    }

    let objectSend: any = {
      address: addresses.data.adr,
      article: shipphing.data.purchasedQuantity,
      city: addresses.data.state,
      commerceId,
      department: addresses.data.department || '',
      dimensions: shipphing.data.size || '',
      district: addresses.data.district || '',
      email: customer.data.email,
      firstName: customer.data.firstName,
      item: shipphing.data.items,
      lastName: customer.data.lastName,
      numDocument,
      numStreet: addresses.data.number,
      order: order.data.order,
      phone: customer.data.phone,
      price: shipphing.data.purchasePrice,
      typeDelivery: typeDeliveryAtTypeDeliveryServer(shipphing.data.type || ''),
      typeDocument: typeDocument,
      typeUpload: 'masivo',
      warehouse: order.data.warehouse || shipphing.data.warehouse || ''
    };

    if (this.data.errors) {
      this.data.errors.forEach(err => {
        if (err.field === EnumFielOrder.purchasePrice) {
          objectSend = { ...objectSend, [`${err.filedSend}`]: numeral(err.correctedValue).value() };
        } else if (err.field === EnumFielOrder.typeShipphing) {
          objectSend = { ...objectSend, [`${err.filedSend}`]: typeDeliveryAtTypeDeliveryServer(`${err.correctedValue}` || '') };
        } else {
          objectSend = { ...objectSend, [`${err.filedSend}`]: err.correctedValue };
        }
      });
    }

    return objectSend;
  }

  /** valor corregido o erroneo **/
  getValueErrorOrCorrect({
    type,
    value,
    className,
    isReactNode,
    returnOnlyBoolean
  }: {
    type?: EnumFielOrder;
    value?: any;
    className?: string;
    isReactNode?: boolean;
    returnOnlyBoolean?: boolean;
  }) {
    const { errors } = this.data;
    let valCurrent = value;
    let isError = false;

    const findError = errors?.find(err => {
      if (err.field === type) {
        return true;
      }
    });

    if (findError) {
      if (findError.isValid) {
        valCurrent = findError.correctedValue;
        isError = false;
      } else {
        valCurrent = Order.getMessageValue(`${findError.value}`);
        isError = true;
      }
    }
    if (isReactNode) {
      return <div className={`${className} ${isError ? 'text-redarauco' : ''}`}>{valCurrent}</div>;
    }
    if (returnOnlyBoolean) {
      return isError;
    }
    return valCurrent;
  }

  getValidateErrorSection(section: 'client' | 'shipping' | 'costs') {
    const { errors } = this.data;

    console.log('getValidateErrorSection() => errors', errors);
    if (section === 'client') {
      const errFilter = errors?.find(err => {
        if (
          err.field === EnumFielOrder.firstName ||
          err.field === EnumFielOrder.lastName ||
          err.field === EnumFielOrder.district ||
          err.field === EnumFielOrder.city ||
          err.field === EnumFielOrder.adr ||
          err.field === EnumFielOrder.phone ||
          err.field === EnumFielOrder.email ||
          err.field === EnumFielOrder.documentType ||
          err.field === EnumFielOrder.numberDocument ||
          err.field === EnumFielOrder.streetNumber
        ) {
          return !err.isValid;
        }
      });
      console.log('errFilter() =>', errFilter);
      if (errFilter) {
        return false;
      }
      return true;
    } else if (section === 'shipping') {
      const errFilter = errors?.find(err => {
        if (err.field === EnumFielOrder.typeShipphing || err.field === EnumFielOrder.items || err.field === EnumFielOrder.size) {
          return !err.isValid;
        }
      });
      if (errFilter) {
        return false;
      }
      return true;
    } else if (section === 'costs') {
      const errFilter = errors?.find(err => {
        if (
          err.field === EnumFielOrder.purchasePrice ||
          err.field === EnumFielOrder.purchasedQuantity ||
          err.field === EnumFielOrder.quanty
        ) {
          return !err.isValid;
        }
      });
      if (errFilter) {
        return false;
      }
      return true;
    }
  }

  getErrors() {
    const { detail, warehouse } = this.data;
    const { order, shipphing, customer, addresses } = detail.data;

    const errors: OrderError[] = [
      {
        name: 'Orden',
        value: order.data.order,
        message: Order.getMessageError(order.data.order),
        field: EnumFielOrder.order,
        path: 'detail.data.order.data.order',
        isRequired: true,
        filedSend: 'order'
      },
      {
        name: 'Tipo de Envío',
        value: shipphing.data.type,
        message: Order.getMessageError(shipphing.data.type),
        field: EnumFielOrder.typeShipphing,
        input: 'select',
        path: 'detail.data.shipphing.data.type',
        filedSend: 'typeDelivery'
      },
      {
        name: 'Bodega',
        value: warehouse,
        message: Order.getMessageError(warehouse),
        field: EnumFielOrder.warehouse,
        path: 'warehouse',
        input: 'select',
        filedSend: 'warehouse'
      },
      {
        name: 'Nombre',
        value: customer.data.firstName,
        message: Order.getMessageError(customer.data.firstName),
        field: EnumFielOrder.firstName,
        path: 'detail.data.customer.data.firstName',
        constraints: VALIDATE_CONSTRAINTS.name,
        filedSend: 'firstName'
      },
      {
        name: 'Apellido',
        value: customer.data.lastName,
        message: Order.getMessageError(customer.data.lastName),
        field: EnumFielOrder.lastName,
        path: 'detail.data.customer.data.lastName',
        constraints: VALIDATE_CONSTRAINTS.name,
        filedSend: 'lastName'
      },
      {
        name: 'Email',
        value: customer.data.email,
        message: Order.getMessageError(customer.data.email),
        field: EnumFielOrder.email,
        path: 'detail.data.customer.data.email',
        constraints: VALIDATE_CONSTRAINTS.email,
        filedSend: 'email'
      },
      {
        name: 'Teléfono',
        value: customer.data.phone,
        message: Order.getMessageError(customer.data.phone),
        field: EnumFielOrder.phone,
        path: 'detail.data.customer.data.phone',
        constraints: VALIDATE_CONSTRAINTS.phone,
        filedSend: 'phone'
      },
      {
        name: 'Tipo de Documento',
        value: customer.data.documentType,
        message: Order.getMessageError(customer.data.documentType),
        field: EnumFielOrder.documentType,
        input: 'select',
        path: 'detail.data.customer.data.documentType',
        filedSend: 'typeDocument'
      },
      {
        name: 'N° Documento',
        value: customer.data.numberDocument,
        message: Order.getMessageError(customer.data.numberDocument),
        field: EnumFielOrder.numberDocument,
        path: 'detail.data.customer.data.numberDocument',
        filedSend: 'numDocument'
      },
      {
        name: 'Ciudad',
        value: addresses.data.state,
        message: Order.getMessageError(addresses.data.state),
        field: EnumFielOrder.city,
        input: 'select',
        path: 'detail.data.addresses.data.state',
        filedSend: 'city'
      },
      {
        name: nameDistrictForCountry(addresses.data.country),
        value: addresses.data.district,
        message: Order.getMessageError(addresses.data.district),
        field: EnumFielOrder.district,
        path: 'detail.data.addresses.data.district',
        input: 'select',
        filedSend: 'district'
      },
      {
        name: 'Dirección',
        value: addresses.data.adr,
        message: Order.getMessageError(addresses.data.adr),
        field: EnumFielOrder.adr,
        path: 'detail.data.addresses.data.adr',
        filedSend: 'address'
      },
      {
        name: 'Número Calle',
        value: addresses.data.number,
        message: Order.getMessageError(addresses.data.number),
        field: EnumFielOrder.streetNumber,
        path: 'detail.data.addresses.data.number',
        filedSend: 'numStreet'
      },
      {
        name: 'Departamento',
        value: addresses.data.department,
        message: Order.getMessageError(addresses.data.department),
        field: EnumFielOrder.department,
        path: 'detail.data.addresses.data.department',
        filedSend: 'department'
      },
      {
        name: 'Item',
        value: shipphing.data.items,
        message: Order.getMessageError(shipphing.data.items),
        field: EnumFielOrder.items,
        path: 'detail.data.shipphing.data.items',
        constraints: VALIDATE_CONSTRAINTS.onlyNumberPositive,
        filedSend: 'item'
      },
      {
        name: 'Artículos Comprados',
        value: shipphing.data.purchasedQuantity,
        message: Order.getMessageError(shipphing.data.purchasedQuantity),
        field: EnumFielOrder.purchasedQuantity,
        path: 'detail.data.shipphing.data.purchasedQuantity',
        constraints: VALIDATE_CONSTRAINTS.onlyNumberPositive,
        filedSend: 'article'
      },
      {
        name: 'Precio',
        value: shipphing.data.purchasePrice,
        message: Order.getMessageError(shipphing.data.purchasePrice),
        field: EnumFielOrder.purchasePrice,
        path: 'detail.data.shipphing.data.purchasePrice',
        constraints: VALIDATE_CONSTRAINTS.onlyNumberPositive,
        filedSend: 'price'
      },
      {
        name: 'Dimensiones',
        value: shipphing.data.size,
        message: Order.getMessageError(shipphing.data.size),
        field: EnumFielOrder.size,
        path: 'detail.data.shipphing.data.size',
        input: 'select',
        filedSend: 'dimensions'
      }
    ];

    this.data.errors = errors.filter(error => error.message !== undefined) || [];
    return errors;
  }

  isValide({
    value,
    field,
    cities,
    districts,
    order,
    dimensions,
    typesDelivery,
    typesDocument
  }: {
    value?: string;
    field?: string;
    cities?: string[];
    districts?: string[];
    order: Order;
    dimensions?: string[];
    typesDelivery?: Delivery[];
    typesDocument?: DocumentType[];
  }) {
    const errors = this.data.errors;
    const { shipphing } = this.data.detail.data;
    let isValidate = false;

    //console.log('isValide() => { value, field, cities }', { value, field, cities, citieFind: cities?.find(city => city === value) });
    if (field === EnumFielOrder.district) {
      if (districts?.find(district => district === value)) {
        isValidate = true;
      } else {
        isValidate = false;
      }
    } else if (field === EnumFielOrder.city) {
      if (cities?.find(city => city.toLowerCase() === value?.toLowerCase())) {
        isValidate = true;
      } else {
        isValidate = false;
      }
    } else if (field === EnumFielOrder.adr || field === EnumFielOrder.streetNumber) {
      if (shipphing.data.type !== TypeDelivery.pickup && shipphing.data.type !== TypeDelivery.pickupStore) {
        if (value && value !== '') {
          isValidate = true;
        } else {
          isValidate = false;
        }
      }
    } else if (field === EnumFielOrder.numberDocument) {
      const resultV = validate.single(
        value,
        VALIDATE_CONSTRAINTS.documentNum(order.data.detail.data.customer.data.documentType, order.data.detail.data.addresses.data.country)
      );
      if (!resultV) {
        isValidate = true;
      } else {
        isValidate = false;
      }
    } else if (field === EnumFielOrder.order) {
      if (value !== undefined && value !== '' && value !== order.isNumOrderDuplicate()) {
        isValidate = true;
      } else {
        isValidate = false;
      }
    } else if (field === EnumFielOrder.size) {
      isValidate = dimensions?.find(dim => dim === value) ? true : false;
    } else if (field === EnumFielOrder.typeShipphing) {
      isValidate = typesDelivery?.find(typeDelivery => typeDelivery.data.value === value) ? true : false;
    } else if (field === EnumFielOrder.lastName) {
      isValidate = validate.single(value, VALIDATE_CONSTRAINTS.name) ? false : true;
    } else if (field === EnumFielOrder.firstName) {
      isValidate = validate.single(value, VALIDATE_CONSTRAINTS.name) ? false : true;
    } else if (field === EnumFielOrder.email) {
      isValidate = validate.single(value, VALIDATE_CONSTRAINTS.email) ? false : true;
    } else if (field === EnumFielOrder.documentType) {
      isValidate = typesDocument?.find(typeDoc => typeDoc.data.value === value) ? true : false;
    } else if (field === EnumFielOrder.purchasePrice) {
      isValidate = validate.single(value, VALIDATE_CONSTRAINTS.onlyNumberPositive) ? false : true;
    } else if (field === EnumFielOrder.purchasedQuantity) {
      isValidate = validate.single(value, VALIDATE_CONSTRAINTS.onlyNumberPositive) ? false : true;
    } else if (field === EnumFielOrder.items) {
      isValidate = validate.single(value, VALIDATE_CONSTRAINTS.onlyNumberPositive) ? false : true;
    } else if (field === EnumFielOrder.phone) {
      isValidate = validate.single(value, VALIDATE_CONSTRAINTS.phone) ? false : true;
    }

    errors?.forEach(error => {
      if (error.field === field) {
        error.isValid = isValidate;
      }
    });
    return isValidate;
  }

  updateNumDocument(value?: any) {
    if (this.data && this.data.detail.data.customer.data.numberDocument) {
      this.data.detail.data.customer.data.numberDocument = value;
    }
  }

  /** actuliza la orden actula con la data que llega del websocket **/
  updateDataWebSocket(orderWS: OrderModelWebSocket) {
    const { id, dateSendEstimate, alertOrder, deliveryCost, status, totalAmount } = orderWS;

    //console.log('updateDataWebSocket() => orderWS', { orderWS, data: this.data });
    this.data.id = id;
    //this.data.createdAt = Order.formatStringData(createdAt);
    this.data.dateSendEstimate = dateSendEstimate;
    this.data.formattedDateSendEstimate = Order.formatStringData(dateSendEstimate);
    this.data.deliveryCost = `${deliveryCost}`;
    this.data.status = status;
    this.data.totalAmount = totalAmount;
    this.data.alertOrder = OrderAlert.parseAlert(alertOrder);
  }

  getTimeLimit(): Duration | undefined {
    try {
      const { formattedDateSendEstimate } = this.data;

      if (formattedDateSendEstimate) {
        const resultDuration = durationWithDiff({
          date: formattedDateSendEstimate,
          format: FormatDate.yyyymmddAltHHmm,
          dateRef: getDateNow({ format: FormatDate.yyyymmddAltHHmm }) as string,
          formatRef: FormatDate.yyyymmddAltHHmm
        });
        //console.log('getTimeLimit() => resultDuration', resultDuration);
        return resultDuration;
      }
      return undefined;
    } catch (err) {
      console.log('getTimeLimit() => err', err);
      return undefined;
    }
  }

  getDataTable() {
    const {
      createdAt,
      formattedDateSendEstimate,
      detail,
      status,
      deliveryCost,
      totalAmountDisplay,
      typeUpload,
      formattedDateDelivered,
      warehouse
    } = this.data;
    const creationDateSplit = `${createdAt}`.split(' ');
    const estimatedDateSplit = formattedDateSendEstimate?.split(' ');
    const deliveredDateSplit = formattedDateDelivered?.split(' ');

    let dataTable: DataTableOrder = {
      order: {
        order: detail.data.order.data.order || '',
        creationDate: creationDateSplit && creationDateSplit.length > 0 ? creationDateSplit[0] : '',
        creationTime: creationDateSplit && creationDateSplit.length > 0 ? creationDateSplit[1] : '',
        estimatedDate: estimatedDateSplit && estimatedDateSplit.length > 0 ? estimatedDateSplit[0] : '',
        estimatedTime: estimatedDateSplit && estimatedDateSplit.length > 0 ? estimatedDateSplit[1] : '',
        deliveredDate: deliveredDateSplit && deliveredDateSplit.length > 0 ? deliveredDateSplit[0] : '',
        deliveredTime: deliveredDateSplit && deliveredDateSplit.length > 0 ? deliveredDateSplit[1] : '',
        warehouse: warehouse || ''
      },
      store: detail.data.order.data.store,
      status,
      deliveryCost,
      totalAmount: totalAmountDisplay,
      typeUpload
    };

    if (detail) {
      if (detail.data.customer) {
        const { lastName, email, firstName, numberDocument, phone } = detail.data.customer.data;

        dataTable = {
          ...dataTable,
          client: {
            fullName: `${firstName} ${lastName}`,
            numDocument: numberDocument,
            phone: phone,
            email: email
          }
        };
      }

      if (detail.data.addresses) {
        const { state, district } = detail.data.addresses.data;
        dataTable = {
          ...dataTable,
          location: {
            city: state,
            district: district,
            address: `${detail.data.addresses.data.adr} ${detail.data.addresses.data.number}`
          }
        };
      }

      if (detail.data.shipphing) {
        const { type, purchasePrice, items, size } = this.data.detail.data.shipphing.data;

        dataTable = {
          ...dataTable,
          typeDelivery: type,
          price: purchasePrice,
          items,
          size
        };
      }
      if (warehouse) {
        dataTable = {
          ...dataTable,
          warehouse: warehouse || ''
        };
      }
    }
    return dataTable;
  }

  onErrorAndChecked(value: boolean) {
    this.data.checked = value;
    this.data.error = !value;
  }

  onChecked(value: boolean) {
    if (!this.data.error) {
      this.data.checked = value;
    }
  }

  onCheckedDeleted(value?: boolean) {
    if (value !== undefined) {
      console.log('onCheckedDeleted() => value', value);
      this.data.isSelectDelete = value;
    } else {
      this.data.isSelectDelete = !this.data.isSelectDelete;
    }
  }

  isNumOrderDuplicate() {
    const { detail } = this.data;
    const regex = /El número de órden ya ha sido creado *?{{(.*?)}}/;

    const data = detail.data.order.data.order;
    const match = data.match(regex);

    if (match && match.length > 1) {
      return match[1];
    }

    return undefined;
  }

  sendServerUpdate() {
    const { detail } = this.data;
    const { shipphing } = detail.data;

    return {
      size: shipphing.data.size
    };
  }

  static headerEmailsTable(): TableColumn<emailEventLogsModelServer>[] {
    return [
      {
        name: <FilterHeaderColumn title='Estado de orden notificado' classNameContainer='text-primary font-normal text-sm' />,
        cell: row => (
          <div className='w-full flex flex-col items-center py-4'>
            <div className={`${stringStatusDeliveryToColor(row.status_order)} mb-2 px-2 rounded-full`}>
              <p className='text-white font-normal text-sm'>{row.status_order || ''}</p>
            </div>
            {row.forwarded && (
              <div className='text-primary font-normal'>
                <p className='text-black font-normal text-xs'>Reenviado</p>
              </div>
            )}
          </div>
        )
      },
      {
        name: <FilterHeaderColumn title='Fecha de envío' classNameContainer='text-primary font-normal text-sm' />,
        selector: row => row.date_sent,
        reorder: true,
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={formatDate(row.date_sent)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Estado' classNameContainer='text-primary font-normal text-sm' />,
        cell: row => (
          <div className='w-full flex flex-row justify-center'>
            {!row.email_sent ? (
              <Tooltip
                trigger={['hover', 'focus']}
                placement='top'
                overlay={
                  <div className='flex flex-row w-full'>
                    <div className='flex flex-col items-end justify-end mr-2'>
                      <p className='text-xs font-normal'>{row?.error}</p>
                    </div>
                  </div>
                }
                getPopupContainer={triggerNode => triggerNode}
                overlayClassName='ant-tooltip-content-info w-full'
                overlayInnerStyle={{ backgroundColor: 'white', borderRadius: 5, color: 'black', padding: 10 }}
                color={'white'}
              >
                <button className='flex self-center h-4'>
                  <IconCustom name={NameIcons.cancel} className='text-red-700' />
                </button>
              </Tooltip>
            ) : (
              <IconCustom className='text-green-700' name={NameIcons.done} />
            )}
          </div>
        )
      }
    ];
  }
}

export default Order;
