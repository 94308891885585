import { AxiosResponse } from 'axios';
import { EnumCountries } from 'configs/Enums';
//import { URL_API_TEST } from 'configs/Constants';

import { ResponseApp } from 'configs/Interface';
import { convertAllAtCountries } from 'helpers/Converts';

import { handleErrorServiceApi } from 'helpers/Errors';
import Country from 'models/Country';

import Store from 'models/store';
import StoreJanis from 'models/store/StoreJanis';

import api from '.';

class StoreProvider {
  async createStore(store: Store): Promise<AxiosResponse | ResponseApp> {
    try {
      console.log('createStore() ==>', store.sendServer());
      return await api().post(`shop/`, store.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateStore(store: Store): Promise<AxiosResponse | ResponseApp> {
    try {
      console.log('updateStore() ==>', store.sendServer());
      return await api().put(`shop/${store.data.id}`, store.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async deleteStore(store: Store): Promise<AxiosResponse | ResponseApp> {
    try {
      console.log('updateStore() ==>', store.sendServer());
      return await api().delete(`shop/${store.data.id}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getStoreJanisById(storeJanis: StoreJanis): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`shop/janis?name=store&id=${storeJanis.data.id}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getInfoStoresAvailables(country?: string): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`shop/available${country ? `?country=${country}` : ''}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getInfoStores(country?: string): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`shop/janis?name=store${country ? `&country=${country}` : ''}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getInfoWarehouse({ idWarehouse, country }: { idWarehouse: string; country?: string }): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`shop/janis?name=warehouse&id=${idWarehouse}${country ? `&country=${country}` : ''}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getInfoSaleChannel({ idSaleChannel, country }: { idSaleChannel: string; country?: string }): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`shop/janis?name=sales-channel&id=${idSaleChannel}${country ? `&country=${country}` : ''}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getShop(country?: string): Promise<AxiosResponse | ResponseApp> {
    try {
      if (country) {
        let countries: string = country;
        if (country === EnumCountries.all) {
          countries = `${EnumCountries.cl},${EnumCountries.col},${EnumCountries.per}`;
        }
        return await api().get(`shop/?country=${countries}`);
      }
      return await api().get(`shop/`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getTypesOperator(): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`shop/operator`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getStoreById(id?: string): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`shop/${id}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getWarehouse({ country, countries }: { country?: string; countries?: Country[] }): Promise<AxiosResponse | ResponseApp> {
    try {
      console.log('getWarehouse() ==> { country, countries }', {
        country,
        countries,
        convert: convertAllAtCountries({ country, countries, isString: true })
      });
      return await api().get(`warehouses?country=${convertAllAtCountries({ country, countries, isString: true })}`);
      //return await api({ url: URL_API_TEST }).get('warehouse');
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new StoreProvider();
