import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { PathRoute } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';

import RecoverPassword from 'components/molecules/forms/RecoverPassword';

const RecoverPasswordPage = () => {
  const { recoverPasswordUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const recoverPassword = async (email: string) => {
    const result = await recoverPasswordUser(email);
    //console.log('recoverPassword() ===>', result);
    if (result) {
      navigate(PathRoute.signin);
    }
  };

  return <RecoverPassword onRecoverPassword={recoverPassword} />;
};

export default RecoverPasswordPage;
