import { ReactNode, useContext, useEffect, useState } from 'react';
import { Pie, measureTextWidth } from '@ant-design/plots';

import { numberAtPercentage } from 'helpers/Converts';

import { RootContext } from 'context/RootContext';

import DonutSkeleton from './Donut.skeleton';
import { EnumStatusDelivery, SizeScreen, TypeDelivery, TypeDeliveryDisplay } from 'configs/Enums';

export type DonutGraphicsData = {
  name?: string;
  value: number;
  color?: string;
};

export type DonutGraphicsProps = {
  data?: DonutGraphicsData[];
  title?: string;
  /** texto del centro de la gráfica **/
  textCenter?: string;
  classNameContainer?: string;
  componentLeft?: ReactNode;
  isColor?: boolean;
  componentRight?: ReactNode;
  componentTop?: ReactNode;
};

const ThemeColor = {
  colors10: ['#6E9B3C', '#BE231E', '#FBBF24', '#154BAE', '#c4c4c4'],
  colors11: ['#154BAE', '#6E9B3C', '#BE231E', '#C4C4C4']
};

const config = {
  appendPadding: 10,
  angleField: 'value',
  colorField: 'name',
  radius: 1,
  innerRadius: 0.6,
  meta: {
    value: {
      formatter: (v: any) => `${v} ¥`
    }
  },
  label: {
    type: 'inner',
    offset: '-50%',
    content: '{value}',
    style: {
      textAlign: 'center',
      fontSize: 14
    }
  },
  interactions: [
    {
      type: 'legend-filter',
      enable: false
    },
    {
      type: 'pie-statistic-active'
    },
    {
      type: 'element-active'
    }
  ]
};

const convertNameAtColor = (name: string) => {
  const nameParse = name?.toLowerCase();
  if (nameParse === TypeDeliveryDisplay.expressDelivery.toLowerCase() || nameParse === EnumStatusDelivery.delivered.toLowerCase()) {
    return '#6E9B3C';
  } else if (nameParse === TypeDeliveryDisplay.nextDay.toLowerCase() || nameParse === TypeDelivery.deliveryNextDay.toLowerCase()) {
    return '#FBBF24';
  } else if (
    nameParse === TypeDeliveryDisplay.sameDay.toLowerCase() ||
    nameParse === TypeDelivery.deliverySameDay.toLowerCase() ||
    nameParse === EnumStatusDelivery.notDelivered.toLowerCase() ||
    nameParse === 'órdenes fallidas'
  ) {
    return '#BE231E';
  } else if (
    nameParse === TypeDeliveryDisplay.pickupStore.toLowerCase() ||
    nameParse === EnumStatusDelivery.earring ||
    nameParse === EnumStatusDelivery.pendingDelivery
  ) {
    return '#154BAE';
  } else if (nameParse === EnumStatusDelivery.cancelled.toLowerCase()) {
    return '#c4c4c4';
  } else if (nameParse === 'órdenes canceladas') {
    return '#00379B';
  } else if (nameParse === 'órdenes no canceladas' || nameParse === 'marcas inactivas' || nameParse === 'órdenes correctas') {
    return '#C4C4C4';
  } else if (nameParse === 'marcas activas') {
    return '#6E9B3C';
  }
  return undefined;
};

const renderStatistic = (containerWidth: any, text: any, style: any) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
  }

  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
};

const DonutGraphics = ({
  data,
  title,
  textCenter,
  classNameContainer,
  componentLeft,
  isColor = true,
  componentRight,
  componentTop
}: DonutGraphicsProps) => {
  const [total, setTotal] = useState(0);
  const { screenResolution } = useContext(RootContext);

  useEffect(() => {
    if (data && data.length > 0) {
      const totalReducer = data.reduce((prev: any, curr: DonutGraphicsData) => {
        if (typeof prev === 'number') {
          return prev + curr.value;
        }
        return prev.value + curr.value;
      });
      //console.log('data ==>', { totalReducer, data });
      if (typeof totalReducer === 'number') {
        setTotal(totalReducer);
      }
    }
    //console.log('DonutGraphics() =>', data);
  }, [data]);

  if (!data) {
    return <DonutSkeleton isActive={!data} />;
  }

  return (
    <div
      className={`bg-white flex flex-col flex-1 border-grayarauco-300 p-8 relative ${classNameContainer}`}
      style={{ borderWidth: 0.6, borderRadius: 10 }}
    >
      <div className='flex justify-between flex-col lg:flex-row'>
        <h1 className='text-lg font-bold text-black mb-4 lg:mb-0'>{title}</h1>
        {componentRight}
      </div>
      {componentTop}

      {screenResolution <= SizeScreen.lg && componentLeft && (
        <div className='flex justify-center items-center mt-4' style={{ flex: 2 }}>
          {componentLeft}
        </div>
      )}
      <div className='flex flex-row flex-1'>
        {screenResolution > SizeScreen.lg && componentLeft && (
          <div className='flex justify-end' style={{ flex: 2 }}>
            {componentLeft}
          </div>
        )}
        <div style={{ flex: 10 }} className='flex h-96'>
          <Pie
            {...{ data }}
            className='w-full'
            legend={{
              itemName: {
                formatter: (text, _, index) => {
                  return `${numberAtPercentage({ value: data[index].value || 0, total: total || 0 })}%  ${text}`;
                },
                style: { fontSize: 16, fill: '#000000', fontStyle: 'normal', fontWeight: 'normal' }
              },
              maxItemWidth: 250,
              layout: 'vertical',
              position: screenResolution < SizeScreen.md ? 'bottom' : 'right'
            }}
            theme={ThemeColor}
            color={
              isColor
                ? (datum: any, defaultColor?: string) => {
                    //console.log('color ==> { datum, defaultColor }', { datum, defaultColor });
                    return convertNameAtColor(datum.name) || defaultColor || '';
                  }
                : ThemeColor.colors11
            }
            statistic={{
              //title: false,
              title: {
                offsetY: -4,
                customHtml: (container, _, datum) => {
                  const { width, height } = container.getBoundingClientRect();
                  const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                  //console.log('customHtml ==> ', { d, datum });
                  const text = datum ? datum.name : textCenter;
                  return renderStatistic(d, text, {
                    fontSize: screenResolution >= SizeScreen.lg ? 28 : 16
                  });
                }
              },
              content: {
                offsetY: 4,
                style: {
                  whiteSpace: 'pre-wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                },
                customHtml: (container, _, datum, dataC) => {
                  const { width } = container.getBoundingClientRect();
                  const text = datum ? `${datum.value}` : `${dataC?.reduce((r, d) => r + d.value, 0)}`;
                  return renderStatistic(width, text, {
                    fontSize: screenResolution >= SizeScreen.lg ? 32 : 28
                  });
                }
              }
            }}
            {...config}
          />
        </div>
      </div>
    </div>
  );
};

export default DonutGraphics;
