import { Checkbox, CheckboxProps } from 'antd';

type CheckboxCustomProps = CheckboxProps;

const CheckboxCustom = ({ checked, children, style, className, ...other }: CheckboxCustomProps) => {
  return (
    <Checkbox style={style} className={`checkbox-custom ${className}`} {...{ checked }} {...other}>
      {children}
    </Checkbox>
  );
};

export default CheckboxCustom;
