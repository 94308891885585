import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import DeliverySchedule from 'models/delivery/DeliverySchedule';
import CutOffScheduleSettingOrganism from './CutOffSchedule';

import { getDeliveriesConfigByCommerce } from 'services/app/Delivery';

type CutOffScheduleBoardProps = {
  schedules: DeliverySchedule[];
  commerce?: string;
  classNameContainer?: string;
  onUpdateSchedules?: (schedules: DeliverySchedule[]) => void;
};

const CutOffScheduleBoard = ({ schedules, commerce, classNameContainer, onUpdateSchedules }: CutOffScheduleBoardProps) => {
  const { countryUser } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(RootContext);

  const [schedulesCurrent, setSchedulesCurrent] = useState<DeliverySchedule[]>(schedules);

  const loadCutOffSchedule = async () => {
    showLoading();
    const { status, data } = await getDeliveriesConfigByCommerce({ commerceId: commerce, country: countryUser });
    console.log('loadCutOffSchedule() ==> { status, data }', { status, data, commerce });

    if (status) {
      setSchedulesCurrent(data);
    } else {
      setSchedulesCurrent([]);
    }
    hideLoading();
  };

  const onUpdateSchedule = ({ type, value }: { type: 'update' | 'delete' | 'create'; value: DeliverySchedule }) => {
    console.log('onUpdateSchedules() => { type, value }', { type, value });
    if (type === 'update') {
      const schedulesNews: DeliverySchedule[] = [];
      schedulesCurrent.forEach(schedule => {
        const sched = typeof schedule.data.delivery === 'string' ? schedule.data.delivery : schedule.data.delivery?.data.value;
        const val = typeof value.data.delivery === 'string' ? value.data.delivery : value.data.delivery?.data.value;
        if (sched === val) {
          schedule = value;
        }
        schedulesNews.push(schedule);
      });
      console.log('onUpdateSchedules => { schedulesCurrent, schedulesNews }', { schedulesCurrent, schedulesNews });
      if (onUpdateSchedules) {
        onUpdateSchedules(schedulesNews);
      }
      setSchedulesCurrent(schedulesNews);
    } else if (type === 'delete') {
      const newScheduleCurrent = schedulesCurrent.filter(schedule => {
        const sched = typeof schedule.data.delivery === 'string' ? schedule.data.delivery : schedule.data.delivery?.data.value;
        const val = typeof value.data.delivery === 'string' ? value.data.delivery : value.data.delivery?.data.value;
        if (sched !== val) {
          return true;
        }
        return false;
      });
      console.log('onUpdateSchedules() => newScheduleCurrent', newScheduleCurrent);
      if (onUpdateSchedules) {
        onUpdateSchedules(newScheduleCurrent);
      }
      setSchedulesCurrent(newScheduleCurrent);
    } else if (type === 'create') {
      const schedulesCurrentNew = [...schedulesCurrent, value];
      if (onUpdateSchedules) {
        onUpdateSchedules(schedulesCurrentNew);
      }
      setSchedulesCurrent(schedulesCurrentNew);
    }
  };

  useEffect(() => {
    if (commerce) {
      loadCutOffSchedule();
    }
  }, [commerce]);

  return (
    <div className={classNameContainer}>
      <CutOffScheduleSettingOrganism
        loadSchedulesConfig={() => loadCutOffSchedule()}
        schedules={schedulesCurrent || []}
        isFullPage={false}
        isNewShop={!commerce}
        onUpdateSchedules={onUpdateSchedule}
        {...{ commerce }}
      />
    </div>
  );
};

export default CutOffScheduleBoard;
