export interface TypeOperatorModel {
  name?: string;
  value?: string;
  id?: string;
  description?: string;
  createdAt?: string;
}

export interface TypeOperatorServerModel {
  name?: string;
  id?: string;
  description?: string;
  createdAt?: string;
}

class TypeOperator {
  data: TypeOperatorModel;

  constructor(data: TypeOperatorModel) {
    this.data = data;
  }

  static formalizeData(data: TypeOperatorServerModel): TypeOperatorModel {
    const { name, id, description, createdAt } = data;

    return {
      name,
      value: name,
      id,
      description,
      createdAt
    };
  }
}

TypeOperator.prototype.toString = function countryToString() {
  return `${this.data.id}-${this.data.name}`;
};

export default TypeOperator;
