import { ChangeEvent, ReactNode } from 'react';

import { Input } from 'antd';

type InputSearchProps = {
  onChange?: (value?: string) => void;
  className?: string;
  placeholder?: string;
  onSearch?: (
    value: string,
    event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  enterButton?: React.ReactNode;
  allowClear?:
    | boolean
    | {
        clearIcon?: ReactNode;
      };
  onFocus?: () => void;
  onBlur?: () => void;
};

const InputSearch = ({ onChange, className, onSearch, enterButton, allowClear, placeholder, onFocus, onBlur }: InputSearchProps) => {
  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('onChangeSearch', event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Input.Search
      onChange={onChangeSearch}
      style={{ borderRadius: 10, borderWidth: 0.6 }}
      className={`input-search overflow-hidden border-primary ${className}`}
      bordered={false}
      {...{ onSearch, enterButton, allowClear, placeholder, onFocus, onBlur }}
    />
  );
};

export default InputSearch;
