import { useContext, useState } from 'react';
import { Tabs } from 'antd';

import { NameIcons, SizeScreen } from 'configs/Enums';

import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';
import ColumnCustom from 'components/molecules/graphics/column/Column';
import LineCustom from 'components/molecules/graphics/line/Line';
import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';

import DemandPickupStatistics from 'models/statistic/DemandPickupStatistics';

type DemandStatisticsProps = {
  data?: any;
  onFilter?: (params: ParamsValueFilterStatistic) => void;
  paramsFilter?: ParamsValueFilterStatistic;
  demandPickup?: DemandPickupStatistics;
};

export enum FilterTime {
  average = 'average',
  currentMonth = 'month',
  currentWeek = 'week',
  today = 'week'
}

const DemandStatistics = ({ demandPickup }: DemandStatisticsProps) => {
  const { screenResolution } = useContext(RootContext);

  const [tabActive, setTabActive] = useState<'day' | 'schedule'>('day');
  const [subTabActive, setSubTabActive] = useState<FilterTime>(FilterTime.average);

  return (
    <div>
      <h1 className='text-lg font-bold'>Demanda Pickup de días y horarios</h1>

      <div className='flex items-start mt-4 md:mt-8 flex-col md:items-center md:flex-row'>
        <div className='items-center flex'>
          <IconCustom name={NameIcons.today} className='text-4xl text-primary mr-4' />

          <div className='flex flex-col'>
            <p className='font-bold text-base'>Día con mas entregas: {demandPickup?.data.day[subTabActive].more || ''}</p>
            <p className='font-normal text-base'>Día con menos entregas: {demandPickup?.data.day[subTabActive].less || ''}</p>
          </div>
        </div>

        <div className='ml-0 mt-4 md:mt-0 md:ml-10 items-center flex'>
          <IconCustom name={NameIcons.schedule} className='text-4xl text-primary mr-4' />

          <div className='flex flex-col'>
            <p className='font-bold text-base'>Horario con mas entregas: {demandPickup?.data.schedule[subTabActive].more || ''}</p>
            <p className='font-normal text-base'>Horario con menos entregas: {demandPickup?.data.schedule[subTabActive].less || ''}</p>
          </div>
        </div>
      </div>

      <div className='flex w-full justify-end items-end border-b-2 border-grayarauco-200 py-0 mt-2'>
        <div
          className={`flex p-2 w-52 justify-center items-center ${
            tabActive === 'day' ? 'bg-primary border border-primary' : 'bg-transparent border border-grayarauco-200'
          }`}
          style={{ borderTopLeftRadius: 10, marginBottom: -2 }}
          onClick={() => {
            setTabActive('day');
          }}
        >
          <p className={`text-base ${tabActive === 'day' ? 'text-white' : 'text-grayarauco-400'}`}>Días</p>
        </div>

        <div
          className={`flex p-2 w-52 justify-center items-center my-0 ${
            tabActive === 'schedule' ? 'bg-primary border border-primary' : 'bg-transparent border border-grayarauco-200'
          }`}
          style={{ borderTopRightRadius: 10, marginBottom: -2 }}
          onClick={() => {
            setTabActive('schedule');
          }}
        >
          <p className={`text-base ${tabActive === 'schedule' ? 'text-white' : 'text-grayarauco-400'}`}>Horarios</p>
        </div>
      </div>

      <div>
        <div className='flex justify-center mt-7'>
          <Tabs
            centered
            className='ant-tab-original'
            style={{ width: screenResolution >= SizeScreen.md ? '30%' : 'auto' }}
            onChange={val => setSubTabActive(val as FilterTime)}
          >
            <Tabs.TabPane
              className='justify-center items-center flex'
              tabKey='Promedio'
              key={FilterTime.average}
              tab={<div className='flex justify-center items-center my-4 text-base md:text-lg'>Últimos 6 meses</div>}
            ></Tabs.TabPane>
            <Tabs.TabPane
              tabKey='Mes actual'
              key={FilterTime.currentMonth}
              tab={<div className='flex justify-center items-center my-4 text-base md:text-lg'>Mes actual</div>}
            ></Tabs.TabPane>

            {tabActive === 'day' && (
              <Tabs.TabPane
                tabKey='Semana actual'
                key={FilterTime.currentWeek}
                tab={<div className='flex justify-center items-center my-4 text-base md:text-lg'>Semana actual</div>}
              ></Tabs.TabPane>
            )}
            {tabActive === 'schedule' && (
              <Tabs.TabPane
                tabKey='hoy'
                key={FilterTime.today}
                tab={<div className='flex justify-center items-center my-4 text-base md:text-lg'>Hoy</div>}
              ></Tabs.TabPane>
            )}
          </Tabs>
        </div>

        <div>
          {tabActive === 'day' && (
            <ColumnCustom
              titleX='Días de la semana'
              titleY='Promedio de entregas Pickup'
              color={(data: any) => {
                //console.log('color ==>', { data, other });
                return data.color || '#154BAE';
              }}
              seriesField='color'
              data={demandPickup?.data.day[subTabActive].items}
              legend={false}
            />
          )}
          {tabActive === 'schedule' && (
            <LineCustom
              slider={{
                start: 0,
                end: 1
              }}
              data={demandPickup?.data.schedule[subTabActive].items.map(item => ({ name: item.name, value: item.value, type: 'schedule' }))}
              titleX=''
              titleY='Cantidad'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DemandStatistics;
