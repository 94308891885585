import { EnumCountries } from 'configs/Enums';

import { typeDeliveryAtTypeDeliveryServer, typeDocAtTypeDocServer } from 'helpers/Converts';

import Delivery from 'models/Delivery';
import WarehousePA from 'models/warehouse';

export interface FormManualOrderModel {
  order?: number;
  dateSale?: string;
  timeSale?: string;
  typeDelivery?: Delivery;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  typeDocument?: string;
  numDocument?: string;
  city?: string;
  district?: string;
  direction?: string;
  numStreet?: string;
  department?: string;
  item?: number;
  totalItems?: number;
  amount?: number;
  dimensions?: string;
  warehouse?: WarehousePA;
}

export interface FormManualOrderServerModel {
  order: number | string;
  date?: string;
  typeDelivery: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  typeDocument: string;
  numDocument: string;
  city: string;
  district: string;
  address: string;
  numStreet: string;
  department: string;
  item: number | string;
  article: number | string;
  price: number | string;
  dimensions: string;
}

export enum FormManualOrderModelParams {
  order = 'order',
  dateSale = 'dateSale',
  timeSale = 'timeSale',
  typeDelivery = 'typeDelivery',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  typeDocument = 'typeDocument',
  numDocument = 'numDocument',
  city = 'city',
  district = 'district',
  direction = 'direction',
  numStreet = 'numStreet',
  department = 'department',
  item = 'item',
  totalItems = 'totalItems',
  amount = 'amount',
  dimensions = 'dimensions'
}

class FormManualOrder {
  data: FormManualOrderModel;

  constructor(data: FormManualOrderModel) {
    this.data = data;
  }

  sendServer(country?: string): FormManualOrderServerModel {
    const {
      order,
      typeDelivery,
      firstName,
      lastName,
      email,
      phone,
      typeDocument,
      numDocument,
      city,
      district,
      direction,
      numStreet,
      department,
      item,
      totalItems,
      amount,
      dimensions,
      warehouse
    } = this.data;

    let typeDocumentFormat = typeDocAtTypeDocServer(typeDocument || '', country);
    const numDocumentFormat = numDocument || '';

    if ((numDocument === '' || !numDocument) && country === EnumCountries.cl) {
      typeDocumentFormat = '';
    }
    numDocumentFormat.replace('K', '-K').replace('k', '-K');

    const dataSend = {
      address: direction || '',
      article: totalItems || '0',
      city: city || '',
      department: department || '',
      dimensions: dimensions || '',
      district: district || '',
      email: email || '',
      firstName: firstName || '',
      item: item || '0',
      lastName: lastName || '',
      numDocument: numDocumentFormat || '',
      numStreet: numStreet || '',
      order: order || '0',
      phone: phone || '',
      price: (amount || '0').toString(),
      typeDelivery: `${typeDeliveryAtTypeDeliveryServer(typeDelivery?.data.value || '')}`,
      typeDocument: typeDocumentFormat,
      typeUpload: 'unitario',
      warehouse: warehouse?.data.id || ''
    };

    //console.log('dataSend =>', dataSend);
    return dataSend;
  }
}

export default FormManualOrder;
