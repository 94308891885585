import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'antd';

import { PathRoute } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

type SectionDashboardSubRoutesProps = {
  subRoute: { icon: string; name: string; path: PathRoute; type: number };
  isExpand?: boolean;
  hideText?: boolean;
  onClick?: ({ onAction }: { onAction: () => void }) => void;
  badge?: number;
};

const SectionDashboardSubRoutes = ({ subRoute, badge, isExpand, onClick, hideText }: SectionDashboardSubRoutesProps) => {
  const [over, setOver] = useState(false);

  const navigate = useNavigate();

  return (
    <div className={`border-araucoblue-200 ${isExpand ? 'bg-grayarauco-100' : ''}`} style={{ borderBottomWidth: 0.6 }}>
      <button
        key={subRoute.path}
        className={`flex flex-row items-center justify-between text-grayarauco-400`}
        onMouseOver={() => {
          setOver(true);
        }}
        onMouseOut={() => {
          setOver(false);
        }}
        onClick={() => {
          if (onClick) {
            onClick({
              onAction: () => {
                if (subRoute.path) {
                  navigate(subRoute.path);
                }
              }
            });
          }
        }}
      >
        <span className='inline-flex items-center justify-center h-12 w-14 text-lg text-black'>
          <IconCustom name={subRoute.icon} className={`${over ? 'text-araucoblue-200' : 'text-grayaraucofive'}`} />
        </span>
        {!hideText && <span className={`text-sm font-medium ${over ? 'text-primary' : 'text-black'}`}>{subRoute.name}</span>}
        <Badge offset={[hideText ? -30 : 0, 0]} className={`${hideText ? '' : 'ml-2'}`} count={badge} overflowCount={99} />
      </button>
    </div>
  );
};

export default SectionDashboardSubRoutes;
