import { TableColumn } from 'react-data-table-component';
import { Duration } from 'moment';

import { VALIDATE_CONSTRAINTS } from 'configs/Constants';
import { FormatTime, NameIcons, TypeDelivery, UnitDate } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';
import FilterHeaderColumn from 'components/molecules/headers/FilterHeaderColumn';
import TableCellMolecule from 'components/molecules/items/TableCell';

import { shippingTypeStringToDisplay, stringAtDuration, stringDeliveryToIcon } from 'helpers/Converts';

import Delivery from 'models/Delivery';
import { FormItemModel } from 'models/form';

export interface DeliveryScheduleModel {
  open?: string;
  time?: string;
  delivery?: string | Delivery;
  id?: string;
  close?: string;
  country?: string;
  stopDelivery?: string;
  commerce?: string;
}

export interface DeliveryScheduleServerModel {
  updatedAt: string;
  createdAt: string;
  open: string;
  time: string;
  delivery: string;
  id: string;
  close: string;
  country: string;
  stopDelivery?: string;
  commerce?: string;
}

class DeliverySchedule {
  data: DeliveryScheduleModel;

  constructor(data: DeliveryScheduleModel) {
    this.data = data;
  }

  static isParameterInvalid(param?: string): boolean {
    if (param) {
      return param.indexOf('Error:') !== -1;
    }
    return false;
  }

  static removeFlag(param?: string): string {
    if (param) {
      return param.replace('Error:', '');
    }
    return '';
  }

  static formalizeData(data: DeliveryScheduleServerModel): DeliveryScheduleModel {
    const { open, time, delivery, id, close, country, stopDelivery, commerce } = data;
    let timeDuration = time;
    if (time) {
      const timeSplit = time.split(':');
      //console.log('formalizeData() =>', timeSplit);
      if (timeSplit && timeSplit.length > 0) {
        if (timeSplit[0].length === 1 && timeSplit[0] !== '0') {
          timeSplit[0] = `0${timeSplit[0]}`;
        }

        timeDuration = `${timeSplit[0]}`;

        if (timeSplit.length > 1) {
          if (timeSplit[1].length === 1) {
            timeSplit[1] = `0${timeSplit[1]}`;
          }
          timeDuration = `${timeDuration}:${timeSplit[1]}`;
        }
      }
    }
    console.log('formalizeData() =>', timeDuration);

    const deliveryFormat = delivery;

    if (delivery) {
      if (delivery === TypeDelivery.pickup || delivery === TypeDelivery.pickupStore) {
        const duration = stringAtDuration({ value: time });
        console.log('formalizeData() => duration', { duration, time, days: duration?.get(UnitDate.days) });
        timeDuration = `${duration?.get(UnitDate.days)}`;
      }
    }

    return {
      open: open === '00:00' ? '' : open,
      time: timeDuration === '00:00' ? '' : timeDuration,
      delivery: deliveryFormat,
      id,
      close: close === '00:00' ? '' : close,
      country,
      stopDelivery: stopDelivery === '00:00' ? '' : stopDelivery,
      commerce: commerce || 'global'
    };
  }

  static formFieldsUpdate({ values }: { values?: DeliverySchedule }): FormItemModel[] {
    //console.log('formFields() ==> ', { shops, values });
    let labelDuration = 'Duración tiempo envío (HH:mm)';
    let hourDelivery = 'Hora estimada (entrega) (HH:mm)';
    let typeDuration: any = 'duration';

    if (values && values.data.delivery) {
      if (values.data.delivery === TypeDelivery.pickup || values.data.delivery === TypeDelivery.pickupStore) {
        labelDuration = 'Dias de retiro';
        typeDuration = 'select';
      } else if (values.data.delivery === TypeDelivery.nextDay || values.data.delivery === TypeDelivery.sameDay) {
        hourDelivery = 'Hora de último delivery (HH:mm)';
      }
    }

    return [
      {
        label: 'Hora apertura',
        type: 'time',
        identifier: 'open',
        isRequired: true, //values?.data.delivery === TypeDelivery.expressDelivery,
        //hide: !(values?.data.delivery === TypeDelivery.expressDelivery),
        value: values?.data.open,
        formatTime: FormatTime.HHmm
      },
      {
        label: hourDelivery,
        type: 'duration',
        mask: '99:99',
        identifier: 'stopDelivery',
        validateConstraints: VALIDATE_CONSTRAINTS.time,
        isRequired: true,
        /*values?.data.delivery === TypeDelivery.sameDay ||
          values?.data.delivery === TypeDelivery.nextDay ||
          values?.data.delivery === TypeDelivery.pickupStore ||
          values?.data.delivery === TypeDelivery.pickup,*/
        /*hide: !(
          values?.data.delivery === TypeDelivery.sameDay ||
          values?.data.delivery === TypeDelivery.nextDay ||
          values?.data.delivery === TypeDelivery.pickupStore ||
          values?.data.delivery === TypeDelivery.pickup
        ),*/
        value: values?.data.stopDelivery
      },
      {
        label: 'Hora cierre',
        type: 'time',
        identifier: 'close',
        isRequired: true,
        value: values?.data.close,
        formatTime: FormatTime.HHmm
      },
      {
        label: labelDuration,
        type: typeDuration,
        data: [...Array(10)].map((_, i) => ({ value: `${i + 1}`, item: `${i + 1}`, label: `${i + 1}` })),
        identifier: 'time',
        isRequired: true,
        validateConstraints: VALIDATE_CONSTRAINTS.time,
        mask: '99:99',
        value: values?.data.time,
        /*hide: !(
          values?.data.delivery === TypeDelivery.expressDelivery ||
          values?.data.delivery === TypeDelivery.pickup ||
          values?.data.delivery === TypeDelivery.pickupStore
        ),*/
        formatTime: FormatTime.HHmm
      }
    ];
  }

  static formFieldsAdd({ values, typesDelivery }: { values?: DeliverySchedule; typesDelivery?: Delivery[] }): FormItemModel[] {
    console.log('formFieldsAdd() ==> ', { typesDelivery, values });
    let labelDuration = 'Duración tiempo envío (HH:mm)';
    let typeDuration: any = 'duration';
    let valueTime: string | number | undefined = values?.data.time;
    let hourDelivery = 'Hora estimada de entrega (HH:mm)';
    const dataDays: any = [...Array(10)].map((_, i) => ({ value: `${i + 1}`, item: `${i + 1}`, label: `${i + 1}` }));
    let deliveryFormat: string | undefined;

    if (values && values.data.delivery) {
      deliveryFormat = typeof values?.data.delivery === 'string' ? values?.data.delivery : values?.data.delivery?.data.value;
      if (deliveryFormat === TypeDelivery.pickup || deliveryFormat === TypeDelivery.pickupStore) {
        labelDuration = 'Dias de retiro';
        typeDuration = 'select';
        if (!valueTime) {
          valueTime = `3`;
        }
      } else if (deliveryFormat === TypeDelivery.nextDay || deliveryFormat === TypeDelivery.sameDay) {
        hourDelivery = 'Hora de último delivery (HH:mm)';
      }
    }
    console.log('formFieldsAdd() => { typeDuration, labelDuration, valueTime }', { typeDuration, labelDuration, valueTime, dataDays });

    return [
      {
        label: 'Tipo de envío',
        type: 'select',
        identifier: 'delivery',
        isRequired: true,
        value: values?.data.delivery,
        data:
          typesDelivery && typesDelivery.length > 0
            ? typesDelivery.map(typeDelivey => ({ value: typeDelivey.toString(), label: typeDelivey.data.name, item: typeDelivey }))
            : []
      },
      {
        label: 'Hora apertura',
        type: 'time',
        identifier: 'open',
        value: values?.data.open,
        formatTime: FormatTime.HHmm,
        isRequired: true //deliveryFormat === TypeDelivery.expressDelivery,
        //hide: !(deliveryFormat === TypeDelivery.expressDelivery)
      },
      {
        label: hourDelivery,
        type: 'duration',
        identifier: 'stopDelivery',
        validateConstraints: VALIDATE_CONSTRAINTS.time,
        mask: '99:99',
        value: values?.data.stopDelivery,
        isRequired: true,
        /*deliveryFormat === TypeDelivery.sameDay ||
          deliveryFormat === TypeDelivery.nextDay ||
          deliveryFormat === TypeDelivery.pickupStore ||
          deliveryFormat === TypeDelivery.pickup,*/
        /*hide: !(
          deliveryFormat === TypeDelivery.sameDay ||
          deliveryFormat === TypeDelivery.nextDay ||
          deliveryFormat === TypeDelivery.pickupStore ||
          deliveryFormat === TypeDelivery.pickup
        ),*/
        formatTime: FormatTime.HHmm
      },
      {
        label: 'Hora cierre',
        type: 'time',
        identifier: 'close',
        isRequired: true,
        value: values?.data.close,
        formatTime: FormatTime.HHmm
      },
      {
        label: labelDuration,
        type: typeDuration,
        data: dataDays,
        identifier: 'time',
        isRequired: true /*
          deliveryFormat === TypeDelivery.expressDelivery ||
          deliveryFormat === TypeDelivery.pickup ||
          deliveryFormat === TypeDelivery.pickupStore,
        validateConstraints: VALIDATE_CONSTRAINTS.time,*/,
        mask: '99:99',
        value: valueTime,
        formatTime: FormatTime.HHmm
        /*hide: !(
          deliveryFormat === TypeDelivery.expressDelivery ||
          deliveryFormat === TypeDelivery.pickup ||
          deliveryFormat === TypeDelivery.pickupStore
        )*/
      }
    ];
  }

  static headerTable({
    showModalUpdate,
    onDeleted,
    isPickup
  }: {
    showModalUpdate: (item: DeliverySchedule) => void;
    onDeleted: (item: DeliverySchedule) => void;
    isPickup?: boolean;
  }): TableColumn<DeliverySchedule>[] {
    return [
      {
        name: <FilterHeaderColumn title='Tipo envío' />,
        selector: row =>
          DeliverySchedule.removeFlag(typeof row.data.delivery === 'string' ? row.data.delivery : row.data.delivery?.data.name),
        sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row =>
              DeliverySchedule.isParameterInvalid(typeof row.data.delivery === 'string' ? row.data.delivery : row.data.delivery?.data.name),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule isEditable={false} {...{ rowIndex, column }}>
            <div className='flex flex-row justify-center items-center'>
              <IconCustom
                name={stringDeliveryToIcon(typeof row.data.delivery === 'string' ? row.data.delivery : row.data.delivery?.data.name)}
                className={'text-primary text-base'}
              />
              <p className={'text-sm text-primary ml-2'}>
                {shippingTypeStringToDisplay(typeof row.data.delivery === 'string' ? row.data.delivery : row.data.delivery?.data.name)}
              </p>
            </div>
          </TableCellMolecule>
        )
      },
      {
        name: <FilterHeaderColumn title='Hora apertura' />,
        selector: row => DeliverySchedule.removeFlag(row.data.open),
        sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => DeliverySchedule.isParameterInvalid(row.data.open),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={DeliverySchedule.removeFlag(row.data.open)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title={isPickup ? 'Hora de última recepción' : 'Hora de última recepción'} />,
        selector: row => DeliverySchedule.removeFlag(row.data.stopDelivery),
        sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => DeliverySchedule.isParameterInvalid(row.data.stopDelivery),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={DeliverySchedule.removeFlag(row.data.stopDelivery)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title={isPickup ? 'Hora cierre de Pickup' : 'Hora cierre despacho'} />,
        selector: row => DeliverySchedule.removeFlag(row.data.close),
        sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => DeliverySchedule.isParameterInvalid(row.data.close),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={DeliverySchedule.removeFlag(row.data.close)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title={<p>{isPickup ? 'Max. días almacenamiento' : 'Tiempo despacho'}</p>} />,
        selector: row => DeliverySchedule.removeFlag(row.data.time),
        sortable: true,
        reorder: true,
        conditionalCellStyles: [
          {
            when: row => DeliverySchedule.isParameterInvalid(row.data.time),
            classNames: ['cell-error']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule value={DeliverySchedule.removeFlag(row.data.time)} isEditable={false} {...{ rowIndex, column }} />
        )
      },
      {
        name: <FilterHeaderColumn title='Opciones' />,
        //selector: row => row,
        sortable: true,
        reorder: true,
        cell: row => (
          <div className='w-full flex flex-row justify-center pr-4'>
            <button onClick={() => showModalUpdate(row)}>
              <IconCustom name={NameIcons.rename} className='text-grayarauco-400 text-2xl' />
            </button>

            <button onClick={() => onDeleted(row)}>
              <IconCustom name={NameIcons.deleted} className='text-grayarauco-400 text-2xl' />
            </button>
          </div>
        )
      }
    ];
  }

  sendServer() {
    const { delivery, time, country, open, close, stopDelivery, commerce } = this.data;
    let timeFormat = time;
    const deliveryFormat = typeof delivery === 'string' ? delivery : delivery?.data.value;

    if (delivery) {
      if (deliveryFormat === TypeDelivery.pickup || deliveryFormat === TypeDelivery.pickupStore) {
        const duration: Duration | undefined = stringAtDuration({ value: `${time}`, unit: UnitDate.days });
        timeFormat = `${duration?.asHours()}:00`;
      }
    }

    return {
      delivery: deliveryFormat,
      time: timeFormat || '00:00',
      country,
      open: open || '00:00',
      close: close || '00:00',
      stopDelivery: stopDelivery || '00:00',
      commerce: commerce || 'global'
    };
  }

  /**
   * actuliza la informacion basica.
   */
  updateInfoForm(value: any) {
    this.data.open = value.open;
    this.data.close = value.close;
    this.data.time = value.time;
    this.data.stopDelivery = value.stopDelivery;
    //this.data.commerce = value.commerce || 'global';
    if (value.delivery) {
      this.data.delivery = value.delivery;
    }
  }

  updateCountry(value?: string) {
    this.data.country = value;
  }
}

export default DeliverySchedule;
