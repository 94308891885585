import { useState } from 'react';

import { NameIcons } from 'configs/Enums';

import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';
import BoardOrderAlert from 'components/molecules/board/OrderAlert';
import IconCustom from 'components/atoms/icons';

import OrderAlert from 'models/alerts/OrderAlert';

type OrderAlertsSettingOrganismProps = {
  title?: string;
  orderAlerts: OrderAlert[];
  onUpdateOrderAlertSetting: (orderAlert: OrderAlert) => void;
  onDeletedOrderAlertSetting: (orderAlert: OrderAlert) => void;
  onCreateOrderAlertSetting: (orderAlert: OrderAlert) => void;
};

const OptionLeyend = [
  {
    name: 'Alto',
    description: 'Urgencia alta, se ha incumplido la entrega, la fecha y hora actual son mayor a la fecha y hora estimada de entrega.'
  },
  {
    name: 'Medio',
    description: 'Incumplimiento, la fecha y hora actual son mayor a la fecha y hora de entrega.'
  },
  {
    name: 'Bajo',
    description: 'Incumplimiento, la fecha y hora actual son mayor a la fecha y hora de entrega.'
  }
];

const colorsConvert = (name: string) => {
  if (name?.toLowerCase() === 'alto') {
    return 'bg-redarauco-200';
  }
  if (name?.toLowerCase() === 'medio') {
    return 'bg-araucoyellow-200';
  }
  return 'bg-araucogreen';
};

const OrderAlertsSettingOrganism = ({
  title,
  orderAlerts,
  onUpdateOrderAlertSetting,
  onDeletedOrderAlertSetting,
  onCreateOrderAlertSetting
}: OrderAlertsSettingOrganismProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MenuSessionContainer notStyleContainer classNameContainer='flex flex-col flex-1 justify-between'>
      <div className=''>
        <BoardOrderAlert {...{ orderAlerts, onUpdateOrderAlertSetting, onDeletedOrderAlertSetting, onCreateOrderAlertSetting, title }} />
      </div>

      <div className='bg-white'>
        <div className='relative'>
          {isOpen && (
            <div className='bg-white absolute bottom-0 left-0 right-0 flex flex-col gap-4 py-3 px-10'>
              {OptionLeyend.map(oL => (
                <div key={oL.name} className='flex flex-row items-center'>
                  <div>
                    <div
                      className={`flex justify-center items-center w-16 px-4 py-1 rounded-full text-center text-white ${colorsConvert(
                        oL.name
                      )}`}
                    >
                      {oL.name}
                    </div>
                  </div>
                  <div className='flex justify-center items-center'>
                    <p className='text-sm font-normal ml-6'>{oL.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='px-5 py-4 flex justify-between items-center'>
          <p className='text-base font-normal mr-2'>Definición de términos</p>
          <IconCustom
            name={isOpen ? NameIcons.arrowUp : NameIcons.arrowDown}
            className='cursor-pointer text-3xl text-grayarauco-400'
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </div>
    </MenuSessionContainer>
  );
};

export default OrderAlertsSettingOrganism;
