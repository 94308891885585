import { ResponseApp } from 'configs/Interface';

import UserProvider from 'services/api/User';

import User, { UserModelServer } from 'models/User';
import UserRole, { UserRoleServerModel } from 'models/user/UserRole';

export const getInfoUserSession = async (): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await UserProvider.me();
    console.log('getInfoUserSession ===>', { status, data });

    if (status === 200) {
      if (data) {
        return { status: true, data: new User(User.formalizeData(data)) };
      }
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener la información del usuario.' };
    }
    return { status: false, message: 'No fue posible obtener la información del usuario.' };
  } catch (e) {
    return { status: false, message: 'No fue posible obtener la información del usuario.' };
  }
};

/** información como el delivery, el país y otros datos del usuario **/
export const getInfoGeneral = async (user: User): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await UserProvider.getData();
    console.log('getDataUser ==>', { status, data, other: other });
    if (status === 200) {
      if (data) {
        user.updateInfoGeneral(data);
        return { status: true, data: user.data.generalInf ? { ...user.data.generalInf } : undefined };
      }
    }
    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible object la información del usuario.' };
    }
    return { status: false, message: 'No fue posible object la información del usuario.' };
  } catch (e) {
    return { status: false, message: 'No fue posible obtener la información del usuario.' };
  }
};

export const createUser = async (user: User) => {
  try {
    const { status, data, ...other } = await UserProvider.createUser(user);
    console.log('createUser() ==>', { status, data, other });
    if (status === 201 || status === 200) {
      return { status: true, message: data.message };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible crear el usuario.' };
  } catch (err) {
    console.log('createUser() ==> err ==>', err);
    return { status: false, message: 'No fue posible crear el usuario.' };
  }
};

export const getRolesUser = async () => {
  try {
    const { status, data, ...other } = await UserProvider.getRoles();

    if (status === 200) {
      if (data.roles && data.roles.length > 0) {
        return { status: true, data: data.roles.map((rol: UserRoleServerModel) => new UserRole(UserRole.formalizeData(rol))) };
      }
      return { status: false, message: 'No hay roles definidos.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los roles.' };
  } catch (err) {
    return { status: false, message: `No fue posible obtener los roles: ${(err as Error).message}` };
  }
};

export const getUsers = async (): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await UserProvider.getUsers();
    console.log('getUsers() ==>', { data, status, other });
    if (status) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((user: UserModelServer) => new User(User.formalizeData(user)))
        };
      }
      return { status: false, message: 'No hay mas usuario.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener los usuarios.' };
    }
    return { status: false, message: 'No fue posible obtener los usuarios.' };
  } catch (err) {
    return { status: false, message: `No fue posible obtener los usuario: ${(err as Error).message}.` };
  }
};

export const deleteUserById = async (user: User): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await UserProvider.deleteUserById(user);
    console.log('deleteUserById() =>', { data, status, other });

    if (status === 200) {
      return { status: true, message: data.message || 'Se elimino con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible borrar el usuario.' };
  } catch (err) {
    return { status: false, message: `No fue posible borrar el usuario: ${(err as Error).message}` };
  }
};

export const updateUserById = async (user: User): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await UserProvider.updateUserById(user);
    console.log('updateUserById() =>', { data, status, other });

    if (status) {
      return { status: true, message: 'Se actualizo con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible actualizar el usuario.' };
  } catch (err) {
    return { status: false, message: `No fue posible actualizar el usuario: ${(err as Error).message}` };
  }
};
