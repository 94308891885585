import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/AuthContext';
import { PathRoute } from 'configs/Enums';

const LoadingRedirect = () => {
  const { authStatus } = useContext(AuthContext);
  const navigate = useNavigate();

  const navigateInit = () => {
    if (authStatus.isAuthenticated) {
      console.log('navigateInit() ==> showRoutesMenu => ', authStatus.user?.enabledRoutes()[0]);
      navigate(authStatus.user?.enabledRoutes()[0] || PathRoute.uploadFile);
    } else {
      navigate(PathRoute.signin);
    }
  };

  useEffect(() => {
    navigateInit();
  }, [authStatus]);

  return (
    <div className='flex h-screen justify-center items-center'>
      <div className='spinner'></div>
    </div>
  );
};

export default LoadingRedirect;
