import { useEffect } from 'react';
import DataTable, { Alignment, TableColumn, TableStyles } from 'react-data-table-component';
import {
  ComponentProps,
  ConditionalStyles,
  ExpandableRowsComponent,
  PaginationChangePage,
  PaginationChangeRowsPerPage,
  PaginationOptions,
  PaginationServerOptions,
  RowState
} from 'react-data-table-component/dist/src/DataTable/types';

type BoardCustomProps = {
  title?: string | React.ReactNode;
  data: Array<any>;
  columns: TableColumn<any>[];
  subHeader?: React.ReactNode | React.ReactNode[];
  subHeaderAlign?: Alignment;
  subHeaderComponent?: React.ReactNode | React.ReactNode[];
  subHeaderWrap?: boolean;
  persistTableHead?: boolean;
  noHeader?: boolean;
  noDataComponent?: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  progressPending?: boolean;
  flag?: number;
  selectableRows?: boolean;
  selectableRowSelected?: RowState<any>;
  selectableRowDisabled?: RowState<any>;
  selectableRowsComponent?: 'input' | React.ReactNode;
  contextActions?: React.ReactNode | React.ReactNode[];
  contextComponent?: React.ReactNode;
  noContextMenu?: boolean;
  onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: any[] }) => void;
  paginationPerPage?: number;
  paginationResetDefaultPage?: boolean;
  paginationRowsPerPageOptions?: number[];
  paginationServer?: boolean;
  paginationServerOptions?: PaginationServerOptions;
  paginationTotalRows?: number;
  onChangePage?: PaginationChangePage;
  onChangeRowsPerPage?: PaginationChangeRowsPerPage;
  paginationComponentOptions?: PaginationOptions;
  conditionalRowStyles?: ConditionalStyles<any>[];
  expandableRowDisabled?: RowState<any>;
  expandableRowExpanded?: RowState<any>;
  expandableRows?: boolean;
  expandableRowsComponent?: ExpandableRowsComponent<any>;
  expandableRowsComponentProps?: ComponentProps;
  expandableRowsHideExpander?: boolean;
  expandOnRowClicked?: boolean;
  expandOnRowDoubleClicked?: boolean;
  onRowClicked?: (row: any, e: React.MouseEvent) => void;
  selectableRowsComponentProps?: ComponentProps;
  pagination?: boolean;
  responsive?: boolean;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  disabled?: boolean;
};

const paginationComponentOptionsDefault = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: false,
  selectAllRowsItemText: 'Todos'
};

export const customStyles: TableStyles = {
  cells: {
    style: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  head: {
    style: {
      paddingRight: '0px',
      paddingLeft: '0px'
    }
  },
  header: {
    style: {
      paddingRight: '0px',
      paddingLeft: '0px',
      backgroundColor: 'transparent'
    }
  },
  headCells: {
    style: {
      paddingRight: '0px',
      paddingLeft: '0px'
    }
  },
  headRow: {
    style: {
      paddingRight: '0px',
      paddingLeft: '0px'
    }
  },
  noData: {
    style: {
      //justifyContent: 'flex-start'
    }
  }
};

const BoardCustom = ({
  title,
  data,
  columns,
  subHeader,
  subHeaderAlign,
  subHeaderComponent,
  subHeaderWrap,
  persistTableHead,
  noHeader,
  noDataComponent,
  actions,
  progressPending,
  flag,
  selectableRows,
  selectableRowSelected,
  selectableRowDisabled,
  selectableRowsComponent,
  contextActions,
  contextComponent,
  noContextMenu,
  onSelectedRowsChange,
  paginationServer,
  paginationTotalRows,
  onChangeRowsPerPage,
  onChangePage,
  paginationPerPage,
  paginationResetDefaultPage,
  paginationRowsPerPageOptions,
  paginationServerOptions,
  paginationComponentOptions,
  conditionalRowStyles,
  expandableRowDisabled,
  expandableRowExpanded,
  expandableRows,
  expandableRowsComponent,
  expandableRowsComponentProps,
  expandableRowsHideExpander,
  expandOnRowClicked,
  expandOnRowDoubleClicked,
  onRowClicked,
  selectableRowsComponentProps,
  pagination = true,
  responsive,
  fixedHeader,
  fixedHeaderScrollHeight,
  disabled
}: BoardCustomProps) => {
  useEffect(() => {
    console.log('useEffect', flag);
  }, []);

  return (
    <DataTable
      theme='light'
      customStyles={customStyles}
      striped
      highlightOnHover
      pointerOnHover
      paginationComponentOptions={{ ...paginationComponentOptionsDefault, ...paginationComponentOptions }}
      noDataComponent={
        noDataComponent || <h1 className='pb-3 self-start font-semibold text-sm sm:text-lg text-gray-900 py-10'>No hay registros</h1>
      }
      {...{
        fixedHeader,
        fixedHeaderScrollHeight,
        responsive,
        actions,
        title,
        data,
        columns,
        subHeader,
        subHeaderAlign,
        subHeaderComponent,
        subHeaderWrap,
        persistTableHead,
        noHeader,
        progressPending,
        selectableRows,
        selectableRowSelected,
        selectableRowDisabled,
        selectableRowsComponent,
        selectableRowsComponentProps,
        contextActions,
        contextComponent,
        noContextMenu,
        onSelectedRowsChange,
        paginationServer,
        paginationTotalRows,
        onChangeRowsPerPage,
        onChangePage,
        paginationPerPage,
        paginationResetDefaultPage,
        paginationRowsPerPageOptions,
        paginationServerOptions,
        conditionalRowStyles,
        expandableRowDisabled,
        expandableRowExpanded,
        expandableRows,
        expandableRowsComponent,
        expandableRowsComponentProps,
        expandableRowsHideExpander,
        expandOnRowClicked,
        expandOnRowDoubleClicked,
        onRowClicked,
        pagination,
        disabled
      }}
    />
  );
};

export default BoardCustom;
