import { Slider } from 'antd';
import { Tooltip } from 'antd';

import LabelCustom from 'components/atoms/labels';

type SliderCustomProps = {
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  value?: number;
  label?: string | number;
  classNameLabel?: string;
  isRequired?: boolean;
  hide?: boolean;
};

const SliderCustom = ({ min, max, onChange, value, label, classNameLabel, isRequired, hide }: SliderCustomProps) => {
  return (
    <div hidden={hide}>
      {label && (
        <LabelCustom className={`mb-2 ${classNameLabel}`}>
          {label}
          {isRequired && <span className='text-red-400'> *</span>}
        </LabelCustom>
      )}
      <div className='py-1'>
        <Tooltip
          //visible={!!messageError}
          visible={false}
          trigger={['hover', 'focus']}
          //title={messageErrorTooltip || messageError}
          title={''}
          placement='top'
          overlayClassName='ant-tooltip-content-error'
          overlayInnerStyle={{ backgroundColor: 'red' }}
        >
          <Slider onChange={newValue => onChange(newValue)} {...{ min, max, value }} />
        </Tooltip>
      </div>
    </div>
  );
};

export default SliderCustom;
