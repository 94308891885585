import { useContext, useEffect, useState } from 'react';

import { ASSETS } from 'configs/Constants';
import { TypeToast } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import CreateStoreOrganism from 'components/organisms/dashboard/create/CreateStore';
import { StoreBoardFilterParams } from 'components/molecules/filters/StoreBoard';

import Country, { CountryServerModel } from 'models/Country';
import Store, { StoreModel } from 'models/store';
import StoreJanis from 'models/store/StoreJanis';
import SaleChannel from 'models/store/SaleChannel';
import DeliverySchedule from 'models/delivery/DeliverySchedule';

//import { createStore, getInfoSaleChannel, getInfoStoresJanis, getInfoWarehouse } from 'services/app/Store';
import {
  createStore,
  deleteStore,
  getInfoSaleChannel,
  getInfoStoresJanis,
  getInfoStoresJanisAvailables,
  getInfoWarehouse,
  getShop,
  updateStore
} from 'services/app/Store';
import { createDeliveryConfigs } from 'services/app/Delivery';
//import WareHouse from 'models/store/Warehouse';

const CreateStorePage = () => {
  const { showLoading, hideLoading, showToast } = useContext(RootContext);
  const { onUpdateWarehouse } = useContext(DataContext);
  const { typesDelivery, countryUser, onUpdateTypeOperator } = useContext(AuthContext);

  const [countries, setCountries] = useState<Country[]>([]);
  const [storesJanis, setStoresJanis] = useState<StoreJanis[]>([]);
  const [salesChannels, setSalesChannels] = useState<SaleChannel[]>([]);
  const [storeModel, setStoreModel] = useState<StoreModel>();
  const [stores, setStores] = useState<Store[]>([]);
  const [storesFilter, setStoresFilter] = useState<Store[]>([]);
  const [flag, setFlag] = useState(0);
  const [schedules, setSchedules] = useState<DeliverySchedule[]>([]);

  const loadStores = async () => {
    showLoading();
    try {
      const { status, data, message } = await getShop(countryUser);
      console.log('loadStores() =>', { status, data, message, typesDelivery });

      if (status) {
        setStores(data);
        setStoresFilter(data);
      } else {
        setStores([]);
        setStoresFilter([]);
        showToast({ text: message || 'No fue posible cargar las tiendas.', type: TypeToast.error });
      }
      hideLoading();
    } catch (err) {
      console.log('loadStores() ==>', err);
      setStores([]);
      setStoresFilter([]);
      hideLoading();
    }
  };

  const loadShopsJanisAvailables = async (storesJanisResult: StoreJanis[]) => {
    //showLoading();
    try {
      const { data, status, message } = await getInfoStoresJanisAvailables(countryUser);
      console.log('loadShopsJanisAvailables() =>', { data, status, message });

      if (status) {
        const storesJanisContain = storesJanisResult.map(stoJa => {
          if (data.find((stoJaA: StoreJanis) => stoJaA.data.id === stoJa.data.id)) {
            stoJa.updateAvailable(true);
          } else {
            stoJa.updateAvailable(false);
          }
          return stoJa;
        });
        console.log('loadShopsJanisAvailables() => storesJanisContain', storesJanisContain);
        setStoresJanis(storesJanisContain);
      } else {
        setStoresJanis(storesJanisResult);
        if (message) {
          showToast({ text: message, type: TypeToast.error });
        }
      }

      //hideLoading();
    } catch (err) {
      console.log('loadShopsJanisAvailables() =>', err);
      //hideLoading();
    }
  };

  const loadShopsJanis = async () => {
    showLoading();
    try {
      const { data, status, message } = await getInfoStoresJanis(countryUser);
      console.log('loadShopsJanis() ==>', { data, status, message });
      if (status) {
        await loadShopsJanisAvailables(data);
      } else {
        setStoresJanis([]);
        showToast({ text: message || 'No fue posible cargar las tiendas de Janis.', type: TypeToast.error });
      }
      hideLoading();
    } catch (err) {
      console.log('loadShopsJanis() ==> err', err);
      setStoresJanis([]);
      hideLoading();
    }
  };

  const loadSalesChannel = async (idSaleChannel: string): Promise<ResponseApp> => {
    //showLoading();
    try {
      const { data, status } = await getInfoSaleChannel({ idSaleChannel, country: countryUser });
      //console.log('loadSalesChannel() ==>', { data, status, message });
      if (status) {
        return { status, data };
      } else {
        return { status: false };
      }
    } catch (err) {
      //console.log('loadSalesChannel() ==> err', err);
      //hideLoading();
      return { status: false };
    }
  };

  const onChangeForm = async (store: StoreModel) => {
    showLoading();
    try {
      //console.log('onChangeForm() ==>', { store, storeModel });
      if (store.storeJanis !== storeModel?.storeJanis) {
        const { status, data } = await getInfoWarehouse({ idWarehouse: store.storeJanis?.data.warehouseId || '', country: countryUser });
        //console.log('onChangeForm() ==>', { status, data });
        const salesChannelsNew: SaleChannel[] = [];
        if (status) {
          if (data.data.delivery && data.data.delivery.length > 0) {
            store.storeJanis?.updateDelivery(data.data.delivery);
          } else {
            store.storeJanis?.updateDelivery(undefined);
          }
          for await (const saleChannel of data.data.salesChannels) {
            const { status: statusSaleChannel, data: dataSaleChannel } = await loadSalesChannel(saleChannel);
            if (statusSaleChannel) {
              salesChannelsNew.push(dataSaleChannel);
            }
          }
        }
        //console.log('onChangeForm() ==>', salesChannelsNew);
        setSalesChannels(salesChannelsNew);
      }
      setStoreModel(store);
    } catch (err) {
      console.log('onChangeForm() ==> err', err);
      //setStoreModel(undefined);
    }
    hideLoading();
  };

  const onCreateStore = async (store: Store) => {
    showLoading();
    setStoreModel(undefined);
    try {
      //console.log('onCreateStore() ==>', store);
      const { status, message, data } = await createStore(store);
      //console.log('onCreateStore() ==>', { store, status, message });
      if (status) {
        if (schedules && schedules.length > 0) {
          const schedulesUpdate: DeliverySchedule[] = [];
          schedules.forEach(schedule => {
            schedule.data.commerce = data.data.commerceId;
            schedule.data.country = countryUser;
            schedulesUpdate.push(schedule);
          });
          //console.log('onCreateStore() ==>', { schedulesUpdate });
          await createDeliveryConfigs(schedulesUpdate);
        }
        showToast({ text: message || 'Tienda creada con éxito.', type: TypeToast.success });
        loadShopsJanisAvailables(storesJanis);
        loadStores();
      } else {
        showToast({ text: message || 'No fue posible crear la tienda por el momento.', type: TypeToast.error });
      }
      hideLoading();
    } catch (err) {
      console.log('createStore() ==> err', err);
      hideLoading();
    }
  };

  const onDeletedStore = async (store: Store) => {
    showLoading();
    setStoreModel(undefined);
    try {
      //console.log('onDeleted() ==>', store);
      const { status, message } = await deleteStore(store);

      if (status) {
        showToast({ text: message || 'Fue eliminado con exito.', type: TypeToast.success });
        await loadShopsJanisAvailables(storesJanis);
        loadStores();
      } else {
        showToast({ text: message || 'No fue posible eliminar la tienda.', type: TypeToast.error });
      }
      hideLoading();
    } catch (err) {
      hideLoading();
    }
  };

  const onUpdateStore = async (store: Store) => {
    showLoading();
    setStoreModel(undefined);
    try {
      console.log('onUpdateStore() ==>', store);
      const { status, message } = await updateStore(store);

      if (status) {
        showToast({ text: message || 'La tienda se actulizo con éxito.', type: TypeToast.success });
        await loadShopsJanisAvailables(storesJanis);
        loadStores();
      } else {
        showToast({ text: message || 'No fue posible actulizar la tienda.', type: TypeToast.error });
      }
      hideLoading();
    } catch (err) {
      hideLoading();
    }
  };

  const onCancelStore = async () => {
    setStoreModel(undefined);
  };

  const onFilterList = async (params: StoreBoardFilterParams) => {
    try {
      const { typesDelivery, typesOperator, brands, saleChannel, rutOrID, statusStore } = params;
      let storeF = stores;

      if (typesOperator) {
        storeF = storeF.filter(sto => typesOperator.find(tyO => tyO.data.value?.toLowerCase() === sto.data.typeOperator?.toLowerCase()));
      }
      if (typesDelivery) {
        storeF = storeF.filter(sto => sto.data.typesDelivery?.find(tD => typesDelivery.find(tyO => tyO.data.value === tD.data.value)));
      }
      if (brands) {
        storeF = storeF.filter(sto => brands?.find(bran => bran.data.brandSearch === sto.data.brandSearch));
      }
      console.log('onFilterList() ==> { params, storeF, stores }', { params, storeF, stores });
      if (saleChannel) {
        storeF = storeF.filter(sto =>
          `${sto?.data.saleChannel?.data.referenceId}`.toLowerCase()?.indexOf(saleChannel?.toLowerCase()) !== -1 ? true : false
        );
      }
      if (rutOrID) {
        storeF = storeF.filter(sto => (sto?.data.rutOrId?.toLowerCase()?.indexOf(rutOrID?.toLowerCase()) !== -1 ? true : false));
      }
      if (statusStore) {
        storeF = storeF.filter(sto => {
          const statusS = sto?.data.isActive ? 'activa' : 'inactiva';
          return statusStore.find(sS => sS === statusS);
        });
      }
      //console.log('onFilter() 7 => storeF', { storeF });
      setStoresFilter(storeF);
      setFlag(flag + 1);
      return [];
    } catch (err) {
      console.log('onFilter() => err', err);
      setStoresFilter([]);
      setFlag(flag + 1);
      return [];
    }
  };

  useEffect(() => {
    onUpdateTypeOperator();
    onUpdateWarehouse();
    if (ASSETS.data && ASSETS.data.countries && ASSETS.data.countries.length > 0) {
      setCountries(ASSETS.data.countries.map((country: CountryServerModel) => new Country(Country.formalizeData(country))));
    }
    loadShopsJanis();
    loadStores();
  }, [countryUser]);

  return (
    <CreateStoreOrganism
      values={storeModel}
      onFilter={onFilterList}
      stores={storesFilter}
      onUpdateSchedules={schedulesNew => setSchedules(schedulesNew)}
      {...{
        countries,
        typesDelivery,
        storesJanis,
        onCreateStore,
        onChangeForm,
        salesChannels,
        onDeletedStore,
        onUpdateStore,
        onCancelStore,
        schedules
      }}
    />
  );
};

export default CreateStorePage;
