import { EnumCountries, EnumTypeDownloadFile, EnumTypeFile } from 'configs/Enums';

import DownloadFile, { DownloadFileServerModel } from 'models/file/DownloadFile';
import Handbook from 'models/file/Handbook';
import Store from 'models/store';

import FileProvider from 'services/api/File';

export const getDownloadFiles = async ({ country, typeDFile }: { country: EnumCountries | string; typeDFile: EnumTypeDownloadFile }) => {
  try {
    const { status, data, ...other } = await FileProvider.getDownloadFiles({ country, type: typeDFile });
    //console.log('getDownloadFiles() ==>', { status, data, other });

    if (status === 200) {
      if (data.file) {
        let typeFile = EnumTypeFile.xls;
        if (typeDFile === EnumTypeDownloadFile.handbook) {
          typeFile = EnumTypeFile.pdf;
        }
        if (typeDFile === EnumTypeDownloadFile.template) {
          typeFile = EnumTypeFile.xls;
        }
        if (typeDFile === EnumTypeDownloadFile.handbookManualUnitLoading) {
          typeFile = EnumTypeFile.pdf;
        }
        return {
          status: true,
          data: data.file.map(
            (dFile: DownloadFileServerModel) =>
              new DownloadFile(DownloadFile.formalizeData({ ...dFile, name: `${typeDFile} ${country}`, type: typeFile }))
          )
        };
      }
    }

    if ('message' in other) {
      return { status: false, data: other.message };
    }

    return { status: false, data: 'No fue posible descargar los archivos.' };
  } catch (err) {
    console.log('getDownloadFiles() ==>', err);
    return { status: false, message: 'No fue posible descargar los archivos.' };
  }
};

export const postHandbook = async (handbook?: Handbook) => {
  try {
    const { status, data, ...other } = await FileProvider.postHandbook({ handbook });
    console.log('postHandbook() ==>', { status, data, other });

    if (status) {
      return { status: true };
    }

    if ('message' in other) {
      return { status: false, data: other.message };
    }

    return { status: false, data: 'No fue posible cargar los archivos.' };
  } catch (err) {
    return { status: false, message: 'No fue posible cargar los archivos.' };
  }
};

export const generatorExcelOrders = async (params: {
  dateStart: string;
  dateEnd: string;
  store?: Store[];
  deliveryState?: string[];
  country?: string[];
}) => {
  try {
    const { status, data, ...other } = await FileProvider.generatorExcelOrders(params);

    if (status) {
      return { status: true, data };
    }

    if ('message' in other) {
      return { status: false, data: other.message };
    }

    return { status: false, data: 'No fue posible cargar los archivos.' };
  } catch (err) {
    return { status: false, message: 'No fue posible generar el excel de ordenes' };
  }
};
