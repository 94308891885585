export interface CountryModel {
  id?: string;
  name: string;
  value: string;
  currency?: string[];
  typesDelivery?: string[];
}

export interface CountryServerModel {
  name?: string;
  value?: string;
  currency?: string[];
  typesDelivery?: string[];
  id?: string;
  country?: string;
}

class Country {
  data: CountryModel;

  constructor(data: CountryModel) {
    this.data = data;
  }

  static formalizeData(data: CountryServerModel): CountryModel {
    const { name, value, id, country, currency, typesDelivery } = data;

    return {
      id: id,
      name: name || country || '',
      value: value || id || '',
      currency,
      typesDelivery
    };
  }
}

Country.prototype.toString = function countryToString() {
  return `${this.data.value}`;
};

export default Country;
