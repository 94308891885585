import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'antd';

import { APP_DEPLOYMENT_DATE, APP_VERSION, ASSETS, SURVEY_URL } from 'configs/Constants';
import { NameIcons, PathRoute, SizeScreen, StyleButton } from 'configs/Enums';
import { SectionDashboard } from 'configs/Interface';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';
import { RouterInContext } from 'context/RouterIn';

import CountrySelectorModal from 'components/molecules/modals/CountrySelector';
import ClearCacheButton from 'components/atoms/buttons/ClearCacheButton';
import IconCustom from 'components/atoms/icons';
import SectionDashboardItem from 'components/molecules/items/SectionDashboard';
import NotificationDD from 'components/molecules/dropDown/Notification';

import './SidebarNavigation.css';

type SidebarNavigationVerticalProps = {
  sections: SectionDashboard[];
};

const SidebarNavigationVertical = ({ sections }: SidebarNavigationVerticalProps) => {
  const { screenResolution, showNotificationPopUp } = useContext(RootContext);
  const { authStatus, numFailedOrders, signOut } = useContext(AuthContext);
  const { isCorrectedOrder, onIsCorrectOrders, updateHContainer } = useContext(RouterInContext);

  const navigate = useNavigate();

  const [isExpand, setIsExpand] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const [badge, setBadge] = useState(0);

  const onNavigationWelcome = () => {
    navigate(PathRoute.welcome);
  };

  const onShowPopup = ({ onAction }: { onAction?: () => void }) => {
    if (isCorrectedOrder) {
      showNotificationPopUp({
        title: 'Corrección de órdenes fallidas',
        isClose: true,
        messageNotMarkdown: (
          <p className='text-center text-xl font-normal w-4/5 sm:w-3/5'>
            Tienes órdenes corregidas listas para cargar, si sales se perderán las correciones aplicadas.
          </p>
        ),
        classNameMessageP: 'w-3/5 text-center',
        options: [
          {
            text: 'Salir',
            style: StyleButton.outlinePrimary,
            classNameButton: 'w-32 sm:w-52',
            onAction: () => {
              if (onAction) {
                onAction();
                onIsCorrectOrders(false);
                window.onbeforeunload = () => {
                  console.log('onbeforeunload');
                };
              }
            }
          },
          {
            text: 'continuar',
            classNameButton: 'w-32 sm:w-52'
          }
        ]
      });
    } else {
      if (onAction) {
        onAction();
      }
    }
  };

  const loadHeightContainer = () => {
    const headerNav = document.getElementById('header-nav');
    const hC = window.innerHeight - (headerNav?.clientHeight || 48);
    console.log('loadHeightContainer() =>', {
      wH: window.innerHeight,
      hNH: headerNav?.clientHeight,
      hcont: hC
    });
    updateHContainer(hC);
  };

  useEffect(() => {
    if (screenResolution <= SizeScreen.md) {
      setIsTop(true);
      loadHeightContainer();
    } else {
      setIsTop(false);
    }
    if (numFailedOrders) {
      setBadge(numFailedOrders);
    }
  }, [screenResolution, numFailedOrders]);

  if (isTop) {
    return (
      <div className='w-screen' id='header-nav'>
        <div className='flex flex-row h-12 w-screen justify-between bg-white overflow-visible'>
          <div className='flex items-center justify-center p-2'>
            <img
              className={`h-8 cursor-pointer`}
              src={ASSETS.logoWithoutText}
              alt='logo'
              onClick={() => {
                onShowPopup({
                  onAction: () => {
                    onNavigationWelcome();
                  }
                });
              }}
            />
          </div>
          <div className='flex items-center justify-center p-4 flex-row'>
            <NotificationDD />
            <CountrySelectorModal />
            {screenResolution <= SizeScreen.md && (
              <div
                onClick={() => {
                  signOut();
                }}
                className='font-semibold text-white bg-araucogreen mr-2 w-7 h-7 rounded-full flex justify-center items-center cursor-pointer'
              >
                <span>{`${authStatus.user?.getFirstLetterName()}`}</span>
              </div>
            )}
            <div className='flex items-center justify-center' onClick={() => setIsExpand(!isExpand)}>
              <Badge count={!isExpand ? badge : 0} overflowCount={99}>
                <IconCustom name={isExpand ? NameIcons.close : NameIcons.menu} />
              </Badge>
            </div>
          </div>
        </div>

        <div
          className={`absolute flex mt-12 flex-row background-menu-horizontal z-50 ${
            isExpand ? 'bottom-0 left-0 right-0 top-0 w-full' : 'top-0 bottom-0'
          }`}
        >
          <div
            className={`flex flex-col ${isExpand && screenResolution === SizeScreen.sm ? 'w-screen' : 'w-0'} ${
              isExpand && screenResolution === SizeScreen.md ? 'w-80' : 'w-0'
            } bg-white overflow-hidden animate-width h-full`}
          >
            {/**
            <div className='w-14 flex justify-center items-center my-4'>
              <IconCustom onClick={() => setIsExpand(!isExpand)} className='cursor-pointer text-grayarauco-400' name={NameIcons.menu} />
            </div>
            <div className='flex items-center justify-center py-4'>
              <img className='w-32' src={ASSETS.logo} alt='logo' />
            </div>
            **/}

            <ul className='flex flex-col py-4 flex-1'>
              {sections.map(section => (
                <SectionDashboardItem
                  key={section.name}
                  onClick={({ onAction }) => {
                    setIsExpand(false);
                    onShowPopup({
                      onAction
                    });
                  }}
                  hideText={!isExpand}
                  {...{ section }}
                />
              ))}
            </ul>
          </div>
          <div
            className='flex flex-1'
            onClick={() => {
              setIsExpand(false);
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className='min-h-screen max-h-screen flex flex-row bg-gray-100 shadow-lg'>
      <div className={`flex flex-col ${isExpand ? 'w-60' : 'w-14'} bg-white overflow-hidden animate-width`}>
        <div className='w-14 flex justify-center items-center my-4'>
          <IconCustom onClick={() => setIsExpand(!isExpand)} className='cursor-pointer text-grayarauco-400' name={NameIcons.menu} />
        </div>

        <div className={`flex items-center justify-center self-center ${isExpand ? 'h-14' : 'h-10'} mx-2`}>
          <img
            className={`h-full object-contain cursor-pointer`}
            src={isExpand ? ASSETS.logo : ASSETS.logoWithoutText}
            alt='logo'
            onClick={() => {
              onShowPopup({
                onAction: () => {
                  onNavigationWelcome();
                }
              });
            }}
          />
        </div>

        <ul className='flex flex-col pb-4 pt-8 flex-1 overflow-y-auto overflow-x-hidden'>
          {sections.map(section => (
            <SectionDashboardItem
              key={section.name}
              onClick={({ onAction }) => {
                onShowPopup({
                  onAction
                });
              }}
              hideText={!isExpand}
              {...{ section }}
            />
          ))}
          {authStatus.user?.isLocatario() && SURVEY_URL && (
            <li>
              <a
                onClick={() => {
                  onShowPopup({
                    onAction: () => {
                      window.open(`${SURVEY_URL}`, 'black');
                    }
                  });
                }}
                className='flex flex-row items-center h-12'
              >
                <span className='inline-flex items-center justify-center h-12 w-14 text-lg'>
                  <IconCustom name={NameIcons.quiz} className='text-grayarauco-400' />
                </span>
                {isExpand && <span className='text-sm font-medium'>Tú opinión nos importa</span>}
              </a>
            </li>
          )}
        </ul>

        <span className={`text-transparent text-center ${isExpand ? '' : 'hidden'}`}>{APP_DEPLOYMENT_DATE}</span>
        <div className={`transition-all overflow-hidden flex justify-center items-center ${isExpand ? 'w-full' : 'w-0'}`}>
          <span className={`font-bold text-gray-500`}>
            v <ClearCacheButton>{APP_VERSION}</ClearCacheButton>
          </span>
        </div>
        <a
          onClick={() => {
            onShowPopup({
              onAction: () => {
                setIsExpand(false);
                signOut();
              }
            });
          }}
          className='flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800'
        >
          <span className='inline-flex items-center justify-center h-12 w-14 text-lg text-red-400'>
            <span className='material-icons'>logout</span>
          </span>
          {isExpand && <span className='text-sm font-medium'>Cerrar sesión</span>}
        </a>
      </div>
    </div>
  );
};

export default SidebarNavigationVertical;
