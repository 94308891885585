import {
  ChangeEvent,
  ChangeEventHandler,
  FormEventHandler,
  forwardRef,
  ReactEventHandler,
  ReactNode,
  useContext,
  useEffect,
  useState,
  CSSProperties
} from 'react';
import validate from 'validate.js';
import { Input, InputNumber, Tooltip } from 'antd';
import { LiteralUnion } from 'antd/lib/_util/type';
import InputMask from 'react-input-mask';

import { IS_IOS, TimeOut } from 'configs/Constants';
import { EnumCountries, NameIcons } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';

import LabelCustom from 'components/atoms/labels';

import IconCustom from '../icons';

import { convertNumberToPrice, replaceNumberToPrice } from 'helpers/Converts';

type InputCustomProps = {
  defaultShowPassword?: boolean;
  placeholder?: string;
  type?: LiteralUnion<
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'price'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'textArea'
    | 'week',
    string
  >;
  value?: string | ReadonlyArray<string> | number | undefined;
  /** es para cualquiera de los inputs, menos para los tipos numerico de precio **/
  onChange?: ChangeEventHandler<any> | undefined;
  onChangeNumber?: (val?: number) => void;
  onSubmit?: FormEventHandler<any> | undefined;
  className?: string;
  label?: string;
  classNameLabel?: string;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  isVisibleTooltip?: boolean;
  enterKeyHint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send' | undefined;
  isRequired?: boolean;
  isIconRequired?: boolean;
  passwordValue?: string;
  passwordConfirmValue?: string;
  /** usa cualquiera de estas constraints
   * @see [validators](https://validatejs.org/#validators)
   * @example
   * ```
   *  {
   *    numericality: {
          onlyInteger: true,
          notInteger: 'Solo son permitidos los valores enteros.',
          greaterThan: 1,
          notGreaterThan: 'No menor a 1',
          noStrings: false
        }
   *  }
   * ```
  * **/
  validateConstraints?: any;
  messageErrorTooltip?: string;
  /** retorna si el valor es valido segun el validateConstraints y isRequired **/
  onValidate?: (isValid?: boolean, val?: any) => void;
  disabled?: boolean;
  autoComplete?: 'off' | 'on' | 'new-password';
  autoSave?: 'off' | 'on';
  autoCorrect?: 'off' | 'on';
  mask?: string | Array<string | RegExp>;
  maxLength?: number;
  hide?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onLoad?: ReactEventHandler<any> | undefined;
  onLoadStart?: ReactEventHandler<any> | undefined;
  classNameContainer?: string;
  classNameContainerInput?: string;
  rows?: number;
  /** cuando se quiere buscar algo por servidor para no saturar tiene un Delay **/
  onSearch?: (value?: string | number) => void;
  info?: ReactNode;
  styleLabel?: CSSProperties | undefined;
  suffix?: ReactNode;
};

const InputCustom = forwardRef(
  (
    {
      placeholder,
      type,
      value,
      onChange,
      className,
      label,
      classNameLabel,
      onPressEnter,
      onChangeNumber,
      enterKeyHint,
      isRequired,
      validateConstraints,
      messageErrorTooltip,
      onValidate,
      passwordValue,
      passwordConfirmValue,
      disabled,
      autoSave = 'off',
      autoCorrect = 'off',
      autoComplete = 'off',
      mask = '99:99',
      maxLength,
      hide,
      onBlur,
      onFocus,
      onLoad,
      classNameContainer,
      rows = 4,
      onSearch,
      isIconRequired = true,
      info,
      styleLabel,
      classNameContainerInput,
      defaultShowPassword = false,
      suffix
    }: InputCustomProps,
    ref: any
  ) => {
    const { authStatus } = useContext(AuthContext);

    const [messageError, setMessageError] = useState<string>();
    const [showMessageError, setShowMessageError] = useState<boolean>();
    const [isVisiblePassword, setIsVisiblePassword] = useState(defaultShowPassword);

    let timeOutSearch: NodeJS.Timeout | undefined;

    const handleValidate = (isVal: boolean, val?: any) => {
      if (onValidate) {
        onValidate(isVal, val);
      }
    };

    const handleChange = (event: ChangeEvent<any>) => {
      //console.log('handleChange() ==>', event);
      setShowMessageError(false);
      if (event && onChange) {
        if (isRequired && validate.isEmpty(event.target.value)) {
          handleValidate(false, event);
          setMessageError('El campo es obligatorio');
          onChange(event);
          setIsVisiblePassword(true);
        } else if (validateConstraints) {
          setIsVisiblePassword(false);
          if (validateConstraints.hourAndMinutes) {
            const result = validateConstraints.hourAndMinutes(event.target?.value);
            if (result) {
              handleValidate(true, event);
              setMessageError(undefined);
              onChange(event);
            } else {
              handleValidate(false, event);
              setMessageError('La duración no es valida.');
              onChange(event);
            }
          } else if ((event.target?.value === undefined || event.target?.value === '') && !isRequired) {
            handleValidate(true, event);
            setMessageError(undefined);
            onChange(event);
          } else {
            const result = validate.single(event.target?.value, validateConstraints);
            if (result) {
              handleValidate(false, event);
              setMessageError(result[0]);
              onChange(event);
            } else {
              handleValidate(true, event);
              setMessageError(undefined);
              onChange(event);
            }
          }
          //console.log('resutl', result);
        } else {
          handleValidate(true, event);
          setMessageError(undefined);
          if (event.target.value && event.target.value === '') {
            setIsVisiblePassword(false);
          }
          onChange(event);
        }
      } else {
        handleValidate(false, event);
      }

      //console.log('handleChange() ==>', { passwordValue, passwordConfirmValue });
      if (passwordValue) {
        if (passwordValue !== event.target.value) {
          setMessageError('La confirmación no coincide con la contraseña.');
          handleValidate(false, event);
        }
      }
    };

    const onChangeN = (value: number) => {
      //console.log('onChangeNumber() =>', value);
      setShowMessageError(false);
      const val = value;
      if (val && onChangeNumber) {
        if (isRequired && validate.isEmpty(val)) {
          handleValidate(false, val);
          setMessageError('El campo es obligatorio');
          onChangeNumber(val);
        } else if (validateConstraints) {
          if (val === undefined && !isRequired) {
            handleValidate(true, val);
            setMessageError(undefined);
            onChangeNumber(val);
          } else {
            const result = validate.single(val, validateConstraints);
            if (result) {
              handleValidate(false, val);
              setMessageError(result[0]);
              onChangeNumber(val);
            } else {
              handleValidate(true, val);
              setMessageError(undefined);
              onChangeNumber(val);
            }
          }
          //console.log('resutl', result);
        } else {
          handleValidate(true, val);
          setMessageError(undefined);
          if (val === undefined) {
            setIsVisiblePassword(false);
          }
          //onChangeNumber(val);
        }
      } else {
        handleValidate(false, val);
      }
    };

    const handleBlur = () => {
      if (messageError) {
        setShowMessageError(true);
      } else {
        setShowMessageError(false);
      }
      if (onBlur) {
        onBlur();
      }
    };

    const handleSearch = (value?: string | number) => {
      try {
        if (timeOutSearch) {
          clearTimeout(timeOutSearch);
        }
        if (value) {
          timeOutSearch = setTimeout(() => {
            if (onSearch) {
              onSearch(value);
            }
          }, TimeOut.search);
        } else {
          if (onSearch) {
            onSearch(value);
          }
        }
      } catch (err) {
        console.log('onSearch() => err', err);
      }
    };

    useEffect(() => {
      if (messageErrorTooltip) {
        setMessageError(messageErrorTooltip);
        setShowMessageError(true);
      }
      if (passwordValue) {
        if (passwordValue !== value) {
          setMessageError('La confirmación no coincide con la contraseña.');
          handleValidate(false);
        } else {
          setMessageError(undefined);
          handleValidate(true);
        }
      }
      if (disabled) {
        setMessageError(undefined);
        handleValidate(true);
        //onChange(undefined);
      }
      if (hide) {
        setMessageError(undefined);
        handleValidate(true);
      }
      if (onLoad) {
        onLoad(ref);
      }

      return () => {
        if (timeOutSearch) {
          clearTimeout(timeOutSearch);
        }
      };
    }, [messageErrorTooltip, disabled, passwordConfirmValue, passwordValue, hide]);

    if (type === 'password') {
      if (autoComplete === 'off' || autoComplete === 'new-password') {
        return (
          <div hidden={hide} className={`${classNameContainer}`}>
            {label && (
              <LabelCustom className={`mb-2 ${classNameLabel} flex items-center justify-between`}>
                <div style={styleLabel}>
                  {label}
                  {isRequired && isIconRequired && <span className='text-red-400'> *</span>}
                </div>
                {info && (
                  <Tooltip
                    trigger={['hover', 'focus']}
                    title={info}
                    overlayClassName='ant-tooltip-content-info'
                    overlayInnerStyle={{ backgroundColor: 'white', borderRadius: 5, color: 'black' }}
                    color={'white'}
                  >
                    <button className='flex self-center'>
                      <IconCustom name={NameIcons.info} />
                    </button>
                  </Tooltip>
                )}
              </LabelCustom>
            )}
            <Tooltip
              visible={showMessageError}
              trigger={['hover', 'focus']}
              title={messageError}
              placement='top'
              overlayClassName='ant-tooltip-content-error'
              overlayInnerStyle={{ backgroundColor: 'red' }}
            >
              <div>
                <div className={`relative ${classNameContainerInput}`}>
                  <Input
                    className={`text-gray-700 font-bold rounded-md pr-10 ${className}`}
                    style={{ borderRadius: 10 }}
                    size='large'
                    onChange={event => handleChange(event)}
                    type={isVisiblePassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    id='none'
                    name={`${Date.now()}`}
                    onBlur={() => {
                      if (value && value === '') {
                        setIsVisiblePassword(true);
                      }
                      handleBlur();
                    }}
                    onFocus={() => {
                      if (value && value === '') {
                        setIsVisiblePassword(true);
                      }
                      if (onFocus) {
                        onFocus();
                      }
                    }}
                    {...{ placeholder, value, onPressEnter, enterKeyHint, ref, autoSave, autoCorrect, maxLength, suffix }}
                  />
                  <button
                    type='button'
                    className='absolute h-full text-gray-500 right-4'
                    onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                  >
                    <IconCustom className='text-lg' name={!isVisiblePassword ? NameIcons.visibility : NameIcons.visibilityOff} />
                  </button>
                </div>
              </div>
            </Tooltip>
          </div>
        );
      }
      return (
        <div hidden={hide} className={`${classNameContainer}`}>
          {label && (
            <LabelCustom className={`mb-2 ${classNameLabel} flex items-center justify-between`}>
              <div style={styleLabel}>
                {label}
                {isRequired && isIconRequired && <span className='text-red-400'> *</span>}
              </div>
              {info && (
                <Tooltip
                  trigger={['hover', 'focus']}
                  title={info}
                  overlayClassName='ant-tooltip-content-info'
                  overlayInnerStyle={{ backgroundColor: 'white', borderRadius: 5, color: 'black' }}
                  color={'white'}
                >
                  <button className='flex self-center'>
                    <IconCustom name={NameIcons.info} />
                  </button>
                </Tooltip>
              )}
            </LabelCustom>
          )}
          <Tooltip
            visible={showMessageError}
            trigger={['hover', 'focus']}
            title={messageError}
            placement='top'
            overlayClassName='ant-tooltip-content-error'
            overlayInnerStyle={{ backgroundColor: 'red' }}
          >
            <Input.Password
              className={`text-gray-700 font-bold rounded-md ${className}`}
              style={{ borderRadius: 10 }}
              size='large'
              type='text'
              onChange={event => handleChange(event)}
              onBlur={() => handleBlur()}
              {...{
                placeholder,
                value,
                autoComplete,
                onPressEnter,
                enterKeyHint,
                ref,
                autoSave,
                autoCorrect,
                maxLength,
                onFocus,
                suffix
              }}
            />
          </Tooltip>
        </div>
      );
    }
    return (
      <div hidden={hide} className={`${classNameContainer}`}>
        {label && (
          <LabelCustom className={`mb-2 ${classNameLabel} flex items-center justify-between`}>
            <div style={styleLabel}>
              {label}
              {isRequired && isIconRequired && <span className='text-red-400'> *</span>}
            </div>
            {info && (
              <Tooltip
                trigger={['hover', 'focus']}
                title={info}
                overlayClassName='ant-tooltip-content-info'
                overlayInnerStyle={{ backgroundColor: 'white', borderRadius: 5, color: 'black' }}
                color={'white'}
                getPopupContainer={triggerNode => {
                  console.log('getPopupContainer() => triggerNode', triggerNode);
                  return triggerNode;
                }}
              >
                <button className='flex self-center'>
                  <IconCustom name={NameIcons.info} />
                </button>
              </Tooltip>
            )}
          </LabelCustom>
        )}
        <Tooltip
          visible={showMessageError}
          trigger={['hover', 'focus']}
          title={messageError}
          placement='top'
          overlayClassName='ant-tooltip-content-error'
          overlayInnerStyle={{ backgroundColor: 'red' }}
          getPopupContainer={triggerNode => {
            console.log('getPopupContainer() => triggerNode', triggerNode);
            return triggerNode;
          }}
        >
          {type === 'duration' && (
            <InputMask value={value} onChange={event => handleChange(event)} {...{ mask }}>
              {(inputprops: any) => (
                <Input
                  className={`text-gray-700 rounded-md ${IS_IOS ? 'h-10' : ''} font-bold ${className} ${
                    messageError ? 'ant-input-error' : ''
                  }`}
                  style={{ borderRadius: 10 }}
                  size='large'
                  //onChange={event => handleChange(event)}
                  //onBlur={() => handleBlur()}
                  {...{ inputprops }}
                  {...{
                    placeholder,
                    disabled,
                    type,
                    onPressEnter,
                    enterKeyHint,
                    ref,
                    autoSave,
                    autoCorrect,
                    autoComplete,
                    onFocus,
                    suffix
                  }}
                />
              )}
            </InputMask>
          )}
          {type === 'price' && (typeof value === 'number' || typeof value === 'number' || typeof value === 'undefined') && (
            <InputNumber
              //stringMode={false}
              formatter={value => convertNumberToPrice({ val: value, country: authStatus?.user?.data.country })}
              //parser={value => parseFloat(value?.replace(/\$\s?|(,*)/g, '') || '')}
              parser={value => replaceNumberToPrice({ val: value, country: authStatus?.user?.data.country })}
              className={`text-gray-700 w-full rounded-md ${IS_IOS ? 'h-10' : ''} font-bold ${className} ${
                messageError ? 'ant-input-error' : ''
              }`}
              decimalSeparator={authStatus?.user?.data.country !== EnumCountries.per ? ',' : '.'}
              //type='number'
              onChange={onChangeN}
              //value={value}
              size='large'
              onBlur={() => handleBlur()}
              style={{ borderRadius: 10 }}
              {...{
                placeholder,
                disabled,
                onPressEnter,
                enterKeyHint,
                ref,
                autoSave,
                autoCorrect,
                autoComplete,
                maxLength,
                onFocus
              }}
            />
          )}
          {type !== 'duration' && type !== 'price' && type !== 'textArea' && (
            <Input
              className={`text-gray-700 rounded-md ${IS_IOS ? 'h-10' : ''} font-bold ${className} ${messageError ? 'ant-input-error' : ''}`}
              style={{ borderRadius: 10 }}
              size='large'
              onChange={event => {
                if (onSearch) {
                  handleSearch(event.target.value);
                }
                handleChange(event);
              }}
              onBlur={() => handleBlur()}
              {...{
                placeholder,
                disabled,
                type,
                value,
                onPressEnter,
                enterKeyHint,
                ref,
                autoSave,
                autoCorrect,
                autoComplete,
                maxLength,
                onFocus,
                suffix
              }}
            />
          )}
          {type === 'textArea' && (
            <Input.TextArea
              className={`text-gray-700 font-bold rounded-md ${className} ${messageError ? 'ant-input-error' : ''}`}
              style={{ borderRight: 10 }}
              size='large'
              onChange={event => {
                if (onSearch) {
                  handleSearch(event.target.value);
                }
                handleChange(event);
              }}
              {...{
                rows,
                placeholder,
                disabled,
                type,
                value,
                enterKeyHint,
                ref,
                autoSave,
                autoCorrect,
                autoComplete,
                maxLength,
                onBlur,
                onFocus
              }}
            />
          )}
        </Tooltip>
      </div>
    );
  }
);

export default InputCustom;
