import { AxiosResponse } from 'axios';

import { ResponseApp } from 'configs/Interface';

import { handleErrorServiceApi } from 'helpers/Errors';

import DeliverySchedule from 'models/delivery/DeliverySchedule';

import api from '.';

class DeliveryProvider {
  async getDeliveriesConfig(): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`delivery?commerce=global`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getDeliveriesConfigByCommerce(commerceId?: string): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`delivery?commerce=${commerceId}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async createDeliveryConfig(deliverySchedule: DeliverySchedule): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().post(`delivery`, deliverySchedule.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateDeliveryConfigById(deliverySchedule: DeliverySchedule): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().put(`delivery/${deliverySchedule.data.id}`, deliverySchedule.sendServer());
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async deleteDeliveryConfigById(deliverySchedule: DeliverySchedule): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().delete(`delivery/${deliverySchedule.data.id}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new DeliveryProvider();
