import { useContext } from 'react';

import { AuthContext } from 'context/AuthContext';

import UserRole from 'models/user/UserRole';
import SelectionButton from 'components/atoms/selects/SelectionButton';

type RoleUserSelectProps = {
  onChange: (val?: UserRole[]) => void;
  values?: UserRole[];
};

const RoleUserSelect = ({ onChange, values }: RoleUserSelectProps) => {
  const { roles } = useContext(AuthContext);

  return (
    <SelectionButton
      classNameContainer='w-full sm:w-auto'
      className='w-full sm:w-auto'
      onClose={val => onChange(val)}
      text='Rol'
      values={values}
      items={roles?.map(val => ({ value: val.toString(), label: val.data.name, labelSearch: val.data.name, item: val })) || []}
    />
  );
};

export default RoleUserSelect;
