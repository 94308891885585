//import { FormatDate } from 'configs/Enums';
import { PageMetadata, PaginatedServicesQuery, ResponseApp } from 'configs/Interface';

import { BulkLoadStatisticsFilterValue } from 'components/molecules/filters/BulkLoadStatisticsFilter';
import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';

import Order, { OrderModelServer } from 'models/order';
import PieTypeDelivery from 'models/order/graphics/PieTypeDelivery';
import GraphicsOrderByStatusJany from 'models/order/graphics/OrderByStatusJanyBar';

import AverageTimeOrder from 'models/order/graphics/AverageTimeOrder';
import GraphicOrderByStorePie from 'models/order/graphics/OrdersByStore';
import FormManualOrder from 'models/order/FormManual';
import User from 'models/User';
import Store from 'models/store';
import GraphicOrderByStore from 'models/order/graphics/OrderByStore';
import ShippingStatus from 'models/delivery/ShippingStatus';

//import { stringAtDate } from 'helpers/Converts';
//import { isSameOrBefore } from 'helpers/Validate';

import OrderProvider from 'services/api/Order';

export const uploadFileCsvOfOrder = async (csvFile: File, store?: Store): Promise<ResponseApp> => {
  try {
    //console.log('csvFile', csvFile);
    const { status, data, ...other } = await OrderProvider.uploadFileCsv(csvFile, store);
    console.log('uploadFileCsvOfOrder ==> app ==> ', { status, data, ...other });

    if (status) {
      if ('message' in other) {
        return { status: true, message: other.message, data };
      }
      return { status: true };
    }
    if (data) {
      if (data.description) {
        return { status: false, data: data.description };
      }
      if (data.duplicates) {
        return { status: false, data };
      }
      if (data.data.length > 0 && data.data[0].type?.toLowerCase().indexOf('duplicadas') !== -1) {
        return { status: false, data: { duplicates: data.data[0].data, message: data.data[0].type }, message: data.data[0].type };
      }
      if (data.data) {
        return {
          status: false,
          message: data.message
        };
      }
    }
    if ('message' in other) {
      if (other.message === 'Failed to fetch') {
        return { status: false, message: 'No fue posible cargar el archivo en este momento' };
      } else {
        return { status: false, message: other.message };
      }
    }
    return { status: false, message: 'No fue posible cargar el archivo.' };
  } catch (err) {
    console.log('uploadFileCsvOfOrder ===>', (err as Error).message);
    return { status: false, message: 'No fue posible cargar el archivo.' };
  }
};

export const responseOrder = (data: any): ResponseApp => {
  try {
    let ordersFilter = [];
    if (data && data.orders && data.orders.length > 0) {
      ordersFilter = data.orders.filter((order: any) => order !== null && order !== undefined);
    }
    if (data && data.order && data.order.length > 0) {
      ordersFilter = data.order.filter((order: any) => order !== null && order !== undefined);
    }
    return {
      status: true,
      data: {
        orders: ordersFilter.map((order: OrderModelServer) => new Order(Order.formalizeData(order))),
        //.sort((orderA: Order, orderB: Order) => {
        //const dateA = stringAtDate(orderA.data.createdAt as string, FormatDate.yyyymmddHHmm, FormatDate.yyyymmddAlthmma);
        //const dateB = stringAtDate(orderB.data.createdAt as string, FormatDate.yyyymmddHHmm, FormatDate.yyyymmddAlthmma);
        ////console.log('order =>', { dateA, dateB, isSameOrBefore: isSameOrBefore({ dateBase: dateA, dateReference: dateB }) });
        //if (isSameOrBefore({ dateBase: dateA, dateReference: dateB })) {
        //return 1;
        //} else if (!isSameOrBefore({ dateBase: dateA, dateReference: dateB })) {
        //return -1;
        //}
        //return 0;
        //}),
        meta: { page: data.page || 0, items: data.items || 0, totalPages: data.totalPage || 0, totalItems: data.totalItems || 0 }
      }
    };
  } catch (err) {
    console.log('responseOrder() ==> err', err);
    return { status: true, data: [] };
  }
};

export const getOrders = async (params?: PaginatedServicesQuery): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.getOrders(params);
    console.log('getOrders ===> app ==> ', { status, data, ...other, params });

    if (status === 200) {
      if (data && data.orders && data.orders.length > 0) {
        return responseOrder(data);
      }
      //return responseOrder(data);
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  } catch (err) {
    console.log('getOrders ===> app ===> error ===> ', err);
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  }
};

export const getOrdersFailed = async (params?: PaginatedServicesQuery): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.getOrdersFailed(params);
    console.log('getOrdersFailed() ===> app ==> ', { status, data, ...other, params });

    if (status === 200) {
      if (data && data.orders && data.orders.length > 0) {
        return responseOrder(data);
      }
      return responseOrder(data);
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  } catch (err) {
    console.log('getOrders ===> app ===> error ===> ', err);
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  }
};

export const getOrdersByRangeDate = async (params: {
  startDate?: string;
  finishDate?: string;
  deliveryState?: string[];
  country?: string;
  numOrder?: string;
  page?: number;
  items?: number;
}): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.getOrdersByRangeDate(params);
    console.log('getOrdersByRangeDate ===> ', { status, data, other });

    if (status === 200) {
      if (data && data.orders && data.orders.length > 0) {
        return responseOrder(data);
      }
      return { status: true, data: [] };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  } catch (err) {
    console.log('getOrdersByRangeDate() ==> err', err);
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  }
};

export const getOrdersByStore = async (
  params: {
    store?: Store;
    stores?: Store[];
    dateStart?: string;
    dateEnd?: string;
    deliveryState?: string[];
    country?: string;
    numOrder?: string;
  } & PageMetadata
) => {
  try {
    const { status, data, ...other } = await OrderProvider.getOrdersByStore(params);

    if (status === 200) {
      if (data && data.orders && data.orders.length > 0) {
        return responseOrder(data);
      }
      return { status: false, message: 'No hay ordenes.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener las órdenes.' };
  } catch (err) {
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  }
};

export const getOrdersByNumOrder = async (params: PaginatedServicesQuery & { user?: User }) => {
  try {
    const { status, data, ...other } = await OrderProvider.getOrdersByNumOrder(params);
    console.log('getOrdersByNumOrder() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.order && data.order.length > 0) {
        if (params.user?.isLocatario()) {
          const { data: dataR, status: statusR } = responseOrder(data);
          if (statusR) {
            const orderFilter = (dataR.orders as Order[]).filter(order => {
              console.log('getOrdersByNumOrder() => { numberCommerce, commerceId }', {
                numberCommerce: order.data.commerceId,
                commerceId: params.user?.data.commerce?.data.commerceId
              });
              return order.data.commerceId === params.user?.data.commerce?.data.commerceId;
            });
            console.log('getOrdersByNumOrder() => orderFilter', orderFilter);
            if (orderFilter && orderFilter.length > 0) {
              return { status: true, data: { orders: orderFilter } };
            } else {
              return { status: false, message: 'No hay ordenes.' };
            }
          }
        } else {
          return responseOrder(data);
        }
        //return { status: true, data: new Order(Order.formalizeData(data.order)) };
      }
      return { status: false, message: 'No hay ordenes.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener las órdenes.' };
  } catch (err) {
    console.log('getOrdersByNumOrder() ==> err =>', err);
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  }
};

export const getGraphicPie = async (value: BulkLoadStatisticsFilterValue): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.getGraphicPie(value);
    console.log('getGraphicPie() ===>', { status, data, ...other });
    if (status === 200) {
      if (data) {
        return { status: true, data: new PieTypeDelivery(PieTypeDelivery.formalizeData(data)) };
      }
    } else if ('message' in other && other.message === '404') {
      return { status: true, message: 'No hay resultados.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  } catch (err) {
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  }
};

export const getGraphicOrdersByStatusJanis = async (
  value: BulkLoadStatisticsFilterValue & PaginatedServicesQuery & ParamsValueFilterStatistic
): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.getGraphicOrdersByStatusJanis(value);
    console.log('getGraphicOrdersByStatusJanis() ===>', { status, data, ...other });
    if (status === 200) {
      if (data) {
        return { status: true, data: new GraphicsOrderByStatusJany(GraphicsOrderByStatusJany.formalizeData(data)) };
      }
    } else if ('message' in other && other.message === '404') {
      return { status: true, message: 'No hay resultados.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  } catch (err) {
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  }
};

export const getGraphicTimeAverageOrder = async (value: BulkLoadStatisticsFilterValue & PaginatedServicesQuery): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.getGraphicTimeAverageOrder(value);
    console.log('getGraphicTimeAverageOrder() ===>', { status, data, ...other });
    if (status === 200) {
      if (data) {
        return { status: true, data: new AverageTimeOrder(AverageTimeOrder.formalizeData(data)) };
      }
    } else if ('message' in other && other.message === '404') {
      return { status: true, message: 'No hay resultados.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  } catch (err) {
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  }
};

export const getGraphicStore = async (value: BulkLoadStatisticsFilterValue & { country?: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.getGraphicStore(value);
    //console.log('getGraphicStore() ===>', { status, data, ...other });
    if (status === 200) {
      if (data) {
        return {
          status: true,
          data: {
            pieTypeDelivery: new PieTypeDelivery(PieTypeDelivery.formalizeDataSuperAdmin(data)),
            pieOrderStores: new GraphicOrderByStorePie(GraphicOrderByStorePie.formalizeData(data)),
            pieOrderStatus: new GraphicsOrderByStatusJany(GraphicsOrderByStatusJany.formalizeDataSuperAdmin(data)),
            graphicOrderByStore: new GraphicOrderByStore(GraphicOrderByStore.formalizeData(data))
          }
        };
      }
    } else if ('message' in other && other.message === '404') {
      return { status: true, message: 'No hay resultados.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  } catch (err) {
    return { status: false, message: 'No fue posible obtener los datos a gráficar.' };
  }
};

export const createOrderUnit = async (value: FormManualOrder, user?: User, store?: Store): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await OrderProvider.createOrderUnit(value, user, store);
    console.log('createOrderUnit() ==>', { other, status, data });
    if (status === 200 || status === 201) {
      return { status: true, message: 'Orden ingresada con éxito en el sistema.' };
    }
    if ('message' in other) {
      if (other.message && data) {
        return {
          status: false,
          message: 'Error ingresando la orden',
          data: {
            errors: data,
            errorSchema: false
          }
        };
      } else {
        return {
          status: false,
          message: 'Error ingresando la orden',
          data: {
            errors: other.message,
            errorSchema: true
          }
        };
      }
    }
    return { status: false, message: 'No fue posible crear la orden.' };
  } catch (err) {
    return { status: false, message: 'No fue posible crear la orden.' };
  }
};

export const getOrderById = async (id: string) => {
  try {
    const { status, data, ...other } = await OrderProvider.getOrdersById(id);
    console.log('getOrdersById() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.order && data.order.length > 0) {
        const ordersNew: Order[] = data.order.map((order: any) => new Order(Order.formalizeData(order, true))) || [];
        let orderNew: Order | undefined = undefined;
        if (ordersNew.length > 1) {
          orderNew = ordersNew.find(order => order.data.detail.data.order.data.order === id);
        } else {
          orderNew = ordersNew[0];
        }
        //const orderNew = data.order[0] && data.order[0].length > 0 ? data.order[0][0] : data.order[0];
        return { status: true, data: orderNew };
      }
      return { status: false, message: 'No hay ordenes.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener la orden' };
  } catch (err) {
    console.log('getOrdersById() => err', err);
    return { status: false, message: 'No fue posible obtener la orden' };
  }
};

export const getHistoryStateByIdOrder = async (order: Order): Promise<ResponseApp> => {
  try {
    const { data, status, ...other } = await OrderProvider.getHistoryStateByIdOrder(order);
    console.log('getHistoryStateByIdOrder() => { data, status, other }', { data, status, other });

    if (status === 200) {
      if (data && data.status && data.status.length > 0) {
        return {
          status: true,
          data: data.status.map((sta: any) => new ShippingStatus(ShippingStatus.formalizeData(sta)))
        };
      }
      return { status: true, message: 'No hay informacion.', data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener la orden' };
  } catch (err) {
    return { status: false, message: 'No fue posible obtener el historial de cambios.' };
  }
};

export const deleteFailedOrders = async (orders: Order[]) => {
  try {
    const { data, status, ...other } = await OrderProvider.deleteFailedOrders(orders);
    console.log('deleteFailedOrders() => { data, status, other }', { data, status, other });

    if (status === 200) {
      if (data && data.message) {
        return { status: true, message: data.message };
      }
      return { status: true, message: 'Ordenes fallidas borradas con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener la orden' };
  } catch (err) {
    return { status: false, message: 'No fue posible borrar las ordenes fallidas.' };
  }
};

export const getStatusOrder = async () => {
  try {
    const { status, data, ...other } = await OrderProvider.getStatusOrder();

    if (status === 200) {
      return { status: true, data: data.status };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No hay estados por cargar.' };
  } catch (err) {
    return { status: false, message: 'No hay estados por cargar.' };
  }
};

export const postFixOrders = async ({ orders }: { orders: Order[] }) => {
  try {
    const { status, data, ...other } = await OrderProvider.onCorrectOrders({ orders });
    console.log('postFixOrders() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.message) {
        return { status: true, message: data.message, data: data.processId };
      }
      return { status: true, message: '' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible corregir las ordenes.' };
  } catch (err) {
    console.log('postCorrectOrders() => err', err);
    return { status: false, message: 'No fue posible corregir las ordenes' };
  }
};

export const getOrdersFilter = async (params: BulkLoadStatisticsFilterValue & PaginatedServicesQuery) => {
  try {
    const { status, data, ...other } = await OrderProvider.getOrdersFilter(params);
    console.log('getOrdersFilter ===> app ==> ', { status, data, ...other, params, stores: params.stores?.map(stor => stor.data) });

    if (status === 200) {
      if (data && data.orders && data.orders.length > 0) {
        return responseOrder(data);
      }
      //return responseOrder(data);
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible obtener las órdenes.' };
  } catch (err) {
    console.log('getOrdersFilter() => err', err);
    return { status: false, message: `No fue posible obtener las ordenes ${(err as Error).message}.` };
  }
};

export const updateOrder = async (order: Order) => {
  try {
    const { status, data, ...other } = await OrderProvider.updateOrder(order);
    console.log('updateOrder ===> app ==> ', { status, data, ...other, order });

    if (status === 200 || status === 201) {
      return { status: true, message: 'Se actualizo con éxito.' };
    }
    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible actualizar.' };
  } catch (err) {
    console.log('updateOrder() => err', err);
    return { status: false, message: `No fue posible actualizar: ${(err as Error).message}.` };
  }
};
