import Order, { DataTableOrder } from 'models/order';

type CellLocationProps = {
  order?: DataTableOrder;
  ignoreError?: boolean;
};

const CellLocation = ({ order, ignoreError }: CellLocationProps) => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center py-2 pl-4'>
      <div className='w-full h-full flex flex-col justify-center border-r border-grayaraucoborder pr-4'>
        <h3 className='text-xs font-bold text-transparent'>
          {ignoreError && Order.isParameterInvalid(order?.client?.fullName) ? '' : order?.client?.fullName}
        </h3>

        {order && (
          <div className='flex flex-col mt-2'>
            {order.location && order.location.address && (
              <div className='flex flex-row items-center'>
                <p className='text-xs text-grayarauco'>
                  {ignoreError && Order.isParameterInvalid(order?.location?.address) ? '' : order?.location?.address}
                </p>
              </div>
            )}

            {order.location && order.location.city && (
              <div className='flex flex-row items-center mt-2'>
                <p className='text-xs text-grayarauco'>
                  {ignoreError && Order.isParameterInvalid(order?.location?.city) ? '' : order?.location?.city}
                </p>
              </div>
            )}

            {order.location && order.location.district && (
              <div className='flex flex-row items-center mt-2'>
                <p className='text-xs text-grayarauco'>
                  {ignoreError && Order.isParameterInvalid(order?.location?.district) ? '' : order?.location?.district}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellLocation;
