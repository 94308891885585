import { FilterBillingParams } from 'components/molecules/filters/BillingFilter';
import { ResponseApp } from 'configs/Interface';

import { downloadBase64ToFile } from 'helpers/Converts';

import Invoice, { InvoiceServerHistoricalModel } from 'models/invoice';
import Store from 'models/store';

import InvoiceProvider from 'services/api/Invoice';

export const getInvoiceCurrent = async (params?: { store?: Store; country?: string }): Promise<ResponseApp> => {
  try {
    const { status, data, ...other } = await InvoiceProvider.getInvoiceCurrent(params);
    console.log('getInvoiceCurrent() ==>', { status, data, other, params });

    if (status === 200) {
      if (data) {
        return {
          status: true,
          data: new Invoice(Invoice.formalizeData(data))
        };
      }
      return { status: true, message: 'No hay facturas.', data: Invoice.voidIvoice() };
    }

    if ('message' in other) {
      return { status: false, message: other.message, data: Invoice.voidIvoice() };
    }

    return { status: false, message: 'No fue posible cargar las facturas.', data: Invoice.voidIvoice() };
  } catch (err) {
    console.log('getInvoiceByPagAndPer() => err', err);
    return { status: false, message: `No fue posible cargar las facturas: ${(err as Error).message}`, data: Invoice.voidIvoice() };
  }
};

export const getInvoiceHistorical = async (params?: FilterBillingParams) => {
  try {
    const { status, data, ...other } = await InvoiceProvider.getInvoiceHistorical(params);
    console.log('getInvoiceHistorical() ==>', { status, data, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map(
            (invoice: InvoiceServerHistoricalModel) =>
              new Invoice(Invoice.formalizeDataHistorical({ ...invoice, store: params?.store, year: params?.year }))
          )
        };
      }
      return { status: true, message: 'No hay facturas.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible cargar las facturas.' };
  } catch (err) {
    console.log('getInvoiceHistorical() => err', err);
    return { status: false, message: `No fue posible cargar las facturas: ${(err as Error).message}`, data: Invoice.voidIvoice() };
  }
};

export const getInvoiceDetail = async (params?: FilterBillingParams) => {
  try {
    const { status, data, ...other } = await InvoiceProvider.getInvoiceDetail(params);
    console.log('getInvoiceDetail() ==>', { status, data, other, params });

    if (status === 200) {
      if (data) {
        return {
          status: true,
          data: new Invoice(Invoice.formalizeData(data))
        };
      }
      return { status: true, message: 'No hay facturas.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible cargar las facturas.' };
  } catch (err) {
    console.log('getInvoiceDetail() => err', err);
    return { status: false, message: `No fue posible cargar las facturas: ${(err as Error).message}`, data: Invoice.voidIvoice() };
  }
};

export const getFileDownloadInvoice = async (invoice?: Invoice) => {
  try {
    const { status, data, ...other } = await InvoiceProvider.getFileDownload(invoice);
    if (status === 200) {
      if (data.file) {
        await downloadBase64ToFile({ data: data.file, type: 'xlsx' });
        return { status: true, message: 'Archivo descargado con exito.' };
      }
      return { status: false, message: 'No fue posible procesar el archivo para la descarga.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible descargar el archivo.' };
  } catch (err) {
    console.log('getFileDownloadInvoice() =>', err);
    return { status: false, message: `No fue posible descargar el archivo: ${(err as Error).message}` };
  }
};
