import { AxiosResponse } from 'axios';
import { BulkLoadStatisticsFilterValue } from 'components/molecules/filters/BulkLoadStatisticsFilter';
import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';

import { DashboardOrganismSections, EnumTypePerformance, FormatDate, TypeDelivery } from 'configs/Enums';
import { PaginatedServicesQuery, ParamsGeneralQuery, ResponseApp } from 'configs/Interface';
import moment from 'configs/MomentJS';
import { homologationInterval } from 'helpers/Converts';

import { handleErrorServiceApi } from 'helpers/Errors';
//import Store from 'models/store';

import api from '.';

class StatisticProvider {
  parseQueryServices({
    country,
    deliveryStates,
    stores,
    type,
    typesDelivery,
    typesOperator,
    dateRange,
    timeInterval,
    orderStates,
    isGroupTypeDelivery,
    ignoreGroup = true,
    typePerformance,
    section
  }: PaginatedServicesQuery & BulkLoadStatisticsFilterValue & ParamsValueFilterStatistic & ParamsGeneralQuery) {
    const body: any = {};
    console.log('parseQueryServices() => { page, items, country }', {
      country,
      deliveryStates,
      stores,
      type,
      typesDelivery,
      typesOperator,
      dateRange,
      timeInterval,
      orderStates,
      isGroupTypeDelivery,
      ignoreGroup,
      typePerformance
    });
    //&tipo=${encodeURIComponent(types)}&status=${deliveryStatesFormat}&store=${storesFormat}

    if (country) {
      body.country = country;
    }

    if (deliveryStates) {
      body.status = deliveryStates;
    }

    if (orderStates) {
      body.status = orderStates;
    }

    if (stores) {
      const storesFormat = stores.map(store => store.data.commerceId);
      body.store = storesFormat;
    }

    if (type) {
      //const typeFormat = type.join(',');
      body.tipo = type;
    }

    if (timeInterval) {
      body.grafico = timeInterval;
    }

    if (typePerformance) {
      if (typePerformance === EnumTypePerformance.delivery) {
        if (typesDelivery) {
          body.tipo = typesDelivery
            .filter(typeDelivery => typeDelivery.data.value !== TypeDelivery.pickupStore)
            .map(typeDelivery => typeDelivery.data.value);
        } else {
          body.tipo = [TypeDelivery.expressDelivery, TypeDelivery.nextDay, TypeDelivery.sameDay];
        }
      } else if (typePerformance === EnumTypePerformance.pickup) {
        body.tipo = [TypeDelivery.pickupStore];
      } else {
        body.tipo = [TypeDelivery.expressDelivery, TypeDelivery.nextDay, TypeDelivery.sameDay, TypeDelivery.pickupStore];
      }
    } else if (isGroupTypeDelivery && !ignoreGroup) {
      body.tipo = [TypeDelivery.expressDelivery, TypeDelivery.nextDay, TypeDelivery.sameDay, TypeDelivery.pickupStore];
    } else if (typesDelivery) {
      if (section === DashboardOrganismSections.general) {
        body.tipo = [TypeDelivery.expressDelivery, TypeDelivery.nextDay, TypeDelivery.sameDay, TypeDelivery.pickupStore];
      } else {
        body.tipo = typesDelivery.map(typeDelivery => typeDelivery.data.value);
      }
    }

    if (dateRange) {
      body.startDate = moment(`${dateRange.startDate} 00:00`, FormatDate.yyyymmddHHmmAlt).valueOf();
      body.endDate = moment(`${dateRange.endDate} 23:59`, FormatDate.yyyymmddHHmmAlt).valueOf();
    }

    if (typesOperator) {
      const typesOperatorFormat = typesOperator.map(tOperator => tOperator.data.value);
      body.typeStore = typesOperatorFormat;
    }

    return body;
  }

  async getStatisticTypeDeliveryPerformance(params: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      const body = this.parseQueryServices(params);
      let typeDelivery = EnumTypePerformance.delivery;

      if (params.typePerformance === EnumTypePerformance.pickup) {
        typeDelivery = EnumTypePerformance.delivery;
      }

      return api().post(`graficas/super/${typeDelivery}`, { ...body, typeDelivery, status: [] });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getStatisticPerformance(params: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      const body = this.parseQueryServices(params);

      return api().post(`graficas/performance`, { ...body, status: [] });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getKpiGeneral(value: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      const body = this.parseQueryServices(value);
      //delete body.status;
      //delete body.tipo;

      return await api().post(`graficas/super/kpi`, { ...body });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getStatusOrder(value: ParamsValueFilterStatistic & ParamsGeneralQuery) {
    try {
      const body = this.parseQueryServices(value);
      return await api().post(`graficas/janis`, { ...body });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getCountOrderByTypeDelivery(value: ParamsValueFilterStatistic) {
    try {
      const body = this.parseQueryServices(value);
      return await api().post(`graficas`, { ...body });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrdersByStore(value: ParamsValueFilterStatistic) {
    try {
      const body = this.parseQueryServices(value);
      return await api().post(`graficas/store`, { ...body });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getEvolutionByTypesDelivery(value: ParamsValueFilterStatistic) {
    try {
      const body = this.parseQueryServices({ ...value, ignoreGroup: false });
      if (value && value.timeInterval) {
        body.grafico = homologationInterval(value.timeInterval);
      }
      return await api().post(`graficas/bydelivery`, { ...body });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getActiveStores({ country, typesOperator }: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      let body: any = undefined;

      if (country) {
        body = {
          country,
          typeStore: typesOperator?.map(typeOperator => typeOperator.data.value)
        };
      }

      return await api().post(`graficas/shop`, body);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getPickupDemand(params?: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      console.log('getPickupDemand() ==> params', params);
      const date = {
        from: params?.dateRangeDemandPickup?.startDate || params?.dateRange?.startDate,
        to: params?.dateRangeDemandPickup?.endDate || params?.dateRange?.endDate
      };
      const body = this.parseQueryServices(params || {});
      return api().post(`graficas/demand`, { ...body, ...date, country: params?.country, group: params?.group, status: [] });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrderCanceled(params?: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      //body.startDate = moment(`${dateRange.startDate} 00:00`, FormatDate.yyyymmddHHmmAlt).valueOf();
      const date = {
        startDate: moment(`${params?.dataRangeCancelOrder?.startDate} 00:00`, FormatDate.yyyymmddHHmmAlt).valueOf(),
        endDate: moment(`${params?.dataRangeCancelOrder?.endDate} 23:59`, FormatDate.yyyymmddHHmmAlt).valueOf()
      };
      return api().post(`graficas/canceledOrders`, {
        ...date,
        typeStore: params?.typesOperator?.map(typeOperator => typeOperator.data.value),
        store: params?.stores?.map(store => store.data.commerceId) || [],
        tipo: params?.typesDelivery?.map(typeDelivery => typeDelivery.data.value) || [],
        country: params?.country
      });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOrderFail(params?: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      const date = {
        startDate: moment(`${params?.dateRangeFailedOrder?.startDate} 00:00`, FormatDate.yyyymmddHHmmAlt).valueOf(),
        endDate: moment(`${params?.dateRangeFailedOrder?.endDate} 23:59`, FormatDate.yyyymmddHHmmAlt).valueOf()
      };
      return api().post(`graficas/orders-fail`, {
        ...date,
        typeStore: params?.typesOperator?.map(typeOperator => typeOperator.data.value),
        store: params?.stores?.map(store => store.data.commerceId) || [],
        tipo: params?.typesDelivery?.map(typeDelivery => typeDelivery.data.value) || [],
        country: params?.country
      });
    } catch (err) {
      console.log('getOrderFail() ==> err', { err });
      return handleErrorServiceApi(err as Error);
    }
  }

  async getAmounts(params: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      return api().post(`graficas/amounts`, { ...this.parseQueryServices(params), status: [] });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getOperators(params: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      return api().post(`graficas/operators`, { ...this.parseQueryServices(params), status: [] });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getDistribution(params: ParamsValueFilterStatistic): Promise<AxiosResponse | ResponseApp> {
    try {
      return api().post(`graficas/distribution`, { ...this.parseQueryServices(params), status: [] });
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new StatisticProvider();
