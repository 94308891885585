import { useContext, useState, useEffect } from 'react';

import { AuthContext } from 'context/AuthContext';

import ModalCustom from '.';
import SelectCustom from 'components/atoms/selects';

const StoreSelectorModal = () => {
  const { stores, authStatus, onUpdateStoreSession, storeSession } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log('useEffect() => StoreSelectorModal => ', { isCellarPA: authStatus.user?.isCellarPA(), storeSession: !storeSession });
    if (authStatus.user?.isCellarPA() && !storeSession) {
      setShowModal(true);
    }
  }, [stores, authStatus]);

  if (authStatus.user?.isCellarPA()) {
    return (
      <div>
        <SelectCustom
          label='Selecciona la tienda:'
          data={stores
            .filter(store => store.data.isActive === true)
            .map(store => ({ label: store.data.brand, value: store.toString(), item: store }))}
          onChange={value => onUpdateStoreSession(value)}
          value={storeSession}
          allowClear={false}
        />

        <ModalCustom
          isVisible={showModal}
          onClose={() => {
            //console.log('onClose() => countrySelector', { countryUser, countryItems: CountryItems[CountryItems.length - 1] });
            if (storeSession) {
              onUpdateStoreSession(storeSession);
              setShowModal(false);
            } else {
              setShowModal(true);
            }
          }}
        >
          <div>
            <h1>Selecciona la tienda</h1>
            <div className='flex flex-row mt-4'>
              <SelectCustom
                //label='Selecciona la tienda:'
                data={stores
                  .filter(store => store.data.isActive === true)
                  .map(store => ({ label: store.data.brand, value: store.toString(), item: store }))}
                onChange={value => {
                  onUpdateStoreSession(value);
                  setShowModal(false);
                }}
              />
            </div>
          </div>
        </ModalCustom>
      </div>
    );
  }
  return null;
};

export default StoreSelectorModal;
