import { useContext, useEffect, useReducer, useState } from 'react';
import { Tabs } from 'antd';

import {
  DashboardOrganismSections,
  EnumTypeOperator,
  //EnumTypeOperator,
  EnumTypePerformance,
  //FormatDate,
  NameIcons,
  SizeScreen,
  StyleButton,
  TypeTimeInterval
} from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import ButtonCustom from 'components/atoms/buttons';
import DatePickerCustom from 'components/atoms/pickers/DatePicker';
import SelectionButton from 'components/atoms/selects/SelectionButton';
import IconCustom from 'components/atoms/icons';
import TypeOperatorSelect from 'components/molecules/selects/TypeOperator';

import { capitalize, reducerDelivery, stringDeliveryToIcon } from 'helpers/Converts';

import TypeOperator from 'models/store/TypeOperator';
import Store from 'models/store';
import Delivery from 'models/Delivery';
import moment from 'moment';
//import { getDateNow } from 'helpers/Generator';

type StatisticHeaderProps = {
  onFilter: (params?: ParamsValueFilterStatistic) => void;
  stores?: Store[];
  values?: ParamsValueFilterStatistic;
  onChangeSeccion: (key: DashboardOrganismSections) => void;
  section?: DashboardOrganismSections;
};

enum TypeValueFilter {
  typesOperator = 'typesOperator',
  stores = 'stores',
  typesDelivery = 'typesDelivery',
  orderStates = 'orderStates',
  dateRange = 'dateRange',
  all = 'all',
  typePerformance = 'typePerformance'
}

export type ParamsValueFilterStatistic = {
  typesOperator?: TypeOperator[];
  stores?: Store[];
  typesDelivery?: Delivery[];
  orderStates?: string[];
  dateRange?: { startDate: string; endDate: string };
  dateRangeDemandPickup?: { startDate: string; endDate: string };
  dateRangeFailedOrder?: { startDate?: string; endDate?: string };
  dataRangeCancelOrder?: { startDate?: string; endDate?: string };
  section?: DashboardOrganismSections;
  /** cantidad de montos de ventas mensuales **/
  intervalTimeAmountSale?: 'year' | 'yearActuality';
  timeInterval?: TypeTimeInterval;
  timeIntervalCancelOrder?: TypeTimeInterval;
  country?: string[];
  isGroupTypeDelivery?: boolean;
  // usado para ignorar la agrupacion
  ignoreGroup?: boolean;
  typePerformance?: EnumTypePerformance;
  /** filtro de demanda pickup **/
  group?: string;
  tabActiveBusiness?: string;
};

const { TabPane } = Tabs;

const StatisticHeader = ({ onFilter, stores, onChangeSeccion, values, section }: StatisticHeaderProps) => {
  const { typesOperator, authStatus, typesDelivery: typesD, deliveryStates } = useContext(AuthContext);
  const { screenResolution } = useContext(RootContext);

  const [firstLoad, setFirstLoad] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [isOpenOtherFilter, setIsOpenOtherFilter] = useState(false);
  const [typesDelivery, setTypesDelivery] = useState<Delivery[]>([]);

  const [valueFilter, dispatchValueFilter] = useReducer(
    (prevState: ParamsValueFilterStatistic, action: { type: TypeValueFilter; value: any }) => {
      switch (action.type) {
        case TypeValueFilter.typesOperator:
          return {
            ...prevState,
            typesOperator: action.value
          };
        case TypeValueFilter.stores:
          return {
            ...prevState,
            stores: action.value
          };
        case TypeValueFilter.typesDelivery:
          return {
            ...prevState,
            typesDelivery: action.value
          };
        case TypeValueFilter.orderStates:
          return {
            ...prevState,
            orderStates: action.value
          };
        case TypeValueFilter.dateRange:
          return {
            ...prevState,
            dateRange: action.value
          };
        case TypeValueFilter.typePerformance:
          return {
            ...prevState,
            typePerformance: action.value
          };
        case TypeValueFilter.all:
          return {
            ...action.value
          };
        default:
          return {
            ...prevState
          };
      }
    },
    {
      typesOperator: typesOperator || undefined,
      stores: undefined,
      typesDelivery: undefined,
      orderStates: undefined,
      dateRange: undefined,
      typePerformance: DashboardOrganismSections.general
    }
  );

  const onChangeTab = (activeKey: string) => {
    console.log('onChangeTab() => activeKey', { activeKey, typesD });
    onChangeSeccion(activeKey as DashboardOrganismSections);
    if (activeKey === DashboardOrganismSections.general) {
      onFilter({ ...values, typePerformance: undefined, section: activeKey });
    } else if (activeKey === DashboardOrganismSections.performanceDelivery) {
      onFilter({ ...values, typePerformance: EnumTypePerformance.delivery, section: activeKey });
    } else if (activeKey === DashboardOrganismSections.performancePickup) {
      onFilter({ ...values, typePerformance: EnumTypePerformance.pickup, section: activeKey });
    } else if (activeKey === DashboardOrganismSections.business) {
      onFilter({ ...values, typePerformance: undefined, section: activeKey });
    }
    dispatchValueFilter({ type: TypeValueFilter.typePerformance, value: activeKey });
  };

  useEffect(() => {
    let typesDeliveryNew: Delivery[] = [];
    let typesDeliveryValue: Delivery[] = [];
    let typesOperatorValue: TypeOperator[] = [];

    if (typesD) {
      typesDeliveryNew = typesD.filter(typeD => {
        if (section === DashboardOrganismSections.general || section === DashboardOrganismSections.business) {
          return true;
        }
        return section === (reducerDelivery(typeD.data.value) as DashboardOrganismSections);
      });

      typesDeliveryValue =
        values?.typesDelivery?.filter(typeD => {
          if (section === DashboardOrganismSections.general || section === DashboardOrganismSections.business) {
            return true;
          }
          return section === (reducerDelivery(typeD.data.value) as DashboardOrganismSections);
        }) || typesDeliveryNew;
    }

    if (typesOperator) {
      if (values?.typesOperator && values?.typesOperator?.length > 0) {
        typesOperatorValue = values?.typesOperator;
      } else {
        typesOperatorValue =
          typesOperator.filter(typeOperator => typeOperator.data.value?.toLowerCase() !== EnumTypeOperator.dummy) || undefined;
      }
    }

    //const val: ParamsValueFilterStatistic = {
    //typesOperator: typesOperator.filter(typeOperator => typeOperator.data.value?.toLowerCase() !== EnumTypeOperator.dummy) || undefined,
    //stores: authStatus.user?.isLocatario() && authStatus.user.data.commerce ? [authStatus.user.data.commerce] : stores,
    //typesDelivery: typesDeliveryNew,
    //dateRange: {
    //endDate: getDateNow({ format: FormatDate.yyyymmdd }) as string,
    //startDate: `${getDateNow({ format: FormatDate.yyyymm })}-01`
    //},
    //orderStates: deliveryStates
    //};
    const val: ParamsValueFilterStatistic = {
      typesOperator: typesOperatorValue,
      stores: values?.stores,
      typesDelivery: typesDeliveryValue,
      dateRange: {
        endDate: values?.dateRange?.endDate || '',
        startDate: values?.dateRange?.startDate || ''
      },
      orderStates: values?.orderStates
    };
    dispatchValueFilter({
      value: val,
      type: TypeValueFilter.all
    });
    setTypesDelivery(typesDeliveryNew);

    console.log('useEffect() ==> typesDeliveryNew', {
      typesDeliveryNew,
      typesOperatorValue,
      typesOperator,
      typesOperatorV: values?.typesOperator
    });
    let validationLoad = false;
    if (!authStatus.user?.isLocatario()) {
      validationLoad =
        typesOperatorValue &&
        typesOperatorValue.length > 0 &&
        stores &&
        stores.length > 0 &&
        typesDeliveryNew &&
        typesDeliveryNew.length > 0 &&
        deliveryStates &&
        deliveryStates.length &&
        !firstLoad
          ? true
          : false;
    } else {
      validationLoad =
        typesOperatorValue &&
        typesOperatorValue.length > 0 &&
        typesDeliveryNew &&
        typesDeliveryNew.length > 0 &&
        deliveryStates &&
        deliveryStates.length &&
        !firstLoad
          ? true
          : false;
    }

    if (validationLoad) {
      onFilter(val);
      setFirstLoad(true);
    }
  }, [typesOperator, stores, typesD, deliveryStates, section]);

  return (
    <div className='bg-white pt-8 px-5 sm:px-10' style={{ marginTop: 2 }}>
      <div className='flex flex-col w-full'>
        <div>
          <h1 className='text-2xl sm:text-3xl font-bold'>Estadísticas</h1>
        </div>

        <div className='flex flex-col justify-between items-center'>
          {screenResolution <= SizeScreen.xl && (
            <div className='flex w-full justify-start items-start'>
              <h3 className='text-base font-normal'>Datos generales</h3>
            </div>
          )}

          <Tabs
            centered={true}
            size='large'
            tabBarExtraContent={{
              left: screenResolution > SizeScreen.xl ? <h3 className='text-lg font-normal'>Datos generales</h3> : undefined
            }}
            defaultActiveKey={DashboardOrganismSections.general}
            className='flex flex-1 w-full text-lg tab-statistics'
            onChange={onChangeTab}
          >
            <TabPane tab='Datos generales' className='text-xs md:text-lg' key={DashboardOrganismSections.general} />
            <TabPane tab='Delivery' className='text-xs md:text-lg' key={DashboardOrganismSections.performanceDelivery} />
            <TabPane tab='Pickup' className='text-xs md:text-lg' key={DashboardOrganismSections.performancePickup} />
            {(authStatus.user?.isSuperAdmin() || authStatus.user?.isSuperAdminGlobal()) && (
              <TabPane tab='Negocio' className='text-xs md:text-lg' key={DashboardOrganismSections.business} />
            )}
          </Tabs>
        </div>
      </div>

      <div className='flex flex-col'>
        <div
          className='flex flex-col mt-0 xl:mt-5 pb-7 relative xl:justify-between xl:items-center xl:flex-row'
          hidden={!isOpenFilter && screenResolution < SizeScreen.md}
        >
          {section !== DashboardOrganismSections.business &&
            section !== DashboardOrganismSections.performanceDelivery &&
            section !== DashboardOrganismSections.performancePickup &&
            screenResolution <= SizeScreen.xl && (
              <div className='flex flex-col justify-end items-end'>
                <DatePickerCustom
                  onChangeRange={values => {
                    dispatchValueFilter({ type: TypeValueFilter.dateRange, value: values });
                  }}
                  value={
                    valueFilter.dateRange ? [moment(valueFilter.dateRange?.startDate), moment(valueFilter.dateRange?.endDate)] : undefined
                  }
                  isRange
                  className=''
                  classNameContainer='ml-0 md:ml-2 w-65 relative self-end'
                  label='Rango de fechas'
                  getPopupContainer={(triggerNode: any) => {
                    console.log('getPopupContainer() => triggerNode', triggerNode);
                    return triggerNode;
                  }}
                />

                {screenResolution < SizeScreen.md && (
                  <ButtonCustom
                    className='mt-4 flex flex-row justify-center items-center'
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                    styleButton={StyleButton.outlinePrimary}
                    onClick={() => setIsOpenOtherFilter(!isOpenOtherFilter)}
                  >
                    <IconCustom name={NameIcons.filter} className='text-primary' />
                    <p className='text-primary text-sm font-bold'>Ver filtros</p>
                  </ButtonCustom>
                )}
              </div>
            )}

          <div
            className='flex flex-col items-start sm:flex-row sm:items-end flex-1'
            hidden={!isOpenOtherFilter && screenResolution < SizeScreen.md}
          >
            <TypeOperatorSelect
              onChange={val => dispatchValueFilter({ type: TypeValueFilter.typesOperator, value: val })}
              value={valueFilter.typesOperator}
            />

            {!authStatus.user?.isLocatario() &&
              (section !== DashboardOrganismSections.business || values?.tabActiveBusiness !== 'storeActived') && (
                <SelectionButton
                  className='w-full justify-between'
                  classNameContainer='ml-0 mb-4 w-full sm:ml-2 sm:mb-0 sm:w-auto'
                  text='Marca'
                  items={
                    stores?.map(store => ({
                      label: capitalize(store.data.brand || ''),
                      labelSearch: store.data.brand || '',
                      value: store.toString(),
                      item: store
                    })) || undefined
                  }
                  limitBubbles={10}
                  getPopupContainer={(triggerNode: any) => {
                    console.log('getPopupContainer() => triggerNode', triggerNode);
                    return triggerNode;
                  }}
                  values={valueFilter.stores}
                  onChange={values => dispatchValueFilter({ type: TypeValueFilter.stores, value: values })}
                />
              )}

            {section !== DashboardOrganismSections.general &&
              section !== DashboardOrganismSections.performancePickup &&
              (section !== DashboardOrganismSections.business || values?.tabActiveBusiness !== 'storeActived') && (
                <SelectionButton
                  className='w-full justify-between'
                  classNameContainer='ml-0 mb-4 w-full sm:ml-2 sm:mb-0 sm:w-auto'
                  text='Tipo de orden'
                  items={typesDelivery.map(typeDelivery => ({
                    label: (
                      <div className='flex flex-row ml-2 items-center'>
                        <IconCustom name={stringDeliveryToIcon(typeDelivery.data.value)} />
                        <p className='ml-1'>{typeDelivery.data.name}</p>
                      </div>
                    ),
                    labelSearch: typeDelivery.data.name,
                    value: typeDelivery.toString(),
                    item: typeDelivery
                  }))}
                  getPopupContainer={(triggerNode: any) => {
                    console.log('getPopupContainer() => triggerNode', triggerNode);
                    return triggerNode;
                  }}
                  values={valueFilter.typesDelivery}
                  onChange={values => dispatchValueFilter({ type: TypeValueFilter.typesDelivery, value: values })}
                />
              )}

            {section !== DashboardOrganismSections.general &&
              section !== DashboardOrganismSections.performanceDelivery &&
              section !== DashboardOrganismSections.performancePickup &&
              section !== DashboardOrganismSections.business && (
                <SelectionButton
                  className='w-full justify-between'
                  classNameContainer='ml-0 mb-4 w-full sm:ml-2 sm:mb-0 sm:w-auto'
                  text='Estados de orden'
                  items={deliveryStates?.map(deliveryState => ({
                    label: deliveryState,
                    labelSearch: deliveryState,
                    value: deliveryState,
                    item: deliveryState
                  }))}
                  getPopupContainer={(triggerNode: any) => {
                    console.log('getPopupContainer() => triggerNode', triggerNode);
                    return triggerNode;
                  }}
                  values={valueFilter.orderStates}
                  onChange={values => dispatchValueFilter({ type: TypeValueFilter.orderStates, value: values })}
                />
              )}
          </div>

          {section !== DashboardOrganismSections.business &&
            section !== DashboardOrganismSections.performanceDelivery &&
            section !== DashboardOrganismSections.performancePickup &&
            screenResolution > SizeScreen.xl && (
              <div className='flex justify-end items-end sm:mx-8'>
                <DatePickerCustom
                  onChangeRange={values => {
                    dispatchValueFilter({ type: TypeValueFilter.dateRange, value: values });
                  }}
                  value={
                    valueFilter.dateRange ? [moment(valueFilter.dateRange?.startDate), moment(valueFilter.dateRange?.endDate)] : undefined
                  }
                  isRange
                  className=''
                  classNameContainer='ml-2 w-65 relative self-end mb-4 sm:mb-0'
                  label='Rango de fechas'
                  getPopupContainer={(triggerNode: any) => {
                    console.log('getPopupContainer() => triggerNode', triggerNode);
                    return triggerNode;
                  }}
                />
              </div>
            )}

          <div
            className='flex justify-center items-center self-center sm:justify-end sm:items-end sm:self-end py-1'
            style={{ marginTop: !isOpenOtherFilter && screenResolution < SizeScreen.xl ? 16 : 0 }}
          >
            <ButtonCustom style={{ width: screenResolution <= SizeScreen.sm ? 244 : 'auto' }} onClick={() => onFilter(valueFilter)}>
              Filtrar
            </ButtonCustom>
          </div>
        </div>

        {screenResolution < SizeScreen.md && (
          <div className='flex w-full justify-end'>
            <IconCustom onClick={() => setIsOpenFilter(!isOpenFilter)} name={isOpenFilter ? NameIcons.arrowUp : NameIcons.arrowDown} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StatisticHeader;
