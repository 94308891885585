import CountryProvider from 'services/api/Country';

export const getInfoToCountry = async ({ country }: { country?: string }) => {
  try {
    const { status, data, ...other } = await CountryProvider.getInfoToCountry(country);

    if (status === 200) {
      if (data && data.data) {
        return { status: true, data: data.data };
      }
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }
    return { status: false, message: 'No fue posible cargar la información.' };
  } catch (err) {
    return { status: false, message: `No fue posible cargar la información: ${(err as Error).message}` };
  }
};
