import { EnumTypeFile } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import { downloadBase64ToFile } from 'helpers/Converts';

export interface DownloadFileModel {
  fileLocation?: string;
  fileFormat?: string;
  name?: string;
  type?: EnumTypeFile;
  id: string;
}

export interface DownloadFileServerModel {
  fileLocation: string;
  fileFormat: string;
  id: string;
  name?: string;
  type?: EnumTypeFile;
}

class DownloadFile {
  data: DownloadFileModel;

  constructor(data: DownloadFileModel) {
    this.data = data;
  }

  static formalizeData(data: DownloadFileServerModel): DownloadFileModel {
    const { fileLocation, fileFormat, name, type, id } = data;

    return {
      fileLocation,
      fileFormat,
      id,
      name,
      type
    };
  }

  async download(): Promise<ResponseApp> {
    try {
      const { fileFormat, name, type } = this.data;

      await downloadBase64ToFile({ data: fileFormat, name: name || 'archivo', type: type || 'xls' });
      return { status: true, message: 'Archivo descargado con exito.' };
    } catch (err) {
      return { status: false, message: `No fue posible descargar el archivo: ${(err as Error).message}` };
    }
  }
}

export default DownloadFile;
