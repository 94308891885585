import { ItemGraph } from './Common';

interface AmountStatisticModel {
  items?: ItemGraph[];
  total?: number;
  totalLastMonth?: number;
}

class AmountStatistic {
  data: AmountStatisticModel;

  constructor(data?: AmountStatisticModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = { items: [], total: 0, totalLastMonth: 0 };
    }
  }

  static formalizeData(data: any) {
    const items: ItemGraph[] = [];
    let totalLastMonth = 0;

    if (data.month_graphic && data.month_graphic.length > 0) {
      totalLastMonth = data.month_graphic[0].value;
      data.month_graphic.reverse();
      data.month_graphic.forEach((item: any) => {
        items.push(item);
      });
    }
    return { items, total: 0, totalLastMonth };
  }
}

export default AmountStatistic;
