import { DataTableOrder } from 'models/order';

type CellStoreProps = {
  order?: DataTableOrder;
  ignoreError?: boolean;
};

const CellStore = ({ order }: CellStoreProps) => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center py-2 pl-5'>
      <div className='flex flex-col justify-center w-full border-r border-grayaraucoborder pr-4 h-full'>
        <h3 className='text-sm font-normal text-black text-center'>{order?.store}</h3>
      </div>
    </div>
  );
};

export default CellStore;
