import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { APP_NAME } from 'configs/Constants';
import { FormatDate, NameIcons, PathRoute, SizeScreen, StyleButton, UnitDate } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

//import SectionRouteWelcome from 'components/organisms/welcome/sections';
//import IconCustom from 'components/atoms/icons';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';
import InputCustom from 'components/atoms/inputs';
import ButtonCustom from 'components/atoms/buttons';
import DirectAccessCard from 'components/molecules/items/cards/DirectAccessCard';
import IconCustom from 'components/atoms/icons';

import { convertNumberToPrice, secondsToHumanize, stringAtDate } from 'helpers/Converts';
import { addTime, getDateNow } from 'helpers/Generator';
import { isSameOrAfter } from 'helpers/Validate';

import AlertMessage from 'components/molecules/messages/Alert';
import KpiGeneral from 'models/statistic/order/KpiGeneral';
import StatusOrder from 'models/statistic/order/StatusOrder';

interface WelcomeOrganismProps {
  onSearchOrderByNum: (numOrder?: string) => void;
  kpiGeneralStatistic?: KpiGeneral;
  statusOrder?: StatusOrder;
}

const WelcomeOrganism = ({ onSearchOrderByNum, kpiGeneralStatistic, statusOrder }: WelcomeOrganismProps) => {
  const { authStatus, countryUser, dateExpiredPassword } = useContext(AuthContext);
  const { screenResolution } = useContext(RootContext);

  const navigate = useNavigate();

  const [numOrder, setNumOrder] = useState<string>();
  const [isMessage, setIsMessage] = useState(false);

  useEffect(() => {
    if (dateExpiredPassword) {
      const dateBase = addTime({
        unit: UnitDate.days,
        amount: 10,
        baseDate: getDateNow({ format: FormatDate.none }) || '',
        format: FormatDate.none
      });
      const dateReference = stringAtDate(dateExpiredPassword, FormatDate.none, FormatDate.ddmmaaaa);
      if (
        isSameOrAfter({
          dateBase,
          dateReference
        })
      ) {
        setIsMessage(true);
      } else {
        setIsMessage(false);
      }
    } else {
      setIsMessage(false);
    }
  }, [dateExpiredPassword]);

  return (
    <MenuSessionContainer className='bg-gray-200 h-full'>
      <div className='container-welcome'>
        {isMessage && (
          <AlertMessage
            className='mb-4 border border-araucoblue-200'
            notIcon
            featuredText={
              <div className={`flex justify-between items-center ${screenResolution <= SizeScreen.md ? 'flex-col' : 'flex-row'}`}>
                <div className='flex flex-col'>
                  <h1 className='text-base font-bold text-araucoblue-200'>Actualiza tu contraseña</h1>
                  <p className='text-base font-normal text-black'>
                    Tienes hasta el <strong className='font-bold'>{dateExpiredPassword}</strong> para cambiar su contraseña, recuerda que
                    por nuestra política de seguridad, te solicitaremos actualizar tu contraseña cada 3 meses.
                  </p>
                </div>
                <div className={`mr-2 flex justify-center items-center ${screenResolution <= SizeScreen.md ? 'ml-0 mt-3' : 'ml-7 mt-0'}`}>
                  <ButtonCustom
                    styleButton={StyleButton.outlinePrimary}
                    onClick={() => {
                      navigate(
                        `${PathRoute.newPassword}?tokenAuth=${authStatus.accessToken}&force=true&months=${3}&date=${dateExpiredPassword}`
                      );
                    }}
                  >
                    Actualizar contraseña
                  </ButtonCustom>
                </div>
              </div>
            }
          />
        )}

        <div className='p-8 flex flex-col justify-start items-start bg-grayarauco-50 rounded-xl shadow-md'>
          <h3 className='font-normal text-base mb-2 text-black'>Ingresa n° de orden</h3>
          <div className='flex flex-col sm:flex-row'>
            <InputCustom
              classNameContainer='w-full sm:w-96'
              placeholder='Ej: 80711'
              onChange={event => setNumOrder(event.target.value)}
              onPressEnter={() => setNumOrder(numOrder)}
            />
            <ButtonCustom size='none' className='ml-3 px-14 h-10 mt-6 sm:mt-0' onClick={() => onSearchOrderByNum(numOrder)}>
              Ver información
            </ButtonCustom>
          </div>
        </div>

        <div className='my-8'>
          <h1 className='font-normal'>
            ¡Hola <span className='font-bold'>{authStatus.user?.data.firstName}</span>! Bienvenido a {APP_NAME}
          </h1>
        </div>

        {(authStatus.user?.isSuperAdmin() || authStatus.user?.isSuperAdminGlobal()) && (
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
            <DirectAccessCard
              pathRoute={PathRoute.dashboard}
              textButton='Datos generales'
              body={
                <div className='flex justify-center items-center flex-col mb-5'>
                  <div className='flex justify-center items-center mb-5'>
                    <p className='ml-4 text-xl'>Ticket promedio último mes</p>
                  </div>

                  <p className='text-3xl font-bold text-primary'>
                    {convertNumberToPrice({ val: parseFloat(`${kpiGeneralStatistic?.data.ticket.avg || 0}`), country: countryUser })}
                  </p>
                </div>
              }
            />

            {authStatus.user?.isSuperAdmin() && (
              <DirectAccessCard
                pathRoute={PathRoute.createStore}
                textButton='Agregar tienda'
                body={<IconCustom outlined lightOutlined name={NameIcons.store} className='text-tertiary text-7xl mb-4' />}
              />
            )}

            <DirectAccessCard
              pathRoute={PathRoute.dashboard}
              textButton='Datos generales'
              body={
                <div className='flex justify-center items-center flex-col mb-5'>
                  <div className='flex justify-center items-center mb-5'>
                    <p className='ml-4 text-xl'>Pedidos gestionados último mes</p>
                  </div>

                  <p className='text-3xl font-bold text-primary'>{statusOrder?.data.total || 0}</p>
                </div>
              }
            />

            {authStatus.user?.isSuperAdmin() && (
              <DirectAccessCard
                pathRoute={PathRoute.cutOffScheduleSetting}
                textButton='Límites de horario'
                body={<IconCustom outlined lightOutlined name={NameIcons.traffic} className='text-tertiary text-7xl mb-4' />}
              />
            )}
          </div>
        )}

        {authStatus.user?.isCellarPA() && (
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
            <DirectAccessCard
              pathRoute={PathRoute.listOrders}
              textButton='Listado de órdenes'
              body={
                <div className='flex justify-center items-center mb-10'>
                  <IconCustom outlined lightOutlined className='text-7xl text-tertiary font-light' name={NameIcons.listAlt} />
                </div>
              }
            />
            <DirectAccessCard
              pathRoute={PathRoute.dashboard}
              textButton='Estadísticas'
              body={
                <div className='flex justify-center items-center flex-col mb-5'>
                  <div className='flex justify-center items-center mb-5'>
                    <p className='ml-4 text-xl'>Órdenes ingresadas último mes</p>
                  </div>

                  <p className='text-3xl font-bold text-primary'>{statusOrder?.data.total}</p>
                </div>
              }
            />
            <DirectAccessCard
              pathRoute={PathRoute.dashboard}
              textButton='Estadísticas'
              body={
                <div className='flex justify-center items-center flex-col mb-5'>
                  <div className='flex justify-center items-center mb-5'>
                    <p className='ml-4 text-xl'>Tiempo promedio de retiro</p>
                  </div>

                  <p className='text-3xl font-bold text-primary'>{secondsToHumanize(kpiGeneralStatistic?.data.time.avg || 0)}</p>
                </div>
              }
            />
            <DirectAccessCard
              pathRoute={PathRoute.dashboard}
              textButton='Estadísticas'
              body={
                <div className='flex justify-center items-center flex-col mb-5'>
                  <div className='flex justify-center items-center mb-5'>
                    <p className='ml-4 text-xl'>Tiempo promedio de despacho express</p>
                  </div>

                  <p className='text-3xl font-bold text-primary'>{kpiGeneralStatistic?.getAvgTimePickupDisplay()}</p>
                </div>
              }
            />
          </div>
        )}

        {authStatus.user?.isLocatario() && (
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
            <DirectAccessCard
              pathRoute={PathRoute.unitManualLoad}
              textButton='Carga unitaria'
              body={
                <div className='flex justify-center items-center mb-10'>
                  <IconCustom outlined lightOutlined className='text-7xl text-primary font-light' name={NameIcons.inventory} />
                </div>
              }
            />
            <DirectAccessCard
              pathRoute={PathRoute.uploadFile}
              textButton='Carga masiva'
              body={
                <div className='flex justify-center items-center mb-10'>
                  <IconCustom outlined lightOutlined className='text-7xl text-tertiary font-light' name={NameIcons.uploadFile} />
                </div>
              }
            />
            <DirectAccessCard
              pathRoute={PathRoute.dashboard}
              textButton='Estadísticas'
              body={
                <div className='flex justify-center items-center flex-col mb-5'>
                  <div className='flex justify-center items-center mb-5'>
                    <p className='ml-4 text-xl'>Pedidos gestionados último mes</p>
                  </div>

                  <p className='text-3xl font-bold text-primary'>{statusOrder?.data.total}</p>
                </div>
              }
            />
            <DirectAccessCard
              pathRoute={PathRoute.frequentQuestionsList}
              textButton='Preguntas frecuentes'
              body={
                <div className='flex justify-center items-center mb-10'>
                  <IconCustom outlined lightOutlined className='text-7xl text-gray-500 font-light' name={NameIcons.help} />
                </div>
              }
            />
          </div>
        )}
      </div>
    </MenuSessionContainer>
  );
};

export default WelcomeOrganism;
