import React, { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { PathRoute, StyleButton, TypeToast } from 'configs/Enums';

import { AuthContext } from './AuthContext';
import { RootContext } from './RootContext';

import ProcessUploadFile from 'components/molecules/process/ProcessUploadFile';

import Order, { OrderModelWebSocket } from 'models/order';

import useWebSocketOrder from 'helpers/hooks/WebSocket';

export interface RouterInContextValues {
  updateOrders?: OrderModelWebSocket[];
  newOrders?: number;
  showModalLogout: () => void;
  /** height del contenedor de menu **/
  hContainer: number;
  /** actulizador del height del contenedor **/
  updateHContainer: (h: number) => void;
  /** hay ordenes correctas (usado bloquear) **/
  isCorrectedOrder: boolean;
  onIsCorrectOrders: (val: boolean) => void;
}

export const RouterInContext = createContext({} as RouterInContextValues);

const RouterInProvider = ({ children }: { children: React.ReactNode }) => {
  const { signOut } = useContext(AuthContext);
  const { showToast, showModalQuestion } = useContext(RootContext);

  const [isCorrectedOrder, setIsCorrectedOrder] = useState<boolean>(false);

  const navigate = useNavigate();

  const [updateOrders, setUpdateOrders] = useState<OrderModelWebSocket[]>([]);
  const [newOrders, setNewOrders] = useState<number>(0);
  const [hContainer, setHContainer] = useState<number>(0);

  const onLogout = () => {
    const result = signOut();
    if (result) {
      navigate(PathRoute.signin);
    } else {
      showToast({ text: 'No fue posible cerrar sesión.', type: TypeToast.error });
    }
  };

  const showModalLogout = () => {
    showModalQuestion({
      title: '¿Cerrar sesión?',
      options: [
        {
          text: 'Sí',
          onAction: onLogout,
          style: StyleButton.outlinePrimary
        },
        { text: 'No' }
      ]
    });
  };

  const onUpdateOrders = async (orders?: OrderModelWebSocket[]) => {
    console.log('onUpdateOrder() => orders', orders);
    setUpdateOrders(orders || []);
  };

  const onNewOrders = async (nOrders?: Order[]) => {
    console.log('onNewOrders() ==> nOrders', nOrders);
    setNewOrders(newOrders + 1);
  };

  const updateHContainer = (height: number) => {
    setHContainer(height);
    console.log('updateHContainer() ==> { height }', { height });
  };

  const onIsCorrectOrders = (val: boolean) => {
    setIsCorrectedOrder(val);
  };

  useWebSocketOrder({ onUpdateOrders, onNewOrders });

  return (
    <RouterInContext.Provider
      value={{ updateOrders, onIsCorrectOrders, isCorrectedOrder, newOrders, showModalLogout, hContainer, updateHContainer }}
    >
      {children}
      <ProcessUploadFile />
    </RouterInContext.Provider>
  );
};

export default RouterInProvider;
