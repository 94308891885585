import { useContext, useEffect, useState } from 'react';

import { TypeToast } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import CutOffScheduleOrganism from 'components/organisms/dashboard/settings/CutOffSchedule';

import { getDeliveriesConfig } from 'services/app/Delivery';
import DeliverySchedule from 'models/delivery/DeliverySchedule';

interface CutOffScheduleSettingPageProps {
  title?: string;
}

const CutOffScheduleSettingPage = ({ title }: CutOffScheduleSettingPageProps) => {
  const { showToast, showLoading, hideLoading } = useContext(RootContext);
  const { countryUser } = useContext(AuthContext);

  const [schedules, setSchedules] = useState<DeliverySchedule[]>([]);

  const loadSchedulesConfig = async () => {
    showLoading();
    const { status, data, message } = await getDeliveriesConfig(countryUser);
    console.log('loadSchedulesConfig() ==>', { status, data, message });

    if (status) {
      setSchedules(data);
    } else {
      setSchedules([]);
      showToast({ text: message || 'No fue posible cargar los horarios.', type: TypeToast.success });
    }
    hideLoading();
  };

  useEffect(() => {
    loadSchedulesConfig();
  }, [countryUser]);

  return <CutOffScheduleOrganism {...{ schedules, title, loadSchedulesConfig }} />;
};

export default CutOffScheduleSettingPage;
