import { useContext, useReducer, useState } from 'react';

import { NameIcons, SizeScreen } from 'configs/Enums';

import ButtonCustom from 'components/atoms/buttons';
import InputCustom from 'components/atoms/inputs';
import BrandAndTypeOperatorSelect from '../selects/BrandAndTypeOperator';
import StatusStoreSelect from '../selects/StatusStore';
import TypeDeliverySelect from '../selects/TypeDelivery';

import { RootContext } from 'context/RootContext';

import Store from 'models/store';
import TypeOperator from 'models/store/TypeOperator';
import Delivery from 'models/Delivery';
import IconCustom from 'components/atoms/icons';

type StoreBoardFilterProps = {
  onChange: (params: StoreBoardFilterParams) => void;
};

export interface StoreBoardFilterParams {
  typesOperator?: TypeOperator[];
  brands?: Store[];
  typesDelivery?: Delivery[];
  saleChannel?: string;
  rutOrID?: string;
  statusStore?: string[];
}

export enum StoreBoardFilterEnum {
  typesOperator = 'typesOperator',
  brands = 'brands',
  typesDelivery = 'typesDelivery',
  saleChannel = 'saleChannel',
  rutOrID = 'rutOrID',
  statusStore = 'statusStore'
}

const StoreBoardFilter = ({ onChange }: StoreBoardFilterProps) => {
  const { screenResolution } = useContext(RootContext);

  const [isOpen, setIsOpen] = useState(false);

  const [valueFilter, dispatchValue] = useReducer(
    (prevState: StoreBoardFilterParams, action: { type: StoreBoardFilterEnum; value: any }) => {
      switch (action.type) {
        case StoreBoardFilterEnum.typesOperator:
          return {
            ...prevState,
            typesOperator: action.value
          };
        case StoreBoardFilterEnum.brands:
          return {
            ...prevState,
            brands: action.value
          };
        case StoreBoardFilterEnum.typesDelivery:
          return {
            ...prevState,
            typesDelivery: action.value
          };
        case StoreBoardFilterEnum.saleChannel:
          return {
            ...prevState,
            saleChannel: action.value
          };
        case StoreBoardFilterEnum.rutOrID:
          return {
            ...prevState,
            rutOrID: action.value
          };
        case StoreBoardFilterEnum.statusStore:
          return {
            ...prevState,
            statusStore: action.value
          };
        default:
          return {
            ...prevState
          };
      }
    },
    {
      typesOperator: undefined,
      brands: undefined,
      typesDelivery: undefined,
      saleChannel: undefined,
      rutOrID: undefined,
      statusStore: undefined
    }
  );

  return (
    <div className='flex flex-col w-full sm:w-auto'>
      {(isOpen || screenResolution > SizeScreen.sm) && (
        <div className='flex flex-col w-full items-center md:items-end sm:w-auto sm:flex-row gap-3 mt-6'>
          <div className='flex w-full sm:w-auto flex-col md:flex-row gap-3'>
            <div className='flex flex-col sm:flex-row items-start gap-3 sm:items-end'>
              <BrandAndTypeOperatorSelect
                isDummy
                onChangeTypesOperator={val => dispatchValue({ type: StoreBoardFilterEnum.typesOperator, value: val })}
                onChangeBrands={val => dispatchValue({ type: StoreBoardFilterEnum.brands, value: val })}
              />
              <TypeDeliverySelect
                values={valueFilter.typesDelivery}
                onChange={val => dispatchValue({ type: StoreBoardFilterEnum.typesDelivery, value: val })}
              />
            </div>
            <div className='flex flex-col items-end sm:flex-row gap-3'>
              <InputCustom
                classNameContainer='w-full sm:w-44'
                className='w-full sm:w-44'
                label={'Sales channel'}
                placeholder='Ej: 111'
                value={valueFilter.saleChannel}
                onChange={val => dispatchValue({ type: StoreBoardFilterEnum.saleChannel, value: val.target.value })}
              />
              <InputCustom
                classNameContainer='w-full sm:w-44'
                className='w-full sm:w-44'
                label={'RUT/ID'}
                placeholder='Ej: 70999999'
                value={valueFilter.rutOrID}
                onChange={val => dispatchValue({ type: StoreBoardFilterEnum.rutOrID, value: val.target.value })}
              />
              <StatusStoreSelect
                values={valueFilter.statusStore}
                onChange={val => dispatchValue({ type: StoreBoardFilterEnum.statusStore, value: val })}
              />
            </div>
          </div>

          <div className={'w-full px-4 sm:w-auto'}>
            <ButtonCustom className={'w-full sm:w-auto'} onClick={() => onChange(valueFilter)}>
              Filtrar
            </ButtonCustom>
          </div>
        </div>
      )}
      {screenResolution <= SizeScreen.sm && (
        <div className='my-4 w-full flex justify-end'>
          <IconCustom name={isOpen ? NameIcons.arrowUp : NameIcons.arrowDown} onClick={() => setIsOpen(!isOpen)} />
        </div>
      )}
    </div>
  );
};

export default StoreBoardFilter;
