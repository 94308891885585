import { FormatDate } from 'configs/Enums';

import { isAfterDate, isBeforeDate } from 'helpers/Validate';

import Notification, { NotificationServerModel } from 'models/notification';

import NotificationProvider from 'services/api/Notification';

export const getNotifications = async () => {
  try {
    const { status, data, ...other } = await NotificationProvider.getNotifications();
    console.log('getNotifications() ==> { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.data && data.data.length > 0) {
        return {
          status: true,
          data: data.data
            .map((notification: NotificationServerModel) => new Notification(Notification.formalizeData(notification)))
            .sort((notfA: Notification, notfB: Notification) => {
              if (
                isAfterDate({
                  dateBase: notfA.data.dateFull || '',
                  dateReference: notfB.data.dateFull || '',
                  formatRef: FormatDate.yyyymmddAltHHmm,
                  formatBase: FormatDate.yyyymmddAltHHmm
                })
              ) {
                return -1;
              } else if (
                isBeforeDate({
                  dateBase: notfA.data.dateFull || '',
                  dateReference: notfB.data.dateFull || '',
                  formatRef: FormatDate.yyyymmddAltHHmm,
                  formatBase: FormatDate.yyyymmddAltHHmm
                })
              ) {
                return 1;
              }
              return 0;
            })
        };
      } else {
        return { status: false, message: 'No hay notificaiones' };
      }
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener las notificaciones.' };
  } catch (err) {
    console.log('getNotifications() ==> err', err);
    return { status: false, message: 'No fue posible obtener las notificaciones.' };
  }
};

export const updateReadNotification = async (connectionId: string) => {
  try {
    const { status, data, ...other } = await NotificationProvider.updateReadNotification(connectionId);
    console.log('updateReadNotification() => { status, data, ...other }', { status, data, ...other });

    if (status === 200 || status === 201 || status === 204) {
      return { status: true, message: 'Notification actulizada con éxito.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener las notificaciones.' };
  } catch (err) {
    console.log('updateReadNotification() ==> err', err);
    return { status: false, message: 'No fue posible actulizar la notificacion.' };
  }
};

export const updateReadNotifications = async (nots: Notification[]) => {
  try {
    const { status, data, ...other } = await NotificationProvider.updateReadNotifications(nots);
    console.log('updateReadNotifications() => { status, data, ...other }', { status, data, ...other });

    if (status === 200 || status === 201 || status === 204) {
      return { status: true, message: 'Notification actulizada con éxito.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message };
    }

    return { status: false, message: 'No fue posible obtener las notificaciones.' };
  } catch (err) {
    console.log('updateReadNotification() ==> err', err);
    return { status: false, message: 'No fue posible actulizar la notificacion.' };
  }
};
