import { useEffect, useState } from 'react';

//import { NameIcons, SizeScreen } from 'configs/Enums';
//import { RootContext } from 'context/RootContext';

//import SelectCustom from 'components/atoms/selects';
//import IconCustom from 'components/atoms/icons';
import SelectionButton from 'components/atoms/selects/SelectionButton';

import { capitalize } from 'helpers/Converts';

type StatusStoreSelectProps = {
  onChange?: (val?: string[]) => void;
  values?: string[];
};

const StatusStore = ['activa', 'inactiva'];

const StatusStoreSelect = ({ onChange, values }: StatusStoreSelectProps) => {
  //const { screenResolution } = useContext(RootContext);

  const [valueCurrent, setValueCurrent] = useState<string[] | undefined>(StatusStore);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const onChangeValue = (valueNew?: string[]) => {
    if (onChange) {
      onChange(valueNew);
    }
    setValueCurrent(valueNew);
  };

  useEffect(() => {
    setValueCurrent(values || StatusStore);
    if (!firstLoad) {
      if (onChange) {
        onChange(StatusStore);
      }
      setFirstLoad(true);
    }
  }, [values]);

  return (
    <SelectionButton
      classNameContainer={`w-full sm:w-auto`}
      text='Estado marca'
      items={StatusStore.map(sS => ({ value: sS, labelSearch: sS, label: capitalize(sS), item: sS }))}
      getPopupContainer={(triggerNode: any) => {
        //console.log('getPopupContainer() => triggerNode', triggerNode);
        return triggerNode;
      }}
      values={valueCurrent && valueCurrent.length !== 0 ? valueCurrent : undefined}
      onChange={val => {
        onChangeValue(val);
      }}
      onClose={() => {
        onChangeValue(valueCurrent);
      }}
    />
  );
};

export default StatusStoreSelect;
