import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { PathRoute } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';

import LoginFormMolecule from 'components/molecules/forms/Login';

const LoginPage = () => {
  const { signIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignIn = async (formLogin: { email: string; password: string }) => {
    const { status, data } = await signIn(formLogin);
    console.log('navigateInit() ==>', { status, data });
    if (data && data.token) {
      navigate(`${PathRoute.newPassword}?tokenAuth=${data.token}&force=true&months=${data.months}&date=${data.date}`);
    }
  };

  return <LoginFormMolecule {...{ handleSignIn }} />;
};

export default LoginPage;
