export interface AddressModel {
  number: string;
  state: string;
  adr: string;
  country: string;
  district?: string;
  department?: string;
}

export interface AddressModelServer {
  number: string;
  state: string;
  dir: string;
  pais: string;
  country: string;
  district?: string;
  department?: string;
}

class Address {
  data: AddressModel;

  constructor(data: AddressModel) {
    this.data = data;
  }

  static formalizeData(data: AddressModelServer): AddressModel {
    const { number, state, dir, pais, country, district, department } = data;

    return {
      number,
      state: state?.toUpperCase(),
      adr: dir,
      country: pais || country,
      district: district?.toUpperCase(),
      department
    };
  }

  static createVoid(): Address {
    return new Address({
      number: '',
      state: '',
      adr: '',
      country: '',
      district: '',
      department: ''
    });
  }
}

export default Address;
