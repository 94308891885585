import { useContext, useState } from 'react';

import { EnumTypeDownloadFile, NameIcons, SizeScreen, StyleButton, TypeAlertMessage, TypeToast } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import { RootContext } from 'context/RootContext';

import AlertMessage from 'components/molecules/messages/Alert';
import BoardOrders from 'components/molecules/board/BoardOrders';
import ButtonCustom from 'components/atoms/buttons';
import ButtonDownloadFile from 'components/atoms/buttons/ButtonDownloadFile';
//import UploadedFileItem from 'components/molecules/items/UploadedFile';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import Order from 'models/order';

import './UploadFile.css';
import IconCustom from 'components/atoms/icons';

import { convertXlsAtCsv } from 'helpers/Converts';
import StoreSelectorModal from 'components/molecules/modals/StoreSelector';

type UploadFileProps = {
  onUploadFile(file: File): Promise<ResponseApp>;
};

const UploadFile = ({ onUploadFile }: UploadFileProps) => {
  const { showToast, screenResolution } = useContext(RootContext);

  const [selectedFile, setSelectedFile] = useState<File>();

  const [errorUpload, setErrorUpload] = useState<string>();
  const [orderDuplicates, setOrderDuplicates] = useState<string[]>([]);
  const [failedOrders, setFailedOrders] = useState<Order[]>([]);
  const [exceededOrderLimit, setExceededOrderLimit] = useState(false);

  const isMoreLimitOrder = async (file: File) => {
    const resultConvert = await convertXlsAtCsv(file);
    //console.log('isMoreLimitOrder() => resultConvert', resultConvert);

    if (resultConvert) {
      let numberOrder = 0;
      resultConvert.forEach(rConvert => {
        numberOrder += rConvert.numberLine;
      });

      if (numberOrder - 1 >= 50) {
        setExceededOrderLimit(true);
      } else {
        setExceededOrderLimit(false);
      }
    }
  };

  const restartValue = () => {
    setSelectedFile(undefined);
    setFailedOrders([]);
    setErrorUpload(undefined);
    setOrderDuplicates([]);
  };

  const changeHandler = (event: any) => {
    if (event.target.files.length === 1) {
      const file: File = event.target.files[0];
      //console.log('file', file);
      if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setSelectedFile(file);
        setFailedOrders([]);
        setErrorUpload(undefined);
        setOrderDuplicates([]);
        isMoreLimitOrder(file);
      } else {
        showToast({ text: 'El formato del archivo no es el correcto, descarga y utiliza la plantilla de trabajo', type: TypeToast.error });
        restartValue();
      }
    } else {
      showToast({ text: 'Sólo puede subir un archivo', type: TypeToast.error });
      restartValue();
    }
  };

  const handleUploadFile = async () => {
    if (selectedFile) {
      const { status } = await onUploadFile(selectedFile);
      //console.log('handleUploadFile() ===> ', { status, message, data });
      if (!status) {
        setSelectedFile(undefined);
      } else {
        //setFailedOrders([]);
        restartValue();
      }
    } else {
      showToast({ text: 'Por favor seleccione un archivo.', type: TypeToast.error });
    }
  };

  const onDragStart = (event: any) => {
    event.dataTransfer.setData('file', event.target.id);
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
  };

  const onDrop = (event: any) => {
    event.preventDefault();
    if (event.dataTransfer.files.length === 1) {
      const file: File = event.dataTransfer.files[0];
      //console.log('file', file);
      if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setSelectedFile(file);
        setFailedOrders([]);
        setErrorUpload(undefined);
        isMoreLimitOrder(file);
      } else {
        showToast({ text: 'El formato del archivo no es el correcto, descarga y utiliza la plantilla de trabajo', type: TypeToast.error });
        restartValue();
      }
    } else {
      showToast({ text: 'Sólo puede subir un archivo', type: TypeToast.error });
      restartValue();
    }
  };

  const openBrowserFile = () => {
    const hidden = document.getElementById('hidden-input');
    hidden?.click();
  };

  return (
    <MenuSessionContainer classNameContainer='sm:mx-10'>
      <div className='flex flex-1 flex-col mb-4 justify-start items-start'>
        <div className={'mb-4 flex flex-1 flex-row sm:mb-0'}>
          <div>
            <h1 className='flex pt-2 text-xl mb-2 sm:text-3xl'>Carga tu archivo</h1>
            <h2 className='text-sm sm:text-lg'>Selecciona el archivo de órdenes de envío.</h2>
          </div>
          <div className='pt-2 ml-6'>
            <StoreSelectorModal />
          </div>
        </div>

        <div className='flex flex-1 justify-between items-center w-full flex-row sm:justify-end sm:items-center'>
          <ButtonDownloadFile className='mr-2' type={EnumTypeDownloadFile.handbook} />
          <ButtonDownloadFile type={EnumTypeDownloadFile.template} className='' />
        </div>
      </div>

      {errorUpload && (
        <AlertMessage
          className='mt-4'
          featuredText={errorUpload}
          text={
            orderDuplicates && orderDuplicates.length > 0 ? (
              <div className='mt-2'>
                {orderDuplicates.length > 0 && orderDuplicates.map(orderDuplicate => <p key={orderDuplicate}>- {orderDuplicate}</p>)}
              </div>
            ) : undefined
          }
          type={TypeAlertMessage.danger}
        />
      )}

      {failedOrders && failedOrders.length > 0 && (
        <div className='mt-4'>
          <AlertMessage
            text='Ninguna de las órdenes anteriores se ha publicado. Por favor corrige tu archivo y súbelo de nuevo'
            type={TypeAlertMessage.danger}
          />
          <BoardOrders title='Órdenes Fallidas' orders={failedOrders} />
        </div>
      )}

      <section className='w-full flex flex-col items-center'>
        {!selectedFile && (
          <div className={'p-4 bg-white rounded-md mb-8 w-full'}>
            <header
              className='border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center relative px-6'
              onDragOver={event => onDragOver(event)}
              onDrop={event => onDrop(event)}
              onDragStart={event => onDragStart(event)}
            >
              {screenResolution > SizeScreen.sm && (
                <span className='text-center mb-4'>Arrastra y suelta archivo en cualquier lugar del cuadro</span>
              )}

              <ButtonCustom
                className='mb-3 w-full sm:w-auto'
                styleButton={StyleButton.outlinePrimary}
                size='large'
                onClick={openBrowserFile}
              >
                Subir archivo
              </ButtonCustom>

              <span className='text-center'>Formatos aceptados xls, xlsx</span>

              <input id='hidden-input' type='file' onChange={changeHandler} className='hidden' accept='.xls,.xlsx' multiple={false} />
            </header>
          </div>
        )}

        {/**
        <ul id='gallery' className='flex flex-1 flex-wrap -m-1'>
          {selectedFile && <UploadedFileItem file={selectedFile} onDeleted={() => restartValue()} />}
        </ul>
        **/}
        {selectedFile && (
          <div className='p-4 bg-white rounded-md' style={{ maxWidth: 1220 }}>
            <div className='flex w-full items-center mt-24 mb-20 flex-col sm:flex-row'>
              <div className='flex justify-center items-center flex-col sm:ml-20 sm:mr-10 mb-10 sm:mb-0'>
                <IconCustom name={NameIcons.viewList} className={'text-6xl text-primary sm:text-7xl'} />
                <strong className='text-xl sm:text-2xl text-primary'>{selectedFile.name}</strong>
              </div>

              <div className={'flex justify-center items-center flex-1'}>
                <div className={'flex justify-center items-center flex-1'} style={{ maxWidth: 710 }}>
                  <AlertMessage
                    type={TypeAlertMessage.warning}
                    text={
                      <div>
                        <strong className='text-base sm:text-xl'>Revisa la información antes de cargar</strong>
                        {exceededOrderLimit && (
                          <p className='text-black text-base'>
                            Recuerda que archivos con sobre 50 pedidos el sistema tomará más del tiempo normal en procesarlos.
                          </p>
                        )}
                        {!exceededOrderLimit && (
                          <p className='text-black text-sm sm:text-base'>
                            Si necesitas cambiar datos o borrar una orden subida, solo lo podrás hacer mediante personal de Arauco Hub.
                          </p>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col justify-center items-center mt-4 sm:justify-between sm:flex-row'>
              <ButtonCustom styleButton={StyleButton.outlinePrimary} className={'w-full sm:w-auto'} onClick={() => restartValue()}>
                Cancelar
              </ButtonCustom>

              <ButtonCustom className={'w-full mt-4 sm:w-auto sm:mt-0'} onClick={() => handleUploadFile()}>
                Cargar archivo
              </ButtonCustom>
            </div>
          </div>
        )}
      </section>
    </MenuSessionContainer>
  );
};

export default UploadFile;
