interface SaleChannelModel {
  referenceId?: string;
  currency?: string;
  name?: string;
  id?: string;
}

interface SaleChannelServerModel {
  referenceId?: string;
  currency?: string;
  name?: string;
  id?: string;
}

class SaleChannel {
  data: SaleChannelModel;

  constructor(data: SaleChannelModel) {
    this.data = data;
  }

  static formalizeData(data: SaleChannelServerModel): SaleChannelModel {
    const { referenceId, currency, name, id } = data;

    return {
      referenceId,
      currency,
      name,
      id
    };
  }
}

SaleChannel.prototype.toString = function saleChannelToString() {
  return `${this.data.id}-${this.data.referenceId}`;
};

export default SaleChannel;
