import React, { forwardRef, useContext, useEffect } from 'react';
import { Dropdown } from 'antd';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';
import { RouterInContext } from 'context/RouterIn';

import { IS_MOBILE } from 'configs/Constants';
import { SizeScreen, StyleButton } from 'configs/Enums';

import CountrySelectorModal from 'components/molecules/modals/CountrySelector';
import NotificationDD from 'components/molecules/dropDown/Notification';
import ButtonCustom from 'components/atoms/buttons';

type MenuSessionContainerProp = {
  children?: React.ReactNode;
  notStyleContainer?: boolean;
  classNameContainer?: string;
  className?: string;
  isPage?: boolean;
};

const MenuSessionContainer = forwardRef(
  ({ children, notStyleContainer = false, classNameContainer, className, isPage = true }: MenuSessionContainerProp, ref: any) => {
    const { authStatus, signOut } = useContext(AuthContext);
    const { screenResolution } = useContext(RootContext);
    const { updateHContainer } = useContext(RouterInContext);

    const loadHeightContainer = () => {
      const headerNav = document.getElementById('header-nav');
      const hC = window.innerHeight - (headerNav?.clientHeight || 48);
      console.log('loadHeightContainer() =>', {
        wH: window.innerHeight,
        hNH: headerNav?.clientHeight,
        hcont: hC
      });
      updateHContainer(hC);
    };

    useEffect(() => {
      loadHeightContainer();
    }, []);

    return (
      <div className={`flex flex-col ${className} h-full`} ref={ref}>
        {authStatus.user?.data.fullName && screenResolution > SizeScreen.md && isPage && (
          <div
            id='header-nav'
            className={`bg-white w-full h-10 flex items-center px-4 py-2 ${IS_MOBILE ? 'justify-start' : 'justify-end'}`}
          >
            <div className='flex flex-row'>
              <NotificationDD />
              <CountrySelectorModal />
              <div className='flex flex-row justify-center items-center'>
                <Dropdown
                  overlay={
                    <div className='bg-white rounded-lg p-4 shadow-lg'>
                      <div className='flex flex-col border-b border-gray-200 mb-3 flex justify-center items-center pb-3'>
                        <div className='font-semibold text-white bg-tertiary w-7 h-7 rounded-full flex justify-center items-center cursor-pointer'>
                          <span>{`${authStatus.user?.getFirstLetterName()}`}</span>
                        </div>
                        <h1 className='text-lg'>{authStatus.user?.data.fullName}</h1>
                      </div>
                      <div className='flex justify-center items-center p-4'>
                        <ButtonCustom
                          onClick={() => {
                            signOut();
                          }}
                          styleButton={StyleButton.outlinePrimary}
                        >
                          Cerrar Sesión
                        </ButtonCustom>
                      </div>
                    </div>
                  }
                >
                  <div className='font-semibold text-white bg-tertiary w-7 h-7 rounded-full flex justify-center items-center cursor-pointer'>
                    <span>{`${authStatus.user?.getFirstLetterName()}`}</span>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        )}
        <div className={`${notStyleContainer ? '' : 'mx-4 py-4'} ${classNameContainer}`}>{children}</div>
      </div>
    );
  }
);

export default MenuSessionContainer;
