import { AxiosResponse } from 'axios';

import { ResponseApp } from 'configs/Interface';

import { handleErrorServiceApi } from 'helpers/Errors';

import api from '.';

class DistrictProvider {
  async getCitiesByTypeDelivery({
    typeDelivery,
    country
  }: {
    country: string;
    typeDelivery: string;
  }): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`district?country=${country}&delivery=${typeDelivery}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getCities({ country }: { country: string; typeDelivery: string }) {
    try {
      return await api().get(`district?country=${country}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async getDistrictsByCity({ country, city, typeDelivery }: { country: string; city: string; typeDelivery: string }) {
    try {
      return await api().get(`district?country=${country}&city=${city}&delivery=${typeDelivery}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new DistrictProvider();
