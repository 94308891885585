import { useState, useEffect, useContext } from 'react';
import { Tabs } from 'antd';

import { NameIcons, SizeScreen } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';
import { RouterInContext } from 'context/RouterIn';

import IconCustom from 'components/atoms/icons';
import ButtonCustom from 'components/atoms/buttons';
import FormMolecule from 'components/molecules/forms';
import BoardCustom from 'components/molecules/board';
import ModalCustom from 'components/molecules/modals';
import CutOffScheduleBoard from 'components/organisms/dashboard/settings/CutOffScheduleBoard';

import { FormItemModel } from 'models/form';
import Store, { StoreModel } from 'models/store';
import Country from 'models/Country';
import StoreJanis from 'models/store/StoreJanis';
import SaleChannel from 'models/store/SaleChannel';
import Delivery from 'models/Delivery';
import DeliverySchedule from 'models/delivery/DeliverySchedule';
import StoreBoardFilter, { StoreBoardFilterParams } from '../filters/StoreBoard';
import AlertMessage from '../messages/Alert';

const { TabPane } = Tabs;

type BoardStoreProps = {
  stores: Store[];
  countries: Country[];
  storesJanis: StoreJanis[];
  salesChannels: SaleChannel[];
  typesDelivery: Delivery[];
  values?: StoreModel;
  onUpdateStore: (form: any) => void;
  onCreateStore: (form: any) => void;
  onDeletedStore: (form: any) => void;
  onCancelStore: () => void;
  onChangeForm: (form: any) => void;
  onFilter?: (params: StoreBoardFilterParams) => void;
  onSort?: (params: { isSort?: boolean; field?: string; data?: any[] }) => void;
  flag?: number;
  storesAll?: Store[];
  onUpdateSchedules?: (schedules: DeliverySchedule[]) => void;
  schedules?: DeliverySchedule[];
};

enum TabStoreSeccion {
  dataBrand = 'dataBrand',
  schedules = 'schedules'
}

const BoardStore = ({
  stores,
  countries,
  storesJanis,
  salesChannels,
  typesDelivery,
  values,
  onUpdateStore,
  onCreateStore,
  onDeletedStore,
  onCancelStore,
  onChangeForm,
  onFilter,
  flag,
  onUpdateSchedules,
  schedules
}: BoardStoreProps) => {
  const { showModalQuestion, screenResolution } = useContext(RootContext);
  const { typesOperator } = useContext(AuthContext);
  const { warehouse } = useContext(DataContext);
  const { hContainer } = useContext(RouterInContext);

  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [storeCurrent, setStoreCurrent] = useState<Store>();
  const [itemsForm, setItemsForm] = useState<FormItemModel[]>([]);
  const [storesJanisTemp, setStoresJanisTemp] = useState<StoreJanis[]>();
  //const [sortsList, setSortsList] = useState<string[]>([]);
  const [data, setData] = useState<Store[]>([]);
  const [sectionTabActive, setSectionTabActive] = useState<TabStoreSeccion | string>(TabStoreSeccion.dataBrand);

  const showModalUpdate = (store: Store) => {
    //console.log('showModalUpdate() => store', { store, storesJanis });
    /** obligado a crear clases nuevas ya que no se guarda el dato si no que se actualiza el original **/
    const storesJanisTempAux = storesJanis.map(storeJanis => new StoreJanis({ ...storeJanis.data }));
    store.onUpdateWarehouse({ warehouses: warehouse });
    if (store.data.storeJanisId && storesJanisTempAux) {
      let storeFind: StoreJanis | undefined;
      storesJanisTempAux.forEach(stor => {
        if (stor.data.id === store.data.storeJanisId) {
          stor.updateAvailable(true);
          storeFind = stor;
        }
      });
      console.log('showModalUpdate() 1 =>', { storeFind, storesJanisTempAux, storesJanisTemp, storesJanis, store });
      //const storeFind = storesJanis.find(stor => stor.data.id === store.data.storeJanisId);
      if (storeFind) {
        console.log('showModalUpdate() =>', storeFind);
        store.updateForm({ ...store.data, storeJanis: storeFind });
      }
    }
    //const storeUpdate = new Store({ ...store.data });
    console.log('showModalUpdate() => storeUpdate', { store });
    onChangeForm({ ...store.data });
    store.sendServer();
    setStoreCurrent(new Store({ ...store.data }));
    setStoresJanisTemp(storesJanisTempAux);
    //console.log('delivery', deliveryScheduleCurrent);
    setItemsForm(
      Store.formFields({
        values: { ...store.data },
        countries,
        storesJanis: storesJanisTempAux,
        salesChannels,
        warehouses: warehouse,
        //typesDelivery,
        typesOperator
      })
    );
    setIsModalUpdate(true);
  };

  const showModalCreate = () => {
    const newStore = new Store({});
    setStoreCurrent(newStore);
    //console.log('showModalCreate() =>', newStore);
    setItemsForm(
      Store.formFields({
        values: newStore.data,
        countries,
        storesJanis,
        salesChannels,
        warehouses: warehouse,
        //typesDelivery,
        typesOperator
      })
    );
    setIsModalAdd(true);
  };

  const showModalDelete = (store: Store) => {
    setStoreCurrent(store);
    showModalQuestion({
      title: '¿Desea eliminar la tienda?',
      options: [{ text: 'Si', onAction: () => onDeletedStore(store) }, { text: 'No' }]
    });
  };

  useEffect(() => {
    if (values && storeCurrent) {
      //console.log('useEffect() =>', { storeCurrent, values });
      storeCurrent.updateForm({ ...values });
      setStoreCurrent(new Store({ ...storeCurrent.data }));
      setItemsForm(
        Store.formFields({
          countries,
          storesJanis: storesJanisTemp || storesJanis,
          salesChannels,
          warehouses: warehouse,
          //typesDelivery,
          values,
          typesOperator
        })
      );
    }
    console.log('useEffect() =>', stores);
    setData(stores);
  }, [values, countries, storesJanis, salesChannels, typesDelivery, stores, flag, typesOperator]);

  return (
    <div className='relative' style={{ height: hContainer }}>
      <div className='flex flex-col h-full overflow-auto'>
        <div className='flex flex-col px-5 pt-8 pb-5 bg-white overflow-clip sm:px-10'>
          <div className='flex flex-row'>
            <h1 className='overflow-clip'>Marcas</h1>

            <ButtonCustom
              className='ml-3'
              disabled={storesJanis && storesJanis.length > 0 ? !storesJanis.some(storJ => storJ.data.available) : true}
              onClick={() => showModalCreate()}
            >
              <IconCustom name={NameIcons.add} />
            </ButtonCustom>
          </div>

          <StoreBoardFilter onChange={vals => (onFilter ? onFilter(vals) : undefined)} />
        </div>

        {screenResolution > SizeScreen.md && (
          <div className='mx-10 mt-3'>
            <BoardCustom
              data={data}
              columns={Store.headerTable({
                showModalUpdate,
                onDeleted: showModalDelete
              })}
              persistTableHead
            />
          </div>
        )}
        {screenResolution <= SizeScreen.md && (
          <div className='w-full my-4'>
            {data &&
              data.length > 0 &&
              data.map(dat => {
                return (
                  <div
                    key={dat.data.commerceId}
                    className={`bg-white px-4 py-4 mx-5 ${dat.data.isActive ? 'border-araucogreen' : 'border-grayarauco-300'}`}
                    style={{ borderLeftWidth: 5, marginBottom: 1 }}
                  >
                    <div className='pb-4 mb-4 divide-grayarauco-200' style={{ borderBottomWidth: 0.5 }}>
                      <div className='flex flex-row justify-between'>
                        <p className='text-sm font-bold'>{dat.data.brand}</p>
                        <p className='text-base font-normal'>{dat.data.currency}</p>
                      </div>
                      <p className='text-sm font-normal'>Tipo de operador: {dat.data.typeOperator}</p>
                      <p className='text-sm font-normal'>Ciudad: {dat.data.city}</p>
                    </div>

                    <div className='pb-4 mb-4' style={{ borderBottomWidth: 0.5 }}>
                      <p className='text-sm font-normal'>Sales channel: {dat.data.saleChannel?.data.referenceId}</p>
                      <p className='text-sm font-normal'>RUT/ID: {dat.data.rutOrId}</p>
                      <p className='text-sm font-normal'>SUC/Número de contrato: {dat.data.sucOrContractNum}</p>
                    </div>

                    <div>
                      <p className='text-sm font-normal'>{dat.data.typesDelivery?.map(tD => tD.data.name).join(',')}</p>
                    </div>

                    <div className='flex flex-row justify-end w-full'>
                      <IconCustom name={NameIcons.rename} onClick={() => showModalUpdate(dat)} className='text-xl text-grayarauco-400' />
                    </div>
                    {/****/}
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {/** modal para actulizar tienda **/}
      {storeCurrent && isModalUpdate && (
        <ModalCustom
          notFullScreen
          isVisible={isModalUpdate}
          onClose={() => {
            setIsModalUpdate(false);
            setSectionTabActive(TabStoreSeccion.dataBrand);
            setStoreCurrent(undefined);
            setStoresJanisTemp(undefined);
            setItemsForm([]);
            onCancelStore();
          }}
        >
          <div className='flex flex-col items-start justify-start h-full bg-white'>
            <button
              className='ml-5 text-xs font-normal text-black sm:ml-10'
              onClick={() => {
                setIsModalAdd(false);
                setStoreCurrent(undefined);
                setStoresJanisTemp(undefined);
                setSectionTabActive(TabStoreSeccion.dataBrand);
                setItemsForm([]);
                onCancelStore();
              }}
            >{`< Volver`}</button>
            <div className='flex flex-col items-start mt-4 sm:items-center sm:flex-row'>
              <h1 className='ml-5 text-3xl sm:ml-10'>Actualizar marca</h1>
              <AlertMessage
                className={'mx-5 mt-5 sm:mx-3 sm:mt-0'}
                outlined={false}
                text={
                  <div>
                    <p className='text-base text-black'>
                      <strong>Debes crear primero la marca en Janis</strong>, los datos de sales channel y país son extraídos desde Janis.{' '}
                    </p>
                  </div>
                }
              />
            </div>

            <div className='w-full'>
              <Tabs
                className={'tab-store'}
                activeKey={sectionTabActive}
                onChange={tab => {
                  setSectionTabActive(tab);
                  console.log('onChange() => tab', tab);
                }}
              >
                <TabPane key={TabStoreSeccion.dataBrand} tab='Datos marca' />
                <TabPane key={TabStoreSeccion.schedules} tab='Horario' />
              </Tabs>
            </div>

            {sectionTabActive === TabStoreSeccion.dataBrand && (
              <FormMolecule
                notStyleWidth
                classNameContainer='flex-1 px-5 sm:px-10 py-5 w-full self-center shadow-none'
                classNameContent='md:grid-cols-3 sm:grid-cols-2'
                //title='Actualizar tienda'
                items={itemsForm}
                textBtnSubmit='Actualizar'
                textBtnCancel='Cancelar'
                onChangeForm={form => {
                  console.log('onChangeForm() ==>', form);
                  onChangeForm(form);
                }}
                onCancel={() => {
                  setIsModalUpdate(false);
                  setStoreCurrent(undefined);
                  setItemsForm([]);
                  setStoresJanisTemp(undefined);
                  setSectionTabActive(TabStoreSeccion.dataBrand);
                  onCancelStore();
                }}
                onSubmit={() => {
                  storeCurrent.updateForm({ ...storeCurrent.data, saleChannel: salesChannels[0] });
                  onUpdateStore(storeCurrent);
                  setIsModalUpdate(false);
                  setStoreCurrent(undefined);
                  setItemsForm([]);
                  setStoresJanisTemp(undefined);
                }}
              />
            )}
            {sectionTabActive === TabStoreSeccion.schedules && (
              <CutOffScheduleBoard classNameContainer='my-4 px-2 sm:px-10 w-full' commerce={storeCurrent.data?.commerceId} schedules={[]} />
            )}
          </div>
        </ModalCustom>
      )}

      {/** modal para crear tienda **/}
      {storeCurrent && isModalAdd && (
        <ModalCustom
          notFullScreen
          isVisible={isModalAdd}
          onClose={() => {
            setIsModalAdd(false);
            setSectionTabActive(TabStoreSeccion.dataBrand);
            setStoreCurrent(undefined);
            setStoresJanisTemp(undefined);
            setItemsForm([]);
            onCancelStore();
          }}
        >
          <div className='flex flex-col items-start justify-start h-full bg-white'>
            <button
              className='ml-10 text-xs font-normal text-black'
              onClick={() => {
                setIsModalAdd(false);
                setStoreCurrent(undefined);
                setStoresJanisTemp(undefined);
                setSectionTabActive(TabStoreSeccion.dataBrand);
                setItemsForm([]);
                onCancelStore();
              }}
            >{`< Volver`}</button>

            <div className='flex flex-col items-start mt-4 sm:items-center sm:flex-row'>
              <h1 className='ml-5 text-3xl sm:ml-10'>Agregar marca</h1>
              <AlertMessage
                className={'mx-5 mt-5 sm:mx-3 sm:mt-0'}
                outlined={false}
                text={
                  <div>
                    <p className='text-base text-black'>
                      <strong>Debes crear primero la marca en Janis</strong>, los datos de sales channel y país son extraídos desde Janis.{' '}
                    </p>
                  </div>
                }
              />
            </div>

            <div className='w-full'>
              <Tabs
                className={'tab-store'}
                activeKey={sectionTabActive}
                onChange={tab => {
                  setSectionTabActive(tab);
                  console.log('onChange() => tab', tab);
                }}
              >
                <TabPane key={TabStoreSeccion.dataBrand} tab='Datos marca' />
                <TabPane key={TabStoreSeccion.schedules} tab='Horario' />
              </Tabs>
            </div>

            {sectionTabActive === TabStoreSeccion.dataBrand && (
              <FormMolecule
                notStyleWidth
                classNameContainer='flex-1 px-5 sm:px-10 py-5 w-full self-center shadow-none'
                classNameContent='md:grid-cols-3 sm:grid-cols-2'
                //title='Agregar tienda'
                items={itemsForm}
                textBtnSubmit='Crear'
                textBtnCancel='Cancelar'
                onChangeForm={form => {
                  //console.log('onChangeForm() ==>', form);
                  onChangeForm(form);
                }}
                onSubmit={() => {
                  onCreateStore(storeCurrent);
                  setIsModalAdd(false);
                  setStoreCurrent(undefined);
                  setStoresJanisTemp(undefined);
                  setItemsForm([]);
                }}
                onCancel={() => {
                  setIsModalAdd(false);
                  setStoreCurrent(undefined);
                  setStoresJanisTemp(undefined);
                  setItemsForm([]);
                  onCancelStore();
                }}
              />
            )}
            {sectionTabActive === TabStoreSeccion.schedules && (
              <CutOffScheduleBoard
                classNameContainer='my-4 px-5 sm:px-10 w-full'
                commerce={storeCurrent.data?.commerceId}
                onUpdateSchedules={onUpdateSchedules}
                schedules={schedules || []}
              />
            )}
          </div>
        </ModalCustom>
      )}
    </div>
  );
};

export default BoardStore;
