import { useState } from 'react';
import { Link } from 'react-router-dom';

import { PathRoute, TypeAlertMessage } from 'configs/Enums';

import InputCustom from 'components/atoms/inputs';
import ButtonCustom from 'components/atoms/buttons';
import AlertMessage from 'components/molecules/messages/Alert';
import LayoutContainerAuth from 'components/molecules/layout/ContainerAuth';

import { validateEmail } from 'helpers/Validate';

type RecoverPasswordProps = {
  onRecoverPassword: (email: string) => void;
};

const RecoverPassword = ({ onRecoverPassword }: RecoverPasswordProps) => {
  const [email, setEmail] = useState();
  const [messageError, setMessageError] = useState<string>();

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleRecoverPassword = (event: any) => {
    if (email && email !== '') {
      if (validateEmail(email)) {
        onRecoverPassword(email);
      } else {
        setMessageError('El email no cumple con el formato de example@mail.com.');
      }
    } else {
      setMessageError('El campo es obligatorio.');
    }
    event.preventDefault();
  };

  return (
    <LayoutContainerAuth onSubmit={handleRecoverPassword} classNameForm={'flex flex-col'} classNameTop={'h-screen overflow-auto'}>
      <div className='mb-4'>
        <span className='text-base'>Ingresa tu dirección de correo electrónico de recuperación</span>
        <InputCustom classNameContainer='mt-2' placeholder='usuario@micorreo.cl' onChange={handleChangeEmail} />
      </div>

      {messageError && <AlertMessage text={messageError} type={TypeAlertMessage.danger} />}

      <div className='flex items-center justify-between w-full'>
        <Link className='inline-block align-baseline font-normal text-sm text-blue-500 hover:text-blue-800' to={PathRoute.signin}>
          Volver
        </Link>
        <ButtonCustom className='font-bold' htmlType='submit'>
          Recuperar contraseña
        </ButtonCustom>
      </div>
    </LayoutContainerAuth>
  );
};

export default RecoverPassword;
