import { ReactNode } from 'react';
import { Switch } from 'antd';

import LabelCustom from 'components/atoms/labels';

type InputBooleanProps = {
  value?: boolean;
  label?: ReactNode;
  isRequired?: boolean;
  classNameLabel?: string;
  onChange?: (checked: boolean, event: MouseEvent) => void;
  hide?: boolean;
  classNameContainer?: string;
};

const InputBoolean = ({ value, label, classNameContainer, isRequired, classNameLabel, onChange, hide }: InputBooleanProps) => {
  return (
    <div className={`flex flex-col ${classNameContainer}`} hidden={hide}>
      {label && (
        <LabelCustom className={`mb-2 ${classNameLabel}`}>
          {label}
          {isRequired && <span className='text-red-400'> *</span>}
        </LabelCustom>
      )}
      <div className='items-center flex h-full'>
        <Switch size='default' checkedChildren='Si' unCheckedChildren='No' checked={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default InputBoolean;
