import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { EnumFileUploadStates, NameIcons, PathRoute, SizeScreen } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';

import FileOrders from 'models/files/FileOrders';
import ButtonCustom from 'components/atoms/buttons';

type CardUploadFileProps = {
  status?: EnumFileUploadStates;
  fileOrders?: FileOrders;
  onClose?: (fileOrders: FileOrders) => void;
  index?: number;
};

const CardUploadFile = ({ index, status, fileOrders }: CardUploadFileProps) => {
  const { screenResolution } = useContext(RootContext);

  const navigate = useNavigate();

  if (fileOrders) {
    return (
      <div
        style={{ maxWidth: '432px', width: screenResolution === SizeScreen.sm ? 'auto' : '432px' }}
        className={`shadow-md overflow-hidden ${index !== 0 ? 'mt-2' : 'mt-0'}`}
      >
        <div className='bg-white p-4 py-5 flex flex-col'>
          <div className={`flex justify-between items-center`}>
            <p className='text-base font-bold text-araucoblue-200'>{fileOrders?.data.nameFile}</p>
            {status === EnumFileUploadStates.inProcess && <div className='spinner' />}
            {status === EnumFileUploadStates.complete && fileOrders?.data.correct !== 0 && (
              <div className='flex flex-row items-center'>
                <p className='text-xl font-normal text-black'>
                  <span className='text-tertiary'>{fileOrders?.data.correct}</span>
                  {` de ${(fileOrders?.data.failed || 0) + (fileOrders?.data.correct || 0)}`}
                </p>

                <IconCustom name={NameIcons.done} className='text-tertiary text-5xl font-bold ml-3' />
              </div>
            )}
          </div>
          {status === EnumFileUploadStates.complete && fileOrders?.data.description}

          {status === EnumFileUploadStates.complete && fileOrders?.data.failed !== 0 && (
            <div className={`border-t border-grayaraucoborder w-full ${status === EnumFileUploadStates.complete ? 'mt-4' : ''}`}>
              <div className='flex flex-row w-full mt-4 justify-between'>
                <div className='flex flex-row items-center'>
                  <IconCustom name={NameIcons.info} className='text-araucoblue-200 text-2xl mr-2' />

                  {fileOrders?.data.correct !== 0 && fileOrders?.data.failed !== 0 && (
                    <div className='text-sm font-normal text-black'>
                      <p className=''>Se han detectado</p>

                      <p className='text-araucoblue-200'>
                        <span className='font-bold'>{fileOrders?.data.failed}</span> órdenes mal ingresadas
                      </p>
                    </div>
                  )}
                  {fileOrders?.data.correct === 0 && (
                    <div className='text-sm font-normal text-black'>
                      <p className=''>
                        {`Se han detectado `} <span className='text-araucoblue-200'>todas las órdenes mal ingresadas</span>
                      </p>
                    </div>
                  )}
                </div>

                <ButtonCustom className='text-sm' onClick={() => navigate(PathRoute.listFailedOrders)}>
                  <p className='text-sm'>ir a corregir órdenes</p>
                </ButtonCustom>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default CardUploadFile;
