import { useEffect, useState, ReactElement, ReactNode } from 'react';
import validate from 'validate.js';

import { StyleButton } from 'configs/Enums';

import TimePickerCustom from 'components/atoms/pickers/TimePicker';
import DatePickerCustom from 'components/atoms/pickers/DatePicker';
import ButtonCustom from 'components/atoms/buttons';
import InputCustom from 'components/atoms/inputs';
import SelectCustom from 'components/atoms/selects';
import MarkdownEditor from 'components/atoms/markdown';

import Form, { FormItemModel } from 'models/form';
import SliderCustom from 'components/atoms/sliders';
import moment from 'moment';
import InputBoolean from 'components/atoms/inputs/InputBoolean';
import SelectionButton from 'components/atoms/selects/SelectionButton';

type FormMoleculeProps = {
  form?: Form;
  title?: string;
  items?: FormItemModel[];
  textBtnSubmit?: string;
  textBtnCancel?: string;
  onChangeForm?: (form: any) => void;
  onSubmit?: (form: any) => void;
  onCancel?: () => void;
  footerComponent?: ReactElement;
  classNameContainer?: string;
  classNameContent?: string;
  body?: ReactNode;
  notStyleWidth?: boolean;
};

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value: any) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: function (value: any, options: any) {
    const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  }
});

const FormMolecule = ({
  items,
  title,
  textBtnSubmit,
  textBtnCancel,
  onSubmit,
  onCancel,
  onChangeForm,
  footerComponent,
  classNameContainer,
  classNameContent,
  body,
  notStyleWidth
}: FormMoleculeProps) => {
  const [values, setValues] = useState<any>();
  const [validations, setValidations] = useState<boolean[]>();
  const [flag, setFlag] = useState(0);
  const [isSubmit, setIsSubmit] = useState<boolean>();

  const isReadyToSend = (valids: boolean[] | undefined) => {
    if (valids && valids.length > 0) {
      //console.log('validations ==>', validations);
      const allValidationsOk = valids.every(valid => valid);
      console.log('allValidationsOk', allValidationsOk);
      //return !allValidationsOk;
      setIsSubmit(allValidationsOk);
    } else {
      setIsSubmit(true);
    }
  };

  const handleChange = (value: any, identifier: string) => {
    try {
      values[identifier] = value;
      console.log('handleChange()', { value, identifier, values, items });
      setValues(values);
      if (onChangeForm) {
        onChangeForm(values);
      }
      setFlag(flag + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleValidate = (vali: boolean, index: number) => {
    if (validations) {
      validations[index] = vali;
      setValidations(validations);
    }
    console.log('handleValidate', { vali, index, validations });
    isReadyToSend(validations);
  };

  const handledSubmint = () => {
    if (values && onSubmit) {
      onSubmit(values);
    }
  };

  const renderItemSelect = (item: FormItemModel) => {
    const { data } = item;

    if (item.dependentValue && item.dependentValue.identifiers) {
      return item.dependentValue.conditionals(values[item.dependentValue.identifiers]);
    }
    return data || [];
  };

  useEffect(() => {
    //console.log('useEffect()1 ==>', items);
    if (items) {
      let valuesInitial = {};
      const validInitial: boolean[] = [];
      //console.log('useEffect()2 ==>', items);
      items.forEach(item => {
        //console.log('useEffect()3 ==>', item);
        valuesInitial = { ...valuesInitial, [item.identifier]: item.value };
        let isRequired = !item.isRequired;
        if (item.value && item.isRequired) {
          if (typeof item.value === 'object' && item.value.length === 0) {
            isRequired = false;
          } else {
            isRequired = true;
          }
        }
        let validation = false;
        if (!item.value && !item.isRequired) {
          validation = true;
          //console.log('src/components/molecules/forms/index.tsx', 'useEffect()', 'item', item);
          item.messageError = '';
          item.tooltipMessage = undefined;
        } else {
          if (item.validateConstraints && item.validateConstraints.hourAndMinutes) {
            validation = item.validateConstraints.hourAndMinutes(item.value);
          } else {
            validation = !validate.single(item.value || '', item.validateConstraints) && isRequired;
          }
          //console.log('validate new', validation);
        }
        if (item.type === 'select' && item.isRequired && item.value) {
          validation = true;
        }
        //console.log('useEffect()4 ==>', { passwordValue: item.passwordValue, value: item.value });
        if (item.passwordValue) {
          if (item.passwordValue !== item.value) {
            validation = false;
          } else {
            validation = validation && true;
          }
        }
        validInitial.push(validation);
      });
      console.log('form useEffect() ==>', { valuesInitial, validInitial, items });
      setValues(valuesInitial);
      setValidations(validInitial);
      isReadyToSend(validInitial);
    }
  }, [items]);

  return (
    <div className={`container p-4 shadow-lg bg-white rounded-md ${classNameContainer} ${notStyleWidth ? '' : 'mx-auto xl:w-2/4'}`}>
      {title && <h1 className='mb-2 md:mb-4'>{title}</h1>}

      <div className={`transition ease-in-out grid gap-4 grid-cols-1 mb-2 md:grid-cols-4 sm:grid-cols-2 ${classNameContent}`}>
        {items &&
          values &&
          items.length > 0 &&
          items.map((item, index) => {
            const {
              label,
              type,
              isRequired,
              data,
              placeholder,
              identifier,
              passwordValue,
              passwordConfirmValue,
              maxValue,
              minValue,
              mask,
              hide,
              componentRender
            } = item;
            if (type === 'slider') {
              return (
                <SliderCustom
                  key={label}
                  value={values[identifier]}
                  //validateConstraints={item.validateConstraints}
                  //onValidate={isVal => handleValidate(isVal || false, index)}
                  onChange={newValue => {
                    handleChange(newValue, identifier);
                  }}
                  min={minValue}
                  max={maxValue}
                  //messageErrorTooltip={item.messageError}
                  {...{ label, isRequired, hide }}
                />
              );
            }
            if (type === 'duration') {
              return (
                <InputCustom
                  key={label}
                  type={type}
                  value={values[identifier]}
                  validateConstraints={item.validateConstraints}
                  onValidate={isVal => handleValidate(isVal || false, index)}
                  autoComplete='new-password'
                  onChange={event => {
                    if (event) {
                      handleChange(event.target.value, identifier);
                    }
                  }}
                  messageErrorTooltip={item.messageError}
                  {...{ label, placeholder, isRequired, mask, hide }}
                />
              );
            }
            if (type === 'time') {
              return (
                <TimePickerCustom
                  key={label}
                  value={values[identifier]}
                  //onValidate={isVal => handleValidate(isVal || false, index)}
                  onChange={value => {
                    if (value) {
                      handleChange(value, identifier);
                    }
                  }}
                  format={item.formatTime}
                  messageErrorTooltip={item.messageError}
                  {...{ label, placeholder, isRequired, hide }}
                />
              );
            }
            if (type === 'date') {
              return (
                <DatePickerCustom
                  key={label}
                  value={values[identifier]}
                  //onValidate={isVal => handleValidate(isVal || false, index)}
                  onChange={value => {
                    if (value) {
                      handleChange(value, identifier);
                    }
                  }}
                  messageErrorTooltip={item.messageError}
                  {...{ label, placeholder, isRequired, hide }}
                />
              );
            }
            if (type === 'text' || type === 'password' || type === 'textArea') {
              //console.log('password input ==>', { passwordValue, passwordConfirmValue });
              return (
                <InputCustom
                  key={label}
                  type={type}
                  //type='text'
                  value={values[identifier]}
                  validateConstraints={item.validateConstraints}
                  onValidate={isVal => handleValidate(isVal || false, index)}
                  autoComplete='new-password'
                  onChange={event => {
                    if (event) {
                      handleChange(event.target.value, identifier);
                    }
                  }}
                  classNameContainer={type === 'textArea' ? 'row-span-2 col-span-2' : ''}
                  passwordValue={passwordValue}
                  passwordConfirmValue={passwordConfirmValue}
                  maxLength={item.maxLength}
                  messageErrorTooltip={item.messageError}
                  {...{ label, placeholder, isRequired, hide }}
                />
              );
            }
            if ((type === 'select' || type === 'select-add') && data) {
              return (
                <SelectCustom
                  key={label}
                  style={{ width: '100%' }}
                  value={values[identifier]}
                  onChange={(valueNew: any) => {
                    //console.log('onChange() ==>', { valueNew });
                    handleChange(valueNew, identifier);
                  }}
                  tooltipMessage={item.tooltipMessage}
                  onValidate={isVal => handleValidate(isVal || false, index)}
                  label={typeof label === 'string' ? label : undefined}
                  data={renderItemSelect(item)}
                  flag={flag}
                  mode={undefined}
                  messageErrorTooltip={item.messageError}
                  disabled={item.disabled}
                  type={type === 'select-add' ? 'add' : 'default'}
                  {...{ isRequired, hide }}
                />
              );
            }
            if (type === 'selectMultiple' && data) {
              return (
                <SelectionButton
                  key={label}
                  className='w-full justify-between'
                  classNameContainer='w-full self-end'
                  text={label}
                  items={renderItemSelect(item) as any}
                  getPopupContainer={(triggerNode: any) => {
                    console.log('getPopupContainer() => triggerNode', triggerNode);
                    return triggerNode;
                  }}
                  onClose={(valueNew: any) => {
                    //console.log('onChange() ==>', { valueNew });
                    handleChange(valueNew, identifier);
                  }}
                  values={values[identifier]}
                  onChange={(valueNew: any) => {
                    //console.log('onChange() ==>', { valueNew });
                    handleChange(valueNew, identifier);
                  }}
                />
              );
            }
            if (type === 'number') {
              return (
                <InputCustom
                  key={label}
                  type='number'
                  value={values[identifier]}
                  validateConstraints={item.validateConstraints}
                  onValidate={isVal => handleValidate(isVal || false, index)}
                  autoComplete='new-password'
                  onChange={event => {
                    if (event) {
                      //values[identifier] = event.target.value;
                      //setValues(values);
                      handleChange(event.target.value, identifier);
                    }
                  }}
                  maxLength={item.maxLength}
                  messageErrorTooltip={item.messageError}
                  {...{ label, placeholder, isRequired, hide }}
                />
              );
            }
            if (type === 'boolean') {
              return (
                <InputBoolean
                  key={label}
                  value={values[identifier]}
                  onChange={checked => {
                    handleChange(checked, identifier);
                  }}
                  {...{ label, isRequired, hide }}
                />
              );
            }
            if (type === 'markdown') {
              return (
                <MarkdownEditor
                  key={label}
                  value={values[identifier]}
                  classNameContainer='row-span-4 col-span-4'
                  onValidate={isVal => handleValidate(isVal || false, index)}
                  onChange={newValue => {
                    handleChange(newValue, identifier);
                  }}
                  {...{ label, isRequired, hide }}
                />
              );
            }
            if (type === 'custom' && componentRender) {
              return componentRender({
                hide,
                isRequired,
                onChange: val => handleChange(val, identifier),
                value: values[identifier],
                onValidate: isVal => handleValidate(isVal || false, index)
              });
            }
            //console.log('llego al nulo');
            return null;
          })}
      </div>
      {body}

      {footerComponent && footerComponent}

      {!footerComponent && (
        <div className='flex flex-col sm:flex-row justify-between items-center gap-4'>
          {onCancel && (
            <ButtonCustom className='w-4/5 sm:w-auto' styleButton={StyleButton.outlinePrimary} type='default' onClick={() => onCancel()}>
              {textBtnCancel || 'Cancelar'}
            </ButtonCustom>
          )}
          {onSubmit && (
            <ButtonCustom className='w-4/5 sm:w-auto' disabled={!isSubmit} onClick={() => handledSubmint()}>
              {textBtnSubmit || 'Cargar'}
            </ButtonCustom>
          )}
        </div>
      )}
    </div>
  );
};

export default FormMolecule;
