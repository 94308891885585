import { useContext, useEffect, useState } from 'react';

import { DataContext } from 'context/Data';

import FileOrders from 'models/files/FileOrders';

import CardFileGroup from './CardFileGroup';

//type ProcessUploadFileProps = {
//filesOrders: FileOrders[];
//onClose?: (fileOrders: FileOrders) => void;
//};

interface FileCurrentGrup {
  upload: FileOrders[];
  download: FileOrders[];
  error: FileOrders[];
}

const ProcessUploadFile = () => {
  const { deletedsUploadFile, filesOrders, filesOrdersFlag } = useContext(DataContext);
  const [filesCurrent, setFilesCurrent] = useState<FileCurrentGrup>();

  const onClose = (files: FileOrders[]) => {
    deletedsUploadFile(files);
  };

  //console.log('ProcessUploadFile => filesOrders', { filesOrders });

  useEffect(() => {
    console.log('useEffect() => filesOrders', { filesOrders, filesCurrent });
    setFilesCurrent(FileOrders.reducerType(filesOrders));
  }, [filesOrders, filesOrdersFlag]);

  return (
    <div className='absolute bg-transparent top-0 bottom-0 left-0 right-0 flex justify-end items-end pointer-events-none'>
      <div className='grid m-4 pointer-events-auto grid-cols-1 gap-4'>
        {filesCurrent?.upload && filesCurrent.upload.length > 0 && (
          <CardFileGroup files={filesCurrent.upload} type={'upload'} {...{ onClose, filesOrdersFlag }} />
        )}
        {filesCurrent?.download && filesCurrent.download.length > 0 && (
          <CardFileGroup files={filesCurrent.download} type={'download'} {...{ onClose, filesOrdersFlag }} />
        )}
        {filesCurrent?.error && filesCurrent.error.length > 0 && (
          <CardFileGroup files={filesCurrent.error} type={'error'} {...{ onClose, filesOrdersFlag }} />
        )}
      </div>
    </div>
  );
};

export default ProcessUploadFile;
