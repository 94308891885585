import { useContext, useState } from 'react';

import { AuthContext } from 'context/AuthContext';

import BrandSelect from './Brand';
import TypeOperatorSelect from './TypeOperator';

import Store from 'models/store';
import TypeOperator from 'models/store/TypeOperator';

type BrandAndTypeOperatorSelectProps = {
  onChangeBrands?: (brands?: Store[]) => void;
  onChangeTypesOperator?: (typesOperator?: TypeOperator[]) => void;
  inverse?: boolean;
  isDummy?: boolean;
};

const BrandAndTypeOperatorSelect = ({ onChangeBrands, onChangeTypesOperator, inverse, isDummy }: BrandAndTypeOperatorSelectProps) => {
  const { stores } = useContext(AuthContext);

  //const [typeOperatorCurrent, setTypeOperatorCurrent] = useState<TypeOperator[]>();
  const [brandsCurrent, setBrandsCurrent] = useState<Store[]>();
  const [valueBrands, setValueBrands] = useState<Store[]>();
  const [valueTypeOperator, setValueTypeOperator] = useState<TypeOperator[]>();

  return (
    <div className={`flex gap-3 items-end w-full sm:w-auto ${inverse ? 'flex-col-reverse sm:flex-row-reverse' : 'flex-col sm:flex-row'}`}>
      <TypeOperatorSelect
        onChange={val => {
          if (onChangeTypesOperator) {
            onChangeTypesOperator(val);
          }
          const storesFilter = stores.filter(sto =>
            val?.find(valC => valC.data.value?.toLowerCase() === sto.data.typeOperator?.toLowerCase())
          );
          setBrandsCurrent(storesFilter);
          setValueBrands(storesFilter);
          setValueTypeOperator(val);
          if (onChangeBrands) {
            onChangeBrands(storesFilter);
          }
          //console.log('onChange() ==> val', val);
        }}
        value={valueTypeOperator}
        {...{ isDummy }}
      />
      <BrandSelect
        storesNews={brandsCurrent}
        value={valueBrands}
        onChange={val => {
          if (onChangeBrands) {
            onChangeBrands(val);
          }
          setValueBrands(val);
          //console.log('BrandSelect => onChange() ==> val', val);
        }}
      />
    </div>
  );
};

export default BrandAndTypeOperatorSelect;
