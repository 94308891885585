import { useContext, useState } from 'react';

import { EnumFileUploadStates, SizeScreen, TypeToast } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import UploadFileOrganism from 'components/organisms/dashboard/UploadFile';
import RenderPopUp from 'components/molecules/modals/RenderPopUp';

import { convertXlsAtCsv } from 'helpers/Converts';

import FileOrders from 'models/files/FileOrders';

import { uploadFileCsvOfOrder } from 'services/app/Order';

const UploadFilePage = () => {
  const { showLoading, showToast, hideLoading, screenResolution } = useContext(RootContext);
  const { storeSession } = useContext(AuthContext);
  const { addUploadFile } = useContext(DataContext);

  const [isShowLoading, setIsShowLoading] = useState(false);

  const uploadFileCsv = async (file: File): Promise<ResponseApp> => {
    //console.log('uploadFileCsv', csvFile);
    let numberOrder = 0;
    //csvFile.name = `${csvFile.name.replace(`.xlsx`, `${getDateNow({ format: FormatDate.yyyymmddHHmmAlt })}.xlsx`)}`;
    const csvFile = new File([file], `${FileOrders.generateFileNameWithDate(file)}`);
    console.log('uploadFileCsv => csvFile', { csvFile, file });
    const resultConvert = await convertXlsAtCsv(csvFile);

    if (resultConvert) {
      resultConvert.forEach(rConvert => {
        numberOrder += rConvert.numberLine;
      });
    }
    //console.log('uploadFileCsv() => resultConvert', { resultConvert, numberOrder });

    if (numberOrder >= 50) {
      setIsShowLoading(true);
    } else {
      showLoading();
    }

    //const promisesUploadFile = fileNew.map(async fileN => await uploadFileCsvOfOrder(fileN));
    //const resultPromise = await Promise.all(promisesUploadFile);
    const { status, data, message } = await uploadFileCsvOfOrder(csvFile, storeSession);
    console.log('uploadFileCsv() => { status, data, message }', { status, data, message });

    if (status) {
      hideLoading();
      setIsShowLoading(false);
      addUploadFile(new FileOrders({ nameFile: csvFile.name, id: data, status: EnumFileUploadStates.inProcess, type: 'upload' }));
      return { status: true };
    } else {
      showToast({ text: message || 'No fue posible cargar el archivo.', type: TypeToast.error });
    }

    hideLoading();
    setIsShowLoading(false);
    return { status: false };
  };

  return (
    <>
      <UploadFileOrganism onUploadFile={uploadFileCsv} />
      <RenderPopUp
        isVisible={isShowLoading}
        classNameContent='mx-4 sm:mx-0'
        styleContent={screenResolution <= SizeScreen.sm ? {} : { maxWidth: '50%' }}
      >
        <div className='flex justify-center items-center flex-col p-6'>
          <div className='spinner mb-10' style={{ width: 90, height: 90 }} />
          <p className='text-base text-center sm:text-xl'>
            Has subido un archivo con mas de 50 ordenes, esto puede tardar algunos minutos, por favor espera un momento.
          </p>
        </div>
      </RenderPopUp>
    </>
  );
};

export default UploadFilePage;
