import { ReactNode, useContext } from 'react';

import { RootContext } from 'context/RootContext';

const ClearCacheButton = ({ children }: { children: ReactNode }) => {
  const { showModalQuestion } = useContext(RootContext);
  // Function to clear complete cache data
  const clearCacheData = () => {
    showModalQuestion({
      title: '¿Desea borrar la memoria cache?',
      options: [
        {
          text: 'Si',
          onAction: () => {
            caches.keys().then(names => {
              names.forEach(name => {
                caches.delete(name);
              });
              window.location.reload();
            });
          }
        },
        {
          text: 'No'
        }
      ]
    });
  };

  return <button onClick={() => clearCacheData()}>{children}</button>;
};

export default ClearCacheButton;
