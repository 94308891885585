import moment from 'moment';
import { useEffect, useState } from 'react';

import { mySession } from 'storages/Session';

export interface useSessionTimeProps {
  onActionSession?: () => void;
}

const useSessionTime = ({ onActionSession }: useSessionTimeProps): [string | undefined] => {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    //console.log('useEffect() => sessionTime', {
    //mySession,
    //timeSecond: moment(mySession.expiredIn || '').diff(moment(), 'milliseconds')
    //});
    const timeSecond = moment(mySession.expiredIn || '').diff(moment(), 'milliseconds');
    let timerSession: any = undefined;
    if (!isNaN(timeSecond) && mySession.expiredIn) {
      timerSession = setTimeout(() => {
        if (onActionSession) {
          onActionSession();
        }
        //}, moment(mySession.expiredIn || '').diff(moment(), 'seconds'));
      }, timeSecond);
    }
    setToken(mySession.token);

    return () => {
      clearTimeout(timerSession);
    };
  }, [mySession.token]);

  return [token];
};

export default useSessionTime;
