import CategoryQuestion, { CategoryQuestionServerModel } from 'models/questions/Category';
import FrequentQuestion, { FrequentQuestionServerModel } from 'models/questions/FrequentQuestion';
import HelpCenterResource from 'models/questions/Resource';
import QuestionProvider from 'services/api/Question';

export const getAllFrequentQuestions = async () => {
  try {
    const { data, status, ...other } = await QuestionProvider.getAllFrequentQuestions();
    console.log('getAllFrequentQuestions() => { data, status, other }', { data, status, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((question: FrequentQuestionServerModel) => new FrequentQuestion(FrequentQuestion.formalizeData(question)))
        };
      }

      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener las preguntas frecuentes.' };
    }
    return { status: false, message: 'No fue posible obtener las preguntas frecuentes.' };
  } catch (err) {
    return { status: false, message: `Error al obtener las preguntas frecuentes. ${(err as Error).message}` };
  }
};

export const getAllFrequentQuestionsByCategory = async (params: { category?: CategoryQuestion }) => {
  try {
    const { data, status, ...other } = await QuestionProvider.getAllFrequentQuestionsByCategory(params);
    console.log('getAllFrequentQuestionsByCategory() ==> { data, status, other }', { data, status, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((question: FrequentQuestionServerModel) => new FrequentQuestion(FrequentQuestion.formalizeData(question)))
        };
      }

      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener las preguntas frecuentes.' };
    }
    return { status: false, message: 'No fue posible obtener las preguntas frecuentes.' };
  } catch (err) {
    return { status: false, message: `Error al obtener las preguntas frecuentes. ${(err as Error).message}` };
  }
};

export const getAllInformations = async () => {
  try {
    const { data, status, ...other } = await QuestionProvider.getAllInformations();
    console.log('getAllInformations() ==> { data, status, other }', { data, status, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((question: FrequentQuestionServerModel) => new FrequentQuestion(FrequentQuestion.formalizeData(question)))
        };
      }

      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener la información.' };
    }
    return { status: false, message: 'No fue posible obtener la información.' };
  } catch (err) {
    return { status: false, message: `Error al obtener la información. ${(err as Error).message}` };
  }
};

export const createFrequentQuestion = async (frequentQuestion: FrequentQuestion) => {
  try {
    const { data, status, ...other } = await QuestionProvider.createFrequentQuestion(frequentQuestion);
    console.log('createFrequentQuestion() =>', { data, status, ...other });

    if (status === 200 || status === 201) {
      return { status: true, message: 'Pregunta creada con éxito.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener las preguntas frecuentes.' };
    }
    return { status: false, message: 'No fue posible crear la pregunta.' };
  } catch (err) {
    return { status: false, message: `Error al intentar crear la pregunta frecuente. ${(err as Error).message}` };
  }
};

export const updateFrequentQuestion = async (frequentQuestion: FrequentQuestion) => {
  try {
    const { data, status, ...other } = await QuestionProvider.updateFrequentQuestion(frequentQuestion);
    console.log('updateFrequentQuestion() ==>', { data, status, frequentQuestion, other });

    if (status === 200 || status === 201) {
      return { status: true, message: 'Pregunta actulizada con éxito.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener las preguntas frecuentes.' };
    }
    return { status: false, message: 'No fue posible actulizar la pregunta.' };
  } catch (err) {
    return { status: false, message: `Error al intentar actulizar la pregunta frecuente. ${(err as Error).message}` };
  }
};

export const deletedFrequentQuestion = async (frequentQuestion: FrequentQuestion) => {
  try {
    const { data, status, ...other } = await QuestionProvider.deletedFrequentQuestion(frequentQuestion);
    console.log('deletedFrequentQuestion() ==>', { data, status, frequentQuestion });

    if (status === 200 || status === 201) {
      return { status: true, message: 'Pregunta eliminada con éxito.' };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible eliminar la pregunta.' };
    }
    return { status: false, message: 'No fue posible actulizar la pregunta.' };
  } catch (err) {
    return { status: false, message: `Error al intentar eliminar la pregunta. ${(err as Error).message}` };
  }
};

export const getCategoriesQuestions = async () => {
  try {
    const { status, data, ...other } = await QuestionProvider.getCategories();
    console.log('getCategoriesQuestions() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((dat: CategoryQuestionServerModel) => new CategoryQuestion(CategoryQuestion.formalizeData(dat)))
        };
      }
      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible cargar las categorías.' };
    }
    return { status: false, message: 'No fue posible cargar las categorías.' };
  } catch (err) {
    console.log('getCategoriesQuestions() => err', err);
    return { status: false, message: 'No fue posible cargar las categorías.' };
  }
};

export const onCreateCategoryQuestion = async (category: CategoryQuestion) => {
  try {
    const { data, status, ...other } = await QuestionProvider.createCategoryQuestion(category);
    console.log('onCreateCategoryQuestion() => { status, data, other }', { status, data, other });

    if (status === 201) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((dat: CategoryQuestionServerModel) => new CategoryQuestion(CategoryQuestion.formalizeData(dat)))
        };
      }
      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible crear la categoría.' };
    }
    return { status: false, message: 'No fue posible crear la categoría.' };
  } catch (err) {
    console.log('onCreateCategoryQuestion() => err', err);
    return { status: false, message: 'No fue posible crear la categoría.' };
  }
};

export const onDeletedCategoryQuestion = async (category: CategoryQuestion) => {
  try {
    const { data, status, ...other } = await QuestionProvider.deletedCategoryQuestion(category);
    console.log('onDeletedCategoryQuestion() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((dat: CategoryQuestionServerModel) => new CategoryQuestion(CategoryQuestion.formalizeData(dat)))
        };
      }
      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible crear la categoría.' };
    }
    return { status: false, message: 'No fue posible crear la categoría.' };
  } catch (err) {
    console.log('onCreateCategoryQuestion() => err', err);
    return { status: false, message: 'No fue posible crear la categoría.' };
  }
};

export const onUpdateCategoryQuestion = async (category: CategoryQuestion) => {
  try {
    const { data, status, ...other } = await QuestionProvider.updatedCategoryQuestion(category);
    console.log('onUpdateCategoryQuestion() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: data.map((dat: CategoryQuestionServerModel) => new CategoryQuestion(CategoryQuestion.formalizeData(dat)))
        };
      }
      return { status: true, data: [] };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible actualizar la categoría.' };
    }
    return { status: false, message: 'No fue posible actualizar la categoría.' };
  } catch (err) {
    console.log('onCreateCategoryQuestion() => err', err);
    return { status: false, message: 'No fue posible actualizar la categoría.' };
  }
};

export const onUpdateSource = async (helpCenterResource: HelpCenterResource) => {
  try {
    const { data, status, ...other } = await QuestionProvider.updateSource(helpCenterResource);
    console.log('onUpdateSource() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data && data.length > 0) {
        return {
          status: true,
          data: new HelpCenterResource(data)
        };
      }
      return { status: true, data: undefined };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible actualizar el recurso.' };
    }
    return { status: false, message: 'No fue posible actualizar el recurso.' };
  } catch (err) {
    console.log('onUpdateSource() => err', err);
    return { status: false, message: 'No fue posible actualizar el recurso.' };
  }
};

export const getSource = async () => {
  try {
    const { data, status, ...other } = await QuestionProvider.getSource();
    console.log('getSource() => { status, data, other }', { status, data, other });

    if (status === 200) {
      if (data) {
        return {
          status: true,
          data: new HelpCenterResource(data)
        };
      }
      return { status: true, data: undefined };
    }

    if ('message' in other) {
      return { status: false, message: other.message || 'No fue posible obtener el recurso.' };
    }
    return { status: false, message: 'No fue posible obtener el recurso.' };
  } catch (err) {
    console.log('onSource() => err', err);
    return { status: false, message: 'No fue posible obtener el recurso.' };
  }
};
