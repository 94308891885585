import { useState, useContext } from 'react';
import moment from 'moment';

import { DashboardOrganismSections, NameIcons } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';

//import GeneralDataCake from 'components/molecules/charts/GeneralDataCake';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';
//import BulkLoadStatistics from 'components/molecules/charts/BulkLoadStatistics';
//import BulkLoadStatisticsFilter, { BulkLoadStatisticsFilterValue } from 'components/molecules/filters/BulkLoadStatisticsFilter';
//import PerformanceStatisticOrganism from 'components/organisms/dashboard/statistics/Performance';
//import StatisticsTop from 'components/molecules/bars/StatisticsTop';
//import ActiveStoresChartPie from 'components/molecules/charts/ActiveStores';
import MultiLineEvolutionOrders from 'components/molecules/graphics/line/MultiLineEvolutionOrders';
import StatisticHeader, { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';
import StatisticsCards from 'components/molecules/cards/Statistics';
import StatisticDropDown from 'components/molecules/dropDown/Statistics';
import StatisticGeneralTabs from 'components/molecules/tabs/statistics/StatisticsGeneral';
import DonutGraphics, { DonutGraphicsData } from 'components/molecules/graphics/Donut';
import StatisticDeliveryPickupTabs from 'components/molecules/tabs/statistics/StatisticsDeliveryPickup';
import IconCustom from 'components/atoms/icons';

import { capitalize } from 'helpers/Converts';

import PieTypeDelivery from 'models/order/graphics/PieTypeDelivery';
import GraphicsOrderByStatusJany from 'models/order/graphics/OrderByStatusJanyBar';
import GraphicAverageTimeOrder from 'models/order/graphics/AverageTimeOrder';
import GraphicOrderByStorePie from 'models/order/graphics/OrdersByStore';
import GraphicOrderByStore from 'models/order/graphics/OrderByStore';
import KpiGeneralStatistic from 'models/statistic/KpiGeneral';
import TypeDeliveryStatistic, { MountLastYears } from 'models/statistic/TypeDelivery';
import StoreStatistic from 'models/statistic/Store';
import Store from 'models/store';
import StatusOrder from 'models/statistic/order/StatusOrder';
import CountOrderByTypeDelivery from 'models/statistic/order/CountOrderByTypeDelivery';
import OrderByStore from 'models/statistic/order/OrderByStore';
import EvolutionOrderByDelivery from 'models/statistic/order/EvolutionOrderByDelivery';
import KpiGeneral from 'models/statistic/order/KpiGeneral';
import DemandPickupStatistics from 'models/statistic/DemandPickupStatistics';
import OrderCancel from 'models/statistic/order/OrderCancel';
import StatisticBusinessTabs from 'components/molecules/tabs/statistics/StatisticsBusiness';
import OrderFail from 'models/statistic/order/OrderFail';
import AmountStatistic from 'models/statistic/Amount';
import OperatorStatistic from 'models/statistic/Operator';
import DistributionStatistic from 'models/statistic/Distribution';
import PerformanceStatistic from 'models/statistic/Performance';

type DashboardOrganismProps = {
  onFilter: (params?: ParamsValueFilterStatistic) => void;
  graphicPieTypeDelivery?: PieTypeDelivery;
  graphicOrdersByStatusJanis?: GraphicsOrderByStatusJany;
  graphicTimeAverageOrder?: GraphicAverageTimeOrder;
  graphicOrdersByStore?: GraphicOrderByStorePie;
  graphicOrderByStore?: GraphicOrderByStore;
  typeDeliveryPerformance?: TypeDeliveryStatistic;
  kpiGeneralStatistic?: KpiGeneralStatistic;
  storeStatistic?: StoreStatistic;
  stores?: Store[];
  dataUpdateDate?: string;
  paramsFilter?: ParamsValueFilterStatistic;
  statusOrder?: StatusOrder;
  countOrderByTypeDelivery?: CountOrderByTypeDelivery;
  orderByStore?: OrderByStore;
  evolutionByTypesDelivery?: EvolutionOrderByDelivery;
  kpiGeneral?: KpiGeneral;
  onFilterEvolutionOrder: (params?: ParamsValueFilterStatistic) => void;
  performanceTypeDelivery?: TypeDeliveryStatistic;
  onAmountFilter?: (params: ParamsValueFilterStatistic) => void;
  amountSalesStatistic?: MountLastYears[];
  demandPickup?: DemandPickupStatistics;
  onFilterDemandPickup?: (param: ParamsValueFilterStatistic) => void;
  orderCancelStatistic?: OrderCancel;
  activeStoreStatistic?: StoreStatistic;
  orderFailStatistic?: OrderFail;
  onFilterOrderFail?: (params?: ParamsValueFilterStatistic) => void;
  onFilterOrderCancel?: (params?: ParamsValueFilterStatistic) => void;
  refContainer?: any;
  animationButton?: boolean;
  amountStatistic?: AmountStatistic;
  operatorOrders?: OperatorStatistic;
  distributionOrders?: DistributionStatistic;
  performanceStatistic?: PerformanceStatistic;
};

const DashboardOrganism = ({
  onFilter,
  stores,
  dataUpdateDate,
  paramsFilter,
  statusOrder,
  countOrderByTypeDelivery,
  orderByStore,
  evolutionByTypesDelivery,
  kpiGeneral,
  onFilterEvolutionOrder,
  performanceTypeDelivery,
  onAmountFilter,
  amountSalesStatistic,
  demandPickup,
  onFilterDemandPickup,
  orderCancelStatistic,
  activeStoreStatistic,
  orderFailStatistic,
  onFilterOrderFail,
  onFilterOrderCancel,
  refContainer,
  animationButton,
  amountStatistic,
  operatorOrders,
  distributionOrders,
  performanceStatistic
}: //graphicPieTypeDelivery,
//graphicOrdersByStatusJanis,
//graphicTimeAverageOrder,
//graphicOrdersByStore,
//graphicOrderByStore,
//typeDeliveryPerformance,
//storeStatistic,
//kpiGeneralStatistic
DashboardOrganismProps) => {
  const { authStatus } = useContext(AuthContext);
  const [section, setSection] = useState(DashboardOrganismSections.general);

  return (
    <MenuSessionContainer ref={refContainer} notStyleContainer className='relative' classNameContainer='relative'>
      <StatisticHeader
        values={paramsFilter}
        onChangeSeccion={val => {
          setSection(val);
        }}
        {...{ stores, onFilter, section }}
      />

      <div style={{ borderRadius: 10 }} className='mx-5 md:mx-10 mt-4 bg-white justify-between flex'>
        <p className='py-4 px-4 text-base md:text-lg font-normal'>
          Información correspondiente al mes de <strong>{capitalize(moment().format('MMMM'))}</strong> hasta las {dataUpdateDate}
        </p>

        <div className='flex justify-between items-center relative mr-4 my-2'>
          {animationButton && (
            <span className={`animate-ping absolute inline-flex h-full w-full rounded-md bg-grayarauco-300 opacity-75`}></span>
          )}
          <button
            className='flex justify-center items-center border-grayarauco-400 px-2 z-0'
            style={{ borderRadius: 10, borderWidth: 2, paddingBottom: 8, paddingTop: 10 }}
            onClick={() => onFilter(paramsFilter)}
          >
            <IconCustom name={NameIcons.update} className='text-grayarauco-400' />
          </button>
        </div>
      </div>

      {section !== DashboardOrganismSections.business && <StatisticsCards total={statusOrder?.data.total} data={statusOrder?.data.items} />}
      {section !== DashboardOrganismSections.business && <StatisticDropDown data={kpiGeneral} {...{ section }} />}

      {section === DashboardOrganismSections.general && (
        <div className=''>
          {!authStatus.user?.isLocatario() && (
            <StatisticGeneralTabs
              orderByStore={orderByStore}
              dataCountOrder={countOrderByTypeDelivery?.data.items}
              statusOrder={statusOrder}
            />
          )}
          {authStatus.user?.isLocatario() && (
            <div className='mt-4 mx-10 bg-white flex flex-1'>
              <div className='flex flex-1 flex-col justify-center items-center mx-4 py-4 relative md:flex-row'>
                <DonutGraphics
                  classNameContainer='mr-0 md:mr-2 flex-1 w-full md:w-2/2'
                  textCenter='Envíos'
                  title='Tipos de envío'
                  data={countOrderByTypeDelivery?.data.items as DonutGraphicsData[]}
                />
                <DonutGraphics
                  classNameContainer='ml-0 md:ml-2 flex-1 w-full md:w-2/2'
                  textCenter='Órdenes creadas'
                  title='Estado de órdenes'
                  data={statusOrder?.data.allStatus as DonutGraphicsData[]}
                />
              </div>
            </div>
          )}

          <MultiLineEvolutionOrders
            classNameContainer='p-4 mx-5 md:mx-10 mt-4'
            title='Evolución de tipo de servicios'
            onFilter={val => onFilterEvolutionOrder(val)}
            data={evolutionByTypesDelivery?.data.items}
          />
        </div>
      )}

      {(section === DashboardOrganismSections.performanceDelivery || section === DashboardOrganismSections.performancePickup) && (
        <StatisticDeliveryPickupTabs
          {...{
            performanceTypeDelivery,
            section,
            paramsFilter,
            onAmountFilter,
            amountSalesStatistic,
            demandPickup,
            onFilterDemandPickup,
            amountStatistic,
            operatorOrders,
            distributionOrders,
            performanceStatistic
          }}
          dataCountOrder={countOrderByTypeDelivery?.data.items}
        />
      )}

      {section === DashboardOrganismSections.business && (
        <StatisticBusinessTabs
          onFilterFail={params => (onFilterOrderFail ? onFilterOrderFail(params) : undefined)}
          onFilterCancel={params => (onFilterOrderCancel ? onFilterOrderCancel(params) : undefined)}
          paramsFilter={paramsFilter}
          failedOrders={orderFailStatistic}
          orderCancel={orderCancelStatistic}
          activeStores={activeStoreStatistic}
        />
      )}
    </MenuSessionContainer>
  );
};

export default DashboardOrganism;
