import { useContext, useEffect, useReducer, useState } from 'react';
import { Tabs } from 'antd';

import { NameIcons, StyleButton, TypeToast } from 'configs/Enums';
import { RouterInContext } from 'context/RouterIn';
import { RootContext } from 'context/RootContext';

import InputCustom from 'components/atoms/inputs';
import ModalCustom from 'components/molecules/modals';
import ButtonCustom from 'components/atoms/buttons';
import IconCustom from 'components/atoms/icons';
import BoardFrequentQuestion from 'components/molecules/board/FrequentQuestions';

import { getBase64 } from 'helpers/Converts';

import CategoryQuestion from 'models/questions/Category';
import FrequentQuestion from 'models/questions/FrequentQuestion';

type ModalUpdateCategoryProps = {
  category?: CategoryQuestion;
  categories?: CategoryQuestion[];
  frequentQuestions?: FrequentQuestion[];
  isVisible: boolean;
  onClose: () => void;
  onUpdateCategory: (category: CategoryQuestion) => void;
  onUpdateFrequentQuestion: (form: any) => void;
  onCreateFrequentQuestion: (form: any) => void;
  onDeletedFrequentQuestion: (form: any) => void;
  onLoadQuestions: (params: { category?: CategoryQuestion }) => void;
};

const { TabPane } = Tabs;

enum TabCategorySeccion {
  questions = 'questions',
  categoryQuestion = 'categoryQuestion'
}

interface CategoryValues {
  name?: string;
  description?: string;
  icon?: string | File;
}

enum CategoryValueType {
  name = 'name',
  description = 'description',
  icon = 'icon',
  iconPath = 'iconPath',
  all = 'all'
}

const ModalUpdateCategory = ({
  category,
  categories,
  isVisible,
  onClose,
  onUpdateCategory,
  frequentQuestions,
  onUpdateFrequentQuestion,
  onCreateFrequentQuestion,
  onDeletedFrequentQuestion,
  onLoadQuestions
}: ModalUpdateCategoryProps) => {
  const { showToast } = useContext(RootContext);
  const { hContainer } = useContext(RouterInContext);

  const [sectionTabActive, setSectionTabActive] = useState<TabCategorySeccion | string>(TabCategorySeccion.questions);

  const [value, dispatch] = useReducer(
    (prevState: CategoryValues, action: { type: CategoryValueType; value: any }) => {
      switch (action.type) {
        case CategoryValueType.name:
          return {
            ...prevState,
            name: action.value
          };
        case CategoryValueType.description:
          return {
            ...prevState,
            description: action.value
          };
        case CategoryValueType.icon:
          return {
            ...prevState,
            icon: action.value
          };
        case CategoryValueType.iconPath:
          return {
            ...prevState,
            iconPath: action.value
          };
        case CategoryValueType.all:
          return { ...prevState, ...action.value };
        default:
          return {
            ...prevState
          };
      }
    },
    { name: '', description: '', icon: '', iconPath: '' }
  );

  const openBrowserFile = () => {
    const hidden = document.getElementById('hidden-input');
    hidden?.click();
  };

  const changeHandler = async (event: any) => {
    if (event.target.files.length === 1) {
      const file: File = event.target.files[0];
      console.log('file', file);
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/svg+xml' ||
        file.type === 'image/svg' ||
        file.type === 'image/jpg'
      ) {
        dispatch({ type: CategoryValueType.iconPath, value: await getBase64(file) });
        dispatch({ type: CategoryValueType.icon, value: file });
      } else {
        showToast({ text: 'El formato del archivo no es el correcto, descarga y utiliza la plantilla de trabajo', type: TypeToast.error });
      }
    } else {
      showToast({ text: 'Sólo puede subir un archivo', type: TypeToast.error });
    }
  };

  useEffect(() => {
    if (category) {
      dispatch({ type: CategoryValueType.all, value: category?.data });
    }
    onLoadQuestions({ category: category });
  }, [category]);

  return (
    <ModalCustom notFullScreen isVisible={isVisible} onClose={onClose} styleContent={{ padding: 0 }}>
      <div className='flex flex-col bg-white flex-1 w-full px-5 md:px-10 py-4 overflow-auto' style={{ height: hContainer }}>
        <div>
          <button onClick={() => onClose()}>{'< Volver'}</button>
        </div>

        <div className='flex flex-col flex-1 mt-0 md:mt-4'>
          <h1 className='text-2xl sm:text-3xl'>Nueva categoría de preguntas</h1>

          <div className='flex flex-col mt-4 md:flex-row md:mt-8'>
            <div className='flex flex-col p-0 md:p-8 bg-grayarauco-50'>
              <InputCustom
                label='Categoria preguntas frecuentes'
                value={value.name}
                onChange={val => dispatch({ type: CategoryValueType.name, value: val.target.value })}
              />
              <InputCustom
                classNameContainer='mt-4'
                label='Descripción'
                value={value.description}
                onChange={val => dispatch({ type: CategoryValueType.description, value: val.target.value })}
              />
              <div className='flex flex-col mt-4'>
                <p>Icono</p>
                <div className='flex flex-row items-center mt-2'>
                  {!value.icon && <p className='text-base mr-10'>Sin imagen</p>}
                  {value.icon && <p className='text-base mr-10'>{value.icon?.name}</p>}
                  <ButtonCustom onClick={() => openBrowserFile()}>Subir imagen</ButtonCustom>

                  <input
                    id='hidden-input'
                    type='file'
                    onChange={changeHandler}
                    className='hidden'
                    accept='.png,.jpg,.jpeg,.svg'
                    multiple={false}
                  />
                </div>
              </div>

              <div className='mt-8'>
                <p className='text-base mb-4'>Previsualización botón </p>

                <div className='flex flex-row items-center px-4 py-9 shadow-md bg-white' style={{ borderRadius: 10 }}>
                  {value.icon && <img className='w-16 h-16' src={value.iconPath} />}
                  {!value.icon && <IconCustom name={NameIcons.image} className='text-grayarauco-300 text-5xl' />}
                  <div className='ml-4'>
                    <p className='text-primary text-base font-bold'>{value.name}</p>
                    <p className='mt-4 text-black text-sm font-normal'>{value.description}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='w-full mt-4 pl-0 md:pl-8'>
              <Tabs
                className={'tab-store'}
                activeKey={sectionTabActive}
                onChange={tab => {
                  setSectionTabActive(tab);
                  console.log('onChange() => tab', tab);
                }}
              >
                <TabPane key={TabCategorySeccion.questions} tab='Preguntas' />
                <TabPane key={TabCategorySeccion.categoryQuestion} tab='Categorías preguntas' />
              </Tabs>

              <div>
                {sectionTabActive === TabCategorySeccion.questions && (
                  <BoardFrequentQuestion
                    frequentQuestions={frequentQuestions || []}
                    category={category}
                    {...{ onUpdateFrequentQuestion, onDeletedFrequentQuestion, onCreateFrequentQuestion, categories }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-between items-center flex-col sm:flex-row gap-4 mt-4'>
          <ButtonCustom
            className='w-4/5 sm:w-64'
            styleButton={StyleButton.outlinePrimary}
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </ButtonCustom>
          <ButtonCustom
            className='w-4/5 sm:w-64'
            onClick={() => {
              if (category) {
                category.update({ name: value.name, description: value.description, icon: value.icon });
                onUpdateCategory(category);
              }
            }}
          >
            Actualizar
          </ButtonCustom>
        </div>
      </div>
    </ModalCustom>
  );
};

export default ModalUpdateCategory;
