import { TableColumn } from 'react-data-table-component';

import { FormatDate, NameIcons, TypeDelivery } from 'configs/Enums';

import FilterHeaderColumn from 'components/molecules/headers/FilterHeaderColumn';

import { downloadFileByURL, numberAtNumberH, numberAtPrice, stringAtDate, stringDeliveryToIcon } from 'helpers/Converts';
import { getDateNow, getLastDayMonth } from 'helpers/Generator';

import Base from 'models/Base';
import TableCellMolecule from 'components/molecules/items/TableCell';
import IconCustom from 'components/atoms/icons';
import Store from 'models/store';

export interface InvoiceItemModel {
  name?: string;
  total?: number;
  amount?: number;
  type?: TypeDelivery;
  icon?: NameIcons;
  avg?: number;
}

export interface InvoiceModel {
  deliveries?: InvoiceItemModel[];
  id?: string;
  total?: number;
  amount?: number;
  totalPickup?: number;
  totalDelivery?: number;
  amountPickup?: number;
  amountDelivery?: number;
  avgTotalDelivery?: number;
  avgTotalPickup?: number;
  dateStart?: string;
  dateEnd?: string;
  dateEndDisplay?: string;
  dateStartDisplay?: string;
  dateEndDisplayBoard?: string;
  dateStartDisplayBoard?: string;
  store?: Store;
  year?: number | string;
  url?: string;
  monthYear?: string;
}

export interface InvoiceServerHistoricalModel {
  billingDate: string;
  lastBillingDate: string;
  qTotalDelivery: number;
  qTotalPickup: number;
  qdelivery: number;
  qpickup: number;
  store?: Store;
  year?: string | number;
  url?: string;
  monthYear?: string;
}

export interface InvoiceServerEstimateModel {
  TotalDelivery: {
    totalCountDelivery: number;
    totalDelivery: number;
    averageDelivery: number;
  };
  TotalPickUp: {
    totalCountPickup: number;
    totalPickup: number;
    averagePickup: number;
  };
  Total: {
    totalCount: number;
    total: number;
    averageTotal: number;
  };
  nextDay?: {
    countOrdersNextDay: number;
    totalNextDay: number;
    averageNextDay: number;
  };
  sameDay: {
    countOrdersSameDay: number;
    totalSameDay: number;
    averageSameDay: number;
  };
  express?: {
    countOrdersExpress: number;
    totalExpress: number;
    averageExpress: number;
  };
  startDate?: string;
  endDate?: string;
}

class Invoice extends Base {
  data: InvoiceModel;

  constructor(data: InvoiceModel) {
    super();
    this.data = data;
  }

  static voidIvoice() {
    const deliveriesCurrent: InvoiceItemModel[] = [
      {
        name: 'express_delivery',
        total: 0,
        amount: 0,
        type: TypeDelivery.expressDelivery,
        icon: stringDeliveryToIcon(TypeDelivery.expressDelivery)
      },
      {
        name: 'same_day',
        total: 0,
        amount: 0,
        type: TypeDelivery.sameDay,
        icon: stringDeliveryToIcon(TypeDelivery.sameDay)
      },
      {
        name: 'next_day',
        total: 0,
        amount: 0,
        type: TypeDelivery.nextDay,
        icon: stringDeliveryToIcon(TypeDelivery.nextDay)
      },
      {
        name: 'pickup',
        total: 0,
        amount: 0,
        type: TypeDelivery.pickup,
        icon: stringDeliveryToIcon(TypeDelivery.pickup)
      }
    ];
    const dateEndCurrent = getLastDayMonth({ date: `${getDateNow({ format: FormatDate.yyyymmdd })}`, format: FormatDate.ddmmaaaaAlt });
    const dateStartCurrent = `01/${getDateNow({ format: FormatDate.mmyyyyAlt })}`;

    return new Invoice({
      deliveries: deliveriesCurrent,
      totalPickup: 0,
      totalDelivery: 0,
      amountPickup: 0,
      amountDelivery: 0,
      avgTotalPickup: 0,
      avgTotalDelivery: 0,
      dateEnd: dateEndCurrent,
      dateStart: dateStartCurrent,
      dateEndDisplay: dateEndCurrent,
      dateStartDisplay: dateStartCurrent,
      dateEndDisplayBoard: stringAtDate(dateEndCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaaAlt),
      dateStartDisplayBoard: stringAtDate(dateStartCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaaAlt),
      total: 0,
      amount: 0
    });
  }

  static formalizeData(data: InvoiceServerEstimateModel): InvoiceModel {
    const { TotalDelivery, TotalPickUp, Total, nextDay, sameDay, express, startDate, endDate } = data;
    const dateEndCurrent = endDate;
    const dateStartCurrent = startDate;
    let deliveriesCurrent: InvoiceItemModel[] = [];

    deliveriesCurrent = [
      {
        name: TypeDelivery.pickup,
        total: TotalPickUp.totalPickup,
        amount: TotalPickUp.totalCountPickup,
        avg: TotalPickUp?.averagePickup,
        type: TypeDelivery.pickup
      },
      {
        name: TypeDelivery.expressDelivery,
        total: express?.totalExpress,
        amount: express?.countOrdersExpress,
        avg: express?.averageExpress,
        type: TypeDelivery.expressDelivery
      },
      {
        name: TypeDelivery.sameDay,
        total: sameDay?.totalSameDay,
        amount: sameDay?.countOrdersSameDay,
        avg: sameDay?.averageSameDay,
        type: TypeDelivery.sameDay
      },
      {
        name: TypeDelivery.nextDay,
        total: nextDay?.totalNextDay,
        amount: nextDay?.countOrdersNextDay,
        avg: nextDay?.averageNextDay,
        type: TypeDelivery.nextDay
      }
    ];

    return {
      id: `${dateStartCurrent}-${dateEndCurrent}`,
      deliveries: deliveriesCurrent,
      totalPickup: TotalPickUp.totalPickup || 0,
      totalDelivery: TotalDelivery.totalDelivery || 0,
      amountPickup: TotalPickUp.totalCountPickup || 0,
      amountDelivery: TotalDelivery.totalCountDelivery || 0,
      avgTotalPickup: TotalPickUp.averagePickup || 0,
      avgTotalDelivery: TotalDelivery.averageDelivery || 0,
      dateEnd: dateEndCurrent,
      dateStart: dateStartCurrent,
      dateEndDisplay: stringAtDate(dateEndCurrent, FormatDate.ddmmaaaaAlt, FormatDate.ddmmaaaaAlt),
      dateStartDisplay: stringAtDate(dateStartCurrent, FormatDate.ddmmaaaaAlt, FormatDate.ddmmaaaaAlt),
      dateEndDisplayBoard: stringAtDate(dateEndCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaaAlt),
      dateStartDisplayBoard: stringAtDate(dateStartCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaaAlt),
      total: Total.total || 0,
      amount: Total.totalCount || 0
    };
  }

  static formalizeDataHistorical(data: InvoiceServerHistoricalModel) {
    const { billingDate, lastBillingDate, qTotalPickup, qTotalDelivery, qdelivery, qpickup, store, year, url, monthYear } = data;

    const dateEndCurrent = billingDate;
    const dateStartCurrent = lastBillingDate;

    return {
      id: `${billingDate}-${lastBillingDate}`,
      deliveries: [],
      totalPickup: qTotalPickup,
      totalDelivery: qTotalDelivery,
      amountPickup: qpickup,
      amountDelivery: qdelivery,
      avgTotalPickup: 0,
      avgTotalDelivery: 0,
      dateEnd: dateEndCurrent,
      dateStart: dateStartCurrent,
      dateEndDisplay: stringAtDate(dateEndCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaa),
      dateStartDisplay: stringAtDate(dateStartCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaa),
      dateEndDisplayBoard: stringAtDate(dateEndCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaa),
      dateStartDisplayBoard: stringAtDate(dateStartCurrent, FormatDate.ddmmAlt, FormatDate.ddmmaaaa),
      total: qTotalPickup + qTotalDelivery,
      amount: qpickup + qdelivery,
      store,
      year,
      url,
      monthYear
    };
  }

  static headerTable({
    onDownload,
    onDetail,
    notDownload
  }: {
    onDownload: (invoice: Invoice) => void;
    onDetail: (invoice: Invoice) => void;
    notDownload?: boolean;
  }): TableColumn<Invoice>[] {
    return [
      {
        name: <FilterHeaderColumn classNameContainer={'text-base'} title='Periodo facturación' />,
        selector: row => `${row.data.dateStartDisplayBoard} - ${row.data.dateEndDisplayBoard}`,
        reorder: false,
        conditionalCellStyles: [
          {
            when: row => row.data.dateEndDisplayBoard === '',
            classNames: ['cell-succes']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            classNameContent={'text-base'}
            //className='text-base'
            value={row.data.dateStartDisplayBoard ? `${row.data.dateStartDisplayBoard} - ${row.data.dateEndDisplayBoard}` : ''}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn classNameContainer={'text-base'} title='Pickup' />,
        selector: row => `${row.data.amountPickup}`,
        reorder: false,
        conditionalCellStyles: [
          {
            when: row => row.data.dateEndDisplayBoard === '',
            classNames: ['cell-succes']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            classNameContent={'text-base'}
            value={`${numberAtNumberH(row.data.amountPickup)}`}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn classNameContainer={'text-base'} title='Delivery' />,
        selector: row => `${row.data.amountDelivery}`,
        reorder: false,
        conditionalCellStyles: [
          {
            when: row => row.data.dateEndDisplayBoard === '',
            classNames: ['cell-succes']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            classNameContent={'text-base'}
            value={`${numberAtNumberH(row.data.amountDelivery)}`}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn classNameContainer={'text-base'} title='Precio' />,
        selector: row => `${row.data.total}`,
        reorder: false,
        conditionalCellStyles: [
          {
            when: row => row.data.dateEndDisplayBoard === '',
            classNames: ['cell-succes']
          }
        ],
        cell: (row, rowIndex, column) => (
          <TableCellMolecule
            classNameContent={'text-base'}
            value={`${numberAtPrice(row.data.total)}`}
            isEditable={false}
            {...{ rowIndex, column }}
          />
        )
      },
      {
        name: <FilterHeaderColumn classNameContainer={'text-base'} title='Detalle' />,
        selector: () => ``,
        reorder: false,
        conditionalCellStyles: [
          {
            when: row => row.data.dateEndDisplayBoard === '',
            classNames: ['cell-succes']
          }
        ],
        cell: row =>
          row.data.dateStartDisplayBoard ? (
            <div className='flex flex-1 justify-center items-center'>
              <IconCustom name={NameIcons.visibility} className='text-xl text-grayarauco-400' onClick={() => onDetail(row)} />
            </div>
          ) : null
      },
      {
        name: <FilterHeaderColumn classNameContainer={'text-base'} title='Archivo' />,
        selector: () => ``,
        reorder: false,
        conditionalCellStyles: [
          {
            when: row => row.data.dateEndDisplayBoard === '',
            classNames: ['cell-succes']
          }
        ],
        omit: notDownload,
        cell: row =>
          row.data.dateStartDisplayBoard ? (
            <div className='flex flex-1 justify-center items-center'>
              <IconCustom name={NameIcons.download} className='text-xl text-grayarauco-400' onClick={() => onDownload(row)} />
            </div>
          ) : null
      }
    ];
  }

  async download() {
    try {
      const { url } = this.data;

      if (url) {
        await downloadFileByURL({ url });
      }
      return { status: true };
    } catch (err) {
      console.log('download() ==> err', err);
      return { status: false };
    }
  }
}

export default Invoice;
