import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import BoardStore from 'components/molecules/board/Store';
import { StoreBoardFilterParams } from 'components/molecules/filters/StoreBoard';

import Country from 'models/Country';
import Delivery from 'models/Delivery';
import Store, { StoreModel } from 'models/store';
import StoreJanis from 'models/store/StoreJanis';
import SaleChannel from 'models/store/SaleChannel';
import DeliverySchedule from 'models/delivery/DeliverySchedule';

type CreateStoreOrganismProps = {
  onCreateStore: (store: Store) => void;
  onUpdateStore: (store: Store) => void;
  onDeletedStore: (store: Store) => void;
  onCancelStore: () => void;
  countries: Country[];
  storesJanis: StoreJanis[];
  salesChannels: SaleChannel[];
  onChangeForm: (store: StoreModel) => void;
  typesDelivery: Delivery[];
  values?: StoreModel;
  stores: Store[];
  onFilter?: (params: StoreBoardFilterParams) => void;
  onUpdateSchedules?: (schedules: DeliverySchedule[]) => void;
  schedules?: DeliverySchedule[];
};

const CreateStoreOrganism = ({
  countries,
  onCreateStore,
  onDeletedStore,
  onUpdateStore,
  onCancelStore,
  storesJanis,
  salesChannels,
  typesDelivery,
  values,
  onChangeForm,
  stores,
  onFilter,
  onUpdateSchedules,
  schedules
}: CreateStoreOrganismProps) => {
  return (
    <MenuSessionContainer notStyleContainer>
      <BoardStore
        {...{
          values,
          stores,
          storesJanis,
          countries,
          salesChannels,
          typesDelivery,
          onCreateStore,
          onDeletedStore,
          onUpdateStore,
          onCancelStore,
          onChangeForm,
          onFilter,
          onUpdateSchedules,
          schedules
        }}
      />
    </MenuSessionContainer>
  );
};

export default CreateStoreOrganism;
