import { ReactNode } from 'react';

import { EnumFileUploadStates, FormatDate } from 'configs/Enums';

import { getDateNow } from 'helpers/Generator';

export interface FileOrdersModel {
  id?: string;
  /** ordenes correctas **/
  correct?: number;
  /** ordenes fallidas **/
  failed?: number;
  nameFile?: string;
  /** nombre con todo y fecha **/
  nameFileFull?: string;
  status?: EnumFileUploadStates;
  type?: 'upload' | 'download' | 'error';
  description?: ReactNode;
}

class FileOrders {
  data: FileOrdersModel;

  constructor(data: FileOrdersModel) {
    this.data = data;
    if (data && data.nameFileFull) {
      this.data.nameFile = FileOrders.getOnlyName(data.nameFileFull);
    }

    if (data && data.id) {
      this.data.id = data.id;
    } else {
      this.data.id = data.nameFileFull;
    }
  }

  static generateFileNameWithDate(file: File) {
    try {
      const indexExtFile = file.name.lastIndexOf('.');
      if (indexExtFile !== -1) {
        const extFile = file.name.slice(indexExtFile);
        if (file.name.indexOf(extFile) !== -1) {
          return `${file.name.replace(extFile, `(${getDateNow({ format: FormatDate.yyyymmddHHmmssAlt })})${extFile}`)}`;
        }
      }
      return `${file.name}(${getDateNow({ format: FormatDate.yyyymmddHHmmssAlt })})`;
    } catch (err) {
      console.log('generateFileNameWithDate() ==> err', err);
      return file.name;
    }
  }

  static getOnlyName(fullName?: string) {
    let indexLast = fullName?.indexOf(').xls');

    if (indexLast === -1) {
      indexLast = fullName?.indexOf(').xlsx');
    }

    const indexFirst = fullName?.lastIndexOf('(');
    const fragDate = fullName?.slice(indexFirst, (indexLast || 0) + 1) || '';

    return `${fullName?.replace(fragDate, '')}`;
  }

  static reducerType(filesOrders?: FileOrders[]) {
    let newPrev: { error: FileOrders[]; upload: FileOrders[]; download: FileOrders[] } = { error: [], upload: [], download: [] };
    if (filesOrders && filesOrders.length > 0) {
      if (filesOrders.length > 1) {
        newPrev = (filesOrders as any).reduce((prev: any, curr: any) => {
          if (prev.data) {
            const type = prev.data.type as 'download' | 'error' | 'upload';
            newPrev[`${type}`] = [prev];
          } else {
            newPrev = prev;
          }
          if (curr.data.type === 'upload') {
            newPrev.upload = newPrev.upload && newPrev.upload.length > 0 ? [...newPrev.upload, curr] : [curr];
            return newPrev;
          }
          if (curr.data.type === 'download') {
            newPrev.download = newPrev.download && newPrev.download.length > 0 ? [...newPrev.download, curr] : [curr];
            return newPrev;
          }
          if (curr.data.type === 'error') {
            newPrev.error = newPrev.error && newPrev.error.length > 0 ? [...newPrev.error, curr] : [curr];
            return newPrev;
          }
          return newPrev;
        });
      } else {
        const type = filesOrders[0].data.type as 'download' | 'error' | 'upload';
        newPrev[`${type}`] = [filesOrders[0]];
      }
    }
    console.log('reduceType() => { filesOrders, newPrev }', { filesOrders, newPrev });
    return newPrev;
  }
}

export default FileOrders;
