import { NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import { DataTableOrder } from 'models/order';

type CellOrderProps = {
  order?: DataTableOrder;
  ignoreError?: boolean;
};

const CellOrder = ({ order }: CellOrderProps) => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center py-2 pl-8'>
      <div className='flex flex-col justify-center border-r border-grayaraucoborder pr-4 h-full'>
        <h3 className='text-base font-bold text-black'>{order?.order?.order}</h3>

        {order && order?.order?.creationDate && order?.order?.creationTime && (
          <div className='flex flex-col mt-2'>
            <label className='text-xs text-primary font-normal mb-1'>Fecha ingreso</label>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row items-center mr-10'>
                <p className='text-xs text-grayarauco'>
                  <IconCustom name={NameIcons.calendarMonth} className='text-xs mr-1' />
                  {order?.order?.creationDate}
                </p>
              </div>

              <div className='flex flex-row items-center'>
                <p className='text-xs text-grayarauco'>
                  <IconCustom name={NameIcons.schedule} className='text-xs mr-1' />
                  {order?.order?.creationTime}
                </p>
              </div>
            </div>
          </div>
        )}

        {order && (
          <div className='flex flex-col mt-2'>
            <label className='text-xs text-primary font-normal mb-1'>
              Fecha {order?.order?.deliveredDate !== '' ? 'entrega' : 'estimada'}
            </label>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row items-center'>
                <p className='text-xs text-grayarauco'>
                  <IconCustom name={NameIcons.calendarMonth} className='text-xs mr-1' />
                  {order?.order?.deliveredDate || order?.order?.estimatedDate || ''}
                </p>
              </div>

              <div className='flex flex-row items-center'>
                <p className='text-xs text-grayarauco'>
                  <IconCustom name={NameIcons.schedule} className='text-xs mr-1' />
                  {order?.order?.deliveredTime || order?.order?.estimatedTime || ''}
                </p>
              </div>
            </div>
          </div>
        )}

        {/**
        {order && (
          <div className='flex flex-col mt-2'>
            <label className='text-xs text-primary font-normal mb-1'>Bodega</label>
            <div className='flex flex-row justify-between'>
              <p className='text-xs text-grayarauco'>
                <IconCustom name={NameIcons.warehouse} className='text-xs mr-1' />
                {order?.order?.warehouse || ''}
              </p>
            </div>
          </div>
        )}
        **/}
      </div>
    </div>
  );
};

export default CellOrder;
