import axios from 'axios';

import { ResponseApp } from 'configs/Interface';

export const handleErrorServiceApi = async (e: Error): Promise<ResponseApp> => {
  try {
    if (axios.isAxiosError(e)) {
      console.log('handleErrorServiceApi ===> ', { response: e.response, e });
      if (e.response) {
        if (e.response.status === 200) {
          return { status: true, message: '' };
        }
        //if (e.response.status === 401) {
        //const message = await onLogout();
        //CLog(URL_PATH, 'handleErrorServiceApi()', 'message', message);
        //return { status: false, message: '401' };
        //}
        if (e.response.data) {
          if (e.response.data.token) {
            return { status: false, data: e.response.data };
          }
          if (e.response.data.msg) {
            return { status: false, message: e.response.data.msg, data: e.response.data.error };
          }
          if (e.response.data.message) {
            if (typeof e.response.data.message === 'string' && e.response.data.message.toLowerCase().indexOf('jsonwebtokenerror') !== -1) {
              return { status: false, message: 'La sesión ha caducado.' };
            }
            if (e.response.data.message.es) {
              return { status: false, data: e.response.data.message.es };
            }
            return { status: false, message: e.response.data.message };
          }
          if (e.response.data.messagge) {
            return { status: false, message: e.response.data.messagge };
          }
          if (e.response.status === 404) {
            return { status: false, message: '404' };
          }
        } else if (e.response.statusText) {
          return { status: false, message: e.response.statusText };
        }
        return { status: false, message: 'Error al hacer la solicitud.' };
      } else {
        return { status: false, message: 'Error al hacer la solicitud.' };
      }
    } else {
      return { status: false, message: e.message || 'Error al hacer la solicitud.' };
    }
  } catch (err) {
    console.log('handleErrorServiceApi() ==> err', err);
    return { status: false, message: 'Error al hacer la solicitud.' };
  }
};
