export interface OrderCancelModel {
  items?: { name?: string; value?: number; color?: string }[];
  total?: number;
  totalCancel?: number;
}

export interface OrderCancelServerModel {
  values: {
    canceled?: number;
    creates?: number;
    total?: number;
  };
  percent: {
    canceled?: number;
    creates?: number;
    total?: number;
  };
}

class OrderCancel {
  data: OrderCancelModel;

  constructor(data?: OrderCancelModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = { items: [], total: 0 };
    }
  }

  static formalizeData(data: any) {
    try {
      return {
        items: [
          { name: 'Órdenes canceladas', value: data.canceledOrders, color: '#00379B' },
          { name: 'Órdenes no canceladas', value: data.unCanceledOrders, color: '#C4C4C4' }
        ],
        total: data.canceledOrders + data.unCanceledOrders,
        totalCancel: data.canceledOrders
      };
    } catch (err) {
      return { items: [], total: 0 };
    }
  }

  getOrderCanceled() {
    const { items } = this.data;

    if (items && items.length > 0) {
      return items[0].value || 0;
    }
  }
}

export default OrderCancel;
