import { ItemGraph } from './Common';

interface PerformanceStatisticModel {
  levelOfService: number;
  levelOfServiceMonth: ItemGraph[];
  levelOfServiceWeek: ItemGraph[];
  averageDeliveryMonth: ItemGraph[];
  averageDeliveryWeek: ItemGraph[];
}

class PerformanceStatistic {
  data: PerformanceStatisticModel;

  constructor(data?: PerformanceStatisticModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = { levelOfService: 0, levelOfServiceMonth: [], levelOfServiceWeek: [], averageDeliveryMonth: [], averageDeliveryWeek: [] };
    }
  }

  static formalizeData(data: any): PerformanceStatisticModel {
    const levelOfService = data.level_of_service || 0;
    const levelOfServiceMonth: ItemGraph[] = [];
    const levelOfServiceWeek: ItemGraph[] = [];
    const averageDeliveryMonth: ItemGraph[] = [];
    const averageDeliveryWeek: ItemGraph[] = [];

    if (data.level_of_service_month && data.level_of_service_month.length > 0) {
      data.level_of_service_month.forEach((item: any) => {
        levelOfServiceMonth.push({ name: item.name, value: item.value });
      });
    }
    if (data.level_of_service_week && data.level_of_service_week.length > 0) {
      data.level_of_service_week.forEach((item: any) => {
        levelOfServiceWeek.push({ date: item.name, value: item.value });
      });
    }
    if (data.average_delivery_month && data.average_delivery_month.length > 0) {
      data.average_delivery_month.forEach((item: any) => {
        averageDeliveryMonth.push({ name: item.name, value: item.value });
      });
    }
    if (data.average_delivery_week && data.average_delivery_week.length > 0) {
      data.average_delivery_week.forEach((item: any) => {
        averageDeliveryWeek.push({ date: item.name, value: item.value });
      });
    }

    return {
      levelOfService,
      levelOfServiceMonth: levelOfServiceMonth.reverse(),
      levelOfServiceWeek: levelOfServiceWeek.reverse(),
      averageDeliveryMonth: averageDeliveryMonth.reverse(),
      averageDeliveryWeek: averageDeliveryWeek.reverse()
    };
  }
}

export default PerformanceStatistic;
