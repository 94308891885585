import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'context/AuthContext';

import SelectionButton from 'components/atoms/selects/SelectionButton';

import { capitalize } from 'helpers/Converts';

import Store from 'models/store';

type BrandSelectProps = {
  onChange: (brands?: Store[]) => void;
  storesNews?: Store[];
  value?: Store[];
};

const BrandSelect = ({ onChange, storesNews, value }: BrandSelectProps) => {
  const { stores } = useContext(AuthContext);

  const [storesCurrent, setStoresCurrent] = useState<Store[]>([]);
  const [valueCurrent, setValueCurrent] = useState<Store[]>([]);

  useEffect(() => {
    if (storesNews) {
      setStoresCurrent(storesNews);
    } else {
      setStoresCurrent(stores);
    }
    if (value) {
      setValueCurrent(value);
    }
  }, [storesNews, stores, value]);

  return (
    <SelectionButton
      classNameContainer='flex flex-col w-full sm:w-auto'
      text='Marca'
      items={
        storesCurrent?.map(sto => ({
          label: capitalize(sto.data.brand) || '',
          labelSearch: sto.data.brand || '',
          value: sto.toString() || '',
          item: sto
        })) || []
      }
      getPopupContainer={(triggerNode: any) => {
        //console.log('getPopupContainer() => triggerNode', triggerNode);
        return triggerNode;
      }}
      values={valueCurrent}
      onChange={val => {
        setValueCurrent(val);
        onChange(valueCurrent);
      }}
      onClose={() => {
        onChange(valueCurrent);
      }}
    />
  );
};

export default BrandSelect;
