import { useEffect, useState, useContext } from 'react';

import { RootContext } from 'context/RootContext';

import FrequentQuestionList from 'components/organisms/dashboard/questions/FrequentQuestionList';

import FrequentQuestion from 'models/questions/FrequentQuestion';
import CategoryQuestion from 'models/questions/Category';
import HelpCenterResource from 'models/questions/Resource';

import { getAllFrequentQuestions, getAllInformations, getCategoriesQuestions, getSource } from 'services/app/Question';

const FrequentQuestionListPage = () => {
  const { showLoading, hideLoading } = useContext(RootContext);

  const [frequentQuestions, setFrequentQuestions] = useState<FrequentQuestion[]>([]);
  const [informations, setInformations] = useState<FrequentQuestion[]>([]);
  const [categories, setCategories] = useState<CategoryQuestion[]>([]);
  const [source, setSource] = useState<HelpCenterResource>();

  const loadAllFrequentQuestions = async (categoriesCurrent?: CategoryQuestion[]) => {
    showLoading();
    try {
      const catCurrent = categoriesCurrent || categories;
      const { status, data } = await getAllFrequentQuestions();
      console.log('loadAllFrequentQuestions() => { status, data }', { status, data });
      if (status) {
        const fqNew = data as FrequentQuestion[];
        fqNew.forEach(fq => (fq.data.categoryDisplay = catCurrent.find(cat => cat.data.id === fq.data.category)?.data.name));
        setFrequentQuestions(data);
      }
    } catch (err) {
      console.log('loadAllFrequentQuestions() => err', err);
    }
    hideLoading();
  };

  const loadAllInformations = async () => {
    showLoading();
    try {
      const { status, data } = await getAllInformations();
      if (status) {
        setInformations(data);
      }
    } catch (err) {
      console.log('loadAllInformations() => err', err);
    }
    hideLoading();
  };

  const loadAllCategories = async () => {
    showLoading();
    try {
      const { status, data } = await getCategoriesQuestions();
      if (status) {
        setCategories(data);
        loadAllFrequentQuestions(data);
      }
    } catch (err) {
      console.log('loadAllCategories() => err', err);
    }
    hideLoading();
  };

  const loadSource = async () => {
    showLoading();
    try {
      const { status, data } = await getSource();
      if (status) {
        setSource(data);
      }
    } catch (err) {
      console.log('loadAllCategories() => err', err);
    }
    hideLoading();
  };

  useEffect(() => {
    loadAllCategories();
    loadAllInformations();
    loadSource();
  }, []);

  return <FrequentQuestionList {...{ informations, frequentQuestions, categories, source }} />;
};

export default FrequentQuestionListPage;
