import { forwardRef, useState, useEffect, CSSProperties } from 'react';
import moment, { Moment } from 'moment';

import { DatePicker, Tooltip } from 'antd';
import { CustomFormat, PanelMode } from 'rc-picker/lib/interface';
//import { Moment } from 'moment';

import { FormatDate, StyleButton, UnitDate } from 'configs/Enums';
import { IS_MOBILE } from 'configs/Constants';
//import moment from 'configs/MomentJS';

import LabelCustom from 'components/atoms/labels';
import InputCustom from '../inputs';

import { stringAtDate } from 'helpers/Converts';
import ButtonCustom from '../buttons';
import { subtractTime } from 'helpers/Generator';

const { RangePicker } = DatePicker;

type DatePickerCustomProps = {
  isRange?: boolean;
  className?: string;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  format?: string | CustomFormat<any> | (string | CustomFormat<any>)[];
  onChangeRange?: (values: { startDate?: string; endDate?: string }) => void;
  onChange?: (value?: string) => void;
  value?: any;
  placeholder?: string | [string, string];
  label?: string;
  classNameLabel?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  messageErrorTooltip?: string;
  isRequired?: boolean;
  disabledDate?: (date: any) => boolean;
  hide?: boolean;
  classNameContainer?: string;
  styleLabel?: CSSProperties | undefined;
  renderExtraFooter?: (mode: PanelMode) => React.ReactNode;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
};

enum EnumButtonDateInterval {
  today = 'today',
  thisMonth = 'thisMonth',
  month = 'month',
  trimester = 'trimester'
}

const FooterDatePicker = ({
  value,
  format,
  onChange
}: {
  value: { startDate?: string; endDate?: string };
  format?: string | FormatDate;
  onChange?: (values: any, formatString: [string, string]) => void;
}) => {
  const [buttonDateInterval, setButtonDateInterval] = useState<EnumButtonDateInterval | undefined>();

  const onChangeNew = (enumB: EnumButtonDateInterval) => {
    if (enumB === EnumButtonDateInterval.today) {
      //value: any, formatString: [string, string]
      const today = moment();
      if (onChange) {
        onChange([today, today], [today.format(format), today.format(format)]);
      }
    } else if (enumB === EnumButtonDateInterval.thisMonth) {
      let startMes = moment();
      startMes = moment(`${startMes.year()}-${startMes.month() + 1}-01`);
      if (onChange) {
        onChange([startMes, moment()], [startMes.format(format), moment().format(format)]);
      }
    } else if (enumB === EnumButtonDateInterval.month) {
      const dateNow = moment();
      const dateStart = subtractTime({ unit: UnitDate.months, amount: 1, format: FormatDate.none, baseDate: moment(dateNow) });
      console.log('onChangeNew() ==> { dateNow, dateStart }', { dateNow, dateStart });
      if (onChange) {
        onChange([dateStart, dateNow], [(dateStart as Moment)?.format(format), dateNow.format(format)]);
      }
    } else if (enumB === EnumButtonDateInterval.trimester) {
      const dateNow = moment();
      const dateStart = subtractTime({ unit: UnitDate.months, amount: 3, format: FormatDate.none, baseDate: moment(dateNow) });
      console.log('onChangeNew() ==> { dateNow, dateStart }', { dateNow, dateStart });
      if (onChange) {
        onChange([dateStart, dateNow], [(dateStart as Moment)?.format(format), dateNow.format(format)]);
      }
    }
  };

  useEffect(() => {
    let bDI: EnumButtonDateInterval | undefined;
    if (value && value.endDate && value.startDate) {
      const dateNow = moment().format('YYYY-MM-DD');
      const monthStart = moment().format('YYYY-MM');
      const subtractMonth = subtractTime({ unit: UnitDate.months, amount: 1, format: FormatDate.none, baseDate: moment(dateNow) });
      const subtractMonthThree = subtractTime({ unit: UnitDate.months, amount: 3, format: FormatDate.none, baseDate: moment(dateNow) });

      if (moment(`${dateNow} 23:59:59`).isSameOrAfter(value.endDate) && moment(`${dateNow} 00:00:00`).isSameOrBefore(value.startDate)) {
        bDI = EnumButtonDateInterval.today;
      } else if (moment(`${dateNow} 00:00:00`).isSame(value.endDate) && moment(`${monthStart}-01 00:00:00`).isSame(value.startDate)) {
        bDI = EnumButtonDateInterval.thisMonth;
      } else if (moment(`${dateNow} 00:00:00`).isSame(value.endDate) && (subtractMonth as Moment)?.isSame(value.startDate)) {
        bDI = EnumButtonDateInterval.month;
      } else if (moment(`${dateNow} 00:00:00`).isSame(value.endDate) && (subtractMonthThree as Moment)?.isSame(value.startDate)) {
        bDI = EnumButtonDateInterval.trimester;
      }
    }

    setButtonDateInterval(bDI);
  }, [value]);

  return (
    <div className='border-t py-2 flex flex-row gap-2'>
      <ButtonCustom
        onClick={() => {
          onChangeNew(EnumButtonDateInterval.today);
        }}
        styleButton={buttonDateInterval === EnumButtonDateInterval.today ? StyleButton.bgPrimary : StyleButton.outlinePrimary}
      >
        Hoy
      </ButtonCustom>
      <ButtonCustom
        onClick={() => onChangeNew(EnumButtonDateInterval.thisMonth)}
        styleButton={buttonDateInterval === EnumButtonDateInterval.thisMonth ? StyleButton.bgPrimary : StyleButton.outlinePrimary}
      >
        Este mes
      </ButtonCustom>
      <ButtonCustom
        onClick={() => onChangeNew(EnumButtonDateInterval.month)}
        styleButton={buttonDateInterval === EnumButtonDateInterval.month ? StyleButton.bgPrimary : StyleButton.outlinePrimary}
      >
        Mes
      </ButtonCustom>
      <ButtonCustom
        onClick={() => onChangeNew(EnumButtonDateInterval.trimester)}
        styleButton={buttonDateInterval === EnumButtonDateInterval.trimester ? StyleButton.bgPrimary : StyleButton.outlinePrimary}
      >
        Trimestre
      </ButtonCustom>
    </div>
  );
};

const DatePickerCustom = forwardRef(
  (
    {
      picker = 'date',
      format = FormatDate.yyyymmdd,
      isRange,
      onChange,
      className,
      onChangeRange,
      value,
      placeholder,
      label,
      classNameLabel,
      onFocus,
      onBlur,
      onClick,
      messageErrorTooltip,
      isRequired,
      disabledDate,
      hide,
      classNameContainer,
      styleLabel,
      renderExtraFooter,
      getPopupContainer
    }: DatePickerCustomProps,
    ref: any
  ) => {
    const [openPicker, setOpenPicker] = useState<boolean>();
    const [messageError, setMessageError] = useState<string>();

    const handleChangeRange = (value: any, formatString: [string, string]) => {
      //console.log('handleChangeRange() ==>', { value, formatString });
      if (onChangeRange && value && value.length > 0) {
        if (value && value.length > 0) {
          onChangeRange({ startDate: formatString[0], endDate: formatString[1] });
        } else {
          onChangeRange({ startDate: undefined, endDate: undefined });
        }
      }
    };

    const handleChange = (_: any, formatString: string) => {
      //console.log('handleChange() ==>', { value, formatString });
      if (isRequired && (formatString === '' || formatString === undefined)) {
        setMessageError('El campo es requerido.');
      } else if (onChange && formatString) {
        if (formatString) {
          onChange(formatString);
        } else {
          onChange('');
        }
        setMessageError(undefined);
      }
    };

    const handleFocus = () => {
      setOpenPicker(true);
      if (onFocus) {
        onFocus();
      }
    };

    const handleBlur = () => {
      setOpenPicker(false);
      if (onBlur) {
        onBlur();
      }
    };

    const handleClick = () => {
      setOpenPicker(true);
      if (onClick) {
        onClick();
      }
    };

    useEffect(() => {
      setMessageError(messageErrorTooltip);
      //console.log(IS_MOBILE);
    }, [messageErrorTooltip]);

    if (isRange) {
      return (
        <div hidden={hide} className={classNameContainer}>
          {label && (
            <LabelCustom className={`mb-2 ${classNameLabel}`}>
              <div style={styleLabel}>{label}</div>
            </LabelCustom>
          )}
          <RangePicker
            placeholder={['Fecha de inicio', 'Fecha de fin']}
            className={`text-gray-700 font-bold w-full ${className}`}
            size='large'
            onChange={handleChangeRange}
            renderExtraFooter={
              renderExtraFooter ||
              (() => {
                return (
                  <FooterDatePicker
                    value={{
                      startDate: value && value.length >= 1 ? value[0] : undefined,
                      endDate: value && value.length >= 1 ? value[1] : undefined
                    }}
                    format={format as FormatDate}
                    onChange={handleChangeRange}
                  />
                );
              })
            }
            {...{ picker, format, value, ref }}
          />
        </div>
      );
    }
    return (
      <div hidden={hide} className={classNameContainer}>
        {label && (
          <LabelCustom className={`mb-2 ${classNameLabel}`}>
            <div style={styleLabel}>{label}</div>
            {isRequired && <span className='text-red-400'> *</span>}
          </LabelCustom>
        )}
        <Tooltip
          visible={!!messageError}
          trigger={['hover', 'focus']}
          title={messageErrorTooltip || messageError}
          placement='top'
          overlayClassName='ant-tooltip-content-error'
          overlayInnerStyle={{ backgroundColor: 'red' }}
        >
          {IS_MOBILE && (
            <InputCustom
              type='date'
              onChange={event => {
                handleChange(null, stringAtDate(event.target.value, format as FormatDate) || '');
              }}
              {...{ ref }}
            />
          )}
          {!IS_MOBILE && (
            <DatePicker
              placeholder={placeholder && typeof placeholder === 'string' ? placeholder : 'Seleccione la fecha'}
              className={`text-gray-700 font-bold w-full ${className} ${messageError ? 'ant-input-error' : ''}`}
              size='large'
              onChange={handleChange}
              open={openPicker}
              onFocus={() => handleFocus()}
              onBlur={() => handleBlur()}
              onClick={() => handleClick()}
              //value={moment(value)}
              {...{ picker, format, ref, disabledDate, renderExtraFooter, getPopupContainer }}
            />
          )}
        </Tooltip>
      </div>
    );
  }
);

export default DatePickerCustom;
