import { useState, useContext, useRef, useEffect } from 'react';
import validate from 'validate.js';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import { APP_NAME, APP_VERSION, VALIDATE_CONSTRAINTS } from 'configs/Constants';
import { NameIcons, PathRoute, SizeScreen, TypeAlertMessage } from 'configs/Enums';
import { ResponseApp } from 'configs/Interface';

import InputCustom from 'components/atoms/inputs';
import LabelCustom from 'components/atoms/labels';
import AlertMessage from 'components/molecules/messages/Alert';
import ButtonCustom from 'components/atoms/buttons';
import LayoutContainerAuth from 'components/molecules/layout/ContainerAuth';
import IconCustom from 'components/atoms/icons';
import ClearCacheButton from 'components/atoms/buttons/ClearCacheButton';

type NewPasswordFormMoleculeProps = {
  handleNewPassword?(password: string): Promise<ResponseApp>;
  isUpdatePassword?: boolean;
  months?: number;
  expiredDate?: string;
};

const NUM_CHARACTERS = 10;

const AnimationCheck = ({ isCheck }: { isCheck?: boolean }) => {
  const refSvg = useRef<SVGSVGElement>(null);
  const refCicle = useRef<SVGCircleElement>(null);
  const refPath = useRef<SVGPathElement>(null);

  const [unCheck, setUnCheck] = useState(true);

  useEffect(() => {
    if (isCheck) {
      setUnCheck(false);
      refSvg.current?.setAttribute('class', 'checkmark');
      refPath.current?.setAttribute('class', 'checkmark__check');
      refCicle.current?.setAttribute('class', 'checkmark__circle');
    } else {
      refSvg.current?.setAttribute('class', 'checkmark_inverse');
      refPath.current?.setAttribute('class', 'checkmark__check_inverse');
      refCicle.current?.setAttribute('class', 'checkmark__circle_inverse');
    }
  }, [isCheck]);

  return (
    <div>
      <div
        className='flex justify-center items-center'
        style={unCheck && !isCheck ? { width: 0, height: 0 } : { width: 35, height: 35, paddingBottom: 5 }}
      >
        <div style={{ width: 20, height: 20 }}>
          <svg
            ref={refSvg}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 52 52'
            onAnimationEnd={event => {
              if (!isCheck && event.animationName === 'scaleCheckInverse') {
                console.log('onAnimationEnd() => event', event);
                setUnCheck(true);
              } else {
                setUnCheck(false);
              }
            }}
          >
            <circle ref={refCicle} cx='26' cy='26' r='25' fill='none' />
            <path ref={refPath} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
          </svg>
        </div>
      </div>
      {unCheck && (
        <div className='flex justify-center items-center' style={{ width: 35, height: 35 }}>
          <IconCustom name={NameIcons.checkCircle} className='text-grayarauco-300' />
        </div>
      )}
    </div>
  );
};

const NewPasswordFormMolecule = ({ handleNewPassword, isUpdatePassword, months, expiredDate }: NewPasswordFormMoleculeProps) => {
  const { updateTokenAccessAndLogin } = useContext(AuthContext);
  const { screenResolution } = useContext(RootContext);

  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [messageError, setMessageError] = useState<string>();
  const [isContinuous, setIsContinuous] = useState(false);
  const [dateValidity, setDateValidity] = useState('');
  const [tokenAccess, setTokenAccess] = useState<string>();
  const navigate = useNavigate();

  const isValidate = ({ type }: { type: 1 | 2 | 3 | 4 }) => {
    if (type === 1) {
      const validateResult = validate.single(password, VALIDATE_CONSTRAINTS.containsUppercaseLowercase);
      if (!validateResult && password) {
        return true;
      }
    } else if (type === 2) {
      const validateResult = validate.single(password, VALIDATE_CONSTRAINTS.containsNumber);
      if (!validateResult && password) {
        return true;
      }
    } else if (type === 3) {
      const validateResult = validate.single(password, VALIDATE_CONSTRAINTS.containsSpecialCharacters);
      if (!validateResult && password) {
        return true;
      }
    } else if (type === 4) {
      if ((password?.length || 0) >= NUM_CHARACTERS) {
        return true;
      }
    }
    return false;
  };

  const disabledButton = () => {
    if ((!password && !confirmPassword) || (password === '' && confirmPassword === '')) {
      return true;
    }
    if (isValidate({ type: 1 }) && isValidate({ type: 2 }) && isValidate({ type: 3 }) && isValidate({ type: 4 })) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setMessageError('Las contraseñas no coinciden, vuelve a intentarlo.');
    } else if (!disabledButton() && handleNewPassword) {
      const { status, message, data } = await handleNewPassword(password || '');
      //console.log('handleSubmit() => { status, message }', { status, message, data });
      if (status) {
        setMessageError(undefined);
        if (data && isUpdatePassword) {
          setIsContinuous(status);
          setDateValidity(data.validity);
          setTokenAccess(data.token);
        }
      } else {
        if ('Las contraseñas no pueden ser iguales' === data) {
          setMessageError('Esta contraseña ya fue utilizada, crea una nueva contraseña ');
        } else {
          setMessageError(message || data);
        }
      }
    }
  };

  console.log('NewPassword() => { screenResolution }', { screenResolution, validationResult: screenResolution <= SizeScreen.md });
  return (
    <LayoutContainerAuth
      notStyleForm
      classNameForm={'flex flex-row justify-center items-center'}
      classNameTop={'h-screen overflow-auto'}
      onSubmit={handleSubmit}
      noLegend={(isContinuous && isUpdatePassword) || screenResolution <= SizeScreen.md}
      topComponent={
        isUpdatePassword && !isContinuous ? (
          <AlertMessage
            className={'m-4'}
            type={TypeAlertMessage.warning}
            featuredText={
              <div>
                <h3 className='text-xl font-bold text-yellow-600'>Actualiza tu contraseña</h3>
                <p className='text-black text-base font-normal'>
                  El {expiredDate} venció el plazo para actualizar tu contraseña de acceso a Arauco Hub, actualiza tu contraseña para poder
                  ingresar, recuerda que por nuestra política de seguridad, te solicitaremos actualizar tu contraseña cada {months} meses.
                </p>
              </div>
            }
          />
        ) : undefined
      }
    >
      {!isContinuous && (
        <div className='flex' style={{ flexDirection: screenResolution <= SizeScreen.md ? 'column' : 'row' }}>
          <div
            className='bg-white shadow-lg rounded-md pt-6 pb-8 mb-4'
            style={{ paddingLeft: screenResolution <= SizeScreen.md ? 38 : 48, paddingRight: screenResolution <= SizeScreen.md ? 38 : 48 }}
          >
            {isUpdatePassword && (
              <div className='mb-7'>
                <h3 className={'text-lg font-bold'}>Actualización contraseña Arauco Hub</h3>
              </div>
            )}
            <div className='mb-4'>
              <LabelCustom className='mb-2 text-base' style={{ fontWeight: 'normal' }}>
                Nueva contraseña
              </LabelCustom>
              <InputCustom
                type='password'
                className='rounded-xl'
                classNameContainerInput='mb-3'
                onChange={event => {
                  setMessageError(undefined);
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className='mb-6'>
              <LabelCustom className='mb-2 text-base' style={{ fontWeight: 'normal' }}>
                Confirmar nueva contraseña
              </LabelCustom>
              <InputCustom
                type='password'
                className='rounded-xl'
                classNameContainerInput={`${messageError ? 'mb-2' : 'mb-6'}`}
                onChange={event => {
                  setMessageError(undefined);
                  setConfirmPassword(event.target.value);
                }}
              />
            </div>

            {messageError && <AlertMessage className='mb-8' text={messageError} type={TypeAlertMessage.danger} />}

            <div className='flex items-center justify-center'>
              <ButtonCustom
                disabled={disabledButton()}
                className={`font-bold w-4/5 md:w-auto ${
                  !disabledButton() && password !== confirmPassword
                    ? 'bg-grayarauco-300 border-0 border-transparent hover:border-grayarauco-300 hover:bg-grayarauco-300 focus:bg-grayarauco-300'
                    : ''
                }`}
                htmlType='submit'
              >
                Cambiar contraseña
              </ButtonCustom>
            </div>
          </div>

          {screenResolution <= SizeScreen.md && (
            <p className='text-left text-gray-500 text-base'>
              &copy;{new Date().getFullYear()} {APP_NAME}. <ClearCacheButton>v{APP_VERSION}</ClearCacheButton>
            </p>
          )}

          <div
            className='flex flex-col justify-center items-center'
            style={{ marginLeft: screenResolution <= SizeScreen.md ? 0 : 16, marginTop: screenResolution <= SizeScreen.md ? 40 : 0 }}
          >
            <div className='flex flex-row items-center w-full'>
              <AnimationCheck isCheck={isValidate({ type: 1 })} />
              <p className='text-base font-normal'>Usa Mayúsculas/Minúsculas</p>
            </div>

            <div className='mt-4 flex flex-row items-center w-full'>
              <AnimationCheck isCheck={isValidate({ type: 2 })} />
              <p className='text-base font-normal'>Uso de números</p>
            </div>

            <div className='mt-4 flex flex-row items-center w-full'>
              <AnimationCheck isCheck={isValidate({ type: 3 })} />
              <p className='text-base font-normal'>Uso de signos (ej: .¡!@#$%^&*+-_)</p>
            </div>

            <div className='mt-4 flex flex-row items-center w-full'>
              <AnimationCheck isCheck={isValidate({ type: 4 })} />
              <p className='text-base font-normal'>Mínimo {NUM_CHARACTERS} caracteres</p>
            </div>
          </div>
        </div>
      )}
      {isContinuous && isUpdatePassword && (
        <div className='flex justify-center items-center flex-col self-center'>
          <div className='flex justify-center items-center'>
            <p className='text-lg font-normal text-black'>¡Tu contraseña ha sido cambiada con éxito!</p>
          </div>

          <div className='my-12 flex flex justify-center items-center'>
            <p className='text-lg font-bold text-black text-center'>
              Vigencia nueva contraseña <br /> Hasta el {dateValidity}
            </p>
          </div>

          <div className='flex justify-center items-center'>
            <button
              className='text-lg font-bold text-araucoblue-200 underline'
              onClick={() => {
                updateTokenAccessAndLogin({ token: tokenAccess });
                navigate(PathRoute.welcome);
              }}
            >
              Acceder a Arauco Hub
            </button>
          </div>
        </div>
      )}
    </LayoutContainerAuth>
  );
};

export default NewPasswordFormMolecule;
