import { AxiosResponse } from 'axios';
import api from '.';

import { ResponseApp } from 'configs/Interface';

import { handleErrorServiceApi } from 'helpers/Errors';

import User from 'models/User';

class UserProvider {
  async me(): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get('users/me');
    } catch (e) {
      console.log('me api ===>', e);
      //return { status: false };
      return handleErrorServiceApi(e as Error);
    }
  }

  /** información como el delivery, el país y otros datos del usuario **/
  async getData(): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get('users/data');
    } catch (e) {
      return handleErrorServiceApi(e as Error);
    }
  }

  async getUsers(): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().get(`users`);
    } catch (e) {
      return handleErrorServiceApi(e as Error);
    }
  }

  async createUser(user: User): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().post('users', user.sendServer());
    } catch (e) {
      return handleErrorServiceApi(e as Error);
    }
  }

  async deleteUserById(user: User): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().delete(`users/${user.data.id}`);
    } catch (e) {
      return handleErrorServiceApi(e as Error);
    }
  }

  async updateUserById(user: User): Promise<AxiosResponse | ResponseApp> {
    try {
      return await api().put(`users/${user.data.id}`, user.sendServer());
    } catch (e) {
      return handleErrorServiceApi(e as Error);
    }
  }

  async getRoles(): Promise<AxiosResponse | ResponseApp> {
    try {
      return api().get('roles');
    } catch (e) {
      return handleErrorServiceApi(e as Error);
    }
  }
}

export default new UserProvider();
