import { useContext, useEffect, useState } from 'react';
import { Skeleton } from 'antd';

import { RootContext } from 'context/RootContext';

import { EnumStatusDelivery, NameIcons, SizeScreen } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import ShippingStatus from 'models/delivery/ShippingStatus';

type ShippingStatusTimeLineMoleculesProps = {
  shippingsStatus?: ShippingStatus[];
};

const ShippingStatusItemsMolecules = ({ shippingStatus, lastElement }: { shippingStatus: ShippingStatus; lastElement: boolean }) => {
  const { screenResolution } = useContext(RootContext);

  const [isChecked, setIsChecked] = useState(false);

  const validateIcon = () => {
    if (shippingStatus.data.isCanceled) {
      return NameIcons.close;
    } else if (!shippingStatus.data.inProgress && isChecked) {
      return NameIcons.done;
    } else {
      return shippingStatus.data.icon;
    }
  };

  const validateStyles = ({ type }: { type: 'border' | 'text' | 'bg' }) => {
    if (shippingStatus.data.isCanceled) {
      if (type === 'border') {
        return 'border-grayarauco-300';
      } else if (type === 'text') {
        return 'text-grayarauco-400 font-normal';
      } else if (type === 'bg') {
        return 'bg-grayarauco-300';
      }
    } else if (shippingStatus.data.nameDisplay === EnumStatusDelivery.notDelivered) {
      if (type === 'border') {
        return 'border-alternativeone';
      } else if (type === 'text') {
        return 'text-alternativeone font-semibold';
      } else if (type === 'bg') {
        return 'bg-alternativeone';
      }
    } else if (shippingStatus.data.inProgress) {
      if (type === 'border') {
        return 'border-araucoblue-200';
      } else if (type === 'text') {
        return 'text-araucoblue-200 font-semibold';
      } else if (type === 'bg') {
        return 'bg-araucoblue-200';
      }
    } else if (isChecked && !shippingStatus.data.isCanceled) {
      if (type === 'border') {
        return 'border-araucogreen';
      } else if (type === 'text') {
        return 'text-araucogreen font-semibold';
      } else if (type === 'bg') {
        return 'bg-araucogreen';
      }
    } else {
      if (type === 'border') {
        return 'border-grayarauco-300';
      } else if (type === 'text') {
        return 'text-grayarauco-400 font-normal';
      } else if (type === 'bg') {
        return 'bg-grayarauco-300';
      }
    }
  };

  useEffect(() => {
    if (shippingStatus && shippingStatus.data.dateCreated) {
      setIsChecked(true);
    }
  }, [shippingStatus]);

  return (
    <div className='flex justify-start items-start md:justify-center md:items-center flex-1'>
      {screenResolution > SizeScreen.md && (
        <div className='flex flex-1 flex-col'>
          <div className='flex flex-row justify-center items-center'>
            <div className='flex flex-1 flex-col'>
              <h1 className={`text-sm text-center text-transparent select-none`}>{shippingStatus.data.nameDisplay}</h1>
              <div className={`flex flex-1 border-t-4 h-1 ${validateStyles({ type: 'border' })}`} />
              <h1 className={`text-sm text-center ${validateStyles({ type: 'text' })}`}>{shippingStatus.data.nameDisplay}</h1>
            </div>
            {shippingStatus.data.isCanceled && (
              <div className={`flex rounded-full w-9 h-9 justify-center items-center bg-grayarauco-300`}>
                <IconCustom name={NameIcons.close} className={`text-3xl leading-7 text-white`} />
              </div>
            )}
            {!shippingStatus.data.isCanceled && (
              <div className={`flex rounded-full w-9 h-9 justify-center items-center ${validateStyles({ type: 'bg' })}`}>
                <IconCustom
                  name={isChecked && !shippingStatus.data.inProgress ? NameIcons.done : shippingStatus.data.icon}
                  className={`${isChecked && !shippingStatus.data.inProgress ? 'text-3xl leading-7' : 'text-2xl leading-6'} text-white`}
                />
              </div>
            )}
          </div>

          <div className='flex flex-1'>
            <div className='flex flex-1 justify-between items-center text-grayarauco-400'>
              {shippingStatus.data.dateCreated && (
                <div className='flex items-center'>
                  <IconCustom name={NameIcons.calendarMonth} className='text-xs text-grayarauco-400' />
                  <p className='ml-1'>{shippingStatus.data.dateCreated}</p>
                </div>
              )}
              {shippingStatus.data.timeCreated && (
                <div className='flex items-center ml-2'>
                  <IconCustom name={NameIcons.schedule} className='text-xs text-grayarauco-400' />
                  <p className='ml-1'>{shippingStatus.data.timeCreated}</p>
                </div>
              )}
            </div>

            <div className={`flex rounded-full w-9 h-9 justify-center items-center`}>
              <IconCustom
                name={isChecked ? NameIcons.done : shippingStatus.data.icon}
                className={`text-3xl leading-7 text-white select-none`}
              />
            </div>
          </div>
        </div>
      )}

      {screenResolution <= SizeScreen.md && (
        <div className='flex flex-row'>
          <div className='flex flex-col justify-center items-center'>
            <div className={`flex rounded-full w-9 h-9 justify-center items-center ${validateStyles({ type: 'bg' })}`}>
              <IconCustom name={validateIcon()} className='text-white' />
            </div>

            {!lastElement && (
              <div className='flex justify-center items-center flex-1'>
                <div className={`flex flex-1 h-5 border-l-2 ${validateStyles({ type: 'border' })}`} />
              </div>
            )}
          </div>

          <div className='ml-2'>
            <h1 className={`text-sm text-left mt-2 ${validateStyles({ type: 'text' })}`}>{shippingStatus.data.nameDisplay}</h1>

            <div className='flex flex-1 justify-between items-center text-grayarauco-400 mt-1'>
              {shippingStatus.data.dateCreated && (
                <div className='flex items-center'>
                  <IconCustom name={NameIcons.calendarMonth} className='text-xs text-grayarauco-400' />
                  <p className='ml-1'>{shippingStatus.data.dateCreated}</p>
                </div>
              )}

              {shippingStatus.data.timeCreated && (
                <div className='flex items-center ml-2'>
                  <IconCustom name={NameIcons.schedule} className='text-xs text-grayarauco-400' />
                  <p className='ml-1'>{shippingStatus.data.timeCreated}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ShippingStatusTimeLineMolecules = ({ shippingsStatus }: ShippingStatusTimeLineMoleculesProps) => {
  console.log('shippingsStatus => shippingsStatus', shippingsStatus);
  return (
    <div className='flex flex-col flex-1 md:flex-row'>
      {shippingsStatus &&
        shippingsStatus.map((shippingStatus, index) => {
          return (
            <ShippingStatusItemsMolecules
              key={shippingStatus.data.name}
              lastElement={index === shippingsStatus.length - 1}
              {...{ shippingStatus }}
            />
          );
        })}

      {!shippingsStatus && (
        <div className='flex flex-1 w-full'>
          <div className='flex flex-row flex-1'>
            <div className='flex flex-col flex-1'>
              <p className='select-none text-transparent'>text</p>
              <div className='border-t-4 h-1 w-full' />
              <p className='select-none text-transparent'>text</p>
            </div>
            <Skeleton.Avatar className='mt-2' active size='small' shape='circle' />
          </div>

          <div className='flex flex-row flex-1'>
            <div className='flex flex-col flex-1'>
              <p className='select-none text-transparent'>text</p>
              <div className='border-t-4 h-1 w-full' />
              <p className='select-none text-transparent'>text</p>
            </div>
            <Skeleton.Avatar className='mt-2' active size='small' shape='circle' />
          </div>

          <div className='flex flex-row flex-1'>
            <div className='flex flex-col flex-1'>
              <p className='select-none text-transparent'>text</p>
              <div className='border-t-4 h-1 w-full' />
              <p className='select-none text-transparent'>text</p>
            </div>
            <Skeleton.Avatar className='mt-2' active size='small' shape='circle' />
          </div>

          <div className='flex flex-row flex-1'>
            <div className='flex flex-col flex-1'>
              <p className='select-none text-transparent'>text</p>
              <div className='border-t-4 h-1 w-full' />
              <p className='select-none text-transparent'>text</p>
            </div>
            <Skeleton.Avatar className='mt-2' active size='small' shape='circle' />
          </div>
        </div>
      )}
    </div>
  );
};

export default ShippingStatusTimeLineMolecules;
