import { useContext } from 'react';

import { Route, Routes } from 'react-router-dom';

import { PathRoute } from 'configs/Enums';
import { AuthContext } from 'context/AuthContext';
import RouterInProvider from 'context/RouterIn';

import ContainerMenuLayout from 'components/molecules/layout/ContainerMenuLayout';

import LoadingPage from 'pages/Loading';
import LoadingRedirect from 'pages/LoadingRedirect';
import UploadFilePage from 'pages/menu/UploadFile';
import ListOrdersPage from 'pages/menu/ListOrders';
import ListFailedOrdersPage from 'pages/menu/ListFailedOrders';
import DashboardPage from 'pages/menu/Dashboard';
import WelcomePage from 'pages/welcome';
import UnitManualLoadPage from 'pages/menu/UnitManualLoad';
import CreateUser from 'pages/menu/create/CreateUser';
import CreateStore from 'pages/menu/create/CreateStore';
import CutOffScheduleSettingPage from 'pages/menu/settings/CutOffSchedule';
import InvoicePage from 'pages/menu/Invoice';
import HandbookPage from 'pages/menu/Handbook';
import FrequentQuestionsPage from 'pages/menu/questions/FrequentQuestion';
import FrequentQuestionsListPage from 'pages/menu/questions/FrequentQuestionList';
import OrderAlertsSettingPage from 'pages/menu/settings/OrderAlerts';
import OrderDetailPage from 'pages/menu/order/Detail';
import NewPasswordPage from 'pages/auth/NewPassword';

const RoutersIn = () => {
  const { authStatus, storeSession } = useContext(AuthContext);

  return (
    <RouterInProvider>
      <Routes>
        <Route path='/' element={<LoadingPage />} />
        <Route path={PathRoute.menu} element={<ContainerMenuLayout />}>
          {authStatus.user?.enabledRoutesMenu(storeSession) &&
            authStatus.user?.enabledRoutesMenu(storeSession).map(route => {
              if (route === PathRoute.uploadFile) {
                return <Route key={route} path={route} element={<UploadFilePage />} />;
              } else if (route === PathRoute.unitManualLoad) {
                return <Route key={route} path={route} element={<UnitManualLoadPage />} />;
              } else if (route === PathRoute.listOrders) {
                return (
                  <Route key={route} path={route} element={<ListOrdersPage />}>
                    <Route path={':numOrder'} element={<ListOrdersPage />} />
                  </Route>
                );
              } else if (route === PathRoute.listFailedOrders) {
                return <Route key={route} path={route} element={<ListFailedOrdersPage />} />;
              } else if (route === PathRoute.dashboard) {
                return <Route key={route} path={route} element={<DashboardPage />} />;
              } else if (route === PathRoute.createUser) {
                return <Route key={route} path={route} element={<CreateUser />} />;
              } else if (route === PathRoute.createStore) {
                return <Route key={route} path={route} element={<CreateStore />} />;
              } else if (route === PathRoute.cutOffScheduleSetting) {
                return <Route key={route} path={route} element={<CutOffScheduleSettingPage title={'Políticas de envío'} />} />;
              } else if (route === PathRoute.billing) {
                return <Route key={route} path={route} element={<InvoicePage />} />;
              } else if (route === PathRoute.updateHandbook) {
                return <Route key={route} path={route} element={<HandbookPage />} />;
              } else if (route === PathRoute.frequentQuestions) {
                return <Route key={route} path={route} element={<FrequentQuestionsPage />} />;
              } else if (route === PathRoute.frequentQuestionsList) {
                return <Route key={route} path={route} element={<FrequentQuestionsListPage />} />;
              } else if (route === PathRoute.orderAlertSettings) {
                return <Route key={route} path={route} element={<OrderAlertsSettingPage title='Límites de horarios' />} />;
              } else if (route === PathRoute.orderDetail) {
                return (
                  <Route key={route} path={route} element={<OrderDetailPage />}>
                    <Route path={':orderId'} element={<OrderDetailPage />} />
                  </Route>
                );
              } else if (route === PathRoute.welcome) {
                return <Route key={route} path={route} element={<WelcomePage />} />;
              } else {
                return null;
              }
            })}
        </Route>
        <Route path={PathRoute.frequentQuestionsList} element={<FrequentQuestionsListPage />} />;
        <Route path={PathRoute.signin} element={<LoadingRedirect />} />
        <Route path={PathRoute.signup} element={<LoadingRedirect />} />
        <Route path={PathRoute.reconverPassword} element={<LoadingRedirect />} />
        <Route path={PathRoute.newPassword} element={<NewPasswordPage />} />
        <Route path='*' element={<LoadingRedirect />} />
      </Routes>
    </RouterInProvider>
  );
};

export default RoutersIn;
