import { ReactNode, useEffect, useRef, useState } from 'react';
import { TableColumn } from 'react-data-table-component';

import { FormatDate, FormatTime } from 'configs/Enums';
import moment from 'configs/MomentJS';

import InputCustom from 'components/atoms/inputs';

import { isParameterInvalid, removeFlag } from 'helpers/Validate';
import SelectCustom from 'components/atoms/selects';
import DatePickerCustom from 'components/atoms/pickers/DatePicker';
import TimePickerCustom from 'components/atoms/pickers/TimePicker';

type TableCellMoleculeProps = {
  value?: string | number;
  validateConstraints?: any;
  column?: TableColumn<any>;
  rowIndex?: number;
  isRequired?: boolean;
  isEditable?: boolean;
  onChange?: any;
  onOpenUpdate?: () => void;
  type?:
    | 'select'
    | 'select-add'
    | 'selectMultiple'
    | 'text'
    | 'password'
    | 'number'
    | 'date'
    | 'time'
    | 'date-time'
    | 'email'
    | 'slider'
    | 'duration'
    | 'boolean'
    | 'checkbox'
    | 'custom';
  data?: { value: string | number; label?: string | ReactNode; disabled?: boolean; item?: any }[];
  children?: ReactNode;
  classNameContainer?: string;
  classNameContent?: string;
  disabledStyleContainerD?: boolean;
  placeholder?: string;
};

const TableCellMolecule = ({
  value,
  validateConstraints,
  isRequired,
  isEditable = true,
  onChange,
  onOpenUpdate,
  type = 'text',
  data,
  children,
  classNameContainer,
  classNameContent,
  disabledStyleContainerD,
  placeholder
}: TableCellMoleculeProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const refTimeInput = useRef<HTMLInputElement>(null);

  const [isInvalid, setIsInvalid] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [valueCurrent, setValueCurrent] = useState('');
  const [isError, setIsError] = useState<boolean>();
  const [valueOne, setValueOne] = useState('');
  const [valueTwo, setValueTwo] = useState('');

  const onBlur = () => {
    setIsUpdate(false);
    onUpdate(false);
  };

  const onUpdate = (val?: boolean) => {
    //console.log('onLOad() ==>', refInput.current);
    setIsUpdate(val || false);
    if (onOpenUpdate) {
      onOpenUpdate();
    }
  };

  useEffect(() => {
    if (isRequired && (value === undefined || value === '')) {
      setIsInvalid(true);
      //onChange('Error: El campo es requerido.');
    } else {
      setIsInvalid(isParameterInvalid(`${value}` || ''));
    }
    if (type === 'date-time') {
      const valueSplit = (value as string).split(' ');
      if (valueSplit.length > 1) {
        const valueOne = valueSplit[0];
        const valueTwo = valueSplit[1];

        if (moment.isMoment(valueOne)) {
          setValueOne(valueOne);
        }
        if (moment.isMoment(valueTwo)) {
          setValueTwo(valueTwo);
        }
      }
    }
  }, []);

  return (
    <div
      className={
        disabledStyleContainerD
          ? `${classNameContainer}`
          : `flex h-full p-2 ${
              isError === false ? 'bg-red-600 text-white' : isError === true ? 'bg-green-600 text-white' : ''
            } w-full justify-center items-center ${classNameContainer}`
      }
    >
      {value && isInvalid && (
        <div
          onClick={() => {
            if (isEditable) {
              onUpdate(true);
            }
          }}
        >
          {isUpdate && (
            <div>
              {(type === 'text' || type === 'number') && (
                <InputCustom
                  ref={refInput}
                  value={valueCurrent}
                  onChange={event => {
                    setValueCurrent(event.target.value);
                  }}
                  onLoad={() => {
                    refInput && refInput.current !== null ? refInput.current.focus() : undefined;
                  }}
                  onValidate={(isVal, val) => {
                    setIsError(isVal);
                    if (isVal && onChange) {
                      onChange(val.target.value);
                    }
                  }}
                  {...{ onBlur, validateConstraints, isRequired, placeholder }}
                />
              )}
              {type === 'select' && (
                <SelectCustom
                  style={{ width: 'auto', minWidth: 100 }}
                  data={data || []}
                  value={valueCurrent}
                  onChange={value => {
                    setValueCurrent(value);
                    onChange(value);
                  }}
                  onValidate={isVal => {
                    setIsError(isVal);
                  }}
                  {...{ onBlur, isRequired, placeholder }}
                />
              )}
              {type === 'date-time' && (
                <div className='flex flex-row'>
                  <DatePickerCustom
                    value={valueOne}
                    className='flex-2'
                    format={FormatDate.ddmmaaaaAlt}
                    onChange={value => {
                      setValueOne(value || '');
                      onChange(`${value}${valueTwo ? ` ${valueTwo}` : ''}`);
                      if (refTimeInput && refTimeInput.current) {
                        refTimeInput.current.focus();
                      }
                      if (value) {
                        setIsError(true);
                      } else {
                        setIsError(false);
                      }
                    }}
                    {...{ placeholder }}
                  />
                  <TimePickerCustom
                    value={valueTwo}
                    ref={refTimeInput}
                    className='flex-1'
                    format={FormatTime.HHmm}
                    onChange={value => {
                      setValueTwo(value);
                      //console.log('onChange() => TimePickerCustom => value', value);
                      onChange(`${valueOne ? `${valueOne} ` : ''}${value}`);
                      if (refTimeInput && refTimeInput.current) {
                        refTimeInput.current.blur();
                      }
                      if (value) {
                        setIsError(true);
                      } else {
                        setIsError(false);
                      }
                    }}
                    {...{ placeholder }}
                  />
                </div>
              )}
            </div>
          )}
          {!isUpdate && <div>{removeFlag(value)}</div>}
        </div>
      )}
      {value && !isInvalid && <div className={classNameContent}>{value}</div>}
      {children}
    </div>
  );
};

export default TableCellMolecule;
