import React, { ReactNode, useContext, useEffect, useState } from 'react';

//import { AuthContext } from './AuthContext';

import WarehousePA from 'models/warehouse';
import DownloadFile from 'models/file/DownloadFile';

//import UserRole from 'models/user/UserRole';
import Store from 'models/store';
//import Country from 'models/Country';
import FileOrders from 'models/files/FileOrders';
//import Dimension from 'models/Dimension';
//import Delivery from 'models/Delivery';
import TypeOperator from 'models/store/TypeOperator';

import { getTypesOperator, getWarehouse } from 'services/app/Store';
import { RootContext } from './RootContext';
import { AuthContext } from './AuthContext';

export interface DownloadFiles {
  template?: DownloadFile;
  handbook?: DownloadFile;
  handbookUnit?: DownloadFile;
}

export interface DataContextValues {
  warehouse: WarehousePA[];
  onUpdateWarehouse: () => void;
  onUpdateStores: (stores?: Store[]) => void;
  addUploadFile: (fileOrders: FileOrders) => void;
  updateUploadFile: (fileOrders: FileOrders) => void;
  deletedUploadFile: (fileOrders: FileOrders) => void;
  deletedsUploadFile: (fileOrders: FileOrders[]) => void;

  //flagUpdateNotification: number;
  //typesDelivery: Delivery[];
  //commerceId?: string;
  //downloadFiles?: DownloadFiles;
  //roles?: UserRole[];
  //countryUser?: string;
  //countries?: Country[];
  //deliveryStates: string[];
  //loadAllDownloadFiles: () => void;
  //numFailedOrders: number;
  //onUpdateNumFailedOrders: (val?: number) => void;
  //dimensions: Dimension[];
  //typesDocument: DocumentType[];
  //onUpdateStoreSession: (store?: Store) => void;
  //storeSession?: Store [>* funcion que agrega una card cuando se carga un archivo y se espera que se procese *<];
  //onUpdateCountryUser: (value?: string) => void;
  onUpdateNotification: () => void;
  stores: Store[];
  filesOrders: FileOrders[];
  filesOrdersFlag: number;
  /** los tipos de operadores de tienda (etailer, tienda, dummy) **/
  typesOperator: TypeOperator[];
  onUpdateTypeOperator: () => void;
}

export const DataContext = React.createContext({} as DataContextValues);

const DataContextProvider = ({ children }: { children: ReactNode }) => {
  const { countryUser, countries } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(RootContext);

  const [warehouse, setWarehouse] = useState<WarehousePA[]>([]);
  const [filesOrders, setFilesOrders] = useState<FileOrders[]>([]);
  const [filesOrdersFlag, setFilesOrdersFlag] = useState(0);
  const [stores, setStores] = useState<Store[]>([]);
  const [typesOperator, setTypesOperator] = useState<TypeOperator[]>([]);
  const [flagUpdateNotification, setFlagUpdateNotification] = useState<number>(0);

  const onUpdateNotification = () => {
    setFlagUpdateNotification(flagUpdateNotification + 1);
  };

  const onUpdateWarehouse = async () => {
    const { status, data } = await getWarehouse({ country: countryUser || '', countries: countries || [] });
    console.log('onUpdateWarehouse() ==> { status, data }', { status, data });

    if (status) {
      setWarehouse(data);
    } else {
      setWarehouse([]);
    }
  };

  const onUpdateTypeOperator = async () => {
    showLoading();
    const { data, status } = await getTypesOperator();
    console.log('onUpdateTypeOperator() ==> { data, status }', { data, status });

    if (status) {
      setTypesOperator(data);
    } else {
      setTypesOperator([]);
    }

    hideLoading();
  };

  const onUpdateStores = (storesNew?: Store[]) => {
    try {
      //console.log('onUpdateStores() =>', storesNew);
      setStores(storesNew || []);
    } catch (err) {
      console.log('onUpdateStores() =>', err);
    }
  };

  /**
   * carga de archivos
   */
  const addUploadFile = (fileOrdersAdd: FileOrders) => {
    setFilesOrders([...filesOrders, fileOrdersAdd]);
  };

  const updateUploadFile = (fileOrdersUpdate: FileOrders) => {
    console.log('updateUploadFile() => { filesOrders }', { filesOrders });
    filesOrders.forEach(fileOrders => {
      if (fileOrders.data.id === fileOrdersUpdate.data.id) {
        fileOrders.data = { ...fileOrdersUpdate.data, nameFile: fileOrders.data.nameFile };
      }
    });
    console.log('updateUploadFile() ==> { fileOrdersUpdate, fileOrdersUpdate }', { fileOrdersUpdate, filesOrders });
    setFilesOrders(filesOrders);
    setFilesOrdersFlag(filesOrdersFlag + 1);
  };

  const deletedUploadFile = (fileOrdersUpdate: FileOrders) => {
    const findOrdersUpdate = filesOrders.findIndex(fileOrders => fileOrders.data.id === fileOrdersUpdate.data.id);
    console.log('deletedUploadFile() => { filesOrders }', { filesOrders });
    if (findOrdersUpdate !== -1) {
      filesOrders.splice(findOrdersUpdate, 1);
    }
    console.log('deletedUploadFile() ==> { fileOrdersUpdate, findOrdersUpdate, filesOrders }', {
      fileOrdersUpdate,
      findOrdersUpdate,
      filesOrders
    });
    setFilesOrders([...filesOrders] || []);
  };

  const deletedsUploadFile = (fileOrdersUpdate: FileOrders[]) => {
    const fileO = filesOrders.filter(n => !fileOrdersUpdate.includes(n));

    console.log('deletedUploadFile() ==> fileO', fileO);
    setFilesOrders([...fileO] || []);
  };

  useEffect(() => {
    onUpdateWarehouse();
  }, [countryUser]);

  return (
    <DataContext.Provider
      value={{
        warehouse,
        onUpdateWarehouse,
        addUploadFile,
        updateUploadFile,
        deletedUploadFile,
        deletedsUploadFile,
        onUpdateNotification,
        typesOperator,
        onUpdateTypeOperator,
        stores,
        onUpdateStores,
        filesOrders,
        filesOrdersFlag
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
