import { useState } from 'react';

import { NameIcons } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';

import FrequentQuestion from 'models/questions/FrequentQuestion';
import FrequentQuestionCard from './FrequentQuestion';

type FrequentQuestionBoardProps = {
  fq: FrequentQuestion;
  onUpdated: (fq: FrequentQuestion) => void;
  onDeleted: (fq: FrequentQuestion) => void;
};

const FrequentQuestionBoard = ({ fq, onUpdated, onDeleted }: FrequentQuestionBoardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div key={fq.data.id} className='flex flex-col'>
      <div className={`flex p-4 bg-white ${fq.data.category && fq.data.category !== '' ? 'flex-col' : 'flex-row justify-between'}`}>
        <div className='flex items-center'>
          <h1 className={`text-xs font-bold`}>{fq.data.question}</h1>
        </div>

        {fq.data.category && fq.data.category !== '' && (
          <div className='flex flex-col mt-4 mb-3'>
            <p className='text-xs font-normal'>Categoria</p>
            <p className='text-sm font-normal'>{fq.data.categoryDisplay}</p>
          </div>
        )}

        <div className='flex flex-row gap-4 justify-end'>
          <IconCustom name={NameIcons.rename} className='text-xl text-grayarauco-400' onClick={() => onUpdated(fq)} />
          <IconCustom name={NameIcons.deleted} className='text-xl text-grayarauco-400' onClick={() => onDeleted(fq)} />
          <IconCustom
            name={isExpanded ? NameIcons.arrowUp : NameIcons.arrowDown}
            className='text-xl text-grayarauco-400'
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
      </div>

      {isExpanded && (
        <div className='bg-gray-100 p-4'>
          <FrequentQuestionCard frequentQuestion={fq} />
        </div>
      )}
    </div>
  );
};

export default FrequentQuestionBoard;
