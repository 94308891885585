export type DeliveryObject = { [index: string]: number };

export interface DeliveryItem {
  name?: string;
  type?: string;
  value?: number;
}

export interface OrderByStoreItemModel {
  store?: string;
  delivery?: DeliveryItem[];
  total?: number;
}

export interface OrderByStoreModel {
  items?: OrderByStoreItemModel[];
  total?: number;
}

export type OrderByStoreServerModel = {
  tienda?: string;
  delivery?: DeliveryObject;
}[];

class OrderByStore {
  data: OrderByStoreModel;

  constructor(data: OrderByStoreModel) {
    this.data = data;
  }

  static formalizeData(data: OrderByStoreServerModel) {
    let items: OrderByStoreItemModel[] = [];

    if (data && data.length > 0) {
      items = data.map(da => {
        let delivery: DeliveryItem[] = [];
        if (da.delivery) {
          delivery = Object.entries(da.delivery).map(([key, val]) => ({ name: key, value: val }));
        }
        return { store: da.tienda, delivery, total: delivery.map(deli => deli.value).reduce((prev, curr) => (prev || 0) + (curr || 0)) };
      });
    }

    //console.log('formalizeData() => data', { data, items });

    const total = items?.map(sF => sF.total).reduce((prev, curr) => (prev || 0) + (curr || 0));
    return { items, total };
  }

  getGraphicBar() {
    const { items } = this.data;

    if (items && items.length > 0) {
      const storesFormat = items
        .map(item => ({ name: item.store, value: item.total }))
        .sort((a: any, b: any) => {
          if (a.value > b.value) {
            return -1;
          } else if (a.value < b.value) {
            return 1;
          }
          return 0;
        });

      const firstFive = storesFormat.splice(0, 5);
      let otherStore: any;

      if (storesFormat && storesFormat.length > 0) {
        const totalOther = storesFormat.map(sFormat => sFormat.value).reduce((prev, curr) => (prev || 0) + (curr || 0));
        otherStore = { name: 'Otros', value: totalOther };
        return [...firstFive, otherStore];
      }

      return firstFive;
    }

    return undefined;
  }
}

export default OrderByStore;
