import { useContext } from 'react';

import { RootContext } from 'context/RootContext';
import { EnumInvoiceSection, SizeScreen } from 'configs/Enums';

import BillingHeaderMolecule from 'components/molecules/headers/BillingHeader';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import BillingFilterMolecule, { FilterBillingParams } from 'components/molecules/filters/BillingFilter';

import Invoice from 'models/invoice';
import InvoiceBoard from 'components/molecules/board/Invoice';
import ViewGeneralBill from 'components/molecules/cards/bill/ViewGeneral';
import ModalCustom from 'components/molecules/modals';

type InvoiceOrganismProps = {
  bills: Invoice[];
  billCurrent?: Invoice;
  onFilter: (params?: FilterBillingParams) => void;
  onDownload: (val: Invoice) => void;
  onChangeSeccion: (section: EnumInvoiceSection) => void;
  section: EnumInvoiceSection;
  onLoadDetail: (params: FilterBillingParams) => void;
  updateDetail: (val?: Invoice) => void;
  billDetail?: Invoice;
  paramsFilter?: FilterBillingParams;
};

const InvoiceOrganism = ({
  onFilter,
  onDownload,
  onLoadDetail,
  updateDetail,
  billDetail,
  onChangeSeccion,
  section,
  billCurrent,
  bills,
  paramsFilter
}: InvoiceOrganismProps) => {
  const { screenResolution } = useContext(RootContext);

  const onDetailCurrent = (bill: Invoice) => {
    onLoadDetail({ invoice: bill });
  };

  return (
    <MenuSessionContainer notStyleContainer classNameContainer=''>
      <BillingHeaderMolecule classNameContainer='px-5 sm:px-8 pt-6' {...{ onChangeSeccion, section }} />
      <BillingFilterMolecule
        classNameContainer='px-5 sm:px-8 pb-4'
        dateEnd={billCurrent?.data.dateEndDisplay}
        dateStart={billCurrent?.data.dateStartDisplay}
        {...{ onFilter, section, paramsFilter }}
      />

      {section === EnumInvoiceSection.billingProgress && <ViewGeneralBill classNameContainer={'mx-5 sm:mx-8'} bill={billCurrent} />}

      {section === EnumInvoiceSection.historicalBilling && (
        <div className='flex flex-col mx-5 sm:mx-8'>
          <InvoiceBoard
            data={bills}
            onDetail={onDetailCurrent}
            notDownload={(paramsFilter?.store as any) !== 'Todas'}
            {...{ onDownload }}
          />
        </div>
      )}

      {billDetail && (
        <ModalCustom
          isVisible={billDetail ? true : false}
          styleContent={{ right: screenResolution > SizeScreen.md ? 40 : 0, padding: 0 }}
          onClose={() => updateDetail(undefined)}
        >
          <div className='max-h-screen overflow-scroll'>
            <ViewGeneralBill
              bill={billDetail}
              classNameContent='mx-4 md:mx-10'
              showAlert={false}
              showInterval
              onClose={() => updateDetail(undefined)}
              {...{ paramsFilter }}
            />
          </div>
        </ModalCustom>
      )}
    </MenuSessionContainer>
  );
};

export default InvoiceOrganism;
