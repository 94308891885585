import { shippingTypeStringToDisplay } from 'helpers/Converts';

export interface ShippingModel {
  currency: string;
  purchasedQuantity: string;
  purchasePrice: string;
  type: string;
  typeName?: string;
  items: string;
  size?: string;
  warehouse?: string;
}

export interface ShippingModelServer {
  currency: string;
  purchasedQuantity: string;
  purchasePrice: string;
  type: string;
  typeName?: string;
  items: string;
  size?: string;
  warehouse?: string;
}

class Shipping {
  data: ShippingModel;

  constructor(data: ShippingModel) {
    this.data = data;
  }

  static formalizeData(data: ShippingModelServer): ShippingModel {
    //console.log('formalizeData ===> shipping ===> data ', data);
    const { currency, purchasedQuantity, purchasePrice, type, items, size, warehouse } = data;

    return {
      currency,
      purchasePrice,
      purchasedQuantity,
      type,
      typeName: shippingTypeStringToDisplay(type),
      items,
      size,
      warehouse
    };
  }

  static createVoid(): Shipping {
    return new Shipping({
      currency: '',
      purchasePrice: '',
      purchasedQuantity: '',
      type: '',
      typeName: '',
      items: '',
      size: '',
      warehouse: ''
    });
  }
}

export default Shipping;
