import { useState, useEffect, useContext } from 'react';
import { Badge, Checkbox, Tooltip } from 'antd';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import { VALIDATE_CONSTRAINTS } from 'configs/Constants';
import { NameIcons, SizeScreen, TypeDelivery } from 'configs/Enums';

import IconCustom from 'components/atoms/icons';
import InputCustom from 'components/atoms/inputs';

import Order, { EnumFielOrder, OrderError } from 'models/order';

import { getCitiesByTypeDelivery, getDistrictsByCity } from 'services/app/District';
import SelectCustom from 'components/atoms/selects';

type FailedOrderMoleculesProps = {
  order: Order;
  index?: number;
  onDetail: (order: Order) => void;
  onDetailCorrect: (order: Order) => void;
  onUpdateCorrectedOrders: () => void;
  onDeleted?: (order: Order) => void;
  flag?: number;
  onUpdateFlag?: () => void;
  disabledCorrect?: boolean;
  messageTooltip?: string;
};

const FailedOrderItemMolecules = ({
  error,
  order,
  cities,
  districts,
  onUpdateErrorCounter,
  disabledCorrect,
  messageTooltip
}: {
  error: OrderError;
  order: Order;
  cities: string[];
  districts: string[];
  onUpdateErrorCounter: ({ error }: { error: OrderError }) => void;
  disabledCorrect?: boolean;
  messageTooltip?: string;
}) => {
  const { typesDelivery, typesDocument, dimensions } = useContext(AuthContext);
  const { warehouse } = useContext(DataContext);
  const { screenResolution } = useContext(RootContext);

  const [isValid, setIsValid] = useState(error.isValid);
  const [dataInput, setDataInput] = useState<any>();
  const [valueCurrent, setValueCurrent] = useState<any>(error.correctedValue);

  const loadDataInput = async () => {
    if (error.field === EnumFielOrder.city) {
      setDataInput(cities.map(city => ({ value: city, item: city, label: city })) || []);
    } else if (error.field === EnumFielOrder.documentType) {
      setDataInput(typesDocument.map(typeDoc => ({ value: typeDoc.data.value, item: typeDoc.data.value, label: typeDoc.data.name })));
    } else if (error.field === EnumFielOrder.typeShipphing) {
      setDataInput(
        typesDelivery.map(typeDelivery => ({
          value: typeDelivery.data.value,
          item: typeDelivery.data.value,
          label: typeDelivery.data.name
        }))
      );
    } else if (error.field === EnumFielOrder.size) {
      setDataInput(dimensions.map(dim => ({ value: dim.data.value, item: dim.data.value, label: dim.data.name })));
    } else if (error.field === EnumFielOrder.district) {
      setDataInput(districts.map(district => ({ value: district, item: district, label: district })));
    } else if (error.field === EnumFielOrder.warehouse) {
      setDataInput(warehouse.map(wareH => ({ value: wareH.data.id, item: wareH.data.id, label: wareH.data.description })));
    }
  };

  const onValidateConstraint = () => {
    //console.log('onValidateConstraint() => error', { error, order });
    if (error.field === EnumFielOrder.numberDocument) {
      return VALIDATE_CONSTRAINTS.documentNum(
        order.data.detail.data.customer.data.documentType,
        order.data.detail.data.addresses.data.country
      );
    }

    return error.constraints;
  };

  useEffect(() => {
    loadDataInput();
  }, [error, cities, districts, dimensions, warehouse]);

  return (
    <div className='flex flex-1 flex-col md:flex-row border-b border-grayaraucoborder bg-gray-100' key={error.name}>
      <div className='flex flex-1 flex-col md:flex-row mx-0 md:mx-4 md:my-4 justify-center items-center'>
        {screenResolution <= SizeScreen.md && (
          <div className='flex w-full bg-white px-7 py-2'>
            <h1 className='text-base font-normal'>Columna</h1>
          </div>
        )}

        <h3 className='my-4 md:my-0'>{error.name?.toUpperCase()}</h3>
      </div>

      <div className='flex flex-1 flex-col md:flex-row mx-0 md:mx-4 md:my-4 justify-center items-center md:border-l md:border-r md:border-grayaraucoborder'>
        {screenResolution <= SizeScreen.md && (
          <div className='flex w-full bg-white px-7 py-2'>
            <h1 className='text-base font-normal'>Dato a corregir</h1>
          </div>
        )}

        <div className='flex justify-center items-center flex-col'>
          <h3 className='text-center text-redarauco my-4 md:my-0'>{error.message}</h3>
          {Order.isMessageValue(`${error.value}`) && (
            <p className='text-base font-normal text-black'>{Order.getMessageValue(`${error.value}`)}</p>
          )}
        </div>
      </div>

      <div className='flex flex-1 flex-col md:flex-row mx-0 md:mx-4 md:my-4 justify-center items-center'>
        {screenResolution <= SizeScreen.md && (
          <div className='flex w-full bg-white px-7 py-2'>
            <h1 className='text-base font-normal'>Ingresar nuevo dato</h1>
          </div>
        )}

        <Tooltip
          trigger={['hover', 'focus']}
          overlay={messageTooltip}
          placement='top'
          overlayClassName='ant-tooltip-content-black'
          overlayInnerStyle={{ backgroundColor: 'black' }}
        >
          <div className='flex flex-row my-4 md:my-0'>
            {error.input && error.input === 'select' && (
              <SelectCustom
                disabled={disabledCorrect}
                label={error.name}
                data={dataInput}
                value={valueCurrent}
                isRequired={error.isRequired}
                classNameContainer='flex flex-col justify-center'
                styleContainer={{ maxWidth: 232, minWidth: 232 }}
                style={{ width: '100%' }}
                onChange={value => {
                  const isValideCurrent = order.isValide({
                    value: value,
                    field: error.field,
                    cities,
                    order,
                    districts,
                    dimensions: dimensions.map(dimension => dimension.data.value),
                    typesDelivery,
                    typesDocument
                  });
                  setIsValid(isValideCurrent);
                  error.correctedValue = value;
                  onUpdateErrorCounter({ error });
                  setValueCurrent(value);
                }}
              />
            )}
            {(!error.input || error.input === 'text') && (
              <InputCustom
                label={error.name}
                disabled={disabledCorrect}
                value={valueCurrent}
                validateConstraints={onValidateConstraint()}
                isRequired={error.isRequired}
                onChange={e => {
                  const isValideCurrent = order.isValide({
                    value: e.target.value,
                    field: error.field,
                    cities,
                    order,
                    districts,
                    dimensions: dimensions.map(dimension => dimension.data.value),
                    typesDelivery,
                    typesDocument
                  });
                  setIsValid(isValideCurrent);
                  error.correctedValue = e.target.value;
                  onUpdateErrorCounter({ error });
                  setValueCurrent(e.target.value);
                }}
              />
            )}
            {screenResolution <= SizeScreen.md && (
              <div className='flex justify-center items-center px-4 mt-7'>
                <IconCustom
                  name={NameIcons.checkCircle}
                  outlined={isValid ? false : true}
                  className={`${isValid ? 'text-tertiary' : 'text-grayaraucoborder'}`}
                />
              </div>
            )}
          </div>
        </Tooltip>
      </div>

      {screenResolution > SizeScreen.md && (
        <div className='flex justify-center items-center px-4 border-dashed border-l border-grayaraucoborder my-2 red'>
          <IconCustom
            name={NameIcons.checkCircle}
            outlined={isValid ? false : true}
            className={`${isValid ? 'text-tertiary' : 'text-grayaraucoborder'}`}
          />
        </div>
      )}
    </div>
  );
};

const FailedOrderMolecules = ({
  order,
  index,
  onDetail,
  onDetailCorrect,
  onUpdateCorrectedOrders,
  flag,
  onUpdateFlag,
  disabledCorrect,
  messageTooltip
}: FailedOrderMoleculesProps) => {
  const { showLoading, hideLoading, screenResolution } = useContext(RootContext);

  const [cities, setCities] = useState<string[]>([]);
  const [districts, setDistricts] = useState<string[]>([]);
  const [numberErrors, setNumberErrors] = useState(0);
  const [isExpand, setIsExpand] = useState(false);
  const [isCheckedDeleted, setIsCheckedDeleted] = useState(false);

  const loadCity = async (typeDelivery: string) => {
    showLoading();
    const { status, data } = await getCitiesByTypeDelivery({
      typeDelivery,
      country: order.data.detail.data.addresses.data.country
    });

    if (status) {
      //console.log('loadCity() ==> {status, data}', { status, data });
      setCities(data as string[]);
    } else {
      setCities([]);
    }
    hideLoading();
  };

  const loadDistrict = async ({ typeDelivery, city }: { typeDelivery: string; city: string }) => {
    showLoading();
    const { status, data } = await getDistrictsByCity({ typeDelivery, city, country: order.data.detail.data.addresses.data.country });

    if (status) {
      //console.log('loadDistrict() ==> {status, data}', { status, data });
      setDistricts(data as string[]);
    } else {
      setDistricts([]);
    }
    hideLoading();
  };

  const onUpdateErrorCounter = async ({ error }: { error: OrderError }) => {
    if (error.field === EnumFielOrder.typeShipphing) {
      loadCity(`${error.correctedValue}`);
      order.data.errors?.forEach(err => {
        if (
          err.field === EnumFielOrder.district ||
          err.field === EnumFielOrder.streetNumber ||
          err.field === EnumFielOrder.city ||
          err.field === EnumFielOrder.adr
        ) {
          if (error.correctedValue === TypeDelivery.pickup || error.correctedValue === TypeDelivery.pickupStore) {
            err.hide = true;
            err.correctedValue = '';
            err.isValid = true;
          } else {
            err.hide = false;
            err.isValid = false;
          }
        }
      });
    } else if (error.field === EnumFielOrder.city) {
      const typeDelivery = order.data.errors?.find(err => err.field === EnumFielOrder.typeShipphing);
      loadDistrict({
        typeDelivery: typeDelivery ? `${typeDelivery.correctedValue}` : order.data.detail.data.shipphing.data.type,
        city: `${error.correctedValue}`
      });
    }

    const corrected = order.data.errors?.filter(error => error.isValid || error.hide);
    const failed = (order.data.errors?.length || 0) - (corrected?.length || 0);
    //console.log('onUpdateErrorCounter() => { corrected, failed, errors }', { corrected, failed, errors: order.data.errors });
    setNumberErrors(failed);
    onUpdateCorrectedOrders();
  };

  const loadData = async () => {
    const typeShipphingError = order.data.errors?.find(err => err.field === EnumFielOrder.typeShipphing);
    if (typeShipphingError && typeShipphingError?.correctedValue) {
      await loadCity(`${typeShipphingError.correctedValue}`);
      const cityError = order.data.errors?.find(err => err.field === EnumFielOrder.typeShipphing);
      if (cityError && cityError?.correctedValue) {
        await loadDistrict({ typeDelivery: `${typeShipphingError.correctedValue}`, city: `${cityError.correctedValue}` });
      } else if (!cityError) {
        await loadDistrict({
          typeDelivery: `${typeShipphingError.correctedValue}`,
          city: `${order.data.detail.data.addresses.data.state}`
        });
      }
    } else if (!typeShipphingError) {
      const typeShipphing = order.data.detail.data.shipphing.data.type;
      await loadCity(typeShipphing);
      const cityError = order.data.errors?.find(err => err.field === EnumFielOrder.typeShipphing);
      if (cityError && cityError?.correctedValue) {
        await loadDistrict({ typeDelivery: typeShipphing, city: `${cityError.correctedValue}` });
      } else if (!cityError) {
        await loadDistrict({
          typeDelivery: typeShipphing,
          city: `${order.data.detail.data.addresses.data.state}`
        });
      }
    }
  };

  useEffect(() => {
    if (order) {
      setNumberErrors(order.data.errors?.length || 0);
    }
    //order.onCheckedDeleted(isCheckedDeleted);
    setIsCheckedDeleted(order.data.isSelectDelete || false);
    console.log('useEffect() => order.data.isSelectDelete', order);
  }, [order, flag]);

  return (
    <li className={'mb-4 bg-white'}>
      <div
        className={`p-4 pr-2 md:pr-4 md:p-4 flex items-center justify-between border-l-4 ${
          numberErrors === 0 ? 'border-tertiary' : 'border-redarauco'
        }`}
      >
        <div className='flex items-center'>
          <div className='flex justify-center items-center mr-2 md:mr-4'>
            <Checkbox
              className='checkbox-custom'
              onChange={e => {
                order.onCheckedDeleted(e.target.checked);
                setIsCheckedDeleted(e.target.checked);
                if (onUpdateFlag) {
                  onUpdateFlag();
                }
              }}
              checked={isCheckedDeleted}
            />
          </div>

          <div className='flex justify-center items-center'>
            <p className={'text-xl text-grayarauco font-bold'}>{(index || 0) + 1}</p>
          </div>

          <div className='ml-2 md:ml-4 flex justify-center items-center'>
            <p className={'text-base md:text-xl text-black font-bold'}>
              Orden{' '}
              {Order.isParameterInvalid(order.data.detail.data.order.data.order)
                ? order.data.id?.split('-')[0]
                : order.data.detail.data.order.data.order}
            </p>
          </div>

          <div className='ml-2 md:ml-4 flex justify-center items-center'>
            <IconCustom name={NameIcons.visibility} onClick={() => onDetail(order)} className='text-grayarauco text-xl cursor-pointer' />
          </div>

          {order.isNumOrderDuplicate() && (
            <div className='ml-4 flex justify-center items-center'>
              <IconCustom name={NameIcons.warning} className='text-alternativethree text-xl' />

              <p className='text-base text-alternativethree font-normal ml-1'>
                Orden ya existente,{' '}
                <strong
                  className='underline font-normal cursor-pointer'
                  onClick={() => {
                    return onDetailCorrect(order);
                  }}
                >
                  ver orden ingresada
                </strong>
              </p>
            </div>
          )}
        </div>

        <div className='flex items-center'>
          <div className='flex justify-center items-center'>
            {numberErrors !== 0 && <Badge count={numberErrors} />}
            {screenResolution > SizeScreen.md && numberErrors === 0 && <p className='text-xl text-tertiary font-normal'>corregida</p>}
          </div>

          <div className='flex justify-center items-center border-l border-grayaraucoborder ml-5 md:ml-12'>
            <IconCustom
              name={isExpand ? NameIcons.arrowUp : NameIcons.arrowDown}
              className='cursor-pointer'
              onClick={() => {
                if (!isExpand) {
                  loadData();
                }
                setIsExpand(!isExpand);
              }}
            />
          </div>
        </div>
      </div>

      {isExpand && (
        <div className='flex flex-1 flex-col border-t border-grayaraucoborder'>
          {screenResolution > SizeScreen.md && (
            <div className='flex w-full'>
              <div className='flex flex-1'>
                <div className='flex-1 grid grid-cols-3 py-2'>
                  <div className='flex justify-center items-center'>
                    <h3 className='text-base font-normal'>Columna</h3>
                  </div>

                  <div className='flex justify-center items-center'>
                    <h3 className='text-base font-normal'>Dato a corregir</h3>
                  </div>

                  <div className='flex justify-center items-center'>
                    <h3 className='text-base font-normal'>Ingresar nuevo dato</h3>
                  </div>
                </div>
              </div>

              <div className='flex justify-center items-center mx-4'>
                <IconCustom className='text-transparent' name={NameIcons.checkCircle} />
              </div>
            </div>
          )}
          <div />
          <div className='flex flex-1 flex-col'>
            {order.data.errors
              ?.filter(error => error.message !== undefined)
              .map(error => {
                if (error.hide) {
                  return null;
                }
                return (
                  <FailedOrderItemMolecules
                    key={error.name}
                    {...{ order, error, districts, cities, onUpdateErrorCounter, disabledCorrect, messageTooltip }}
                  />
                );
              })}
          </div>
        </div>
      )}
    </li>
  );
};

export default FailedOrderMolecules;
