import React from 'react';

import { ASSETS, APP_VERSION, APP_NAME } from 'configs/Constants';
import ClearCacheButton from 'components/atoms/buttons/ClearCacheButton';

type LayoutContainerAuthProps = {
  children: React.ReactNode;
  onSubmit: (event: any) => void;
  notStyleForm?: boolean;
  classNameForm?: string;
  classNameFormContainer?: string;
  noLegend?: boolean;
  topComponent?: React.ReactNode;
  classNameTop?: string;
};

const LayoutContainerAuth = ({
  children,
  classNameFormContainer,
  onSubmit,
  notStyleForm,
  classNameForm,
  noLegend = false,
  topComponent,
  classNameTop
}: LayoutContainerAuthProps) => {
  return (
    <div className={`bg-gray-100 flex flex-col relative overflow-auto w-full ${classNameTop}`}>
      {topComponent}
      <div className={`flex flex-col items-center justify-center w-full px-5 flex-1 ${classNameFormContainer || ''}`}>
        <div className='flex justify-center pb-10'>
          <img src={ASSETS.logo} className='w-3/4' style={{ maxWidth: 212 }} />
        </div>
        <form
          className={`${notStyleForm ? '' : 'bg-white shadow-lg rounded-md px-12 pt-6 pb-8 mb-4'} ${classNameForm || ''}`}
          onSubmit={onSubmit}
        >
          {children}
        </form>
        {!noLegend && (
          <p className='text-left text-gray-500 text-xs'>
            &copy;{new Date().getFullYear()} {APP_NAME}. <ClearCacheButton>v{APP_VERSION}</ClearCacheButton>
          </p>
        )}
      </div>
    </div>
  );
};

export default LayoutContainerAuth;
