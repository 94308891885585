export interface StoreStatisticModel {
  items?: { name?: string; value?: number; color?: string }[];
  total: number;
  totalActives: number;
}

export interface ActiveStoreStatisticServerModel {
  shopsActive?: number;
  shopsInactive?: number;
  total?: number;
}

class StoreStatistic {
  data: StoreStatisticModel;

  constructor(data?: StoreStatisticModel) {
    if (data) {
      this.data = data;
    } else {
      this.data = { items: [], total: 0, totalActives: 0 };
    }
  }

  static formalizeActiveStore(data?: any): StoreStatisticModel {
    if (data) {
      const { activeStore, deactivateStore } = data;

      return {
        items: [
          { name: 'Marcas inactivas', value: deactivateStore },
          { name: 'Marcas activas', value: activeStore }
        ],
        total: deactivateStore + activeStore || 0,
        totalActives: activeStore || 0
      };
    } else {
      return {
        items: [],
        total: 0,
        totalActives: 0
      };
    }
  }
}

export default StoreStatistic;
