import { handleErrorServiceApi } from 'helpers/Errors';

import { OrderAlertServerModel } from 'models/alerts/OrderAlert';

import api from '../index';

class OrderAlertSettingProvider {
  async getOrderAlertsSetting(country?: string) {
    try {
      return api().get(`lightorder?country=${country}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async createOrderAlertSetting(orderAlert: OrderAlertServerModel) {
    try {
      return api().post('lightorder', orderAlert);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async updateOrderAlertSetting(orderAlert: OrderAlertServerModel) {
    try {
      return api().put(`lightorder/${orderAlert.id}`, orderAlert);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }

  async deleteOrderAlertSetting(orderAlert: OrderAlertServerModel) {
    try {
      return api().delete(`lightorder/${orderAlert.id}`);
    } catch (err) {
      return handleErrorServiceApi(err as Error);
    }
  }
}

export default new OrderAlertSettingProvider();
