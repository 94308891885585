import { useContext, useState } from 'react';

import { Tabs } from 'antd';

import { NameIcons, SizeScreen } from 'configs/Enums';
import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';
import DonutGraphics from 'components/molecules/graphics/Donut';
import OrderFail from 'models/statistic/order/OrderFail';
import ColumnCustom from 'components/molecules/graphics/column/Column';
//import SelectInterval from 'components/atoms/selects/SelectInterval';
import { ParamsValueFilterStatistic } from 'components/molecules/headers/Statistics';

enum TabOptions {
  general = 'general',
  brand = 'brand',
  errors = 'errors'
}

type BusinessFailedOrderTabProps = {
  failedOrders?: OrderFail;
  params?: ParamsValueFilterStatistic;
  onFilter?: (params: ParamsValueFilterStatistic) => void;
};

const BusinessFailedOrderTab = ({ failedOrders }: BusinessFailedOrderTabProps) => {
  const { screenResolution } = useContext(RootContext);

  const [tabOptionSelect, setTabOptionSelect] = useState<TabOptions>(TabOptions.general);

  const getOrderData = () => {
    if (tabOptionSelect === TabOptions.general) {
      return failedOrders?.data.general;
    } else if (tabOptionSelect === TabOptions.brand) {
      return failedOrders?.data.brand;
    }
    return [];
  };

  return (
    <div>
      <div className='flex justify-between mx-5 md:mx-9 mb-4 md:mb-0 flex-col md:flex-row'>
        <h1 className='text-lg font-bold mb-4 md:mb-8'>Carga con órdenes fallidas</h1>

        {/**
        <SelectInterval
          onChange={val => {
            //console.log('onChange() ==> val', val);
            if (onFilter) {
              onFilter({ ...params, dateRangeFailedOrder: { startDate: val.startDate, endDate: val.endDate } });
            }
          }}
        />
        **/}
      </div>

      <div className='flex flex-col md:flex-row mx-5 md:mx-9'>
        <div className='flex flex-row items-center'>
          <div>
            <IconCustom name={NameIcons.store} className='text-3xl text-grayarauco-400' />
          </div>
          <div className='ml-4'>
            <p className='text-base text-black font-bold'>Marcas con más órdenes fallidas:</p>
            <p className='text-base font-normal'>{failedOrders?.data.storeMostFailed || ''}</p>
          </div>
        </div>

        <div className='ml-0 md:ml-10 flex flex-row items-center'>
          <div>
            <IconCustom name={NameIcons.viewList} className='text-3xl text-grayarauco-400' />
          </div>
          <div className='ml-4'>
            <p className='text-base text-black font-bold'>Errores de formatos mas recurrentes:</p>
            <p className='text-base font-normal'>{failedOrders?.data.formattingMostErrors || ''}</p>
          </div>
        </div>
      </div>

      <div className='flex justify-center mt-7'>
        <Tabs
          centered
          className='ant-tab-original'
          style={{ width: screenResolution > SizeScreen.xl ? '40%' : 'auto' }}
          onChange={(val: any) => {
            //console.log('onChange() => val', val);
            setTabOptionSelect(val);
          }}
        >
          <Tabs.TabPane
            className='justify-center items-center flex'
            tabKey='Promedio'
            key={TabOptions.general}
            tab={<div className='flex justify-center items-center my-4 text-xs lg:text-lg'>Vista general</div>}
          ></Tabs.TabPane>
          <Tabs.TabPane
            tabKey='Mes actual'
            key={TabOptions.brand}
            tab={<div className='flex justify-center items-center my-4 text-xs lg:text-lg'>Vista marca</div>}
          ></Tabs.TabPane>

          <Tabs.TabPane
            tabKey='Semana actual'
            key={TabOptions.errors}
            tab={<div className='flex justify-center items-center my-4 text-xs lg:text-lg'>Tipos de errores</div>}
          ></Tabs.TabPane>
        </Tabs>
      </div>

      <div>
        {(tabOptionSelect === TabOptions.brand || tabOptionSelect === TabOptions.general) && (
          <DonutGraphics
            componentTop={
              screenResolution <= SizeScreen.lg ? (
                <div className='flex flex-col justify-center items-center'>
                  <div className='flex justify-center items-center w-full'>
                    <h1 className='text-4xl text-redarauco-200 font-bold'>{failedOrders?.data.general[1].value}</h1>
                  </div>
                  <div className='flex flex-row items-center'>
                    <IconCustom lightOutlined name={NameIcons.cancel} className='text-4xl text-grayarauco-400' />
                    <p className='text-lg font-normal ml-2'>Órdenes fallidas</p>
                  </div>
                </div>
              ) : undefined
            }
            componentLeft={
              screenResolution > SizeScreen.lg ? (
                <div className='flex flex-col justify-center items-end'>
                  <div className='flex justify-center items-center w-full'>
                    <h1 className='text-4xl text-redarauco-200 font-bold'>
                      {(failedOrders?.data.general?.length || 0) > 0 ? failedOrders?.data.general[1].value : 0}
                    </h1>
                  </div>
                  <div className='flex flex-row items-center'>
                    <IconCustom lightOutlined name={NameIcons.cancel} className='text-4xl text-grayarauco-400' />
                    <p className='text-lg font-normal ml-2'>Órdenes fallidas</p>
                  </div>
                </div>
              ) : undefined
            }
            textCenter='Órdenes cargadas'
            isColor={tabOptionSelect === TabOptions.brand ? false : true}
            data={getOrderData()}
          />
        )}
        {tabOptionSelect === TabOptions.errors && <ColumnCustom data={failedOrders?.data.error} color='#BE231E' />}
      </div>
    </div>
  );
};

export default BusinessFailedOrderTab;
