import { useState, useContext } from 'react';

import { NameIcons, SizeScreen } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import IconCustom from 'components/atoms/icons';
import ButtonCustom from 'components/atoms/buttons';
import BoardCustom from 'components/molecules/board';
import ModalCustom from 'components/molecules/modals';

import { capitalize, shippingTypeStringToDisplay, stringDeliveryToIcon } from 'helpers/Converts';

import OrderAlert from 'models/alerts/OrderAlert';
import LimitScheduleForm from '../forms/LimitSchedule';

type BoardOrderAlertProps = {
  orderAlerts: OrderAlert[];
  onUpdateOrderAlertSetting: (orderAlert: OrderAlert) => void;
  onCreateOrderAlertSetting: (orderAlert: OrderAlert) => void;
  onDeletedOrderAlertSetting: (orderAlert: OrderAlert) => void;
  title?: string;
};

const colorsConvert = (name: string) => {
  if (name?.toLowerCase() === 'alto') {
    return 'bg-redarauco-200';
  }
  if (name?.toLowerCase() === 'medio') {
    return 'bg-araucoyellow-200';
  }
  return 'bg-araucogreen';
};

const BoardOrderAlert = ({
  orderAlerts,
  onUpdateOrderAlertSetting,
  onCreateOrderAlertSetting,
  onDeletedOrderAlertSetting,
  title
}: BoardOrderAlertProps) => {
  const { showModalQuestion, screenResolution } = useContext(RootContext);
  const { typesDelivery, countryUser } = useContext(AuthContext);

  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [orderAlertCurrent, setOrderAlertCurrent] = useState<OrderAlert>();
  const [flag, setFlag] = useState<number>(0);

  const showModalUpdate = (orderAlert: OrderAlert) => {
    const updateScheduleCurrent = new OrderAlert({ ...orderAlert.data, country: countryUser });
    setOrderAlertCurrent(updateScheduleCurrent);
    //console.log('delivery', deliveryScheduleCurrent);
    setFlag(flag + 1);
    setIsModalUpdate(true);
  };

  const showModalCreate = () => {
    const newScheduleCurrent = new OrderAlert({});
    setOrderAlertCurrent(newScheduleCurrent);
    //console.log('delivery', deliveryScheduleCurrent);
    setFlag(flag + 1);
    setIsModalAdd(true);
  };

  const showModalDelete = (orderAlert: OrderAlert) => {
    setOrderAlertCurrent(orderAlert);
    showModalQuestion({
      title: `¿Desea eliminar el limite de horarios de ${orderAlert.data.typeDelivery}?`,
      options: [{ text: 'Si', onAction: () => onDeletedOrderAlertSetting(orderAlert) }, { text: 'No' }]
    });
  };

  const validateAddSchedules = () => {
    const isValidate = typesDelivery.every(typeDelivery =>
      orderAlerts.find(orderAlert => typeDelivery.data.value === orderAlert.data.typeDelivery)
    );
    console.log('validateAddSchedules() =>', { isValidate, typesDelivery, orderAlerts });
    return isValidate;
  };

  return (
    <>
      {title && (
        <div className='bg-white flex flex-row px-10 pt-8 pb-0 sm:pb-8'>
          <h1 className='mb-4'>{title}</h1>

          <ButtonCustom className='ml-4' disabled={validateAddSchedules()} onClick={() => showModalCreate()}>
            <IconCustom name={NameIcons.add} />
          </ButtonCustom>
        </div>
      )}

      <div className='mx-5 sm:mx-10'>
        {screenResolution > SizeScreen.sm && (
          <BoardCustom
            data={orderAlerts}
            columns={OrderAlert.headerTable({ showModalUpdate, onDeleted: showModalDelete, typesDelivery })}
            persistTableHead
            pagination={false}
            title={
              <div className='flex overflow-clip justify-between bg-transparent pt-2 pb-4'>
                <h1 className='overflow-clip text-base font-normal'>
                  Rango de horarios según urgencia de órdenes pendientes para ser entregadas, medidos sobre la{' '}
                  <strong>fecha y hora estimada de entrega</strong>
                </h1>
              </div>
            }
          />
        )}

        {screenResolution <= SizeScreen.sm && (
          <div>
            {orderAlerts &&
              orderAlerts.length > 0 &&
              orderAlerts.map(orderA => (
                <div key={orderA.data.typeDelivery as string} className='bg-white' style={{ marginBottom: 2 }}>
                  <div className='text-primary flex flex-row my-4 pb-2 px-4 pt-4 border-grayarauco-200' style={{ borderBottomWidth: 1 }}>
                    <div className='flex justify-center items-center mr-1'>
                      <IconCustom name={stringDeliveryToIcon(orderA.data.typeDelivery as string)} />
                    </div>
                    <div className='flex justify-center items-center'>
                      <p>{shippingTypeStringToDisplay(orderA.data.typeDelivery as string)}</p>
                    </div>
                  </div>

                  <div className='flex flex-row justify-between px-4'>
                    {orderA.data.params
                      ?.sort((a, b) => {
                        if ((a.level || '') > (b.level || '')) {
                          return 1;
                        }
                        if ((a.level || '') < (b.level || '')) {
                          return -1;
                        }
                        return 0;
                      })
                      .map(par => (
                        <div key={par.color} className='flex flex-col items-center'>
                          <div className={`py-1 px-4 w-16 rounded-full text-white text-sm font-bold ${colorsConvert(par.color || '')}`}>
                            {capitalize(par.color)}
                          </div>
                          <p>{`horas`}</p>
                          <p>{`${OrderAlert.getFirstTime(orderA, par.level)} - ${par.time}`}</p>
                        </div>
                      ))}
                  </div>

                  <div className='flex justify-end items-center mx-4 mt-2 pb-2'>
                    <IconCustom
                      name={NameIcons.rename}
                      className='text-xl mr-1 text-grayarauco-400'
                      onClick={() => showModalUpdate(orderA)}
                    />
                    <IconCustom name={NameIcons.deleted} className='text-xl text-grayarauco-400' onClick={() => showModalDelete(orderA)} />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>

      {/** modal para actulizar el horario de corte **/}
      {orderAlertCurrent && (
        <ModalCustom isVisible={isModalUpdate} onClose={() => setIsModalUpdate(false)} styleContent={{ padding: 0 }}>
          <LimitScheduleForm
            data={orderAlertCurrent}
            flag={flag}
            orderAlerts={orderAlerts}
            onClose={() => {
              setOrderAlertCurrent(undefined);
              setIsModalUpdate(false);
            }}
            onUpdate={data => {
              onUpdateOrderAlertSetting(data);
              setOrderAlertCurrent(undefined);
              setIsModalUpdate(false);
            }}
          />
        </ModalCustom>
      )}

      {orderAlertCurrent && (
        <ModalCustom isVisible={isModalAdd} onClose={() => setIsModalAdd(false)}>
          <LimitScheduleForm
            data={orderAlertCurrent}
            flag={flag}
            orderAlerts={orderAlerts}
            onClose={() => {
              setOrderAlertCurrent(undefined);
              setIsModalAdd(false);
            }}
            onCreate={data => {
              onCreateOrderAlertSetting(data);
              setOrderAlertCurrent(undefined);
              setIsModalAdd(false);
            }}
          />
        </ModalCustom>
      )}
    </>
  );
};

export default BoardOrderAlert;
