export interface HelpCenterResourceModel {
  tutorial: string;
}

class HelpCenterResource {
  tutorial: string;

  constructor(data: HelpCenterResourceModel) {
    this.tutorial = data.tutorial;
  }
}

export default HelpCenterResource;
