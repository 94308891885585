import { useContext, useState, useEffect } from 'react';

import { ASSETS } from 'configs/Constants';
import { EnumCountries } from 'configs/Enums';

import { AuthContext } from 'context/AuthContext';

import ModalCustom from '.';

const CountryItems = [
  {
    name: 'Chile',
    value: EnumCountries.cl,
    logo: ASSETS.flagChileSvg
  },
  {
    name: 'Colombia',
    value: EnumCountries.col,
    logo: ASSETS.flagColombiaSvg
  },
  {
    name: 'Perú',
    value: EnumCountries.per,
    logo: ASSETS.flagPeruSvg
  },
  {
    name: 'Todos',
    value: EnumCountries.all,
    logo: ASSETS.globeWorldPng
  }
];

const CountrySelectorModal = () => {
  const { countryUser, authStatus, onUpdateCountryUser } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);

  const validateLogoCountry = () => {
    if (countryUser === EnumCountries.per) {
      return ASSETS.flagPeruSvg;
    } else if (countryUser === EnumCountries.col) {
      return ASSETS.flagColombiaSvg;
    } else if (countryUser === EnumCountries.cl) {
      return ASSETS.flagChileSvg;
    } else if (countryUser === EnumCountries.all) {
      return ASSETS.globeWorldPng;
    }
  };

  useEffect(() => {
    if (!countryUser && authStatus.user?.isSuperAdminGlobal()) {
      setShowModal(true);
    }
  }, []);

  if (authStatus.user?.isSuperAdminGlobal()) {
    return (
      <div>
        {countryUser && (
          <button className='mx-2 font-semibold' onClick={() => setShowModal(true)}>
            <img className='h-6 w-6 object-contain' src={validateLogoCountry()} alt='log img' />
          </button>
        )}

        <ModalCustom
          isVisible={showModal}
          onClose={() => {
            console.log('onClose() => countrySelector', { countryUser, countryItems: CountryItems[CountryItems.length - 1] });
            onUpdateCountryUser(countryUser || CountryItems[CountryItems.length - 1].value);
            setShowModal(false);
          }}
        >
          <div>
            <h1>Selecciona un país</h1>
            <h2 className='text-lg'>
              Bienvenido <span className='font-bold'>{authStatus.user?.data.fullName}</span> Selecciona una división
            </h2>

            <div className='flex flex-row mt-4'>
              {CountryItems.map(countryItem => {
                return (
                  <div key={countryItem.name} className='flex justify-center flex-col items-center'>
                    <button
                      onClick={() => {
                        console.log('onClick() => countryItem', countryItem);
                        setShowModal(false);
                        onUpdateCountryUser(countryItem.value);
                      }}
                    >
                      <img src={countryItem.logo} alt={countryItem.name} className={`w-20 h-20 object-contain mx-2`} />
                    </button>
                    <span className='text-lg font-semibold text-center'>{countryItem.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalCustom>
      </div>
    );
  }
  return null;
};

export default CountrySelectorModal;
