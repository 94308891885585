import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//import { FormatDate, PathRoute, TypeToast, UnitDate } from 'configs/Enums';
import { FormatDate, PathRoute, TypeToast, UnitDate } from 'configs/Enums';
import { PageMetadata } from 'configs/Interface';

import { AuthContext } from 'context/AuthContext';
import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import ListOrdersOrganism from 'components/organisms/dashboard/ListOrders';

import { getDateNow, getSubtractTime } from 'helpers/Generator';

import Order from 'models/order';
import Store from 'models/store';

import OrderProvider from 'services/api/Order';

import { getOrderById, getOrdersFilter, updateOrder } from 'services/app/Order';
import WarehousePA from 'models/warehouse';

let timeOutFilter: any;

const ListOrdersPage = () => {
  const { showToast, showLoading, hideLoading } = useContext(RootContext);
  const { warehouse, onUpdateWarehouse } = useContext(DataContext);
  const { authStatus, countryUser, stores, deliveryStates } = useContext(AuthContext);

  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersFilter, setOrdersFilter] = useState<Order[]>([]);
  const [flag, setFlag] = useState(0);
  const [meta, setMeta] = useState<PageMetadata>({ page: 1, items: 10, totalPages: 1 });
  const [paramsFilter, setParamsFilter] = useState<{
    dateStart?: string;
    dateEnd?: string;
    store?: Store;
    stores?: Store[];
    deliveryStates?: string[];
    numOrder?: string;
    page?: number;
    items?: number;
    warehouse?: WarehousePA[];
  }>({
    dateStart: getSubtractTime({ unit: UnitDate.months, amount: 1, format: FormatDate.yyyymmdd }) as string,
    dateEnd: getDateNow({ format: FormatDate.yyyymmdd }) as string,
    stores: stores,
    warehouse,
    deliveryStates: deliveryStates
  });
  const [orderCurrent, setOrderCurrent] = useState<Order>();
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const params = useParams();

  const loadOrder = async (id: string) => {
    try {
      const { status, data } = await getOrderById(id);
      if (status) {
        setOrderCurrent(data);
      } else {
        setOrderCurrent(undefined);
      }
    } catch (err) {
      setOrderCurrent(undefined);
    }
  };

  const onFilterOrder = async (values: {
    dateStart?: string;
    dateEnd?: string;
    store?: Store;
    deliveryStates?: string[];
    numOrder?: string;
    page?: number;
    items?: number;
    stores?: Store[];
    warehouse?: WarehousePA[];
  }) => {
    const { dateStart, dateEnd, numOrder } = values;
    //console.log('timeOutFilter ==> ', timeOutFilter);
    clearTimeout(timeOutFilter);

    const paramsUpdate = values || {
      dateStart: dateStart || (getSubtractTime({ unit: UnitDate.months, amount: 1, format: FormatDate.yyyymmdd }) as string),
      dateEnd: dateEnd || (getDateNow({ format: FormatDate.yyyymmdd }) as string)
    };

    setParamsFilter(paramsUpdate);
    showLoading();
    let ordersCurrent: Order[] = orders;
    //console.log('onFilterOrder() ===>', values);

    console.log('paramsUpdate ==> ', paramsUpdate);
    OrderProvider.source.cancel('nueva solicitud.');

    if (numOrder) {
      OrderProvider.controllerOrderByNumOrder?.abort();
    }

    const { status, data } = await getOrdersFilter({
      ...values,
      stores: authStatus.user?.isLocatario()
        ? authStatus.user?.data.commerce
          ? [authStatus.user?.data.commerce]
          : undefined
        : values.stores,
      country: countryUser
    });

    if (status) {
      if (data) {
        ordersCurrent = data.orders;
        setMeta({ ...meta, ...data.meta });
      }
    } else {
      ordersCurrent = [];
    }

    setOrders(ordersCurrent || []);
    setOrdersFilter(ordersCurrent || []);
    hideLoading();

    console.log('paramsUpdate 1 ==> ', paramsUpdate);
    timeOutFilter = setTimeout(
      paramsF => {
        console.log('paramsFilter() 1 ==>', { paramsF, paramsUpdate });
        if (paramsF) {
          console.log('paramsFilter() 2 ==>', paramsF);
          onFilterOrder(paramsF);
        }
      },
      authStatus.user?.isLocatario() || authStatus.user?.isCellarPA() ? 300000 : 500000,
      paramsUpdate
    );
    return { status: true, data: ordersCurrent };
  };

  const onSortList = async (params: { isSort?: boolean; field?: string; data?: Order[] }) => {
    try {
      if (params.data) {
        setOrdersFilter(params.data);
        setFlag(flag + 1);
      }
    } catch (err) {
      console.log('onSortList() =>', err);
    }
  };

  const onFilterList = async (params: { val?: string[] | string; field?: string; data?: any[] }) => {
    try {
      if (params.data) {
        setOrdersFilter(params.data);
        setFlag(flag + 1);
        return params.data;
      }
      setOrdersFilter([]);
      setFlag(flag + 1);
      return [];
    } catch (err) {
      console.log('onFilter() => err', err);
      setOrdersFilter([]);
      setFlag(flag + 1);
      return [];
    }
  };

  const onDetailOrder = async (order: Order) => {
    //navigate(`${PathRoute.orderDetail}/${encodeURIComponent(`${order.data.id}`)}`);
    showLoading();
    await loadOrder(order.data.id || '');
    hideLoading();
  };

  const onUpdateOrder = async (order: Order) => {
    showLoading();
    console.log('onUpdateOrder() ==> order', order);
    const { status, message } = await updateOrder(order);

    if (status) {
      showToast({ text: 'Actualización exitosa.', type: TypeToast.success });
      ordersFilter.forEach(ord => {
        if (order.data.id === ord.data.id) {
          ord = order;
        }
      });
      orders.forEach(ord => {
        if (order.data.id === ord.data.id) {
          ord = order;
        }
      });
      setFlag(flag + 1);
    } else {
      showToast({ text: message || 'No fue posible actualizar.', type: TypeToast.error });
    }
    hideLoading();
  };

  useEffect(() => {
    if (params && params.numOrder) {
      onFilterOrder({
        numOrder: params.numOrder,
        dateStart: getSubtractTime({ unit: UnitDate.months, amount: 1, format: FormatDate.yyyymmdd }) as string,
        dateEnd: getDateNow({ format: FormatDate.yyyymmdd }) as string
      });
      window.history.replaceState('', '', `${window.location.origin}${PathRoute.listOrders}`);
    } else {
      paramsFilter.stores = stores;
      paramsFilter.warehouse = warehouse;
      paramsFilter.deliveryStates = deliveryStates;
      onFilterOrder(paramsFilter);
    }

    if (!firstLoad) {
      onUpdateWarehouse();
      setFirstLoad(true);
    }
    return () => {
      console.log('timeOutFilter clean ==> ', timeOutFilter);
      clearTimeout(timeOutFilter);
    };
  }, [countryUser, stores, warehouse, deliveryStates]);

  return (
    <ListOrdersOrganism
      onSort={onSortList}
      onFilter={onFilterList}
      onFilterFather={params => onFilterOrder({ ...params, items: meta.items, page: 1 })}
      orders={ordersFilter}
      ordersAll={orders}
      paginationServer
      onChangeRowsPerPage={(items, page) => {
        console.log(`onChangeRowsPerPage =>`, { items, page });
        onFilterOrder({ ...paramsFilter, items, page });
      }}
      onChangePage={(page, totalRow) => {
        console.log('onChangePage() => { page, totalRow }', { page, totalRow });
        onFilterOrder({ ...paramsFilter, items: meta.items, page: page });
      }}
      onLoadMore={({ page }) => {
        return onFilterOrder({ ...paramsFilter, items: meta.items, page: page });
      }}
      meta={meta}
      paramsFilter={paramsFilter}
      paginationTotalRows={meta.totalItems || 0}
      flag={flag}
      onDetail={onDetailOrder}
      paginationRowsPerPageOptions={[10, 20, 50, 70]}
      orderCurrent={orderCurrent}
      onUpdateOrderCurrent={(val?: Order) => setOrderCurrent(val)}
      onUpdate={onUpdateOrder}
    />
  );
};

export default ListOrdersPage;
