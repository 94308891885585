import { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';

import { EnumIncomeType, EnumNotificationType, NameIcons, PathRoute } from 'configs/Enums';

import { RouterInContext } from 'context/RouterIn';
import { AuthContext } from 'context/AuthContext';
import IconCustom from 'components/atoms/icons';
//import ButtonCustom from 'components/atoms/buttons';

import Notification from 'models/notification';

import { getNotifications, updateReadNotification, updateReadNotifications } from 'services/app/Notification';
import NotificationProvider from 'services/api/Notification';

const convertStringAtIcon = (val?: string, fails?: number) => {
  //console.log('convertStringAtIcon() ==> val', { val, isvalid: val === EnumNotificationType.ordersNotificationFileFinal });
  if (val === EnumNotificationType.ordersNotificationFileFinal) {
    return NameIcons.checkCircle;
  } else if (val === EnumNotificationType.ordersNotificationFail) {
    if (fails === 0) {
      return NameIcons.playlistAddCheck;
    }
    return NameIcons.playlistRemove;
  } else if (val === EnumNotificationType.creationOrders) {
    return NameIcons.playlistAddCheck;
  }
  return NameIcons.inventory;
};

const convertStringAtColor = (val?: string, fails?: number) => {
  if (val === EnumNotificationType.ordersNotificationFileFinal) {
    return 'text-araucogreen';
  } else if (val === EnumNotificationType.ordersNotificationFail) {
    if (fails === 0) {
      return 'text-primary';
    }
    return 'text-redarauco';
  } else if (val === EnumNotificationType.creationOrders) {
    return 'text-primary';
  }
  return 'text-primary';
};

const NotificationDD = () => {
  const { authStatus, flagUpdateNotification } = useContext(AuthContext);
  const { newOrders } = useContext(RouterInContext);
  const [notifications, setNotitifications] = useState<Notification[]>([]);
  const [isExpand, setIsExpand] = useState(false);
  const [countNotification, setCountNotification] = useState(0);

  const navigate = useNavigate();

  const loadNotification = async () => {
    NotificationProvider.controllerGetNotifications?.abort();
    const { status, data } = await getNotifications();
    console.log('loadNotification() ==> { status, data }', { status, data });

    if (status) {
      const notfNew =
        (data as Notification[]).filter(notf => {
          if (notf.data.state === 'new') {
            if (
              notf.data.type === EnumNotificationType.creationOrders ||
              notf.data.type === EnumNotificationType.ordersNotificationFail ||
              notf.data.type === EnumNotificationType.ordersNotificationFileFinal ||
              notf.data.type === EnumNotificationType.orderStatusChangeCancelled ||
              notf.data.type === EnumNotificationType.orderStatusChangeDelivered ||
              notf.data.type === EnumNotificationType.orderStatusChangeDispatched ||
              notf.data.type === EnumNotificationType.new
            ) {
              return true;
            }
          }
          return false;
        }) || [];
      setNotitifications(notfNew);
      setCountNotification(notfNew.length || 0);
    }
  };

  const onReadNotification = async (notification: Notification) => {
    const { status } = await updateReadNotification(notification.data.connectionId || '');

    let updateNotification = notifications;
    if (status) {
      notification.data.state = EnumIncomeType.read;
      if (countNotification > 0) {
        setCountNotification(countNotification - 1);
      }
      updateNotification = notifications.filter(not => not.data.connectionId !== notification.data.connectionId);
    }
    setNotitifications(updateNotification);
  };

  const onReadAllNotificacion = async () => {
    const { status } = await updateReadNotifications(notifications);

    if (status) {
      setNotitifications([]);
      await loadNotification();
    }
  };

  const renderNotification = () => {
    if (notifications && notifications.length === 0) {
      return (
        <div className='flex flex-col justify-center items-center bg-white h-96 px-8 rounded-md overflow-hidden shadow-xl'>
          <IconCustom name={NameIcons.notification} className='text-4xl text-grayarauco-400' />
          <p className='text-base text-grayarauco-400'>No hay notificaciones nuevas</p>
          <p className='text-xs'>!Estas actualizado!</p>
        </div>
      );
    }
    return (
      <div className='w-80 bg-white rounded-md overflow-hidden shadow-xl bg-grayarauco-100'>
        <div className='flex justify-end items-center px-5 py-1'>
          <p className='underline text-primary text-xs font-light' onClick={() => onReadAllNotificacion()}>
            Marcar todas como leídas
          </p>

          <div className='flex justify-center items-center ml-4'>
            <IconCustom name={NameIcons.cropFree} className='text-xs text-grayarauco-300' />
          </div>
        </div>

        <div className='px-4 pb-4 h-96 overflow-y-scroll bg-white'>
          {notifications.length > 0 &&
            notifications.map(notification => (
              <div className='py-2 border-b relative flex flex-col' key={notification.data.connectionId}>
                <IconCustom
                  onClick={async () => {
                    await onReadNotification(notification);
                  }}
                  name={NameIcons.close}
                  className='text-xs text-grayarauco-400 absolute right-0 top-4'
                />
                <div className='flex flex-row mt-5 flex-1 w-full'>
                  <div className='flex mx-6 justify-center items-center'>
                    {authStatus.user?.isLocatario() && (
                      <IconCustom
                        name={convertStringAtIcon(notification.data.type, notification.data.fails)}
                        className={`text-2xl ${convertStringAtColor(notification.data.type, notification.data.fails)}`}
                      />
                    )}
                    {!authStatus.user?.isLocatario() && <p className='text-sm font-bold text-primary'>{notification.data.store}</p>}
                  </div>

                  <div className='flex flex-1 w-4/6 flex-col justify-center'>
                    <div className={`flex ${notification.data.description ? 'mb-2' : ''}`}>
                      <h2 className='text-sm font-bold self-center'>{notification.data.titleAlternative}</h2>
                    </div>

                    {notification.data.descriptionAlternative && (
                      <div>
                        <p
                          className='text-xs break-words text-left'
                          style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                        >{`${notification.data.descriptionAlternative}`}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className='flex w-full justify-end items-end h-7'>
                    {(notification.data.type === EnumNotificationType.creationOrders ||
                      notification.data.type === EnumNotificationType.new ||
                      notification.data.type === EnumNotificationType.ordersNotificationFail) && (
                      <Button
                        className='mt-0 my-0 pt-0 pb-1 py-0 m-0 p-0 text-xs text-araucoblue-200'
                        type='link'
                        onClick={async () => {
                          //console.log('onClick() ==> notification', notification);
                          await onReadNotification(notification);
                          if (notification.data.type === EnumNotificationType.ordersNotificationFail && notification.data.fails !== 0) {
                            navigate(PathRoute.listFailedOrders);
                          } else if (notification.data.idOrders?.length === 1) {
                            navigate(`${PathRoute.orderDetail}/${notification.data.idOrders[0]}`);
                          } else {
                            navigate(PathRoute.listOrders);
                          }
                        }}
                      >
                        Ver
                      </Button>
                    )}
                  </div>

                  <div className='flex justify-between items-center'>
                    <p className='text-xs text-araucoblue-200 font-normal'>Fecha ingreso</p>

                    <div className='flex flex-row justify-center items-center'>
                      <IconCustom name={NameIcons.calendarMonth} className='text-xs text-grayarauco-400' />
                      <p className='ml-1 text-xs text-grayarauco-400 font-normal'>{notification.data.date}</p>
                    </div>

                    <div className='flex flex-row justify-center items-center'>
                      <IconCustom name={NameIcons.schedule} className='text-xs text-grayarauco-400' />
                      <p className='ml-1 text-xs text-grayarauco-400 font-normal'>{notification.data.time}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    //if (authStatus.user?.isCellarPA()) {
    loadNotification();
    //}
  }, [newOrders, authStatus, flagUpdateNotification]);

  return (
    <Dropdown getPopupContainer={(triggerNode: HTMLElement) => triggerNode} arrow trigger={['click']} overlay={renderNotification}>
      <Button type='text'>
        <Badge count={countNotification} offset={[0, 5]}>
          <IconCustom name={NameIcons.notification} onClick={() => setIsExpand(!isExpand)} />
        </Badge>
      </Button>
    </Dropdown>
  );
};

export default NotificationDD;
