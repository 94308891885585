import { EnumCountries } from 'configs/Enums';

import Address, { AddressModelServer } from 'models/order/Address';
import OrderInfo, { OrderInfoModelServer } from 'models/order/OrderInfo';
import Shipping, { ShippingModelServer } from 'models/order/Shipping';
import Customer, { CustomerModelServer } from 'models/order/Customer';

export interface OrderDetailModel {
  addresses: Address;
  order: OrderInfo;
  ean?: string;
  errors?: boolean;
  shipphing: Shipping;
  customer: Customer;
  emailEventLogs: emailEventLogsModelServer[];
}

export interface OrderDetailModelServer {
  addresses?: AddressModelServer;
  order?: OrderInfoModelServer;
  orden?: OrderInfoModelServer;
  ean?: string;
  errores?: boolean;
  shippings?: ShippingModelServer;
  customer?: CustomerModelServer;
  customerEncrypted?: CustomerModelServer;
  addressEncrypted?: AddressModelServer;
  numOrder?: string;
  orderNum?: string;
  store?: string;
  id?: string;
  emailEventLogs?: emailEventLogsModelServer[];
}

export interface emailEventLogsModelServer {
  email_sent: boolean;
  date_sent: string;
  destination: string;
  status_order: any;
  error?: any;
  forwarded: boolean;
}

class OrderDetail {
  data: OrderDetailModel;

  constructor(data: OrderDetailModel) {
    this.data = data;
  }

  static formalizeData(data: OrderDetailModelServer, encrypted?: boolean): OrderDetailModel {
    const {
      addresses,
      order,
      orden,
      ean,
      shippings,
      addressEncrypted,
      customer,
      customerEncrypted,
      errores,
      store,
      numOrder,
      orderNum,
      id,
      emailEventLogs
    } = data;
    //console.log('formalizeData ===> orderDetail ===> data', data);

    let orderNew: OrderInfo;
    let addressesNew: Address;
    let customerNew: Customer;
    let shippingsNew: Shipping;
    let orderCurrent: OrderInfoModelServer = { order: '' };
    if (numOrder || orderNum || id) {
      let numOrderCurrent = numOrder || orderNum || id || '';
      let indexFirst = numOrderCurrent?.indexOf(`-${EnumCountries.cl}-`);
      if (indexFirst === -1) {
        indexFirst = numOrderCurrent?.indexOf(`-${EnumCountries.col}-`);
      }
      if (indexFirst === -1) {
        indexFirst = numOrderCurrent?.indexOf(`-${EnumCountries.per}-`);
      }
      if (indexFirst !== -1) {
        const numOrderSlice = numOrderCurrent.slice(indexFirst);
        numOrderCurrent = numOrderCurrent.replace(numOrderSlice, '');
      }
      //console.log('formalizeData() => { indexFirst, formatNumOrder }', { indexFirst, formatNumOrder, numOrderCurrent });
      orderCurrent = { order: numOrderCurrent, store };
      orderNew = new OrderInfo(OrderInfo.formalizeData(orderCurrent));
    } else if (order) {
      orderNew = new OrderInfo(OrderInfo.formalizeData(order));
    } else if (orden) {
      orderNew = new OrderInfo(OrderInfo.formalizeData(orden));
    } else {
      orderNew = OrderInfo.createVoid();
    }

    try {
      let addressesData: AddressModelServer | undefined;
      if (addresses) {
        //console.log('formalizeData() => addresses', addresses);
        if (typeof addresses === 'string') {
          addressesData = JSON.parse(addresses);
        } else {
          addressesData = addresses;
        }
      } else {
        addressesNew = Address.createVoid();
      }

      if (addressEncrypted && encrypted) {
        if (typeof addressEncrypted !== 'string') {
          addressesData = { ...addressesData, ...addressEncrypted };
        }
      }

      if (addressesData) {
        addressesNew = new Address(Address.formalizeData(addressesData));
      } else {
        addressesNew = Address.createVoid();
      }
    } catch (err) {
      addressesNew = Address.createVoid();
    }

    try {
      let customerData: CustomerModelServer | undefined;
      if (customer) {
        if (typeof customer === 'string') {
          customerData = JSON.parse(customer);
        } else {
          customerData = customer;
        }
      } else {
        customerNew = Customer.createVoid();
      }
      if (customerEncrypted && encrypted) {
        if (typeof customerEncrypted !== 'string') {
          customerData = { ...customerData, ...customerEncrypted };
        }
      }

      if (customerData) {
        customerNew = new Customer(Customer.formalizeData(customerData));
      } else {
        customerNew = Customer.createVoid();
      }
    } catch (err) {
      customerNew = Customer.createVoid();
    }

    try {
      if (shippings) {
        if (typeof shippings === 'string') {
          shippingsNew = new Shipping(Shipping.formalizeData(JSON.parse(shippings)));
        } else {
          shippingsNew = new Shipping(Shipping.formalizeData(shippings));
        }
      } else {
        shippingsNew = Shipping.createVoid();
      }
    } catch (err) {
      shippingsNew = Shipping.createVoid();
    }
    //console.log('formalizeData() ==> ', { customerNew, customer, customerEncrypted });

    return {
      addresses: addressesNew,
      order: orderNew,
      ean,
      errors: errores,
      shipphing: shippingsNew,
      customer: customerNew,
      emailEventLogs: emailEventLogs || []
    };
  }
}

export default OrderDetail;
