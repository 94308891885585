import { useContext, useEffect, useState } from 'react';

import { EnumTypeFile, TypeToast } from 'configs/Enums';
import { EnumTypeDownloadFile } from 'configs/Enums';
//
import { AuthContext, DownloadFiles } from 'context/AuthContext';
import { RootContext } from 'context/RootContext';

import HandbookOrganism from 'components/organisms/dashboard/Handbook';

import Handbook from 'models/file/Handbook';

import { postHandbook } from 'services/app/File';

const HandbookPage = () => {
  const { showToast, hideLoading, showLoading } = useContext(RootContext);
  const { downloadFiles, countryUser, loadAllDownloadFiles } = useContext(AuthContext);

  const [handbooks, setHandbooks] = useState<Handbook[]>([]);
  const [flag, setFlag] = useState<number>(0);

  const onUploadHandBook = async (handbook?: Handbook) => {
    showLoading();
    try {
      const { status, message, data } = await postHandbook(handbook);
      console.log('uploadHandBook() =>', { data, status, message });

      if (status) {
        showToast({ text: 'Archivo subido con exito', type: TypeToast.success });
      } else {
        showToast({ text: 'El archivo no fu subido con éxito.', type: TypeToast.error });
      }
    } catch (err) {
      console.log('uploadHandBook() =>', err);
    }
    hideLoading();
  };

  const loadFile = async ({ handbook, template, handbookUnit }: DownloadFiles) => {
    try {
      const arrayHandB: Handbook[] = [];
      if (handbook) {
        arrayHandB.push(
          new Handbook(
            await Handbook.formalizeData({
              country: countryUser,
              type: EnumTypeDownloadFile.handbook,
              url: handbook.data.fileLocation,
              name: `Manual de uso Carga masiva - ${countryUser}`,
              title: 'Manual de uso Carga masiva',
              format: EnumTypeFile.pdf,
              base64: handbook.data.fileFormat
            })
          )
        );
      }
      if (template) {
        arrayHandB.push(
          new Handbook(
            await Handbook.formalizeData({
              country: countryUser,
              type: EnumTypeDownloadFile.template,
              url: template.data.fileLocation,
              name: `Plantilla órdenes de envío - ${countryUser}`,
              title: 'Plantilla de trabajo',
              format: EnumTypeFile.xls,
              base64: template.data.fileFormat
            })
          )
        );
      }
      if (handbookUnit) {
        arrayHandB.push(
          new Handbook(
            await Handbook.formalizeData({
              country: countryUser,
              type: EnumTypeDownloadFile.handbookManualUnitLoading,
              url: handbookUnit.data.fileLocation,
              name: `Manual de uso Carga unitaria - ${countryUser}`,
              title: 'Manual de uso Carga unitaria',
              format: EnumTypeFile.pdf,
              base64: handbookUnit.data.fileFormat
            })
          )
        );
      }

      console.log('loadFile() ==>', arrayHandB);
      setHandbooks(arrayHandB);
      setFlag(flag + 1);
    } catch (err) {
      console.log('loadFile() =>', err);
    }
  };

  const onUploadHandBooks = async (val?: Handbook[]) => {
    console.log('onUploadHandBooks() ==>', val);
    try {
      if (val && val.length > 0) {
        for await (const handB of val) {
          await onUploadHandBook(handB);
        }
        loadAllDownloadFiles();
      }
      setFlag(flag + 1);
    } catch (err) {
      console.log('onUploadHandBooks() ==>', err);
      setFlag(flag + 1);
    }
  };

  useEffect(() => {
    if (downloadFiles) {
      loadFile({ ...downloadFiles });
    }
  }, [downloadFiles]);

  return <HandbookOrganism flagHB={flag} {...{ handbooks, countryUser, onUploadHandBooks }} />;
};

export default HandbookPage;
